import { Component, OnInit, Inject } from '@angular/core';
import { PageComponent, _, ICC_PAGE_DATA, SharedFacade } from '@icc/configurator/shared';
import { iccListItem } from '@icc/configurator/ui';

@Component({
  selector: 'icc-glass-type-variants-page',
  templateUrl: './glass-type-variants-page.component.html',
  styleUrls: ['./glass-type-variants-page.component.scss']
})
export class GlassTypeVariantsPageComponent extends PageComponent implements OnInit {
  public title: string = _('WINDOW|Warianty szyb');

  glassTypeVariants: iccListItem[] = [];
  selectedVariant: number | null = null

  constructor(
    @Inject(ICC_PAGE_DATA) private pageData: {
      glassTypeVariants: any[],
      selectedVariant: number
    },
    private sharedFacade: SharedFacade
  ) {
    super();
  }

  ngOnInit() {
    this.glassTypeVariants = this.pageData.glassTypeVariants.map<iccListItem>(variant => ({
      id: Number(variant.id),
      title: variant.name,
      imageUrl: `/files/glasstypevariant/${variant.image}`,
      description: `${variant.name}`,
    }));
    this.selectedVariant = Number(this.pageData.selectedVariant);
  }

  selectVariant(item: iccListItem) {
    this.sharedFacade.closePage(item.id);
  }

}
