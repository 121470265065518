import { Common } from '@icc/common/Common';

import { StateService } from '@icc/common/state.service';
import { EventBusService } from '@icc/common/event-bus.service';

import { Injectable, Inject } from '@angular/core';
import { APP_CONFIG, AppConfig, AppConfigFactory } from '@icc/common/config';

import {
    ConfigurationsService,
    ConfigurationType,
    Configurator,
} from '@icc/common/configurations/configurations.service';
import { TranslateService } from '@icc/common/translate.service';
import { ConfiguratorCurrentConfiguratorService } from './current-configurator.service';
import { Store } from '@ngrx/store';
import { ConfiguratorPartialState } from '@icc/configurator/shared';
import { tap } from 'rxjs/operators';
import { core } from '@icc/common';
import { ConfiguratorTranslateService } from './translate.service';

@Injectable({
    providedIn: 'root',
})
export class ConfiguratorConfigurationsService<
    T extends ConfigurationType = ConfigurationType
> extends ConfigurationsService<T> {
    conf!: Configurator<T>;

    constructor(
        private CurConfService: ConfiguratorCurrentConfiguratorService,
        @Inject(APP_CONFIG) protected config: AppConfigFactory,
        protected translateService: TranslateService,
        private eventBusService: EventBusService
    ) {
        super(translateService, config);

        this.eventBusService.subscribeWithoutConfiguration(
            ['changedConfigurator', 'initializedConfigurator'],
            () => {
                this.init();
            }
        );

        this.eventBusService.subscribe(
            [
                'changedBalcony',
                'changedFillings',
                'changedSashes',
                'changedStep',
                'putAlignmentInField',
                'putExtensionOnSide',
                'removedAlignmentInField',
                'removedAlignment',
                'removedExtensionFromSide',
                'setConstructionColor',
                'setExtensionProfile',
                'setFrameProfile',
                'setGlazingInSash',
                'setGlazingBeadColor',
                'setGlazingBeadInSash',
                'setMullionProfile',
                'setProfileSet',
                'setSashProfile',
                'setSealColor',
                'setSiliconeColor',
                'setShape',
                'setSystem',
                'setWarmEdge',
                'setLowThreshold',
                'unsetLowThreshold',
                'insertMuntins',
                'removeMuntins',
                'updateMuntins',
                'setMuntinColor',
                'updatedPrice',
                'changedHandleType',
                'changedAlushell',
                'changedOptions',
                'montageOptionsChanged',
                'changedDrive',
                'changedDoorHardware',
                'setFillingColor',
                'setDoorPortal',
                'updatedCoupledWindow'
            ],
            data => {
                this.updatedConfiguration();
            }
        );
    }

    /**
     * Uruchamianie wybranego konfiguratora
     */
    init(
        startState?:
            | InstanceType<ConfigurationsService['configurations'][T]['active']>
            | InstanceType<ConfigurationsService['configurations'][T]['simple']>,
        edit = false,
        editedPositionPrices = null
    ) {
        this.price.noPrice = false;
        this.conf = this.getOrInitConfiguratorConfigurations(
            this.CurConfService.conf,
            startState,
            edit,
            editedPositionPrices
        ) as Configurator<T>;
        this.conf$.next(this.conf);
        this.eventBusService.setCurrentConfiguration(this.conf);
        this.version = 1;
        if (this.config() && this.config().IccConfig && Common.isObject(this.config().IccConfig.Configurators[this.CurConfService.conf])) {
            this.version =
                this.config().IccConfig.Configurators[this.CurConfService.conf].version || 1;
        }
    }

    getOrInitConfiguratorConfigurations<U extends ConfigurationType>(
        type: U,
        startState?:
            | InstanceType<ConfigurationsService['configurations'][U]['active']>
            | InstanceType<ConfigurationsService['configurations'][U]['simple']>,
        edit = false,
        editedPositionPrices = null
    ) {
        if (!this.configurators[type] || !this.configurators[type].Current) {
            this.initConfiguratorConfigurations(type, startState, edit, editedPositionPrices);
        } else if (startState) {
            this.loadConfiguratorConfiguration(type, startState, edit, editedPositionPrices);
        }
        return this.configurators[type] as Configurator<U>;
    }

    initConfiguratorConfigurations<U extends ConfigurationType>(
        type: U,
        startState?:
            | InstanceType<ConfigurationsService['configurations'][U]['active']>
            | InstanceType<ConfigurationsService['configurations'][U]['simple']>,
        edit = false,
        editedPositionPrices = null
    ) {
        const activeConfiguration = this.getActiveConfiguration(type);
        if (activeConfiguration) {
            this.configurators[type] = {
                Configuration: activeConfiguration,
                Current: this.setDefaults(new activeConfiguration(startState), !startState),
                Edit: edit,
                Default: this.setDefaults(new activeConfiguration(startState), !startState),
                conf: type,
                copiedPosition: false,
                measurementsAttachments: [],
                offerConfigurator: false,
                editedPositionPrices
            };
        }
    }

    loadConfiguratorConfiguration<U extends ConfigurationType>(
        type: U,
        startState?:
            | InstanceType<ConfigurationsService['configurations'][U]['active']>
            | InstanceType<ConfigurationsService['configurations'][U]['simple']>,
        edit = false,
        editedPositionPrices = null
    ) {
        const activeConfiguration = this.getActiveConfiguration(type);
        if (activeConfiguration) {
            this.configurators[type].Configuration = activeConfiguration;
            this.configurators[type].Current = this.setDefaults(new activeConfiguration(startState), !startState);
            this.configurators[type].Default = this.setDefaults(new activeConfiguration(startState), !startState);
            this.configurators[type].editedPositionPrices = editedPositionPrices;
            this.configurators[type].Edit = edit;
        }
    }
}
