import { ConfiguratorAction, ConfiguratorActionTypes } from './root.actions';
import {
    initialOptions,
    SharedActionTypes,
    SharedAction,
    UiState,
    ConfiguratorState,
    ConfiguratorRootState,
    ConfigurationType,
    isObject,
    Step,
} from '@icc/configurator/shared';
import { IIccConfig } from '@icc/config';

export const initialUiState: UiState = {
    options: initialOptions,
    currency: null,
    steps: [],
    openedPages: [],
    closedPages: [],
    parentConfigurationType: null,
    messages: [],
    openedFilterSheets: [],
    closedFilterSheets: [],
    loaded: false,
    fullscreen: false,
    previewData: {
        isPreviewEnabled: false,
        drawData: null
    },
    colorStepMode: 'simple',
    systemStepMode: 'list',
    lightsGlazingStepMode: 'list',
    savingButton: true,
    returnButton: 'OK',
    additionalReturnButtons: [],
    activeSearch: false,
    searchValue: '',
};

export const initialState: ConfiguratorState = {
    uiState: initialUiState,
};

export const initialRootState: ConfiguratorRootState = {
    configurator: initialState,
};

export function uiStateReducer(
    state: UiState = initialUiState,
    action: ConfiguratorAction | SharedAction
): UiState {
    switch (action.type) {
        case SharedActionTypes.ConfiguratorLoaded: {
            state = {
                ...state,
                options: action.payload.options,
                steps: action.payload.steps,
                selectedStep: getDefaultStep(action.payload.steps, action.payload.options.startStep, action.payload.options.config, action.payload.options.type),
                loaded: true,
                additionalReturnButtons: Array.isArray(action.payload.options.additionalReturnButtons) ? action.payload.options.additionalReturnButtons : [],
                savingButton: true,
                currency: action.payload.options.currency
            };
            break;
        }
        case ConfiguratorActionTypes.ResetConfigurator: {
            state = {
                ...initialUiState
            };
            break;
        }
        case SharedActionTypes.OpenModalConfigurator: {
            state = {
                ...state,
                parentConfigurationType: 'window',
                steps: []
            };
            break;
        }
        case SharedActionTypes.CloseModalConfigurator: {
            state = {
                ...state,
                parentConfigurationType: null,
                steps: []
            };
            break;
        }
        case SharedActionTypes.SwitchStep: {
            const steps = [
                ...state.steps.map(step => {
                    return step.id === action.payload.nextStepId ? {
                        ...step,
                        visited: step.visited + 1
                    } : step;
                })
            ];
            state = {
                ...state,
                steps: [
                    ...steps
                ],
                selectedStep: action.payload.nextStepId,
                openedPages: [],
            };
            break;
        }
        case SharedActionTypes.OpenPage: {
            state = {
                ...state,
                openedPages: [...state.openedPages, {
                    component: action.payload.page,
                    data: action.payload.data || {},
                    id: action.payload.id
                }],
            };
            break;
        }
        case SharedActionTypes.ClosePage: {
            state = {
                ...state,
                openedPages: [...state.openedPages.slice(0, -1)],
                closedPages: [
                    ...state.closedPages,
                    ...state.openedPages.slice(-1).map(page => ({
                        id: page.id,
                        result: action.payload && action.payload.result
                    }))
                ]
            };
            break;
        }
        case SharedActionTypes.CloseAllPages: {
            state = {
                ...state,
                openedPages: [],
                closedPages: [
                    ...state.closedPages,
                    ...state.openedPages.map(page => ({
                        id: page.id,
                        result: 'close_all_pages'
                    }))
                ]
            };
            break;
        }
        case SharedActionTypes.OpenFilterSheet: {
            state = {
                ...state,
                openedFilterSheets: [...state.openedFilterSheets, {
                    component: action.payload.page,
                    data: action.payload.data || {},
                    id: action.payload.id
                }],
            };
            break;
        }
        case SharedActionTypes.CloseFilterSheet: {
            state = {
                ...state,
                openedFilterSheets: [...state.openedFilterSheets.slice(0, -1)],
                closedFilterSheets: [
                    ...state.closedFilterSheets,
                    ...(state.openedFilterSheets.slice(-1).map(page => ({
                        id: page.id,
                        result: action.payload && action.payload.result
                    })))
                ]
            };
            break;
        }
        case SharedActionTypes.EnableStep: {
            const steps = [...state.steps.map(s => ({
                ...s
            }))];
            const stepToEnable = steps.find(step => step.id === action.payload);
            if (stepToEnable) {
                stepToEnable.enable = true;
                state = {
                    ...state,
                    steps: [
                        ...steps
                    ],
                };
            }
            break;
        }
        case SharedActionTypes.DisableStep: {
            const steps = [...state.steps.map(s => ({
                ...s
            }))];
            const stepToDisable = steps.find(step => step.id === action.payload);
            if (stepToDisable) {
                stepToDisable.enable = false;
                state = {
                    ...state,
                    steps: [
                        ...steps,
                    ],
                };
            }
            break;
        }
        case SharedActionTypes.SwitchEnableStep: {
            const steps = [...state.steps.map(s => ({
                ...s
            }))];
            const stepToEnable = steps.find(step => step.id === action.payload);
            if (stepToEnable) {
                stepToEnable.enable = !stepToEnable.enable;
                state = {
                    ...state,
                    steps: [
                        ...steps
                    ],
                };
            }
            break;
        }
        case SharedActionTypes.SwitchEnableRxSteps: {
            if (Object.keys(action.payload).length > 0) {
                const steps = [...state.steps.map(s => ({
                    ...s,
                    enableRx: action.payload[s.id] != null ? action.payload[s.id] : s.enableRx
                }))];
                state = {
                    ...state,
                    steps: [
                        ...steps
                    ],
                };
            }
            break;
        }
        case SharedActionTypes.OpenMessage: {
            let messages = [...state.messages.map(s => ({
                ...s
            }))];
            const exist = messages.find(m => m.message === action.payload.message);
            if (exist && exist.dismissed) {
                exist.dismissed = false;
            } else if (!exist) {
                messages = [...state.messages, action.payload];
            }
            state = {
                ...state,
                messages,
            };
            break;
        }
        case SharedActionTypes.DismissMessage: {
            let messages = [...state.messages.map(s => ({
                ...s
            }))];
            if (action.payload) {
                messages = messages.filter(m => action.payload !== m.message);
            } else {
                const notDissmised = messages.filter(m => !m.dismissed);
                if (notDissmised.length > 0) {
                    notDissmised[notDissmised.length - 1].dismissed = true;
                }
            }
            state = {
                ...state,
                messages,
            };
            break;
        }
        case SharedActionTypes.EnableFullscreen: {
            state = {
                ...state,
                fullscreen: true
            }
            break;
        }
        case SharedActionTypes.TogglePreview: {
            state = {
                ...state,
                previewData: action.payload
            }
            break;
        }
        case SharedActionTypes.DisableFullscreen: {
            state = {
                ...state,
                fullscreen: false
            }
            break;
        }
        case SharedActionTypes.ChangedColorsStepMode: {
            state = {
                ...state,
                colorStepMode: action.payload
            }
            break;
        }
        case SharedActionTypes.ChangedSystemStepMode: {
            state = {
                ...state,
                systemStepMode: action.payload
            }
            break;
        }
        case SharedActionTypes.ChangedLightsGlazingStepMode: {
            state = {
                ...state,
                lightsGlazingStepMode: action.payload
            }
            break;
        }
        case SharedActionTypes.DisableSavingButton: {
            state = {
                ...state,
                savingButton: false
            }
            break;
        }
        case SharedActionTypes.EnableSavingButton: {
            state = {
                ...state,
                savingButton: true
            }
            break;
        }
        case SharedActionTypes.ChangeReturnButton: {
            state = {
                ...state,
                returnButton: action.payload
            }
            break;
        }
        case SharedActionTypes.EnableSearch: {
            state = {
                ...state,
                activeSearch: true
            }
            break;
        }
        case SharedActionTypes.DisableSearch: {
            state = {
                ...state,
                activeSearch: false
            }
            break;
        }
        case SharedActionTypes.StopSearch: {
            state = {
                ...state,
                searchValue: ''
            }
            break;
        }
        case SharedActionTypes.UpdateSearch: {
            state = {
                ...state,
                searchValue: action.payload
            }
            break;
        }
        case ConfiguratorActionTypes.ChangeCurrency: {
            state = {
                ...state,
                options: {
                    ...state.options,
                    currency: action.payload
                }
            }
            break;
        }
    }
    return state;
}

export function configuratorRootReducer(
    state: ConfiguratorRootState = initialRootState,
    action: ConfiguratorAction
): ConfiguratorRootState {
    state = {
        ...state,
        configurator: {
            ...state.configurator,
            uiState: uiStateReducer(state.configurator.uiState, action),
        },
    };
    return state;
}

export function configuratorReducer(
    state: ConfiguratorState = initialState,
    action: ConfiguratorAction
): ConfiguratorState {
    state = {
        ...state,
        uiState: uiStateReducer(state.uiState, action),
    };
    return state;
}


function getDefaultStep(steps: Step[], mode: 'DEFAULT' | 'FIRST' | undefined, IccConfig: IIccConfig, type: ConfigurationType) {
    if (steps) {
        switch (mode) {
            case 'DEFAULT':
                const version = IccConfig.Configurators[type].version || 1;
                let defaultStep = IccConfig.Configurators[type].defaultStep;
                if (isObject(defaultStep)) {
                    defaultStep = defaultStep[version];
                }
                return defaultStep;
            case 'FIRST':
            default:
                return steps[0].id;
        }
    }
}
