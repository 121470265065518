import { Injectable } from '@angular/core';
import { IccColor, IccConfiguratorData, IccColorGroup } from '@icc/common/data-types';
import { EventBusService } from '@icc/common/event-bus.service';
import { ModalService, IssuesService } from '@icc/configurator/shared';
import { ConfiguratorsDataService, LoadedConfiguratorsDataValue } from '@icc/common/configurators/configurators-data.service';
import { WindowActiveConfiguration } from '@icc/common/configurations/WindowActiveConfiguration';
import { Common, IssueLevel, ValidationService, core, TranslateService } from '@icc/common';
import { BrowserProfilesService } from './profiles.service';
import { ColorMappingService } from '@icc/common/colors/colors-mapping.service';
import { ThresholdsColorsComponent } from './thresholds-colors-page/thresholds-colors.component';

@Injectable({
    providedIn: 'root',
})
export class ThresholdColorsService {
    colors: IccColor[] = [];
    colorGroups: IccColorGroup[]= [];
    data: LoadedConfiguratorsDataValue | null = null;

    constructor(
        private eventBusService: EventBusService,
        private modalService: ModalService,
        private issuesService: IssuesService,
        private profilesService: BrowserProfilesService,
        private validationService: ValidationService,
        private colorMappingService: ColorMappingService,
        private translateService: TranslateService,
        private configuratorsDataService: ConfiguratorsDataService,
    ) {
        eventBusService.subscribe<LoadedConfiguratorsDataValue>(['initializedConfigurator'], data => {
            if (data.activeConfiguration && WindowActiveConfiguration.is(data.activeConfiguration)) {
                this.loadColors(data.activeConfiguration as WindowActiveConfiguration);
            }
        });

        eventBusService.subscribe(['setLowThreshold', 'unsetLowThreshold'], data => {
            if (
                WindowActiveConfiguration.is(data.activeConfiguration)
                && WindowActiveConfiguration.is(data.defaultConfiguration)
            ) {
                this.validateColorsAndFixIsses(
                    data.activeConfiguration as WindowActiveConfiguration,
                    data.defaultConfiguration as WindowActiveConfiguration
                );
            }
        });

        eventBusService.subscribe('setConstructionColor', data => {
            if (
                WindowActiveConfiguration.is(data.activeConfiguration)
                && WindowActiveConfiguration.is(data.defaultConfiguration)
            ) {
                this.setMatchingColorsIfDefault(
                    data.activeConfiguration as WindowActiveConfiguration,
                    data.defaultConfiguration as WindowActiveConfiguration
                );
            }
        });

        this.eventBusService.subscribe<LoadedConfiguratorsDataValue>('loadedThresholdColors', data => {
            this.validateColorsAndFixIsses(data.activeConfiguration as WindowActiveConfiguration, data.defaultConfiguration as WindowActiveConfiguration);
        });
    }

    getThresholdColors(conf: WindowActiveConfiguration) {
        const matchingGroups = (this.getThresholdColorGroups(conf) || []).map(group => Number(group.id));
        return this.colors
            .filter(
                color =>
                    color.groups
                    && color.groups
                        .map(Number)
                        .some(
                            groupId =>
                                matchingGroups.includes(groupId)
                        )
            );
    }

    getThresholdColorGroups(conf: WindowActiveConfiguration) {
        let filter: (color?: IccColorGroup) => boolean = group => true;
        const thresholdId = this.profilesService.getUsedThresholdId(conf)
        if (
            this.profilesService.profilesPrices
            && thresholdId
            && this.profilesService.profilesPrices[thresholdId]
            && this.profilesService.profilesPrices[thresholdId][
                WindowActiveConfiguration.is(conf) ? conf.System.id : 'default'
            ]
            && this.profilesService.profilesPrices[thresholdId][
                WindowActiveConfiguration.is(conf) ? conf.System.id : 'default'
            ]['threshold']
        ) {
            filter = group =>
                group
                && group.systems
                && ((WindowActiveConfiguration.is(conf) && group.systems.includes(conf.System.id))
                    || !WindowActiveConfiguration.is(conf))
                && group.target.includes('show')
                && this.profilesService.profilesPrices[thresholdId][
                    WindowActiveConfiguration.is(conf) ? conf.System.id : 'default'
                ]['threshold'].some(
                    (o: any) => Number(o.colorGroup) === Number(group.id) || Number(o.colorGroupOut) === Number(group.id)
                );
        }
        return this.colorGroups.filter(filter);
    }

    getDefaultColor(conf: WindowActiveConfiguration) {
        const availColors = this.getThresholdColors(conf);
        const windowColorId = this.colorMappingService.getWindowColorId(conf, 'inner');
        const matchedColors = this.colorMappingService.getColors(
            windowColorId,
            'window',
            'window'
        );
        const windowColors = matchedColors
            .map(m => availColors.filter(c => Number(c.id) === Number(m))[0])
            .filter(m => m);
        let color;
        if (Common.isArray(windowColors) && Common.isDefined(windowColors[0])) {
            color = core.copy(windowColors[0]);
        } else if (Common.isArray(availColors) && Common.isDefined(availColors[0])) {
            color = core.copy(availColors[0]);
        } else {
            return null;
        }
        color.isDefault = true;
        return color;
    }

    setDefaultColor(conf: WindowActiveConfiguration, defaultConf: any) {
        const color = this.getDefaultColor(conf);
        if (color) {
            this.setColor(color, conf, defaultConf);
        }
    }

    setColor(color: IccColor, conf: WindowActiveConfiguration, defaultConf: any) {
        conf.thresholdColor = { ...color };
        defaultConf.thresholdColor = color;
        this.eventBusService.post({
            key: 'setThresholdColor',
            value: {
                colorId: color.id,
            },
        });
    }

    validColor(conf: WindowActiveConfiguration, defaultConf: any) {
        const availColors = this.getThresholdColors(conf);
        const colorAvailable = availColors.some(
            el => conf.thresholdColor && el.id === conf.thresholdColor.id && el.RAL === conf.thresholdColor.RAL
        );
        return colorAvailable;
    }

    setMatchingColorsIfDefault(conf: WindowActiveConfiguration, defaultConf: any) {
        const pauseId = this.eventBusService.pause(['setThresholdColor']);
        if (conf.thresholdColor && (!conf.thresholdColor.id || conf.thresholdColor.isDefault)) {
            this.setDefaultColor(conf, defaultConf);
        }
        this.eventBusService.resume(['setThresholdColor'], pauseId);
    }

    validateColors(conf: WindowActiveConfiguration, defaultConf: any) {
        this.validationService.indeterminate(conf, 'thresholdColor');
        if (this.validationService.isValidElements(conf, ['system', 'loadedThresholdColor'])) {
            const valid = this.validColor(conf, defaultConf);
            if (!valid) {
                this.validationService.invalid(conf, 'thresholdColor');
                this.issuesService.simpleRegister(
                    'invalid-thresholds-colors',
                    'Niepoprawne kolory progów',
                    this.translateService.instant('WINDOW|Niepoprawne kolory progów'),
                    conf,
                    {
                        level: IssueLevel.ERROR,
                        blockStepsAfter: null,
                    }
                );
            } else {
                Object.assign(conf, this.validationService.valid(conf, 'thresholdColor'));
                this.issuesService.unregister('invalid-thresholds-colors', conf);
            }
        }
    }

    validateColorsAndFixIsses(conf: WindowActiveConfiguration, defaultConf: any) {
        this.validationService.indeterminate(conf, 'thresholdColor');
        if (
            this.data
            && !this.colorGroups.length
            && !this.colors.length
        ) {
            this.loadColors(conf);
        }
        if (
            this.validationService.isValidElements(conf, ['system', 'loadedThresholdColor'])
            && this.profilesService.getUsedThresholdId(conf)
        ) {
            const pauseId = this.eventBusService.pause(['setThresholdColor']);
            try {
                if (!this.validColor(conf, defaultConf)) {
                    this.validationService.invalid(conf, 'thresholdColor');
                    this.setDefaultColor(conf, defaultConf);
                } else {
                    Object.assign(conf, this.validationService.valid(conf, 'thresholdColor'));
                }
            } finally {
                this.eventBusService.resume(['setThresholdColor'], pauseId);
            }
        }
    }

    openModal(conf: WindowActiveConfiguration, defaultConf: any) {
        this.modalService
            .open({
                pageComponent: ThresholdsColorsComponent,
                resolve: {
                    colors: () => this.getThresholdColors(conf),
                    colorGroups: () => this.getThresholdColorGroups(conf),
                    selColor: () => conf.thresholdColor
                },
            })
            .result.then(selectedColor => {
                if (Common.isDefined(selectedColor)) {
                    this.setColor(selectedColor, conf, defaultConf);
                }
            });
    }

    private loadColors(conf: WindowActiveConfiguration) {
        this.data = this.configuratorsDataService.data;
        if (!conf) {
            return;
        }
        this.validationService.indeterminate(conf, 'loadedThresholdColor');

        if ((this.data.windowColorsAll) && this.data.windowColorGroups) {
            this.colors = this.data.windowColorsAll || [];
            this.colorGroups = this.data.windowColorGroups || [];
            Object.assign(conf, this.validationService.valid(conf, 'loadedThresholdColor'));
            this.eventBusService.post({
                key: 'loadedThresholdColors',
                value: null,
                conf
            });
        } else {
            this.validationService.invalid(conf, 'loadedThresholdColor');
        }
    }
}
