import { Injectable } from '@angular/core';
import { ConfigurationsService, ProfileSetsService, TranslateService } from '@icc/common';
import { DoorActiveConfiguration } from '@icc/common/configurations/DoorActiveConfiguration';
import { InfoService } from '@icc/helpers';
import { ColorsService } from '@icc/legacy/configurator/steps/window/colors/colors.service';
import { FillingsService } from '@icc/legacy/configurator/steps/window/glazings/fillings.service';
import { WarmEdgesService } from '@icc/legacy/configurator/steps/window/glazings/warm-edges.service';
import { HandlesService } from '@icc/legacy/configurator/steps/window/handles/handles.service';
import { LocksService } from '../../../../../apps/legacy-icc/src/app/configurator/steps/door/locks/locks.service';
import { BrowserProfileSetsService } from './profiles/profile-sets.service';

@Injectable({
    providedIn: 'root',
})
export class DefaultConfigurationsService {
    constructor(
        private configurationsService: ConfigurationsService<'window'>,
        private fillingsService: FillingsService,
        private warmEdgeService: WarmEdgesService,
        private translateService: TranslateService,
        private infoService: InfoService,
        private handlesService: HandlesService,
        private locksService: LocksService,
        private colorsService: ColorsService,
        private profileSetsService: ProfileSetsService,
        private browserProfileSetsService: BrowserProfileSetsService
    ) {}

    isDefaultConfigurationAvailable(conf = this.configurationsService.conf.Current) {
        return (
            conf.System?.default_market_configuration &&
            (conf.System.default_market_configuration?.glass_type_id ||
                conf.System.default_market_configuration?.window_warm_edge_id ||
                conf.System.default_market_configuration?.window_handler_id ||
                conf.System.default_market_configuration?.single_door_lock_id ||
                conf.System.default_market_configuration?.double_door_lock_id ||
                conf.System.default_market_configuration?.window_color_id)
        );
    }

    setDefaultConfigurations(
        conf = this.configurationsService.conf.Current,
        defaultConf = this.configurationsService.conf.Default
    ) {
        if (!conf.System?.default_market_configuration) return;

        if (conf.System.default_market_configuration?.window_color_id) {
            this.colorsService.setDefaults(conf, defaultConf, true);
        }

        if (conf.System.default_market_configuration?.profile_set_id) {
            const defaultProfileSet = this.browserProfileSetsService.getDefaultSet(
                conf,
                defaultConf
            );
            if (defaultProfileSet) {
                this.browserProfileSetsService.setSet(conf, defaultConf, defaultProfileSet, true);
            }
        }

        if (conf.System.default_market_configuration?.glass_type_id) {
            this.fillingsService.setDefaultGlassForSystem(conf, defaultConf);
        }

        if (conf.System.default_market_configuration?.window_warm_edge_id) {
            this.warmEdgeService.setDefaultWarmEdge(conf, defaultConf, false, true);
        }

        if (conf.System.default_market_configuration?.window_handler_id) {
            this.handlesService.setHandleType(conf.HandleType, () => true, conf, defaultConf, true);
        }

        if (
            conf.System.default_market_configuration?.single_door_lock_id ||
            conf.System.default_market_configuration?.double_door_lock_id
        ) {
            this.locksService.setDefaultLock(
                conf as DoorActiveConfiguration,
                defaultConf as DoorActiveConfiguration
            );
        }
    }

    confirmSettingDefaultConfiguration() {
        this.infoService.openConfirmModal(
            this.translateService.instant('WINDOW|Ustawienia domyślne'),
            this.translateService.instant(
                'WINDOW|Czy zastosować ustawienia domyślne dla wybranej linii produktowej? Obecne ustawienia zostaną zresetowane.'
            ),
            [
                {
                    name: this.translateService.instant('INTERFACE|Tak'),
                    callback: () => {
                        this.setDefaultConfigurations();
                    },
                },
                {
                    name: this.translateService.instant('INTERFACE|Nie'),
                    callback: () => {
                        return;
                    },
                },
            ]
        );
    }
}
