import {core} from '../app/helpers';
import { TokenService } from './token.service';
import {APP_CONFIG, AppConfig, AppConfigFactory} from '@icc/common/config';;
import {HttpClient} from '@angular/common/http';
import {Location} from '@angular/common';
import {Injectable, Inject} from '@angular/core';
import { OnlineStatusService } from '../sync/online-status.service';
import { UserService } from '@icc/common/user.service';
import { LangService } from '../app/lang.service';
import { DatabaseManager, DatabaseManagerToken} from '@icc/helpers/browser';
import {ConfiguratorsDataService} from '@icc/common/configurators/configurators-data.service';
import { BrowserConfiguratorsDataService } from '@icc/legacy/configurator/configurators-data.service';
import {LicenseService} from '@icc/legacy/license/license.service';
import {DiscountsAndMultipliersService} from '@icc/price/b2b';;
import { StateService } from '@icc/common/state.service';
import { InfoService } from '@icc/helpers';

interface LoginData {
    token: string;
    redirect: string;
    message: string;
}

@Injectable()
export class AuthService {
    validMessage: string;

    constructor(
        private tokenService: TokenService,
        private httpClient: HttpClient,
        private location: Location,
        private onlineStatusService: OnlineStatusService,
        private userService: UserService,
        private langService: LangService,
        private infoFactory: InfoService,
        @Inject(APP_CONFIG) private config: AppConfigFactory,
        @Inject(DatabaseManagerToken) private databaseManager: DatabaseManager,
        private configuratorsDataService: ConfiguratorsDataService,
        private discountsAndMultipliersService: DiscountsAndMultipliersService,
        private licenseService: LicenseService,
        private stateService: StateService,
        @Inject('$rootScope') private $rootScope: ng.IRootScopeService
    ) {
        this.validMessage = '';
    }

    async login(email: string, password: string) {
        if (await this.onlineStatusService.checkOnline()) {
            const data = (await this.httpClient.post<LoginData>(`${this.config().EnvConfig.remoteHost || window.location.origin}/user/users/login`, {User: {email, pass: password}, iccAppVersion: this.config().IccConfig.Version}).toPromise());
            if (data.token) {
                this.tokenService.setToken(data.token);
                await this.userService.synchronizeUser();
                await this.userService.loadUser();

                const user = this.userService.get();
                this.langService.changeLangLogin(user.user_language);
                this.stateService.init();
                await this.databaseManager.initAll(user, this.config().machine, this.config());
                (<BrowserConfiguratorsDataService>this.configuratorsDataService).reload();
                this.discountsAndMultipliersService.reload();
                await this.licenseService.checkLicense();
            }
            if (data.redirect) {
                if (/^\/app\//.test(data.redirect) && navigator.userAgent.toLowerCase().indexOf('firefox') === -1) {
                    if (data.redirect === '/app/sync' && this.config().isNative) {
                        data.redirect = '/app/update';
                    }
                    this.location.go(data.redirect);
                    this.$rootScope.$apply();
                } else {
                    window.location.href = data.redirect;
                }
            }
            this.validMessage = '';
            if (data.message) {
                this.validMessage = data.message;
                this.infoFactory.showInfo(data.message, null);
                this.$rootScope.$apply();
            }
        } else {
            setTimeout(() => {
                this.location.go('/app/offline');
            });
        }
    }

    async logout() {
        this.tokenService.setToken(null);
        this.userService.setUser({});
        if (this.onlineStatusService.getStatus()) {
            const data = (await this.httpClient.get(`${this.config().EnvConfig.remoteHost || window.location.origin}/user/users/logout?xhr`).toPromise());
            if (data) {
                this.location.go('/app/login');
                this.$rootScope.$apply();
            }
        } else {
            setTimeout(() => {
                this.location.go('/app/offline');
                this.$rootScope.$apply();
            });
        }
    }

    getValidMessage() {
        return this.validMessage;
    }

    isLoggedIn() {
        return this.tokenService.getToken() != null;
    }

}
