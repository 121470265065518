import { Component, OnInit, Inject } from '@angular/core';
import { PageComponent, ICC_PAGE_DATA } from '@icc/helpers';
import { _, SharedFacade } from '@icc/configurator/shared';
import { iccListItem, iccListTab } from '@icc/configurator/ui';
import { WindowFacade } from '../+state/window.facade';
import { ColorsService } from '@icc/legacy/configurator/steps/window/colors/colors.service';
import { map } from 'rxjs/operators';
import { IccColor, IccColorGroup } from '@icc/common/data-types';

@Component({
    selector: 'icc-colors-page',
    templateUrl: './colors-page.component.html',
    styleUrls: ['./colors-page.component.scss'],
})
export class ColorsPageComponent extends PageComponent implements OnInit {
    public title = _('COLOR|Wybierz kolor');

    colors: iccListItem[] = [];
    colorGroups: iccListTab[] = [];
    selectedColorId = this.pageData.selectedColor
        ? this.pageData.selectedColor.id + '_' + Number(this.pageData.selectedColor.RAL)
        : null;
    selectedGroup: number | 'none' | undefined = 0;

    constructor(
        private sharedFacade: SharedFacade,
        @Inject(ICC_PAGE_DATA)
        private pageData: {
            colors: IccColor[];
            colorGroups: IccColorGroup[];
            selectedColor: IccColor;
        }
    ) {
        super();
    }

    ngOnInit() {
        const groups: IccColorGroup[] = this.pageData.colorGroups.filter(
            group => !group.brushed_alu_group
        );

        const filteredColors = this.pageData.colors.filter(c => groups.some(g => c.groups?.includes(g?.id)));
        const colors: IccColor[] = filteredColors?.length ? filteredColors : this.pageData.colors;

        this.colors = colors.map<iccListItem>(color => ({
            id: Number(color.id) + '_' + Number(color.RAL),
            title: color.name,
            description: color.code,
            color: '#' + color.color,
            imageUrl: '/files/color/' + color.color_img,
            tabs: (color.groups && color.groups.map(Number)) || [],
        }));

        this.colorGroups = groups.map<iccListTab>(group => ({
            id: Number(group.id),
            name: group.name,
        }));

        const selectedGroup =
            this.pageData.selectedColor.groups != null
            && this.pageData.selectedColor.groups.find(g =>
                this.colorGroups.map(gg => gg.id).includes(Number(g))
            );
        if (selectedGroup) {
            this.selectedGroup = Number(selectedGroup);
        } else if (groups.length > 0) {
            this.selectedGroup = Number(groups[0].id);
            if (this.pageData.selectedColor.groups) {
                const colorGroup = this.pageData.selectedColor.groups.find(id =>
                    groups.some(group => Number(group.id) === Number(id))
                );
                if (colorGroup) {
                    this.selectedGroup = Number(colorGroup);
                }
            }
        }
    }

    selectColor(item: iccListItem) {
        const [colorId, colorRal] = String(item.id).split('_');
        const color = this.pageData.colors.find(
            c => Number(colorId) === Number(c.id) && Boolean(Number(colorRal)) === c.RAL
        );
        if (color) {
            this.sharedFacade.closePage({
                color: color,
                group: this.selectedGroup,
                RAL: color.RAL,
            });
        }
    }
}
