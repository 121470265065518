import { PriceFunc, PriceElemsData, PriceSegment } from './Prices';
import { Injectable, Inject } from '@angular/core';
import { Common } from '@icc/common/Common';
import { core } from '@icc/common/helpers';


@Injectable()
export class PriceMosquitoService {
    constructor() {}

    /**
     * Wylicza cene siatki
     * @param  {number} height       Wysokosc konstrukcji
     * @param  {number} width        Szerokosc konstrukcji
     * @param  {number} priceId      Id cennika
     * @param  {object} PriceElems   Wycena
     * @param  {object} NoPriceElems Elementy bez wyceny
     * @param  {object} matrixes     Cennik
     * @param  {number} sashId       Id kwatery
     * @return {number}              Cena po dopłacie
     */
    @PriceFunc({
        shortName: 'mosquitoSize',
        data: {
            height: 'conf.Height',
            width: 'conf.Width',
            matrixes: 'data.mosquitoPrices',
            systemId: 'conf.System.id',
            type: 'conf.Type',
        },
    })
    priceForMosquito(
        { PriceStack, PriceElems, NoPriceCauses }: PriceElemsData,
        { height, width, matrixes, systemId, type },
        sashId?
    ): PriceSegment[] {
        let i = 0;
        let priceId = this.getPriceForMosquito(matrixes, systemId, type, NoPriceCauses);

        if (Common.isDefined(priceId) && priceId !== null) {
            if (Common.isArray(matrixes[priceId].data)) {
                for (i = 0; i < matrixes[priceId].data.length; i++) {
                    if (
                        matrixes[priceId].data[i].width_from <= width
                        && matrixes[priceId].data[i].width_to >= width
                        && matrixes[priceId].data[i].height_from <= height
                        && matrixes[priceId].data[i].height_to >= height
                    ) {
                        if (Common.isDefined(sashId)) {
                            PriceElems.mosquito.size.push({
                                price: matrixes[priceId].data[i].price,
                                priceId,
                                height,
                                width,
                                sashId,
                            });
                        } else {
                            PriceElems.size = {
                                price: matrixes[priceId].data[i].price,
                                priceId,
                                height,
                                width,
                            };
                        }
                        return [
                            {
                                type: 'mosquitoSize',
                                baseValue: parseFloat(matrixes[priceId].data[i].price),
                                value: parseFloat(matrixes[priceId].data[i].price),
                                valueType: 'value',
                                data: {
                                    priceId,
                                    height,
                                    width,
                                    sashId,
                                    systemId
                                },
                            },
                        ];
                    }
                }
                NoPriceCauses.push('no price in matrix');
                return [
                    {
                        type: 'mosquitoSize',
                        baseValue: null,
                        value: null,
                        valueType: 'value',
                        data: {},
                    },
                ];
            } else {
                NoPriceCauses.push('no matrix');
                return [
                    {
                        type: 'mosquitoSize',
                        baseValue: null,
                        value: null,
                        valueType: 'value',
                        data: {},
                    },
                ];
            }
        } else {
            NoPriceCauses.push('no matrix');
            return [
                {
                    type: 'mosquitoSize',
                    baseValue: null,
                    value: null,
                    valueType: 'value',
                    data: {},
                },
            ];
        }
    }

    /**
     * Cena za komara w skrzydle
     * @param  {Number} price           Cena
     * @param  {Array} sashes           Skrzydła
     * @param  {String} mosquitoPriceId Id ceny komara
     * @param  {Array} PriceElems       Skadowe ceny
     * @param  {Object} NoPriceCauses   Powody braku ceny
     * @param  {Object} matrixes        Macierze
     * @param  {Object} colorGroups     Grupy koloró
     * @param  {Object} system          System
     * @return {Number}                 Cena
     */
    @PriceFunc({
        shortName: 'mosquitoSash',
        data: {
            confMosquito: 'conf.mosquito',
            sashes: 'conf.Sashes',
            matrixes: 'data.mosquitoPrices',
            systemId: 'conf.mosquitoSystem',
            colorGroups: 'data.windowColorGroups',
            type: 'conf.Type',
        },
    })
    priceForMosquitoInSash(
        { PriceStack, PriceElems, NoPriceCauses }: PriceElemsData,
        { confMosquito, sashes, matrixes, colorGroups }
    ): PriceSegment[] {
        const priceSegments: PriceSegment[] = [];
        [confMosquito, ...sashes.map(s => ({...s.mosquito,  sashId: s.id}))]
            .filter(e => e)
            .map(mosquito => {
                if (mosquito && mosquito.System) {
                    priceSegments.push(
                        ...this.priceForMosquito(
                            { PriceStack, PriceElems, NoPriceCauses },
                            {
                                height: mosquito.Height,
                                width: mosquito.Width,
                                systemId: mosquito.System.id,
                                type: mosquito.Type,
                                matrixes,
                            },
                            mosquito.sashId
                        )
                    );
                    priceSegments.push(
                        ...this.suppMosquitoColor(
                            { PriceStack, PriceElems, NoPriceCauses },
                            {
                                colors: mosquito.Colors,
                                colorGroups,
                                systemId: mosquito.System.id,
                                typeId: mosquito.Type.id,
                            },
                            mosquito.sashId
                        )
                    );
                    priceSegments.push(
                        ...this.suppMosquitoMontage(
                            { PriceStack, PriceElems, NoPriceCauses },
                            {
                                montage: mosquito.Montage,
                                systemId: mosquito.System.id,
                            },
                            mosquito.sashId
                        )
                    );
                    priceSegments.push(
                        ...this.suppMosquitoProfile(
                            { PriceStack, PriceElems, NoPriceCauses },
                            {
                                profiles: mosquito.ProfilePositions,
                                type: mosquito.Type,
                                width: mosquito.Width,
                                height: mosquito.Height,
                                systemId: mosquito.System.id,
                            },
                            mosquito.sashId
                        )
                    );
                }
            });
        return priceSegments;
    }

    /**
     * Dopłata za kolor ramki siatki
     * @param  {number} price         Cena
     * @param  {object} PriceElems    Elementy składowe ceny
     * @param  {array}  NoPriceCauses Powody braku ceny
     * @param  {object} colors        Kolory
     * @param  {object} colorGroups   Grupy kolorow
     * @param  {object} systemId      Id wybranego systemu
     * @return {number}               Cena
     */
    @PriceFunc({
        shortName: 'mosquitoColor',
        data: {
            colors: 'conf.Colors',
            colorGroups: 'data.windowColorGroups',
            systemId: 'conf.System.id',
            typeId: 'conf.Type.id',
        },
    })
    suppMosquitoColor(
        { PriceStack, PriceElems, NoPriceCauses }: PriceElemsData,
        { colors, colorGroups, systemId, typeId },
        sashId?
    ): PriceSegment[] {
        if (Common.isDefined(colors.frame) && Common.isDefined(colors.frame.groups)) {
            const group = this.getMaxColorGroupFactor({
                colorGroups,
                color: colors.frame,
                systemId,
                typeId,
            });

            if (Common.isDefined(group)) {
                const supp = group;
                const value = supp.priceType === 'percent' ? 1 + supp.price / 100 : supp.price * 1;
                let valueCalculated = 0;
                if (supp.priceType === 'percent') {
                    const mosquitoSegments = PriceStack.filter(
                        segment =>
                            segment
                            && segment.type === 'mosquitoSize'
                            && (segment.data.sashId === sashId
                                || (segment.data.sashId === null && Common.isUndefined(sashId)))
                    );
                    if (mosquitoSegments.length > 0) {
                        valueCalculated = mosquitoSegments[0].value * (value - 1);
                    }
                }

                if (Common.isDefined(sashId)) {
                    PriceElems.mosquito.color.push({
                        price: supp.priceType === 'percent' ? valueCalculated : value,
                        priceAmount: supp.price,
                        priceType: supp.priceType,
                        colorGroup: group,
                        sashId,
                    });
                } else {
                    PriceElems.color = {
                        price: supp.priceType === 'percent' ? valueCalculated : value,
                        priceAmount: supp.price,
                        priceType: supp.priceType,
                        colorGroup: group,
                    };
                }
                return [
                    {
                        type: 'mosquitoColor',
                        baseValue: value,
                        value,
                        valueType: supp.priceType === 'percent' ? 'percent' : 'value',
                        data: {
                            priceAmount: supp.price,
                            priceType: supp.priceType,
                            colorGroup: group,
                            sashId,
                            systemId
                        },
                    },
                ];
            }
        }
        return [
            {
                type: 'mosquitoColor',
                baseValue: 0,
                value: 0,
                valueType: 'value',
                data: {},
            },
        ];
    }

    /**
     * Zwraca najwyższą dopłatę procentową dla koloru.
     * @param  {object} colorGroups Grupy kolorów
     * @param  {object} color       Kolor
     * @param  {string} prop        Nazwa pola zawierającego dopłatę.
     * @return {number}             Dopłata procentowa dla koloru
     */
    getMaxColorGroupFactor({ colorGroups = [], color, systemId, typeId }) {
        let colorFactor = null;
        colorGroups = colorGroups.filter(
            el =>
                Common.isArray(el.systems)
                && el.systems.indexOf(systemId) > -1
                && el.target.indexOf('price_mesh') > -1
                && ((Common.isArray(el.mosquitoTypes)
                    && el.mosquitoTypes.length
                    && el.mosquitoTypes.indexOf(typeId) > -1)
                    || (!el.mosquitoTypes && !typeId))
        );
        if (Common.isArray(color.groups)) {
            for (let i = 0; i < color.groups.length; i++) {
                const group = core.fIdO(colorGroups, color.groups[i]) as any;
                if (Common.isDefined(group) && group.prices_extra_mosquito) {
                    const pricesExtra = group.prices_extra_mosquito
                        ? core.parseJson(group.prices_extra_mosquito)
                        : [];
                    if (
                        colorFactor == null
                        || (pricesExtra.priceType === 'percent'
                            && colorFactor.priceType !== 'percent')
                        || (pricesExtra.priceType === colorFactor.priceType
                            && pricesExtra.price > colorFactor.price)
                    ) {
                        colorFactor = pricesExtra;
                    }
                }
            }
        }
        if (colorFactor == null) {
            return NaN;
        }
        return colorFactor;
    }

    getRALGroup(colorGroups) {
        colorGroups = core.objToArray(colorGroups);
        const RALGroup = core.fIdO<any>(colorGroups, 'ral', 'code');
        return RALGroup;
    }

    /**
     * Dopłata za opcję montażu
     * @param  {number} price         Cena
     * @param  {object} PriceElems    Elementy składowe ceny
     * @param  {array}  NoPriceCauses Powody braku ceny
     * @param  {object} montage       Rodzja montażu
     * @return {number}               Cena
     */
    @PriceFunc({
        shortName: 'mosquitoMontage',
        data: {
            montage: 'conf.Montage',
            systemId: 'conf.System.id',
        },
    })
    suppMosquitoMontage(
        { PriceStack, PriceElems, NoPriceCauses }: PriceElemsData,
        { montage, systemId },
        sashId?
    ): PriceSegment[] {
        if (Common.isObject(montage) && Common.isDefined(montage.id)) {
            if (Common.isObject(montage)) {
                const supp = montage.price * 1;

                PriceElems.montage = {
                    price: supp,
                    montage: montage.id,
                };
                return [
                    {
                        type: 'mosquitoMontage',
                        baseValue: supp,
                        value: supp,
                        valueType: 'value',
                        data: {
                            montage: montage.id,
                            systemId,
                            sashId,
                        },
                    },
                ];
            }
        }
        return [
            {
                type: 'mosquitoMontage',
                baseValue: 0,
                value: 0,
                valueType: 'value',
                data: {},
            },
        ];
    }

    /**
     * Dopłata za profil dodatkowy
     * @param  {number} price         Cena
     * @param  {object} PriceElems    Elementy składowe ceny
     * @param  {array}  NoPriceCauses Powody braku ceny
     * @param  {object} montage       Rodzja montażu
     * @param  {number} width         Szerokosc konstrukcji
     * @param  {number} height        Wysokosc konstrukcji
     * @return {number}               Cena
     */
    @PriceFunc({
        shortName: 'mosquitoProfile',
        data: {
            profiles: 'conf.ProfilePositions',
            type: 'conf.Type',
            width: 'conf.Width',
            height: 'conf.Height',
            systemId: 'conf.System.id',
        },
    })
    suppMosquitoProfile(
        { PriceStack, PriceElems, NoPriceCauses }: PriceElemsData,
        { profiles, type, width, height, systemId },
        sashId?
    ): PriceSegment[] {
        if (Common.isObject(profiles) && Common.isObject(type) && Common.isDefined(type.id)) {
            let len = 0;
            for (const i in profiles) {
                if (profiles[i] && (i === 'up' || i === 'down')) {
                    len += width / 1000;
                } else if (profiles[i] && (i === 'left' || i === 'right')) {
                    len += height / 1000;
                }
            }

            const supp = type.profile_price * len;

            PriceElems.profile = {
                price: supp,
                type: type.id,
                profiles,
                length: len,
            };
            return [
                {
                    type: 'mosquitoProfile',
                    baseValue: supp,
                    value: supp,
                    valueType: 'value',
                    data: {
                        type: type.id,
                        profiles,
                        length: len,
                        sashId,
                        systemId
                    },
                },
            ];
        }

        return [
            {
                type: 'mosquitoProfile',
                baseValue: 0,
                value: 0,
                valueType: 'value',
                data: {},
            },
        ];
    }

    /**
     * Zwraca id cennika dla wybranegoi systemu i typu siatki
     * @param  {object} mosquitoPrices Lista z cennikami
     * @param  {object} systemId       Id wybranego systemu siatek
     * @param  {object} type           Wybrany typ siatek
     * @param  {array}  NoPriceCauses  Lista braów w liczeniu cen
     * @return {string}                Id znalezionego cennika
     */
    getPriceForMosquito(mosquitoPrices, systemId, type, NoPriceCauses) {
        let i;
        if (Common.isObject(mosquitoPrices)) {
            for (i in mosquitoPrices) {
                if (
                    systemId === mosquitoPrices[i].window_line_id
                    && (Common.isUndefined(type.id)
                        || type.id === mosquitoPrices[i].mosquito_type_id)
                ) {
                    return mosquitoPrices[i].id;
                }
            }
        }
        NoPriceCauses.push('no prices for system');
    }
}
