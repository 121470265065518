import { Injectable, Inject } from '@angular/core';
import { DiscountsAndMultipliersService } from '@icc/price/b2b';
import { TranslateService } from '@icc/common/translate.service';
import { APP_CONFIG, AppConfigFactory } from '@icc/common/config';
import { OfferSummaryServiceStatic } from '@icc/common/offers/offer-summary.service';
import { core } from '@icc/common/helpers';
import { currencyExchange } from '@icc/helpers';

@Injectable()
export class MontagesService {
    public loaded = false;

    constructor(
        private discountsAndMultipliersService: DiscountsAndMultipliersService,
        private translateService: TranslateService,
        @Inject(APP_CONFIG) private config: AppConfigFactory,
    ) {}

    static updateMontages(offer, groups, allPositions, user, market, IccConfig, autoUpdateOffer = true) {
        const currency = core.parseJson(offer.currency);
        offer.client_montages_price_before_discount = 0;
        offer.dealer_producer_montages_price_before_discount = 0;
        offer.dealer_montages_price_before_discount = 0;
        for (const key in offer.montages.groups) {
            if (offer.montages.groups.hasOwnProperty(key)) {
                const group = offer.montages.groups[key];
                MontagesService.calculateOwnMontageCosts(group, offer, 1);
                MontagesService.calculateProducerMontageCosts(
                    group,
                    offer,
                    offer.montages.multiplier
                );
                if (offer.montages.montage) {
                    if (group.if_producer_montage && group.if_own_montage) {
                        offer.dealer_montages_price_before_discount += group.producer_montage_cost;
                        offer.client_montages_price_before_discount += group.own_montage_cost;
                        offer.dealer_producer_montages_price_before_discount +=
                            group.own_montage_cost;
                    } else if (group.if_own_montage) {
                        offer.client_montages_price_before_discount += group.own_montage_cost;
                    } else if (group.if_producer_montage) {
                        offer.dealer_producer_montages_price_before_discount +=
                            group.producer_montage_cost;
                        offer.dealer_montages_price_before_discount += group.producer_montage_cost;
                        offer.client_montages_price_before_discount += group.producer_montage_cost;
                    }
                }
            }
        }

        if (autoUpdateOffer) {
            return OfferSummaryServiceStatic.autoUpdateOffer(
                offer.tmp_id,
                undefined,
                undefined,
                undefined,
                user,
                offer,
                IccConfig,
                allPositions,
                market,
                Boolean(Number(offer.order))
            );
        } else {
            return false;
        }
    }

    static matchMontagePriceSet(montage, position) {
        let price_data = JSON.parse(montage.Montage.prices_data);
        let price_set = {
            pp_price: 0,
            mb_price: 0,
            m2_price: 0,
        };

        price_data.forEach(set => {
            const weight_matches =
                position.weight >= Number(set.weight_from || 0)
                && position.weight <= (Number(set.weight_to) || Infinity);
            const circuit_matches =
                position.circuit >= parseInt(set.circuit_from || 0)
                && position.circuit <= (parseInt(set.circuit_to) || Infinity);
            const width_matches =
                position.width >= parseInt(set.width_from || 0)
                && position.width <= (parseInt(set.width_to) || Infinity);
            if (weight_matches && circuit_matches && width_matches) {
                price_set.pp_price = Number(set.pp_price || 0);
                price_set.mb_price = Number(set.mb_price || 0);
                price_set.m2_price = Number(set.m2_price || 0);
            }
        });
        return price_set;
    }

    static calculateOwnMontageCosts(group, offer, multiplier) {
        const currency = offer.currency && typeof offer.currency === 'object'
        ? offer.currency
        : JSON.parse(offer.currency);

        group.own_montage_cost = 0;
        if (offer.montages.montage_allowed && group.own_montages) {
            if (!group.if_own_montage) {
                group.own_montage_cost = 0;
            } else {
                group.positions.forEach(position => {
                    position.own_montage_cost = 0;

                    group.own_montages
                        .filter(montage => montage.selected)
                        .forEach(montage => {
                            const price_set = MontagesService.matchMontagePriceSet(
                                montage,
                                position
                            );
                            if (position.if_modified) {
                                position.modified_own_montage_cost = parseFloat(
                                    parseFloat(
                                        (position.modified_own_montage_cost || 0)
                                            .toString()
                                            .replace(',', '.')
                                    ).toFixed(2)
                                );
                                position.own_montage_cost = position.modified_own_montage_cost;
                            } else {
                                let ownMontageCost = 0;
                                ownMontageCost +=
                                    position.area * price_set.m2_price * position.pieces;
                                ownMontageCost +=
                                    (position.circuit / 1000)
                                    * price_set.mb_price
                                    * position.pieces;
                                ownMontageCost += position.pieces * price_set.pp_price;
                                position.own_montage_cost += currencyExchange(ownMontageCost, currency);
                            }
                        });
                });
                const positions_cost_array = group.positions.map(function(position) {
                    return position.own_montage_cost;
                });
                group.own_montage_cost = positions_cost_array.reduce((a, b) => a + b, 0);
            }
        }
    }

    static calculateProducerMontageCosts(group, offer, multiplier = 1) {
        const currency = offer.currency && typeof offer.currency === 'object'
        ? offer.currency
        : JSON.parse(offer.currency);
        group.producer_montage_cost = 0;
        if (offer.montages.montage_allowed && group.producer_montages) {
            if (!group.if_producer_montage) {
                group.producer_montage_cost = 0;
            } else {
                group.positions.forEach(position => {
                    position.producer_montage_cost = 0;
                    group.producer_montages
                        .filter(montage => montage.selected)
                        .forEach(montage => {
                            const price_set = MontagesService.matchMontagePriceSet(
                                montage,
                                position
                            );
                            if (price_set) {
                                position.producer_montage_cost +=
                                    position.area * price_set.m2_price * position.pieces;
                                position.producer_montage_cost +=
                                    (position.circuit / 1000)
                                    * price_set.mb_price
                                    * position.pieces;
                                position.producer_montage_cost +=
                                    position.pieces * price_set.pp_price;
                            }
                        });
                });
                const positions_cost_array = group.positions.map(
                    position => position.producer_montage_cost
                );
                group.producer_montage_cost = positions_cost_array.reduce((a, b) => a + b, 0);
                group.producer_montage_cost *= offer.market_factor;
                group.producer_montage_cost *= multiplier;
                group.producer_montage_cost = currencyExchange(core.roundPrice(group.producer_montage_cost), currency);
            }
        }
    }

    static setMontagesDataStatic(
        offer: any,
        groups: any[],
        montages: any[],
        dealerId: any,
        marketId: any,
        getGroupName: (group: any) => string,
        multiplier = 1
    ) {
        offer.montages =
            typeof offer.montages === 'string' ? JSON.parse(offer.montages) : offer.montages;
        if (montages && Array.isArray(montages) && montages.length > 0) {
            offer.montages.montage_allowed = true;
            const own_montages = montages.filter(
                item => item.Montage.dealer_id && Number(item.Montage.dealer_id) === Number(dealerId)
                    && (!offer.montages.montageType || Number(item.Montage.montage_type_id) === Number(offer.montages.montageType))
            );
            const producer_montages = montages.filter(
                item =>
                    !item.Montage.dealer_id && Number(item.Montage.market_id) === Number(marketId)
                    && (!offer.montages.montageType || Number(item.Montage.montage_type_id) === Number(offer.montages.montageType))
            );

            offer.montages.multiplier = multiplier;

            offer.montages.positions = groups
                .map(group =>
                    group.rows.filter(row =>
                        [
                            'window',
                            'hs',
                            'door',
                            'folding_door',
                            'sliding_door',
                            'garage_door',
                            'roller_shutter',
                            'pleated_blind',
                            'external_blind',
                            'coupled_window'
                        ].indexOf(row.doc.confType) > -1
                    )
                    .map(row => {
                        const subPositions = [];

                        if (row.doc.confType === 'coupled_window') {
                            row.doc.detailsForView.subPositions.forEach(subPosition => {
                                subPositions.push(subPosition);
                            });
                        } else {
                            subPositions.push(row);
                        }

                        return subPositions.map(subRow => {
                            let width = subRow.doc.details.width || 0;
                            let height = subRow.doc.details.height  || 0;

                            if (subRow.doc.details?.type === 'roller_shutter' || subRow.doc.details?.type === 'external_blind') {
                                width = subRow.doc.details.rollerShutter.boxWidth || 0;
                                height = (subRow.doc.details.rollerShutter.boxHeight + subRow.doc.details.rollerShutter.rollerHeight) || 0;
                            }

                            if (subRow.doc.details?.type === 'pleated_blind') {
                                width = subRow.doc.details.shape.width || 0;
                                height = subRow.doc.details.shape.height || 0;
                            }

                            if (subRow.doc.details?.type === 'garage_door') {
                                width = subRow.doc.details.dimensions?.width || 0;
                                height = subRow.doc.details.dimensions?.height || 0;
                            }

                            return {
                                area: subRow.doc.area,
                                circuit: subRow.doc.circuit,
                                group: group.groupCode,
                                height: height,
                                id: subRow.doc.tmp_id,
                                if_modified: false,
                                modified_own_montage_cost: 0,
                                name: subRow.doc.configuration.Name,
                                own_montage_cost: 0,
                                pieces: subRow.doc.quantity,
                                producer_montage_cost: 0,
                                system:
                                    (subRow.doc.details.system?.id ?? subRow.doc.details.system)
                                    || subRow.doc.details.rollerShutter?.system?.id,
                                type: subRow.doc.confType,
                                weight: subRow.doc.weight,
                                width: width,
                            };
                        });
                    })
                )
                .reduce((group_a, group_b) => group_a.concat([].concat(...group_b)), []);

            const previous_groups = core.copy(offer.montages.groups);

            offer.montages.groups = {};
            offer.montages.positions?.forEach(position => {
                !offer.montages.groups[position.type + '_' + position.system]
                    ? (offer.montages.groups[position.type + '_' + position.system] = {
                          positions: [],
                      })
                    : null;
                offer.montages.groups[position.type + '_' + position.system]['positions'].push(
                    position
                );
                offer.montages.groups[position.type + '_' + position.system]['type'] =
                    position.type;
                offer.montages.groups[position.type + '_' + position.system]['system'] =
                    position.system;
                offer.montages.groups[position.type + '_' + position.system]['name'] =
                    position.name;
            });

            for (const key in offer.montages.groups) {
                if (offer.montages.groups.hasOwnProperty(key)) {
                    const group = offer.montages.groups[key];

                    if ([
                            'window',
                            'hs',
                            'door',
                            'folding_door',
                            'sliding_door',
                            'garage_door',
                            'roller_shutter',
                            'pleated_blind',
                            'external_blind',
                            'coupled_window'
                        ].indexOf(group.type) > -1
                    ) {
                        group.windowLineId = group.system
                        group.windowLineName = group.name;

                        group.own_montages = core
                            .copy(
                                own_montages.filter(montage => {
                                    return montage.WindowLine && montage.WindowLine.map(WindowLine =>
                                        Number(WindowLine.id)
                                    ).includes(Number(group.windowLineId));
                                })
                            )
                            .map(el => {
                                delete el.Montage.windowLines;
                                delete el.WindowLine;
                                return el;
                            });
                        group.producer_montages = core
                            .copy(
                                producer_montages.filter(montage => {
                                    return montage.WindowLine && montage.WindowLine.map(WindowLine =>
                                        Number(WindowLine.id)
                                    ).includes(Number(group.windowLineId)) || montage.RollerShutterSystem && montage.RollerShutterSystem.map(RollerShutterSystem =>
                                        Number(RollerShutterSystem.id)
                                    ).includes(Number(group.windowLineId));
                                })
                            )
                            .map(el => {
                                delete el.Montage.windowLines;
                                delete el.WindowLine;
                                delete el.RollerShutterSystem;
                                return el;
                            });
                        group.producer_montages.forEach(
                            montage => (montage.selected = offer.shop_order ? true : false)
                        );
                        group.own_montages.forEach(
                            montage => (montage.selected = offer.shop_order ? true : false)
                        );
                        group.if_producer_montage = offer.shop_order ? true : false;
                        group.if_own_montage = false;
                        for (const name in previous_groups) {
                            if (previous_groups.hasOwnProperty(name)) {
                                const old_group = previous_groups[name];
                                if (offer.montages.groups.hasOwnProperty(name) && offer.montages.groups[name].hasOwnProperty('producer_montages')) {
                                    const new_group = offer.montages.groups[name];
                                    new_group.producer_montages.forEach(montage => {
                                        old_group.producer_montages.forEach(old_montage => {
                                            if (old_montage.Montage.id === montage.Montage.id) {
                                                montage.selected = old_montage.selected;
                                            }
                                        });
                                    });

                                    new_group.own_montages.forEach(montage => {
                                        old_group.own_montages.forEach(old_montage => {
                                            if (old_montage.Montage.id === montage.Montage.id) {
                                                montage.selected = old_montage.selected;
                                            }
                                        });
                                    });

                                    new_group.if_own_montage = old_group.if_own_montage;
                                    new_group.if_producer_montage = old_group.if_producer_montage;

                                    new_group.positions.forEach(position => {
                                        old_group.positions.forEach(old_position => {
                                            if (old_position.id === position.id) {
                                                position.if_modified = old_position.if_modified;
                                                position.modified_own_montage_cost =
                                                    old_position.modified_own_montage_cost;
                                            }
                                        });
                                    });
                                }
                            }
                        }
                        MontagesService.calculateOwnMontageCosts(group, offer, 1);
                        MontagesService.calculateProducerMontageCosts(group, offer, multiplier);
                    }
                }
            }
        } else {
            offer.montages.montage = false;
            offer.montages.montage_allowed = false;
        }
    }

    setMontagesData(offer: any, groups: any[], montages: any[], dealerId, marketId) {
        const multiplier = this.discountsAndMultipliersService.getMontageMultiplier();
        MontagesService.setMontagesDataStatic(
            offer,
            groups,
            montages,
            dealerId,
            marketId,
            this.getGroupName.bind(this),
            multiplier
        );
    }

    private getGroupName(group: any): any {
        return group.groupCode.includes('BS0') || group.groupCode.includes('BS1')
            ? group.confType == 'window'
                ? group.groupCode.includes('BS0')
                    ? this.translateService.instant('OFFER|Montaż okien małego gabarytu')
                    : this.translateService.instant('OFFER|Montaż okien dużego gabarytu')
                : group.groupCode.includes('BS0')
                ? this.translateService.instant('OFFER|Montaż rolet małego gabarytu')
                : this.translateService.instant('OFFER|Montaż rolet dużego gabarytu')
            : group.rows[0].doc.configuration.System.name;
    }
}
