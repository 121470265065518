import { Configuration } from './Configuration';
import { PriceSegment, DiscountGroups, PricePart } from '@icc/price/b2b';
import { TimeLimitSegment } from '@icc/common/time-limit/time-limit-functions';
import { RollerShutter, RollerShutterParameters } from './parts/roller_shutter';
import { Accessory } from './parts/common';

import { RollerShutterActiveConfiguration } from './RollerShutterActiveConfiguration';
import { core } from '../helpers';

import { changesInVersion4, changesInVersion6 } from './converters/roller_shutter';

export class RollerShutterConfiguration implements Configuration {
    $version = 6;
    type: 'roller_shutter' | 'external_blind' = 'roller_shutter';
    name = '';
    price: number | null = null;
    priceNoMargin: number | null = null;
    priceAfterDiscounts: number | null = null;
    priceGross: number | null = null;
    priceGrossAfterDiscounts: number | null = null;
    quantity = 1;
    priceSegments: PriceSegment[] = [];
    priceSegmentsNoMargin: PriceSegment[] = [];
    priceParts: PricePart[] = [];
    pricePartsNoMargin: PricePart[] = [];
    discountGroups: DiscountGroups = {};
    discountGroupsNoMargin: DiscountGroups = {};
    title = '';
    description = '';
    attachments: any[] = [];
    deletedAttachments: any[] = [];
    valid = {};
    accessories: Accessory[] = [];
    rollerShutter: RollerShutter = null;
    timeLimit: number | null = null;
    timeLimitsStack: TimeLimitSegment[] = [];
    parameters: RollerShutterParameters = {
        weight: null,
    };
    attributes: {
        id: string;
        name: string;
        value: string;
        valueId?: number;
    }[] = [];

    static converters = {
        4: changesInVersion4,
        6: changesInVersion6,
    };

    constructor(
        configuration?,
        dataRequiredToUpdate?: any,
        conversion = true,
        configuratorsDataService?,
        colorsDefaultsService?
    ) {
        if (RollerShutterConfiguration.is(configuration) && conversion) {
            this.runConversion(configuration, dataRequiredToUpdate);
        } else {
            if (RollerShutterConfiguration.is(configuration)) {
                for (const propName in configuration) {
                    if (this.hasOwnProperty(propName)) {
                        this[propName] = configuration[propName];
                    }
                }
            } else {
                if (RollerShutterActiveConfiguration.is(configuration)) {
                    this.name = configuration.Name;
                    this.price = configuration.Price;
                    this.priceNoMargin = configuration.PriceNoMargin;
                    this.priceAfterDiscounts =
                        configuration.PriceAfterDiscounts || configuration.Price;
                    this.priceGross = configuration.PriceGross || configuration.Price;
                    this.priceGrossAfterDiscounts =
                        configuration.PriceGrossAfterDiscounts || configuration.Price;
                    this.quantity = configuration.Quantity;
                    this.priceSegments = configuration.PriceSegments;
                    this.priceSegmentsNoMargin = configuration.PriceSegmentsNoMargin;
                    this.priceParts = configuration.PriceParts;
                    this.pricePartsNoMargin = configuration.PricePartsNoMargin;
                    this.discountGroups = configuration.DiscountGroups;
                    this.discountGroupsNoMargin = configuration.DiscountGroupsNoMargin;
                    this.title = configuration.Title;
                    this.description = configuration.Description;
                    this.attachments = configuration.Attachments;
                    this.valid = configuration.valid;
                    this.timeLimit = configuration.timeLimit;
                    this.timeLimitsStack = configuration.timeLimitsStack;
                    this.attributes = configuration.attributes;
                    if (configuration.Accessories) {
                        this.accessories = configuration.Accessories.map<Accessory>(
                            accessory => new Accessory(accessory)
                        );
                    }
                    configuration.RollerShutter.weight = configuration.Weight;
                    this.rollerShutter = new RollerShutter(configuration.RollerShutter);
                    this.parameters = {
                        weight: configuration.Weight,
                    };
                }
            }
        }
    }

    public static is(configuration): configuration is RollerShutterConfiguration {
        return configuration instanceof RollerShutterConfiguration || configuration.$version;
    }

    protected runConversion(configuration, dataRequiredToUpdate: any) {
        let updatedConfiguration = core.copy(configuration);

        for (let version = configuration.$version + 1; version <= this.$version; version++) {
            updatedConfiguration.$version = version;
            if (RollerShutterConfiguration.converters[version] && typeof RollerShutterConfiguration.converters[version] === 'function') {
                updatedConfiguration = RollerShutterConfiguration.converters[version](
                    updatedConfiguration,
                    dataRequiredToUpdate
                );
            }
        }
        (Object as any)
            .entries(updatedConfiguration)
            .forEach(([key, value]) => (this[key] = value));
    }
}
