import { Injectable, Inject } from '@angular/core';
import { ProfilesService } from '@icc/common/profiles.service';
import { Frame, SashTypes } from '@icc/window';
import { WindowActiveConfiguration } from '@icc/common/configurations/WindowActiveConfiguration';
import { EventBusService } from '@icc/common/event-bus.service';
import { LoadedConfiguratorsDataValue } from '@icc/common/configurators/configurators-data.service';
import { APP_CONFIG, AppConfigFactory } from '@icc/common/config';
import { ActiveSash } from '@icc/common/layout/active-sash';
import { ValidationService } from '@icc/common/configurators/validation.service';
import { AccessoriesService } from '@icc/legacy/configurator/steps/window/accessories/accessories.service';

@Injectable()
export class ThresholdsService {
    minHeightBalcony = 1800;
    maxHeightBalcony = 1800;

    constructor(
        @Inject(APP_CONFIG) private config: AppConfigFactory,
        private eventBusService: EventBusService,
        private profilesService: ProfilesService,
        private validationService: ValidationService,
        private accessoriesService: AccessoriesService,
    ) {
        this.eventBusService.subscribe<LoadedConfiguratorsDataValue>(
            'loadedConfiguratorsData',
            data => {
                this.loadData(data.value);
            }
        );

        this.eventBusService.subscribe<any[]>(['changedSashes'], data => {
            this.validateBalconyAndFixIssues(data.activeConfiguration as WindowActiveConfiguration);
        });

        this.eventBusService.subscribe<any[]>(['changedBalcony', 'changedSashes', 'setProfileSet'], data => {
            this.validateThresholdAndFixIssues(
                data.activeConfiguration as WindowActiveConfiguration
            );
        });
    }

    setThreshold(conf: WindowActiveConfiguration) {
        const pauseId = this.eventBusService.pause(['setLowThreshold']);
        try {
            conf.Frames.forEach(frame => {
                const hasTurnHungSash = conf.Sashes.filter(sash => sash.frameId === frame.id).some(
                    sash =>
                        sash.nearMullions.bottom === -1
                        && SashTypes.TURN_HUNG.indexOf(sash.type.type) > -1
                );
                if (hasTurnHungSash && frame.y + frame.height === conf.Height) {
                    this.setFrameThreshold(frame);
                }
            });
        } finally {
            this.eventBusService.resume(['setLowThreshold'], pauseId);
        }
    }

    unsetThreshold(conf: WindowActiveConfiguration) {
        const pauseId = this.eventBusService.pause(['unsetLowThreshold']);
        try {
            conf.Frames.forEach(frame => this.unsetFrameThreshold(frame));
        } finally {
            this.eventBusService.resume(['unsetLowThreshold'], pauseId);
        }
    }

    setFrameThreshold(frame: Frame) {
        frame.lowThreshold = true;
        this.eventBusService.post({ key: 'setLowThreshold', value: {} });
    }

    unsetFrameThreshold(frame: Frame) {
        frame.lowThreshold = false;
        this.eventBusService.post({ key: 'unsetLowThreshold', value: {} });
    }

    validateThresholdAndFixIssues(conf: WindowActiveConfiguration) {
        const pauseId = this.eventBusService.pause(['setLowThreshold', 'unsetLowThreshold']);
        try {
            conf.Frames.forEach(frame => {
                if (
                    (frame.lowThreshold
                        && (!conf.Balcony
                            && conf.System.confType !== 'hs'
                            && conf.type !== 'door'
                            && conf.type !== 'folding_door'))
                    || (conf.Balcony
                        && conf.Sashes.filter(sash => sash.frameId === frame.id).every(
                            sash =>
                                sash.nearMullions.bottom > -1
                                || SashTypes.TURN_HUNG.indexOf(sash.type.type) === -1
                        ))
                    || frame.y + frame.height !== conf.Height
                ) {
                    this.unsetFrameThreshold(frame);
                }
                if (
                    (!frame.lowThreshold
                        && conf.System.confType === 'hs')
                    || (conf.type === 'door'
                        && conf.Sashes.filter(
                            sash => sash.frameId === frame.id && sash.nearMullions.bottom === -1
                        ).every(sash => SashTypes.TURN_HUNG.indexOf(sash.type.type) > -1 || this.config().IccConfig.Configurators.door?.allowLowThresholdOnDoorLights))
                    || conf.type === 'folding_door'
                ) {
                    this.setFrameThreshold(frame);
                }
            });
        } finally {
            this.eventBusService.resume(['setLowThreshold', 'unsetLowThreshold'], pauseId);
        }
    }

    validateBalconyAndFixIssues(conf: WindowActiveConfiguration) {
        let canBeChosen = false;
        let alwaysBalcony = true;
        if (
            ['door', 'folding_door', 'sliding_door', 'hs'].indexOf(conf.type) === -1
            && this.validationService.isValidElements(conf, ['sashes'])
        ) {
            conf.Sashes.filter(
                sash => sash.nearMullions.bottom === -1 && sash.type.type !== 'F'
            ).map(sash => {
                const sashFrameHeight = this.getSashFrameHeight(conf, sash);

                canBeChosen = canBeChosen || this.minHeightBalcony <= sashFrameHeight;
                alwaysBalcony = alwaysBalcony && this.maxHeightBalcony < sashFrameHeight;
            });
            if (
                (this.config().IccConfig.Configurators.balconyHungWindow
                    && !this.isHungWindow(conf))
                || !canBeChosen
            ) {
                conf.Balcony = false;
                this.changeBalcony();
                return;
            }
            if (alwaysBalcony) {
                conf.Balcony = true;
                this.changeBalcony();
                return;
            }
        }
    }

    /**
     * Funkcja sprawdzajca czy może być balkon
     * @return {Boolean} Czy może być balkon
     */
    canBeBalcony(conf: WindowActiveConfiguration) {
        let canBeChosen = false;
        let alwaysBalcony = true;
        if (
            ['door', 'folding_door', 'sliding_door', 'hs'].indexOf(conf.type) === -1
            && this.validationService.isValidElements(conf, ['sashes'])
        ) {
            conf.Sashes.filter(
                sash => sash.nearMullions.bottom === -1 && sash.type.type !== 'F'
            ).map(sash => {
                const sashFrameHeight = this.getSashFrameHeight(conf, sash);

                canBeChosen = canBeChosen || this.minHeightBalcony <= sashFrameHeight;
                alwaysBalcony = alwaysBalcony && this.maxHeightBalcony < sashFrameHeight;
            });
            return (
                (!this.config().IccConfig.Configurators.balconyHungWindow
                    || this.isHungWindow(conf))
                && canBeChosen
                && !alwaysBalcony
            );
        }

        return false;
    }

    hasThreshold(conf: WindowActiveConfiguration) {
        return conf.Frames.some(frame => frame.lowThreshold);
    }

    /**
     * Funkcja sprawdzajaca czy jest rozwierne
     * @return {Boolean} Czy jest rozwierne
     */
    isHungWindow(conf: WindowActiveConfiguration) {
        const isHungWindow = conf.Sashes.filter(
            sash => sash.nearMullions.bottom === -1 && sash.nearAlignments.bottom === -1
        ).some(sash => SashTypes.TURN_HUNG.indexOf(sash.type.type) > -1);

        return isHungWindow;
    }

    /**
     * Funkcja zmiany drzwi balkonowych
     */
    changeBalcony() {
        this.eventBusService.post({ key: 'changedBalcony', value: {} });
    }

    changeLowThreshold(profileId, conf: WindowActiveConfiguration) {
        const profile = this.profilesService.getProfile(profileId);
        // Save last set threshold in ProfileSet as default value (at resizing layout all chosen profiles are forgotten)
        conf.ProfileSet.threshold = profileId;
        conf.Frames.forEach(frame => {
            if (frame.lowThreshold) {
                this.profilesService.setFrameProfile(conf, profile, frame, 0, {
                    isDefault: false,
                    finWidth: 0,
                });
            }
        });
        this.eventBusService.post({ key: 'setLowThreshold', value: {} });
    }

    private getSashFrameHeight(conf: WindowActiveConfiguration, sash: ActiveSash) {
        const sashFrameProfile =
            sash.frame.bottom
            && conf.UsedProfiles.find(el => el.id === sash.frame.bottom.profileId);
        const sashFrameRebate =
            !this.config().IccConfig.Configurators.handlesWithRebateHeight && sashFrameProfile
                ? Number(sashFrameProfile.rebateWidth) * 2 || 0
                : 0;
        const sashFrameData =
            conf.drawData && conf.drawData.sashFrame.find(el => el.sashId === sash.id);
        const sashFrameHeight = sashFrameData
            ? sashFrameData.outer.rect.height - sashFrameRebate
            : 0;
        return sashFrameHeight;
    }

    private loadData(data) {
        this.minHeightBalcony = Number(data.windowBalconyMin);
        this.maxHeightBalcony = Number(data.windowBalconyMax);
    }
}
