import { Reinforcement } from './Reinforcement';
import { SideProfile } from './SideProfile';
import { SideColors, Wood } from '@icc/common/types';

/** Łączniki */
export class Coupling extends SideProfile {
    /** Czy ustawiony domyślnie */
    isDefault: boolean = true;
    /** Orientacja */
    direction: 'horizontal' | 'vertical' = 'vertical';
    /**
     * Identyfikatory sąsiadujących ram oraz indeksy krawędzi ram z prawej lub dołu,
     * do których jest przykręcony boczny profil
     */
    otherFramesId: {
        id: number;
        edges: number[];
        positionId?: string;
    }[] = [];
    /** Identyfiatory łączników prostopadłych */
    perpendicularCouplings: {
        top?: number[];
        bottom?: number[];
        left?: number[];
        right?: number[];
    } = {};
    /** Typ */
    type = '';
    /** Kąt rozwarcia łącznika */
    angle = 0;

    otherSide: 'left' | 'right' | 'top' | 'bottom' | 'left-top' | 'right-top' = 'bottom';

    constructor(params?: {
        id: number;
        profileId: number;
        profileCategoryId?: number;
        length: number;
        direction: 'horizontal' | 'vertical';
        width: number;
        widthOut: number;
        color: SideColors;
        wood: Wood;
        reinforcement: Reinforcement;
        adjacentSideProfileId: number[];
        adjacentOtherSideProfileId: number[];
        adjacentCouplingId?: number[];
        framesId: {
            id: number;
            edges: number[];
            positionId?: string;
        }[];
        otherFramesId: {
            id: number;
            edges: number[];
            positionId?: string;
        }[];
        side: 'left' | 'right' | 'top' | 'bottom' | 'left-top' | 'right-top';
        otherSide: 'left' | 'right' | 'top' | 'bottom' | 'left-top' | 'right-top';
    }) {
        super(params);
        if (params) {
            this.direction = params.direction;
            this.adjacentOtherSideProfileId = params.adjacentOtherSideProfileId;
            this.profileCategoryId = params.profileCategoryId;
            this.framesId = params.framesId;
            this.otherFramesId = params.otherFramesId;
            this.otherSide = params.otherSide;
            if (this.direction === 'horizontal') {
                this.perpendicularCouplings = {
                    top: [],
                    bottom: [],
                };
            } else {
                this.perpendicularCouplings = {
                    left: [],
                    right: [],
                };
            }
        }
    }
}
