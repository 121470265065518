/* eslint-disable max-statements */
import { Injectable } from '@angular/core';
import { ConfiguratorsDataService, ProfilesService, EventBusService, core, ConfigurationsService, WindowActiveConfiguration, SizeRangeService } from '@icc/common';
import { DoorSizes, Shape, expandShape, getDefaultShapeDimensions, Layout } from '@icc/window';
import { DoorActiveConfiguration } from '@icc/common/configurations/DoorActiveConfiguration';
import { IccDoorSize, IccDoorLightsSize } from '@icc/common/data-types/DoorSize';
import { ActiveSash } from '@icc/common/layout/active-sash';
import { BehaviorSubject } from 'rxjs';
import { FillingsService } from '@icc/legacy/configurator/steps/window/glazings/fillings.service';
import { IssuesService, IssueLevel } from '@icc/helpers';
import { IccDrawMathService } from '@icc/draw';

@Injectable({
    providedIn: 'root',
})
export class SizesService {
    private isStandardHeightSelected$ = new BehaviorSubject<boolean>(true);
    doorSizes: IccDoorSize[] = [];
    doorLightsSizes: IccDoorLightsSize[] = [];
    doorLightsSizes$ = new BehaviorSubject<IccDoorLightsSize[]>([]);

    constructor(
        private configuratorsDataService: ConfiguratorsDataService,
        private profilesService: ProfilesService,
        private eventBusService: EventBusService,
        private fillingsService: FillingsService,
        private issuesService: IssuesService,
        private configurationsSerivce: ConfigurationsService,
        private sizeRangeService: SizeRangeService
    ) {
        this.eventBusService.subscribeWithoutConfiguration('initializedConfigurator', () => {
            this.updateMatchingDoorSizes();
        });
        this.eventBusService.subscribe(['setProfileSet', 'setFrameProfile'], data => {
            if (
                !DoorActiveConfiguration.is(data.activeConfiguration)
                || !data.activeConfiguration.System
                || !data.activeConfiguration.System.door_type
            ) {
                return;
            }
            this.findMatchingDoorSizes(data.activeConfiguration as DoorActiveConfiguration);
        });

        this.eventBusService.subscribe('setSystem', data => {
            if (DoorActiveConfiguration.is(data.activeConfiguration) && data.activeConfiguration.System?.door_type) {
                this.validateSashesShortening(data.activeConfiguration)
            }
        });
    }

    updateMatchingDoorSizes(conf=this.configurationsSerivce.conf.Current) {
        if (DoorActiveConfiguration.is(conf) && this.configuratorsDataService?.data?.doorSizes && this.configuratorsDataService?.data?.doorLightsSizes) {
            this.doorSizes = this.configuratorsDataService.data.doorSizes.filter(
                size => conf.System && size.systems.includes(Number(conf.System.id))
            );
            this.doorLightsSizes = (this.configuratorsDataService.data.doorLightsSizes || []).filter(
                size => {
                    if (conf.Frames && conf.Frames[0]?.frame?.length > 0 && conf.Frames.length > 0 && conf.System) {
                        const frame = conf.Frames[0];
                        const profileId = frame.frame[1].profileId;
                        return (
                            size.systems.includes(Number(conf.System.id))
                            && size.profiles.includes(Number(profileId))
                        );
                    }
                }
            );
            this.doorLightsSizes$.next(this.doorLightsSizes);
        }
    }

    findMatchingDoorSizes(conf: DoorActiveConfiguration) {
        this.updateMatchingDoorSizes(conf);
        this.setDefaultDoorSizes(conf);
    }

    validateSashesShortening(conf = this.configurationsSerivce.conf.Current as DoorActiveConfiguration) {
        const shorteningRange = this.getSashesShorteningRange();

        if (shorteningRange.length === 0) {
            conf.doorSizes.shortening = 0;
            conf.doorSizes.frameShortening = 0;
        }

        if (shorteningRange.length > 0 && !shorteningRange.includes(conf.doorSizes.shortening)) {
            conf.doorSizes.shortening = shorteningRange[0];
            conf.doorSizes.frameShortening = conf.doorSizes.shortening;

            if (!conf.System.seperate_frame_and_sash_shortening && conf.doorSizes.shortening >= 0) {
                conf.doorSizes.frameShortening = conf.doorSizes.shortening;
            }
        }
    }

    getSashesShorteningRange(conf = this.configurationsSerivce.conf.Current as DoorActiveConfiguration): number[] {
        const range: number[] = [];
        (conf.System.shortening || []).forEach((s: { min: number; max: number; step: number }) => {
            let min = Number(s.min);
            const max = Number(s.max);
            const step = Number(s.step);
            if (max !== 0) {
                while (step > 0 ? max >= min : max <= min) {
                    if (!range.includes(min)) {
                        range.push(min);
                    }
                    min += step;
                }

                if (range.indexOf(0) === -1) {
                    range.unshift(0);
                }
            }
        });

        return range;
    }

    findMatchingDoorLightsSizesToDefaultProfile(
        conf: DoorActiveConfiguration,
        type: 'side' | 'top'
    ) {
        return (this.configuratorsDataService.data.doorLightsSizes || [])
            .filter(size => {
                if (conf.Frames && conf.Frames.length > 0 && conf.System) {
                    const frame = conf.Frames[0];
                    const defaultProfile = this.profilesService.getDefaultFrameProfile(
                        'side',
                        frame,
                        conf,
                        1,
                        size.id
                    );
                    return (
                        defaultProfile
                        && size.systems.includes(Number(conf.System.id))
                        && size.profiles.includes(Number(defaultProfile.id))
                    );
                }
            })
            .filter(size => size.type === type);
    }

    setDefaultDoorSizes(conf: DoorActiveConfiguration) {
        if (!conf.System || !conf.System.door_type) {
            return;
        }
        const sashSizes = this.getSashSizes(conf);
        const selectedSashSizeAvailable = sashSizes.some(sash => sash.id === conf.doorSizes.sashSizeId);
        const passiveSashSizes = this.getPassiveSashSizes(conf);
        let sideLightsSizes = this.getSideLightsSizes();
        let topLightsSizes = this.getTopLightsSizes();

        if(conf.System.shortening.length === 0 && !conf.doorSizes.customSize) {
            conf.doorSizes.shortening = 0;
        }
        if (topLightsSizes.length === 0) {
            topLightsSizes = this.findMatchingDoorLightsSizesToDefaultProfile(conf, 'top');
        }
        if (sideLightsSizes.length === 0) {
            sideLightsSizes = this.findMatchingDoorLightsSizesToDefaultProfile(conf, 'side');
        }
        if (sashSizes && !selectedSashSizeAvailable && sashSizes.length > 0) {
            conf.doorSizes.sashSizeId = sashSizes[0].id;
            conf.doorSizesDictionary.door[sashSizes[0].id] = sashSizes[0];
            conf.doorSizes.customHeight = 0;
            conf.doorSizes.customWidth = 0;
            conf.doorSizes.customSize = false;
        }
        this.setStandardWidthSelected(this.isStandardWidthSelected(sashSizes, conf), conf);
        const selectedSashSize = sashSizes.find(sash => sash.id === conf.doorSizes.sashSizeId);
        if (selectedSashSize) {
            if (!selectedSashSize.custom && !selectedSashSize.custom_height_availability) {
                conf.doorSizes.customWidth = 0;
            }
            if (selectedSashSize.custom || selectedSashSize.custom_height_availability) {
                conf.doorSizes.shortening = 0;
                conf.doorSizes.frameShortening = 0;
            }
            if (conf.doorSizes.isStandardWidthSelected) {
                const sashWidth = selectedSashSize.sizes[conf.ProfileSet.sash]?.sash_width
                conf.doorSizes.customWidth = this.getSashWidthInRebate(sashWidth, conf);
            } else {
                conf.doorSizes.customWidth =
                        conf.doorSizes.customWidth === 0
                            ? selectedSashSize?.default_width
                            : conf.doorSizes.customWidth !== selectedSashSize?.default_width
                            ? conf.doorSizes.customWidth
                            : selectedSashSize?.default_width || 0;
                conf.doorSizes.sizeIntervalCustomWidth = selectedSashSize?.size_interval_custom_width || null;
                conf.doorSizes.customSize = selectedSashSize?.custom;
            }
            this.setStandardHeightSelectedInConfig(selectedSashSize, conf);
            if (conf.doorSizes.isStandardHeightSelected) {
                const sashHeight = selectedSashSize.sizes[conf.ProfileSet.sash]?.sash_height
                conf.doorSizes.customHeight = this.getSashHeightInRebate(sashHeight, conf);
            } else {
                conf.doorSizes.customHeight =
                    conf.doorSizes.customHeight === 0
                        ? selectedSashSize?.default_height
                        : conf.doorSizes.customHeight !== selectedSashSize?.default_height
                        ? conf.doorSizes.customHeight
                        : selectedSashSize?.default_height || 0;
                conf.doorSizes.sizeIntervalHeight = selectedSashSize?.size_interval_height || null;
                conf.doorSizes.customSize = selectedSashSize?.custom_height_availability;
            }
        }
        if (
            passiveSashSizes
            && !passiveSashSizes.find(s => s.id === conf.doorSizes.passiveSashSizeId)
            && passiveSashSizes.length > 0
            && conf.Sashes.some(sash => ['DRP', 'DOP'].includes(sash.type?.type))
        ) {
            conf.doorSizes.passiveSashSizeId = passiveSashSizes[0].id;
            conf.doorSizesDictionary.door[passiveSashSizes[0].id] = passiveSashSizes[0];
        } else if (
            !conf.Sashes.some(sash => ['DRP', 'DOP'].includes(sash.type?.type))
            && conf.doorSizes?.passiveSashSizeId
            && conf.doorSizesDictionary?.door[conf.doorSizes?.passiveSashSizeId]
        ) {
            delete conf.doorSizesDictionary.door[conf.doorSizes.passiveSashSizeId];
            conf.doorSizes.passiveSashSizeId = null;
        }
        if (
            sideLightsSizes
            && !sideLightsSizes.find(s => s.id === conf.doorSizes.leftLightSizeId)
            && sideLightsSizes.length > 0
        ) {
            conf.doorSizes.leftLightSizeId = sideLightsSizes[0].id;
            conf.doorSizesDictionary.doorLights[sideLightsSizes[0].id] = sideLightsSizes[0];
            if (conf.doorSizes.leftLightSize || conf.OwnedSashesTypes.doorLeftLight) {
                if (conf.doorSizes.leftLightSize >= sideLightsSizes[0].size_to) {
                    conf.doorSizes.leftLightSize = sideLightsSizes[0].size_to;
                }
                if (conf.doorSizes.leftLightSize <= sideLightsSizes[0].size_from) {
                    conf.doorSizes.leftLightSize = sideLightsSizes[0].size_from;
                }
            }
        }

        if (
            sideLightsSizes
            && !sideLightsSizes.find(s => s.id === conf.doorSizes.rightLightSizeId)
            && sideLightsSizes.length > 0
        ) {
            conf.doorSizes.rightLightSizeId = sideLightsSizes[0].id;
            conf.doorSizesDictionary.doorLights[sideLightsSizes[0].id] = sideLightsSizes[0];
            if (conf.doorSizes.rightLightSize || conf.OwnedSashesTypes.doorRightLight) {
                if (conf.doorSizes.rightLightSize >= sideLightsSizes[0].size_to) {
                    conf.doorSizes.rightLightSize = sideLightsSizes[0].size_to;
                }
                if (conf.doorSizes.rightLightSize <= sideLightsSizes[0].size_from) {
                    conf.doorSizes.rightLightSize = sideLightsSizes[0].size_from;
                }
            }
        }

        if (
            topLightsSizes
            && !topLightsSizes.find(s => s.id === conf.doorSizes.topLightSizeId)
            && topLightsSizes.length > 0
        ) {
            conf.doorSizes.topLightSizeId = topLightsSizes[0].id;
            conf.doorSizesDictionary.doorLights[topLightsSizes[0].id] = topLightsSizes[0];
            if (conf.doorSizes.topLightSize || conf.OwnedSashesTypes.doorTopLight) {
                if (conf.doorSizes.topLightSize >= topLightsSizes[0].size_to) {
                    conf.doorSizes.topLightSize = topLightsSizes[0].size_to;
                }
                if (conf.doorSizes.topLightSize <= topLightsSizes[0].size_from) {
                    conf.doorSizes.topLightSize = topLightsSizes[0].size_from;
                }
            }
        }

        if (!passiveSashSizes || passiveSashSizes.length === 0) {
            conf.doorSizes.passiveSashSizeId = null;
        }

        if (!sideLightsSizes || sideLightsSizes.length === 0) {
            conf.doorSizes.leftLightSizeId = null;
            conf.doorSizes.leftLightSize = 0;
            conf.doorSizes.rightLightSizeId = null;
            conf.doorSizes.rightLightSize = 0;
            conf.doorSizes.topLightSizeId = null;
            conf.doorSizes.topLightSize = 0;
        }

        this.eventBusService.post({
            key: 'changedDoorSizes',
            value: {},
        });
    }

    getSashWidthInRebate(sashWidth: number, conf: DoorActiveConfiguration) {
        const frameProfile =
        conf.UsedProfiles.find(p => p.type === 'frame')
            || this.profilesService.getProfile(conf.ProfileSet.frameSide);
        return sashWidth + (2 * frameProfile?.spaceSash || 0);
    }

    getSashHeightInRebate(sashHeight: number, conf: DoorActiveConfiguration) {
        const frame = conf.UsedProfiles.find(p => p.type === 'frame')
                || this.profilesService.getProfile(conf.ProfileSet.frameSide);
            const threshold = conf.UsedProfiles.find(p => p.type === 'threshold')
                || this.profilesService.getProfile(conf.ProfileSet.threshold);
        return (sashHeight + (frame?.spaceSash || 0) + (threshold?.spaceSash || 0))
    }


    setStandardHeightSelectedInConfig(sash, conf: DoorActiveConfiguration) {
        if (sash?.custom_height_availability) {
            conf.doorSizes.isStandardHeightSelected = this.isStandardHeightSelected(sash);
        } else if (!sash?.custom_height_availability) {
            conf.doorSizes.isStandardHeightSelected = true;
        }
    }

    isStandardWidthSelected(sashSizes: IccDoorSize[], conf: DoorActiveConfiguration): boolean {
        if (sashSizes && conf.doorSizes?.sashSizeId) {
            return !sashSizes.find((s) => s.id === conf.doorSizes.sashSizeId)?.custom;
        }
    }

    isStandardHeightSelected(sash) {
        return !(
            !this.standardHeightSelected ||
            (sash?.custom_height_availability &&
                !sash?.standard_default_height &&
                !sash?.default_height_availability)
        );
    }

    setStandardWidthSelected(isStandardWidthSelected, conf: DoorActiveConfiguration) {
        conf.doorSizes.isStandardWidthSelected = isStandardWidthSelected;
    }

    setDoorSizesBasedOnLayout(layout: Layout, conf: DoorActiveConfiguration) {
        if (layout && layout.sashes) {
            let sideLightsSizes = this.getSideLightsSizes();
            let topLightsSizes = this.getTopLightsSizes();
            if (topLightsSizes.length === 0) {
                topLightsSizes = this.findMatchingDoorLightsSizesToDefaultProfile(conf, 'top');
            }
            if (sideLightsSizes.length === 0) {
                sideLightsSizes = this.findMatchingDoorLightsSizesToDefaultProfile(conf, 'side');
            }
            let hasLeftLight = false;
            let hasRightLight = false;
            let hasTopLight = false;
            layout.sashes.forEach(sash => {
                if (
                    sash.neighbors.left.length === 0
                    && sash.neighbors.right.length > 0
                    && sash.neighbors.bottom.length === 0
                    && sash.type === 'F'
                ) {
                    const leftSize = sideLightsSizes.find(
                        s => s.id === conf.doorSizes.leftLightSizeId
                    );
                    if (!leftSize) {
                        this.issuesService.registerDataProblem(
                            'no-matching-door-light-size',
                            'Brak pasującego rozmiaru drzwi',
                            conf,
                            {
                                level: IssueLevel.FATAL,
                                extra: {
                                    systemId: conf.System.id,
                                    systemName: conf.System.name,
                                    leftLightSizeId: conf.doorSizes.leftLightSizeId,
                                },
                            }
                        );
                    } else {
                        this.issuesService.unregister('no-matching-door-light-size', conf);
                        conf.doorSizes.leftLightSize = leftSize.size_from;
                        hasLeftLight = true;
                    }
                }

                if (
                    sash.neighbors.right.length === 0
                    && sash.neighbors.left.length > 0
                    && sash.neighbors.bottom.length === 0
                    && sash.type === 'F'
                ) {
                    const rightSize = sideLightsSizes.find(
                        s => s.id === conf.doorSizes.rightLightSizeId
                    );
                    if (!rightSize) {
                        this.issuesService.registerDataProblem(
                            'no-matching-door-light-size',
                            'Brak pasującego rozmiaru drzwi',
                            conf,
                            {
                                level: IssueLevel.FATAL,
                                extra: {
                                    systemId: conf.System.id,
                                    systemName: conf.System.name,
                                    rightLightSizeId: conf.doorSizes.rightLightSizeId,
                                },
                            }
                        );
                    } else {
                        this.issuesService.unregister('no-matching-door-light-size', conf);
                        conf.doorSizes.rightLightSize = rightSize.size_from;
                        hasRightLight = true;
                    }
                }

                if (
                    sash.neighbors.top.length === 0
                    && sash.neighbors.bottom.length > 0
                    && sash.type === 'F'
                ) {
                    const topSize = topLightsSizes.find(
                        s => s.id === conf.doorSizes.topLightSizeId
                    );
                    if (!topSize) {
                        this.issuesService.registerDataProblem(
                            'no-matching-door-light-size',
                            'Brak pasującego rozmiaru drzwi',
                            conf,
                            {
                                level: IssueLevel.FATAL,
                                extra: {
                                    systemId: conf.System.id,
                                    systemName: conf.System.name,
                                    topLightSizeId: conf.doorSizes.topLightSizeId,
                                },
                            }
                        );
                    } else {
                        this.issuesService.unregister('no-matching-door-light-size', conf);
                        conf.doorSizes.topLightSize = topSize.size_from;
                        hasTopLight = true;
                    }
                }
            });

            if (!hasLeftLight) {
                conf.doorSizes.leftLightSize = 0;
            }

            if (!hasRightLight) {
                conf.doorSizes.rightLightSize = 0;
            }

            if (!hasTopLight) {
                conf.doorSizes.topLightSize = 0;
            }
        }
    }

    getConstructionWidthBasedOnLayout(layout: Layout, conf: DoorActiveConfiguration) {
        let width = 1000;
        if (layout && layout.sashes) {
            const sashSizes = this.doorSizes.find(s => s.id === conf.doorSizes.sashSizeId);
            const passiveSashSizes = this.doorSizes.find(
                s => s.id === conf.doorSizes.passiveSashSizeId
            );
            const usedSash = conf.UsedProfiles.find(p => p.type === 'virtual_door_sash');
            const sashProfileId = usedSash ? usedSash.id : conf.ProfileSet.sash;
            const frameProfile =
                conf.UsedProfiles.find(p => p.type === 'frame')
                || this.profilesService.getProfile(conf.ProfileSet.frameSide);
            const size = sashSizes?.sizes && sashSizes.sizes[sashProfileId];
            const passiveSize = passiveSashSizes && passiveSashSizes.sizes[sashProfileId];
            if (!conf.doorSizes.customSize || passiveSize ) {
                if (size && layout.sashes.some(sash => ['DRA', 'DOA'].includes(sash.type))) {
                    width = size.sash_width + 2 * frameProfile?.spaceSash;
                }
                if (passiveSize && layout.sashes.some(sash => ['DRP', 'DOP'].includes(sash.type))) {
                    width += passiveSize.sash_width + size.passive_sash_rabate;
                }
            } else {
                width = conf.doorSizes.customWidth;
            }
            layout.sashes.forEach(sash => {
                if (sash.type && sash.type === 'F') {
                    if (
                        sash.neighbors.left.length === 0
                        && sash.neighbors.right.length > 0
                        && sash.neighbors.bottom.length === 0
                    ) {
                        width += conf.doorSizes.leftLightSize;
                    } else if (
                        sash.neighbors.right.length === 0
                        && sash.neighbors.left.length > 0
                        && sash.neighbors.bottom.length === 0
                    ) {
                        width += conf.doorSizes.rightLightSize;
                    }
                }
            });
        }
        return width;
    }

    getSashSizes(conf: DoorActiveConfiguration) {
        const matchingSizes = this.getDoorSizesFromFillings(conf, 'doorActive');
        return (
            this.doorSizes.filter(
                size =>
                    !size.passive
                    && (matchingSizes.length === 0 || matchingSizes.includes(Number(size.id)))
            ) || []
        );
    }

    getPassiveSashSizes(conf: DoorActiveConfiguration) {
        const matchingSizes = this.getDoorSizesFromFillings(conf, 'doorPassive');
        return (
            this.doorSizes.filter(
                size =>
                    size.passive
                    && (matchingSizes.length === 0 || matchingSizes.includes(Number(size.id)))
            ) || []
        );
    }

    getSideLightsSizes() {
        return this.doorLightsSizes.filter(size => size.type === 'side') || [];
    }

    getTopLightsSizes() {
        return this.doorLightsSizes.filter(size => size.type === 'top') || [];
    }

    getShapeFromDoorSizes(doorSizes: DoorSizes, conf: DoorActiveConfiguration): Shape {
        const { width, height } = this.getConstructionDimensions(doorSizes, conf);
        const shape = expandShape(
            getDefaultShapeDimensions('rect', width || conf.Width, height || conf.Height)
        );
        return shape;
    }

    getConstructionDimensions(doorSizes: DoorSizes, conf: DoorActiveConfiguration) {
        const sashSizes = this.doorSizes.find(s => s.id === doorSizes.sashSizeId);
        const passiveSashSizes = this.doorSizes.find(s => s.id === doorSizes.passiveSashSizeId);
        const usedSash = conf.UsedProfiles.find(p => p.type === 'virtual_door_sash');
        const sashProfileId = usedSash ? usedSash.id : conf.ProfileSet.sash;
        const frameProfile =
            conf.UsedProfiles.find(p => p.type === 'frame')
            || this.profilesService.getProfile(conf.ProfileSet.frameSide);
        const thresholdProfile =
            conf.UsedProfiles.find(p => p.type === 'threshold')
            || this.profilesService.getProfile(conf.ProfileSet.threshold);
        const size = sashSizes && sashSizes.sizes[sashProfileId];
        const passiveSize = passiveSashSizes && passiveSashSizes.sizes[sashProfileId];

        if (!size && !sashSizes?.custom) {
            this.issuesService.registerDataProblem(
                'no-matching-door-size',
                'Brak pasującego rozmiaru drzwi',
                conf,
                {
                    level: IssueLevel.FATAL,
                    extra: {
                        systemId: conf.System.id,
                        systemName: conf.System.name,
                        sashSize: sashSizes && sashSizes.id,
                        sashSizeName: sashSizes && sashSizes.name,
                        sashProfileId,
                    },
                }
            );
        } else {
            this.issuesService.unregister('no-matching-door-size', conf);
        }

        let height = 0;
        let width = 0;

        if ((sashSizes?.custom || sashSizes?.custom_height_availability) && (!conf.doorSizes.passiveSashSizeId)) {
            height = doorSizes.customHeight;
            width = doorSizes.customWidth;
        } else if (
            (sashSizes?.custom || sashSizes?.custom_height_availability) &&
            conf.doorSizes.passiveSashSizeId
        ) {
            height = doorSizes.customHeight;
            if (
                (conf.Sashes.some((sash) => sash.type && ['DRA', 'DOA'].includes(sash.type.type)) ||
                    conf.Sashes.length === 0) &&
                size
            ) {
                width += doorSizes.customWidth;
            }
            if (
                passiveSize &&
                conf.Sashes.some((sash) => sash.type && ['DRP', 'DOP'].includes(sash.type.type))
            ) {
                if (!conf.doorSizes.isStandardWidthSelected) {
                    width += passiveSize.sash_width + (frameProfile?.spaceSash || 0) + ((size.passive_sash_rabate || 0) / 2);
                } else {
                    width += passiveSize.sash_width + (size?.passive_sash_rabate || 0);
                }
            }
            conf.Sashes.forEach((sash) => {
                if (sash.type && sash.type.type === 'F') {
                    const frame = conf.Frames.find((f) => f.id === sash.frameId);
                    if (
                        sash.nearMullions.left === -1 &&
                        ((frame && frame.x) || 0) + sash.rx === 0
                    ) {
                        if (
                            sash.nearMullions.top === -1 &&
                            ((frame && frame.x) || 0) + sash.rx + sash.rWidth === conf.Width
                        ) {
                            height += doorSizes.topLightSize;
                        } else {
                            width += doorSizes.leftLightSize;
                        }
                    } else if (
                        sash.nearMullions.right === -1 &&
                        ((frame && frame.x) || 0) + sash.rx > 0
                    ) {
                        width += doorSizes.rightLightSize;
                    }
                }
            });
            if (doorSizes.shortening > 0) {
                height -= doorSizes.shortening;
            }
            return { width, height };
        } else {
            height = size ? size.sash_height + frameProfile?.spaceSash + thresholdProfile?.spaceSash : 0;
            if (
                (conf.Sashes.some(sash => sash.type && ['DRA', 'DOA'].includes(sash.type.type))
                    || conf.Sashes.length === 0)
                && size
            ) {
                width += size.sash_width + 2 * frameProfile?.spaceSash;
            }
            if (
                passiveSize
                && conf.Sashes.some(sash => sash.type && ['DRP', 'DOP'].includes(sash.type.type))
            ) {
                width += passiveSize.sash_width + size.passive_sash_rabate;
            }
        }
        conf.Sashes.forEach(sash => {
            if (sash.type && sash.type.type === 'F') {
                const frame = conf.Frames.find(f => f.id === sash.frameId);
                if (sash.nearMullions.left === -1 && ((frame && frame.x) || 0) + sash.rx === 0) {
                    if (
                        sash.nearMullions.top === -1
                        && ((frame && frame.x) || 0) + sash.rx + sash.rWidth === conf.Width
                    ) {
                        height += doorSizes.topLightSize;
                    } else {
                        width += doorSizes.leftLightSize;
                    }
                } else if (
                    sash.nearMullions.right === -1
                    && ((frame && frame.x) || 0) + sash.rx > 0
                ) {
                    width += doorSizes.rightLightSize;
                }
            }
        });
        if (doorSizes.shortening > 0) {
            height -= doorSizes.shortening;
        }
        return { width, height };
    }

    setSizes(doorSizes: DoorSizes, conf: DoorActiveConfiguration) {
        conf.doorSizes = doorSizes;

        const sashSizes = this.getSashSizes(conf);
        const passiveSashSizes = this.getPassiveSashSizes(conf);
        const sideLightsSizes = this.getSideLightsSizes();
        const topLightsSizes = this.getTopLightsSizes();

        conf.doorSizesDictionary.door[doorSizes.sashSizeId] = sashSizes.find(s => s.id === doorSizes.sashSizeId);
        if (doorSizes.passiveSashSizeId && passiveSashSizes?.length) {
            conf.doorSizesDictionary.door[doorSizes.passiveSashSizeId] = passiveSashSizes.find(s => s.id === doorSizes.passiveSashSizeId);
        }
        if (doorSizes.leftLightSize && sideLightsSizes?.length) {
            conf.doorSizesDictionary.doorLights[doorSizes.leftLightSizeId] = sideLightsSizes.find(s => s.id === doorSizes.leftLightSizeId);
        }
        if (doorSizes.rightLightSize && sideLightsSizes?.length) {
            conf.doorSizesDictionary.doorLights[doorSizes.rightLightSizeId] = sideLightsSizes.find(s => s.id === doorSizes.rightLightSizeId);
        }
        if (doorSizes.topLightSize && topLightsSizes?.length) {
            conf.doorSizesDictionary.doorLights[doorSizes.topLightSizeId] = topLightsSizes.find(s => s.id === doorSizes.topLightSizeId);
        }

        this.eventBusService.post({
            key: 'changedDoorSizes',
            value: {},
        });
    }

    getSashWidth(
        sash: ActiveSash,
        conf: DoorActiveConfiguration,
        leftSashSchemaType = null,
        rightSashSchemaType = null
    ) {
        const sashSizes = this.doorSizes.find(s => s.id === conf.doorSizes.sashSizeId);
        const passiveSashSizes = this.doorSizes.find(
            s => s.id === conf.doorSizes.passiveSashSizeId
        );
        const usedSash = conf.UsedProfiles.find(p => p.type === 'virtual_door_sash');
        const sashProfileId = usedSash ? usedSash.id : conf.ProfileSet.sash;
        const frameProfile =
            conf.UsedProfiles.find(p => p.type === 'frame')
            || this.profilesService.getProfile(conf.ProfileSet.frameSide);
        const size = sashSizes?.sizes[sashProfileId];
        const passiveSize = passiveSashSizes && passiveSashSizes.sizes[sashProfileId];
        let width = sash.rWidth;
        if (['DRP', 'DOP'].includes(sash.type.type) && passiveSize) {
            if ((conf.doorSizes.customSize && conf.doorSizes.passiveSashSizeId) &&
                (width < passiveSize.sash_width || (conf.doorSizes.isStandardWidthSelected && conf.doorSizes.isStandardWidthSelected))) {
                width = passiveSize.sash_width + (frameProfile?.spaceSash || 0) + ((size?.passive_sash_rabate || 0) / 2);
            } else {
                width = passiveSize.sash_width;
            }
        }
        if (['DRA', 'DOA'].includes(sash.type.type) && size) {
            width = size.sash_width;
        }
        if (['DRA', 'DOA'].includes(sash.type.type) && conf.doorSizes.customSize) {
            if (conf.doorSizes.customSize && conf.doorSizes.passiveSashSizeId) {
                if (width === 0) {
                    width = conf.doorSizes.customWidth;
                } else {
                    width = conf.doorSizes.customWidth - (frameProfile?.spaceSash || 0) - ((size?.passive_sash_rabate || 0) / 2);
                }
            } else {
                width = conf.doorSizes.customWidth;
            }
        }
        const frame = conf.Frames.find(f => f.id === sash.frameId);
        if (
            sash.type.type === 'F'
            && ((frame && frame.x) || 0) + sash.rx === 0
            && ((frame && frame.x) || 0) + sash.rx + sash.rWidth !== conf.Width
        ) {
            width = conf.doorSizes.leftLightSize;
        }
        if (sash.type.type === 'F' && ((frame && frame.x) || 0) + sash.rx > 0) {
            width = conf.doorSizes.rightLightSize;
        }

        if (sash.type.type !== 'F' && !conf.doorSizes.customSize) {
            const lDiv = conf.Mullions.find(m => m.id === sash.nearMullions.left);
            const leftSash = lDiv && lDiv.multiAlignLeft.length > 0 ? lDiv.multiAlignLeft[0] : null;
            const leftType = leftSashSchemaType
                ? leftSashSchemaType.type
                : leftSash && leftSash.type
                ? leftSash.type.type
                : null;
            width +=
                leftType && ['DRA', 'DOA', 'DRP', 'DOP'].includes(leftType)
                    ? size.passive_sash_rabate / 2
                    : frameProfile?.spaceSash;

            const rDiv = conf.Mullions.find(m => m.id === sash.nearMullions.right);
            const rightSash =
                rDiv && rDiv.multiAlignRight.length > 0 ? rDiv.multiAlignRight[0] : null;
            const rightType = rightSashSchemaType
                ? rightSashSchemaType.type
                : rightSash && rightSash.type
                ? rightSash.type.type
                : null;
            width +=
                rightType && ['DRA', 'DOA', 'DRP', 'DOP'].includes(rightType)
                    ? size.passive_sash_rabate / 2
                    : frameProfile?.spaceSash;
        }
        return width;
    }

    getSashHeight(sash: ActiveSash, conf: DoorActiveConfiguration) {
        const sashSizes = this.doorSizes.find(s => s.id === conf.doorSizes.sashSizeId);
        const passiveSashSizes = this.doorSizes.find(
            s => s.id === conf.doorSizes.passiveSashSizeId
        );
        const usedSash = conf.UsedProfiles.find(p => p.type === 'virtual_door_sash');
        const sashProfileId = usedSash ? usedSash.id : conf.ProfileSet.sash;
        const frameProfile =
            conf.UsedProfiles.find(p => p.type === 'frame')
            || this.profilesService.getProfile(conf.ProfileSet.frameSide);
        const thresholdProfile =
            conf.UsedProfiles.find(p => p.type === 'threshold')
            || this.profilesService.getProfile(conf.ProfileSet.threshold);
        const size = sashSizes?.sizes[sashProfileId];
        const passiveSize = passiveSashSizes && passiveSashSizes.sizes[sashProfileId];
        let height = sash.rHeight;
        if (['DRP', 'DOP'].includes(sash.type.type) && passiveSize) {
            if ((sashSizes?.custom || sashSizes?.custom_height_availability)) {
                height = conf.doorSizes.customHeight;
            } else {
                height = passiveSize.sash_height;
            }
        }
        if (['DRA', 'DOA', 'F'].includes(sash.type.type) && size) {
            height = size.sash_height;
        }
        if (['DRA', 'DOA', 'F'].includes(sash.type.type) && conf.doorSizes.customSize) {
            height = conf.doorSizes.customHeight;
        }
        const frame = conf.Frames.find(f => f.id === sash.frameId);
        if (
            sash.type.type === 'F'
            && ((frame && frame.x) || 0) + sash.rx === 0
            && ((frame && frame.x) || 0) + sash.rx + sash.rWidth === conf.Width
        ) {
            height = conf.doorSizes.topLightSize;
        } else if (!conf.doorSizes.customSize) {
            height += frameProfile?.spaceSash + thresholdProfile?.spaceSash;

            if (conf.doorSizes.shortening > 0) {
                height -= conf.doorSizes.shortening;
            }
        } else if ((sashSizes?.custom || sashSizes?.custom_height_availability) && passiveSize) {
            height = conf.doorSizes.customHeight
        }
        return height;
    }

    getDoorSizesFromFillings(
        conf: DoorActiveConfiguration,
        type: 'doorActive' | 'doorActiveInner' | 'doorPassive'
    ) {
        const matchingFillings = this.fillingsService.getMatchingFillingsForDoorSashes(conf, type);
        const doorSizeIds: number[] = matchingFillings.reduce((doorSizes, filling) => {
            if (filling.door_sizes_ids) {
                filling.door_sizes_ids.forEach(sizeId => {
                    if (!doorSizes.includes(Number(sizeId))) {
                        doorSizes.push(Number(sizeId));
                    }
                });
            }
            return doorSizes;
        }, []);
        return doorSizeIds;
    }

    getSizeRangeId(sizeObj: DoorSizes){
        return this.doorSizes.find(s => s.id === sizeObj.sashSizeId)?.size_range_id;
    }


    getSashSizeById(id){
        return this.configuratorsDataService.data.sizeRanges.find(
            (range) => Number(range.id) === Number(id)
        );
    }

    getRange(doorSizes: DoorSizes, field?: 'customHeight' | 'customWidth') {
        const sashSizes = this.doorSizes.find(s => s.id === doorSizes.sashSizeId);
        const sizeRange = this.configuratorsDataService.data.sizeRanges.find(range => Number(range.id) === Number(sashSizes.size_range_id));
        if (sizeRange) {
            const sizesXY = (sizeRange.sizes || []).map(size => ({
                x: Number(size.width),
                y: Number(size.height),
            }));
            return {
                ...core.getRangeFromPoly(sizesXY),
                step: field === 'customWidth' ? (sashSizes?.size_interval_custom_width || 1) : (sashSizes?.size_interval_height || 1)
            };
        }
        return {
            minX: 0,
            minY: 0,
            maxX: Number.MAX_VALUE,
            maxY: Number.MAX_VALUE,
            step: field === 'customWidth' ? (sashSizes?.size_interval_custom_width || 1) : (sashSizes?.size_interval_height || 1)
        };
    }


    setStandardHeightSelected(value: boolean) {
        this.isStandardHeightSelected$.next(value);
    }

    get standardHeightSelected() {
        return this.isStandardHeightSelected$.getValue();
    }

    getSashWidthInRebateBasedOnConstructionWidth(conf: DoorActiveConfiguration | WindowActiveConfiguration) {
        const frameProfile = conf.UsedProfiles.find(p => p.type === 'frame') || this.profilesService.getProfile(conf.ProfileSet.frameSide);
        const leftLightSize = (conf.doorSizes && conf.doorSizes.leftLightSize) || 0;
        const rightLightSize = (conf.doorSizes && conf.doorSizes.rightLightSize) || 0;

        return conf.Width - leftLightSize - rightLightSize - (2 * frameProfile?.spaceSash || 0) + (this.getBiggerRebateValueFromVirtualDoorSash(conf) * 2);
    }

    getSashHeightInRebateBasedOnConstructionHeight(conf: DoorActiveConfiguration | WindowActiveConfiguration) {
        const frame = conf.UsedProfiles.find(p => p.type === 'frame') || this.profilesService.getProfile(conf.ProfileSet.frameSide);
        const threshold = conf.UsedProfiles.find(p => p.type === 'threshold') || this.profilesService.getProfile(conf.ProfileSet.threshold);
        const topLight = (conf.doorSizes && conf.doorSizes.topLightSize) || 0;

        return conf.Height - topLight - (frame?.spaceSash || 0) + (threshold?.spaceSash || 0) + this.getBiggerRebateValueFromVirtualDoorSash(conf);
    }

    getBiggerRebateValueFromVirtualDoorSash(conf: DoorActiveConfiguration | WindowActiveConfiguration) {
        const virtualDoorSash = conf.UsedProfiles.find(p => p.type === 'virtual_door_sash');

        if (virtualDoorSash) {
            if (virtualDoorSash.rebateWidth && virtualDoorSash.rebateWidthOut) {
                return Math.max(virtualDoorSash.rebateWidth, virtualDoorSash.rebateWidthOut)
            } else if (virtualDoorSash.rebateWidth && !virtualDoorSash.rebateWidthOut) {
                return virtualDoorSash.rebateWidth;
            } else if (!virtualDoorSash.rebateWidth && virtualDoorSash.rebateWidthOut) {
                return virtualDoorSash.rebateWidthOut
            } else {
                return 0;
            }
        }
    }
}
