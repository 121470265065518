import { Injectable, Inject } from '@angular/core';
import { Observable, merge, ReplaySubject, Subject, from } from 'rxjs';
import {
    map,
    publish,
    flatMap,
    combineLatest,
    filter,
    startWith,
    bufferWhen,
    take,
} from 'rxjs/operators';
import { ActiveConfiguration } from './configurations/ActiveConfiguration';
import * as Sentry from '@sentry/browser';
import { logger } from './helpers';
import { Common } from './Common';

export class IccEvent<T> {
    key: string;
    value: T;
    conf?: ActiveConfiguration;
    defaultConf?: ActiveConfiguration;
}

export class ObserverData<T> {
    key: string;
    value: T;
    activeConfiguration: ActiveConfiguration;
    defaultConfiguration: ActiveConfiguration;
    user: any;
    isEdited: boolean;
}

@Injectable()
export class EventBusService {
    private bus = new ReplaySubject<IccEvent<any>>();

    private events = [
        'initializedConfigurator',
        'changedSashes',
        'changedFrames',
        '#changedStep',
        '#startedChangingStep',
        'setDefaultColors',
        'setDefaultLayout',
        'setConstructionColor',
        'setGlazingInSash',
        'loadedFillings',
        'setGlazingBeadColor',
        'setGlazingBeadInSash',
        'loadedGlazingBeads',
        'setSealColor',
        'setSiliconeColor',
        'setWarmEdge',
        'loadedWarmEdges',
        'setFrameProfile',
        'setSashProfile',
        'setMullionProfile',
        'setSystem',
        'loadedConfiguratorsData',
        'loadedFillingsColors',
        'loadedProfiles',
        'setProfileSet',
        'validProfileSet',
        'validatedFillings',
        'validatedProfiles',
        'changedFillings',
        'putAlignmentInField',
        'removedAlignmentInField',
        'removedAlignment',
        'putExtensionOnSide',
        'removeExtensionBySide',
        'removedExtensionFromSide',
        'setExtensionProfile',
        'setFillingColor',
        'setProfileColor',
        'setShape',
        'setLowThreshold',
        'unsetLowThreshold',
        'foundedPrices',
        'changedBalcony',
        'loadedMuntinsColors',
        'setMuntinType',
        'setMuntinColor',
        'insertMuntins',
        'removeMuntins',
        'updateMuntins',
        'googleTags',
        'tutorialSteps',
        'setBondedGlazingInSash',
        'alushellColorTypeChange',
        'changedState',
        'changedOffer',
        'changedOfferObject',
        'syncedCustomPrices',
        'syncedDiscountsAndMultipliers',
        'syncedPrices',
        'isUser',
        'changedConfigurator',
        'icc-redraw',
        'syncedPositions',
        'syncedOffers',
        'syncedConfigurators',
        'syncedAdditionals',
        'syncedClients',
        'syncedLayouts',
        'syncedOfferAttachments',
        'syncedPositionAttachments',
        'connected',
        'progress',
        'changedToken',
        'syncedTranslations',
        'syncedSettings',
        'syncedUsers',
        'synced',
        'redraw',
        'wideSash',
        'updatedClient',
        'updatedOffer',
        'updatedOfferAttachment',
        'updatedPopularLayout',
        'updatedPosition',
        'updatedPositionAttachment',
        'updatedSynchronization',
        'modifiedOffer',
        'changedLang',
        'availableConfigs',
        'reloadColorsData',
        'correctTotalOfferPrice',
        'correctedTotalOfferPrice',
        'processDependencies',
        'offerUsersChanged',
        'importedClients',
        'saveGuideProfile',
        'saveSlatProfile',
        'setCouplingProfile',
        'updatedCoupledWindow',
        'refreshHandlesColors',
        'changedShutter',
        'updatedPrice',
        'changedHandleType',
        'changedAlushell',
        'changedOptions',
        'changedFitting',
        'changedFinWidths',
        'montageOptionsChanged',
        'changedDrive',
        'setShutterColor',
        'changedDoorHardware',
        'changedDoorSizes',
        'configurationChanged',
        'setPanelGlazing',
        'loadedThresholdColors',
        'setThresholdColor',
        'setLippingColor',
        'loadedLippingColor',
        'syncedTransportCosts',
        'setDoorPortal',
        'setDoorPortalColor',
        'changedShortening',
        'syncedMontages',
        'changedConfiguratorLocal',
        'dismissMessage',
        'accessoryColorBasedOnMapping'
    ];

    private pauseId = 0;

    private subjects: {
        [key: string]: {
            observable: Subject<IccEvent<any>>;
            pauser: Subject<boolean>;
            paused: number[];
        };
    } = {};

    private currentConfigurations = {
        active: null,
        default: null,
        edit: null,
    };

    private currentUser = null;

    constructor() {
        this.events.forEach(event => {
            let replay = true;
            if (event[0] === '#') {
                event = event.substr(1);
                replay = false;
            }
            this.subjects[event] = {
                observable: replay
                    ? new ReplaySubject<IccEvent<any>>(1)
                    : new Subject<IccEvent<any>>(),
                pauser: new Subject<boolean>(),
                paused: [],
            };
        });
    }

    subscribe<T>(
        key: string,
        next?: (value: ObserverData<T>) => void,
        error?: (error: any) => void,
        complete?: () => void
    );
    subscribe<T>(
        key: string[],
        next?: (value: ObserverData<T>) => void,
        error?: (error: any) => void,
        complete?: () => void
    );
    subscribe<T>(
        key: string | string[],
        next?: (value: ObserverData<T>) => void,
        error?: (error: any) => void,
        complete?: () => void
    ) {
        if (
            (Common.isArray(key) && key.some(k => !this.subjects[k]))
            || (Common.isString(key) && !this.subjects[key] && key !== '*')
        ) {
            logger.debug('%cEVENT SUB %s', 'background: lightblue; color: red', key);
            return;
        }
        let observable;
        if (key === '*') {
            observable = merge<IccEvent<T>>(
                ...Object.keys(this.subjects).map(k =>
                    this.pausableBuffer<IccEvent<T>>(this.subjects[k])
                )
            );
        } else if (Common.isArray(key)) {
            observable = merge<IccEvent<T>>(
                ...key.map(k => this.pausableBuffer<IccEvent<T>>(this.subjects[k]))
            );
        } else {
            observable = this.pausableBuffer<IccEvent<T>>(this.subjects[key]);
        }

        return observable
            .pipe(
                map((event: IccEvent<T>) => ({
                    key: event.key,
                    value: event.value,
                    activeConfiguration: event.conf
                        ? event.conf
                        : this.currentConfigurations
                        ? this.currentConfigurations.active
                        : null,
                    defaultConfiguration: event.defaultConf
                        ? event.defaultConf
                        : this.currentConfigurations
                        ? this.currentConfigurations.default
                        : null,
                    user: this.currentUser,
                    isEdited:
                        this.currentConfigurations && this.currentConfigurations.edit
                            ? true
                            : false,
                }))
            )
            .subscribe(next);
    }

    subscribeWithoutConfiguration<T>(
        key: string,
        next?: (value: ObserverData<T>) => void,
        error?: (error: any) => void,
        complete?: () => void
    );
    subscribeWithoutConfiguration<T>(
        key: string[],
        next?: (value: ObserverData<T>) => void,
        error?: (error: any) => void,
        complete?: () => void
    );
    subscribeWithoutConfiguration<T>(
        key: string | string[],
        next?: (value: ObserverData<T>) => void,
        error?: (error: any) => void,
        complete?: () => void
    ) {
        if (
            (Common.isArray(key) && key.some(k => !this.subjects[k]))
            || (Common.isString(key) && !this.subjects[key] && key !== '*')
        ) {
            let notExistingKeys = key;
            if (Common.isArray(key)) {
                notExistingKeys = key.filter(k => !this.subjects[k]).join();
            }
            logger.debug(
                '%cEVENT Takie zdarzenie nie istnieje %s',
                'background: lightblue; color: red',
                notExistingKeys
            );
            return;
        }
        if (key === '*') {
            return merge<IccEvent<T>>(
                ...Object.keys(this.subjects).map(k =>
                    this.pausableBuffer<IccEvent<T>>(this.subjects[k])
                )
            )
                .pipe(
                    map((event: IccEvent<T>) => ({
                        key: event.key,
                        value: event.value,
                        activeConfiguration: this.currentConfigurations
                            ? this.currentConfigurations.active
                            : null,
                        user: this.currentUser,
                    }))
                )
                .subscribe(next);
        } else if (Common.isArray(key)) {
            return merge<IccEvent<T>>(
                ...key.map(k => this.pausableBuffer<IccEvent<T>>(this.subjects[k]))
            )
                .pipe(
                    map((event: IccEvent<T>) => ({
                        key: event.key,
                        value: event.value,
                        activeConfiguration: this.currentConfigurations
                            ? this.currentConfigurations.active
                            : null,
                        user: this.currentUser,
                    }))
                )
                .subscribe(next);
        } else {
            return this.pausableBuffer<IccEvent<T>>(this.subjects[key])
                .pipe(
                    map((event: IccEvent<T>) => ({
                        key: event.key,
                        value: event.value,
                        activeConfiguration: this.currentConfigurations
                            ? this.currentConfigurations.active
                            : null,
                        user: this.currentUser,
                    }))
                )
                .subscribe(next);
        }
    }

    toPromise<T>(
        key: string,
        next?: (value: ObserverData<T>) => void,
        error?: (error: any) => void,
        complete?: () => void
    );
    toPromise<T>(
        key: string[],
        next?: (value: ObserverData<T>) => void,
        error?: (error: any) => void,
        complete?: () => void
    );
    toPromise<T>(key: string | string[]) {
        if (
            (Common.isArray(key) && key.some(k => !this.subjects[k]))
            || (Common.isString(key) && !this.subjects[key] && key !== '*')
        ) {
            let notExistingKeys = key;
            if (Common.isArray(key)) {
                notExistingKeys = key.filter(k => !this.subjects[k]).join();
            }
            logger.debug(
                '%cEVENT Takie zdarzenie nie istnieje %s',
                'background: lightblue; color: red',
                notExistingKeys
            );
            return;
        }
        if (key === '*') {
            return merge<IccEvent<T>>(
                ...Object.keys(this.subjects).map(k =>
                    this.pausableBuffer<IccEvent<T>>(this.subjects[k])
                )
            )
                .pipe(
                    map((event: IccEvent<T>) => ({
                        key: event.key,
                        value: event.value,
                        activeConfiguration: this.currentConfigurations
                            ? this.currentConfigurations.active
                            : null,
                        user: this.currentUser,
                    })),
                    take(1)
                )
                .toPromise();
        } else if (Common.isArray(key)) {
            return merge<IccEvent<T>>(
                ...key.map(k => this.pausableBuffer<IccEvent<T>>(this.subjects[k]))
            )
                .pipe(
                    map((event: IccEvent<T>) => ({
                        key: event.key,
                        value: event.value,
                        activeConfiguration: this.currentConfigurations
                            ? this.currentConfigurations.active
                            : null,
                        user: this.currentUser,
                    })),
                    take(1)
                )
                .toPromise();
        } else {
            return this.pausableBuffer<IccEvent<T>>(this.subjects[key])
                .pipe(
                    map((event: IccEvent<T>) => ({
                        key: event.key,
                        value: event.value,
                        activeConfiguration: this.currentConfigurations
                            ? this.currentConfigurations.active
                            : null,
                        user: this.currentUser,
                    })),
                    take(1)
                )
                .toPromise();
        }
    }

    post<T>(event: IccEvent<T>) {
        logger.debug('%cEVENT %s', 'background: lightblue; color: darkblue', event.key);
        Sentry.addBreadcrumb({
            message: event.key,
            category: 'event',
        });

        if (!this.subjects[event.key]) {
            throw new Error(
                `Nie ma takiego zdarzenia! Jeśli chcesz je wyemitować dopisz ${
                    event.key
                } do listy w event-bus.service.ts`
            );
        }
        this.subjects[event.key].observable.next(event);
    }

    pause(events: string[]) {
        const pauseId = this.pauseId++;
        logger.debug('%cPAUSE %s %s', 'background: grey; color: black', events.join(','), pauseId);
        events.forEach(event => {
            this.subjects[event].paused.length === 0 && this.subjects[event].pauser.next(false);
            this.subjects[event].paused.push(pauseId);
        });

        return pauseId;
    }

    resume(events: string[], pauseId: number) {
        logger.debug('%cRESUME %s %s', 'background: lightpink; color: darkpink', events.join(','), pauseId);
        events.forEach(event => {
            this.subjects[event].paused.splice(this.subjects[event].paused.indexOf(pauseId), 1);
            this.subjects[event].paused.length === 0 && this.subjects[event].pauser.next(true);
        });
    }

    pausableBuffer<T>(event: { observable: Subject<T>; pauser: Subject<boolean> }) {
        return event.observable.pipe(
            publish(input =>
                input.pipe(
                    combineLatest(event.pauser.pipe(startWith(true)), (v, b) => b),
                    filter(e => e),
                    publish(s => input.pipe(bufferWhen(() => s.pipe(take(1)))))
                )
            ),
            flatMap(e => from(e.slice(-1)))
        );
    }

    setCurrentConfiguration(conf) {
        this.currentConfigurations.active = conf ? conf.Current : null;
        this.currentConfigurations.default = conf ? conf.Default : null;
        this.currentConfigurations.edit = conf ? conf.Edit : null;
    }

    setCurrentUser(user) {
        this.currentUser = user;
    }
}
