/* eslint-disable no-eval */
import {Injectable, Inject} from '@angular/core';
import { WindowActiveConfiguration } from '@icc/common/configurations/WindowActiveConfiguration';
import {ConfiguratorsDataService} from '@icc/common/configurators/configurators-data.service';
import {IssueLevel} from '@icc/common/issues.service';
import { ProfilesService } from '@icc/common/profiles.service';
import {EventBusService} from '@icc/common/event-bus.service';
import {APP_CONFIG, AppConfig, AppConfigFactory} from '@icc/common/config';;
import { Common } from '@icc/common/Common';
import { IssuesService, InfoService } from '@icc/helpers';
import { AccessoriesService } from '../configurator/steps/window/accessories/accessories.service';
import { ColorsDefaultsService } from '@icc/common/colors/colors-defaults.service';
import { ColorMappingService } from '@icc/common/colors/colors-mapping.service';
import { core } from '@icc/common/helpers';
import { Occurrence } from './occurrence.interface';

@Injectable()
export class DependenciesService {

    occurrence: Occurrence[] = [];

    constructor(
        private eventBusService: EventBusService,
        @Inject(APP_CONFIG) private config: AppConfigFactory,
        private configuratorsDataService: ConfiguratorsDataService,
        private issuesService: IssuesService,
        private infoService: InfoService,
        private profilesService: ProfilesService,
        private accessoriesService: AccessoriesService,
        private colorsDefaultsService: ColorsDefaultsService,
        private colorMappingService: ColorMappingService,
    ) {
        if (this.config().IccConfig.Configurators.dependencies) {
            this.eventBusService.subscribe(['processDependencies'], data => {
                if (
                    Common.isDefined(
                        (<WindowActiveConfiguration>data.activeConfiguration).Layout
                    ) &&
                    Common.isDefined(
                        (<WindowActiveConfiguration>data.activeConfiguration).Layout.id
                    ) &&
                    (<WindowActiveConfiguration>data.activeConfiguration).Layout.id
                ) {
                    this.process(<WindowActiveConfiguration>data.activeConfiguration);
                }
            });
        }
    }

    /**
     * Funkcja sprawdzająca zależności
     * @param {object} conf Konfiguracja
     * @memberof DependenciesService
     */
    process(conf) {
        if (Common.isObject(conf)) {
            this.issuesService.unregister('dependencyBlockade', conf);
            conf.Dependencies = [];
            const dependencies = this.configuratorsDataService.data.dependencies || [];
            const sashesDependenciesAccessories = core.copy(conf.Sashes.map(sash => sash.hardware.filter(el => el.accessory.fromDependency && el.accessory.changedColor)));
            conf.Sashes.map(sash => sash.hardware = sash.hardware.filter(el => !el.accessory.fromDependency));

            dependencies.forEach(dependency => {
                if (dependency.conditions.length && dependency.actions.length) {
                    if (
                        dependency.conditions.some(
                            el => el.blockType === 'sash'
                        )
                        || dependency.actions.some(
                            el => el.blockType === 'sash' || el.valueType === 'sash'
                        )
                    ) {
                        conf.Sashes.forEach((sash, index) => {
                            if (
                                Common.isObject(sash) &&
                                eval(this.check(dependency, dependency.conditions, dependency.id, conf, sash).check)
                            ) {
                                eval(this.check(dependency, dependency.actions, dependency.id, conf, sash, sashesDependenciesAccessories[index]).check);
                            }
                        }, this);
                    } else {
                        const checkResult = this.check(dependency, dependency.conditions, dependency.id, conf);
                        const check = eval(checkResult.check);
                        const dependencyAssociationIds = checkResult.dependencyAssociationIds;
                        
                        if (dependency.actions.find(action => action.type === 'addRemovableAccessoryToConstruction')) {
                            this.removeAccessoriesBasedOnOccurrences(dependency, conf, check);
                        }

                        if (check && 
                            dependency.actions.find(a => a.type === 'addRemovableAccessoryToConstruction')
                            && this.occurrence.find(k => k.id === dependency.id)?.occurrence <= 1) {
                            eval(this.check(dependency, dependency.actions, dependency.id, conf, null, [], dependencyAssociationIds).check);
                        } else if (check && !dependency.actions.find(a => a.type === 'addRemovableAccessoryToConstruction')) {
                            eval(this.check(dependency, dependency.actions, dependency.id, conf, null, [], dependencyAssociationIds).check);
                        }
                    }
                }
            }, this);
        }
    }


    private removeAccessoriesBasedOnOccurrences(dependency: any, conf: any, check: any) {
        dependency.conditions.forEach(condition => {
            switch (condition.type) {
                case 'system': {
                    this.setDependencyOccurrence(condition, conf, dependency, check);
                    break;
                }
            }
        });
    }

    private setDependencyOccurrence(condition: any, conf: any, dependency: any, check: boolean) {
        const isSystemValid = this.checkSystem(condition.value, conf);
        const foundDependency = this.occurrence.find(k => k.id === dependency.id);

        if (isSystemValid && check) {
            if (!foundDependency) {
                this.occurrence.push({ id: dependency.id, occurrence: 1 });
            } else {
                foundDependency.occurrence += 1;
            }
        } else if (foundDependency) {
            foundDependency.occurrence = 0;
        }
    }

    /**
     * Funkcja sprawdzająca warunki i tworząca wyrażenie
     * @param {array} conditions     warunki
     * @param {number} dependencyId  Id zależności
     * @param {object} [conf={}]     Konfiguracja
     * @param {object} [sash=null]   Skrzydło
     * @returns {string}
     * @memberof DependenciesService
     */
    check(dependency, conditions, dependencyId, conf: WindowActiveConfiguration, sash = null, sashDependenciesAccessories = [], conditionDependencyAssociationIds = []) {
        let check = '';
        const dependencyAssociationIds = [];
        // eslint-disable-next-line max-statements
        conditions.forEach(condition => {
            switch (condition.type) {
                case 'productType':
                    check += ' ' + this.checkProductType(condition.value, conf); break;
                case 'system':
                    check += ' ' + this.checkSystem(condition.value, conf); break;
                case 'fitting':
                    check += ' ' + this.checkFitting(condition.value, conf); break;
                case 'sashType':
                    check += ' ' + this.checkSashType(condition.value, sash); break;
                case 'sashFillingOuterType':
                    check += ' ' + this.checkSashFillingOuterType(condition.value, conf); break;
                case 'sashFillingInnerType':
                    check += ' ' + this.checkSashFillingInnerType(condition.value, conf); break;
                case 'sashTypeInConstruction':
                    check += ' ' + this.checkSashTypeInConstruction(condition.value, conf); break;
                case 'withoutSashType':
                    check += ' ' + this.checkWithoutSashType(condition.value, conf); break;
                case 'sashWeight':
                    check += ' ' + this.checkSashWeight(condition.value, sash); break;
                case 'shape':
                    check += ' ' + this.checkShape(condition.value, conf); break;
                case 'sashHeight':
                    check += ' ' + this.checkSashHeight(condition.value, sash); break;
                case 'sashWidth':
                    check += ' ' + this.checkSashWidth(condition.value, sash); break;
                case 'height':
                    check += ' ' + this.checkHeight(condition.value, conf); break;
                case 'width':
                    check += ' ' + this.checkWidth(condition.value, conf); break;
                case 'doorHeight':
                    check += ' ' + this.checkDoorHeight(condition.value, conf); break;
                case 'colorType':
                    check += ' ' + this.checkColorType(condition.value, conf); break;
                case 'profile':
                    check += ' ' + this.checkProfile(condition.value, conf); break;
                case 'sizeRange':
                    check += ' ' + this.checkSizeRange(condition.data, conf.Width, conf.Height); break;
                case 'sizeRangeSash':
                    const sashDimensions = conf.drawData?.sash.find(s => sash && s.sashId == sash.id);
                    check += ' ' + (sashDimensions ? this.checkSizeRange(condition.data, sashDimensions.inner.rect.width, sashDimensions.inner.rect.height) : false); break;
                case 'shortening':
                    { 
                        const shortening = this.checkShortening(condition.value, conf);
                        check += ' ' + shortening;
                        if (!shortening) {
                            this.removeDependentAccessories(conf, dependencyId);
                        }
                        break;
                    }
                case 'frameShortening':
                    {
                        const frameShortening = this.checkFrameShortening(condition.value, conf);
                        check += ' ' + frameShortening;
                        if (!frameShortening) {
                            this.removeDependentAccessories(conf, dependencyId);
                        }
                        break;
                    }
                case 'accessory':
                    {
                        const checkAccessory = this.checkAccessory(condition.value, conf);
                        this.removeDependentItems(condition.value, conf, dependencyId);
                        
                        const isAccessoryBlockedToConfiguration = dependency.actions.find(p => p.value === 'blockade_to_configuration');
                        if (isAccessoryBlockedToConfiguration) {
                            check += ' ' + true;
                        } else {
                            check += ' ' + checkAccessory;
                        }

                        if (checkAccessory || isAccessoryBlockedToConfiguration) {
                            dependencyAssociationIds.push(condition.value);
                        }
                        break;
                    }
                case 'accessoriesCategory':
                    check += ' ' + this.checkAccessoriesCategory(condition.value, conf); break;
                case 'sashAccessory':
                    check += ' ' + this.checkSashAccessory(condition.value, sash); break;
                case 'sashAccessoriesCategory':
                    check += ' ' + this.checkSashAccessoriesCategory(condition.value, sash); break;
                case 'arcHeightInSash':
                    check += ' ' + this.checkArcHeightInSash(condition, conf, sash); break;
                case 'dividerOnSlant':
                    check += ' ' + this.checkDividerOnSlant(conf); break;
                case 'dividerOnSlantVertical':
                    check += ' ' + this.checkDividerOnSlantVertical(conf); break;
                case 'mosquitoType':
                    check += ' ' + this.checkMosquitoType(condition.value, sash); break;
                case 'lock':
                    check += ' ' + this.checkLock(condition.value, conf); break;
                case 'pull':
                    {
                        const checkPull = this.checkPull(condition.value, conf);
                        this.removeDependentItems(condition.value, conf, dependencyId);
                        const isBlockadeAvailable = this.isPullBlockadeAvailable(conf, dependency, condition);

                        if (isBlockadeAvailable) {
                            check += ' ' + true;
                        } else {
                            check += ' ' + checkPull;
                        }

                        if ((isBlockadeAvailable) || checkPull) {
                            dependencyAssociationIds.push(condition.value);
                        }

                        break;
                    }
                case 'extension':
                    {
                        const accessoryDependencies = dependency.conditions.filter(d => d.type === 'accessory');
                        const accessoryIds = accessoryDependencies && accessoryDependencies.map(k => k.value);

                        const isHingeViable = conf.Hinge?.id && accessoryIds.includes(conf.Hinge.id);
                        const blockedToConfiguration = dependency.actions.some((p) => p.value === 'blockade_to_configuration');
                        const isHingeBlockedToConfiguration = isHingeViable && blockedToConfiguration;
                        this.configuratorsDataService.data.dependencies.find(d => d.id === dependency.id).blockedExtension = isHingeBlockedToConfiguration;
                       
                        if (blockedToConfiguration) {
                            check += ' ' + true;
                        } else {
                            check += ' ' + this.checkExtension(condition.value, conf);
                        }

                        break;
                    }
                case 'sashLayoutVariant':
                    {
                        const accessoryDependencies = dependency.conditions.filter(d => d.type === 'accessory');
                        const accessoryIds = accessoryDependencies && accessoryDependencies.map(k => k.value);
                        
                        const isHingeViable = conf.Hinge?.id && accessoryIds.includes(conf.Hinge.id);
                        const blockedToConfiguration = dependency.actions.some((p) => p.value === 'blockade_to_configuration');
                        const isHingeBlockedToConfiguration = isHingeViable && blockedToConfiguration;
                        this.configuratorsDataService.data.dependencies.find(d => d.id === dependency.id).blockedSashLayoutVariant = isHingeBlockedToConfiguration;

                        if (blockedToConfiguration) {
                            check += ' ' + true;
                        } else {
                            check += ' ' + this.checkSashLayoutVariant(condition.value, conf);
                        }

                        break;
                    }
                case 'addAccessoryToConstruction':
                    check += ' ' + this.addAccessoryToConstruction(condition, conf, dependencyId, conditionDependencyAssociationIds); break;
                case 'addRemovableAccessoryToConstruction':
                    check += ' ' + this.addAccessoryToConstruction(condition, conf, dependencyId, conditionDependencyAssociationIds); break;
                case 'addAccessoryToSash':
                    check += ' ' + this.addAccessoryToSash(condition.value, sash, conf, sashDependenciesAccessories); break;
                case 'openingBracket':
                    check += ' ('; break;
                case 'closingBracket':
                    check += ' )'; break;
                case 'and':
                    check += ' &&'; break;
                case 'or':
                    check += ' ||'; break;
                case 'not':
                    check += ' !'; break;
                case 'info':
                    check += this.info(condition); break;
                case 'blockade':
                    check += this.blockade(condition.value, dependencyId); break;
                case 'surcharge':
                    check += this.surcharge(condition, dependencyId, sash); break;
            }
        });

        return {check, dependencyAssociationIds};
    }


    checkSashLayoutVariant(dependencyValue: { sashesLayoutsVariantsOptions, sashesLayoutsVariantsTypes}, conf: WindowActiveConfiguration) {
        const ALL_SASHES_LAYOUTS_VARIANTS = "ALL_SASHES_LAYOUTS_VARIANTS";
        const layoutsIds = dependencyValue?.sashesLayoutsVariantsTypes?.map(k => k.id);
    
        if (!layoutsIds.length) {
            return;
        }

        const selectedAllSashesVariants = layoutsIds.includes(ALL_SASHES_LAYOUTS_VARIANTS);
        const isCurrentLayoutBlocked = dependencyValue.sashesLayoutsVariantsTypes.some((k) => k.id === conf.Layout.id);

        if (dependencyValue?.sashesLayoutsVariantsOptions?.includes('from_hinge_side')) {
            const handlePosition = conf.Sashes?.find((k) => k.type?.handle_position)?.type?.handle_position;

            switch(handlePosition) {
                case 'L': {
                    return selectedAllSashesVariants && conf.OwnedSashesTypes.doorRightLight ||
                    isCurrentLayoutBlocked
                }
                case 'R': {
                    return selectedAllSashesVariants && conf.OwnedSashesTypes.doorLeftLight || 
                    isCurrentLayoutBlocked
                }
                default: return false;
            }
        } else {
            return (
                isCurrentLayoutBlocked ||
                (selectedAllSashesVariants ? (
                    conf.OwnedSashesTypes.doorRightLight || 
                    conf.OwnedSashesTypes.doorLeftLight || 
                    conf.OwnedSashesTypes.doorTopLight) : false)
            )
        }
    }

    checkExtension(value: { extensionOptions, extensionTypes }, conf: WindowActiveConfiguration) {
        const ALL_EXTENSIONS = "ALL_EXTENSIONS";
        const extensionIds = value.extensionTypes.map(k => k.id);
     
        if (!extensionIds.length) {
            return;
        }

        if (value?.extensionOptions?.includes('from_hinge_side')) {
            const handlePosition = conf.Sashes.find((k) => k.type.handle_position).type.handle_position;
            switch(handlePosition) {
                case 'L': {
                    const sideProfiles = conf.SideProfiles.filter(k => k.side === 'right');
                    return (
                        (extensionIds.includes(ALL_EXTENSIONS) && conf.SideProfiles.length) ||
                        sideProfiles.some((sp) => extensionIds.includes(sp.profileId))
                    );
                }
                case 'R': {
                    const sideProfiles = conf.SideProfiles.filter(k => k.side === 'left');
                    return (
                        (extensionIds.includes(ALL_EXTENSIONS) && conf.SideProfiles.length) ||
                        sideProfiles.some((sp) => extensionIds.includes(sp.profileId))
                    );
                }
                default: return false
            }
        } else {
            return (
                value.extensionTypes.some((k) => conf.SideProfiles.some(sp => sp.profileId === k.id)) ||
                extensionIds.includes(ALL_EXTENSIONS) && conf.SideProfiles.length
            );
        }
    }
    /**
     * Wyświetlanie informacji
     * @param {object} info Informacja
     * @returns {string|bolean}
     * @memberof DependenciesService
     */
    info(info) {
        if (['info', 'warning', 'error'].includes(info.valueType)) {
            return ' this.infoService.openWarning("' + info.value + '")';
        }
        return true;
    }

    /**
     * Blokowanie dodawania do oferty lub wymuszenie przekazania do wyceny
     * @param {object} blockade     Blokada
     * @param {number} dependencyId Id zależności
     * @returns {string|bolean}
     * @memberof DependenciesService
     */
    blockade(blockade, dependencyId) {
        if (blockade == 'blockade_to_configuration') {
            return ' conf.Dependencies.push({id: ' + dependencyId + ', type: "blockade_to_configuration"})';
        } else if (blockade == 'blockade') {
            return ' (this.issuesService.simpleRegister("dependencyBlockade", "Dependency Blockade", "", conf, {blockAddToOffer: true, showMessage: false, logLevel: ' + IssueLevel.NONE + '}))';
        } else if (blockade == 'valuation') {
            return ' conf.Dependencies.push({id: ' + dependencyId + ', type: "valuation"})';
        }
        return true;
    }

    /**
     * Dopłata do skrzydła lub konstrukcji
     * @param {object} surcharge    Dopłata
     * @param {number} dependencyId Id zależnośći
     * @param {object} sash         Skrzydło
     * @returns {string|bolean}
     * @memberof DependenciesService
     */
    surcharge(surcharge, dependencyId, sash) {
        if (surcharge.valueType == 'sash') {
            return ' conf.Dependencies.push({id: ' + dependencyId + ', type: "sash", sashId: ' + sash.id + ', value: ' + surcharge.value + '})';
        } else if (surcharge.valueType == 'construction') {
            return ' conf.Dependencies.push({id: ' + dependencyId + ', type: "construction", sashId: null , value: ' + surcharge.value + '})';
        }
        return true;
    }

    /**
     * Sprawdzanie typu produktu
     * @param {string} value Wartość do sprawdzenia
     * @param {object} conf  Konfiguracja pozycji
     * @returns {boolean}
     * @memberof DependenciesService
     */
    checkProductType(value, conf) {
        if (Common.isObject(conf.System) && Common.isDefined(conf.System.type) && value == conf.System.type) {
            return true;
        }
        return false;
    }

    /**
    * Sprawdzanie systemu produktu
    * @param {string} value Wartość do sprawdzenia
    * @param {object} conf  Konfiguracja pozycji
    * @returns {boolean}
    * @memberof DependenciesService
    */
    checkSystem(value, conf) {
        if (Common.isObject(conf.System) && Common.isDefined(conf.System.id) && value && value.map(Number).indexOf(Number(conf.System.id)) > -1) {
            return true;
        }
        return false;
    }

    /**
     * Sprawdzanie okucia
     * @param {string} value Wartość do sprawdzenia
     * @param {object} conf  Konfiguracja pozycji
     * @returns {boolean}
     * @memberof DependenciesService
     */
    checkFitting(value, conf) {
        if (Common.isObject(conf.Fitting) && Common.isDefined(conf.Fitting.id) && value == conf.Fitting.id) {
            return true;
        }
        return false;
    }

    /**
    * Sprawdzanie typu skrzydła
    * @param {string} value Wartość do sprawdzenia
    * @param {object} sash  Konfiguracja skrzydła
    * @returns {boolean}
    * @memberof DependenciesService
    */
    checkSashType(value, sash) {
        if (sash != null && Common.isObject(sash.type) && Common.isDefined(sash.type.id) && value == sash.type.id) {
            return true;
        }
        return false;
    }

    /**
     * Sprawdzanie wzoru wewnętrznego
     * @param value Wartość (identyfikator) do sprawdzenia
     * @param sash Konfiguracja skrzydła
     * @returns 
     * @memberof DependenciesService
     */
    checkSashFillingInnerType(value, conf) {
        if (value && conf.Sashes) {
            return conf.Sashes.find(sash => value.map(Number).indexOf(Number(sash?.panelInner?.id)) > -1) ? true : false;
        }
    }

    /**
     * Sprawdzanie wzoru zewnętrznego
     * @param value Wartość (identyfikator) do sprawdzenia
     * @param sash Konfiguracja skrzydła
     * @returns 
     * @memberof DependenciesService
     */
    checkSashFillingOuterType(value, conf) {
        if (value && conf.Sashes) {
            return conf.Sashes.find(sash => value.map(Number).indexOf(Number(sash?.glazing?.id)) > -1) ? true : false;
        }
    }
    
    /**
     * Sprawdzanie typu skrzydła w całej konstrukcji
     * @param {string} value Wartość do sprawdzenia
     * @param {object} conf  Konfiguracja pozycji
     * @returns {boolean}
     * @memberof DependenciesService
     */
    checkSashTypeInConstruction(value, conf) {
        if (
            conf != null
            && Common.isArray(conf.Sashes)
            && conf.Sashes.some(
                sash =>
                    Common.isObject(sash.type)
                    && Common.isDefined(sash.type.id)
                    && value == sash.type.id)
        ) {
            return true;
        }
        return false;
    }
    /**
     * Sprawdzanie nieobecności typu skrzydła
     * @param {string} value Wartość do sprawdzenia
     * @param {object} sash  Konfiguracja skrzydła
     * @returns {boolean}
     * @memberof DependenciesService
     */
    checkWithoutSashType(value, conf) {
        if (
            conf != null
            && Common.isArray(conf.Sashes)
            && conf.Sashes.every(sash => Common.isObject(sash.type) && Common.isDefined(sash.type.id) && value != sash.type.id)
        ) {
            return true;
        }
        return false;
    }

    /**
     * Sprawdzanie wagi skrzydła
     * @param {string} value Wartość do sprawdzenia
     * @param {object} sash  Konfiguracja skrzydła
     * @returns {boolean}
     * @memberof DependenciesService
     */
    checkSashWeight(value, sash) {
        if (sash != null && Common.isDefined(sash.weight) && sash.weight != null && sash.weight !== false && value.from <= sash.weight && value.to >= sash.weight) {
            return true;
        }
        return false;
    }

    /**
     * Sprawdzanie kształtu
     * @param {string} value Wartość do sprawdzenia
     * @param {object} conf  Konfiguracja pozycji
     * @returns {boolean}
     * @memberof DependenciesService
     */
    checkShape(value, conf) {
        if (Common.isObject(conf.Shape) && Common.isDefined(conf.Shape.shape) && value == conf.Shape.shape) {
            return true;
        }
        return false;
    }

    /**
     * Sprawdzanie wysokości skrzydła
     * @param {string} value Wartość do sprawdzenia
     * @param {object} sash  Konfiguracja skrzydła
     * @returns {boolean}
     * @memberof DependenciesService
     */
    checkSashHeight(value, sash) {
        if (sash != null && Common.isDefined(sash.rHeight) && sash.rHeight != null && sash.rHeight !== false && value.from <= sash.rHeight && value.to >= sash.rHeight) {
            return true;
        }
        return false;
    }

    /**
     * Sprawdzanie szerokości skrzydła
     * @param {string} value Wartość do sprawdzenia
     * @param {object} sash  Konfiguracja skrzydła
     * @returns {boolean}
     * @memberof DependenciesService
     */
    checkSashWidth(value, sash) {
        if (sash != null && Common.isDefined(sash.rWidth) && sash.rWidth != null && sash.rWidth !== false && value.from <= sash.rWidth && value.to >= sash.rWidth) {
            return true;
        }
        return false;
    }

    /**
     * Sprawdzanie wysokości konstrukcji
     * @param {string} value Wartość do sprawdzenia
     * @param {object} conf  Konfiguracja pozycji
     * @returns {boolean}
     * @memberof DependenciesService
     */
    checkHeight(value, conf) {
        if (Common.isDefined(conf.Height) && conf.Height != null && value.from <= conf.Height && value.to >= conf.Height) {
            return true;
        }
        return false;
    }

    /**
     * Sprawdzanie szerokości konstrukcji
     * @param {string} value Wartość do sprawdzenia
     * @param {object} conf  Konfiguracja pozycji
     * @returns {boolean}
     * @memberof DependenciesService
     */
    checkWidth(value, conf) {
        if (Common.isDefined(conf.Width) && conf.Width != null && value.from <= conf.Width && value.to >= conf.Width) {
            return true;
        }
        return false;
    }

    checkDoorHeight(value, conf) {
        const doorHeight = (Number(conf?.Height) || 0) - (Number(conf?.doorSizes?.topLightSize) || 0)- (Number(conf?.doorSizes?.bottomLightSize) || 0);

        if (value.from <= doorHeight && value.to >= doorHeight) {
            return true;
        }

        return false;
    }
    /**
     * Sprawdzanie typu koloru
     * @param {string} value Wartość do sprawdzenia
     * @param {object} conf  Konfiguracja pozycji
     * @returns {boolean}
     * @memberof DependenciesService
     */
    checkColorType(value, conf) {
        if (Common.isDefined(conf.Colors) && conf.Colors.frame) {
            const colors = conf.Colors;
            switch (value) {
                case 'White':
                    return colors.frame.core.type === 'white'
                        && !colors.frame.outer?.id
                        && !colors.frame.inner?.id;
                case 'Cream':
                    return colors.frame.core.type === 'cream'
                        && !colors.frame.outer?.id
                        && !colors.frame.inner?.id;
                case 'Outer':
                    return colors.frame.outer?.id && !colors.frame.inner?.id; 
                case 'Inner':
                    return !colors.frame.outer?.id && colors.frame.inner?.id;
                case 'Bilateral':
                    return colors.frame.outer?.id
                        && colors.frame.inner?.id
                        && colors.frame.outer?.id === colors.frame.inner?.id;
                case 'Bicolor':
                    return colors.frame.outer?.id
                        && colors.frame.inner?.id
                        && colors.frame.outer?.id !== colors.frame.inner?.id;
            }
        }
        return false;
    }

    /**
     * Sprawdzanie zakresu wymiarów
     * @param {string} value   Wartość do sprawdzenia
     * @param {number} width   Szerokość
     * @param {number} height  Wysokość
     * @returns {boolean}
     * @memberof DependenciesService
     */
    checkSizeRange(poly, width, height) {
        let inside = false;
        if (Common.isDefined(width) && Common.isDefined(height) && width != null && width !== false && height != null && height !== false) {
            const pointX = width;
            const pointY = height;
            for (let i = 0, j = poly.length - 1; i < poly.length; j = i++) {
                const xi = Number(poly[i].width);
                const yi = Number(poly[i].height);
                const xj = Number(poly[j].width);
                const yj = Number(poly[j].height);
                if (((yi > pointY) != (yj > pointY)) && (pointX < (xj - xi) * (pointY - yi) / (yj - yi) + xi)) {
                    inside = !inside;
                }
            }
        }
        return inside;
    }

    /**
     * Sprawdzanie akcesorium
     * @param {string} value Wartość do sprawdzenia
     * @param {object} conf  Konfiguracja pozycji
     * @returns {boolean}
     * @memberof DependenciesService
     */
    checkAccessory(value, conf) {
        if ((Number(conf.doorHardware?.plates?.mainLock) === Number(value))
            || (Number(conf.Hinge?.id) === Number(value))
            || (Common.isObject(conf.Handle)
                && Common.isDefined(conf.Handle.id)
                && conf.Handle.id == value)
            || (Common.isArray(conf.Accessories)
                && conf.Accessories.some(el => Common.isObject(el.accessory)
                    && Common.isDefined(el.accessory.id)
                    && el.accessory.id == value))
            || (Common.isArray(conf.Sashes)
                && conf.Sashes.some(sash =>
                    (Common.isObject(sash.handle)
                        && Common.isDefined(sash.handle.id)
                        && sash.handle.id == value)
                    || (Common.isArray(sash.hardware)
                        && sash.hardware.some(el => Common.isObject(el.accessory)
                            && Common.isDefined(el.accessory.id)
                            && el.accessory.id == value))))
            || (Common.isObject(conf.SideAccessories)
                && Object.keys(conf.SideAccessories).some(side => Common.isArray(conf.SideAccessories[side])
                    && conf.SideAccessories[side].some(el => Common.isObject(el.accessory)
                        && Common.isDefined(el.accessory.id)
                        && el.accessory.id == value)))
        ) {
            return true;
        }
        return false;
    }

    /**
     * Sprawdzanie akcesorium w konstrukcji
     * @param {string} value Wartość do sprawdzenia
     * @param {object} conf  Konfiguracja pozycji
     * @returns {boolean}
     * @memberof DependenciesService
     */
    checkConstructionAccessory(value, conf) {
        if (
            Common.isArray(conf.Accessories)
            && conf.Accessories.some(el => Common.isObject(el.accessory)
                && Common.isDefined(el.accessory.id)
                && el.accessory.id == value)
        ) {
            return true;
        }
        return false;
    }

    /**
     * Sprawdzanie akcesorium w skrzydle
     * @param {string} value Wartość do sprawdzenia
     * @param {object} sash  Konfiguracja skrzydła
     * @returns {boolean}
     * @memberof DependenciesService
     */
    checkSashAccessory(value, sash) {
        if ((sash != null
                && ((Common.isObject(sash.handle) && Common.isDefined(sash.handle.id) && sash.handle.id == value)
                    || (Common.isArray(sash.hardware)
                        && sash.hardware.some(el => Common.isObject(el.accessory)
                            && Common.isDefined(el.accessory.id)
                            && el.accessory.id == value))))
        ) {
            return true;
        }
        return false;
    }

    /**
     * Sprawdzanie kategorii akcesoriów
     * @param {string} value Wartość do sprawdzenia
     * @param {object} conf  Konfiguracja pozycji
     * @returns {boolean}
     * @memberof DependenciesService
     */
    checkAccessoriesCategory(value, conf) {
        if ((Common.isObject(conf.Handle)
                && Common.isDefined(conf.Handle.window_accessories_category_id)
                && conf.Handle.window_accessories_category_id == value)
            || (Common.isArray(conf.Accessories)
                && conf.Accessories.some(el => Common.isObject(el.accessory)
                    && Common.isDefined(el.accessory.window_accessories_category_id)
                    && el.accessory.window_accessories_category_id == value))
            || (Common.isArray(conf.Sashes)
                && conf.Sashes.some(sash =>
                    (Common.isObject(sash.handle)
                        && Common.isDefined(sash.handle.window_accessories_category_id)
                        && sash.handle.window_accessories_category_id == value)
                    || (Common.isArray(sash.hardware)
                        && sash.hardware.some(el => Common.isObject(el.accessory)
                            && Common.isDefined(el.accessory.window_accessories_category_id)
                            && el.accessory.window_accessories_category_id == value))))
            || (Common.isObject(conf.SideAccessories)
                && Object.keys(conf.SideAccessories).some(side => Common.isArray(conf.SideAccessories[side])
                    && conf.SideAccessories[side].some(el => Common.isObject(el.accessory)
                        && Common.isDefined(el.accessory.window_accessories_category_id)
                        && el.accessory.window_accessories_category_id == value)))
        ) {
            return true;
        }
        return false;
    }

    /**
     * Sprawdzanie kategorii akcesoriów w skrzydle
     * @param {string} value Wartość do sprawdzenia
     * @param {object} sash  Konfiguracja skrzydla
     * @returns {boolean}
     * @memberof DependenciesService
     */
    checkSashAccessoriesCategory(value, sash) {
        if ((sash != null
                && ((Common.isObject(sash.handle) && Common.isDefined(sash.handle.window_accessories_category_id) && sash.handle.window_accessories_category_id == value)
                    || (Common.isArray(sash.hardware)
                        && sash.hardware.some(el => Common.isObject(el.accessory)
                            && Common.isDefined(el.accessory.window_accessories_category_id)
                            && el.accessory.window_accessories_category_id == value))))
        ) {
            return true;
        }
        return false;
    }

    /**
     * Sprawdzanie wysokości łuku w skrzydle
     * @param {object} condition Wartość do sprawdzenia
     * @param {object} conf  Konfiguracja pozycji
     * @returns {boolean}
     * @memberof DependenciesService
     */
    checkArcHeightInSash(condition, conf, sash) {
        const openingSide = sash.type.symbol.indexOf('(R)') > -1 ? 'R' : (sash.type.symbol.indexOf('(L)') > -1 ? 'L' : false);
        if (openingSide && conf.drawData?.sashFrame) {
            const sashPoly = conf.drawData.sashFrame.find(o => o.sashId === sash.id);
            const sashHeight = sashPoly.outer.rect.height;

            if (conf.Shape.shape == 'arc'
                && ((conf.Shape.type == 'R' && openingSide == 'R') || (conf.Shape.type == 'L' && openingSide == 'L') || conf.Shape.type == 'F')
                && ((condition.valueType == '>' && (this.getArcHeight(sashPoly, openingSide) / sashHeight * 100) > (condition.value))
                    || (condition.valueType == '<' && (this.getArcHeight(sashPoly, openingSide) / sashHeight * 100) < (condition.value)))
            ) {
                return true;
            }
        }

        return false;
    }

    /**
     * Wyliczanie wysokości łuku w skrzydle
     * @param {object} sashPoly     Wymiary skrzydła
     * @param {string} openingSide  Strona otwierania
     * @returns {number}
     * @memberof DependenciesService
     */
    getArcHeight(sashPoly, openingSide) {
        let arcHeight = 0;
        const sashHeight = sashPoly.outer.rect.height;
        let sidePolyHeight = 0;
        let sidePoly;
        if (openingSide == 'R') {
            sidePoly = sashPoly.sides.find(el => el.outerEdge.side == 'right' && !el.outerEdge.circle);
        } else if (openingSide == 'L') {
            sidePoly = sashPoly.sides.find(el => el.outerEdge.side == 'left' && !el.outerEdge.circle);
        }
        if (sidePoly) {
            sidePolyHeight = sidePoly.rect.height;
        }
        arcHeight = sashHeight - sidePolyHeight;

        return arcHeight;
    }

    /**
     * Sprawdzanie czy słupek jest na połączeniu skosu z poziomą częścią ramy
     * @param {object} conf  Konfiguracja pozycji
     * @returns {boolean}
     * @memberof DependenciesService
     */
    checkDividerOnSlant(conf) {
        const verticalMullions = conf.Mullions.filter(el => el.direction === 'vertical');
         if (conf.Shape.shape === 'poligon' && verticalMullions.length) {
            return verticalMullions.some(mullion => {
                let profile = this.profilesService.getProfile(mullion.profileId);
                let alignment = conf.Alignments.find(el => el.perpendicularMullions && el.perpendicularMullions.bottom && el.perpendicularMullions.bottom.some(elem => elem == mullion.id));
                if (alignment) {
                    let alignmentRect = conf.drawData.alignment.find(o => o.alignmentId === alignment.id).rect;
                    if ((alignmentRect.x >= (mullion.rx - profile.width / 2) && alignmentRect.x <= (mullion.rx + profile.width / 2))
                        || ((alignmentRect.x + alignmentRect.width) >= (mullion.rx - profile.width / 2) && (alignmentRect.x + alignmentRect.width) <= (mullion.rx + profile.width / 2))
                    ) {
                        return true;
                    }
                } else {
                    if (mullion.ry == 0) {
                        const framePoly = conf.drawData.frame[0].inner.poly;
                        return framePoly.some(point => point.x >= (mullion.rx - profile.width / 2) && point.x <= (mullion.rx + profile.width / 2));
                    } else {
                        let topMullion = conf.Mullions.find(el => el.direction == 'horizontal' && el.multiAlignBottomDiv.length && el.multiAlignBottomDiv.some(div => div.id == mullion.id));
                        let topMullionRect = conf.drawData.mullion.find(o => o.mullionId === topMullion.id).rect;
                        if ((topMullionRect.x >= (mullion.rx - profile.width / 2) && topMullionRect.x <= (mullion.rx + profile.width / 2))
                            || ((topMullionRect.x + topMullionRect.width) >= (mullion.rx - profile.width / 2) && (topMullionRect.x + topMullionRect.width) <= (mullion.rx + profile.width / 2))
                        ) {
                            return true;
                        }
                    }
                    return false;
                }
            });
        }
        return false;
    }

    /**
      * Sprawdzanie czy słupek jest na połączeniu skosu z pionową częścią ramy
      * @param {object} conf  Konfiguracja pozycji
      * @returns {boolean}
      * @memberof DependenciesService
      */
      checkDividerOnSlantVertical(conf) {
        const horizontalMullions = conf.Mullions.filter(el => el.direction === 'horizontal');
        if ((conf.Shape.shape === 'poligon' || conf.Shape.shape === 'arc') && horizontalMullions.length) {
            return horizontalMullions.some(mullion => {
                let profile = this.profilesService.getProfile(mullion.profileId);

                // lewa strona
                let leftAlignment = conf.Alignments.find(el => el.perpendicularMullions && el.perpendicularMullions.right && el.perpendicularMullions.right.some(elem => elem == mullion.id));
                if (leftAlignment) {
                    let alignmentRect = conf.drawData.alignment.find(o => o.alignmentId === leftAlignment.id).rect;
                    if ((alignmentRect.y >= (mullion.ry - profile.width / 2) && alignmentRect.y <= (mullion.ry + profile.width / 2))) {
                        return true;
                    }
                } else {
                    let leftMullion = conf.Mullions.find(el => el.direction == 'vertical' && el.multiAlignRightDiv.length && el.multiAlignRightDiv.some(div => div.id == mullion.id));
                    if (leftMullion) {
                        let leftMullionRect = conf.drawData.mullion.find(o => o.mullionId === leftMullion.id).rect;
                        if ((leftMullionRect.y >= (mullion.ry - profile.width / 2) && leftMullionRect.y <= (mullion.ry + profile.width / 2))) {
                            return true;
                        }
                    }
                }

                // prawa strona
                let rightAlignment = conf.Alignments.find(el => el.perpendicularMullions && el.perpendicularMullions.left && el.perpendicularMullions.left.some(elem => elem == mullion.id));
                if (rightAlignment) {
                    let alignmentRect = conf.drawData.alignment.find(o => o.alignmentId === rightAlignment.id).rect;
                    if ((alignmentRect.y >= (mullion.ry - profile.width / 2) && alignmentRect.y <= (mullion.ry + profile.width / 2))) {
                        return true;
                    }
                } else {
                    let rightMullion = conf.Mullions.find(el => el.direction == 'vertical' && el.multiAlignLeftDiv.length && el.multiAlignLeftDiv.some(div => div.id == mullion.id));
                    if (rightMullion) {
                        let rightMullionRect = conf.drawData.mullion.find(o => o.mullionId === rightMullion.id).rect;
                        if ((rightMullionRect.y >= (mullion.ry - profile.width / 2) && rightMullionRect.y <= (mullion.ry + profile.width / 2))) {
                            return true;
                        }
                    }
                }

                const framePoly = conf.drawData.frame[0].inner.poly;
                const mullionDimensions = conf.drawData.mullion.find(el => el.mullionId == mullion.id);
                return framePoly.some(point =>
                    point.y >= (mullion.ry - profile.width / 2) && point.y <= (mullion.ry + profile.width / 2)
                    && (Math.round(point.x) === Math.round(mullionDimensions.rect.x)
                        || Math.round(point.x) === Math.round(mullionDimensions.rect.x + mullionDimensions.rect.width))
                );

            });
        }
        return false;
    }

    /**
     * Sprawdzanie typu siatki w skrzydle
     * @param {string} value Wartość do sprawdzenia
     * @param {object} sash  Konfiguracja skrzydła
     * @returns {boolean}
     * @memberof DependenciesService
     */
    checkMosquitoType(value, sash) {
        if (
            sash != null
            && Common.isObject(sash.mosquito)
            && Common.isDefined(sash.mosquito.Type)
            && Common.isDefined(sash.mosquito.Type.id)
            && sash.mosquito.Type.id == value
        ) {
            return true;
        }
        return false;
    }

    /**
     * Sprawdzanie wybranego zamka
     * @param {string} value Wartość do sprawdzenia
     * @param {object} conf  Konfiguracja konstrukcji
     * @returns {boolean}
     * @memberof DependenciesService
     */
    checkLock(value, conf) {
        if (
            Common.isObject(conf.Lock)
            && Common.isDefined(conf.Lock.id)
            && conf.Lock.id == value
        ) {
            return true;
        }
        return false;
    }

    /**
     * Sprawdzanie wybranego pochwytu
     * @param {string} value Wartość do sprawdzenia
     * @param {object} conf  Konfiguracja konstrukcji
     * @returns {boolean}
     * @memberof DependenciesService
     */
    checkPull(value, conf) {
        if (
            Common.isObject(conf.doorHardware)
            && Common.isDefined(conf.doorHardware.pull)
            && conf.doorHardware.pull == value
        ) {
            return true;
        }

        return false;
    }

    /**
     * Dodanie dodatku do całej konstrukcji
     * @param {string} value Wartość do sprawdzenia
     * @param {object} sash  Konfiguracja skrzydła
     * @returns {boolean}
     * @memberof DependenciesService
     */
    // eslint-disable-next-line max-statements
    addAccessoryToConstruction(condition, conf, dependencyId, dependencyAssociationIds?: number[]) {
        const accessory = this.accessoriesService.getAccessory(condition.value);
        if (accessory) {
            if (!this.checkConstructionAccessory(condition.value, conf)) {
                let color = null;
                if (Common.isArray(accessory.colors_ids) && accessory.price_source === 'colors') {
                    this.colorMappingService.getWindowColorId(conf, 'inner');
                    const windowColorId = this.colorMappingService.getWindowColorId(conf, 'inner');
                    const windowColorRal = this.colorMappingService.getWindowColorId(conf, 'inner', 'RAL');
                    const matchedColors = this.colorMappingService.getColors(windowColorId, windowColorRal ? 'ral' : 'window', 'accessory');
                    const matchedColor = matchedColors.find(el => accessory.colors_ids.some(id => Number(id) === el));
                    if (matchedColor) {
                        color = this.accessoriesService.colorsAll
                            .find(el => matchedColor === Number(el.id));
                    } else {
                        color = this.accessoriesService.colorsAll
                        .find(el => accessory.colors_ids.some(id => id == el.id));
                    }
                    accessory.selectedColor = color.id;
                } else if (accessory.price_source === 'table') {
                    const colorInner = this.colorsDefaultsService.getColorValue(conf.Colors, 'inner').toLowerCase();
                    const colorOuter = this.colorsDefaultsService.getColorValue(conf.Colors, 'outer').toLowerCase();
                    let colorOptions = 'white';
                    if ((colorInner === 'white' || colorInner === 'none') && colorOuter === 'color') {
                        colorOptions = 'white-color';
                    } else if ((colorOuter === 'white' || colorOuter === 'none') && colorInner === 'color') {
                        colorOptions = 'color-white';
                    } else if (colorOuter === 'color' && colorInner === 'color') {
                        colorOptions = 'color-color';
                    }
                    accessory.colorOptions = colorOptions;
                }
                accessory.fromDependency = dependencyId;
                accessory.addedWithDependency = true;
                accessory.dependencyAssociationIds = dependencyAssociationIds;

                if (condition.type === 'addRemovableAccessoryToConstruction') {
                    accessory.addedWithRemovableDependency = true;
                }

                this.accessoriesService.add('construction', accessory, color);
            } else {
                this.accessoriesService.markAccessoryWithDependencyId(condition, conf, dependencyId);
                this.accessoriesService.markAccessoryWithDependencyAssociationIds(condition, conf, dependencyAssociationIds);
            }
        }

        return true;
    }

    /**
     * Dodanie dodatku do skrzydła
     * @param {string} value Wartość do sprawdzenia
     * @param {object} sash  Konfiguracja skrzydła
     * @returns {boolean}
     * @memberof DependenciesService
     */
    addAccessoryToSash(value, sash, conf, sashDependenciesAccessories = []) {
        if (
            sash != null
            && Common.isArray(sash.hardware)
            && !sash.hardware.some(el => el.id == value && el.fromDependency)
        ) {
            let accessory = this.accessoriesService.getAccessory(value);
            if (accessory) {
                const dependencyAccessory = sashDependenciesAccessories.find(el => el.accessory.id == accessory.id);
                let color = null;
                if (dependencyAccessory) {
                    accessory = dependencyAccessory.accessory;
                    color = color = this.accessoriesService.colorsAll
                        .find(el => el.id == accessory.selectedColor);
                } else {
                    this.accessoriesService.setAmountAccessory(accessory, 'sash', conf, sash);
                    if (Common.isArray(accessory.colors_ids) && accessory.price_source === 'colors') {
                        this.colorMappingService.getWindowColorId(conf, 'inner');
                        const windowColorId = this.colorMappingService.getWindowColorId(conf, 'inner');
                        const windowColorRal = this.colorMappingService.getWindowColorId(conf, 'inner', 'RAL');
                        const matchedColors = this.colorMappingService.getColors(windowColorId, windowColorRal ? 'ral' : 'window', 'accessory');
                        const matchedColor = matchedColors.find(el => accessory.colors_ids.some(id => Number(id) === el));
                        if (matchedColor) {
                            color = this.accessoriesService.colorsAll
                                .find(el => matchedColor === Number(el.id));
                        } else {
                            color = this.accessoriesService.colorsAll
                            .find(el => accessory.colors_ids.some(id => id == el.id));
                        }
                        accessory.selectedColor = color.id;
                    } else if (accessory.price_source === 'table') {
                        const colorInner = this.colorsDefaultsService.getColorValue(conf.Colors, 'inner').toLowerCase();
                        const colorOuter = this.colorsDefaultsService.getColorValue(conf.Colors, 'outer').toLowerCase();
                        let colorOptions = 'white';
                        if ((colorInner === 'white' || colorInner === 'none') && colorOuter === 'color') {
                            colorOptions = 'white-color';
                        } else if ((colorOuter === 'white' || colorOuter === 'none') && colorInner === 'color') {
                            colorOptions = 'color-white';
                        } else if (colorOuter === 'color' && colorInner === 'color') {
                            colorOptions = 'color-color';
                        }
                        accessory.colorOptions = colorOptions;
                    }
                    accessory.fromDependency = true;
                }
                this.accessoriesService.add(sash, accessory, color);
            }
        }
        return true;
    }

    /**
     * Sprawdzanie systemu produktu
     * @param {string} value Wartość do sprawdzenia
     * @param {object} conf  Konfiguracja pozycji
     * @returns {boolean}
     * @memberof DependenciesService
     */
    checkProfile(value, conf) {
        if (
            Common.isArray(conf.UsedProfiles)
            && value
            && conf.UsedProfiles.some(profile => (
                profile.type === value.type
                && (value.options || []).every(o => profile.options && profile.options.indexOf(o) > -1)
                && (value.notOptions || []).every(o => profile.options && profile.options.indexOf(o) === -1)
            ))
        ) {
            return true;
        }
        return false;
    }

    /**
     * Sprawdzanie skracania drzwi
     * @param {string} value Wartość do sprawdzenia
     * @param {object} conf  Konfiguracja pozycji
     * @returns {boolean}
     * @memberof DependenciesService
     */
    checkShortening(value, conf) {
        return conf.doorSizes
            && conf.doorSizes.shortening
            && value.from <= conf.doorSizes.shortening
            && value.to >= conf.doorSizes.shortening;
    }

    /**
     * Sprawdzanie skracania ościeżnicy
     * @param {string} value Wartość do sprawdzenia
     * @param {object} conf  Konfiguracja pozycji
     * @returns {boolean}
     * @memberof DependenciesService
     */
     checkFrameShortening(value, conf) {
        const frameShortening = conf.doorSizes && conf.doorSizes.frameShortening ? conf.doorSizes.frameShortening : 0;
        return value.from <= frameShortening && value.to >= frameShortening;
    }

    private removeDependentAccessories(conf, dependencyId) {
        if (conf.Accessories && dependencyId) {
            const accessoriesFromDependency = conf.Accessories.filter(
                (el) =>
                    el.accessory?.fromDependency &&
                    Number(el.accessory?.fromDependency) === Number(dependencyId)
            );

            accessoriesFromDependency.forEach((accessory) => {
                accessory && this.accessoriesService.remove(null, accessory, true);
            });
        }
    }

    getDependencyById(id: number) {
        return this.configuratorsDataService.data.dependencies.find(d => d.id == id);
    }

    private removeDependentItems(value, conf, dependencyId) {
        const accessoriesFromThatDependency =
            Common.isArray(conf.Accessories) &&
            conf.Accessories.filter(
                (el) =>
                    el.accessory &&
                    Number.isInteger(el.accessory.fromDependency) &&
                    Number(el.accessory.fromDependency) === Number(dependencyId) &&
                    Array.isArray(el.accessory.dependencyAssociationIds) &&
                    el.accessory.dependencyAssociationIds.map(Number).includes(Number(value))
            );

        accessoriesFromThatDependency.forEach((accessory) => {
            this.accessoriesService.markAccessoryWithDependencyId(
                accessory.accessory.id,
                conf,
                false
            );
            if (accessory.accessory.addedWithDependency) {
                this.accessoriesService.remove(null, accessory, true);
            }
        });
    }

    getPulls(conf) {
        return (this.configuratorsDataService.data.pullType || []).filter((item) => {
            if (item?.window_lines_ids.indexOf(conf.System?.id) > -1) {
                return item;
            }
        });
    }

    isPullBlockadeAvailable(conf, dependency, condition): boolean {
        const blockade = dependency.actions.find((p) => p.value === 'blockade_to_configuration');
        const isPullAvailable = this.getPulls(conf).some(
            (p) => Number(p?.id) === Number(condition?.value)
        );

        return blockade && isPullAvailable;
    }
}
