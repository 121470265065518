import { ConfiguratorUiModule } from '@icc/configurator/ui';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LocksComponent } from './locks/locks.component';
import { SharedModule } from '@icc/configurator/shared';
import { ModelComponent } from './model/model.component';
import { PriceModule } from '@icc/price/b2b';
import { WindowFacade } from '../../../window/src/lib/+state/window.facade';
import { ParametersService } from '@icc/common/configurators/parameters.service';
import { HardwareComponent } from './hardware/hardware.component';
import { HardwareTypesPageComponent } from './hardware-types-page/hardware-types-page.component';
import { HardwareTypeOptionsPageComponent } from './hardware-type-options-page/hardware-type-options-page.component';
import { PlatesPageComponent } from './plates-page/plates-page.component';
import { CylindersPageComponent } from './cylinders-page/cylinders-page.component';
import { SizesComponent } from './sizes/sizes.component';
import { ExtensionsPageComponent } from './extensions-page/extensions-page.component';
import { LightsGlazingComponent } from './lights-glazing/lights-glazing.component';
import { ModelListPageComponent } from './model-list-page/model-list-page.component';
import { ModelInfoComponent } from './model-info/model-info.component';
@NgModule({
    imports: [CommonModule, SharedModule, PriceModule, ConfiguratorUiModule],
    exports: [
        HardwareComponent,
        HardwareTypesPageComponent,
        HardwareTypeOptionsPageComponent,
        PlatesPageComponent,
        CylindersPageComponent,
        SizesComponent,
        ExtensionsPageComponent,
        LightsGlazingComponent,
        ModelListPageComponent,
        ModelInfoComponent,
    ],
    declarations: [
        LocksComponent,
        ModelComponent,
        HardwareComponent,
        HardwareTypesPageComponent,
        HardwareTypeOptionsPageComponent,
        PlatesPageComponent,
        CylindersPageComponent,
        SizesComponent,
        ExtensionsPageComponent,
        LightsGlazingComponent,
        ModelListPageComponent,
        ModelInfoComponent,
    ],
    entryComponents: [
        LocksComponent,
        ModelComponent,
        HardwareComponent,
        HardwareTypesPageComponent,
        HardwareTypeOptionsPageComponent,
        PlatesPageComponent,
        CylindersPageComponent,
        SizesComponent,
        ExtensionsPageComponent,
        LightsGlazingComponent,
        ModelListPageComponent,
        ModelInfoComponent,
    ],
    providers: [WindowFacade, ParametersService],
})
export class ConfiguratorDoorModule {}
