// Generated by https://quicktype.io

export interface IccColorGroup {
    id: string;
    max_seal_color_id: string;
    color_price_source: 'factor' | 'table';
    price_factor_in: null | string;
    price_factor_out: null | string;
    price_factor_both: null | string;
    ral_colors: boolean;
    price_factor_duo: null | string;
    price_factor_type: boolean;
    type: string;
    sides: Side[] | null;
    code: string | null;
    static: boolean;
    order: null | string;
    created: string;
    modified: string;
    target: Target[];
    prices_extra: null | string;
    offer_price_ranges: any[] | null;
    brushed_alu_group: boolean | null;
    prices_extra_profiles: null | string;
    prices_extra_mosquito: null | string;
    roller_element: RollerElementElement[] | null | string;
    rs_price_m2: null | string;
    rs_price_h: null | string;
    rs_price_w: null | string;
    rs_price_percent: string;
    rs_time_limit_id: null;
    rs_include_rals: boolean | null;
    rs_guide_rail_type: string | null;
    rs_code: null;
    locale: string;
    name: string;
    name_cms: string;
    systems: string[] | null;
    roller_systems: string[] | null;
    woodTypes: any[] | null;
    mosquitoTypes: string[] | null;
    profiles: string[];
    max_sheet_metal_height: number;
    max_sheet_metal_width: number;
    min_sheet_metal_height: number;
    min_sheet_metal_width: number;
}

export type RollerElementElement =
    | 'box'
    | 'box_guide_outer'
    | 'box_inner'
    | 'box_side_surface'
    | 'endslat'
    | 'guide_rail'
    | 'profile'
    | 'revision';

/**
 * @enum {Side}
 * @member {F} Frame
 * @member {S} Sash
 * @member {A} Alushell
 * @member {B} Bilateral
 * @member {C} Core
 * @member {DI} Bicolor_Inner
 * @member {DO} Bicolor_Outer
 * @member {I} Inner
 * @member {O} Outer
 */
export enum Side {
    FA = 'F|A',
    FB = 'F|B',
    FC = 'F|C',
    FDi = 'F|DI',
    FDo = 'F|DO',
    FI = 'F|I',
    FO = 'F|O',
    SA = 'S|A',
    SB = 'S|B',
    SC = 'S|C',
    SDi = 'S|DI',
    SDo = 'S|DO',
    SI = 'S|I',
    SO = 'S|O',
    LO = "L|O",
    LI = "L|I",
    LB = "L|B"
}

export type Target = 'price' | 'price_roller' | 'show' | 'show_roller' | 'show_profile';
