import { isUndefined } from './helpers';
import { core } from '@icc/common/helpers';

/**
 * Przelicza wartość w walucie bazowej (np. z konfiguratora) na walutę oferty
 * @param input Wartość w walucie bazowej (cennika)
 * @param currency Waluta oferty
 * @param exchange Czy przeliczać? `null` - zgodnie z ofertą, `true` - przeliczać, `false` - nie przeliczać
 * @returns Wartość w walucie oferty
 */
export function currencyExchange(input: any, currency: any, exchange = null) {
    let value = NaN;

    if (input === null || isUndefined(input)) {
        return NaN;
    }
    if (!currency) {
        return parseFloat(input);
    }
    currency = core.parseJson(currency);
    if (!currency.value) {
        return parseFloat(input);
    }
    const toExchange = exchange ?? currency.pricesInCurrency;
    if (toExchange) {
        value = Math.round(100 * (parseFloat(input) || 0) / parseFloat(currency.value)) / 100;
    } else {
        value = Math.round(100 * parseFloat(input) || 0) / 100;
    }
    return value;
}

/**
 * Przelicza wartość w walucie oferty na walutę bazową (np. z konfiguratora)
 * @param input Wartość w walucie bazowej (cennika)
 * @param currency Waluta oferty
 * @param exchange Czy przeliczać? `null` - zgodnie z ofertą, `true` - przeliczać, `false` - nie przeliczać
 * @returns Wartość w walucie bazowej
 */
export function currencyExchangeReverse(input: any, currency: any, exchange = null) {
    let value = NaN;

    if (input === null || isUndefined(input)) {
        return NaN;
    }
    if (!currency) {
        return parseFloat(input);
    }
    currency = core.parseJson(currency);
    if (!currency.value) {
        return parseFloat(input);
    }
    const toExchange = exchange ?? currency.pricesInCurrency;
    if (toExchange) {
        value = Math.round(100 * (parseFloat(input) || 0) * parseFloat(currency.value)) / 100;
    } else {
        value = Math.round(100 * parseFloat(input) || 0) / 100;
    }
    return value;
}

/**
 * Przelicza wartość w walucie bazowej (zapisaną w ofercie) na docelową do wyświetlania
 * @param input Wartość w walucie bazowej (zapisana w ofercie)
 * @param currency Waluta docelowa (oferty)
 * @param exchange Czy przeliczać? `null` - zgodnie z ofertą, `true` - przeliczać, `false` - nie przeliczać
 * @returns Wartość w walucie docelowej
 */
export function currencyExchangeView(input: any, currency: any, exchange = null) {
    let value = NaN;

    if (input === null || isUndefined(input)) {
        return NaN;
    }
    if (!currency) {
        return parseFloat(input);
    }
    currency = core.parseJson(currency);
    if (!currency.value) {
        return parseFloat(input);
    }
    const toExchange = exchange ?? !currency.pricesInCurrency;
    if (toExchange) {
        value = Math.round(100 * (parseFloat(input) || 0) / parseFloat(currency.value)) / 100;
    } else {
        value = Math.round(100 * parseFloat(input) || 0) / 100;
    }
    return value;
}
