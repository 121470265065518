import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { _, StepComponent, SharedFacade } from '@icc/configurator/shared';
import { SystemsService } from '@icc/legacy/configurator/steps/window/systems/systems.service';
import {
    EventBusService,
    AppConfigFactory,
    APP_CONFIG,
    ConfigurationsService,
    DrawService,
    TranslateService,
} from '@icc/common';
import { iccListTab, iccListItem } from '@icc/configurator/ui';
import { ModalService, castToNullableNumber } from '@icc/helpers';
import { SystemInfoComponent } from '../system-info/system-info.component';
import { BrowserProfileSetsService } from '../profiles/profile-sets.service';
import { WindowFacade } from '../+state/window.facade';
import { SystemFiltersComponent, SystemFilters } from '../system-filters/system-filters.component';
import { map } from 'rxjs/operators';
import { ThresholdsService } from '@icc/legacy/configurator/layout/thresholds.service';
import { ThermalTransmittanceTabularService } from 'libs/configurator/door/src/lib/thermal-transmittance-tabular.service';

@Component({
    selector: 'icc-system',
    templateUrl: './system.component.html',
    styleUrls: ['./system.component.scss'],
})
export class SystemComponent extends StepComponent implements OnInit, OnDestroy {
    static stepName = _('STEPS|Linia');
    static stepIcon = {
        ligature: 'apps',
    };
    public configurator = 'window';
    public stepId = 'system';
    public title = _('WINDOW|Linia');
    public options = [
        {
            title: _('WINDOW|Filtry'),
            callback: () => {
                this.openFilters();
            },
            icon: {
                ligature: 'tune',
            },
            show: () => this.mode === 'list',
        },
    ];

    systemTabs: 'types' | 'groups' | 'tags' = 'types';
    mode: 'list' | 'details' = 'list';
    systems: iccListItem[] = [];
    systemTypes: iccListTab[] = [];
    systemGroupsTabs: iccListTab[] = [];
    systemTagsTabs: iccListTab[] = [];
    tabs: iccListTab[] = [];
    allSystems = true;
    selectedSystems: any[] = [];
    systemId: number | null = null;
    system$ = this.windowFacade.system$;
    systemId$ = this.windowFacade.system$.pipe(map((system) => Number(system.id)));
    profile$ = this.windowFacade.profileSet$;
    activeTab: iccListTab | null = null;

    systemGroups: any[] = [];
    systemTags: any[] = [];
    filters: any = null;
    filtering = false;

    confType = 'window';
    isAnySystemDoorType = false;

    private showDetails = this.config().preset === 'b2b';
    private subscriptions: any[] = [];
    showProfileSetInSystemStep: boolean;

    constructor(
        private systemsService: SystemsService,
        private eventBusService: EventBusService,
        private configurationsService: ConfigurationsService<'window' | 'sliding_door' | 'door'>,
        @Inject(APP_CONFIG) private config: AppConfigFactory,
        public drawService: DrawService,
        private modalService: ModalService,
        private profileSetsService: BrowserProfileSetsService,
        private sharedFacade: SharedFacade,
        private windowFacade: WindowFacade,
        private translateService: TranslateService,
        private thresholdService: ThresholdsService,
        private thermalTransmittanceTabularService: ThermalTransmittanceTabularService
    ) {
        super();
        this.subscriptions.push(
            this.eventBusService.subscribeWithoutConfiguration('initializedConfigurator', () =>
                this.init()
            )
        );
        this.showProfileSetInSystemStep = this.config().IccConfig?.Configurators?.showProfileSetInSystemStep;
    }

    
    ngOnInit() {
        if (this.configurationsService.conf.Current) {
            this.systemId = Number(this.configurationsService.conf.Current.System.id);
        }
        this.subscriptions.push(
            this.sharedFacade.systemStepMode$.subscribe((stepMode) => {
                this.mode = stepMode;
            })
        );

        if (this.systemsService.loadedData) {
            this.init();
        }
    }

    ngOnDestroy() {
        this.subscriptions.map((el) => el.unsubscribe());
    }

    init() {
        this.confType = this.configurationsService.conf.Current.type;
        this.systemTabs = this.config().IccConfig.Configurators.systemTabs;
        this.systemTypes =
            this.confType !== 'sliding_door'
                ? this.systemsService.systemTypes.map((type) => ({
                      id: type.type,
                      name: type.name,
                  }))
                : [
                      {
                          id: 'hs',
                          name: this.translateService.instant('CONFIGURATOR|HS'),
                      },
                      {
                          id: 'window',
                          name: this.translateService.instant('CONFIGURATOR|PSK'),
                      },
                  ];
        this.systemGroups = this.systemsService.groups;
        this.systemTags = this.systemsService.tags.filter((tag) =>
            this.systemsService.systems.some((system) => system.tags.includes(tag.id))
        );
        this.systemGroupsTabs = this.systemsService.groups.map((type) => ({
            id: type.id,
            name: type.name,
        }));
        this.systemTagsTabs = this.systemsService.tags.map((tag) => ({
            id: tag.id,
            name: tag.name,
        }));
        if (this.systemTabs === 'groups') {
            this.tabs = this.systemGroupsTabs;
        } else if (this.systemTabs === 'tags') {
            this.tabs = this.systemTagsTabs;
        } else {
            this.tabs = this.systemTypes;
        }
        this.loadSystems();
        this.isAnySystemDoorType = this.systemsService.isAnySystemDoorType();
    }

    selectSystem(item: iccListItem) {
        const system = this.systemsService.systems.find((s) => Number(s.id) === item.id);
        if (system) {
            this.systemId = Number(system.id);
            if (this.showDetails && !system.door_type) {
                this.sharedFacade.changeSystemStepMode('details');
            }
            this.systemsService.selectSystem(
                this.configurationsService.conf.Current,
                this.configurationsService.conf.Default,
                system,
                false,
                true,
                true,
                false,
                !!this.configurationsService.conf.Edit,
                true
            );
        }
    }

    showInfo(item: iccListItem) {
        const system = this.systemsService.systems.find((s) => Number(s.id) === item.id);
        const modalService = this.modalService
            .open({
                pageComponent: SystemInfoComponent,
                resolve: {
                    system: () => system,
                    button: () => true,
                    showImage: () => system?.show_title_image
                },
            })
            .result.then((result: boolean) => {
                if (result) {
                    this.selectSystem(item);
                }
            });
    }

    profileSet() {
        this.profileSetsService.openProfileSetsModal(
            this.configurationsService.conf.Current,
            this.configurationsService.conf.Default
        );
    }

    openList() {
        this.sharedFacade.changeSystemStepMode('list');
    }

    openFilters() {
        this.modalService
            .open({
                pageComponent: SystemFiltersComponent,
                resolve: {
                    filters: this.filters,
                    systemTypes: this.systemTypes,
                    systemGroups: this.systemGroups,
                    systemTags: this.systemTags,
                },
                filters: true,
            })
            .result.then((result) => {
                if (result) {
                    this.filters = result.filters;
                    this.filterSystems(this.filters);
                }
            });
    }

    tabChange(event: { tab: iccListTab; filteredItems: iccListItem[] }) {
        this.activeTab = event.tab;
    }

    private loadSystems(systems = this.systemsService.systems) {
        this.confType = this.configurationsService.conf.Current.type;
        this.systems = systems.map((system) => {
            let tabs;
            if (this.systemTabs === 'groups') {
                tabs = system.window_line_group_id ? [Number(system.window_line_group_id)] : [];
            } else if (this.systemTabs === 'tags') {
                tabs = system.tags;
            } else {
                tabs = this.confType !== 'sliding_door' ? [system.type] : [system.confType];
            }
            return {
                id: Number(system.id),
                title: system.name,
                tabs,
                imageUrl: '/files/windowline/' + system.title_image,
                showInfo: true,
            };
        });
    }

    private filterSystems(filters: SystemFilters) {
        this.filtering = Boolean(filters.type || filters.groupId || filters.tags.length > 0);
        const filteredSystems = this.systemsService.systems.filter((system) => {
            let match = true;
            if (filters.type) {
                match = match && system.type === filters.type;
            }
            if (filters.groupId) {
                match =
                    match && castToNullableNumber(system.window_line_group_id) === filters.groupId;
            }
            if (filters.tags) {
                match = match && filters.tags.every((tag) => system.tags.includes(tag));
            }
            return match;
        });
        this.loadSystems(filteredSystems);
    }
}
