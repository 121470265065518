import { Configuration } from './Configuration';
import { PriceSegment, DiscountGroups, PricePart } from '@icc/price/b2b';
import { TimeLimitSegment } from '@icc/common/time-limit/time-limit-functions';
import { GarageDoorSystem, GarageDoorDimensions, Panel, Structure, Control, Guide } from './parts/garage_door';
import { Accessory, Color } from './parts/common';
import { core } from '../helpers';

import { GarageDoorActiveConfiguration } from './GarageDoorActiveConfiguration';
import { PanelsData } from './parts/garage_door/PanelsData';
import { SystemV2 } from '@icc/window';
// import { changesInVersion6, changesInVersion7 } from './converters/garage';
import { GlazingImpost } from './parts/garage_door/GlazingImpost';

export class GarageDoorConfiguration implements Configuration {
    $version = 8;
    type: 'garage_door' = 'garage_door';
    name = '';
    price: number | null = null;
    priceNoMargin: number | null = null;
    priceAfterDiscounts: number | null = null;
    priceGross: number | null = null;
    priceGrossAfterDiscounts: number | null = null;
    quantity: number = 1;
    priceSegments: PriceSegment[] = [];
    priceSegmentsNoMargin: PriceSegment[] = [];
    priceParts: PricePart[] = [];
    pricePartsNoMargin: PricePart[] = [];
    discountGroups: DiscountGroups = {};
    discountGroupsNoMargin: DiscountGroups = {};
    timeLimit: number | null = null;
    timeLimitsStack: TimeLimitSegment[] = [];
    title = '';
    description = '';
    attachments: any[] = [];
    deletedAttachments: any[] = [];
    valid: {} = {};
    attributes: {
        id: string;
        name: string;
        value: string;
        valueId?: number;
    }[] = [];

    system: number;
    dimensions: GarageDoorDimensions;
    panelsData: PanelsData;
    glazingsGlobalImposts: GlazingImpost[];
    mechanism;
    guide;
    panel;
    panelInternal;
    panelStructure;
    panelInternalStructure;
    plinthPanel;
    plinthPanelStructure;
    panelColor;
    panelInternalColor;
    drive;
    driveType;
    driveSide;
    wicketDoor;
    wicketDoorDirection;
    wicketDoorFrameProfilesColor;
    wicketDoorHandleColor;
    accessories: Accessory[] = [];

     /**
     * Słownik z danymi składowych konfiguracji
     */
    dictionary: {
        /**
         * Linia produktowa
         */
        systems: Record<SystemV2['id'], SystemV2>;
    } = {
        systems: {}
    };

    private converters = {
        // 6: changesInVersion6,
        // 7: changesInVersion7
    };

    constructor(
        configuration?,
        dataRequiredToUpdate?: any,
        conversion = true,
    ) {
        if (GarageDoorConfiguration.is(configuration) && conversion) {
            this.runConversion(configuration, dataRequiredToUpdate);
        } else {
            if (GarageDoorActiveConfiguration.is(configuration)) {
                this.name = configuration.Name;
                this.price = configuration.Price;
                this.priceNoMargin = configuration.PriceNoMargin;
                this.priceAfterDiscounts = configuration.PriceAfterDiscounts || configuration.Price;
                this.priceGross = configuration.PriceGross || configuration.Price;
                this.priceGrossAfterDiscounts =
                    configuration.PriceGrossAfterDiscounts || configuration.Price;
                this.quantity = configuration.Quantity;
                this.priceSegments = configuration.PriceSegments;
                this.priceSegmentsNoMargin = configuration.PriceSegmentsNoMargin;
                this.priceParts = configuration.PriceParts;
                this.pricePartsNoMargin = configuration.PricePartsNoMargin;
                this.discountGroups = configuration.DiscountGroups;
                this.discountGroupsNoMargin = configuration.DiscountGroupsNoMargin;
                this.title = configuration.Title;
                this.description = configuration.Description;
                this.attachments = configuration.Attachments;
                this.valid = configuration.valid;
                this.timeLimit = configuration.timeLimit;
                this.timeLimitsStack = configuration.timeLimitsStack;
                this.attributes = configuration.attributes;

                if (configuration.system && configuration.system.id) {
                    this.system = Number(configuration.system.id);

                    this.dictionary.systems[Number(configuration.system.id)] = {
                        id: Number(configuration.system.id),
                        imageSideOnOffer: configuration.system.image_side_on_offer,
                        name: configuration.system.name,
                        titleImage: configuration.system.title_image,
                        type: configuration.system.type,
                        offerInfo: configuration.system.offer_info,
                        frameType: configuration.system.type_frame,
                        confType: configuration.system.confType,
                        doorType: configuration.system.door_type,
                        frameJointAngles: configuration.system.frame_joint_angles,
                        sashJointAngles: configuration.system.sash_joint_angles,
                        thresholdJointAngles: configuration.system.threshold_joint_angles,
                        glazingBeadJointAngles: configuration.system.glazing_bead_joint_angles,
                        availableThresholdSashRebate: configuration.system.available_threshold_sash_rebate,
                        indexNo: configuration.system.index_no,
                        windowLineGroupId: configuration.system.window_line_group_id
                    };
                }

                this.dimensions = configuration.dimensions;
                this.panelsData = configuration.panelsData;
                this.glazingsGlobalImposts = configuration.glazingsGlobalImposts;
                this.mechanism = configuration.mechanism;
                this.guide = configuration.guide;
                this.panel = configuration.panel;
                this.panelInternal = configuration.panelInternal;
                this.panelStructure = configuration.panelStructure;
                this.panelInternalStructure = configuration.panelInternalStructure;
                this.plinthPanel = configuration.plinthPanel;
                this.plinthPanelStructure = configuration.plinthPanelStructure;
                this.panelColor = configuration.panelColor;
                this.panelInternalColor = configuration.panelInternalColor;
                this.drive = configuration.drive;
                this.driveType = configuration.driveType;
                this.driveSide = configuration.driveSide;
                this.wicketDoor = configuration.wicketDoor;
                this.wicketDoorDirection = configuration.wicketDoorDirection;
                this.wicketDoorFrameProfilesColor = configuration.wicketDoorFrameProfilesColor;
                this.wicketDoorHandleColor = configuration.wicketDoorHandleColor;
                this.accessories = configuration.Accessories.map<Accessory>(
                    accessory => new Accessory(accessory)
                );
            }
        }
    }

    public static is(configuration): configuration is GarageDoorConfiguration {
        return configuration instanceof GarageDoorConfiguration || configuration.$version;
    }

    protected runConversion(configuration, dataRequiredToUpdate: any) {
        let updatedConfiguration = core.copy(configuration);

        for (let version = configuration.$version + 1; version <= this.$version; version++) {
            updatedConfiguration.$version = version;
            if (this.converters[version] && typeof this.converters[version] === 'function') {
                updatedConfiguration = this.converters[version](
                    updatedConfiguration,
                    dataRequiredToUpdate
                );
            }
        }
        (Object as any)
            .entries(updatedConfiguration)
            .forEach(([key, value]) => (this[key] = value));
    }
}
