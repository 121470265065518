import { core } from '@icc/common/helpers';
import { Profile } from '@icc/window';
import { WindowActiveConfiguration } from '@icc/common/configurations/WindowActiveConfiguration';
import { ProfilesService } from '@icc/common/profiles.service';
import { EventBusService } from '@icc/common/event-bus.service';
import { Injectable, Inject } from '@angular/core';
import { FramesService } from '@icc/common/layout/frames.service';
import { APP_CONFIG, AppConfigFactory } from './config';
import { IccFilling } from './data-types';

@Injectable()
export abstract class GlazingBeadsService {
    protected GLAZING_BEAD_TOLERANCE = 1;

    protected glazingBeads: Profile[] = [];
    protected glazingBeadMatches = [];

    constructor(
        protected profilesService: ProfilesService,
        protected eventBusService: EventBusService,
        @Inject(APP_CONFIG) protected config: AppConfigFactory,
    ) {}

    setDefaultGlazingBeadInSashes(conf: WindowActiveConfiguration) {
        conf.Sashes.forEach(sash => {
            this.setDefaultGlazingBeadInSash(sash, sash, conf, conf);
            sash.intSashes.forEach(intSash => {
                this.setDefaultGlazingBeadInSash(intSash, sash, conf, conf);
            });
        });
    }

    setDefaultGlazingBeadInSash(field, sash, conf: WindowActiveConfiguration, defaultConf) {
        const defaultGlazingBead = this.getDefaultGlazingBeadInSash(field, sash, conf, defaultConf);
        this.setGlazingBeadInSash(field, defaultGlazingBead, true, conf, defaultConf);
    }

    setGlazingBeadInSash(sash, glazingBead: Profile, isDefault = false, conf = null, defaultConf = null) {
        if (!glazingBead || !glazingBead.id) {
            return;
        }
        sash.glazingBead = {
            isDefault,
            profileId: glazingBead.id,
        };
        if (sash.intSashes.length === 1) {
            this.setGlazingBeadInSash(sash.intSashes[0], glazingBead, isDefault, conf, defaultConf);
        }
        this.eventBusService.post({
            key: 'setGlazingBeadInSash',
            value: {
                glazingBeadId: glazingBead.id,
                sashId: sash,
                profile: glazingBead,
            },
            conf,
            defaultConf
        });
    }

    checkSingleGlazingBeads(conf: WindowActiveConfiguration, defaultConf?: any) {
        let isSingleGlazingBead = true;
        let isSingleGlazingBeadInFixes = true;
        let isSingleGlazingBeadInSashes = true;
        let firstGlazingBeadId = null;
        let firstGlazingBeadIdInFixes = null;
        let firstGlazingBeadIdInSashes = null;

        isSingleGlazingBead = conf.Sashes.every((sash, i) => {
            let isSingleGlazingBeadInSash = true;
            let firstGlazingBeadIdInSash = sash.glazingBead.profileId;

            isSingleGlazingBeadInSash = sash.intSashes.every((intSash, j) => {
                if (j === 0) {
                    firstGlazingBeadIdInSash = intSash.glazingBead.profileId;
                }
                return firstGlazingBeadIdInSash === intSash.glazingBead.profileId;
            });

            if (i === 0) {
                firstGlazingBeadId = firstGlazingBeadIdInSash;
            }

            if (sash.type.type === 'F') {
                if (!firstGlazingBeadIdInFixes) {
                    firstGlazingBeadIdInFixes = firstGlazingBeadIdInSash;
                }
                isSingleGlazingBeadInFixes = isSingleGlazingBeadInFixes
                        && isSingleGlazingBeadInSash
                        && firstGlazingBeadIdInFixes === firstGlazingBeadIdInSash;
            } else {
                if (!firstGlazingBeadIdInSashes) {
                    firstGlazingBeadIdInSashes = firstGlazingBeadIdInSash;
                }
                isSingleGlazingBeadInSashes = isSingleGlazingBeadInSashes
                        && isSingleGlazingBeadInSash
                        && firstGlazingBeadIdInSashes === firstGlazingBeadIdInSash;
            }

            sash.oneGlazingBead = firstGlazingBeadIdInSash;
            return isSingleGlazingBeadInSash && firstGlazingBeadId === firstGlazingBeadIdInSash;
        });

        conf.OneGlazingBead = isSingleGlazingBead;
        if (isSingleGlazingBead && defaultConf) {
            defaultConf.GlazingBead = firstGlazingBeadId;
        }
        conf.OneGlazingBeadSash = {
            fix: false,
            sashes: false
        };
        if (isSingleGlazingBeadInFixes) {
            conf.OneGlazingBeadSash.fix = firstGlazingBeadIdInFixes;
        }
        if (isSingleGlazingBeadInSashes) {
            conf.OneGlazingBeadSash.sashes = firstGlazingBeadIdInSashes;
        }
    }

    abstract getMatchingFillingThicknessInSash(field, sash, conf: WindowActiveConfiguration, panelType?: IccFilling['panel_type']): {
        min: number,
        max: number
    }[];

    abstract loadGlazingBeads(conf: WindowActiveConfiguration);

    protected getDefaultGlazingBeadInSash(
        field,
        sash,
        conf: WindowActiveConfiguration,
        defaultConf?
    ): Profile {
        const matchedBeads = this.getMatchingGlazingBeadsInSash(field, sash, conf);
        const matchingBeadsToShape = matchedBeads.filter(
            bead => conf.GlazingBeadType && bead.profileShapeId === conf.GlazingBeadType
        );
        const matchingBeadsToProfileSetShape = matchedBeads.filter(
            bead =>
                conf.ProfileSet
                && conf.ProfileSet.glazingBeadShape
                && bead.profileShapeId === conf.ProfileSet.glazingBeadShape
        );
        if (
            defaultConf
            && defaultConf.GlazingBead
            && matchedBeads.filter(bead => bead.id === defaultConf.GlazingBead).length > 0
        ) {
            return this.profilesService.getProfile(defaultConf.GlazingBead);
        } else if (matchingBeadsToProfileSetShape.length > 0) {
            return matchingBeadsToProfileSetShape[0];
        } else if (matchingBeadsToShape.length > 0) {
            return matchingBeadsToShape[0];
        } else if (matchedBeads.length > 0) {
            return matchedBeads[0];
        }
        return null;
    }

    protected getMatchingGlazingBeadsInSash(field, sash, conf: WindowActiveConfiguration) {
        let sashProfile;
        if (sash.type.type !== 'F') {
            sashProfile = this.profilesService.getProfile(sash.frame.bottom.profileId);
        } else {
            const frame = FramesService.getFrameProfilesForSash(sash, conf);
            sashProfile = this.profilesService.getProfile((frame[1] || frame[0]).profileId);
        }
        if (
            field.glazing.type !== 'glazing'
            && (sash.panelType === 'Double'
                || sash.panelType === 'Inner'
                || sash.panelType === 'Mixed')
            || field.glazing.type === 'door_panels'
        ) {
            return [];
        }
        const fieldFillingThickness = Math.round(Number(field.glazing.thickness_mm));

        let beadDepth = sashProfile?.glazingRebate
            - sashProfile?.repairGaskets
            - fieldFillingThickness;

        if (sash.panelType === 'Outer') {
            beadDepth = sashProfile?.depthToGlazing
                + sashProfile?.glazingRebate
                - fieldFillingThickness;
        }

        const matchedBeads = this.glazingBeads.filter(
            profile =>
                (this.config().IccConfig.Configurators.glazingBeadsByMatches && profile.matchingThicknesses && profile.matchingThicknesses.includes(fieldFillingThickness))
                ||
                (!this.config().IccConfig.Configurators.glazingBeadsByMatches && (profile.systems.indexOf(Number(conf.System.id)) > -1
                && profile.width >= sashProfile.glazingBeadMinHeight
                && profile.width <= sashProfile.glazingBeadMaxHeight
                && beadDepth - profile.decreaseGasketSqueeze <= profile.depth
                && beadDepth + profile.increaseGasketSqueeze >= profile.depth))
        );
        return matchedBeads;
    }
}
