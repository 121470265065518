import { UserService, Common, APP_CONFIG, AppConfigFactory, ConfigurationsService, ConfiguratorsDataService, EventBusService, TranslateService, WindowActiveConfiguration } from '@icc/common';
import { ModalService, StepsService, InfoService, ChangedStepValue, NoopPageComponent } from '@icc/helpers';
import { Inject, Injectable } from '@angular/core';
import { PriceService } from '@icc/price';
import { CurrentConfiguratorService } from '@icc/common/configurators/current-configurator.service';
import { RollerDimensionsService } from './dimensions.service';
import { DrivesService } from './drives.service';
import { ColorsService } from './colors.service';
import { SystemsPageComponent } from 'libs/configurator/shutter/src/lib/systems-page/systems-page.component';
import { SharedFacade } from '@icc/configurator/shared';

/**
 * Fabryka schematów rolet
 * @param {object} $rootScope               Angular Root Scope
 * @param {object} $uibModal                   Dostarcza obsługę okien modalnych
 * @param {object} ConfigurationsService    Fabryka konfiguracji
 * @param {object} StepFactory              Fabryka kroków
 * @param {object} ConfiguratorsDataService Fabryka danych konfiguracji
 * @param {object} CurConfService           Bieżaca konfiguracja
 * @param {object} PriceService             Liczenie cen
 * @param {object} RollerDimensionsFactory  Fabryka rozmiarów rolet
 * @param {object} DrivesService            Fabryka napędow
 * @param {object} RollerColorsService      Fabryak kolorów rolet
 */
@Injectable()
export class SchemasService {

    public systems = [];
    loadedData = false;
    modalConfigurator = false;

    constructor(
        private userService: UserService,
        private modalService: ModalService,
        @Inject(APP_CONFIG) private config: AppConfigFactory,
        private configurationsService: ConfigurationsService<'roller_shutter' | 'external_blind' | 'window'>,
        private configuratorsDataService: ConfiguratorsDataService,
        private stepsService: StepsService,
        private priceService: PriceService,
        private currentConfiguratorService: CurrentConfiguratorService,
        private rollerDimensionsService: RollerDimensionsService,
        private eventBusService: EventBusService,
        private drivesService: DrivesService,
        private colorsService: ColorsService,
        private infoService: InfoService,
        private translateService: TranslateService,
        private sharedFacade: SharedFacade
    ) {

        if (this.configuratorsDataService.loaded) {
            this.init();
        }

        this.eventBusService.subscribe<ChangedStepValue>('changedStep', data => {
            if (this.configurationsService.conf.Current.type !== 'external_blind') {
                if (data.value.nextStep.code !== 'rollerschema' && data.value.nextStep.code !== 'rollertype'
                    && (data.value.prevStep.code === 'rollerschema' || data.value.prevStep.code === 'rollertype')
                    && data.value.nextStep.code !== data.value.prevStep.code
                ) {
                    if (Common.isUndefined(this.configurationsService.conf.Current.RollerShutter.shutters)
                        || (
                            Common.isArray(this.configurationsService.conf.Current.RollerShutter.shutters)
                            && this.configurationsService.conf.Current.RollerShutter.shutters.length === 0
                        )
                    ) {
                        this.infoService.openWarning(this.translateService.instant('ROLLERSHUTTER|Nie wybrano schematu'));
                        this.stepsService.selectStep(data.value.prevStep.i);
                    }
                }
            }
        });

        this.eventBusService.subscribeWithoutConfiguration('initializedConfigurator', () => {
            this.init();
        });

        this.sharedFacade.getIsModalConfigurator$.subscribe(active => {
            this.modalConfigurator = active;
        });
    }

    /**
     * Funkcja inicjalizujaca
     */
    init() {
        if (
            this.currentConfiguratorService.conf !== 'window'
            && this.currentConfiguratorService.conf !== 'roller_shutter'
            && this.currentConfiguratorService.conf !== 'external_blind'
            && this.currentConfiguratorService.conf !== 'hs'
            && this.currentConfiguratorService.conf !== 'sliding_door'
            && this.currentConfiguratorService.conf !== 'folding_door'
        ) {
            return;
        }
        const conf = this.configurationsService.conf.Current;
        const defaultConf = this.configurationsService.conf.Default;
        const user = this.userService.get();
        this.systems = this.configuratorsDataService.data.rollerShutterSystems || [];
        let windowConf: WindowActiveConfiguration;
        if (this.configurationsService.configurators.window?.Current) {
            windowConf = this.configurationsService.configurators.window.Current as WindowActiveConfiguration;
        }
        if (
            WindowActiveConfiguration.is(windowConf)
            && Common.isObject(windowConf.System.roller_shutter_systems)
            && this.modalConfigurator
        ) {
            this.systems = this.systems.filter(system =>
                windowConf.System.roller_shutter_systems.includes(system.id)
            );
        }

        if (
            !conf.RollerShutter.system
            || Common.isUndefined(conf.RollerShutter.system.id)
            || !this.systems.some(system => conf.RollerShutter.system.id === system.id)
        ) {
            if (!Object.keys(conf.RollerShutter?.system).length) {
                conf.RollerShutter.system = {};
                this.setDefaultSystem(conf, defaultConf);
            }
            if (Common.isUndefined(conf.RollerShutter.system.id) && WindowActiveConfiguration.is(conf)) {
                conf.hasRoller = false;
            }
        }
        if (
            (Common.isUndefined(conf.RollerShutter.schemaId)
                || conf.RollerShutter.schemaId === null)
            && (this.currentConfiguratorService.conf !== 'roller_shutter'
                && this.currentConfiguratorService.conf !== 'external_blind'
                || (!user || !user.access || user.access === 'klient')
                || !this.stepsService.hasStep('rollerschema')
            )
        ) {
            conf.RollerShutter.schemaId = '1';
        }
        if (
            (Common.isUndefined(conf.RollerShutter.shutters)
                || (Common.isArray(conf.RollerShutter.shutters)
                    && conf.RollerShutter.shutters.length === 0))
            && (this.currentConfiguratorService.conf !== 'roller_shutter'
                && this.currentConfiguratorService.conf !== 'external_blind'
                || (!user || !user.access || user.access === 'klient')
                || !this.stepsService.hasStep('rollerschema')
            )
        ) {
            this.setShuttersBySchema({ nextStep: false, conf });
        }
        this.loadedData = true;
        this.priceService.count();
    }

    /**
     * Funkcja ustawiajca rolete wg schematu
     * @param {number} width  Szerokość konfiguracji
     * @param {number} height Wysokość konfiguracji
     */
    public setShuttersBySchema({
        width = this.configurationsService.conf.Current.Width,
        height = this.configurationsService.conf.Current.Height,
        conf = this.configurationsService.conf.Current,
        nextStep = true
    } = {}) {
        switch (conf.RollerShutter.schemaId) {
            case '1':
                conf.RollerShutter.shutters = [
                    {
                        id        : 0,
                        x         : 1,
                        realWidth : width,
                        width     : 0,
                        realHeight: height,
                        height    : 0,
                        handling  : 'l'
                    }
                ];
                break;
            case '2a':
                conf.RollerShutter.shutters = [
                    {
                        id        : 0,
                        x         : 1,
                        realWidth : Math.floor(width / 2),
                        width     : 0,
                        realHeight: height,
                        height    : 0,
                        handling  : 'l'
                    },
                    {
                        id        : 1,
                        x         : 0 + 1,
                        realWidth : Math.ceil(width / 2),
                        width     : 0,
                        realHeight: height,
                        height    : 0,
                        handling  : 'l'
                    }
                ];
                break;
            case '2b':
                conf.RollerShutter.shutters = [
                    {
                        id        : 0,
                        x         : 1,
                        realWidth : Math.floor(width / 2),
                        width     : 0,
                        realHeight: height,
                        height    : 0,
                        handling  : 'l'
                    },
                    {
                        id        : 1,
                        x         : 0,
                        realWidth : Math.ceil(width / 2),
                        width     : 0,
                        realHeight: height,
                        height    : 0,
                        handling  : 'l'
                    }
                ];
                break;
            case '3':
                conf.RollerShutter.shutters = [
                    {
                        id        : 0,
                        x         : 1,
                        realWidth : Math.round(width / 3),
                        width     : 0,
                        realHeight: height,
                        height    : 0,
                        handling  : 'l'
                    },
                    {
                        id        : 1,
                        x         : 0 + 1,
                        realWidth : Math.round(width / 3),
                        width     : 0,
                        realHeight: height,
                        height    : 0,
                        handling  : 'l'
                    },
                    {
                        id        : 2,
                        x         : 0 + 1,
                        realWidth : width - (Math.round(width / 3) + Math.round(width / 3)),
                        width     : 0,
                        realHeight: height,
                        height    : 0,
                        handling  : 'l'
                    }
                ];
                break;
        }
        for (let i = 1; i < conf.RollerShutter.shutters.length; i++) {
            if (conf.RollerShutter.schemaId === '2b' || conf.RollerShutter.schemaId === '3') {
                conf.RollerShutter.shutters[i].commonRail = false;
            } else {
                conf.RollerShutter.shutters[i].commonRail = true;
            }
        }
        if (conf.type !== 'roller_shutter' && conf.type !== 'external_blind') {
            for (let i = 0; i < conf.RollerShutter.shutters.length; i++) {
                conf.RollerShutter.shutters[i].realHeight = conf.Height;
            }
        }
        const configurators = this.configurationsService.configurators;

        this.rollerDimensionsService.resetGuides(conf);
        this.rollerDimensionsService.resetSlats(conf);
        this.rollerDimensionsService.setGuideProfiles(conf);
        this.rollerDimensionsService.setSlatProfiles(conf);
        this.drivesService.getDefaultDrive();
        this.priceService.count();
        if (configurators.window && this.modalConfigurator) {
            if (configurators.window.Current !== null) {
                this.rollerDimensionsService.resetDimensionToWindowSize(conf);
            }
        }
        if (nextStep) {
            this.stepsService.goToNextStep(true);
        }
    }

    /**
     * Funkcja ustawiajaca domyślny system
     */
    public setDefaultSystem(conf = this.configurationsService.conf.Current, defaultConf = this.configurationsService.conf.Default) {
        const systems = this.systems.filter(system => system.n_type === conf.RollerShutter.type.type);

        if (systems && systems.length) {
            this.setSystem(systems[0], conf, defaultConf);
        }
    }

    /**
     * Funkcja ustawiajaca nazwe konfiguracji
     */
    private setConfigName(conf) {
        if (this.currentConfiguratorService.conf === 'roller_shutter' || this.currentConfiguratorService.conf === 'external_blind') {
            conf.Name
                = conf.RollerShutter.type.name + ' > ' + conf.RollerShutter.system.name;
        }
    }

    setSystem(
        system,
        conf = this.configurationsService.conf.Current,
        defaultConf = this.configurationsService.conf.Default
    ) {
        if (system) {
            conf.RollerShutter.system = system;
        }
        this.rollerDimensionsService.loadProfilesBySystem(conf);
        this.rollerDimensionsService.setBoundaryDimensionsFromPrices(conf);
        this.colorsService.init(false, conf, defaultConf);
        this.rollerDimensionsService.loadBoxHeights(false, false, conf);
        this.drivesService.loadRetractor(conf);
        this.drivesService.loadDriveManuals(conf);
        this.drivesService.loadDrives();
        this.rollerDimensionsService.setGuideProfiles(conf);
        this.setConfigName(conf);
        this.priceService.count();
        conf.RollerShutter.concealedStrip =
            conf.RollerShutter.concealedStrip
            && this.config().IccConfig.Configurators.roller_shutter.concealedStrip
            && system.concealedStrip;
        conf.RollerShutter.inInsulation =
            conf.RollerShutter.inInsulation
            && this.config().IccConfig.Configurators.roller_shutter.inInsulation
            && system.inInsulation;
    }

    /**
     * Funkcja otwierajaca okno modal
     */
    public openModalSystems() {
        const modalInstance = this.modalService.open({
            templateUrl: 'modalSystems.html',
            controller : 'ModalSystemsCtrl as msystems',
            pageComponent: SystemsPageComponent,
            resolve    : {
                systems  : () => this.getSystems(),
                selectedSystem: () => this.configurationsService.conf.Current.RollerShutter.system,
                type    : () => this.configurationsService.conf.Current.RollerShutter.type.type
            }
        });
        if (this.config().IccConfig.Configurators.tutorialAvailable) {
            this.eventBusService.post({
                key: 'tutorialSteps',
                value: 'systems'
             });
        }

        modalInstance.result.then(selectedSystem => {
            if (Common.isDefined(selectedSystem)) {
                this.setSystem(selectedSystem);
            }
        });

        modalInstance.closed.then(() => {
            if (this.config().IccConfig.Configurators.tutorialAvailable) {
                this.eventBusService.post({
                    key: 'tutorialSteps',
                    value: 'getStepImg'
                });
            }
        });

        return modalInstance.result;
    }
    getAllSystems(){
        return this.systems;
    }
    getSystems() {
        return this.systems.filter(system => system.n_type === this.configurationsService.conf.Current.RollerShutter.type.type)
    }

}

