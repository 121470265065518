import { IccColor, IccSideColors } from '@icc/common/data-types';
import { Color, SideColors, ColorId } from '@icc/common/configurations/parts/common';
import { SideColorsV2, ColorV2 } from '../../parts/common/Colors';

export function iccColorToColor(color: Partial<IccColor>): Color {
    return {
        id: Number(color.id),
        name: color.name,
        code: color.code,
        color: color.color,
        type: color.type,
        color_img: color.color_img,
        RAL: Boolean(color.RAL),
        isDefault: Boolean(color.isDefault),
        groups: (color.groups || []).map(Number),
    };
}

export function iccSideColorsToSideColors(color: Partial<IccSideColors>): SideColors {
    return {
        outer: color.outer && color.outer.id ? iccColorToColor(color.outer) : null,
        inner: color.inner && color.inner.id ? iccColorToColor(color.inner) : null,
        core: color.core && color.core.id ? iccColorToColor(color.core) : null,
        alushell: color.alushell && color.alushell.id ? iccColorToColor(color.alushell) : null,
    };
}

export function iccColorToColorId(color: Partial<IccColor>): ColorId {
    return {
        id: Number(color?.id),
        RAL: Boolean(color?.RAL),
        isDefault: Boolean(color?.isDefault),
    };
}

export function getColorUid(color: ColorId) {
    return color.id + '+' + Number(color.RAL);
}

export function sideColorsV2ToSideColors(
    colors: Record<string, ColorV2>,
    sideColors: SideColorsV2,
    langCode: string
): SideColors {
    return {
        outer:
            sideColors.outer && sideColors.outer.id
                ? convertName(colors[getColorUid(sideColors.outer)], langCode)
                : null,
        inner:
            sideColors.inner && sideColors.inner.id
                ? convertName(colors[getColorUid(sideColors.inner)], langCode)
                : null,
        core:
            sideColors.core && sideColors.core.id
                ? convertName(colors[getColorUid(sideColors.core)], langCode)
                : null,
        alushell:
            sideColors.alushell && sideColors.alushell.id
                ? convertName(colors[getColorUid(sideColors.alushell)], langCode)
                : null,
    };
}

function convertName<
    T extends {
        name: Record<string, string>;
    }
>(
    obj: T,
    langCode: string
): T & {
    name: string;
} {
    return {
        ...obj,
        name: obj.name[langCode],
    };
}
