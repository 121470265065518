/* eslint-disable max-statements */
import { Common } from '../Common';
import { core } from '../helpers';

export class TranslateService {
    static translate<T extends { id: number | string }>(
        translatedData: {
            [model: string]: {
                [id: string]: {
                    [f: string]: string;
                };
            };
        },
        obj: T,
        model: string,
        field: string,
        langCode: string,
        objectId: string = 'id',
        objectField = field
    ) {
        if (!Common.isObject(obj)) {
            return '';
        }

        const current = Common.isDefined(obj[objectField])
            ? Common.isObject(obj[objectField])
            ? (obj[objectField][langCode]
                ? obj[objectField][langCode]
                : obj[objectField]['pl'] && Common.isObject(obj[objectField])
                    ? obj[objectField]['pl']
                    : Common.isObject(obj[objectField])
                    ? obj[objectField][Object.keys(obj[objectField])[0]]
                    : obj[objectField]
            )
            : obj[objectField]
            : '';
        let id: string | number = Common.isDefined(obj[objectId]) ? obj[objectId] : null;
        if (id && typeof id === 'string' && id.includes && id.includes('.')) {
            id = id.split('.')[0];
        }

        if (translatedData) {
            if (
                Common.isDefined(translatedData[model])
                && Common.isDefined(translatedData[model][id])
                && Common.isDefined(translatedData[model][id][field])
                && translatedData[model][id][field]
            ) {
                return translatedData[model][id][field];
            }
        }

        return current;
    }

    static extractDataToTranslate(positions, lang, isGroup = false) {
        const dataToTranslate = [];
        let data = '';
        let key = '';
        let model: string | ((object: any) => string) = '';
        let keyValue = '';
        let exist = false;
        let objectId = 'id';
        const objectIds = {
            dividers: 'dividerId',
            couplings: 'couplingId',
            'sashes.intMullions': 'dividerId',
            'Mullions.static': 'dividerId',
            'Mullions.movable': 'dividerId',
            'windowLine': 'windowLineId'
        };
        const pathToModelMap = this.getModel();

        const excludedFields = [
            'priceSegments',
            'priceSegmentsNoMargin',
            'priceParts',
            'pricePartsNoMargin',
            'profileSet',
            'usedProfilesSegments',
            'frames',
            'frame',
            'layout',
            'shape',
            'nearMullions',
            'nearAlignments',
        ];

        const numberFields = [
            'glazingSpacer',
        ];

        for (let i = 0; i < positions.length; i++) {
            let pos;
            if (Common.isDefined(positions[i].doc)) {
                pos = positions[i].doc;
            } else {
                pos = positions[i];
            }
            if (!isGroup) {
                if (
                    [
                        'custom',
                        'transport_cost',
                        'colors_cost',
                        'colors_waste_cost',
                        'other',
                        'additional',
                    ].indexOf(pos.confType) === -1
                ) {
                    if (!Common.isObject(pos.details)) {
                        pos.details = core.parseJson(pos.details);
                    }
                    if (Common.isDefined(pos.details.priceSegments)) {
                        pos.details.priceSegments
                            .filter(
                                e => e != null && e.type === 'sashType' && e.data.suppId != null
                            )
                            .forEach(e => {
                                dataToTranslate.push(
                                    `("SashTypesSupplement", "${e.data.suppId}", "${lang}")`
                                );
                            });
                    }
                    extract(pos.details);
                }
            } else {
                extract(pos);
            }
        }
        // eslint-disable-next-line max-statements
        function extract(elems, path = '') {
            for (const k in elems) {
                if (excludedFields.indexOf(k) === -1) {
                    if (Common.isArray(elems[k])) {
                        for (let i = 0; i < elems[k].length; i++) {
                            if (Common.isObject(elems[k][i])) {
                                key = path ? path + '.' + k : k;
                                objectId = Common.isDefined(objectIds[key]) ? objectIds[key] : 'id';
                                if (
                                    elems[k][i][objectId] != null
                                ) {
                                    exist = false;
                                    model = Common.isDefined(pathToModelMap[key])
                                        ? pathToModelMap[key]
                                        : key;
                                    if (typeof model === 'function') {
                                        model = model(elems[k][i]) || key;
                                    }
                                    keyValue = elems[k][i][objectId];
                                    if (keyValue.includes && keyValue.includes('.')) {
                                        keyValue = keyValue.split('.')[0];
                                    }

                                    data = `("${model}", "${keyValue}", "${lang}")`;
                                    for (let j = 0; j < dataToTranslate.length; j++) {
                                        if (dataToTranslate.indexOf(data) > -1) {
                                            exist = true;
                                            break;
                                        }
                                    }
                                    if (!exist) {
                                        dataToTranslate.push(data);
                                    }
                                }
                                extract(elems[k][i], key);
                            }
                        }
                    } else if (Common.isObject(elems[k])) {
                        key = path ? path + (!isNaN(+k) || k.includes('+') ? '' : '.' + k) : k;
                        objectId = Common.isDefined(objectIds[key]) ? objectIds[key] : 'id';
                        if (elems[k][objectId] != null) {
                            exist = false;
                            model = Common.isDefined(pathToModelMap[key])
                                ? pathToModelMap[key]
                                : key;
                            if (typeof model === 'function') {
                                model = model(elems[k]) || key;
                            }
                            keyValue = elems[k][objectId];
                            if (keyValue.includes && keyValue.includes('.')) {
                                keyValue = keyValue.split('.')[0];
                            }
                            data = `("${model}", "${keyValue}", "${lang}")`;
                            for (let j = 0; j < dataToTranslate.length; j++) {
                                if (dataToTranslate.indexOf(data) > -1) {
                                    exist = true;
                                    break;
                                }
                            }
                            if (!exist) {
                                dataToTranslate.push(data);
                            }
                        }
                        extract(elems[k], key);
                    } else if (numberFields.indexOf(k) > -1 && Common.isNumber(elems[k])) {
                        key = path ? path + '.' + k : k;
                        exist = false;
                        model = Common.isDefined(pathToModelMap[key])
                            ? pathToModelMap[key]
                            : key;
                        if (typeof model === 'function') {
                            model = model(elems[k]) || key;
                        }
                        data = `("${model}", "${elems[k]}", "${lang}")`;
                        for (let j = 0; j < dataToTranslate.length; j++) {
                            if (dataToTranslate.indexOf(data) > -1) {
                                exist = true;
                                break;
                            }
                        }
                        if (!exist) {
                            dataToTranslate.push(data);
                        }
                    }
                }
            }
        }

        return dataToTranslate.filter(d => !d.includes('.'));
    }

    static getModel() {
        return {
            extension: 'Profile',
            'doorPlatesColor': 'WindowHandlesColor',
            accessories: 'WindowAccessory',
            'multipointLock': 'WindowAccessory',
            'mainLockPlate': 'WindowAccessory',
            'hinges': 'WindowAccessory',
            'doorViewer': 'WindowAccessory',

            'accessories.color.accessory': 'WindowHandlesColor',
            'accessories.color.profile.core': 'WindowColor',
            'accessories.color.profile.inner': 'WindowColor',
            'accessories.color.profile.outer': 'WindowColor',
            'accessories.category': 'WindowAccessoriesCategory',

            'colors.frame.core': 'WindowColor',
            'colors.sash.core': 'WindowColor',
            'colors.frame.outer': 'WindowColor',
            'colors.frame.inner': 'WindowColor',
            'colors.sash.outer': 'WindowColor',
            'colors.sash.inner': 'WindowColor',
            dividers: 'Divider',
            couplings: 'Profile',
            fitting: 'WindowFitting',
            frame: 'Profile',
            glazingBeadColor: 'GlazingBeadColor',
            glazingSpacer: 'WindowWarmEdge',
            'muntins.colorInner': 'WindowColor',
            'muntins.colorOuter': 'WindowColor',
            'muntins.type.type': 'WindowBarType',
            'rollerShutter.colors.box': 'WindowColor',
            'rollerShutter.colors.boxGuideOuter': 'WindowColor',
            'rollerShutter.colors.boxInner': 'WindowColor',
            'rollerShutter.colors.endslat': 'WindowColor',
            'rollerShutter.colors.guideRail': 'WindowColor',
            'rollerShutter.colors.profile': 'WindowColor',
            'rollerShutter.colors.revision': 'WindowColor',
            'rollerShutter.drive': 'RollerShutterDrive',
            'rollerShutter.driveAccessories': (object) => object.type === 'rollerShutterManual' ? 'RollerShutterDrivesManual' : 'WindowAccessory',
            'rollerShutter.profile': 'Profile',
            'rollerShutter.roundReel': 'RoundReel',
            'rollerShutter.system': 'RollerShutterSystem',
            'rollerShutter.type': 'WindowLine',
            'rollerShutter.slats': 'Profile',
            'rollerShutter.guideRails': 'Profile',
            'rollerShutter.hanger': 'RollerShutterHanger',
            sashFrame: 'SashFrame',
            'sashes.filling': 'Filling',
            'sashes.panelGlazing': 'Filling',
            'sashes.frame': 'Profile',
            'sashes.glazingBead': 'GlazingBead',
            'sashes.handleInner': 'WindowAccessory',
            'sashes.handleInner.color': 'WindowHandlesColor',
            'sashes.handleOuter': 'WindowAccessory',
            'sashes.handleOuter.color': 'WindowHandlesColor',
            'sashes.hardware': 'WindowAccessory',
            'sashes.intMullions': 'Divider',
            'sashes.intSashes.filling': 'Filling',
            'sashes.intSashes.panelGlazing': 'Filling',
            'sashes.intSashes.glazingBead': 'GlazingBead',
            'sashes.type': 'SashType',
            sealColor: 'WindowSealColor',
            siliconeColor: 'WindowSiliconeColor',

            'sideAccessories.bottom': 'WindowAccessory',
            'sideAccessories.top': 'WindowAccessory',
            'sideAccessories.left': 'WindowAccessory',
            'sideAccessories.right': 'WindowAccessory',

            'sideAccessories.bottom.color.profile.core': 'WindowColor',
            'sideAccessories.bottom.color.profile.inner': 'WindowColor',
            'sideAccessories.bottom.color.profile.outer': 'WindowColor',

            'sideAccessories.top.color.profile.core': 'WindowColor',
            'sideAccessories.top.color.profile.inner': 'WindowColor',
            'sideAccessories.top.color.profile.outer': 'WindowColor',

            'sideAccessories.left.color.profile.core': 'WindowColor',
            'sideAccessories.left.color.profile.inner': 'WindowColor',
            'sideAccessories.left.color.profile.outer': 'WindowColor',

            'sideAccessories.right.color.profile.core': 'WindowColor',
            'sideAccessories.right.color.profile.inner': 'WindowColor',
            'sideAccessories.right.color.profile.outer': 'WindowColor',

            system: 'WindowLine',
            wood: 'WoodType',
            lowThreshold: 'Frame',
            hinge: 'WindowAccessory',
            'hinge.color': 'WindowHandlesColor',
            lock: 'WindowAccessory',
            glazingBead: 'GlazingBead',
            'sashes.reinforcement': 'Reinforcement',
            layout: 'SashesLayoutsVariant',
            cassonettos: 'Cassonetto',
            windowSills: 'WindowSill',
            'fillings.filling': 'Filling',
            'fillings.glazingSpacer': 'WindowWarmEdge',
            'profiles.profile': 'Profile',
            model: 'DoorModel',
            modelGroup: 'DoorModelsGroup',
            usedProfiles: 'Profile',
            usedProfileShapes: 'ProfileShape',

            'profiles.color.profile.core': 'WindowColor',
            'profiles.color.profile.inner': 'WindowColor',
            'profiles.color.profile.outer': 'WindowColor',

            'Colors.frame.core': 'WindowColor',
            'Colors.frame.outer': 'WindowColor',
            'Colors.frame.inner': 'WindowColor',
            'Colors.frame.alushell': 'WindowColor',
            'Colors.sash.core': 'WindowColor',
            'Colors.sash.outer': 'WindowColor',
            'Colors.sash.inner': 'WindowColor',
            'Colors.sash.alushell': 'WindowColor',
            'Mullions.static': 'Divider',
            'Mullions.movable': 'Divider',
            Fitting: 'WindowFitting',
            Frame: 'Profile',
            Glazing: 'Filling',
            WarmEdge: 'WindowWarmEdge',
            System: 'WindowLine',
            Handle: 'WindowAccessory',
            HandleColor: 'WindowHandlesColor',
            RollerSystem: 'RollerShutterSystem',
            RollerType: 'WindowLine',
            SashFrame: 'SashFrame',
            FrameName: 'Profile',
            SashName: 'Profile',
            SashOutwardName: 'Profile',
            MullionName: 'Profile',

            'colors.frame': 'WindowColor',
            'colors.net': 'WindowColor',
            'colors.panel': 'WindowColor',
            'variant': 'MosquitoType',
            'windowLine': 'WindowLine',
            montage: 'MosquitoMontage',

            'dictionary.colors': 'WindowColor',
            'dictionary.glazingBeadColors': 'GlazingBeadColor',
            'dictionary.sealColors': 'WindowSealColor',
            'dictionary.siliconeColors': 'WindowSiliconeColor',
            'dictionary.accessoryColors': 'WindowHandlesColor',
            'dictionary.woods': 'WoodType',
            'dictionary.glazingSpacers': 'WindowWarmEdge',
            'dictionary.hinges': 'WindowAccessory',
            'dictionary.locks': 'WindowAccessory',
            'dictionary.fittings': 'WindowFitting',
            'dictionary.systems': 'WindowLine',
            'dictionary.profiles': 'Profile',
            'dictionary.profileShapes': 'ProfileShape',
            'dictionary.sashTypes': 'SashType',
            'dictionary.reinforcements': 'Reinforcement',
            'dictionary.handles': 'WindowAccessory',
            'dictionary.fillings': 'Filling',
            'dictionary.doorHardware': 'WindowAccessory',
            'dictionary.hardwareSystems': 'WindowAccessorySystem',
            'dictionary.doorSizes': 'DoorSize',
            'dictionary.doorLightSizes': 'DoorLightSize',
        };
    }
}
