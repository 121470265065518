import { logger, core } from '@icc/common/helpers';
import { TranslateService } from '@icc/common/translate.service';
import { ConfigurationsService, ConfiguratorsDataService, AppConfigFactory, APP_CONFIG, EventBusService, GlazingSizesService } from '@icc/common';
import { FillingsService } from './fillings.service';
import { CurrentConfiguratorService } from '@icc/common/configurators/current-configurator.service';
import { Inject, Injectable } from '@angular/core';
import { isArray, isUndefined, isObject, InfoService, IssuesService, IssueLevel } from '@icc/helpers';

@Injectable()
export class FillingValidationService {

    glazingRestricts = [];
    loadedData = false;
    constructor (
        private translateService: TranslateService,
        private configurationsService: ConfigurationsService<'window' | 'door'>,
        private fillingsService: FillingsService,
        private configuratorsDataService: ConfiguratorsDataService,
        private curConfService: CurrentConfiguratorService,
        private issuesService: IssuesService,
        @Inject(APP_CONFIG) private config: AppConfigFactory,
        private glazingSizesService: GlazingSizesService,
        private infoService: InfoService,
        eventBusService: EventBusService,
    ) {
        'ngInject';

        if (this.configuratorsDataService.loaded) {
            this.init();
        }

        eventBusService.subscribeWithoutConfiguration('initializedConfigurator', () => {
            this.init();
        });

        eventBusService.subscribe('changedSashes', () => {
            this.valid();
        });
    }

    /**
     * Funkcja inicjalizujaca
     */
    init() {
        this.issuesService.addValidateFunction(this.valid.bind(this));
        this.glazingRestricts = this.configuratorsDataService.data.glazingRestrictions;
        if (this.curConfService.conf != 'complementary_goods') {
            this.glazingSizesService.count(
                this.configurationsService.conf ? this.configurationsService.conf.Current : null
            );
        }

        this.loadedData = true;
    }

    /**
     * Funkcja sprawdzania szklenia
     * @param  {object} sash Szkydło
     * @return {bool}        Czy szklenie pasuje w danym skrzydle.
     */
    checkGlass(glazing, width = 0, height = 0, area: any = 0) {
        let restrict, noWarrantyRestrict;
        let warranty = true;
        if (!isArray(this.glazingRestricts)) {
            logger.warn('Brak ograniczeń szklenia!');
            return { valid: true };
        }
        for (var i = 0; i < this.glazingRestricts.length; i++) {
            var rest = this.glazingRestricts[i];
            if (isUndefined(glazing)) {
                continue;
            }

            if (
                parseInt(glazing.thinkness_glass) == parseInt(rest.thickness)
                && parseInt(glazing.frame_width) >= parseInt(rest.frame_width)
            ) {
                if (rest.no_warranty) {
                    noWarrantyRestrict = rest;
                } else {
                    restrict = rest;
                }
            }
        }
        if (isObject(restrict) || isObject(noWarrantyRestrict)) {
            let validGlazing = true,
                reason = '',
                type;
            const glazingWidth = width;
            const glazingHeight = height;
            const glazingArea = area;

            if (isObject(restrict)) {
                let ratio = restrict.ratio.split(':');
                ratio = ratio[1] / ratio[0];
                if (parseFloat(glazingArea) > parseFloat(restrict.area)) {
                    validGlazing = false;
                    reason =
                        reason
                        + this.translateService.instant(
                            'WINDOW|<br>Maksymalna powierzchnia tego szklenia wynosi: <b> {restrict_area}m<sup>2</sup></b>, a aktualna powierzchnia: <b> {glazing_area}m<sup>2</sup></b></li>',
                            { restrict_area: restrict.area, glazing_area: core.round(glazingArea) }
                        );
                    type = 'max';
                }
                if (glazingWidth < restrict.length_min) {
                    validGlazing = false;
                    reason =
                        reason
                        + this.translateService.instant(
                            'WINDOW|<br>Minimalna szerokość tego szklenia wynosi: <b> {restrict_length_min}mm</b>, a aktualna szerokość: <b> {glazing_width}mm</b></li>',
                            {
                                restrict_length_min: restrict.length_min,
                                glazing_width: core.round(glazingWidth),
                            }
                        );
                    type = 'min';
                }
                if (glazingHeight < restrict.length_min) {
                    validGlazing = false;
                    reason =
                        reason
                        + this.translateService.instant(
                            'WINDOW|<br>Minimalna wysokość tego szklenia wynosi: <b> {restrict_length_min}mm</b>, a aktualna wysokość: <b> {glazing_height}mm</b></li>',
                            {
                                restrict_length_min: restrict.length_min,
                                glazing_height: core.round(glazingHeight),
                            }
                        );
                    type = 'min';
                }
                if (glazingWidth > restrict.length_max) {
                    validGlazing = false;
                    reason =
                        reason
                        + this.translateService.instant(
                            'WINDOW|<br>Maksymalna szerokość tego szklenia wynosi: <b> {restrict_length_max}mm</b>, a aktualna szerokość: <b> {glazing_width}mm</b></li>',
                            {
                                restrict_length_max: restrict.length_max,
                                glazing_width: core.round(glazingWidth),
                            }
                        );
                    type = 'max';
                }
                if (glazingHeight > restrict.length_max) {
                    validGlazing = false;
                    reason =
                        reason
                        + this.translateService.instant(
                            'WINDOW|<br>Maksymalna wysokość tego szklenia wynosi: <b> {restrict_length_max}mm</b>, a aktualna wysokość: <b> {glazing_height}mm</b></li>',
                            {
                                restrict_length_max: restrict.length_max,
                                glazing_height: core.round(glazingHeight),
                            }
                        );
                    type = 'max';
                }
                if (glazingWidth >= glazingHeight && glazingWidth / glazingHeight > ratio) {
                    validGlazing = false;
                    reason =
                        reason
                        + this.translateService.instant(
                            'WINDOW|<br>Maksymalna proporcja boków tego szklenia wynosi: <b> {restrict_ratio}</b>, a aktualne proporcje: <b> {glazing_ratio}</b></li>',
                            {
                                restrict_ratio: restrict.ratio,
                                glazing_ratio: '1:' + core.round(glazingWidth / glazingHeight),
                            }
                        );
                    type = 'ratio';
                } else if (glazingHeight / glazingWidth > ratio) {
                    validGlazing = false;
                    reason =
                        reason
                        + this.translateService.instant(
                            'WINDOW|<br>Maksymalna proporcja boków tego szklenia wynosi: <b> {restrict_ratio}</b>, a aktualne proporcje: <b> {glazing_ratio}</b></li>',
                            {
                                restrict_ratio: restrict.ratio,
                                glazing_ratio: '1:' + core.round(glazingHeight / glazingWidth),
                            }
                        );
                    type = 'ratio';
                }
            }
            if (
                this.config().IccConfig.Configurators.noWarrantyGlazing
                && (!validGlazing || (validGlazing && !isObject(restrict)))
                && noWarrantyRestrict
            ) {
                let noWarrantyRestrictRatio = noWarrantyRestrict.ratio.split(':');
                noWarrantyRestrictRatio = noWarrantyRestrictRatio[1] / noWarrantyRestrictRatio[0];
                if (
                    parseFloat(glazingArea) < parseFloat(noWarrantyRestrict.area)
                    && glazingWidth > noWarrantyRestrict.length_min
                    && glazingHeight > noWarrantyRestrict.length_min
                    && glazingWidth < noWarrantyRestrict.length_max
                    && glazingHeight < noWarrantyRestrict.length_max
                    && ((glazingWidth >= glazingHeight
                        && glazingWidth / glazingHeight < noWarrantyRestrictRatio)
                        || glazingHeight / glazingWidth < noWarrantyRestrictRatio)
                ) {
                    validGlazing = true;
                    reason = '';
                    type = null;
                    warranty = false;
                    this.issuesService.simpleRegister(
                        'filling-no-warranty',
                        'Niepoprawne wypełnienie',
                        this.translateService.instant(
                            'OFFER|BRAK GWARANCJI: jeden lub więcej pakietów szybowych w konstrukcji przekracza wymiary - na pakiety szybowe nie można udzielić gwarancji.'
                        ),
                        this.configurationsService.conf.Current,
                        {
                            blockAddToOffer: false,
                            noPrice: false,
                            logLevel: IssueLevel.NONE
                        }
                    );
                }
            }

            return { valid: validGlazing, reason, type, warranty };
        }
        return { valid: true, warranty };
    }

    checkGlassInSash(sash) {
        const filling =
            this.configurationsService.conf.Current.drawData
            && this.configurationsService.conf.Current.drawData.filling
            && this.configurationsService.conf.Current.drawData.filling.find(o => o.sashId === sash.id);
        const glazingWidth = filling ? filling.rect.width : 0;
        const glazingHeight = filling ? filling.rect.height : 0;
        const glazingArea = filling ? filling.rectArea : 0;
        return this.checkGlass(sash.glazing, glazingWidth, glazingHeight, glazingArea);
    }

    getSashMessage(sash, check) {
        let message = '';

        const glazingArea = (sash.glazingSizes.height * sash.glazingSizes.width) / 1e6;
        let glazingRatio;
        if (sash.glazingSizes.height >= sash.glazingSizes.width) {
            glazingRatio = sash.glazingSizes.height / sash.glazingSizes.width;
        } else {
            glazingRatio = sash.glazingSizes.width / sash.glazingSizes.height;
        }

        const restriction = this.glazingRestricts.find(rest => {
            let ratio = rest.ratio.split(':');
            ratio = ratio[1] / ratio[0];

            return (
                rest.area > glazingArea
                && rest.length_max > sash.glazingSizes.width
                && rest.length_max > sash.glazingSizes.height
                && rest.length_min < sash.glazingSizes.width
                && rest.length_min < sash.glazingSizes.height
                && glazingRatio < ratio
            );
        });
        let proposedGlazing;
        if (restriction && restriction.frame_width) {
            proposedGlazing = this.fillingsService.fillings.find(glazing =>
                Number(glazing.thinkness_glass) >= parseFloat(restriction.thickness)
                    && Number(glazing.frame_width) >= parseFloat(restriction.frame_width)
            );
        }
        if (restriction) {
            message =
                this.translateService.instant(
                    'WINDOW|<br>Wybierz pakiet szybowy gdzie:<br><li>Najcieńsza szyba w pakiecie ma grubość: <b>{restriction_thickness}mm</b></li><li>Minimalna szerokość ramki międzyszybowej wynosi:{restriction_frame_width}mm',
                    {
                        restriction_thickness: restriction.thickness,
                        restriction_frame_width: restriction.frame_width,
                    }
                ) + '</li>';
            if (proposedGlazing && (proposedGlazing.code || proposedGlazing.name)) {
                message = `${message} <li>${this.translateService.instant(
                    'WINDOW|Przykładowo szklenie:'
                )}<b> ${proposedGlazing.code || proposedGlazing.name} mm</b></li>`;
            }
        }

        if (check.type == 'max' || check.type == 'ratio') {
            if (message) {
                message = `${message} <br> ${this.translateService.instant(
                    'WINDOW|lub podziel kwatery na mniejsze'
                )} `;
            } else {
                message = `${message} <br> ${this.translateService.instant(
                    'WINDOW|Podziel kwatery na mniejsze'
                )}`;
            }
        }

        if (check.type == 'min' && message == '') {
            message = `${this.translateService.instant('WINDOW|Nie można wykonać tak małego szklenia')}`;
        }

        const parentSash = this.configurationsService.conf.Current.Sashes.find(
            e => e.id === sash.parentId
        );
        const sashIndex = parentSash
            ? `${parentSash.index}${sash.index ? '.' + sash.index : ''}`
            : `${sash.index}`;
        message =
            this.translateService.instant(
                'WINDOW|Szklenie <b>{glazing_code}</b> w kwaterze: <b>{sash_index}</b> nie spełnia następujących warunków:',
                { glazing_code: sash.glazing.code || sash.glazing.name, sash_index: sashIndex }
            )
            + check.reason
            + message;

        return { message, hasSashIndex: sashIndex != null };
    }

    /**
     * Funkcja walidujaca. Sprawdza czy wypełnienia w konfiguracji są poprawne.
     * @return {bool} Czy wypełnienia w konfiguracji są poprawne.
     */
    valid() {
        if (
            !['window', 'hs', 'door', 'folding_door', 'sliding_door'].includes(
                this.configurationsService.conf.Current.type
            )
        ) {
            this.issuesService.unregister('incorrect-filling', this.configurationsService.conf.Current);
            return true;
        }

        const messages = [];
        let warranty = true;

        this.configurationsService.conf.Current.Sashes.reduce(
            (sashes, sash) => sashes.concat([sash], sash.intSashes),
            []
        )
            .filter(sash => !sash.intSashes || !sash.intSashes.length)
            .map(sash => {
                const check = this.checkGlassInSash(sash);
                warranty = warranty && check.warranty;
                if (!check.valid) {
                    const { message, hasSashIndex } = this.getSashMessage(sash, check);
                    if (hasSashIndex) {
                        messages.push(message);
                    }
                }
            });

        this.configurationsService.conf.Current.GlazingWarranty = warranty;

        if (messages.length) {
            this.issuesService.simpleRegister(
                'incorrect-filling',
                'Niepoprawne wypełnienie',
                messages.join('<br><br>'),
                this.configurationsService.conf.Current,
                {
                    logLevel: IssueLevel.NONE,
                    extra: {
                        messages
                    }
                }
            );
            return false;
        } else {
            this.issuesService.unregister('incorrect-filling', this.configurationsService.conf.Current);
            return true;
        }
    }


}
