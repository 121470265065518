import { core } from '@icc/common/Core';
import { IIccConfig } from '@icc/config';

export function weightUnitFormatter (value: number | string,  weightUnit: string, round: number,  config: IIccConfig) {
    let weight = Number(value);

    if(weightUnit === 'lb') {
        weight = weight * config.Configurators.kgToPounds;
    }
    
    if (round === 0) {
        weight = core.formatNumber(weight, round, 100);
    } else if (round > 0) {
        weight = core.toFixed(weight, round);
    }

    return weight + ` ${weightUnit}`;
}
