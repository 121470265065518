import { TemplateRef } from '@angular/core';

export interface iccListItem {
    categoryId?: number | string;
    id: number | string;
    /** Id wykorzystywane w przypadku, gdy rekord o 1 id jest dzielony na kilka z różnymi ID (na przykład panele ozdobne w ModelListPageComponent) */
    fullId?: number | string;
    title?: string;
    description?: string;
    imageUrl?: string;
    color?: string;
    side?: 'left' | 'right' | 'top' | 'bottom';
    tabs?: (number | string)[];
    showInfo?: boolean;
    editGlazing?: boolean;
    disabled?: boolean;
    show_title_image?: boolean;
}

export interface iccListTab {
    id: number | string;
    name: string;
    icon?: string;
    active?: boolean;
    quantity_restriction?: any;
    max_quantity?: any;
}

export interface iccSelect {
    id: number;
    name: string;
}

export type iccListItemTemplate =
    | 'itemOnlyImg'
    | 'itemColor'
    | 'itemImgTitleDescription'
    | 'mountingMethod'
    | 'itemImgTitle'
    | 'itemOnlyTitle';
export type iccListItemWidth = 'full' | 'wide' | 'medium' | 'narrow' | 'verynarrow';
export type iccListItemImgHeight = 'low' | 'medium' | 'high';

export type TemplateMap = {
    [key in iccListItemTemplate]: {
        template: TemplateRef<any>;
        width: iccListItemWidth;
        imgHeight: iccListItemImgHeight;
    }
}

export function itemTemplateIsType(
    template: TemplateRef<any> | iccListItemTemplate | undefined,
    map: TemplateMap
): template is iccListItemTemplate {
    return typeof template === 'string' && Object.keys(map).includes(template);
}
