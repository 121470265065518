import { ConfigurationType, ConfiguratorOptions, ReturnButton, ReturnButtonVariant } from '../configurator-options';
import { Step } from './shared.actions';
import { ComponentType } from '@angular/cdk/overlay';
import { PageComponent } from '@icc/helpers';
import { Issue } from '@icc/helpers';

export const CONFIGURATOR_KEY = 'configurator';

export interface StepOption {
    name: string;
    route: string;
}

export interface OpenedPage {
    component: ComponentType<PageComponent>,
    data: { [key: string]: string | Function | Array<string | Function> | Object },
    id: string
}

export interface ClosedPage {
    id: string;
    result: any
}

export interface Message {
    message: string;
    footer: boolean;
    dismissed?: boolean;
    issue?: boolean;
}

export interface PreviewData {
    isPreviewEnabled: boolean,
    drawData: any
}
export interface UiState {
    previewData: PreviewData;
    options: ConfiguratorOptions;
    steps: Step[];
    selectedStep?: string;
    openedPages: OpenedPage[];
    closedPages: ClosedPage[];
    openedFilterSheets: OpenedPage[];
    closedFilterSheets: ClosedPage[];
    parentConfigurationType: ConfigurationType | null;
    messages: Message[];
    loaded: boolean;
    fullscreen: boolean;
    error?: any;
    colorStepMode: 'simple' | 'advanced' | 'door_extended';
    systemStepMode: 'list' | 'details';
    lightsGlazingStepMode: 'list' | 'extra';
    savingButton: boolean;
    returnButton: ReturnButtonVariant;
    additionalReturnButtons?: ReturnButton[];
    activeSearch: boolean;
    searchValue: string;
    currency: any;
}

export interface ConfiguratorState {
    uiState: UiState;
}

export interface ConfiguratorRootState {
    configurator: ConfiguratorState;
}

export interface ConfiguratorPartialState {
    readonly [CONFIGURATOR_KEY]: ConfiguratorState;
}
