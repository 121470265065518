<ng-container *ngIf="mode === 'simple'">
    <icc-list searchable="true" [tabs]="colorGroups" [items]="colors"  (select)="selectColor($event)" [itemTemplate]="'itemColor'" [selected]="selectedColorId$ | async"></icc-list>
</ng-container>
<ng-container *ngIf="mode === 'advanced' || mode === 'extended' || mode === 'door_extended'">

    <icc-select-box
        [title]="((mode === 'door_extended' || !(doorType$ | async)) && !isDoubleSidedColorAvailable()) ? ('COLOR|Kolor zewnętrzny drzwi' | translate) : ('COLOR|Kolor drzwi' | translate)"
        [description]="(outerFillingFirst$ | async)?.name"
        [color]="'#' + (outerFillingFirst$ | async)?.color"
        [imageUrl]="'/files/color/' + (outerFillingFirst$ | async)?.color_img"
        [buttonText]="'INTERFACE|Zmień' | translate"
        [colorSelect]="true"
        *ngIf="showOuterFillingFirstColor(hasDecoPanel$ | async)"
        (select)="openModalFillingColor('outer', 'first', 'doorActive')"
    ></icc-select-box>
    <icc-select-box
        *ngIf="showInnerFillingFirstColor((hasDecoPanel$ | async), (doorType$ | async), (hasDoorActiveInner$ | async))"
        [title]="'COLOR|Kolor wewnętrzny drzwi' | translate"
        [description]="(innerFillingFirst$ | async)?.name"
        [color]="'#' + (innerFillingFirst$ | async)?.color"
        [imageUrl]="'/files/color/' + (innerFillingFirst$ | async)?.color_img"
        [colorSelect]="true"
        [buttonCustomStyle]="(showWhiteColorButton('innerFrame') && (hasAlushell$ | async) !== true) || showBilateralButton('innerFrame') && (hasAlushell$ | async) !== true"
        [buttonText]="showWhiteColorButton('innerFillingFirst') && (hasAlushell$ | async) !== true ? ('INTERFACE|Biały' | translate) : false"
        (select)="setWhiteColor('sash', 'innerFillingFirst')"
        [disabledButton1]="isWhiteColorButtonDisabled('sash', 'innerFillingFirst')"
        [buttonText2]="showBilateralButton('innerFillingFirst') && (hasAlushell$ | async) !== true ? ('INTERFACE|Kolor obustronny' | translate) : false"
        (select2)="setDoubleSidedColor('sash', 'innerFillingFirst')"
        (selectExpandMore)="openModalFillingColor('inner', 'first', 'doorActive')"
        [buttonExpandMore]="selectedSystemType === 'pvc' && (hasAlushell$ | async) !== true ? ('INTERFACE|Zmień' | translate) : false"
        [buttonText3]="((hasAlushell$ | async) || (doorType$ | async)) || selectedSystemType !== 'pvc'  ? ('INTERFACE|Zmień' | translate) : false"
        (select3)="openModalFillingColor('inner', 'first', 'doorActive')"
        ></icc-select-box>
    <icc-select-box
        [title]="(mode === 'door_extended' || !(doorType$ | async)) ? ('COLOR|Kolor zewnętrzny aplikacji drzwi' | translate) : ('COLOR|Kolor aplikacji drzwi' | translate)"
        [description]="(outerFillingOther$ | async)?.name"
        [color]="'#' + (outerFillingOther$ | async)?.color"
        [imageUrl]="'/files/color/' + (outerFillingOther$ | async)?.color_img"
        [buttonText]="'INTERFACE|Zmień' | translate"
        [colorSelect]="true"
        *ngIf="availableDecoPanelSecondColor$ | async"
        (select)="openModalFillingColor('outer', 'other', 'doorActive')"
    ></icc-select-box>
    <icc-select-box
        *ngIf="showInnerFillingOther((availableInnerDecoPanelSecondColor$ | async), (showInnerDoorPanel(doorType$ | async)), (hasDoorActiveInner$ | async))"
        [title]="'COLOR|Kolor wewnętrzny aplikacji drzwi' | translate"
        [description]="(innerFillingOther$ | async)?.name"
        [color]="'#' + (innerFillingOther$ | async)?.color"
        [imageUrl]="'/files/color/' + (innerFillingOther$ | async)?.color_img"
        [buttonText]="'INTERFACE|Zmień' | translate"
        [colorSelect]="true"
        (select)="openModalFillingColor('inner', 'other', 'doorActive')"
    ></icc-select-box>

    <!-- Skrzydło bierne -->
    <ng-container  *ngIf="hasDoorPassiveSash$ | async">
        <icc-select-box
            [title]="(mode === 'door_extended') ? ('COLOR|Kolor zewnętrzny skrzydła biernego' | translate) : ('COLOR|Kolor skrzydła biernego' | translate)"
            [description]="(outerFillingFirstPassive$ | async)?.name"
            [color]="'#' + (outerFillingFirstPassive$ | async)?.color"
            [imageUrl]="'/files/color/' + (outerFillingFirstPassive$ | async)?.color_img"
            [buttonText]="'INTERFACE|Zmień' | translate"
            [colorSelect]="true"
            *ngIf="hasDecoPanelPassive$ | async"
            (select)="openModalFillingColor('outer', 'first', 'doorPassive')"
        ></icc-select-box>
        <icc-select-box
            [title]="'COLOR|Kolor wewnętrzny skrzydła biernego' | translate"
            [description]="(innerFillingFirstPassive$ | async)?.name"
            [color]="'#' + (innerFillingFirstPassive$ | async)?.color"
            [imageUrl]="'/files/color/' + (innerFillingFirstPassive$ | async)?.color_img"
            [buttonText]="'INTERFACE|Zmień' | translate"
            [colorSelect]="true"
            *ngIf="(hasDecoPanelPassive$ | async) && showInnerDoorPanel((doorType$ | async))"
            (select)="openModalFillingColor('inner', 'first', 'doorPassive')"
        ></icc-select-box>
        <icc-select-box
            [title]="(mode === 'door_extended') ? ('COLOR|Kolor zewnętrzny aplikacji skrzydła biernego' | translate) : ('COLOR|Kolor aplikacji skrzydła biernego' | translate)"
            [description]="(outerFillingOtherPassive$ | async)?.name"
            [color]="'#' + (outerFillingOtherPassive$ | async)?.color"
            [imageUrl]="'/files/color/' + (outerFillingOtherPassive$ | async)?.color_img"
            [buttonText]="'INTERFACE|Zmień' | translate"
            [colorSelect]="true"
            *ngIf="availableDecoPanelSecondColorPassive$ | async"
            (select)="openModalFillingColor('outer', 'other', 'doorPassive')"
        ></icc-select-box>
        <icc-select-box
            [title]="'COLOR|Kolor wewnętrzny aplikacji skrzydła biernego' | translate"
            [description]="(innerFillingOtherPassive$ | async)?.name"
            [color]="'#' + (innerFillingOtherPassive$ | async)?.color"
            [imageUrl]="'/files/color/' + (innerFillingOtherPassive$ | async)?.color_img"
            [buttonText]="'INTERFACE|Zmień' | translate"
            [colorSelect]="true"
            *ngIf="(availableDecoPanelSecondColorPassive$ | async) && showInnerDoorPanel((doorType$ | async))"
            (select)="openModalFillingColor('inner', 'other', 'doorPassive')"
        ></icc-select-box>
    </ng-container>

    <ng-container *ngIf="mode === 'extended'">
        <icc-select-box
            *ngIf="(hasAlushellColorSelect$ | async)"
            [title]="'COLOR|Kolor nakładki aluminiowej skrzydła' | translate"
            [description]="(alushellSash$ | async)?.name"
            [color]="'#' + (alushellSash$ | async)?.color"
            [imageUrl]="'/files/color/' + (alushellSash$ | async)?.color_img"
            [buttonText]="'INTERFACE|Zmień' | translate"
            [colorSelect]="true"
            (select)="openModalColor('alushell', 'sash')"
        ></icc-select-box>
        <icc-select-box *ngIf="showOuterSash(hasAlushell$ | async)"
            [title]="'COLOR|Kolor zewnętrzny skrzydła' | translate"
            [description]="(outerSash$ | async)?.name"
            [color]="'#' + (outerSash$ | async)?.color"
            [imageUrl]="'/files/color/' + (outerSash$ | async)?.color_img"
            [buttonText]="'INTERFACE|Zmień' | translate"
            [colorSelect]="true"
            (select)="openModalColor('outer', 'sash')"
        ></icc-select-box>
        <icc-select-box *ngIf="showInnerSash()"
            [title]="'COLOR|Kolor wewnętrzny skrzydła' | translate"
            [description]="(innerSash$ | async)?.name"
            [color]="'#' + (innerSash$ | async)?.color"
            [imageUrl]="'/files/color/' + (innerSash$ | async)?.color_img"
            [colorSelect]="true"
            [buttonCustomStyle]="(showWhiteColorButton('innerFrame') && (hasAlushell$ | async) !== true) || showBilateralButton('innerFrame') && (hasAlushell$ | async) !== true"
            [buttonText]="showWhiteColorButton('innerSash') && (hasAlushell$ | async) !== true ? ('INTERFACE|Biały' | translate) : false"
            (select)="setWhiteColor('sash', 'innerSash')"
            [disabledButton1]="isWhiteColorButtonDisabled('sash', 'innerSash')"
            [buttonText2]="showBilateralButton('innerSash') && (hasAlushell$ | async) !== true ? ('INTERFACE|Kolor obustronny' | translate) : false"
            (select2)="setDoubleSidedColor('sash', 'innerSash')"
            [buttonText3]="(hasAlushell$ | async) || selectedSystemType !== 'pvc' ? ('INTERFACE|Zmień' | translate) : false"
            (select3)="openModalColor('inner', 'sash')"
            [buttonExpandMore]="selectedSystemType === 'pvc' && (hasAlushell$ | async) !== true ? ('INTERFACE|Zmień' | translate) : false"
            (selectExpandMore)="openModalColor('inner', 'sash')"
        ></icc-select-box>
        <icc-select-box
            *ngIf="showCore()"
            [title]="'WINDOW|Rdzeń skrzydła' | translate "
            [description]="(coreSash$ | async)?.name"
            [color]="'#' + (coreSash$ | async)?.color"
            [imageUrl]="'/files/color/' + (coreSash$ | async)?.color_img"
            [buttonText]="'INTERFACE|Zmień' | translate"
            [disabledButton1]="isCoreButtonDisabled()"
            [colorSelect]="true"
            (select)="openModalColor('core', 'frame')"
        ></icc-select-box>
    </ng-container>

    <icc-select-box
        [title]="getFrameAlushellTitle()"
        [description]="(alushellFrame$ | async)?.name"
        [color]="'#' + (alushellFrame$ | async)?.color"
        [imageUrl]="'/files/color/' + (alushellFrame$ | async)?.color_img"
        [buttonText]="'INTERFACE|Zmień' | translate"
        [colorSelect]="true"
        *ngIf="hasAlushellColorSelect$ | async"
        (select)="openModalColor('alushell', 'frame')"
    ></icc-select-box>
    <icc-select-box
        *ngIf="showFrameOuter((hasAlushell$ | async), (doorType$ | async), showOuterFillingFirstColor(hasDecoPanel$ | async))"
        [title]="getFrameOuterTitle((doorType$ | async), (hasDecoPanel$ | async))"
        [description]="(outerFrame$ | async)?.name"
        [color]="'#' + (outerFrame$ | async)?.color"
        [imageUrl]="'/files/color/' + (outerFrame$ | async)?.color_img"
        [buttonText]="'INTERFACE|Zmień' | translate"
        [colorSelect]="true"
        (select)="openModalColor('outer', 'frame')"
    ></icc-select-box>
    <icc-select-box
        *ngIf="showFrameInner((doorType$ | async), (hasAlushell$ | async), showInnerFillingFirstColor((hasDecoPanel$ | async), (doorType$ | async), (hasDoorActiveInner$ | async)))"
        [title]="getFrameInnerTitle((doorType$ | async), (hasDecoPanel$ | async))"
        [description]="(innerFrame$ | async)?.name"
        [color]="'#' + (innerFrame$ | async)?.color"
        [imageUrl]="'/files/color/' + (innerFrame$ | async)?.color_img"
        [colorSelect]="true"
        [buttonCustomStyle]="(showWhiteColorButton('innerFrame') && (hasAlushell$ | async) !== true) || showBilateralButton('innerFrame') && (hasAlushell$ | async) !== true"
        [buttonText]="showWhiteColorButton('innerFrame') && (hasAlushell$ | async) !== true ? ('INTERFACE|Biały' | translate) : false"
        (select)="setWhiteColor('frame', 'innerFrame')"
        [disabledButton1]="isWhiteColorButtonDisabled('frame', 'innerFrame')"
        [buttonText2]="showBilateralButton('innerFrame') && (hasAlushell$ | async) !== true ? ('INTERFACE|Kolor obustronny' | translate) : false"
        (select2)="setDoubleSidedColor('frame', 'innerFrame')"
        (selectExpandMore)="openModalColor('inner', 'frame')"
        [buttonExpandMore]="selectedSystemType === 'pvc' && (hasAlushell$ | async) !== true ? ('INTERFACE|Zmień' | translate) : false"
        [buttonText3]="(hasAlushell$ | async) || (doorType$ | async) || selectedSystemType !== 'pvc' ? ('INTERFACE|Zmień' | translate) : false"
        (select3)="openModalColor('inner', 'frame')"
    ></icc-select-box>
    <icc-select-box
        *ngIf="showCore()"
        [title]="getFrameCoreTitle()"
        [description]="(coreFrame$ | async)?.name"
        [color]="'#' + (coreFrame$ | async)?.color"
        [imageUrl]="'/files/color/' + (coreFrame$ | async)?.color_img"
        [buttonText]="'INTERFACE|Zmień' | translate"
        [colorSelect]="true"
        [disabledButton1]="isCoreButtonDisabled()"
        (select)="openModalColor('core', 'frame')"
    ></icc-select-box>
    <icc-select-box
        [title]="'COLOR|Kolor progu' | translate"
        [description]="(thresholdColor$ | async)?.name"
        [color]="'#' + (thresholdColor$ | async)?.color"
        [imageUrl]="'/files/color/' + (thresholdColor$ | async)?.color_img"
        [buttonText]="'INTERFACE|Zmień' | translate"
        [colorSelect]="true"
        [disabledButton1]="(availableThresholds$ | async)?.length <= 1"
        *ngIf="hasThresholdColorSelect$ | async"
        (select)="openModalThresholdColor()"
    ></icc-select-box>

    <icc-select-box
        [title]="mode === 'door_extended' && (showInnerBicolorLippingColor((hasLippingBicolorSelect$ | async), showInnerLippingColor(doorType$ | async)))? ('COLOR|Kolor zewnętrzny listwy mocującej' | translate) : ('COLOR|Kolor listwy mocującej' | translate)"
        [description]="(lippingColor$ | async)?.name"
        [color]="'#' + (lippingColor$ | async)?.color"
        [imageUrl]="'/files/color/' + (lippingColor$ | async)?.color_img"
        [buttonText]="'INTERFACE|Zmień' | translate"
        [colorSelect]="true"
        *ngIf="hasLippingColorSelect$ | async"
        (select)="openModalLippingColor('outer')"
    ></icc-select-box>

    <icc-select-box
        *ngIf="showInnerBicolorLippingColor((hasLippingBicolorSelect$ | async), showInnerLippingColor(doorType$ | async))"
        [title]="'COLOR|Kolor wewnętrzny listwy mocującej' | translate"
        [description]="(innerLippingColor$ | async)?.name"
        [color]="'#' + (innerLippingColor$ | async)?.color"
        [imageUrl]="'/files/color/' + (innerLippingColor$ | async)?.color_img"
        [buttonText]="'INTERFACE|Zmień' | translate"
        [colorSelect]="true"
        (select)="openModalLippingColor('inner')"
        ></icc-select-box>

    <icc-select-box *ngIf="(selectedDoorPortal$ | async)"
        [title]="('DOOR|Kolor portalu' | translate)"
        [description]="(selectedDoorPortalColor$ | async)?.name"
        [imageUrl]="'/files/color/' + (selectedDoorPortalColor$ | async)?.color_img"
        [buttonText]="'INTERFACE|Zmień' | translate"
        [colorSelect]="true"
        (select)="openModalPortalColor()"
    ></icc-select-box>
</ng-container>
