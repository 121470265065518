/* eslint-disable max-statements */
import { Common } from './Common';
import { core } from './helpers';
import { TranslateService } from './translations/TranslateService';
import { priceFromSegments } from '@icc/price/b2b';
import { Profile, SashV2, ReinforcementV2, ProfileShapeV2, SideProfile } from '@icc/window';
import { WindowConfiguration } from '@icc/common/configurations/WindowConfiguration';
import { DoorConfiguration } from '@icc/common/configurations/DoorConfiguration';
import { CoupledWindowConfiguration } from '@icc/common/configurations/CoupledWindowConfiguration';
import { getColorUid } from './configurations/converters/window/colors';
import { ParserService } from './ParserService';
import { crankLengths, mountingLengths } from '@icc/common/configurators/driveEngineStatic';
import { Inject } from '@angular/core';
import { AppConfig, APP_CONFIG } from '@icc/common';
import { PositionService } from './offers/PositionService';

export class PositionsDetailsService {
    private mapMullionType = {
        fixed_mullion: 'static',
        false_mullion: 'movable',
        false_mullion_central: 'movableCentral',
        false_mullion_double: 'movableDouble',
    };

    private complementaryGoods = [
        'accessories',
        'cassonettos',
        'fillings',
        'profiles',
        'windowSills',
    ];

    constructor(
        private IccConfig,
        private positions,
        private offer,
        private access,
        private showPrices,
        private priceType,
        private langCode,
        private translateFilter,
        private editable = false,
        private market_time_limits_offer_positions = false,
        private market_time_limits_pdf_positions = false,
        private pdf = false,
        private translatedData = null,
        private showInfo: any = false,
        private allPositions = positions,
        private hideDimensions = false,
        @Inject(APP_CONFIG) public config?: AppConfig,
    ) {
        'ngInject';
        if (Common.isArray(positions)) {
            return this.offerPositions(positions, access, translatedData, allPositions, offer?.currency);
        } else {
            return this.offerPosition(positions, translatedData, access, allPositions, offer?.currency);
        }
    }

    /**
     * Funkcja ustawiające dane do wyświetlania
     * @param  {array}  positions Pozycje
     * @param  {object} offer     Oferta
     * @param  {string} access    Typ użytkownika
     * @return {array}            Pozycje
     */
    offerPositions(positions, access, translatedData, allPositions, currency) {
        for (let i = 0; i < positions.length; i++) {
            this.offerPosition(positions[i], translatedData, access, allPositions, currency);
        }

        return positions;
    }

    offerPosition(position: any, translatedData: any, access: any, allPositions: any[], currency: any) {
        let pos;
        if (Common.isDefined(position.doc)) {
            pos = position.doc;
        } else {
            pos = position;
        }
        if (
            [
                'custom',
                'transport_cost',
                'colors_cost',
                'colors_waste_cost',
                'other',
                'additional',
                'static'
            ].indexOf(pos.confType) === -1
        ) {
                if (!Common.isObject(pos.details)) {
                    pos.details = core.parseJson(pos.details);
                }
                if (!Common.isObject(pos.configuration)) {
                    pos.configuration = core.parseJson(pos.configuration);
                }
                const conf = pos.details;
                const drawData = pos.configuration.drawData;
                const prices =
                this.priceType === 'dealer' ? conf.priceSegmentsNoMargin : conf.priceSegments;
                const priceParts =
                this.priceType === 'dealer' ? conf.pricePartsNoMargin : conf.priceParts;
                const config = this.config;
                const IccConfig = this.IccConfig;
                let details = this.positionSimpleDetails(pos, conf, prices, priceParts, currency, translatedData, config, IccConfig);
                const positionDetailsData = {
                    pos,
                    conf,
                    drawData,
                    details,
                    prices,
                    priceParts,
                    translatedData,
                    allPositions,
                    currency,
            };
            if (conf.sashes && conf.sashes.length) {
                details = this.positionDetails(positionDetailsData);
                details = this.positionDetailsSashes(positionDetailsData);
            }
            if (Common.isDefined(conf.rollerShutter) && conf.rollerShutter !== null) {
                details = this.positionDetailsRollers(positionDetailsData);
            }
            if (
                (conf?.rollerShutter?.Accessories?.length)
                || conf.accessories
                && (conf.accessories.length
                    || (conf.sideAccessories
                        && (conf.sideAccessories.bottom.length
                            || conf.sideAccessories.top.length
                            || conf.sideAccessories.left.length
                            || conf.sideAccessories.right.length)))
            ) {
                details = this.positionDetailsAccessories(positionDetailsData);
            }
            if (conf.type === 'garage_door') {
                details = this.positionDetailsGarageDoor(positionDetailsData);
            }
            if (conf.type === 'mosquito') {
                details = this.positionDetailsMosquito(positionDetailsData);
                if (
                    details.colors
                    && details.colors.frame
                    && !details.colors.frame.RAL
                    && details.colors.frame.group != null
                    && details.colors.frame.group !== 'RAL'
                ) {
                    details.noEdit = true;
                }
            }
            if (conf.type === 'winter_garden') {
                details = this.positionDetailsWinterGarden(positionDetailsData);
            }
            if (conf.type === 'awning') {
                details = this.positionDetailsAwning(positionDetailsData);
            }
            if (conf.type === 'pleated_blind') {
                details = this.positionDetailsPleatedBlinds(positionDetailsData);
            }
            if (conf.type === 'complementary_goods') {
                details = this.positionDetailsComplementaryGoods(positionDetailsData);
            }
            if (
                details.mosquitos
                && details.mosquitos.length
                && details.mosquitos.some(
                    el =>
                        el.colors
                        && el.colors.frame
                        && !el.colors.frame.RAL
                        && el.colors.frame.group != null
                        && el.colors.frame.group !== 'RAL'
                )
            ) {
                details.noEdit = true;
            }
            if (conf.type === 'coupled_window') {
                details = this.positionDetailsCoupledWindow(positionDetailsData);
            }
            if (
                [
                    "window",
                    "hs",
                    "door",
                    "folding_door",
                    "sliding_door",
                    "garage_door",
                    "roller_shutter",
                    "pleated_blind",
                    "external_blind"
                ].indexOf(conf.type) > -1
                && this.offer
                && this.offer.montages
            ) {
                details = this.positionMontages(positionDetailsData);
            }
            details.showDetails = pos.show_details ?? true;

            if (Common.isObject(pos.detailsForView)) {
                Object.assign(pos.detailsForView, details);
            } else {
                pos.detailsForView = details;
            }
        } else {
            if (!Common.isObject(pos.details)) {
                pos.details = core.parseJson(pos.details);
            }
            const conf = pos.details;
            let details = this.positionAdditionalDetails(pos, conf, access);
            if (pos.confType === 'colors_cost') {
                const positionDetailsData = {
                    conf,
                    details,
                    translatedData,
                    discounts: {
                        client: this.offer.client_discount_position,
                        dealer: this.offer.dealer_discount_producer,
                    },
                    currency,
                };
                details = this.positionDetailsColorsCost(positionDetailsData);
            }
            if (pos.confType === 'colors_waste_cost') {
                const positionDetailsData = {
                    conf,
                    details,
                    translatedData,
                    discounts: {
                        client: this.offer.client_discount_position,
                        dealer: this.offer.dealer_discount_producer,
                    },
                    currency,
                };
                details = this.positionDetailsColorsWasteCost(positionDetailsData);
            }
            if (conf.goodType) {
                details.goodType = conf.goodType;
            }
            if (Common.isObject(pos.detailsForView)) {
                Object.assign(pos.detailsForView, details);
            } else {
                pos.detailsForView = details;
            }
        }

        const tree = ParserService.parseConfiguration(
            pos.detailsForView,
            this.IccConfig,
            this.pdf,
            this.translateFilter,
            this.hideDimensions ? ParserService.hideDimensionUnit : ParserService.dimensionUnit,
            this.editable,
            this.market_time_limits_offer_positions,
            this.market_time_limits_pdf_positions,
            this.offer?.client_split_transport_cost,
            this.offer?.split_transport_cost,
            this.offer?.transport_from_producent,
            this.offer?.order == '1',
            undefined,
            this.config
        );

        if (Common.isObject(pos.detailsTree)) {
            Object.assign(pos.detailsTree, tree);
        } else {
            pos.detailsTree = tree;
        }

        return pos;
    }

    /**
     * Konstruktor danych pozycji dla widoku
     * @param  {object} pos    Pozycja
     * @param  {object} conf   Konfiguracja
     * @param  {array}  prices Tablica segmentów cen
     * @return {object}        Dane pozycji dla widoku
     */
    positionSimpleDetails(pos, conf, prices, priceParts, currency, translatedData, appConfig, IccConfig) {
        const posName = pos.name ?? this.getPositionName(pos, translatedData);
        return {
            id: pos.tmp_id,
            index: pos.listNumber,
            type: pos.confType,
            standard: pos.standard,
            name: posName,
            title: this.IccConfig.Offer.groupsOfPositions
                ? pos.custom_title
                    ? pos.custom_title
                    : posName
                : posName,
            customTitle: pos.custom_title,
            hasSashes: conf.sashes && conf.sashes.length ? true : false,
            height: conf.height,
            width: conf.width,
            size: pos.size ||
                (this.isSideProfileSelected(conf) || this.IccConfig.Configurators.showDimensionUnit ?
                this.getCustomTextForConstructionSize(conf.sideProfiles, conf.width, conf.height) :
                    ParserService.dimensionUnit(conf.width, this.config?.dimensionUnit ?? this.config?.dimension_unit, this.IccConfig, appConfig?.number_of_places_after_decimal_separator)
                        + ' x ' +
                    ParserService.dimensionUnit(conf.height, this.config?.dimensionUnit ?? this.config?.dimension_unit, this.IccConfig, appConfig?.number_of_places_after_decimal_separator)
                ),
            factors: pos.configuration.Factors,
            area: pos.area,
            weight: conf.parameters?.weight,
            dimension_unit: appConfig?.dimensiontUnit ?? appConfig?.dimension_unit,
            number_of_places_after_decimal_separator: appConfig?.number_of_places_after_decimal_separator,
            weight_unit: appConfig?.weightUnit ?? appConfig?.weight_unit,
            glazing_area: pos.glazing_area,
            circuit: pos.circuit,
            shutter_circuit: pos.shutter_circuit,
            door_circuit: pos.door_circuit,
            window_circuit: pos.window_circuit,
            image: pos.image,
            description: pos.description,
            groupCode: pos.groupCode,
            quantity: pos.quantity,
            attachments: pos.attachments,
            transportCost: pos.transport_cost,
            clientTransportCost: pos.client_transport_cost,
            weldFinishType: conf.weldFinishType,
            drainagePosition: conf.drainagePosition,
            timeLimit: conf.timeLimit,
            modified: pos.modified,
            accessories: {
                price:
                    priceFromSegments(prices, priceParts, currency, { value: { type: 'accessories' } })
                    + priceFromSegments(prices, priceParts, currency, {
                        value: { type: 'sideAccessories' },
                    })
                    + priceFromSegments(prices, priceParts, currency, {
                        value: { type: 'sashesHardware' },
                    })
                    + priceFromSegments(prices, priceParts, currency, {
                        value: { type: 'complementaryGoods', data: { good: 'accessory' } },
                    }),
                construction: [],
                sashes: [],
                side: {
                    top: [],
                    bottom: [],
                    left: [],
                    right: [],
                },
            },
            dependencies: {
                price: priceFromSegments(prices, priceParts, currency, {
                    value: { type: 'dependencies' },
                }),
                sashes: [],
            },
            prices: {
                client: {
                    priceBeforeDiscount: Number(pos.client_price_before_discount),
                    price: Number(pos.client_price),
                    discount: Number(pos.client_discount),
                    base: core.roundPrice(pos.summary?.client?.components?.base),
                    surcharges: core.roundPrice(
                        pos.summary?.client?.components?.addons
                            + pos.summary?.client?.components?.fitting
                            + pos.summary?.client?.components?.glass
                            + pos.summary?.client?.components?.roller
                            + pos.summary?.client?.components?.colorCost
                            + pos.summary?.client?.components?.mosquito
                    ),
                },
                dealer: {
                    priceBeforeDiscount: Number(pos.dealer_price_before_discount),
                    price: Number(pos.dealer_price),
                    discount: Number(pos.dealer_discount),
                    base: core.roundPrice(pos.summary?.dealer?.components?.base),
                    surcharges: core.roundPrice(
                        pos.summary?.dealer?.components?.addons
                            + pos.summary?.dealer?.components?.fitting
                            + pos.summary?.dealer?.components?.glass
                            + pos.summary?.client?.components?.roller
                            + pos.summary?.client?.components?.colorCost
                            + pos.summary?.client?.components?.mosquito
                    ),
                },
                valuatedPrice: Number(pos.valuated_price),
                groupDiscounts: Common.isObject(pos.group_discounts)
                    ? pos.group_discounts
                    : core.parseJson(pos.group_discounts),
                show:
                    this.access
                    && ((pos.standard && (Number(pos.dealer_price) > 0 && this.showPrices))
                        || (!this.pdf
                            && this.offer
                            && ['2', '5', '6', '7', '8'].indexOf(this.offer.status) > -1
                            && this.access === 'producent')),
            },
            attributes: conf.attributes
        } as any;
    }

    /**
     * Konstruktor danych pozycji dla widoku
     * @param  {object} pos    Pozycja
     * @param  {object} conf   Konfiguracja
     * @param  {array}  prices Tablica segmentów cen
     * @return {object}        Dane pozycji dla widoku
     */
    positionAdditionalDetails(pos, conf, access) {
        return {
            id: pos.tmp_id,
            index: pos.listNumber,
            type: pos.confType,
            standard: pos.standard,
            name: this.IccConfig.PDF.additionalPositionsLongName ? pos.details.name : pos.name,
            title: this.IccConfig.Offer.groupsOfPositions
                ? pos.custom_title
                    ? pos.custom_title
                    : pos.name
                : pos.name,
            customTitle: pos.custom_title,
            image: pos.image,
            description: pos.description,
            groupCode: pos.groupCode,
            quantity: pos.quantity,
            attachments: pos.attachments,
            modified: pos.modified,
            prices: {
                client: {
                    priceBeforeDiscount: Number(pos.client_price_before_discount),
                    price: Number(pos.client_price),
                    discount: Number(pos.client_discount),
                    base: core.roundPrice(pos.summary.client.components.base),
                    surcharges: core.roundPrice(
                        pos.summary.client.components.addons
                            + pos.summary.client.components.fitting
                            + pos.summary.client.components.glass
                            + pos.summary.client.components.roller
                            + pos.summary.client.components.colorCost
                            + pos.summary.client.components.mosquito
                    ),
                },
                dealer: {
                    priceBeforeDiscount: Number(pos.dealer_price_before_discount),
                    price: Number(pos.dealer_price),
                    discount: Number(pos.dealer_discount),
                    base: core.roundPrice(pos.summary.dealer.components.base),
                    surcharges: core.roundPrice(
                        pos.summary.dealer.components.addons
                            + pos.summary.dealer.components.fitting
                            + pos.summary.dealer.components.glass
                            + pos.summary.client.components.roller
                            + pos.summary.client.components.colorCost
                            + pos.summary.client.components.mosquito
                    ),
                },
                valuatedPrice: Number(pos.valuated_price),
                groupDiscounts: Common.isObject(pos.group_discounts)
                    ? pos.group_discounts
                    : core.parseJson(pos.group_discounts),
                show:
                    this.access
                    && ((pos.standard && (Number(pos.dealer_price) > 0 && this.showPrices))
                        || (!this.pdf
                            && ['2', '5', '6', '7', '8'].indexOf(this.offer.status) > -1
                            && this.access === 'producent')),
            },
            goodType: null,
            attributes: pos.details?.attributes,
            showDetails: pos.show_details ?? true,
        };
    }

    /**
     * Konstruktor danych pozycji dla widoku
     * @param  {object} details        Szczegóły pozycji
     * @param  {object} pos            Pozycja
     * @param  {object} conf           Konfiguracja
     * @param  {array}  prices         Tablica segmentów cen
     * @return {object}                Dane pozycji dla widoku
     */
    positionDetails({
        conf,
        drawData,
        details,
        prices,
        priceParts,
        translatedData,
        currency,
    }: {
        conf: WindowConfiguration | DoorConfiguration;
        drawData: any;
        details: any;
        prices;
        priceParts;
        translatedData;
        currency;
    }) {
        let profile: Profile | undefined;
        if (Common.isDefined(conf.system)) {
            details.system = conf.dictionary.systems[conf.system];
            details.system.name = TranslateService.translate(
                translatedData,
                details.system,
                'WindowLine',
                'name',
                this.langCode
            );
            details.system.offerInfo = TranslateService.translate(
                translatedData,
                details.system,
                'WindowLine',
                'offer_info',
                this.langCode,
                'id',
                'offerInfo'
            );
            details.system.price = priceFromSegments(prices, priceParts, currency, {
                percent: { type: 'system' },
            });
        }
        details.parameters = conf.parameters;
        details.attachments = conf.attachments;
        details.isMistakeProduct = conf.isMistakeProduct;
        details.hasWarranty = conf.hasWarranty;
        details.hasGlazingWarranty = conf.hasGlazingWarranty;
        details.doorSide = null;
        details.doorPortal = conf.doorPortal;
        if (conf.sashes.length && conf.type === 'door') {
            const doorSideWZ = conf.sashes.some(
                s => s.type && conf.dictionary.sashTypes[s.type.id].type === 'DOA'
            )
                ? 'Z'
                : 'W';
            const doorSideLR = conf.sashes.some(
                s =>
                    s.type
                    && (
                        conf.dictionary.sashTypes[s.type.id].type === 'DRA'
                            && conf.dictionary.sashTypes[s.type.id].handlePosition === 'R'
                        || conf.dictionary.sashTypes[s.type.id].type === 'DOA' && conf.dictionary.sashTypes[s.type.id].handlePosition === 'L'
                    )
            )
                ? 'L'
                : 'R';

            details.doorSide = doorSideWZ + '_' + doorSideLR;

            if (this.IccConfig.PDF.showDoorSide) {
                const sidedSashes = conf.sashes
                    .filter(sash => !['F', 'FF', 'K'].includes(sash.type && conf.dictionary.sashTypes[sash.type.id].type));

                if (sidedSashes.length <= 2) {
                    const sides = sidedSashes
                        .map(sash => {
                            let openingSideLR = conf.dictionary.sashTypes[sash.type.id].handlePosition;
                            if (details.doorSide == 'W') {
                                openingSideLR = conf.dictionary.sashTypes[sash.type.id].handlePosition === 'R' ? 'L' : 'R';
                            }
                            return `/img/door/${doorSideWZ}_${openingSideLR}.png`;
                        });
                    const passiveIndex = sidedSashes.findIndex(sash => ['DOP', 'DRP']
                        .includes(sash.type && conf.dictionary.sashTypes[sash.type.id].type));
                    if (sidedSashes.length === 2 && passiveIndex > -1) {
                        details.doorOpeningSide = [`/img/door/${doorSideWZ}_${passiveIndex}.png`];
                    } else {
                        details.doorOpeningSide = Array.from(new Set(sides));
                    }
                }
            }
        }

        details.ownedSashesTypes = conf.ownedSashesTypes;
        details.balcony = conf.balcony;
        details.rollerShutter = null;
        details.locks = null;
        details.handles = null;
        details.fillings = null;
        details.bondedGlazings = null;
        details.sashFrames = null;
        details.muntins = null;
        details.mosquitos = null;
        details.glazingBead = null;
        details.reinforcement = null;
        if (Common.isObject(conf.layout)) {
            details.layout = conf.layout;
            details.layout.name = TranslateService.translate(
                translatedData,
                details.layout,
                'SashesLayoutsVariant',
                'name',
                this.langCode
            );
        }
        details.sashesTypes = {
            type: conf.sashesType,
            price: priceFromSegments(prices, priceParts, currency, { value: { type: 'sashType' } }),
            sashes: [],
            elems: [],
        };
        prices
            .filter(e => e != null && e.type === 'sashType')
            .forEach(e => {
                details.sashesTypes.elems.push({
                    name: TranslateService.translate(
                        translatedData,
                        { id: e.data.suppId, name: e.data.name },
                        'SashTypesSupplement',
                        'name',
                        this.langCode
                    ),
                    price: e.value,
                });
            });
        details.mullions = {
            isDefault: true,
            static: [],
            movable: [],
            movableCentral: [],
            movableDouble: [],
            astragalFrame: [],
            intMullions: [],
            staticOne: true,
            movableOne: true,
            movableCentralOne: true,
            movableDoubleOne: true,
            astragalFrameOne: true,
            intMullionsOne: true,
            price:
                priceFromSegments(prices, priceParts, currency, { value: { type: 'dividers' } })
                + priceFromSegments(prices, priceParts, currency, { value: { type: 'intMullions' } }),
        };
        details.steel = !conf.steel
            ? null
            : {
                  type: conf.steel,
                  price: priceFromSegments(prices, priceParts, currency, { percent: { type: 'steel' } }),
              };
        details.wood = !conf.wood
            ? null
            : {
                  id: conf.wood,
                  name: TranslateService.translate(
                      translatedData,
                      conf.dictionary.woods[conf.wood],
                      'WoodType',
                      'name',
                      this.langCode
                  ),
                  price: priceFromSegments(prices, priceParts, currency, { percent: { type: 'wood' } }),
              };
        details.alushell = {
            has: conf.hasAlushell,
            type: conf.alushellType,
            price: priceFromSegments(prices, priceParts, currency, { value: { type: 'alushell' } }),
        };
        details.sashes = {
            has: conf.sashes.length ? true : false,
            price: priceFromSegments(prices, priceParts, currency, { value: { type: 'sashes' } }),
            sashes: [],
        };
        details.shape = {
            shape: conf.shape.shape,
            price: priceFromSegments(prices, priceParts, currency, { value: { type: 'shape' } }),
            sashes: [],
        };

        details.casing = conf.casing?.id ? details.casing = {
            id: conf.casing.id,
            name: conf.casing.name,
            innerWidth: conf.casing.innerWidth,
            wallOverlap: conf.casing.wallOverlap,
            hitchPoint: conf.casing.hitchPoint,
            price: priceFromSegments(prices, priceParts, currency, {
                value: { type: 'casing' },
            }),
        } : null;

        const frameWithLowThreshold = conf.frames.find(f => f.lowThreshold);
        const lowThresholdProfile =
            frameWithLowThreshold
            && conf.dictionary.profiles[frameWithLowThreshold.frame[0].profileId];
        details.lowThreshold = !frameWithLowThreshold
            ? false
            : {
                  id: frameWithLowThreshold.frame[0].id,
                  name: TranslateService.translate(
                      translatedData,
                      lowThresholdProfile,
                      'Profile',
                      'name',
                      this.langCode
                  ),
                  color: !conf.thresholdColor ? null :
                    {
                        id: conf.thresholdColor.id,
                        name: TranslateService.translate(
                            translatedData,
                            conf.dictionary.colors[getColorUid(conf.thresholdColor)],
                            'WindowColor',
                            'name',
                            this.langCode
                        ),
                        ral: conf.thresholdColor.RAL,
                        isDefault: conf.thresholdColor.isDefault,
                    },
                  price: priceFromSegments(prices, priceParts, currency, {
                      value: { type: 'lowThreshold' },
                  }),
              };
        details.sealColor = !conf.sealColor
            ? null
            : {
                  id: conf.sealColor.id,
                  name: TranslateService.translate(
                      translatedData,
                      conf.dictionary.sealColors[conf.sealColor.id],
                      'WindowSealColor',
                      'name',
                      this.langCode
                  ),
                  ral: conf.sealColor.RAL,
                  isDefault: conf.sealColor.isDefault,
                  price: priceFromSegments(prices, priceParts, currency, {
                      percent: { type: 'sealColor' },
                  }),
              };
        details.siliconeColor = !conf.siliconeColor
            ? null
            : {
                  id: conf.siliconeColor.id,
                  name: TranslateService.translate(
                      translatedData,
                      conf.dictionary.siliconeColors[conf.siliconeColor.id],
                      'WindowSiliconeColor',
                      'name',
                      this.langCode
                  ),
                  ral: conf.siliconeColor.RAL,
                  isDefault: conf.siliconeColor.isDefault,
                  price: priceFromSegments(prices, priceParts, currency, {
                      percent: { type: 'siliconeColor' },
                  }),
              };
        details.lippingColor = !conf.lippingColor
              ? null
              : {
                    id: conf.lippingColor.id,
                    name: TranslateService.translate(
                        translatedData,
                        conf.dictionary.colors[getColorUid(conf.lippingColor)],
                        'WindowColor',
                        'name',
                        this.langCode
                    ),
                    ral: conf.lippingColor.RAL,
                    isDefault: conf.lippingColor.isDefault,
                    price: priceFromSegments(prices, priceParts, currency, {
                        percent: { type: 'lippingColor' },
                    }),
                };
        details.innerLippingColor = !conf.innerLippingColor
            ? null
            : {
                  id: conf.innerLippingColor.id,
                  name: TranslateService.translate(
                      translatedData,
                      conf.dictionary.colors[getColorUid(conf.innerLippingColor)],
                      'WindowColor',
                      'name',
                      this.langCode
                  ),
                  ral: conf.innerLippingColor.RAL,
                  isDefault: conf.innerLippingColor.isDefault,
                  price: priceFromSegments(prices, priceParts, currency, {
                      percent: { type: 'innerLippingColor' },
                  }),
              };
        details.hinge = !conf.hinge?.id
        ? null
        : {
              id: conf.hinge.id,
              name: TranslateService.translate(
                  translatedData,
                  conf.dictionary.hinges[conf.hinge.id],
                  'WindowAccessory',
                  'name',
                  this.langCode
              ),
              priceSource: conf.dictionary.hinges[conf.hinge.id].priceSource,
              price: priceFromSegments(prices, priceParts, currency, { value: { type: 'hinge' } }),
              color: conf.hinge?.color?.id
                  ? {
                        id: conf.hinge.color.id,
                        name: TranslateService.translate(
                            translatedData,
                            conf.dictionary.accessoryColors[conf.hinge.color.id],
                            'WindowHandlesColor',
                            'name',
                            this.langCode
                        ),
                        ral: conf.hinge.color.RAL,
                        isDefault: conf.hinge.color.isDefault,
                    }
                  : null,
              };
        details.lock = !conf.lock
            ? null
            : {
                  id: conf.lock,
                  name: TranslateService.translate(
                      translatedData,
                      conf.dictionary.locks[conf.lock],
                      'WindowAccessory',
                      'name',
                      this.langCode
                  ),
                  price: priceFromSegments(prices, priceParts, currency, { value: { type: 'lock' } }),
              };
        details.glazingSpacer = !conf.glazingSpacer
            ? null
            : {
                  id: conf.glazingSpacer,
                  name: TranslateService.translate(
                      translatedData,
                      conf.dictionary.glazingSpacers[conf.glazingSpacer],
                      'WindowWarmEdge',
                      'name',
                      this.langCode
                  ),
                  price: priceFromSegments(prices, priceParts, currency, {
                      value: { type: 'warmEdge' },
                  }),
              };
        details.fitting = !conf.fitting
            ? null
            : {
                  id: conf.fitting,
                  name: TranslateService.translate(
                      translatedData,
                      conf.dictionary.fittings[conf.fitting],
                      'WindowFitting',
                      'name',
                      this.langCode
                  ),
                  price: priceFromSegments(prices, priceParts, currency, {
                      value: { type: 'fittings' },
                  }),
              };
        details.profileSet = {
            isDefault: false,
        };
        if (conf.profileSet) {
            details.profileSet.isDefault = conf.profileSet.isDefault;
        }
        details.frames = {
            has: conf.frames.length ? true : false,
            price: priceFromSegments(prices, priceParts, currency, { value: { type: 'frame' } }),
            renoPrice: priceFromSegments(prices, priceParts, currency, {
                value: { type: 'renoFrame' },
            }),
            isDefault: true,
            hasOne: true,
            frames: [],
        };
        for (const frame of conf.frames) {
            if (frame.frame) {
                let frameDetails = null;
                for (let i = frame.frame.length - 1; i >= 0; i--) {
                    const el = frame.frame[i];
                    profile = {
                        ...conf.dictionary.profiles[el.profileId],
                        name: TranslateService.translate(
                            translatedData,
                            conf.dictionary.profiles[el.profileId],
                            'Profile',
                            'name',
                            this.langCode
                        ),
                    };
                    if (frameDetails == null) {
                        frameDetails = {
                            id: frame.id,
                            profileId: el.profileId,
                            isDefault: el.isDefault,
                            frameIndex: core.indexToLetter(frame.index),
                            parameters: frame.parameters,
                            hasOneFinWidth: el.finWidth || 0,
                            hasOne: true,
                            name: profile.name,
                            price: priceFromSegments(prices, priceParts, currency, {
                                value: { type: 'frame', data: { frameId: frame.id } },
                            }),
                            renoPrice: priceFromSegments(prices, priceParts, currency, {
                                value: { type: 'renoFrame', data: { frameId: frame.id } },
                            }),
                            monoblockDepth: profile.monoblockDepth ? profile.monoblockDepth : 0,
                            profiles: [],
                            weldFinishType: conf.weldFinishType,
                        };
                    }
                    frameDetails.profiles.push({
                        id: el.profileId,
                        isDefault: el.isDefault,
                        name: profile.name,
                        monoblockDepth: profile.monoblockDepth ? profile.monoblockDepth : 0,
                        finWidth: el.finWidth,
                        side: el.side,
                        weldFinishType: conf.weldFinishType,
                    });

                    if (el.finWidth !== frameDetails.hasOneFinWidth) {
                        frameDetails.hasOneFinWidth = false;
                    }

                    details.frames.isDefault = details.frames && el.isDefault;

                    frameDetails.hasOne =
                        frameDetails.hasOne
                        && (frameDetails.profileId === el.profileId
                            || profile.type === 'threshold');
                }
                if (frameDetails) {
                    details.frames.hasOne = details.frames.hasOne && frameDetails.hasOne;
                    details.frames.frames.push(frameDetails);
                }
            }
        }

        details.couplings = {
            isDefault: true,
            couplings: [],
            price: priceFromSegments(prices, priceParts, currency, { value: { type: 'couplings' } }),
        };

        if (conf.couplings.length) {
            let topLeft;
            let bottomRight;
            let reinforcement;

            for (const coupling of conf.couplings) {
                topLeft = coupling.framesId.map(f => f.id)[0];
                bottomRight = coupling.otherFramesId.map(f => f.id)[0];
                topLeft = core.indexToLetter(
                    conf.frames[core.fId(conf.frames, topLeft, 'id')].index
                );
                bottomRight = core.indexToLetter(
                    conf.frames[core.fId(conf.frames, bottomRight, 'id')].index
                );

                profile = {
                    ...conf.dictionary.profiles[coupling.profileId],
                    name: TranslateService.translate(
                        translatedData,
                        conf.dictionary.profiles[coupling.profileId],
                        'Profile',
                        'name',
                        this.langCode
                    ),
                };

                reinforcement = coupling.reinforcement;
                if (reinforcement && reinforcement.id !== null) {
                    reinforcement.name = TranslateService.translate(
                        translatedData,
                        reinforcement,
                        'Reinforcement',
                        'name',
                        this.langCode
                    );
                }

                const couplingDetails = {
                    id: coupling.id,
                    profileId: coupling.profileId,
                    type: profile ? profile.type : null,
                    name: profile ? profile.name : '',
                    outerWidth: coupling.widthOut,
                    innerWidth: coupling.width,
                    length: coupling.length,
                    direction: coupling.direction,
                    price: priceFromSegments(prices, priceParts, currency, {
                        value: { type: 'couplings', data: { couplingId: coupling.id } },
                    }),
                    topLeft,
                    bottomRight,
                    reinforcement,
                };
                details.couplings.couplings.push(couplingDetails);
            }
        }

        details.alignments = {
            alignments: [],
            price: priceFromSegments(prices, priceParts, currency, { value: { type: 'alignment' } }),
            show: false,
        };

        if (conf.alignments && conf.alignments.length > 0) {
            details.alignments.show = true;

            conf.alignments.map(alignment => {
                const sashes = [];

                ['top', 'left', 'right', 'bottom'].map(side => {
                    alignment.adjacentSashes[side]
                        .map(id => core.fIdO(conf.sashes, id))
                        .filter(sash => sash)
                        .forEach(sash => sashes.push(sash));
                });

                details.alignments.alignments.push({
                    id: alignment.id,
                    name: TranslateService.translate(
                        translatedData,
                        conf.dictionary.profiles[alignment.profileId],
                        'Profile',
                        'name',
                        this.langCode
                    ),
                    price: priceFromSegments(prices, priceParts, currency, {
                        value: { type: 'alignment', data: { id: alignment.id } },
                    }),
                    sash: sashes.length ? sashes[0].index : undefined,
                    side: alignment.side,
                    type: 'frame',
                });
            });
        }

        conf.sashes.map(sash => {
            if (sash.intAlignments && sash.intAlignments.length > 0) {
                details.alignments.show = true;

                sash.intAlignments.map(alignment => {
                    details.alignments.alignments.push({
                        id: alignment.id,
                        name: TranslateService.translate(
                            translatedData,
                            conf.dictionary.profiles[alignment.profileId],
                            'Profile',
                            'name',
                            this.langCode
                        ),
                        price: priceFromSegments(prices, priceParts, currency, {
                            value: { type: 'alignment', data: { id: alignment.id } },
                        }),
                        sash: sash.index,
                        side: alignment.side,
                        type: 'sashFrame',
                    });
                });
            }
        });

        details.extensions = {
            extensions: [],
            price: priceFromSegments(prices, priceParts, currency, { value: { type: 'sideProfile' } }),
            show: false,
        };

        if (conf.sideProfiles && conf.sideProfiles.length) {
            details.extensions.show = true;

            conf.sideProfiles.map(extension => {
                const extensionProfile = conf.dictionary.profiles[extension.profileId];
                const reinforcement =
                    conf.dictionary.reinforcements[extension.reinforcement] || null;
                details.extensions.extensions.push({
                    id: extension.id,
                    count: extension.count,
                    code: extensionProfile ? extensionProfile.code : null,
                    name: extensionProfile
                        ? TranslateService.translate(
                              translatedData,
                              extensionProfile,
                              'Profile',
                              'name',
                              this.langCode
                          )
                        : '',
                    type: extensionProfile ? extensionProfile.type : null,
                    reinforcement: reinforcement
                        ? {
                              id: reinforcement.id,
                              name: TranslateService.translate(
                                  translatedData,
                                  reinforcement,
                                  'Reinforcement',
                                  'name',
                                  this.langCode
                              ),
                          }
                        : null,
                    width: extension.dimensions.width,
                    height: extension.dimensions.height,
                    price: priceFromSegments(prices, priceParts, currency, {
                        value: {
                            type: 'sideProfile',
                            data: { id: extension.id, profileId: extension.profileId },
                        },
                    }),
                    side: extension.side,
                    color: {
                        core:
                            extension.color && extension.color.core && extension.color.core.id
                                ? {
                                      id: extension.color.core.id,
                                      RAL: extension.color.core.RAL,
                                      code: extension.color.core.code,
                                      type: extension.color.core.type,
                                      name: TranslateService.translate(
                                          translatedData,
                                          extension.color.core,
                                          'WindowColor',
                                          'name',
                                          this.langCode
                                      ),
                                  }
                                : null,
                        inner:
                            extension.color && extension.color.inner && extension.color.inner.id
                                ? {
                                      id: extension.color.inner.id,
                                      RAL: extension.color.inner.RAL,
                                      code: extension.color.inner.code,
                                      name: TranslateService.translate(
                                          translatedData,
                                          extension.color.inner,
                                          'WindowColor',
                                          'name',
                                          this.langCode
                                      ),
                                  }
                                : null,
                        outer:
                            extension.color && extension.color.outer && extension.color.outer.id
                                ? {
                                      id: extension.color.outer.id,
                                      RAL: extension.color.outer.RAL,
                                      code: extension.color.outer.code,
                                      name: TranslateService.translate(
                                          translatedData,
                                          extension.color.outer,
                                          'WindowColor',
                                          'name',
                                          this.langCode
                                      ),
                                  }
                                : null,
                    },
                });
            });
        }

        let colorHeaderType = '';
        let colorHeaderName = '';
        if (!conf.colorsSashExt) {
            if (!conf.colors.frame.outer?.id
                && !conf.colors.frame.inner?.id
                && ['white', 'cream'].includes(conf.dictionary.colors[getColorUid(conf.colors.frame.core)]?.type)
            ) {
                colorHeaderType = this.translateFilter('COLOR|Kolor');
                colorHeaderName = TranslateService.translate(
                    translatedData,
                    conf.dictionary.colors[getColorUid(conf.colors.frame.core)],
                    'WindowColor',
                    'name',
                    this.langCode
                );
            } else if (
                conf.colors.frame.outer?.id
                && !conf.colors.frame.inner?.id
            ) {
                colorHeaderType = this.translateFilter('COLOR|Kolor jednostronny zewnętrzny');
                colorHeaderName = TranslateService.translate(
                    translatedData,
                    conf.dictionary.colors[getColorUid(conf.colors.frame.outer)],
                    'WindowColor',
                    'name',
                    this.langCode
                );
            } else if (
                !conf.colors.frame.outer?.id
                && conf.colors.frame.inner?.id
            ) {
                colorHeaderType = this.translateFilter('COLOR|Kolor jednostronny wewnętrzny');
                colorHeaderName = TranslateService.translate(
                    translatedData,
                    conf.dictionary.colors[getColorUid(conf.colors.frame.inner)],
                    'WindowColor',
                    'name',
                    this.langCode
                );
            } else if (conf.colors.frame.outer?.id === conf.colors.frame.inner?.id) {
                colorHeaderType = this.translateFilter('COLOR|Kolor obustronny');
                colorHeaderName = TranslateService.translate(
                    translatedData,
                    conf.dictionary.colors[getColorUid(conf.colors.frame.outer)],
                    'WindowColor',
                    'name',
                    this.langCode
                );
            }
        }
        if (conf.colorsSashExt ||
            conf.colors.frame.outer?.id
            && conf.colors.frame.inner?.id
            && conf.colors.frame.outer?.id !== conf.colors.frame.inner?.id
        ) {
            colorHeaderType = this.translateFilter('COLOR|Kolor');
            colorHeaderName = this.translateFilter('COLOR|różne');
        }
        const colorHeader = `${colorHeaderType}: ${colorHeaderName}`;
        details.colors = {
            header: colorHeader,
            sashExt: conf.colorsSashExt,
            frame: !conf.colors.frame
                ? null
                : {
                      alushell: !conf.colors.frame.alushell
                          ? null
                          : {
                                RAL: conf.colors.frame.alushell.RAL,
                                code:
                                    conf.dictionary.colors[getColorUid(conf.colors.frame.alushell)]
                                        .code,
                                isDefault: conf.colors.frame.alushell.isDefault,
                                id: conf.colors.frame.alushell.id,
                                name: TranslateService.translate(
                                    translatedData,
                                    conf.dictionary.colors[getColorUid(conf.colors.frame.alushell)],
                                    'WindowColor',
                                    'name',
                                    this.langCode
                                ),
                            },
                      core: !conf.colors.frame.core
                          ? null
                          : {
                                RAL: conf.colors.frame.core.RAL,
                                code:
                                    conf.dictionary.colors[getColorUid(conf.colors.frame.core)]
                                        .code,
                                isDefault: conf.colors.frame.core.isDefault,
                                id: conf.colors.frame.core.id,
                                name: TranslateService.translate(
                                    translatedData,
                                    conf.dictionary.colors[getColorUid(conf.colors.frame.core)],
                                    'WindowColor',
                                    'name',
                                    this.langCode
                                ),
                            },
                      inner: !conf.colors.frame.inner
                          ? null
                          : {
                                RAL: conf.colors.frame.inner.RAL,
                                code:
                                    conf.dictionary.colors[getColorUid(conf.colors.frame.inner)]
                                        .code,
                                isDefault: conf.colors.frame.inner.isDefault,
                                id: conf.colors.frame.inner.id,
                                name: TranslateService.translate(
                                    translatedData,
                                    conf.dictionary.colors[getColorUid(conf.colors.frame.inner)],
                                    'WindowColor',
                                    'name',
                                    this.langCode
                                ),
                            },
                      outer: !conf.colors.frame.outer
                          ? null
                          : {
                                RAL: conf.colors.frame.outer.RAL,
                                code:
                                    conf.dictionary.colors[getColorUid(conf.colors.frame.outer)]
                                        .code,
                                isDefault: conf.colors.frame.outer.isDefault,
                                id: conf.colors.frame.outer.id,
                                name: TranslateService.translate(
                                    translatedData,
                                    conf.dictionary.colors[getColorUid(conf.colors.frame.outer)],
                                    'WindowColor',
                                    'name',
                                    this.langCode
                                ),
                            },
                  },
            sash: !conf.colors.sash
                ? null
                : {
                      alushell: !conf.colors.sash.alushell
                          ? null
                          : {
                                RAL: conf.colors.sash.alushell.RAL,
                                code:
                                    conf.dictionary.colors[getColorUid(conf.colors.sash.alushell)]
                                        .code,
                                isDefault: conf.colors.sash.alushell.isDefault,
                                id: conf.colors.sash.alushell.id,
                                name: TranslateService.translate(
                                    translatedData,
                                    conf.dictionary.colors[getColorUid(conf.colors.sash.alushell)],
                                    'WindowColor',
                                    'name',
                                    this.langCode
                                ),
                            },
                      core: !conf.colors.sash.core
                          ? null
                          : {
                                RAL: conf.colors.sash.core.RAL,
                                code:
                                    conf.dictionary.colors[getColorUid(conf.colors.sash.core)].code,
                                isDefault: conf.colors.sash.core.isDefault,
                                id: conf.colors.sash.core.id,
                                name: TranslateService.translate(
                                    translatedData,
                                    conf.dictionary.colors[getColorUid(conf.colors.sash.core)],
                                    'WindowColor',
                                    'name',
                                    this.langCode
                                ),
                            },
                      inner: !conf.colors.sash.inner
                          ? null
                          : {
                                RAL: conf.colors.sash.inner.RAL,
                                code:
                                    conf.dictionary.colors[getColorUid(conf.colors.sash.inner)]
                                        .code,
                                isDefault: conf.colors.sash.inner.isDefault,
                                id: conf.colors.sash.inner.id,
                                name: TranslateService.translate(
                                    translatedData,
                                    conf.dictionary.colors[getColorUid(conf.colors.sash.inner)],
                                    'WindowColor',
                                    'name',
                                    this.langCode
                                ),
                            },
                      outer: !conf.colors.sash.outer
                          ? null
                          : {
                                RAL: conf.colors.sash.outer.RAL,
                                code:
                                    conf.dictionary.colors[getColorUid(conf.colors.sash.outer)]
                                        .code,
                                isDefault: conf.colors.sash.outer.isDefault,
                                id: conf.colors.sash.outer.id,
                                name: TranslateService.translate(
                                    translatedData,
                                    conf.dictionary.colors[getColorUid(conf.colors.sash.outer)],
                                    'WindowColor',
                                    'name',
                                    this.langCode
                                ),
                            },
                  },
            price:
                priceFromSegments(prices, priceParts, currency, { percent: { type: 'color' } })
                + priceFromSegments(prices, priceParts, currency, {
                    percent: { type: 'colorAlushell' },
                }),
        };

        if (DoorConfiguration.is(conf)) {
            if (Common.isDefined(conf.model) && conf.model !== null) {
                details.model = Common.extend(core.copy(conf.model), {
                    name: TranslateService.translate(
                        translatedData,
                        conf.model,
                        'DoorModel',
                        'name',
                        this.langCode
                    ),
                });
            }
            if (Common.isDefined(conf.modelGroup) && conf.modelGroup !== null) {
                details.modelGroup = Common.extend(core.copy(conf.modelGroup), {
                    name: TranslateService.translate(
                        translatedData,
                        conf.modelGroup,
                        'DoorModelsGroup',
                        'name',
                        this.langCode
                    ),
                });
            }

            details.doorSizes = {
                sash: conf.doorSizes.sashSizeId ? {
                    id: conf.doorSizes.sashSizeId,
                    name: TranslateService.translate(
                        translatedData,
                        conf.dictionary.doorSizes[conf.doorSizes.sashSizeId],
                        'DoorSize',
                        'name',
                        this.langCode
                    ),
                } : null,
                passiveSash: conf.sashes.some(sash => sash.type.passive) ? {
                    id: conf.doorSizes.passiveSashSizeId,
                    name: TranslateService.translate(
                        translatedData,
                        conf.dictionary.doorSizes[conf.doorSizes.passiveSashSizeId],
                        'DoorSize',
                        'name',
                        this.langCode
                    ),
                } : null,
                topLight: conf.doorSizes.topLightSize,
                leftLight: conf.doorSizes.leftLightSize,
                rightLight: conf.doorSizes.rightLightSize,
                shortening: conf.doorSizes.shortening,
                frameShortening: conf.doorSizes.frameShortening,
                customSize: conf.doorSizes.customSize,
                customWidth: conf.doorSizes.customWidth,
                customHeight: conf.doorSizes.customHeight,
            };

            details.doorHardware = {
                doorHandleAdjustment: !conf.doorHardware?.doorHandleAdjustment
                    ? null
                    : {
                          id: conf.doorHardware?.doorHandleAdjustment,
                          name: TranslateService.translate(
                              translatedData,
                              conf.dictionary.doorHardware[conf.doorHardware?.doorHandleAdjustment],
                              'WindowAccessory',
                              'name',
                              this.langCode
                          ),
                          price: priceFromSegments(prices, priceParts, currency, {
                              value: { type: 'doorHandleAdjustment' },
                          }),
                      },
                type: !conf.doorHardware?.type
                    ? null
                    : {
                          id: conf.doorHardware?.type,
                          name: TranslateService.translate(
                              translatedData,
                              conf.dictionary.doorHardware[conf.doorHardware?.type],
                              'WindowAccessory',
                              'name',
                              this.langCode
                          ),
                          price: priceFromSegments(prices, priceParts, currency, {
                              value: { type: 'hardwareType' },
                          }),
                      },
                set: conf.doorHardware?.set,
                pull: !conf.doorHardware?.pull
                    ? null
                    : {
                          id: conf.doorHardware?.pull,
                          name: TranslateService.translate(
                              translatedData,
                              conf.dictionary.doorHardware[conf.doorHardware?.pull],
                              'WindowAccessory',
                              'name',
                              this.langCode
                          ),
                          price: priceFromSegments(prices, priceParts, currency, {
                              value: { type: 'pull' },
                          }),
                          color: !conf.doorHardware?.pullColor
                          ? null
                          : {
                                id: conf.doorHardware?.pullColor,
                                name: TranslateService.translate(
                                    translatedData,
                                    conf.dictionary.accessoryColors[conf.doorHardware?.pullColor],
                                    'WindowHandlesColor',
                                    'name',
                                    this.langCode
                                ),
                            },
                      },
                electronicLock: !conf.doorHardware?.electronicLock
                    ? null
                    : {
                          id: conf.doorHardware?.electronicLock,
                          name: TranslateService.translate(
                              translatedData,
                              conf.dictionary.doorHardware[conf.doorHardware?.electronicLock],
                              'WindowAccessory',
                              'name',
                              this.langCode
                          ),
                          price: priceFromSegments(prices, priceParts, currency, {
                              value: { type: 'electronicLock' },
                          }),
                      },
                color: !conf.doorHardware?.color
                    ? null
                    : {
                          id: conf.doorHardware?.color,
                          name: TranslateService.translate(
                              translatedData,
                              conf.dictionary.accessoryColors[conf.doorHardware?.color],
                              'WindowHandlesColor',
                              'name',
                              this.langCode
                          ),
                      },
                plateType: !conf.doorHardware?.plateType
                    ? null
                    : {
                          id: conf.doorHardware?.plateType,
                          name: TranslateService.translate(
                              translatedData,
                              conf.dictionary.doorHardware[conf.doorHardware?.plateType],
                              'WindowAccessory',
                              'name',
                              this.langCode
                          ),
                          price: priceFromSegments(prices, priceParts, currency, {
                              value: { type: 'plateType' },
                          }),
                      },
                doorViewer: !conf.doorHardware?.doorViewer
                    ? null
                    : {
                          id: conf.doorHardware?.doorViewer,
                          name: TranslateService.translate(
                              translatedData,
                              conf.dictionary.doorHardware[conf.doorHardware?.doorViewer],
                              'WindowAccessory',
                              'name',
                              this.langCode
                          ),
                          price: priceFromSegments(prices, priceParts, currency, {
                              value: { type: 'doorViewer' },
                          }),
                      },
                doorViewerPunching: conf.doorHardware?.doorViewerPunching,
                plates: {
                    mainLock: !conf.doorHardware?.plates.mainLock
                        ? null
                        : {
                              id: conf.doorHardware?.plates.mainLock,
                              name: TranslateService.translate(
                                  translatedData,
                                  conf.dictionary.doorHardware[conf.doorHardware?.plates.mainLock],
                                  'WindowAccessory',
                                  'name',
                                  this.langCode
                              ),
                              price: priceFromSegments(prices, priceParts, currency, {
                                  value: { type: 'mainLockPlate' },
                              }),
                          },
                    additionalLock: !conf.doorHardware?.plates.additionalLock
                        ? null
                        : {
                              id: conf.doorHardware?.plates.additionalLock,
                              name: TranslateService.translate(
                                  translatedData,
                                  conf.dictionary.doorHardware[
                                      conf.doorHardware?.plates.additionalLock
                                  ],
                                  'WindowAccessory',
                                  'name',
                                  this.langCode
                              ),
                              price: priceFromSegments(prices, priceParts, currency, {
                                  value: { type: 'additionalLockPlate' },
                              }),
                          },
                    color: !conf.doorHardware?.plates.color
                        ? null
                        : {
                              id: conf.doorHardware?.plates.color,
                              name: TranslateService.translate(
                                  translatedData,
                                  conf.dictionary.accessoryColors[conf.doorHardware?.plates.color],
                                  'WindowHandlesColor',
                                  'name',
                                  this.langCode
                              ),
                          },
                },
                cylinders: {
                    mainLock: !conf.doorHardware?.cylinders.mainLock
                        ? null
                        : {
                              id: conf.doorHardware?.cylinders.mainLock,
                              name: TranslateService.translate(
                                  translatedData,
                                  conf.dictionary.doorHardware[
                                      conf.doorHardware?.cylinders.mainLock
                                  ],
                                  'WindowAccessory',
                                  'name',
                                  this.langCode
                              ),
                              price: priceFromSegments(prices, priceParts, currency, {
                                  value: { type: 'mainLockCylinder' },
                              }),
                              isKeyAvailableForMainLock: conf.doorHardware?.cylinders.isKeyAvailableForMainLock,
                              keyQuantityForMainLock: conf.doorHardware?.cylinders.keyQuantityForMainLock,
                              color: !conf.doorHardware?.cylinders?.color
                                  ? null
                                  : {
                                        id: conf.doorHardware?.cylinders.color,
                                        name: TranslateService.translate(
                                            translatedData,
                                            conf.dictionary.accessoryColors[
                                                conf.doorHardware?.cylinders.color
                                            ],
                                            'WindowAccessory',
                                            'name',
                                            this.langCode
                                        ),
                                    },
                          },
                    additionalLock: !conf.doorHardware?.cylinders.additionalLock
                        ? null
                        : {
                              id: conf.doorHardware?.cylinders.additionalLock,
                              name: TranslateService.translate(
                                  translatedData,
                                  conf.dictionary.doorHardware[
                                      conf.doorHardware?.cylinders.additionalLock
                                  ],
                                  'WindowAccessory',
                                  'name',
                                  this.langCode
                              ),
                              price: priceFromSegments(prices, priceParts, currency, {
                                  value: { type: 'additionalLockCylinder' },
                              }),
                              isKeyAvailableForAdditionalLock: conf.doorHardware.cylinders.isKeyAvailableForAdditionalLock,
                              keyQuantityForAdditionalLock: conf.doorHardware.cylinders.keyQuantityForAdditionalLock,
                              color: !conf.doorHardware?.cylinders?.color
                                  ? null
                                  : {
                                        id: conf.doorHardware?.cylinders.color,
                                        name: TranslateService.translate(
                                            translatedData,
                                            conf.dictionary.accessoryColors[
                                                conf.doorHardware?.cylinders.color
                                            ],
                                            'WindowAccessory',
                                            'name',
                                            this.langCode
                                        ),
                                    },
                          },
                    system: !conf.doorHardware?.cylinders.system
                        ? null
                        : {
                              id: conf.doorHardware?.cylinders.system,
                              name: TranslateService.translate(
                                  translatedData,
                                  conf.dictionary.hardwareSystems[
                                      conf.doorHardware?.cylinders.system
                                  ],
                                  'WindowAccessory',
                                  'name',
                                  this.langCode
                              ),
                          },
                },
                decorativeHingeCovers: !conf.doorHardware?.decorativeHingeCoversColor
                    ? null
                    : {
                          id: conf.doorHardware?.decorativeHingeCoversColor,
                          name: TranslateService.translate(
                              translatedData,
                              conf.dictionary.accessoryColors[
                                  conf.doorHardware?.decorativeHingeCoversColor
                              ],
                              'WindowHandlesColor',
                              'name',
                              this.langCode
                          ),
                      },
            };
        }

        return details;
    }

    /**
     * Funkcja zwracająca dane z pól
     * @param  {object} conf      Konfiguracja pozycji
     * @param  {object} sash      Skrzydło
     * @param  {array}  intSashes Pola
     * @param  {array}  prices    Tablica segmentów cen
     * @return {object}           Dane z pól
     */
    positionDetailsIntSashes(
        conf: WindowConfiguration | DoorConfiguration,
        sash,
        intSashes,
        prices,
        priceParts,
        translatedData,
        currency,
    ) {
        const fillingIntSashes = [];
        const bondedGlazingIntSashes = [];
        const glazingBeadIntSashes = [];
        const muntinsIntSashes = [];
        let muntinsIntSash;
        for (let i = 0; i < intSashes.length; i++) {
            const sashFilling = conf.dictionary.fillings[intSashes[i].filling.id];
            fillingIntSashes.push({
                id: sashFilling.id,
                sashId: sash.id,
                sashIndex: sash.index,
                sashType: sash.type,
                isashId: intSashes[i].id,
                isashIndex: intSashes[i].index,
                type: sashFilling.type,
                u: sashFilling.u,
                rw: sashFilling.rw,
                c: sashFilling.c,
                ctr: sashFilling.ctr,
                lt: sashFilling.lt,
                g: sashFilling.g,
                psi: sashFilling.psi,
                weight: sashFilling.weight,
                name: TranslateService.translate(
                    translatedData,
                    sashFilling,
                    'Filling',
                    'name',
                    this.langCode
                ),
                producer: sashFilling.producer,
                hasOne: true,
                color: !intSashes[i].filling.color
                    ? null
                    : Common.extend(core.copy(intSashes[i].filling.color), {
                          inner: {
                              id: intSashes[i].filling.color.inner.id,
                              name: TranslateService.translate(
                                  translatedData,
                                  conf.dictionary.colors[getColorUid(intSashes[i].filling.color.inner)],
                                  'WindowColor',
                                  'name',
                                  this.langCode
                              ),
                          },
                          outer: {
                              id: intSashes[i].filling.color.outer.id,
                              name: TranslateService.translate(
                                  translatedData,
                                  conf.dictionary.colors[getColorUid(intSashes[i].filling.color.outer)],
                                  'WindowColor',
                                  'name',
                                  this.langCode
                              ),
                          },
                          core: {
                              id: intSashes[i].filling.color.core.id,
                              name: TranslateService.translate(
                                  translatedData,
                                  conf.dictionary.colors[getColorUid(intSashes[i].filling.color.core)],
                                  'WindowColor',
                                  'name',
                                  this.langCode
                              ),
                          },
                      }),
                overlayColor: intSashes[i].filling.overlayColor,
                panelType: sash.panelType,
                panelThickness: sashFilling.thickness_mm || null,
                price:
                    priceFromSegments(prices, priceParts, currency, {
                        value: {
                            type: 'filling',
                            data: { sashId: sash.id, isashId: intSashes[i].id },
                        },
                    })
                    + priceFromSegments(prices, priceParts, currency, {
                        value: {
                            type: 'fillingShape',
                            data: { sashId: sash.id, isashId: intSashes[i].id },
                        },
                    }),
                priceGlazingSpacer: priceFromSegments(prices, priceParts, currency, {
                    value: {
                        type: 'warmEdge',
                        data: { sashId: sash.id, isashId: intSashes[i].id },
                    },
                }),
                pricePanel: {
                    panel: priceFromSegments(prices, priceParts, currency, {
                        value: {
                            type: 'panelPrice',
                            data: { sashId: sash.id, isashId: intSashes[i].id },
                        },
                    }),
                    types: priceFromSegments(prices, priceParts, currency, {
                        value: {
                            type: 'panelTypes',
                            data: { sashId: sash.id, isashId: intSashes[i].id },
                        },
                    }),
                    sizes: priceFromSegments(prices, priceParts, currency, {
                        value: {
                            type: 'panelSizes',
                            data: { sashId: sash.id, isashId: intSashes[i].id },
                        },
                    }),
                    glazing: priceFromSegments(prices, priceParts, currency, {
                        value: {
                            type: 'glazingPrice',
                            data: { sashId: sash.id, isashId: intSashes[i].id },
                        },
                    }),
                    all:
                        priceFromSegments(prices, priceParts, currency, {
                            value: {
                                type: 'panelTypes',
                                data: { sashId: sash.id, isashId: intSashes[i].id },
                            },
                        })
                        + priceFromSegments(prices, priceParts, currency, {
                            value: {
                                type: 'panelSizes',
                                data: { sashId: sash.id, isashId: intSashes[i].id },
                            },
                        })
                        + priceFromSegments(prices, priceParts, currency, {
                            value: {
                                type: 'panelPrice',
                                data: { sashId: sash.id, isashId: intSashes[i].id },
                            },
                        })
                        + priceFromSegments(prices, priceParts, currency, {
                            value: {
                                type: 'glazingPrice',
                                data: { sashId: sash.id, isashId: intSashes[i].id },
                            },
                        }),
                },
                intSashes: [],
                fillingSizes: intSashes[i].fillingSizes,
                panelGlazing: !intSashes[i].panelGlazing?.id
                    ? null
                    : {
                          id: intSashes[i].panelGlazing.id,
                          name: TranslateService.translate(
                              translatedData,
                              intSashes[i].panelGlazing,
                              'Filling',
                              'name',
                              this.langCode
                          ),
                          type: intSashes[i].panelGlazing.type,
                          producer: intSashes[i].panelGlazing.producer,
                      },
            });
            const glazingBead = conf.dictionary.profiles[intSashes[i].glazingBead.profileId];
            if (glazingBead && intSashes[i].panelType !== 'Inner' && intSashes[i].panelType !== 'Double') {
                let shape: false | ProfileShapeV2 = false;
                if (Common.isDefined(glazingBead.profileShapeId)) {
                    shape = conf.dictionary.profileShapes[glazingBead.profileShapeId];
                }
                glazingBeadIntSashes.push({
                    id: glazingBead.id,
                    sashId: sash.id,
                    sashIndex: sash.index,
                    isashId: intSashes[i].id,
                    isashIndex: intSashes[i].index,
                    name: TranslateService.translate(
                        translatedData,
                        glazingBead,
                        'Profile',
                        'name',
                        this.langCode
                    ),
                    shape: shape
                        ? TranslateService.translate(
                              translatedData,
                              shape,
                              'ProfileShape',
                              'name',
                              this.langCode
                          )
                        : false,
                    price: priceFromSegments(prices, priceParts, currency, {
                        value: {
                            type: 'glazingBead',
                            data: { sashId: sash.id, isashId: intSashes[i].id },
                        },
                    }),
                });
            }

            bondedGlazingIntSashes.push({
                has: intSashes[i].bondedGlazing,
                sashId: sash.id,
                sashIndex: sash.index,
                isashId: intSashes[i].id,
                isashIndex: intSashes[i].index,
            });

            if (conf.muntins && intSashes[i].muntins && intSashes[i].muntins.length) {
                muntinsIntSash = {
                    sashId: sash.id,
                    sashIndex: sash.index,
                    isashId: intSashes[i].id,
                    isashIndex: intSashes[i].index,
                    horizontal: [],
                    vertical: [],
                };

                muntinsIntSash.horizontal = core.copy(
                    intSashes[i].muntins.filter(muntin => muntin.start.y === muntin.end.y)
                );
                muntinsIntSash.vertical = core.copy(
                    intSashes[i].muntins.filter(muntin => muntin.start.x === muntin.end.x)
                );

                muntinsIntSashes.push(muntinsIntSash);
            }
        }

        return { fillingIntSashes, muntinsIntSashes, glazingBeadIntSashes, bondedGlazingIntSashes };
    }

    /**
     * Funkcja zwracające dane z kwater
     * @param  {object} conf    Konfiguracja pozycji
     * @param  {object} details Dane do widoku
     * @param  {array}  prices  Tablica segmentów cen
     * @return {array}          Dane do widoku
     */
    positionDetailsSashes({
        conf,
        details,
        prices,
        priceParts,
        translatedData,
        currency
    }: {
        conf: WindowConfiguration | DoorConfiguration;
        drawData: any;
        details: any;
        prices;
        priceParts;
        translatedData;
        currency;
    }) {
        const sashes = conf.sashes.sort((a, b) => a.index - b.index);
        let glazingCount;
        let intSashes: SashV2[];
        let div;
        let intSashesData;
        let fillingSash;
        let bondedGlazingsSash;
        let muntinsSash;
        let mosquitoSash;
        let glazingBeadSash;
        let sashAccessories;
        let topLeft;
        let bottomRight;
        let type;
        let profiles;
        let profile: Profile;
        let isDefault;
        let hasOne;
        let hasOneInSash;
        let reinforcement: ReinforcementV2;

        const mullionsOne: {
            [prop: string]: any;
        } = {};

        details.measurements = conf.measurements;

        // Słupki początek
        if (conf.mullions.length) {
            for (let y = 0; y < conf.mullions.length; y++) {
                topLeft = conf.mullions[y].adjacentSashes.top
                    ? conf.mullions[y].adjacentSashes.top[0]
                    : conf.mullions[y].adjacentSashes.left[0];
                bottomRight = conf.mullions[y].adjacentSashes.bottom
                    ? conf.mullions[y].adjacentSashes.bottom[0]
                    : conf.mullions[y].adjacentSashes.right[0];
                topLeft = conf.sashes[core.fId(conf.sashes, topLeft, 'id')].index;
                bottomRight = conf.sashes[core.fId(conf.sashes, bottomRight, 'id')].index;

                profile = {
                    ...conf.dictionary.profiles[conf.mullions[y].profileId],
                    name: TranslateService.translate(
                        translatedData,
                        conf.dictionary.profiles[conf.mullions[y].profileId],
                        'Profile',
                        'name',
                        this.langCode
                    ),
                };

                reinforcement = conf.dictionary.reinforcements[conf.mullions[y].reinforcement];
                if (reinforcement && reinforcement.id !== null) {
                    reinforcement.name = TranslateService.translate(
                        translatedData,
                        reinforcement,
                        'Reinforcement',
                        'name',
                        this.langCode
                    );
                }

                div = {
                    id: conf.mullions[y].id,
                    isDefault: conf.mullions[y].isDefault,
                    profileId: conf.mullions[y].profileId,
                    type: profile ? profile.type : null,
                    name: profile ? profile.name : '',
                    outerWidth: profile.widthOut,
                    innerWidth: profile.width,
                    length: conf.mullions[y].length,
                    position: conf.mullions[y].position,
                    direction: conf.mullions[y].direction,
                    price: priceFromSegments(prices, priceParts, currency, {
                        value: { type: 'dividers', data: { divId: conf.mullions[y].id } },
                    }),
                    topLeft,
                    bottomRight,
                    reinforcement,
                };
                details.mullions.isDefault =
                    details.mullions.isDefault
                    && (Common.isDefined(conf.mullions[y].isDefault)
                        ? conf.mullions[y].isDefault
                        : true);
                type = profile ? profile.type : null;
                if (profile && profile.options && profile.options.indexOf('central_handle') > -1) {
                    type = 'false_mullion_central';
                }
                if (profile && profile.options && profile.options.indexOf('double_handle') > -1) {
                    type = 'false_mullion_double';
                }
                if (profile && this.mapMullionType[type]) {
                    if (!mullionsOne[core.toCamelCase(this.mapMullionType[type])]) {
                        mullionsOne[core.toCamelCase(this.mapMullionType[type])] = div.profileId;
                    }
                    if (
                        div.profileId !== mullionsOne[core.toCamelCase(this.mapMullionType[type])]
                    ) {
                        details.mullions[
                            core.toCamelCase(this.mapMullionType[type]) + 'One'
                        ] = false;
                    }
                    details.mullions[core.toCamelCase(this.mapMullionType[type])].push(div);
                }
            }
        }
        // Słupki koniec

        for (let i = 0; i < sashes.length; i++) {
            // Skrzydła początek
            details.sashes.sashes.push({
                id: sashes[i].id,
                index: sashes[i].index,
                weight: sashes[i].weight,
                price: priceFromSegments(prices, priceParts, currency, {
                    value: { type: 'sashes', data: { sashId: sashes[i].id, isashId: null } },
                }),
            });
            // Skrzydła koniec

            // Kształt początek
            details.shape.sashes.push({
                sashId: sashes[i].id,
                sashIndex: sashes[i].index,
                shape: sashes[i].shape,
                price: priceFromSegments(prices, priceParts, currency, {
                    value: { type: 'shape', data: { sashId: sashes[i].id, isashId: null } },
                }),
            });
            // Kształt koniec

            // Dane z pól
            if (sashes[i].intSashes.length) {
                intSashes = sashes[i].intSashes.sort((a, b) => a.index - b.index);
                intSashesData = this.positionDetailsIntSashes(
                    conf,
                    sashes[i],
                    intSashes,
                    prices,
                    priceParts,
                    translatedData,
                    currency,
                );
            }

            // Szczebliny / szprosy konstrukcyjne początek
            if (sashes[i].intMullions.length) {
                for (let j = 0; j < sashes[i].intMullions.length; j++) {
                    topLeft = sashes[i].intMullions[j].adjacentSashes.top
                        ? sashes[i].intMullions[j].adjacentSashes.top[0]
                        : sashes[i].intMullions[j].adjacentSashes.left[0];
                    bottomRight = sashes[i].intMullions[j].adjacentSashes.bottom
                        ? sashes[i].intMullions[j].adjacentSashes.bottom[0]
                        : sashes[i].intMullions[j].adjacentSashes.right[0];
                    topLeft =
                        conf.sashes[i].intSashes[core.fId(conf.sashes[i].intSashes, topLeft, 'id')]
                            .index;
                    bottomRight =
                        conf.sashes[i].intSashes[
                            core.fId(conf.sashes[i].intSashes, bottomRight, 'id')
                        ].index;
                    profile = {
                        ...conf.dictionary.profiles[sashes[i].intMullions[j].profileId],
                        name: TranslateService.translate(
                            translatedData,
                            conf.dictionary.profiles[sashes[i].intMullions[j].profileId],
                            'Profile',
                            'name',
                            this.langCode
                        ),
                    };
                    details.mullions.intMullions.push({
                        id: sashes[i].intMullions[j].id,
                        isDefault: sashes[i].intMullions[j].isDefault,
                        profileId: sashes[i].intMullions[j].profileId,
                        sashId: sashes[i].id,
                        sashIndex: sashes[i].index,
                        type: sashes[i].intMullions[j].type,
                        name: profile ? profile.name : '',
                        outerWidth: profile.widthOut,
                        innerWidth: profile.width,
                        length: sashes[i].intMullions[j].length,
                        position: sashes[i].intMullions[j].position,
                        direction: sashes[i].intMullions[j].direction,
                        price: priceFromSegments(prices, priceParts, currency, {
                            value: {
                                type: 'intMullions',
                                data: { sashId: sashes[i].id, divId: sashes[i].intMullions[j].id },
                            },
                        }),
                        topLeft,
                        bottomRight,
                    });
                    details.mullions.isDefault =
                        details.mullions.isDefault
                        && (Common.isDefined(sashes[i].intMullions[j].isDefault)
                            ? sashes[i].intMullions[j].isDefault
                            : true);
                    if (!mullionsOne.intMullions) {
                        mullionsOne.intMullions = sashes[i].intMullions[j].profileId;
                    }
                    if (sashes[i].intMullions[j].profileId !== mullionsOne.intMullions) {
                        details.mullions.intMullionsOne = false;
                    }
                }
            }
            // Szczebliny / szprosy konstrukcyjne koniec

            // Akcesoria początek
            if (sashes[i].hardware.length) {
                sashAccessories = [];
                for (let j = 0; j < sashes[i].hardware.length; j++) {
                    const data = {
                        sashId: sashes[i].id,
                        id: sashes[i].hardware[j].id,
                    };
                    if (sashes[i].hardware[j].colorSet) {
                        data[`colorField`] = sashes[i].hardware[j].colorSet;
                    }
                    if (sashes[i].hardware[j].color && sashes[i].hardware[j].color.accessory) {
                        data[`colorId`] = sashes[i].hardware[j].color.accessory.id;
                        sashes[i].hardware[j].color.accessory.name = TranslateService.translate(
                            translatedData,
                            sashes[i].hardware[j].color.accessory,
                            'WindowHandlesColor',
                            'name',
                            this.langCode
                        );

                    }
                    sashAccessories.push(
                        Common.extend(core.copy(sashes[i].hardware[j]), {
                            name: TranslateService.translate(
                                translatedData,
                                sashes[i].hardware[j],
                                'WindowAccessory',
                                'name',
                                this.langCode
                            ),
                            price: priceFromSegments(prices, priceParts, currency, {
                                value: { type: 'sashesHardware', data },
                            }),
                        })
                    );
                }
                details.accessories.sashes.push({
                    sashId: sashes[i].id,
                    sashIndex: sashes[i].index,
                    price: priceFromSegments(prices, priceParts, currency, {
                        value: { type: 'sashesHardware', data: { sashId: sashes[i].id } },
                        percent: { type: 'sashesHardware' },
                    }),
                    accessories: sashAccessories,
                });
            }
            // Akcesoria koniec

            // Wzmocnienia konstrukcji początek
            // TODO
            // if (sashes[i].reinforcement) {
            //     if (details.reinforcement === null) {
            //         details.reinforcement = core.copy(sashes[i].reinforcement);
            //         details.reinforcement.hasOne = conf.oneReinforcement;
            //         details.reinforcement.show = false;
            //         details.reinforcement.sashes = [];
            //     }
            //     details.reinforcement.sashes.push(sashes[i].reinforcement);
            //     if (sashes[i].reinforcement.showOnOffer) {
            //         details.reinforcement.show = true;
            //     }
            // }
            // Wzmocnienia konstrukcji koniec

            // Typy skrzydeł początek
            details.sashesTypes.sashes.push({
                id: sashes[i].type.id,
                type: conf.dictionary.sashTypes[sashes[i].type.id].type,
                name: TranslateService.translate(
                    translatedData,
                    sashes[i].type,
                    'SashType',
                    'name',
                    this.langCode
                ),
                handlePosition: sashes[i].type.handlePosition,
            });
            // Typy skrzydeł koniec

            let glazingBead = conf.dictionary.profiles[sashes[i].glazingBead.profileId];
            if (!glazingBead && sashes[i].intSashes.length) {
                glazingBead =
                    conf.dictionary.profiles[sashes[i].intSashes[0].glazingBead.profileId];
            }
            if (glazingBead && sashes[i].panelType !== 'Double' && sashes[i].panelType !== 'Inner') {
                let shape: ProfileShapeV2 | boolean = false;
                if (Common.isDefined(glazingBead.profileShapeId)) {
                    shape = conf.dictionary.profileShapes[glazingBead.profileShapeId];
                }
                // Listwy przyszybowe początek
                if (details.glazingBead === null) {
                    details.glazingBead = {
                        id: glazingBead.id,
                        sashId: null,
                        isashId: null,
                        name: TranslateService.translate(
                            translatedData,
                            glazingBead,
                            'Profile',
                            'name',
                            this.langCode
                        ),
                        shape: shape
                            ? TranslateService.translate(
                                  translatedData,
                                  shape,
                                  'ProfileShape',
                                  'name',
                                  this.langCode
                              )
                            : false,
                        hasOne: true,
                        price: priceFromSegments(prices, priceParts, currency, {
                            value: { type: 'glazingBead' },
                        }),
                        sashes: [],
                        color: conf.glazingBeadColor && {
                            id: conf.glazingBeadColor.id,
                            name: TranslateService.translate(
                                translatedData,
                                conf.glazingBeadColor,
                                'GlazingBeadColor',
                                'name',
                                this.langCode
                            ),
                            ral: conf.glazingBeadColor.RAL,
                            isDefault: conf.glazingBeadColor.isDefault,
                            price: priceFromSegments(prices, priceParts, currency, {
                                percent: { type: 'glazingBeadColor' },
                            }),
                        },
                    };
                }

                glazingBeadSash = {
                    id: glazingBead.id,
                    sashId: sashes[i].id,
                    sashIndex: sashes[i].index,
                    isashId: null,
                    name: TranslateService.translate(
                        translatedData,
                        glazingBead,
                        'Profile',
                        'name',
                        this.langCode
                    ),
                    shape: shape
                        ? TranslateService.translate(
                              translatedData,
                              shape,
                              'ProfileShape',
                              'name',
                              this.langCode
                          )
                        : '',
                    hasOne: true,
                    price: priceFromSegments(prices, priceParts, currency, {
                        value: { type: 'glazingBead' },
                    }),
                    intSashes: [],
                };

                if (details.glazingBead.id !== glazingBeadSash.id) {
                    details.glazingBead.hasOne = false;
                }

                if (sashes[i].intSashes.length) {
                    glazingBeadSash.intSashes = intSashesData.glazingBeadIntSashes;
                    for (let j = 0; j < glazingBeadSash.intSashes.length; j++) {
                        if (glazingBeadSash.id !== glazingBeadSash.intSashes[j].id) {
                            glazingBeadSash.hasOne = false;
                        }
                    }
                }
                details.glazingBead.hasOne = details.glazingBead.hasOne && glazingBeadSash.hasOne;
                if (glazingBeadSash.intSashes.length === 1) {
                    glazingBeadSash.name = glazingBeadSash.intSashes[0].name;
                    glazingBeadSash.shape = glazingBeadSash.intSashes[0].shape;
                    glazingBeadSash.price = glazingBeadSash.intSashes[0].price;
                    glazingBeadSash.hasOne = true;
                }
                details.glazingBead.sashes.push(glazingBeadSash);
                // Listwy przyszybowe koniec
            }
            // Wypełnienia początek
            glazingCount = prices.filter(
                e =>
                    e != null
                    && e.type === 'filling'
                    && Number(e.data.sashId) === Number(sashes[i].id)
                    && e.data.isashId === null
            );
            glazingCount = glazingCount.length ? glazingCount[0].data.glazingCount * 1 : null;
            const sashFilling = conf.dictionary.fillings[sashes[i].filling.id];
            const sashPanelGlazing =
                sashes[i].panelGlazing && conf.dictionary.fillings[sashes[i].panelGlazing.id];
            if (details.fillings === null) {
                details.doorFillings = {
                    active: null,
                    activeInner: null,
                    passive: null,
                    leftLight: null,
                    rightLight: null,
                    topLight: null,
                };

                details.fillings = {
                    id: sashFilling.id,
                    sashId: null,
                    isashId: null,
                    type: sashFilling.type,
                    u: sashFilling.u,
                    psi: sashFilling.psi,
                    weight: sashFilling.weight,
                    name: TranslateService.translate(
                        translatedData,
                        sashFilling,
                        'Filling',
                        'name',
                        this.langCode
                    ),
                    producer: sashFilling.producer,
                    hasOne:
                        (!this.IccConfig.Offer.fillingSizes && conf.oneGlazing)
                        || (this.IccConfig.Offer.fillingSizes
                            && sashes.length < 2
                            && (!sashes[0].intSashes || sashes[0].intSashes.length < 2)),
                    glazingCount,
                    price:
                        priceFromSegments(prices, priceParts, currency, { value: { type: 'filling' } })
                        + priceFromSegments(prices, priceParts, currency, {
                            value: { type: 'fillingShape' },
                        }),
                    pricePanel: {
                        panel: priceFromSegments(prices, priceParts, currency, {
                            value: { type: 'panelPrice' },
                        }),
                        types: priceFromSegments(prices, priceParts, currency, {
                            value: { type: 'panelTypes' },
                        }),
                        sizes: priceFromSegments(prices, priceParts, currency, {
                            value: { type: 'panelSizes' },
                        }),
                        glazing: priceFromSegments(prices, priceParts, currency, {
                            value: { type: 'glazingPrice' },
                        }),
                        all:
                            priceFromSegments(prices, priceParts, currency, {
                                value: { type: 'panelTypes' },
                            })
                            + priceFromSegments(prices, priceParts, currency, {
                                value: { type: 'panelSizes' },
                            })
                            + priceFromSegments(prices, priceParts, currency, {
                                value: { type: 'panelPrice' },
                            })
                            + priceFromSegments(prices, priceParts, currency, {
                                value: { type: 'glazingPrice' },
                            }),
                    },
                    sashes: [],
                    fillingSizes: sashes[i].fillingSizes,
                    color: null,
                    singleColor: false,
                    panelGlazing: !sashes[i].panelGlazing?.id
                        ? null
                        : {
                              id: sashes[i].panelGlazing.id,
                              name: TranslateService.translate(
                                  translatedData,
                                  sashes[i].panelGlazing,
                                  'Filling',
                                  'name',
                                  this.langCode
                              ),
                              type: sashPanelGlazing.type,
                              producer: sashPanelGlazing.producer,
                          },
                };
            }

            fillingSash = {
                id: sashFilling.id,
                sashId: sashes[i].id,
                sashIndex: sashes[i].index,
                sashType: sashes[i].type,
                isashId: null,
                type: sashFilling.type,
                u: sashFilling.u,
                rw: sashFilling.rw,
                c: sashFilling.c,
                ctr: sashFilling.ctr,
                lt: sashFilling.lt,
                g: sashFilling.g,
                psi: sashFilling.psi,
                weight: sashFilling.weight,
                color: !sashes[i].filling.color
                    ? null
                    : Common.extend(core.copy(sashes[i].filling.color), {
                          inner: {
                              id: sashes[i].filling.color.inner.id,
                              name: TranslateService.translate(
                                  translatedData,
                                  conf.dictionary.colors[getColorUid(sashes[i].filling.color.inner)],
                                  'WindowColor',
                                  'name',
                                  this.langCode
                              ),
                          },
                          outer: {
                              id: sashes[i].filling.color.outer.id,
                              name: TranslateService.translate(
                                  translatedData,
                                  conf.dictionary.colors[getColorUid(sashes[i].filling.color.outer)],
                                  'WindowColor',
                                  'name',
                                  this.langCode
                              ),
                          },
                          core: {
                              id: sashes[i].filling.color.core.id,
                              name: TranslateService.translate(
                                  translatedData,
                                  conf.dictionary.colors[getColorUid(sashes[i].filling.color.core)],
                                  'WindowColor',
                                  'name',
                                  this.langCode
                              ),
                          },
                      }),
                overlayColor: !sashes[i].filling.overlayColor
                    ? null
                    : Common.extend(core.copy(sashes[i].filling.overlayColor), {
                        inner: {
                            id: sashes[i].filling.overlayColor.inner.id,
                            name: TranslateService.translate(
                                translatedData,
                                conf.dictionary.colors[getColorUid(sashes[i].filling.overlayColor.inner)],
                                'WindowColor',
                                'name',
                                this.langCode
                            ),
                        },
                        outer: {
                            id: sashes[i].filling.overlayColor.outer.id,
                            name: TranslateService.translate(
                                translatedData,
                                conf.dictionary.colors[getColorUid(sashes[i].filling.overlayColor.outer)],
                                'WindowColor',
                                'name',
                                this.langCode
                            ),
                        },
                        core: {
                            id: sashes[i].filling.overlayColor.core.id,
                            name: TranslateService.translate(
                                translatedData,
                                conf.dictionary.colors[getColorUid(sashes[i].filling.overlayColor.core)],
                                'WindowColor',
                                'name',
                                this.langCode
                            ),
                        },
                    }),
                name: TranslateService.translate(
                    translatedData,
                    sashFilling,
                    'Filling',
                    'name',
                    this.langCode
                ),
                producer: sashFilling.producer,
                hasOne:
                    (!this.IccConfig.Offer.fillingSizes && sashes[i].oneGlazing)
                    || (this.IccConfig.Offer.fillingSizes
                        && (!sashes[i].intSashes || sashes[i].intSashes.length < 2)),
                singleColor: false,
                singleOverlayColor: false,
                glazingCount,
                price:
                    priceFromSegments(prices, priceParts, currency, {
                        value: { type: 'filling', data: { sashId: sashes[i].id } },
                    })
                    + priceFromSegments(prices, priceParts, currency, {
                        value: { type: 'fillingShape', data: { sashId: sashes[i].id } },
                    }),
                priceGlazingSpacer: priceFromSegments(prices, priceParts, currency, {
                    value: { type: 'warmEdge', data: { sashId: sashes[i].id } },
                }),
                pricePanel: {
                    panel: priceFromSegments(prices, priceParts, currency, {
                        value: {
                            type: 'panelPrice',
                            data: { sashId: sashes[i].id, isashId: null },
                        },
                    }),
                    types: priceFromSegments(prices, priceParts, currency, {
                        value: {
                            type: 'panelTypes',
                            data: { sashId: sashes[i].id, isashId: null },
                        },
                    }),
                    sizes: priceFromSegments(prices, priceParts, currency, {
                        value: {
                            type: 'panelSizes',
                            data: { sashId: sashes[i].id, isashId: null },
                        },
                    }),
                    glazing: priceFromSegments(prices, priceParts, currency, {
                        value: {
                            type: 'glazingPrice',
                            data: { sashId: sashes[i].id, isashId: null },
                        },
                    }),
                    all:
                        priceFromSegments(prices, priceParts, currency, {
                            value: {
                                type: 'panelTypes',
                                data: { sashId: sashes[i].id, isashId: null },
                            },
                        })
                        + priceFromSegments(prices, priceParts, currency, {
                            value: {
                                type: 'panelSizes',
                                data: { sashId: sashes[i].id, isashId: null },
                            },
                        })
                        + priceFromSegments(prices, priceParts, currency, {
                            value: {
                                type: 'panelPrice',
                                data: { sashId: sashes[i].id, isashId: null },
                            },
                        })
                        + priceFromSegments(prices, priceParts, currency, {
                            value: {
                                type: 'glazingPrice',
                                data: { sashId: sashes[i].id, isashId: null },
                            },
                        }),
                },
                intSashes: [],
                fillingSizes: sashes[i].fillingSizes,
                panelGlazing: !sashes[i].panelGlazing?.id
                    ? null
                    : {
                          id: sashes[i].panelGlazing.id,
                          name: TranslateService.translate(
                              translatedData,
                              sashPanelGlazing,
                              'Filling',
                              'name',
                              this.langCode
                          ),
                          type: sashPanelGlazing.type,
                          producer: sashPanelGlazing.producer,
                      },
            };
            if (sashes[i].panelInner?.color?.inner) {
                fillingSash.color.inner = Common.extend(core.copy(sashes[i].panelInner.color.inner), {
                    id: sashes[i].panelInner.color.inner.id,
                    name: TranslateService.translate(
                        translatedData,
                        conf.dictionary.colors[getColorUid(sashes[i].panelInner.color.inner)],
                        'WindowColor',
                        'name',
                        this.langCode
                    ),
                });
            }

            if (fillingSash.overlayColor && sashes[i].panelInner?.overlayColor?.inner) {
                fillingSash.overlayColor.inner = Common.extend(core.copy(sashes[i].panelInner.overlayColor.inner), {
                    id: sashes[i].panelInner.overlayColor.inner.id,
                    name: TranslateService.translate(
                        translatedData,
                        conf.dictionary.colors[getColorUid(sashes[i].panelInner.overlayColor.inner)],
                        'WindowColor',
                        'name',
                        this.langCode
                    ),
                });
            }

            if (fillingSash.color && fillingSash.color.inner.id === fillingSash.color.outer.id) {
                fillingSash.singleColor = true;
            }
            if (fillingSash.overlayColor && fillingSash.overlayColor.inner.id === fillingSash.overlayColor.outer.id) {
                fillingSash.singleOverlayColor = true;
            }
            if (sashes[i].intSashes.length) {
                fillingSash.intSashes = intSashesData.fillingIntSashes;
                let fillingColor;
                fillingSash.intSashes.forEach(el => {
                    if (el.type === 'pvc_panels' || el.type === 'deco_panels') {
                        if (!fillingColor) {
                            fillingColor = el.color;
                            fillingSash.color = el.color;
                        }
                        fillingSash.hasOne =
                            fillingSash.hasOne
                            && el.hasOne
                            && (!this.IccConfig.Configurators.pvcPanelsDifferentColors
                                || (this.IccConfig.Configurators.pvcPanelsDifferentColors
                                    && el.color
                                    && el.color.inner.id === fillingColor.inner.id
                                    && el.color.outer.id === fillingColor.outer.id));
                        el.singleColor = el.color && el.color.inner.id === el.color.outer.id;
                        fillingSash.color = core.copy(el.color);
                        fillingSash.overlayColor = core.copy(el.overlayColor);
                        fillingSash.panelType = core.copy(el.panelType);
                        fillingSash.panelThickness = core.copy(el.panelThickness);
                    }
                    details.fillings.fillingSizes = el.fillingSizes;
                });
            }
            details.fillings.sashes.push(fillingSash);

            const sashType = conf.dictionary.sashTypes[sashes[i].type.id];
            if (conf.type === 'door') {
                if (
                    (conf.oneGlazing || conf.ownedSashesTypes.doorActive)
                    && sashType.type === 'DRA' || sashType.type === 'DOA'
                ) {
                    details.doorFillings.active = fillingSash;

                    if (sashes[i].panelInner) {
                        const sashPanelInner = conf.dictionary.fillings[Number(sashes[i].panelInner.id)];
                        const panelInnerSash = {
                            id: sashPanelInner.id,
                            sashId: sashes[i].id,
                            sashIndex: sashes[i].index,
                            sashType: sashes[i].type,
                            isashId: null,
                            type: sashPanelInner.type,
                            u: sashPanelInner.u,
                            rw: sashPanelInner.rw,
                            c: sashPanelInner.c,
                            ctr: sashPanelInner.ctr,
                            lt: sashPanelInner.lt,
                            g: sashPanelInner.g,
                            psi: sashPanelInner.psi,
                            weight: sashPanelInner.weight,
                            color: !sashes[i].panelInner.color
                                ? null
                                : Common.extend(core.copy(sashes[i].panelInner.color), {
                                      inner: {
                                          id: sashes[i].panelInner.color.inner.id,
                                          name: TranslateService.translate(
                                              translatedData,
                                              conf.dictionary.colors[getColorUid(sashes[i].panelInner.color.inner)],
                                              'WindowColor',
                                              'name',
                                              this.langCode
                                          ),
                                      },
                                      outer: {
                                          id: sashes[i].panelInner.color.outer.id,
                                          name: TranslateService.translate(
                                              translatedData,
                                              conf.dictionary.colors[getColorUid(sashes[i].panelInner.color.outer)],
                                              'WindowColor',
                                              'name',
                                              this.langCode
                                          ),
                                      },
                                      core: {
                                          id: sashes[i].panelInner.color.core.id,
                                          name: TranslateService.translate(
                                              translatedData,
                                              conf.dictionary.colors[getColorUid(sashes[i].panelInner.color.core)],
                                              'WindowColor',
                                              'name',
                                              this.langCode
                                          ),
                                      },
                                  }),
                            overlayColor: !sashes[i].panelInner.overlayColor
                                ? null
                                : Common.extend(core.copy(sashes[i].panelInner.overlayColor), {
                                    inner: {
                                        id: sashes[i].panelInner.overlayColor.inner.id,
                                        name: TranslateService.translate(
                                            translatedData,
                                            conf.dictionary.colors[getColorUid(sashes[i].panelInner.overlayColor.inner)],
                                            'WindowColor',
                                            'name',
                                            this.langCode
                                        ),
                                    },
                                    outer: {
                                        id: sashes[i].panelInner.overlayColor.outer.id,
                                        name: TranslateService.translate(
                                            translatedData,
                                            conf.dictionary.colors[getColorUid(sashes[i].panelInner.overlayColor.outer)],
                                            'WindowColor',
                                            'name',
                                            this.langCode
                                        ),
                                    },
                                    core: {
                                        id: sashes[i].panelInner.overlayColor.core.id,
                                        name: TranslateService.translate(
                                            translatedData,
                                            conf.dictionary.colors[getColorUid(sashes[i].panelInner.overlayColor.core)],
                                            'WindowColor',
                                            'name',
                                            this.langCode
                                        ),
                                    },
                                }),
                            name: TranslateService.translate(
                                translatedData,
                                sashPanelInner,
                                'Filling',
                                'name',
                                this.langCode
                            ),
                            producer: sashPanelInner.producer,
                            hasOne: true,
                            singleColor: false,
                            singleOverlayColor: false,
                            fillingSizes: sashes[i].fillingSizes
                        };
                        if (panelInnerSash.color && panelInnerSash.color.inner.id === panelInnerSash.color.outer.id) {
                            panelInnerSash.singleColor = true;
                        }
                        if (panelInnerSash.overlayColor && panelInnerSash.overlayColor.inner.id === panelInnerSash.overlayColor.outer.id) {
                            panelInnerSash.singleOverlayColor = true;
                        }
                        details.doorFillings.activeInner = panelInnerSash;
                    }
                }
                if (
                    conf.ownedSashesTypes.doorPassive
                    && sashType.type === 'DRP' || sashType.type === 'DOP'
                ) {
                    details.doorFillings.passive = fillingSash;
                }

                const frame = conf.frames.find(f => f.id === sashes[i].frameId);
                if (
                    conf.ownedSashesTypes.doorTopLight
                    && sashType.type === 'F'
                    && sashes[i].nearMullions.left === -1 && ((frame && frame.x) || 0) + sashes[i].x === 0
                    && sashes[i].nearMullions.top === -1
                    && ((frame && frame.x) || 0) + sashes[i].x + sashes[i].width === conf.width
                ) {
                    details.doorFillings.topLight = fillingSash;
                }

                if (
                    conf.ownedSashesTypes.doorLeftLight
                    && sashType.type === 'F'
                    && sashes[i].nearMullions.left === -1 && ((frame && frame.x) || 0) + sashes[i].x === 0
                    && (sashes[i].nearMullions.top !== -1
                        || ((frame && frame.x) || 0) + sashes[i].x + sashes[i].width !== conf.width
                    )
                ) {
                    details.doorFillings.leftLight = fillingSash;
                }

                if (
                    conf.ownedSashesTypes.doorRightLight
                    && sashType.type === 'F'
                    && sashes[i].nearMullions.right === -1 && ((frame && frame.x) || 0) + sashes[i].x > 0
                ) {
                    details.doorFillings.rightLight = fillingSash;
                }
            }

            if (fillingSash.color) {
                if (!details.fillings.color) {
                    details.fillings.color = fillingSash.color;
                }
                details.fillings.hasOne =
                    details.fillings.hasOne
                    && fillingSash.hasOne
                    && details.fillings.color.inner.id === fillingSash.color.inner.id
                    && details.fillings.color.outer.id === fillingSash.color.outer.id;
            }

            if (details.bondedGlazings === null) {
                details.bondedGlazings = {
                    has: sashes.some(
                        sash =>
                            (sash.intSashes.length === 0 && sash.bondedGlazing)
                            || sash.intSashes.some(field => field.bondedGlazing)
                    ),
                    hasOne: sashes.every(
                        sash =>
                            (sash.intSashes.length === 0 && sash.bondedGlazing)
                            || sash.intSashes.every(field => field.bondedGlazing)
                    ),
                    sashes: [],
                };
            }

            bondedGlazingsSash = {
                has:
                    (sashes[i].intSashes.length === 0 && sashes[i].bondedGlazing)
                    || sashes[i].intSashes.some(field => field.bondedGlazing),
                hasOne:
                    (sashes[i].intSashes.length === 0 && sashes[i].bondedGlazing)
                    || sashes[i].intSashes.every(field => field.bondedGlazing),
                intSashes: [],
                sashId: sashes[i].id,
                sashIndex: sashes[i].index,
                isashId: null,
            };
            if (sashes[i].intSashes.length) {
                bondedGlazingsSash.intSashes = intSashesData.bondedGlazingIntSashes;
            }

            details.bondedGlazings.sashes.push(bondedGlazingsSash);
            // Wypełnienia koniec

            // Ramy skrzydeł początek
            if (conf.dictionary.sashTypes[sashes[i].type.id].type !== 'F') {
                profiles = {};
                isDefault = true;
                hasOne = true;
                hasOneInSash = true;
                reinforcement = {
                    id: null,
                    extId: null,
                    name: null,
                };

                // tslint:disable-next-line:forin
                for (const side in sashes[i].frame) {
                    profile = {
                        ...conf.dictionary.profiles[sashes[i].frame[side].profileId],
                        name: TranslateService.translate(
                            translatedData,
                            conf.dictionary.profiles[sashes[i].frame[side].profileId],
                            'Profile',
                            'name',
                            this.langCode
                        ),
                    };
                    reinforcement =
                        conf.dictionary.reinforcements[sashes[i].frame[side].reinforcement];
                    if (reinforcement && reinforcement.id !== null) {
                        reinforcement.name = TranslateService.translate(
                            translatedData,
                            reinforcement,
                            'Reinforcement',
                            'name',
                            this.langCode
                        );
                    }

                    if (details.sashFrames === null) {
                        details.sashFrames = {
                            profileId: profile.id,
                            isDefault,
                            hasOne,
                            name: profile.name,
                            price: priceFromSegments(prices, priceParts, currency, {
                                value: { type: 'sashFrames' },
                            }),
                            sashes: [],
                            weldFinishType: conf.weldFinishType,
                            reinforcement,
                        };
                    }

                    profiles[side] = {
                        id: sashes[i].frame[side].profileId,
                        isDefault: sashes[i].frame[side].isDefault,
                        name: TranslateService.translate(
                            translatedData,
                            profile,
                            'Profile',
                            'name',
                            this.langCode
                        ),
                        reinforcement,
                    };

                    isDefault = isDefault && sashes[i].frame[side].isDefault;
                    hasOne =
                        hasOne
                        && sashes[i].frame[side].profileId === details.sashFrames.profileId
                        && (!details.sashFrames.reinforcement
                            || sashes[i].frame[side].reinforcement
                                === details.sashFrames.reinforcement.id);
                    hasOneInSash =
                        hasOneInSash
                        && sashes[i].frame[side].profileId === sashes[i].frame.bottom.profileId
                        && sashes[i].frame[side].reinforcement
                            === sashes[i].frame.bottom.reinforcement;
                }

                details.sashFrames.sashes.push({
                    profileId: profile.id,
                    sashId: sashes[i].id,
                    sashIndex: sashes[i].index,
                    name: TranslateService.translate(
                        translatedData,
                        profile,
                        'Profile',
                        'name',
                        this.langCode
                    ),
                    price: priceFromSegments(prices, priceParts, currency, {
                        value: { type: 'sashFrames', data: { sashId: sashes[i].id } },
                    }),
                    hasOne: hasOneInSash,
                    profiles,
                    isDefault,
                    weldFinishType: conf.weldFinishType,
                    reinforcement,
                });

                details.sashFrames.isDefault = details.sashFrames.isDefault && isDefault;
                details.sashFrames.hasOne = details.sashFrames.hasOne && hasOne;
            }
            // Ramy skrzydeł koniec

            // Klamki początek
            if (
                conf.hasHandle
                && (sashes[i].handleInner != null || sashes[i].handleOuter != null)
            ) {
                let handleInnerDetails;
                if (sashes[i].handleInner != null) {
                    const handleInner = conf.dictionary.handles[sashes[i].handleInner.id];
                    const handleInnerColor =
                        conf.dictionary.accessoryColors[sashes[i].handleInner.color.id];
                    handleInnerDetails = {
                        id: handleInner.id,
                        handleType: handleInner.handleType,
                        name: TranslateService.translate(
                            translatedData,
                            handleInner,
                            'WindowAccessory',
                            'name',
                            this.langCode
                        ),
                        color: {
                            id: handleInnerColor.id,
                            name: TranslateService.translate(
                                translatedData,
                                handleInnerColor,
                                'WindowHandlesColor',
                                'name',
                                this.langCode
                            ),
                            isDefault: sashes[i].handleInner.color.isDefault,
                        },
                    };
                }

                let handleOuterDetails;
                if (sashes[i].handleOuter != null) {
                    const handleOuter = conf.dictionary.handles[sashes[i].handleOuter.id];
                    const handleOuterColor =
                        conf.dictionary.accessoryColors[sashes[i].handleOuter.color.id];
                    handleOuterDetails = {
                        id: handleOuter.id,
                        handleType: handleOuter.handleType,
                        name: TranslateService.translate(
                            translatedData,
                            handleOuter,
                            'WindowAccessory',
                            'name',
                            this.langCode
                        ),
                        color: {
                            id: handleOuterColor.id,
                            name: TranslateService.translate(
                                translatedData,
                                handleOuterColor,
                                'WindowHandlesColor',
                                'name',
                                this.langCode
                            ),
                            isDefault: sashes[i].handleOuter.color.isDefault,
                        },
                    };
                }

                if (conf.System?.default_market_configuration?.double_door_lock_id
                    && details.locks === null
                    && conf.sashes.filter(sash => sash.lock).length > 1) {
                    details.locks = {
                        sashId: null,
                        sashes: []
                    }
                }
                if (details?.locks) {
                    details.locks.sashes.push({
                        sashId: sashes[i].id,
                        sashIndex: sashes[i].index,
                        lock: sashes[i].lock,
                    })
                }

                if (details.handles === null) {
                    details.handles = {
                        sashId: null,
                        hasOne: conf.oneHandle,
                        oneHeight: conf.oneHandlesHeight,
                        height:
                            sashes[i].type.handlePosition !== 'T' ? sashes[i].handleHeight : null,
                        type: conf.handleType,
                        inner: handleInnerDetails,
                        outer: handleOuterDetails,
                        price: {
                            handle: priceFromSegments(prices, priceParts, currency, {
                                value: { type: 'handles' },
                            }),
                            handleHeight: priceFromSegments(prices, priceParts, currency, {
                                value: { type: 'handlesHeights' },
                            }),
                            handlePosition: priceFromSegments(prices, priceParts, currency, {
                                value: { type: 'handlePosition' },
                            }),
                            all:
                                priceFromSegments(prices, priceParts, currency, {
                                    value: { type: 'handles' },
                                })
                                + priceFromSegments(prices, priceParts, currency, {
                                    value: { type: 'handlesHeights' },
                                })
                                + priceFromSegments(prices, priceParts, currency, {
                                    value: { type: 'handlePosition' },
                                }),
                        },
                        sashes: [],
                    };
                }

                details.handles.sashes.push({
                    sashId: sashes[i].id,
                    sashIndex: sashes[i].index,
                    isDefaultHeight: sashes[i].defaultHandleHeight,
                    height: sashes[i].type.handlePosition !== 'T' ? sashes[i].handleHeight : null,
                    type: conf.handleType,
                    inner: handleInnerDetails,
                    outer: handleOuterDetails,
                    price: {
                        handle: priceFromSegments(prices, priceParts, currency, {
                            value: { type: 'handles', data: { sashId: sashes[i].id } },
                        }),
                        handleHeight: priceFromSegments(prices, priceParts, currency, {
                            value: { type: 'handlesHeights', data: { sashId: sashes[i].id } },
                        }),
                        handlePosition: priceFromSegments(prices, priceParts, currency, {
                            value: { type: 'handlePosition', data: { sashId: sashes[i].id } },
                        }),
                        all:
                            priceFromSegments(prices, priceParts, currency, {
                                value: { type: 'handles', data: { sashId: sashes[i].id } },
                            })
                            + priceFromSegments(prices, priceParts, currency, {
                                value: { type: 'handlesHeights', data: { sashId: sashes[i].id } },
                            })
                            + priceFromSegments(prices, priceParts, currency, {
                                value: { type: 'handlePosition', data: { sashId: sashes[i].id } },
                            }),
                    },
                });
            }
            // Klamki koniec

            // Szprosy początek
            if (conf.muntins) {
                muntinsSash = {
                    sashId: sashes[i].id,
                    sashIndex: sashes[i].index,
                    horizontal: [],
                    vertical: [],
                    intSashes: [],
                    price: priceFromSegments(prices, priceParts, currency, {
                        value: { type: 'muntins', data: { sashId: sashes[i].id } },
                    }),
                };

                if (details.muntins === null) {
                    const muntinsColorInner = conf.muntins.colorInner
                        ? conf.dictionary.colors[getColorUid(conf.muntins.colorInner)]
                        : null;
                    const muntinsColorOuter = conf.muntins.colorOuter
                        ? conf.dictionary.colors[getColorUid(conf.muntins.colorOuter)]
                        : null;
                    details.muntins = {
                        size: conf.muntins.size,
                        duplex: conf.muntins.duplex,
                        price: priceFromSegments(prices, priceParts, currency, {
                            value: { type: 'muntins' },
                        }),
                        colorDraw: conf.muntins.colorDraw,
                        colorInner: muntinsColorInner
                            ? {
                                  id: muntinsColorInner.id,
                                  name: TranslateService.translate(
                                      translatedData,
                                      muntinsColorInner,
                                      'WindowColor',
                                      'name',
                                      this.langCode
                                  ),
                                  code: muntinsColorInner.code,
                                  ral: muntinsColorInner.RAL,
                                  isDefault: conf.muntins.colorInner.isDefault,
                                  type: muntinsColorInner.type,
                              }
                            : null,
                        colorOuter: muntinsColorOuter
                            ? {
                                  id: muntinsColorOuter.id,
                                  name: TranslateService.translate(
                                      translatedData,
                                      muntinsColorOuter,
                                      'WindowColor',
                                      'name',
                                      this.langCode
                                  ),
                                  code: muntinsColorOuter.code,
                                  ral: muntinsColorOuter.RAL,
                                  isDefault: conf.muntins.colorOuter.isDefault,
                                  type: muntinsColorOuter.type,
                              }
                            : null,
                        type: conf.muntins.type
                            && conf.muntins.type.type && {
                                id: conf.muntins.type.type.id,
                                type: conf.muntins.type.type.type,
                                name: TranslateService.translate(
                                    translatedData,
                                    conf.muntins.type.type,
                                    'WindowBarType',
                                    'name',
                                    this.langCode
                                ),
                                duplex: conf.muntins.type.type.duplex,
                            },
                        sashes: [],
                    };
                }

                if (sashes[i].muntins && sashes[i].muntins.length) {
                    muntinsSash.horizontal = core.copy(
                        sashes[i].muntins.filter(muntin => muntin.start.y === muntin.end.y)
                    );
                    muntinsSash.vertical = core.copy(
                        sashes[i].muntins.filter(muntin => muntin.start.x === muntin.end.x)
                    );
                }

                if (
                    sashes[i].intSashes.length
                    && Common.isDefined(intSashesData.muntinsIntSashes)
                ) {
                    muntinsSash.intSashes = intSashesData.muntinsIntSashes;
                }
                details.muntins.sashes.push(muntinsSash);
            }
            // Szprosy koniec

            // siatki - w konstrukcji i w kwaterach
            const mosquitos = [
                conf.mosquito,
                ...conf.sashes.map(s => ({ ...s.mosquito, sashId: s.id })),
            ].filter(e => e && e.system);
            if (mosquitos.length) {
                details.mosquitos = {
                    price:
                        priceFromSegments(prices, priceParts, currency, {
                            value: { type: 'mosquitoSize' },
                        })
                        + priceFromSegments(prices, priceParts, currency, {
                            value: { type: 'mosquitoColor' },
                        })
                        + priceFromSegments(prices, priceParts, currency, {
                            value: { type: 'mosquitoMontage' },
                        })
                        + priceFromSegments(prices, priceParts, currency, {
                            value: { type: 'mosquitoProfile' },
                        }),
                    mosquitos: [],
                };
                mosquitos.map((mosquito, index) => {
                    details.mosquitos.mosquitos[index] = {};
                    this.positionDetailsMosquito({
                        conf: mosquito,
                        details: details.mosquitos.mosquitos[index],
                        prices,
                        priceParts,
                        translatedData,
                        currency,
                    });
                });
            }

            if (
                priceFromSegments(prices, priceParts, currency, {
                    value: { type: 'sashDependencies', data: { sashId: sashes[i].id } },
                }) > 0
            ) {
                details.dependencies.sashes.push({
                    sashId: sashes[i].id,
                    sashIndex: sashes[i].index,
                    price: priceFromSegments(prices, priceParts, currency, {
                        value: { type: 'sashDependencies', data: { sashId: sashes[i].id } },
                    }),
                });
            }
        }

        return details;
    }

    /**
     * Funkcja zwracająca dane plis
     * @param  {object} conf    Konfiguracja
     * @param  {object} details Dane dla widoku
     * @param  {array}  prices  Tablica segmentów cen
     * @return {object}         Dane dla widoku
     */
    positionDetailsPleatedBlinds({ conf, details, prices, priceParts, translatedData, currency }) {
        details.pleatedBlind = core.copy(conf);
        details.parameters = conf.parameters;
        return details;
    }

    /**
     * Funkcja zwracająca dane rolet
     * @param  {object} conf    Konfiguracja
     * @param  {object} details Dane dla widoku
     * @param  {array}  prices  Tablica segmentów cen
     * @return {object}         Dane dla widoku
     */
    positionDetailsRollers({ conf, details, prices, priceParts, translatedData, currency }) {
        details.rollerShutter = core.copy(conf.rollerShutter);
        details.parameters = conf.parameters;
        if (
            Common.isDefined(details.rollerShutter.colors.box)
            && details.rollerShutter.colors.box
        ) {
            details.rollerShutter.colors.box.name = TranslateService.translate(
                translatedData,
                details.rollerShutter.colors.box,
                'WindowColor',
                'name',
                this.langCode
            );
        }
        if (
            Common.isDefined(details.rollerShutter.colors.boxGuideOuter)
            && details.rollerShutter.colors.boxGuideOuter
        ) {
            details.rollerShutter.colors.boxGuideOuter.name = TranslateService.translate(
                translatedData,
                details.rollerShutter.colors.boxGuideOuter,
                'WindowColor',
                'name',
                this.langCode
            );
        }
        if (
            Common.isDefined(details.rollerShutter.colors.boxInner)
            && details.rollerShutter.colors.boxInner
        ) {
            details.rollerShutter.colors.boxInner.name = TranslateService.translate(
                translatedData,
                details.rollerShutter.colors.boxInner,
                'WindowColor',
                'name',
                this.langCode
            );
        }
        if (
            Common.isDefined(details.rollerShutter.colors.endslat)
            && details.rollerShutter.colors.endslat
        ) {
            details.rollerShutter.colors.endslat.name = TranslateService.translate(
                translatedData,
                details.rollerShutter.colors.endslat,
                'WindowColor',
                'name',
                this.langCode
            );
        }
        if (
            Common.isDefined(details.rollerShutter.colors.guideRail)
            && details.rollerShutter.colors.guideRail
        ) {
            details.rollerShutter.colors.guideRail.name = TranslateService.translate(
                translatedData,
                details.rollerShutter.colors.guideRail,
                'WindowColor',
                'name',
                this.langCode
            );
        }
        if (
            Common.isDefined(details.rollerShutter.colors.profile)
            && details.rollerShutter.colors.profile
        ) {
            details.rollerShutter.colors.profile.name = TranslateService.translate(
                translatedData,
                details.rollerShutter.colors.profile,
                'WindowColor',
                'name',
                this.langCode
            );
        }
        if (
            Common.isDefined(details.rollerShutter.colors.revision)
            && details.rollerShutter.colors.revision
        ) {
            details.rollerShutter.colors.revision.name = TranslateService.translate(
                translatedData,
                details.rollerShutter.colors.revision,
                'WindowColor',
                'name',
                this.langCode
            );
        }
        if (Common.isDefined(details.rollerShutter.drive) && details.rollerShutter.drive) {
            details.rollerShutter.drive.name = TranslateService.translate(
                translatedData,
                details.rollerShutter.drive,
                'RollerShutterDrive',
                'name',
                this.langCode
            );
        }
        if (Common.isDefined(details.rollerShutter.hanger) && details.rollerShutter.hanger) {
            details.rollerShutter.hanger.name = TranslateService.translate(
                translatedData,
                details.rollerShutter.hanger,
                'RollerShutterHanger',
                'name',
                this.langCode
            );
        }
        if (Common.isDefined(details.rollerShutter.profile) && details.rollerShutter.profile) {
            details.rollerShutter.profile.name = TranslateService.translate(
                translatedData,
                details.rollerShutter.profile,
                'Profile',
                'name',
                this.langCode
            );
        }
        if (Common.isDefined(details.rollerShutter.roundReel) && details.rollerShutter.roundReel) {
            details.rollerShutter.roundReel.name = TranslateService.translate(
                translatedData,
                details.rollerShutter.roundReel,
                'RoundReel',
                'name',
                this.langCode
            );
        }
        if (Common.isDefined(details.rollerShutter.system) && details.rollerShutter.system) {
            details.rollerShutter.system.name = TranslateService.translate(
                translatedData,
                details.rollerShutter.system,
                'RollerShutterSystem',
                'name',
                this.langCode
            );
        }
        if (details.rollerShutter?.type?.offerInfo) {
            details.rollerShutter.type.offerInfo = TranslateService.translate(
                translatedData,
                details.rollerShutter.type,
                'WindowLine',
                'offer_info',
                this.langCode,
                'id',
                'offerInfo'
            );
        }
        if (Common.isDefined(details.rollerShutter.type) && details.rollerShutter.type) {
            details.rollerShutter.type.name = TranslateService.translate(
                translatedData,
                details.rollerShutter.type,
                'WindowLine',
                'name',
                this.langCode
            );
        }
        if (Common.isArray(details.rollerShutter.driveAccessories)) {
            for (let i = 0; i < details.rollerShutter.driveAccessories.length; i++) {
                details.rollerShutter.driveAccessories[i].name = TranslateService.translate(
                    translatedData,
                    details.rollerShutter.driveAccessories[i],
                    details.rollerShutter.driveAccessories[i].type === 'rollerShutterManual' ? 'RollerShutterDrivesManual' : 'WindowAccessory',
                    'name',
                    this.langCode
                );
            }
        }

        details.rollerShutter.guideRailsArr = [];
        if (
            Common.isDefined(details.rollerShutter.guideRails)
            && details.rollerShutter.guideRails
            && details.rollerShutter.shutters
        ) {
            details.rollerShutter.shutters.forEach(shutter => {
                const guides = details.rollerShutter.guideRails
                    .filter(rail => rail.leftOf === shutter.id || rail.rightOf === shutter.id)
                    .sort((rail1, rail2) => {
                        if (rail1.side.includes('common') || rail2.side.includes('common')) {
                            return -1;
                        }
                        if (rail1.side > rail2.side) {
                            return 1;
                        } else if (rail1.side === rail2.side) {
                            return 0;
                        }
                        return -1;
                    });

                if (details.rollerShutter.guideRails.length === 2) {
                    guides.forEach(guide => {
                        let sideText = '';
                        if (guide.leftOf !== null) {
                            sideText = this.translateFilter('INTERFACE|Lewa');
                        } else if (guide.rightOf !== null) {
                            sideText = this.translateFilter('INTERFACE|Prawa');
                        }
                        const profile = TranslateService.translate(
                            translatedData,
                            guide,
                            'Profile',
                            'name',
                            this.langCode
                        );
                        if (
                            !details.rollerShutter.guideRailsArr.find(
                                el => el.originalSide === guide.side
                            )
                        ) {
                            details.rollerShutter.guideRailsArr.push({
                                name: '',
                                side: sideText,
                                profile,
                                shutterNumber: '',
                                originalSide: guide.side,
                            });
                        }
                    });
                } else {
                    guides.forEach(guide => {
                        let sideText = '';
                        let shutterNumber = null;
                        if (guide.leftOf !== null) {
                            sideText = this.translateFilter('INTERFACE|Lewa');
                        } else if (guide.rightOf !== null) {
                            sideText = this.translateFilter('INTERFACE|Prawa');
                        }
                        if (guide.leftOf !== null && guide.rightOf !== null) {
                            shutterNumber = `${guide.rightOf + 1}/${guide.leftOf + 1}`;
                            sideText = this.translateFilter('INTERFACE|Wspólna');
                        }
                        const profile = TranslateService.translate(
                            translatedData,
                            guide,
                            'Profile',
                            'name',
                            this.langCode
                        );
                        if (
                            !details.rollerShutter.guideRailsArr.find(
                                el => el.originalSide === guide.side
                            )
                        ) {
                            details.rollerShutter.guideRailsArr.push({
                                name: this.translateFilter('WINDOW|Kwatera'),
                                side: sideText,
                                profile,
                                shutterNumber:
                                    shutterNumber !== null ? shutterNumber : shutter.id + 1,
                                originalSide: guide.side,
                            });
                        }
                    });
                }
            });
        }

        details.rollerShutter.slats = [];

        if (Common.isArray(conf.rollerShutter.slats)) {
            details.rollerShutter.slats = conf.rollerShutter.slats.map(slat => ({
                ...slat,
                name: TranslateService.translate(
                    translatedData,
                    slat,
                    'Profile',
                    'name',
                    this.langCode
                )
            }));
        }

        details.rollerShutter.colors.price =
            priceFromSegments(prices, priceParts, currency, { value: { type: 'rollerColor' } })
            + priceFromSegments(prices, priceParts, currency, { value: { type: 'rollerColors' } })
            + priceFromSegments(prices, priceParts, currency, {
                percent: { type: 'rollerColorsPercent' },
            });
        if (details.rollerShutter.drive) {
            details.rollerShutter.drive.price = priceFromSegments(prices, priceParts, currency, {
                value: { type: 'rollerDrive' },
            });
        }

        if (this.IccConfig.Configurators.external_blind.staticManualDriveData && conf.type === 'external_blind'){
            details.rollerShutter.crankLength = crankLengths.includes(String(conf.rollerShutter.crankLength)) ? conf.rollerShutter.crankLength : crankLengths[0];
            details.rollerShutter.mountingLength = mountingLengths.includes(String(conf.rollerShutter.mountingLength)) ?  conf.rollerShutter.mountingLength : mountingLengths[0];
        }

        details.rollerShutter.price = {
            boxes:
                priceFromSegments(prices, priceParts, currency, {
                    value: { type: 'rollerBoxes' },
                    percent: { type: null },
                })
                + priceFromSegments(prices, priceParts, currency, {
                    value: { type: 'rollerBoxes' },
                    percent: { type: 'margin' },
                })
                + priceFromSegments(prices, priceParts, currency, {
                    value: { type: 'rollerBoxes' },
                    percent: { type: 'market' },
                })
                + priceFromSegments(prices, priceParts, currency, {
                    value: { type: 'rollerBoxes' },
                    percent: { type: 'marketMargin' },
                })
                + priceFromSegments(prices, priceParts, currency, {
                    value: { type: 'rollerBoxes' },
                    percent: { type: 'rollerMultiplier' },
                }),
            boxSize: priceFromSegments(prices, priceParts, currency, {
                value: { type: 'rollerBoxSize' },
            }),
            changedSize: priceFromSegments(prices, priceParts, currency, {
                value: { type: 'rollerChangedSize' },
            }),
            driveManual: priceFromSegments(prices, priceParts, currency, {
                value: { type: 'rollerDriveManual' },
            }),
            driveElems: priceFromSegments(prices, priceParts, currency, {
                value: { type: 'rollerDriveElems' },
            }),
            overload: priceFromSegments(prices, priceParts, currency, {
                value: { type: 'rollerOverload' },
            }),
            drive:
                priceFromSegments(prices, priceParts, currency, { value: { type: 'rollerDrive' } })
                + priceFromSegments(prices, priceParts, currency, {
                    value: { type: 'rollerDriveManual' },
                })
                + priceFromSegments(prices, priceParts, currency, {
                    value: { type: 'rollerDriveElems' },
                })
                + priceFromSegments(prices, priceParts, currency, { value: { type: 'rollerOverload' } }),
            construction:
                priceFromSegments(prices, priceParts, currency, {
                    value: { type: 'rollerBoxes' },
                    percent: { type: null },
                })
                + priceFromSegments(prices, priceParts, currency, {
                    value: { type: 'rollerBoxes' },
                    percent: { type: 'margin' },
                })
                + priceFromSegments(prices, priceParts, currency, {
                    value: { type: 'rollerBoxes' },
                    percent: { type: 'market' },
                })
                + priceFromSegments(prices, priceParts, currency, {
                    value: { type: 'rollerBoxes' },
                    percent: { type: 'marketMargin' },
                })
                + priceFromSegments(prices, priceParts, currency, {
                    value: { type: 'rollerBoxes' },
                    percent: { type: 'rollerMultiplier' },
                })
                + priceFromSegments(prices, priceParts, currency, { value: { type: 'rollerBoxSize' } })
                + priceFromSegments(prices, priceParts, currency, {
                    value: { type: 'rollerShutterMosquito' },
                })
                + priceFromSegments(prices, priceParts, currency, {
                    value: { type: 'rollerShutterGuideRail' },
                })
                + priceFromSegments(prices, priceParts, currency, {
                    value: { type: 'rollerShutterSlat' },
                })
                + priceFromSegments(prices, priceParts, currency, {
                    value: { type: 'rollerChangedSize' },
                })
                + priceFromSegments(prices, priceParts, currency, { value: { type: 'rollerOverload' } })
                + priceFromSegments(prices, priceParts, currency, { value: { type: 'rollerConsole' } }),
            all:
                priceFromSegments(prices, priceParts, currency, { value: { type: 'rollerColor' } })
                + priceFromSegments(prices, priceParts, currency, { value: { type: 'rollerColors' } })
                + priceFromSegments(prices, priceParts, currency, { value: { type: 'rollerDrive' } })
                + priceFromSegments(prices, priceParts, currency, { value: { type: 'rollerBoxes' } })
                + priceFromSegments(prices, priceParts, currency, { value: { type: 'rollerBoxSize' } })
                + priceFromSegments(prices, priceParts, currency, {
                    value: { type: 'rollerShutterMosquito' },
                })
                + priceFromSegments(prices, priceParts, currency, {
                    value: { type: 'rollerShutterGuideRail' },
                })
                + priceFromSegments(prices, priceParts, currency, {
                    value: { type: 'rollerShutterSlat' },
                })
                + priceFromSegments(prices, priceParts, currency, {
                    value: { type: 'rollerDriveElems' },
                })
                + priceFromSegments(prices, priceParts, currency, {
                    value: { type: 'rollerChangedSize' },
                })
                + priceFromSegments(prices, priceParts, currency, { value: { type: 'rollerOverload' } })
                + priceFromSegments(prices, priceParts, currency, { value: { type: 'rollerConsole' } })
                + priceFromSegments(prices, priceParts, currency, {
                    value: { type: 'rollerDriveManual' },
                })
                + priceFromSegments(prices, priceParts, currency, {
                    value: { type: 'rollerSlatDivision' },
                })
                + priceFromSegments(prices, priceParts, currency, { value: { type: 'rollerAssembly' } }),
            mosquito: priceFromSegments(prices, priceParts, currency, {
                value: { type: 'rollerShutterMosquito' },
            }),
            rollerSlatDivision: priceFromSegments(prices, priceParts, currency, {
                value: { type: 'rollerSlatDivision' },
            }),
            guides: priceFromSegments(prices, priceParts, currency, {
                value: { type: 'rollerShutterGuideRail' },
            }),
            slats: priceFromSegments(prices, priceParts, currency, {
                value: { type: 'rollerShutterSlat' },
            }),
        };
        if (this.IccConfig.Configurators.roller_shutter.showRollerAssembly) {
            details.rollerShutter.price.assembly = priceFromSegments(prices, priceParts, currency, {
                value: { type: 'rollerAssembly' },
            });
        } else {
            details.rollerShutter.price.boxes += priceFromSegments(prices, priceParts, currency, {
                value: { type: 'rollerAssembly' },
            });
            details.rollerShutter.price.construction += priceFromSegments(prices, priceParts, currency, {
                value: { type: 'rollerAssembly' },
            });
        }

        if (details.rollerShutter.driveAccessories.length) {
            for (let i = 0; i < details.rollerShutter.driveAccessories.length; i++) {
                details.rollerShutter.driveAccessories[i].price = priceFromSegments(
                    prices,
                    priceParts,
                    currency,
                    {
                        value: {
                            type: 'rollerDriveElems',
                            data: { id: details.rollerShutter.driveAccessories[i].id },
                        },
                    }
                );
            }
        }
        return details;
    }

    /**
     * Funkcja zwracająca dane rolet
     * @param  {object} conf    Konfiguracja
     * @param  {object} details Dane dla widoku
     * @param  {array}  prices  Tablica segmentów cen
     * @return {object}         Dane dla widoku
     */
    positionDetailsAccessories({ conf, details, prices, priceParts, translatedData, currency, }) {
        let accessories = [];

        if (conf?.rollerShutter?.Accessories && conf?.type !== 'roller_shutter' && conf?.type === 'window') {
            accessories = [...conf?.accessories, ...conf.rollerShutter.Accessories];
        } else {
            accessories = [...conf?.accessories];
        }

        for (let i = 0; i < accessories.length; i++) {
            const data = {
                id: accessories[i].id,
                amount: accessories[i].amount,
            };
            if (conf.type === 'complementary_goods') {
                data[`good`] = 'accessory';
            }
            if (accessories[i].colorSet) {
                data[`colorField`] = accessories[i].colorSet;
            }
            if (accessories[i].color) {
                if (accessories[i].color.accessory) {
                    data[`colorId`] = accessories[i].color.accessory.id;
                    accessories[i].color.accessory.name = TranslateService.translate(
                        translatedData,
                        accessories[i].color.accessory,
                        'WindowHandlesColor',
                        'name',
                        this.langCode
                    );
                }
                if (accessories[i].color.profile) {
                    ['core', 'inner', 'outer', 'alushell'].forEach(side => {
                        if (accessories[i].color.profile[side]) {
                            data[`colorSash.frame.${side}.id`] =
                                accessories[i].color.profile[side].id;
                            accessories[i].color.profile[
                                side
                            ].name = TranslateService.translate(
                                translatedData,
                                accessories[i].color.profile[side],
                                'WindowColor',
                                'name',
                                this.langCode
                            );
                        } else {
                            data[`colorSash.frame.${side}.id`] = null;
                        }
                    });
                }
            }
            details.accessories.construction.push(
                Common.extend(core.copy(accessories[i]), {
                    name: TranslateService.translate(
                        translatedData,
                        accessories[i],
                        'WindowAccessory',
                        'name',
                        this.langCode
                    ),
                    price: priceFromSegments(prices, priceParts, currency, {
                        value: {
                            type:
                                conf.type === 'complementary_goods'
                                    ? 'complementaryGoods'
                                    : 'accessories',
                            data,
                        },
                    }),
                })
            );
        }

        // tslint:disable-next-line:forin
        for (const key in conf.sideAccessories) {
            for (let i = 0; i < conf.sideAccessories[key].length; i++) {
                const data = {
                    id: conf.sideAccessories[key][i].id,
                    side: key,
                    amount: conf.sideAccessories[key][i].amount,
                };
                if (conf.sideAccessories[key][i].colorSet) {
                    data[`colorField`] = conf.sideAccessories[key][i].colorSet;
                }
                if (
                    conf.sideAccessories[key][i].color
                ) {
                    if (conf.sideAccessories[key][i].color.accessory) {
                        data[`colorId`] = conf.sideAccessories[key][i].color.accessory.id;
                        conf.sideAccessories[key][i].color.accessory.name = TranslateService.translate(
                            translatedData,
                            conf.sideAccessories[key][i].color.accessory,
                            'WindowHandlesColor',
                            'name',
                            this.langCode
                        );
                    }
                    if (
                        conf.sideAccessories[key][i].color.profile
                    ) {
                        ['core', 'inner', 'outer', 'alushell'].forEach(side => {
                            if (conf.sideAccessories[key][i].color.profile[side]) {
                                data[`colorSash.frame.${side}.id`] =
                                    conf.sideAccessories[key][i].color.profile[side].id;
                                conf.sideAccessories[key][i].color.profile[
                                    side
                                ].name = TranslateService.translate(
                                    translatedData,
                                    conf.sideAccessories[key][i].color.profile[side],
                                    'WindowColor',
                                    'name',
                                    this.langCode
                                );
                            } else {
                                data[`colorSash.frame.${side}.id`] = null;
                            }
                        });
                    }
                }
                details.accessories.side[key].push(
                    Common.extend(core.copy(conf.sideAccessories[key][i]), {
                        name: TranslateService.translate(
                            translatedData,
                            conf.sideAccessories[key][i],
                            'WindowAccessory',
                            'name',
                            this.langCode
                        ),
                        price: priceFromSegments(prices, priceParts, currency, {
                            value: {
                                type: 'sideAccessories',
                                data,
                            },
                        }),
                    })
                );
            }
        }

        return details;
    }

    /**
     * Funkcja zwracająca dane bram garażowych
     * @param  {object} conf    Konfiguracja
     * @param  {object} details Dane dla widoku
     * @param  {array}  prices  Tablica segmentów cen
     * @return {object}         Dane dla widoku
     */
    positionDetailsGarageDoor({ conf, details, prices, translatedData, currency }) {
        details.system = conf.system;
        details.dimensions = conf.dimensions;
        details.mechanism = conf.mechanism;
        details.guide = conf.guide;
        details.panel = conf.panel;
        details.panelStructure = conf.panelStructure;
        details.panelInternalStructure = conf.panelInternalStructure;
        details.plinthPanel = conf.plinthPanel;
        details.plinthPanelStructure = conf.plinthPanelStructure;
        details.drive = conf.drive;

        const driveType = conf.drive.is_manual ? 'GARAGEDOOR|Ręczne' : 'GARAGEDOOR|Elektryczne'
        details.driveType = this.translateFilter(driveType);

        const driveSide = conf.driveSide === 'left' ? 'GARAGEDOOR|Lewa' : 'GARAGEDOOR|Prawa'
        details.driveSide = this.translateFilter(driveSide);

        details.panelColor = conf.panelColor;
        details.panelInternalColor = conf.panelInternalColor;

        details.wicketDoor = conf.wicketDoor;
        const wicketDoorDirection = conf.wicketDoorDirection === 'left' ? 'GARAGEDOOR|Lewa' : 'GARAGEDOOR|Prawa'
        details.wicketDoorDirection = this.translateFilter(wicketDoorDirection);
        details.wicketDoorFrameProfilesColor = conf.wicketDoorFrameProfilesColor;
        details.wicketDoorHandleColor = conf.wicketDoorHandleColor;

        details.panelsData = conf.panelsData;
        return details;
    }

    /**
     * Funkcja zwracająca dane siatek
     * @param  {object} conf    Konfiguracja
     * @param  {object} details Dane dla widoku
     * @param  {array}  prices  Tablica segmentów cen
     * @return {object}         Dane dla widoku
     */
    positionDetailsMosquito({ conf, details, prices, priceParts, translatedData, currency }) {
        details.width = conf.width;
        details.height = conf.height;
        details.sashSize = conf.sashWidth + 'x' + conf.sashHeight;
        details.system = conf.system;
        details.system.name = TranslateService.translate(
            translatedData,
            details.system,
            'WindowLine',
            'name',
            this.langCode
        );
        details.variant = conf.variant;
        details.netType = conf.netType;
        if (details.netType) {
            details.netType.name = TranslateService.translate(
                translatedData,
                details.netType,
                'MosquitoNetType',
                'name',
                this.langCode
            );
        }
        if (details.variant) {
            details.variant.name = TranslateService.translate(
                translatedData,
                details.variant,
                'MosquitoType',
                'name',
                this.langCode
            );
        }
        details.colors = {};
        const mosquitoColors = {
            gray: this.translateFilter('COLOR|Szary'),
            black: this.translateFilter('COLOR|Czarny'),
            white: this.translateFilter('COLOR|Biały'),
            brown: this.translateFilter('COLOR|Brązowy')
        };
        ['frame', 'net', 'panel'].forEach(side => {
            details.colors[side] = null;
            if (conf.colors[side] && conf.colors[side].name) {
                details.colors[side] = conf.colors[side];
                details.colors[side].name = TranslateService.translate(
                    translatedData,
                    details.colors[side],
                    'WindowColor',
                    'name',
                    this.langCode
                );
            }
        });
        if (details.variant && details.variant.hasDirection) {
            details.direction = conf.direction === 'L' ? 'L' : 'R';
        }
        if (conf.montage && conf.montage.id) {
            details.montage = conf.montage;
            details.montage.name = TranslateService.translate(
                translatedData,
                details.montage,
                'MosquitoMontage',
                'name',
                this.langCode
            );
        }
        if (conf.windowLine && conf.windowLine.id) {
            details.windowLine = conf.windowLine;
            details.windowLine.name = TranslateService.translate(
                translatedData,
                details.windowLine,
                'WindowLine',
                'name',
                this.langCode,
                'windowLineId'
            );
        }
        details.profilePositions = conf.profilePositions;
        details.holdersWidth = conf.holdersWidth;
        details.muntinPosition = conf.muntinPosition;
        details.price =
            priceFromSegments(prices, priceParts, currency, {
                value: { type: 'mosquitoSize', data: { sashId: conf.sashId } },
            })
            + priceFromSegments(prices, priceParts, currency, {
                value: { type: 'mosquitoColor', data: { sashId: conf.sashId } },
            })
            + priceFromSegments(prices, priceParts, currency, {
                value: { type: 'mosquitoMontage', data: { sashId: conf.sashId } },
            })
            + priceFromSegments(prices, priceParts, currency, {
                value: { type: 'mosquitoProfile', data: { sashId: conf.sashId } },
            });

        return details;
    }

    /**
     * Funkcja zwracająca dane siatek
     * @param  {object} conf    Konfiguracja
     * @param  {object} details Dane dla widoku
     * @param  {array}  prices  Tablica segmentów cen
     * @return {object}         Dane dla widoku
     */
    positionDetailsWinterGarden({ conf, details, prices, translatedData, currency }) {
        details.garden = conf.garden;
        details.garden.name = TranslateService.translate(
            translatedData,
            details.garden,
            'WinterGarden',
            'name',
            this.langCode
        );

        details.dimensions = conf.dimensions;
        details.handleFacet = conf.handleFacet;
        details.description = conf.description;

        return details;
    }

    /**
     * Funkcja zwracająca dane markiz
     * @param  {object} conf    Konfiguracja
     * @param  {object} details Dane dla widoku
     * @param  {array}  prices  Tablica segmentów cen
     * @return {object}         Dane dla widoku
     */
    positionDetailsAwning({ conf, details, prices, translatedData, currency }) {
        details.system = conf.system;
        details.system.name = TranslateService.translate(
            translatedData,
            details.system,
            'AwningLine',
            'name',
            this.langCode
        );

        details.outreach = conf.outreach;

        details.color = conf.color;
        details.color.name = TranslateService.translate(
            translatedData,
            details.color,
            'AwningConstructionColour',
            'name',
            this.langCode
        );
        details.fabric = conf.fabric;
        details.fabric.name = TranslateService.translate(
            translatedData,
            details.fabric,
            'AwningFabric',
            'name',
            this.langCode
        );
        details.valance = conf.valance;
        details.valance.name = TranslateService.translate(
            translatedData,
            details.valance,
            'AwningValance',
            'name',
            this.langCode
        );
        details.drive = conf.drive;
        details.drive.name =
            conf.drive.type === 'motor'
                ? TranslateService.translate(
                      translatedData,
                      details.drive,
                      'AwningMotor',
                      'name',
                      this.langCode
                  )
                : TranslateService.translate(
                      translatedData,
                      details.drive,
                      'AwningCrank',
                      'name',
                      this.langCode
                  );

        details.mounting = conf.mounting;
        details.mounting.name = TranslateService.translate(
            translatedData,
            details.mounting,
            'AwningMounting',
            'name',
            this.langCode
        );

        return details;
    }
    /**
     * Funkcja zwracająca dane montaży
     * @param  {object} pos     Pozycja
     * @param  {object} details Dane dla widoku
     * @return {object}         Dane dla widoku
     */
    positionMontages({ pos, details, currency }) {
        const montages = core.parseJson(this.offer?.montages);
        details.montages = {
            own: [],
            producer: [],
            own_cost: 0,
            producer_cost: 0
        }
        if (montages.montage) {
            for (const group in montages.groups) {
                if (montages.groups[group].positions.find(el => el.id === pos.tmp_id)) {
                    if (montages.groups[group].own_montages?.some(el => el.selected)) {
                        details.montages.own_cost = montages.groups[group].own_montage_cost;
                        montages.groups[group].own_montages?.forEach(montage => {
                            if (montage.selected) {
                                details.montages.own.push({
                                    id: montage.Montage.id,
                                    name: montage.Montage.name
                                });
                            }
                        });
                    }
                    if (montages.groups[group].producer_montages?.some(el => el.selected)) {
                        details.montages.producer_cost = montages.groups[group].producer_montage_cost;
                        montages.groups[group].producer_montages?.forEach(montage => {
                            if (montage.selected) {
                                details.montages.producer.push({
                                    id: montage.Montage.id,
                                    name: montage.Montage.name
                                });
                            }
                        });
                    }
                }
            }
        }

        return details;
    }

    /**
     * Funkcja zwracająca dane kasonetek
     * @param  {object} conf    Konfiguracja
     * @param  {object} details Dane dla widoku
     * @param  {array}  prices  Tablica segmentów cen
     * @return {object}         Dane dla widoku
     */
    positionDetailsComplementaryGoods({ conf, details, prices, priceParts, translatedData, currency }) {
        details.cassonettos = null;
        details.fillings = null;
        details.windowSills = null;
        details.profiles = null;

        if (conf.cassonettos && conf.cassonettos.length) {
            details.cassonettos = {
                price: priceFromSegments(prices, priceParts, currency, {
                    value: { type: 'complementaryGoods', data: { good: 'cassonetto' } },
                }),
                elems: [],
            };

            for (let i = 0; i < conf.cassonettos.length; i++) {
                details.cassonettos.elems.push(
                    Common.extend(core.copy(conf.cassonettos[i]), {
                        name: TranslateService.translate(
                            translatedData,
                            conf.cassonettos[i],
                            'Cassonetto',
                            'name',
                            this.langCode
                        ),
                        price: priceFromSegments(prices, priceParts, currency, {
                            value: {
                                type: 'complementaryGoods',
                                data: {
                                    id: Number(conf.cassonettos[i].id),
                                    good: 'cassonetto',
                                    currentHeight: conf.cassonettos[i].height,
                                    currentWidth: conf.cassonettos[i].width,
                                    color: conf.cassonettos[i].color.id,
                                    p1: conf.cassonettos[i].p1,
                                    p2: conf.cassonettos[i].p2,
                                    l1: conf.cassonettos[i].l1,
                                },
                            },
                        }),
                    })
                );
            }
        }

        if (conf.fillings && conf.fillings.length) {
            details.fillings = {
                price: priceFromSegments(prices, priceParts, currency, {
                    value: { type: 'complementaryGoods', data: { good: 'glass' } },
                }),
                elems: [],
            };

            for (let i = 0; i < conf.fillings.length; i++) {
                details.fillings.elems.push({
                    count: conf.fillings[i].count,
                    height: conf.fillings[i].height,
                    width: conf.fillings[i].width,
                    price: priceFromSegments(prices, priceParts, currency, {
                        value: {
                            type: 'complementaryGoods',
                            data: {
                                id: Number(conf.fillings[i].filling.id),
                                good: 'glass',
                                warmEdge: conf.fillings[i].glazingSpacer.id,
                                currentWidth: conf.fillings[i].width,
                                currentHeight: conf.fillings[i].height,
                            },
                        },
                    }),
                    filling: Common.extend(core.copy(conf.fillings[i].filling), {
                        name: TranslateService.translate(
                            translatedData,
                            conf.fillings[i].filling,
                            'Filling',
                            'name',
                            this.langCode
                        ),
                    }),
                    glazingSpacer: Common.extend(core.copy(conf.fillings[i].glazingSpacer), {
                        name: TranslateService.translate(
                            translatedData,
                            conf.fillings[i].glazingSpacer,
                            'WindowWarmEdge',
                            'name',
                            this.langCode
                        ),
                    }),
                });
            }
        }

        if (conf.windowSills && conf.windowSills.length) {
            details.windowSills = {
                price: priceFromSegments(prices, priceParts, currency, {
                    value: { type: 'complementaryGoods', data: { good: 'windowsill' } },
                }),
                elems: [],
            };

            for (let i = 0; i < conf.windowSills.length; i++) {
                details.windowSills.elems.push(
                    Common.extend(core.copy(conf.windowSills[i]), {
                        name: TranslateService.translate(
                            translatedData,
                            conf.windowSills[i],
                            'WindowSill',
                            'name',
                            this.langCode
                        ),
                        price: priceFromSegments(prices, priceParts, currency, {
                            value: {
                                type: 'complementaryGoods',
                                data: {
                                    id: Number(conf.windowSills[i].id),
                                    good: 'windowsill',
                                    currentLength: conf.windowSills[i].length,
                                    currentWidth: conf.windowSills[i].width,
                                    plugs: conf.windowSills[i].plugs || false,
                                },
                            },
                        }),
                    })
                );
            }
        }

        if (conf.profiles && conf.profiles.length) {
            details.profiles = {
                price: priceFromSegments(prices, priceParts, currency, {
                    value: { type: 'complementaryGoods', data: { good: 'profile' } },
                }),
                elems: [],
            };

            for (let i = 0; i < conf.profiles.length; i++) {
                const data = {
                    id: conf.profiles[i].profile.id,
                    good: 'profile',
                    amount: conf.profiles[i].length,
                    count: conf.profiles[i].count,
                };
                if (conf.profiles[i].color) {
                    if (conf.profiles[i].color.accessory) {
                        data[`colorId`] = conf.profiles[i].color.accessory.id;
                    }
                    if (conf.profiles[i].color.profile) {
                        ['core', 'inner', 'outer', 'alushell'].forEach(side => {
                            if (conf.profiles[i].color.profile[side]) {
                                data[`colorSash.frame.${side}.id`] =
                                    String(conf.profiles[i].color.profile[side].id);
                                conf.profiles[i].color.profile[
                                    side
                                ].name = TranslateService.translate(
                                    translatedData,
                                    conf.profiles[i].color.profile[side],
                                    'WindowColor',
                                    'name',
                                    this.langCode
                                );
                            } else {
                                data[`colorSash.frame.${side}.id`] = undefined;
                            }
                        });
                    }
                }
                details.profiles.elems.push(
                    Common.extend(core.copy(conf.profiles[i]), {
                        name: TranslateService.translate(
                            translatedData,
                            conf.profiles[i].profile,
                            'Profile',
                            'name',
                            this.langCode
                        ),
                        price: priceFromSegments(prices, priceParts, currency, {
                            value: {
                                type: 'complementaryGoods',
                                data,
                            },
                        }),
                    })
                );
            }
        }

        details.img = this.getComplementaryGoodsImg(conf);

        return details;
    }

    /**
     * Konstruktor danych pozycji dla widoku
     * @param  {object} details        Szczegóły pozycji
     * @param  {object} pos            Pozycja
     * @param  {object} conf           Konfiguracja
     * @param  {array}  prices         Tablica segmentów cen
     * @return {object}                Dane pozycji dla widoku
     */
    positionDetailsColorsCost({ conf, details, translatedData, discounts, currency }) {
        details.colors = [];
        conf.colors.forEach(group => {
            const posGroup = {
                system: null,
                wood: null,
                alushell: null,
                colors: null,
                prices: {
                    client: {
                        price: group.price * (1 - discounts.client / 100),
                        priceBeforeDiscount: group.price,
                        discount: discounts.client
                    },
                    dealer: {
                        price: group.dealerPrice * (1 - discounts.dealer / 100),
                        priceBeforeDiscount: group.dealerPrice,
                        discount: discounts.dealer
                    }
                }
            };
            if (Common.isObject(group.system)) {
                posGroup.system = group.system;
                posGroup.system.name = TranslateService.translate(
                    translatedData,
                    posGroup.system,
                    'WindowLine',
                    'name',
                    this.langCode
                );
            }
            posGroup.wood = !group.wood
                ? null
                : {
                      id: group.wood.id,
                      name: TranslateService.translate(
                          translatedData,
                          group.wood,
                          'WoodType',
                          'name',
                          this.langCode
                      ),
                  };
            posGroup.alushell = {
                has: group.hasAlushell,
                type: group.alushellType,
            };
            let colorHeaderType = '';
            let colorHeaderName = '';
            if (!group.colorsSashExt) {
                if (!group.colors.frame.outer?.id && !group.colors.frame.inner?.id && group.colors.frame.core) {
                    colorHeaderType = this.translateFilter('COLOR|Kolor');
                    colorHeaderName = TranslateService.translate(
                        translatedData,
                        group.colors.frame.core,
                        'WindowColor',
                        'name',
                        this.langCode
                    );
                } else if (group.colors.frame.outer?.id && !group.colors.frame.inner?.id) {
                    colorHeaderType = this.translateFilter('COLOR|Kolor jednostronny zewnętrzny');
                    colorHeaderName = TranslateService.translate(
                        translatedData,
                        group.colors.frame.outer,
                        'WindowColor',
                        'name',
                        this.langCode
                    );
                } else if (group.colors.frame.inner?.id && !group.colors.frame.outer?.id) {
                    colorHeaderType = this.translateFilter('COLOR|Kolor jednostronny wewnętrzny');
                    colorHeaderName = TranslateService.translate(
                        translatedData,
                        group.colors.frame.inner,
                        'WindowColor',
                        'name',
                        this.langCode
                    );
                } else if (group.colors.frame.inner?.id === group.colors.frame.outer?.id) {
                    colorHeaderType = this.translateFilter('COLOR|Kolor obustronny');
                    colorHeaderName = TranslateService.translate(
                        translatedData,
                        group.colors.frame.outer,
                        'WindowColor',
                        'name',
                        this.langCode
                    );
                }
            }
            if (group.colorsSashExt || conf.colors.frame.outer?.id
                && conf.colors.frame.inner?.id
                && conf.colors.frame.outer?.id !== conf.colors.frame.inner?.idd) {
                colorHeaderType = this.translateFilter('COLOR|Kolor');
                colorHeaderName = this.translateFilter('COLOR|różne');
            }
            const colorHeader = `${colorHeaderType}: ${colorHeaderName}`;
            posGroup.colors = {
                header: colorHeader,
                type: group.colorType,
                sashExt: group.colorsSashExt,
                frame: !group.colors.frame
                    ? null
                    : {
                          alushell: !group.colors.frame.alushell
                              ? null
                              : {
                                    Ral: group.colors.frame.alushell.Ral,
                                    code: group.colors.frame.alushell.code,
                                    isDefault: group.colors.frame.alushell.isDefault,
                                    id: group.colors.frame.alushell.id,
                                    name: TranslateService.translate(
                                        translatedData,
                                        group.colors.frame.alushell,
                                        'WindowColor',
                                        'name',
                                        this.langCode
                                    ),
                                },
                          core: !group.colors.frame.core
                              ? null
                              : {
                                    Ral: group.colors.frame.core.Ral,
                                    code: group.colors.frame.core.code,
                                    isDefault: group.colors.frame.core.isDefault,
                                    id: group.colors.frame.core.id,
                                    name: TranslateService.translate(
                                        translatedData,
                                        group.colors.frame.core,
                                        'WindowColor',
                                        'name',
                                        this.langCode
                                    ),
                                },
                          inner: !group.colors.frame.inner
                              ? null
                              : {
                                    Ral: group.colors.frame.inner.Ral,
                                    code: group.colors.frame.inner.code,
                                    isDefault: group.colors.frame.inner.isDefault,
                                    id: group.colors.frame.inner.id,
                                    name: TranslateService.translate(
                                        translatedData,
                                        group.colors.frame.inner,
                                        'WindowColor',
                                        'name',
                                        this.langCode
                                    ),
                                },
                          outer: !group.colors.frame.outer
                              ? null
                              : {
                                    Ral: group.colors.frame.outer.Ral,
                                    code: group.colors.frame.outer.code,
                                    isDefault: group.colors.frame.outer.isDefault,
                                    id: group.colors.frame.outer.id,
                                    name: TranslateService.translate(
                                        translatedData,
                                        group.colors.frame.outer,
                                        'WindowColor',
                                        'name',
                                        this.langCode
                                    ),
                                },
                      },
                sash: !group.colors.sash
                    ? null
                    : {
                          alushell: !group.colors.sash.alushell
                              ? null
                              : {
                                    Ral: group.colors.sash.alushell.Ral,
                                    code: group.colors.sash.alushell.code,
                                    isDefault: group.colors.sash.alushell.isDefault,
                                    id: group.colors.sash.alushell.id,
                                    name: TranslateService.translate(
                                        translatedData,
                                        group.colors.sash.alushell,
                                        'WindowColor',
                                        'name',
                                        this.langCode
                                    ),
                                },
                          core: !group.colors.sash.core
                              ? null
                              : {
                                    Ral: group.colors.sash.core.Ral,
                                    code: group.colors.sash.core.code,
                                    isDefault: group.colors.sash.core.isDefault,
                                    id: group.colors.sash.core.id,
                                    name: TranslateService.translate(
                                        translatedData,
                                        group.colors.sash.core,
                                        'WindowColor',
                                        'name',
                                        this.langCode
                                    ),
                                },
                          inner: !group.colors.sash.inner
                              ? null
                              : {
                                    Ral: group.colors.sash.inner.Ral,
                                    code: group.colors.sash.inner.code,
                                    isDefault: group.colors.sash.inner.isDefault,
                                    id: group.colors.sash.inner.id,
                                    name: TranslateService.translate(
                                        translatedData,
                                        group.colors.sash.inner,
                                        'WindowColor',
                                        'name',
                                        this.langCode
                                    ),
                                },
                          outer: !group.colors.sash.outer
                              ? null
                              : {
                                    Ral: group.colors.sash.outer.Ral,
                                    code: group.colors.sash.outer.code,
                                    isDefault: group.colors.sash.outer.isDefault,
                                    id: group.colors.sash.outer.id,
                                    name: TranslateService.translate(
                                        translatedData,
                                        group.colors.sash.outer,
                                        'WindowColor',
                                        'name',
                                        this.langCode
                                    ),
                                },
                      },
            };
            details.colors.push(posGroup);
        });

        return details;
    }

    /**
     * Konstruktor danych pozycji dla widoku
     * @param  {object} details        Szczegóły pozycji
     * @param  {object} pos            Pozycja
     * @param  {object} conf           Konfiguracja
     * @param  {array}  prices         Tablica segmentów cen
     * @return {object}                Dane pozycji dla widoku
     */
    positionDetailsColorsWasteCost({ conf, details, translatedData, discounts, currency }) {
        details.colors = [];
        conf.colors.forEach(group => {
            const posGroup = {
                profile: null,
                wood: null,
                alushell: null,
                colors: null,
                price: group.price,
                prices: {
                    client: {
                        price: group.price * (1 - discounts.client / 100),
                        priceBeforeDiscount: group.price,
                        discount: discounts.client
                    },
                    dealer: {
                        price: group.dealerPrice * (1 - discounts.dealer / 100),
                        priceBeforeDiscount: group.dealerPrice,
                        discount: discounts.dealer
                    }
                }
            };
            if (Common.isObject(group.profile)) {
                posGroup.profile = group.profile;
                posGroup.profile.name = TranslateService.translate(
                    translatedData,
                    posGroup.profile,
                    'Profile',
                    'name',
                    this.langCode
                );
            }
            posGroup.wood = !group.wood
                ? null
                : {
                      id: group.wood.id,
                      name: TranslateService.translate(
                          translatedData,
                          group.wood,
                          'WoodType',
                          'name',
                          this.langCode
                      ),
                  };
            posGroup.alushell = {
                has: group.hasAlushell,
                type: group.alushellType,
            };
            let colorHeaderType = '';
            let colorHeaderName = '';
            let colorHeaderCoreName = '';
            if (!group.colors.frame?.outer?.id && !group.colors.frame?.inner?.id && group.colors.core) {
                colorHeaderType = this.translateFilter('COLOR|Kolor');
                colorHeaderName = TranslateService.translate(
                    translatedData,
                    group.colors.core,
                    'WindowColor',
                    'name',
                    this.langCode
                );
            } else if (group.colors.outer?.frame?.id && !group.colors.inner?.frame?.id) {
                colorHeaderType = this.translateFilter('COLOR|Kolor jednostronny zewnętrzny');
                colorHeaderName = TranslateService.translate(
                    translatedData,
                    group.colors.outer,
                    'WindowColor',
                    'name',
                    this.langCode
                );
                colorHeaderCoreName = TranslateService.translate(
                    translatedData,
                    group.colors.core,
                    'WindowColor',
                    'name',
                    this.langCode
                );
            } else if (!group.colors.outer?.frame?.id && group.colors.inner?.frame?.id) {
                colorHeaderType = this.translateFilter('COLOR|Kolor jednostronny wewnętrzny');
                colorHeaderName = TranslateService.translate(
                    translatedData,
                    group.colors.inner,
                    'WindowColor',
                    'name',
                    this.langCode
                );
                colorHeaderCoreName = TranslateService.translate(
                    translatedData,
                    group.colors.core,
                    'WindowColor',
                    'name',
                    this.langCode
                );
            } else if (group.colors.outer?.frame?.id === group.colors.inner?.frame?.id) {
                colorHeaderType = this.translateFilter('COLOR|Kolor obustronny');
                colorHeaderName = TranslateService.translate(
                    translatedData,
                    group.colors.outer,
                    'WindowColor',
                    'name',
                    this.langCode
                );
                colorHeaderCoreName = TranslateService.translate(
                    translatedData,
                    group.colors.core,
                    'WindowColor',
                    'name',
                    this.langCode
                );
            } else if (!group.colors.outer?.frame?.id && !group.colors.outer?.frame?.id && group.colors.outer?.frame?.id !== group.colors.inner.frame?.id) {
                colorHeaderType = this.translateFilter('COLOR|Bikolor');
                colorHeaderName =
                    TranslateService.translate(
                        translatedData,
                        group.colors.outer,
                        'WindowColor',
                        'name',
                        this.langCode
                    )
                    + '/'
                    + TranslateService.translate(
                        translatedData,
                        group.colors.inner,
                        'WindowColor',
                        'name',
                        this.langCode
                    );
                colorHeaderCoreName = TranslateService.translate(
                    translatedData,
                    group.colors.core,
                    'WindowColor',
                    'name',
                    this.langCode
                );
            }
            const colorHeaderCore = this.translateFilter('COLOR|Rdzeń');
            const colorHeader =
                `${colorHeaderType}: ${colorHeaderName}`
                + (colorHeaderCoreName ? ` ${colorHeaderCore}: ${colorHeaderCoreName}` : '');
            posGroup.colors = {
                header: colorHeader,
                type: group.colorType,
                sashExt: group.colorsSashExt,
                frame: !group.colors
                    ? null
                    : {
                          alushell: !group.colors.alushell
                              ? null
                              : {
                                    Ral: group.colors.alushell.Ral,
                                    code: group.colors.alushell.code,
                                    isDefault: group.colors.alushell.isDefault,
                                    id: group.colors.alushell.id,
                                    name: TranslateService.translate(
                                        translatedData,
                                        group.colors.alushell,
                                        'WindowColor',
                                        'name',
                                        this.langCode
                                    ),
                                },
                          core: !group.colors.core
                              ? null
                              : {
                                    Ral: group.colors.core.Ral,
                                    code: group.colors.core.code,
                                    isDefault: group.colors.core.isDefault,
                                    id: group.colors.core.id,
                                    name: TranslateService.translate(
                                        translatedData,
                                        group.colors.core,
                                        'WindowColor',
                                        'name',
                                        this.langCode
                                    ),
                                },
                          inner: !group.colors.inner
                              ? null
                              : {
                                    Ral: group.colors.inner.Ral,
                                    code: group.colors.inner.code,
                                    isDefault: group.colors.inner.isDefault,
                                    id: group.colors.inner.id,
                                    name: TranslateService.translate(
                                        translatedData,
                                        group.colors.inner,
                                        'WindowColor',
                                        'name',
                                        this.langCode
                                    ),
                                },
                          outer: !group.colors.outer
                              ? null
                              : {
                                    Ral: group.colors.outer.Ral,
                                    code: group.colors.outer.code,
                                    isDefault: group.colors.outer.isDefault,
                                    id: group.colors.outer.id,
                                    name: TranslateService.translate(
                                        translatedData,
                                        group.colors.outer,
                                        'WindowColor',
                                        'name',
                                        this.langCode
                                    ),
                                },
                      },
                sash: !group.colors
                    ? null
                    : {
                          alushell: !group.colors.alushell
                              ? null
                              : {
                                    Ral: group.colors.alushell.Ral,
                                    code: group.colors.alushell.code,
                                    isDefault: group.colors.alushell.isDefault,
                                    id: group.colors.alushell.id,
                                    name: TranslateService.translate(
                                        translatedData,
                                        group.colors.alushell,
                                        'WindowColor',
                                        'name',
                                        this.langCode
                                    ),
                                },
                          core: !group.colors.core
                              ? null
                              : {
                                    Ral: group.colors.core.Ral,
                                    code: group.colors.core.code,
                                    isDefault: group.colors.core.isDefault,
                                    id: group.colors.core.id,
                                    name: TranslateService.translate(
                                        translatedData,
                                        group.colors.core,
                                        'WindowColor',
                                        'name',
                                        this.langCode
                                    ),
                                },
                          inner: !group.colors.inner
                              ? null
                              : {
                                    Ral: group.colors.inner.Ral,
                                    code: group.colors.inner.code,
                                    isDefault: group.colors.inner.isDefault,
                                    id: group.colors.inner.id,
                                    name: TranslateService.translate(
                                        translatedData,
                                        group.colors.inner,
                                        'WindowColor',
                                        'name',
                                        this.langCode
                                    ),
                                },
                          outer: !group.colors.outer
                              ? null
                              : {
                                    Ral: group.colors.outer.Ral,
                                    code: group.colors.outer.code,
                                    isDefault: group.colors.outer.isDefault,
                                    id: group.colors.outer.id,
                                    name: TranslateService.translate(
                                        translatedData,
                                        group.colors.outer,
                                        'WindowColor',
                                        'name',
                                        this.langCode
                                    ),
                                },
                      },
            };
            details.colors.push(posGroup);
        });

        return details;
    }

    getPositionName(pos, translatedData) {
        let name = '';
        if (pos.details.type === 'roller_shutter' || pos.details.type === 'external_blind') {
            name =
                (pos.details.rollerShutter.type
                    ? TranslateService.translate(
                          translatedData,
                          pos.details.rollerShutter.type,
                          'WindowLine',
                          'name',
                          this.langCode
                      ) + ' > '
                    : '')
                + TranslateService.translate(
                    translatedData,
                    pos.details.rollerShutter.system,
                    'RollerShutterSystem',
                    'name',
                    this.langCode
                );
        } else {
            const typesNames = {
                'pvc'   : this.translateFilter('WINDOW|PVC'),
                'alu'   : this.translateFilter('WINDOW|ALU'),
                'wood'  : this.translateFilter('WINDOW|Drewno'),
            };
            const system = (pos.details.dictionary
                && pos.details.dictionary.systems
                && pos.details.dictionary.systems[pos.details.system])
                || pos.details.system;
            if (system?.doorType) {
                const doorSize = pos.details.dictionary?.doorSizes?.[pos.details.doorSizes?.sashSizeId];
                name = TranslateService.translate(
                    translatedData,
                    system,
                    'WindowLine',
                    'name',
                    this.langCode
                ) + (doorSize ? (' (' + TranslateService.translate(
                    translatedData,
                    doorSize,
                    'DoorSize',
                    'name',
                    this.langCode
                ) + ')') : '');
            } else {
                name =
                    (system && typesNames[system.type]
                        ? typesNames[system.type] + ' > '
                        : '')
                    + TranslateService.translate(
                        translatedData,
                        system,
                        'WindowLine',
                        'name',
                        this.langCode
                    );
            }
        }

        if (name === '') {
            switch (pos.details.type) {
                case 'accessory':
                    name = this.translateFilter('ACCESSORY|Akcesoria');
                    break;
                case 'complementary_goods':
                    name = this.IccConfig.Configurators.complementary_goods
                        .namedAccessory
                        ? this.translateFilter('ACCESSORY|Akcesoria')
                        : this.translateFilter('GOODS|Dobra komplementarne');
                    break;
                case 'winter_garden':
                    name = this.translateFilter('WINTERGARDEN|Ogrody zimowe');
                    break;
                case 'coupled_window':
                    name = this.translateFilter('WINDOW|Konstrukcje złożone');
                    break;
                default:
                    name = pos.details.name;
            }
        }

        return name;
    }

    /**
     * Funkcja zwracająca dane markiz
     * @param  {object} conf    Konfiguracja
     * @param  {object} details Dane dla widoku
     * @param  {array}  prices  Tablica segmentów cen
     * @return {object}         Dane dla widoku
     */
    positionDetailsCoupledWindow({
        conf,
        details,
        prices,
        translatedData,
        allPositions,
        priceParts,
        drawData,
        currency,
    }: {
        conf: CoupledWindowConfiguration;
        details;
        prices;
        translatedData;
        allPositions;
        priceParts;
        drawData;
        currency;
    }) {
        details.subPositions = [];
        let manySystemAndColors = false;

        const firstGroupCode = conf.windows.length > 0 && conf.windows[0].groupCode;

        conf.windows.forEach(window => {
            const position = core.copy(allPositions.find(p => p.id === window.positionId));
            if (position) {
                const windowDetails = new PositionsDetailsService(
                    this.IccConfig,
                    position.doc,
                    this.offer,
                    this.access,
                    this.showPrices,
                    this.priceType,
                    this.langCode,
                    this.translateFilter,
                    this.editable,
                    this.market_time_limits_offer_positions,
                    this.market_time_limits_pdf_positions,
                    this.pdf,
                    this.translatedData,
                    this.showInfo,
                    this.allPositions,
                    this.hideDimensions,
                    this.config
                );
                position.doc.detailsForView.partOfcoupledWindow = true;
                position.doc.detailsForView.index =
                    details.index + '.' + core.indexToLetter(window.index);
                details.subPositions.push(position);
            } else if(this.showInfo) {
                this.showInfo(this.translateFilter('OFFER|Błędna konstrukcja złożona. Usuń ją i dodaj ponownie.'));
            }
            manySystemAndColors = manySystemAndColors || window.groupCode !== firstGroupCode;
        });

        conf.rollerShutters.forEach(rollerShutter => {
            const position = core.copy(allPositions.find(p => p.id === rollerShutter.positionId));
            const rollerShutterDetails = new PositionsDetailsService(
                this.IccConfig,
                position.doc,
                this.offer,
                this.access,
                this.showPrices,
                this.priceType,
                this.langCode,
                this.translateFilter,
                this.editable,
                this.market_time_limits_offer_positions,
                this.market_time_limits_pdf_positions,
                this.pdf,
                this.translatedData,
                this.showInfo,
                this.allPositions,
                this.hideDimensions,
                this.config
            );
            position.doc.detailsForView.partOfcoupledWindow = true;
            position.doc.detailsForView.index =
                details.index + '.' + core.indexToLetter(rollerShutter.index);
            details.subPositions.push(position);

            manySystemAndColors = manySystemAndColors || rollerShutter.groupCode !== firstGroupCode;
        });

        details.hasWarranty = true;
        details.hasGlazingWarranty = true;

        details.subPositions.forEach(position => {
            position.doc.detailsForView.manySystemAndColors = manySystemAndColors;
            details.prices.client.base += position.doc.detailsForView.prices.client.base;
            details.prices.client.surcharges +=
                position.doc.detailsForView.prices.client.surcharges;
            details.prices.dealer.base += position.doc.detailsForView.prices.dealer.base;
            details.prices.dealer.surcharges +=
                position.doc.detailsForView.prices.dealer.surcharges;
            details.hasWarranty = details.hasWarranty && position.doc.detailsForView.hasWarranty;
            details.hasGlazingWarranty =
                details.hasGlazingWarranty && position.doc.detailsForView.hasGlazingWarranty;
        });

        details.couplings = {
            isDefault: true,
            couplings: [],
            price: priceFromSegments(prices, priceParts, currency, { value: { type: 'couplings' } }),
        };

        if (conf.couplings.length) {
            let topLeft;
            let topLeftExtension;
            let bottomRight;
            let bottomRightExtension;
            let reinforcement;

            for (const coupling of conf.couplings) {
                topLeftExtension = coupling.adjacentSideProfileId.length
                    ? this.getFirstSideProfileById(coupling.adjacentSideProfileId[0], conf)
                    : null;
                topLeft = (topLeftExtension || coupling).framesId.map(f => [f.id, f.positionId])[0];
                bottomRightExtension = coupling.adjacentOtherSideProfileId.length
                    ? this.getFirstSideProfileById(coupling.adjacentOtherSideProfileId[0], conf)
                    : null;
                bottomRight = (bottomRightExtension
                    ? bottomRightExtension.framesId
                    : coupling.otherFramesId
                ).map(f => [f.id, f.positionId])[0];
                const topLeftWindow =
                    topLeft && details.subPositions.find(w => w.id === topLeft[1]);
                topLeft = topLeftWindow
                    ? topLeftWindow.doc.detailsForView.index
                      + (topLeftWindow.doc.details.frames.length > 1
                          ? '.'
                            + core.indexToLetter(
                                topLeftWindow.doc.details.frames[
                                    core.fId(topLeftWindow.doc.details.frames, topLeft[0], 'id')
                                ].index
                            )
                          : '')
                    : '';
                const bottomRightWindow =
                    bottomRight && details.subPositions.find(w => w.id === bottomRight[1]);
                bottomRight = bottomRightWindow
                    ? bottomRightWindow.doc.detailsForView.index
                      + (bottomRightWindow.doc.details.frames.length > 1
                          ? '.'
                            + core.indexToLetter(
                                bottomRightWindow.doc.details.frames[
                                    core.fId(
                                        bottomRightWindow.doc.details.frames,
                                        bottomRight[0],
                                        'id'
                                    )
                                ].index
                            )
                          : '')
                    : '';

                const profile = core.fIdO(conf.usedProfiles, coupling.profileId);

                reinforcement = coupling.reinforcement;
                if (reinforcement && reinforcement.id !== null) {
                    reinforcement.name = TranslateService.translate(
                        translatedData,
                        reinforcement,
                        'Reinforcement',
                        'name',
                        this.langCode
                    );
                }

                const couplingDetails = {
                    id: coupling.id,
                    profileId: coupling.profileId,
                    name: TranslateService.translate(
                        translatedData,
                        profile,
                        'Profile',
                        'name',
                        this.langCode
                    ),
                    outerWidth: coupling.widthOut,
                    innerWidth: coupling.width,
                    length: coupling.length,
                    direction: coupling.direction,
                    type: profile ? profile.type : null,
                    price: priceFromSegments(prices, priceParts, currency, {
                        value: { type: 'couplings', data: { couplingId: coupling.id } },
                    }),
                    topLeft,
                    bottomRight,
                    reinforcement,
                    color: {
                        core:
                            coupling.color && coupling.color.core && coupling.color.core.id
                                ? {
                                      id: coupling.color.core.id,
                                      RAL: coupling.color.core.RAL,
                                      code: coupling.color.core.code,
                                      name: TranslateService.translate(
                                          translatedData,
                                          coupling.color.core,
                                          'WindowColor',
                                          'name',
                                          this.langCode
                                      ),
                                  }
                                : null,
                        inner:
                            coupling.color && coupling.color.inner && coupling.color.inner.id
                                ? {
                                      id: coupling.color.inner.id,
                                      RAL: coupling.color.inner.RAL,
                                      code: coupling.color.inner.code,
                                      name: TranslateService.translate(
                                          translatedData,
                                          coupling.color.inner,
                                          'WindowColor',
                                          'name',
                                          this.langCode
                                      ),
                                  }
                                : null,
                        outer:
                            coupling.color && coupling.color.outer && coupling.color.outer.id
                                ? {
                                      id: coupling.color.outer.id,
                                      RAL: coupling.color.outer.RAL,
                                      code: coupling.color.outer.code,
                                      name: TranslateService.translate(
                                          translatedData,
                                          coupling.color.outer,
                                          'WindowColor',
                                          'name',
                                          this.langCode
                                      ),
                                  }
                                : null,
                    },
                };
                details.couplings.couplings.push(couplingDetails);
            }
        }

        details.extensions = {
            extensions: [],
            price: priceFromSegments(prices, priceParts, currency, { value: { type: 'sideProfile' } }),
            show: false,
        };

        if (drawData && drawData.extension && conf.sideProfiles && conf.sideProfiles.length) {
            details.extensions.show = true;

            let adjacentFrame;
            let adjacentExtension;

            conf.sideProfiles.map(extension => {
                adjacentExtension = this.getFirstSideProfileById(extension.id, conf);
                adjacentFrame = (adjacentExtension || extension).framesId.map(f => [
                    f.id,
                    f.positionId,
                    f.edges[0],
                ])[0];
                const adjacentWindow =
                    adjacentFrame && details.subPositions.find(w => w.id === adjacentFrame[1]);
                const adjacentFrameIndex = adjacentWindow
                    ? adjacentWindow.doc.detailsForView.index
                      + (adjacentWindow.doc.details.frames.length > 1
                          ? '.'
                            + core.indexToLetter(
                                adjacentWindow.doc.details.frames[
                                    core.fId(
                                        adjacentWindow.doc.details.frames,
                                        adjacentFrame[0],
                                        'id'
                                    )
                                ].index
                            )
                          : '')
                    : '';
                const extensionProfile = conf.usedProfiles.find(
                    usedProfile => usedProfile.id === extension.profileId
                );
                const extensionDimensions = drawData.extension.find(
                    el => el.extensionId === extension.id
                );

                details.extensions.extensions.push({
                    id: extension.id,
                    code: extensionProfile ? extensionProfile.code : null,
                    count: extension.count || 1,
                    name: extensionProfile
                        ? TranslateService.translate(
                              translatedData,
                              extensionProfile,
                              'Profile',
                              'name',
                              this.langCode
                          )
                        : '',
                    type: extensionProfile ? extensionProfile.type : null,
                    width: extensionDimensions.rect.width,
                    height: extensionDimensions.rect.height,
                    price: priceFromSegments(prices, priceParts, currency, {
                        value: {
                            type: 'sideProfile',
                            data: { id: extension.id, profileId: extension.profileId },
                        },
                    }),
                    frameIndex: adjacentFrameIndex,
                    frameEdgeIndex: adjacentFrame && adjacentFrame.length > 2 && adjacentFrame[2],
                    side: extension.side,
                    color: {
                        core:
                            extension.color && extension.color.core && extension.color.core.id
                                ? {
                                      id: extension.color.core.id,
                                      RAL: extension.color.core.RAL,
                                      code: extension.color.core.code,
                                      name: TranslateService.translate(
                                          translatedData,
                                          extension.color.core,
                                          'WindowColor',
                                          'name',
                                          this.langCode
                                      ),
                                  }
                                : null,
                        inner:
                            extension.color && extension.color.inner && extension.color.inner.id
                                ? {
                                      id: extension.color.inner.id,
                                      RAL: extension.color.inner.RAL,
                                      code: extension.color.inner.code,
                                      name: TranslateService.translate(
                                          translatedData,
                                          extension.color.inner,
                                          'WindowColor',
                                          'name',
                                          this.langCode
                                      ),
                                  }
                                : null,
                        outer:
                            extension.color && extension.color.outer && extension.color.outer.id
                                ? {
                                      id: extension.color.outer.id,
                                      RAL: extension.color.outer.RAL,
                                      code: extension.color.outer.code,
                                      name: TranslateService.translate(
                                          translatedData,
                                          extension.color.outer,
                                          'WindowColor',
                                          'name',
                                          this.langCode
                                      ),
                                  }
                                : null,
                    },
                });
            });
        }

        if (conf.windowSills && conf.windowSills.length) {
            details.windowSills = {
                price: priceFromSegments(prices, priceParts, currency, {
                    value: { type: 'complementaryGoods', data: { good: 'windowsill' } },
                }),
                elems: [],
            };

            for (let i = 0; i < conf.windowSills.length; i++) {
                details.windowSills.elems.push(
                    Common.extend(core.copy(conf.windowSills[i]), {
                        name: TranslateService.translate(
                            translatedData,
                            conf.windowSills[i],
                            'WindowSill',
                            'name',
                            this.langCode
                        ),
                        price: priceFromSegments(prices, priceParts, currency, {
                            value: {
                                type: 'complementaryGoods',
                                data: {
                                    id: Number(conf.windowSills[i].id),
                                    good: 'windowsill',
                                    currentLength: conf.windowSills[i].length,
                                    currentWidth: conf.windowSills[i].width,
                                    plugs: conf.windowSills[i].plugs || false,
                                },
                            },
                        }),
                    })
                );
            }
        }

        return details;
    }

    getComplementaryGoodsImg(conf) {
        let image = null;
        if (
            this.complementaryGoods.reduce(
                (prev, complementaryGood) => prev + conf[complementaryGood].length,
                0
            ) === 1
        ) {
            const complementaryGood = this.complementaryGoods.find(el => conf[el].length);
            image = conf[complementaryGood][0].image;
        }
        return image;
    }

    getFirstSideProfileById(sideProfileId: number, conf: CoupledWindowConfiguration) {
        const lastProfiles = conf.sideProfiles.filter(s => s.id === sideProfileId);
        const profiles = lastProfiles
            .map(s =>
                s.adjacentSideProfileId.length > 0 ? this.getBackProfiles(s, conf.sideProfiles) : s
            )
            .reduce<SideProfile[]>((acc, val) => acc.concat(val), []);
        return profiles[0];
    }

    getLastSideProfileById(sideProfileId: number, conf: CoupledWindowConfiguration) {
        const lastProfiles = conf.sideProfiles.filter(s => s.id === sideProfileId);
        const profiles = lastProfiles
            .map(s =>
                s.adjacentOtherSideProfileId.length > 0
                    ? this.getNextProfiles(s, conf.sideProfiles)
                    : s
            )
            .reduce<SideProfile[]>((acc, val) => acc.concat(val), []);
        return profiles[0];
    }

    private getBackProfiles(baseProfile: SideProfile, sideProfiles: SideProfile[]): SideProfile[] {
        const backProfiles = sideProfiles.filter(s =>
            baseProfile.adjacentSideProfileId.includes(s.id)
        );
        const allBackProfiles = backProfiles
            .map(s =>
                s.adjacentSideProfileId.length > 0 ? this.getBackProfiles(s, sideProfiles) : s
            )
            .reduce<SideProfile[]>((acc, val) => acc.concat(val), []);
        return allBackProfiles;
    }

    private getNextProfiles(baseProfile: SideProfile, sideProfiles: SideProfile[]): SideProfile[] {
        const backProfiles = sideProfiles.filter(s =>
            baseProfile.adjacentOtherSideProfileId.includes(s.id)
        );
        const allBackProfiles = backProfiles
            .map(s =>
                s.adjacentOtherSideProfileId.length > 0 ? this.getNextProfiles(s, sideProfiles) : s
            )
            .reduce<SideProfile[]>((acc, val) => acc.concat(val), []);
        return allBackProfiles;
    }

    getCustomTextForConstructionSize(sideProfiles, width: number, height: number) {
        const dimension = this.getSideProfilesSizes(sideProfiles);
        let leftSide = '', rightSide = '', bottomSide = '', topSide = '';
        const dimensionUnit = this.config?.dimensionUnit ?? this.config?.dimension_unit;
        const numberOfPlacesAfterDecimalSeparator = this.config?.numberOfPlacesAfterDecimalSeparator;
        sideProfiles.forEach((p) => {
            if (p.dimensions?.width > 0 && ParserService) {
                switch (p.side) {
                    case 'left':
                        leftSide = ParserService.dimensionUnit(dimension.leftSide, dimensionUnit, this.IccConfig, numberOfPlacesAfterDecimalSeparator) + ' + ';
                        break;
                    case 'right':
                        rightSide = ' + ' + ParserService.dimensionUnit(dimension.rightSide, dimensionUnit, this.IccConfig, numberOfPlacesAfterDecimalSeparator)
                        break;
                    case 'bottom':
                        bottomSide = ParserService.dimensionUnit(dimension.bottomSide, dimensionUnit, this.IccConfig, numberOfPlacesAfterDecimalSeparator) + ' + ';
                        break;
                    case 'top':
                        topSide = ' + ' + ParserService.dimensionUnit(dimension.topSide, dimensionUnit, this.IccConfig,numberOfPlacesAfterDecimalSeparator);
                        break;
                }
            }
        });
        if (ParserService) {
            if(leftSide !== '' || rightSide !== '' || bottomSide !== '' || topSide !== '') {
                return (
                    '(' + leftSide +
                    ParserService.dimensionUnit(width, dimensionUnit, this.IccConfig, numberOfPlacesAfterDecimalSeparator) +
                    rightSide +
                    ') x (' +
                    bottomSide +
                    ParserService.dimensionUnit(height, dimensionUnit, this.IccConfig, numberOfPlacesAfterDecimalSeparator) +
                    topSide + ')'
                );
            } else {
                return ParserService.dimensionUnit(width, dimensionUnit, this.IccConfig, numberOfPlacesAfterDecimalSeparator)
                        + ' x '
                        + ParserService.dimensionUnit(height, dimensionUnit, this.IccConfig, numberOfPlacesAfterDecimalSeparator)
            }
        }
    }

    getSideProfilesSizes(sideProfiles): Dimension {
        const dimension: Dimension = { leftSide: 0, rightSide: 0, bottomSide: 0, topSide: 0 };

        sideProfiles.forEach((p) => {
            if (p.dimensions?.width > 0) {
                switch (p.side) {
                    case 'left':
                        dimension.leftSide += +p.dimensions.width;
                        break;
                    case 'right':
                        dimension.rightSide += p.dimensions.width;
                        break;
                    case 'bottom':
                        dimension.bottomSide += p.dimensions?.height;
                        break;
                    case 'top':
                        dimension.topSide += p.dimensions?.height;
                        break;
                }
            }
        });

        return dimension;
    }


    isSideProfileSelected(conf): boolean {
        if (conf?.sideProfiles?.length > 0) {
            return true;
        } else {
            return false;
        }
    }
}

export interface Dimension {
    leftSide: number;
    rightSide: number;
    bottomSide: number;
    topSide: number;
}
