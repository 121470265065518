import angular from 'angular';
import { OffersService } from '@icc/legacy/panel/offers.service';
import { PositionService } from '@icc/common/offers/PositionService';
import { logger } from '@icc/common/helpers';

/**
 * Funkcja do kontrolera aplikacji
 * @param {[type]} $scope              scope
 * @param {[type]} $rootScope          rootScope
 * @param {[type]} $window             window
 * @param {[type]} $location           location
 * @param {[type]} $sce                sce
 * @param {[type]} InfoService         InfoService
 * @param {[type]} OffersFactory       OffersFactory
 * @param {[type]} PositionsFactory    PositionsFactory
 * @param {[type]} SyncWorkerFactory   SyncWorkerFactory
 * @param {[type]} SynchronizeService  SynchronizeService
 * @param {[type]} OnlineStatusService OnlineStatusService
 * @param {[type]} UserService         UserService
 * @param {[type]} IccConfig           IccConfig
 * @param {[type]} Elements            Elements
 * @param {[type]} Langs               Langs
 * @param {[type]} CurLang             CurLang
 *
 */
export default function AppCtrl(
    $scope,
    $rootScope,
    $window,
    $http,
    $location,
    $sce,
    $cookies,
    InfoService,
    OffersFactory,
    SyncWorkerFactory,
    SynchronizeService,
    OnlineStatusService,
    UserService,
    IccConfig,
    Elements,
    Langs,
    isSafari,
    EnvConfig,
    PdfService,
    EventBusService,
    StateService,
    ProjectName,
    AuthService,
    isElectron,
    ConfiguratorsDataService,
    ConfiguratorsAvailabilityService,
    CurConfService,
    PriceService,
    $translate,
    tmhDynamicLocale,
    LangService,
    hotkeys,
    machine,
    DatabaseManager,
    ConfigurationsService,
    isPhonegap,
    isNative,
    domain,
    theme,
    metaTagData,
    Core
) {
    'ngInject';

    $rootScope.IccConfig = IccConfig;
    $rootScope.EnvConfig = EnvConfig;
    $rootScope.Elements = parseElements(Elements);
    $rootScope.text = false;
    $rootScope.showFix = false;
    $rootScope.mistakeProductLoaded = false;
    $rootScope.user = UserService.get();
    $rootScope.offerModuleAccess = UserService.offerModuleAccess;
    $rootScope.hideDraw = false;
    $rootScope.langs = Langs;
    $rootScope.langsDictionary = (Langs || []).reduce((prev, l) => {
        prev[l.lang] = l.name;
        return prev;
    }, {});
    $rootScope.ProjectName = ProjectName;
    $rootScope.isElectron = isElectron;
    $rootScope.isNative = isNative;
    $rootScope.isPhonegap = isPhonegap;
    $rootScope.curLang = LangService.getLang();
    $rootScope.userLogState = false;
    $rootScope.userLogs = logger.userLogs;
    $rootScope.path = '';
    $rootScope.defaultUrl = domain;

    $rootScope.hideInfo = InfoService.hideInfo.bind(InfoService);
    $rootScope.showInfo = InfoService.showInfo.bind(InfoService);
    $rootScope.checkOnline = OnlineStatusService.goOnline.bind(OnlineStatusService);
    $rootScope.switchLanguage = switchLanguage;
    $rootScope.toggleUserLog = toggleUserLog;
    $rootScope.refresh = refresh;
    $rootScope.themeLogo = theme && theme.themeLogo;
    $rootScope.themeFavicon = theme && theme.favicon;

    $scope.quickOffer = OffersFactory.quickOffer;
    $scope.clientOffer = OffersFactory.clientOffer;
    $scope.oneClientOffer = OffersFactory.oneClientOffer;
    $scope.openPdf = PdfService.openPdf.bind(PdfService);
    // $scope.loginAction = `${CurLang}/logowanie`;
    $scope.loginAction = `user/users/login`;
    $scope.login = login
    $scope.logout = AuthService.logout.bind(AuthService);
    $scope.loginPending = false;
    $scope.validMessage = '';
    $scope.hasShop = IccConfig.Modules.eCommerce && ($rootScope.user.access == 'dealer' || $rootScope.user.access == 'dealerh') && $rootScope.user.marketHasStore;
    $scope.canCreateDomainOrSubdomain = ($rootScope.user.access == 'dealer') && ($rootScope.user.dealer?.can_create_domain || $rootScope.user.dealer?.can_create_subdomain);

    if (sessionStorage.getItem('text')) {
        $rootScope.showInfo(sessionStorage.getItem('text'));
    }

    if (!isElectron) {
        if (window.navigator.platform.includes('Mac')) {
            $scope.appDownloadLink = `/updates/mac/ic-complex-b2b-${$rootScope.ProjectName}-setup-${IccConfig.Version}.dmg`;
        } else if (window.navigator.platform.includes('Win')) {
            $scope.appDownloadLink = `/updates/win/ic-complex-b2b-${$rootScope.ProjectName}-setup-${IccConfig.Version}.exe`;
        }
    }

    isMozzillaPrivateMode().then(isMozzillaPrivate => {
        if (isMozzillaPrivate) {
            alert(
                $translate.instant(
                    'SYNCHRONIZE|Sprawdź czy masz miejsce na dysku lub przeglądarka nie jest włączona w trybie incognito.'
                )
            );
        }
    });

    curPathWithoutLang();

    if (isElectron) {
        const nextLocation = window.ipc.sendSync('get-location');
        if (nextLocation) {
            $location.url(nextLocation);
        }

        hotkeys.bindTo($scope).add({
            combo: 'mod+9',
            description: 'Narzędzia deweloperskie',
            callback: () => {
                window.ipc.send('toggle-devtools');
            },
        });

        window.ipc.on('translate', (event, ...args) => {
            window.ipc.send('translate', $translate.instant(...args));
        });
    }
    const subscriptions = [];
    subscriptions.push(
        EventBusService.subscribeWithoutConfiguration('isUser', data => {
            const oldOfferModuleAccess = $rootScope.offerModuleAccess;
            const oldUserId = $rootScope.user.id;
            $rootScope.user = data.value;
            $rootScope.offerModuleAccess = UserService.offerModuleAccess;
            $scope.hasShop = IccConfig.Modules.eCommerce && ($rootScope.user.access == 'dealer' || $rootScope.user.access == 'dealerh') && $rootScope.user.marketHasStore;
            logger.setUser($rootScope.user);
            if (
                oldUserId
                && $rootScope.user.id
                && !oldOfferModuleAccess
                && $rootScope.offerModuleAccess
            ) {
                $location.path('/app/sync');
            }
            if (oldUserId !== $rootScope.user.id) {
                $location.path('/app/sync');
            }
        })
    );

    subscriptions.push(
        EventBusService.subscribeWithoutConfiguration('changedState', data => {
            $rootScope.state = data.value;
        })
    );
    subscriptions.push(
        EventBusService.subscribeWithoutConfiguration('changedLang', data => {
            $rootScope.curLang = data.value.lang;
            $translate.use(data.value.lang);
            tmhDynamicLocale.set(data.value.lang);
        })
    );

    subscriptions.push(
        EventBusService.subscribeWithoutConfiguration(['synced'], eventData => {
            const data = eventData.value;
            if (data.elements.indexOf('Offer') > -1) {
                const syncedCurrentOffer =
                    angular.isObject(StateService.state.offer)
                    && data.Offer.filter(row => row.tmp_id === StateService.state.offer.tmp_id);
                if (syncedCurrentOffer && syncedCurrentOffer.length) {
                    if (
                        StateService.state.offer
                        && StateService.state.offer.revision
                        && syncedCurrentOffer[0].revision
                        && !StateService.state.offer.saving
                    ) {
                        const dbRevision = JSON.parse(StateService.state.offer.revision);
                        const docRevision = JSON.parse(syncedCurrentOffer[0].revision);
                        const actual = (
                            !docRevision[getMachineId($rootScope.user)]
                            || (dbRevision[getMachineId($rootScope.user)]
                                && docRevision[getMachineId($rootScope.user)]
                                && dbRevision[getMachineId($rootScope.user)] === docRevision[getMachineId($rootScope.user)])
                        );
                        if (actual) {
                            Object.assign(
                                StateService.state.offer,
                                OffersService.parseOfferValues(syncedCurrentOffer[0])
                            );
                        }
                    }

                }
            }
            if (data.elements.indexOf('Position') > -1) {
                const syncedCurrentOfferPositions =
                    angular.isObject(StateService.state.offer)
                    && data.Position.filter(
                        row => row.dealer_offer_id === StateService.state.offer.tmp_id
                    );
                if (
                    syncedCurrentOfferPositions
                    && syncedCurrentOfferPositions.length
                    && StateService.state.positions
                    && StateService.state.positions.length
                ) {
                    if (syncedCurrentOfferPositions) {
                        syncedCurrentOfferPositions.forEach(position => {
                            const positionFromState = StateService.state.positions.find(
                                row => row.doc.tmp_id === position.tmp_id
                            );
                            if (positionFromState) {
                                Object.assign(
                                    positionFromState.doc,
                                    PositionService.parsePositionValues(position)
                                );
                            }
                        });
                    }
                }
            }
        })
    );

    subscriptions.push(
        EventBusService.subscribeWithoutConfiguration('updatedPrice', data => {
            if (!$rootScope.$$phase) {
                $rootScope.$apply();
            }
        })
    );

    const broadcastSubscriptions = [];
    broadcastSubscriptions.push(
        $rootScope.$on('$routeChangeStart', (e, next, current) => {
            routeChange();
        })
    );
    broadcastSubscriptions.push(
        $rootScope.$on('$routeChangeStart', (e, next, current) => {
                document.title = metaTagData.pageTitle + " " + metaTagData.pageTitleSuffix;
        })
    );

    $scope.$on('$destroy', () => {
        subscriptions.map(el => el.unsubscribe());
        broadcastSubscriptions.map(el => el());
    });

    $scope.$on('$locationChangeStart', (event, next, old) => {
        if (
            next.search(/\/app\//) > -1
            && old.search(/\/app\//) === -1
            && old.search(/\/app$/) === -1
            && old.search(/\/application\/dist\/web\/index\.html$/) === -1
        ) {
            if (isElectron) {
                window.ipc
                    && window.ipc.sendSync(
                        'save-location',
                        next.replace($location.protocol() + '://' + $location.host(), '')
                    );
            }
            $window.location.href = next;
        }
    });

    $rootScope.backToVisualizer = function(url) {
        $window.location.pathname = url;
    };

    $rootScope.redirectNoUpdate = function(url) {
        $location.url(url);
    };

    $scope.goBack = function() {
        $window.history.back();
    };

    /**
     * Przewija stronę do elementu o podanym ID
     * @param {string} [anchor] id elementu
     */
    $rootScope.goTo = function(anchor) {
        setTimeout(() => {
            var element = document.getElementById(anchor);
            if (element && element.offsetParent !== null) {
                window.scrollTo(0, element.offsetTop);
            }
        }, 20);
    };

    /**
     * Przekierowanie do konfiguratora admina przy dodawaniu produktów z pomyłek
     */
    $scope.redirectToAdminConfigurator = function() {
        window.location.pathname = 'app/window';
    };

    $scope.openShopInfoModal = elem => {
        const modalContent = $rootScope.Elements[elem];
        InfoService.openInfoModal({
            ownTemplateUrl: 'shopRegulationsInfo.html',
            title: modalContent.name,
            message: $sce.getTrustedHtml(modalContent.content),
        });
    };

    $scope.downloadApp = () => $window.open($scope.appDownloadLink, '_blank');

    routeChange();

    setTimeout(() => {
        navigator.splashscreen && navigator.splashscreen.hide();
    }, 2000);

    /**
     * Tworzy html z tresci elementow dodatkowych
     * @param  {object} source Object z elementami dodatkowymi
     * @return {object}        Poprawiny objekt
     */
    function parseElements(source) {
        var elements = {};
        for (var i in source) {
            if (typeof source[i].content === 'string') {
                elements[i] = angular.extend(source[i], {
                    content: $sce.trustAsHtml(source[i].content),
                });
            }
        }
        return elements;
    }

    function routeChange() {
        logger.log('PATH', $location.path(), $location.path().split('/')[2]);
        const b2cActive = IccConfig.Offer.B2C.active && !isElectron;
        const path = $location.path().split('/')[2];
        $rootScope.path = path;
        if (angular.isUndefined(path)) {
            $rootScope.place = 'visualizer';
            SynchronizeService.synchronizeLiveUser();
        } else if (
            path === 'sync'
            || path === 'update'
            || path === 'license'
            || path === 'offline'
        ) {
            $rootScope.place = 'sync';
        } else if (path === 'login' && !b2cActive) {
            $rootScope.place = 'login';
        } else if (path === 'login' && b2cActive) {
            $rootScope.place = 'visualizer';
            SynchronizeService.synchronizeLiveUser();
        } else {
            $rootScope.place = 'configurator';
            SynchronizeService.synchronizeLiveUser();
        }

        $rootScope.newConfigurator = path ==='new_configurator';

        if (
            angular.isDefined(path)
            && path !== 'sync'
            && path !== 'update'
            && path !== 'offline'
            && (path !== 'login' || b2cActive)
            && $location.path() !== '/application/dist/web/index.html'
        ) {
            SyncWorkerFactory.startSyncWorker();
        }

        if (ConfiguratorsAvailabilityService.configs.indexOf(path) > -1) {
            PriceService.syncingPrice = true;
            PriceService.syncPrice();
        } else {
            PriceService.syncingPrice = false;
        }
    }

    function curPathWithoutLang() {
        var path = $location.path().split('/');
        $rootScope.curPath = '';
        if (path[2] == 'user' || path[2] == 'users') {
            if (Langs.map(l => l.lang).indexOf(path[1]) > -1) {
                path.splice(1, 1);
                $rootScope.curPath = path.join('/');
            }
        }
    }

    function switchLanguage(lang) {
        if (window.elements && window.elements[lang]) {
            const elementsData = {};
            Object.keys(window.elements[lang]).forEach(key => {
                if (
                    window.elements[lang][key].markets
                    && window.elements[lang][key].content
                    && window.elements[lang][key].markets.includes(
                        $rootScope.user.marketId.toString()
                    )
                ) {
                    elementsData[key.split('_')[1]] = window.elements[lang][key];
                }
            });
            $rootScope.Elements = parseElements(elementsData);
        }
        if ($location.path().includes('/app/')) {
            LangService.changeLangOnline(lang);
        } else {
            $window.location.href = `/${lang + $rootScope.curPath}`;
        }
    }

    function toggleUserLog() {
        $rootScope.userLogState = !$rootScope.userLogState;
    }

    function refresh() {
        window.location.href = '/';
    }

    function isMozzillaPrivateMode() {
        return new Promise(resolve => {
            if ('MozAppearance' in document.documentElement.style) {
                const db = indexedDB.open('test');
                db.onerror = () => resolve(true);
                db.onsuccess = () => resolve(false);
                return void 0;
            }
            resolve(false);
        });
    }

    function login(email, password) {
        $scope.loginPending = true;
        $scope.validMessage = '';
        AuthService.login(email, password)
            .then(() => {
                $scope.loginPending = false;
                $scope.validMessage = AuthService.getValidMessage();
            })
            .catch(() => $scope.loginPending = false);
    }

    function getMachineId(user) {
        return `${user.id|| ''}:${machine}`;
    }
}
