import { Component, OnInit, Inject } from '@angular/core';
import {
    _,
    StepComponent,
    SharedFacade,
    PageComponent,
    ModalService,
    ICC_PAGE_DATA,
    isNotNullOrUndefined,
} from '@icc/configurator/shared';
import { iccListItem } from '@icc/configurator/ui';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { ConfigurationsService } from '@icc/common';
import { IccHandle } from '@icc/common/data-types/Handle.ts';
import { IccAccessoryColor, IccSashType } from '@icc/common/data-types';
import { HandlesOptionsPageComponent } from '../../handles-options-page/handles-options-page.component';
import { ActiveSash } from '@icc/common/layout/active-sash';

interface iccHandleListItem extends iccListItem {
    colors: {
        id: number;
        name: string;
        color: string | null;
        colorImg: string | null;
    }[];
    selectedColor: number;
    showColorSelection: boolean;
}

@Component({
    selector: 'icc-handles-page',
    templateUrl: './handles-page.component.html',
    styleUrls: ['./handles-page.component.scss'],
})
export class HandlesPageComponent extends PageComponent implements OnInit {
    public title = _('WINDOW|Wybierz klamkę');
    public options = [];

    handles: iccHandleListItem[] = [];
    selectedHandleId = 0;

    constructor(
        private sharedFacade: SharedFacade,
        private modalService: ModalService,
        @Inject(ICC_PAGE_DATA)
        private pageData: {
            handles: IccHandle[];
            handleColors: IccAccessoryColor[];
            selHandle: IccHandle;
            selHandleColor: IccAccessoryColor;
            selHandleOuterColor: IccAccessoryColor;
            availColorOuter: boolean;
            showHeightSelect: boolean;
            handleHeights: {
                name: string;
                height: number;
            }[];
            loweredHandleHeight: {
                name: string;
                height: number;
            };
            minHandleHeight: number;
            maxHandleHeight: number;
            handleHeightType: ActiveSash['handleHeightType'];
            sashHeight: number;
            sashType: IccSashType['type'];
            position: IccSashType['handle_position'];
            systemHeight: boolean;
            handleY: number;
        }
    ) {
        super();
    }

    ngOnInit() {
        this.handles = this.pageData.handles.map(handle => {
            const selectedColor =
                (handle.colors_ids
                    && ((this.pageData.selHandleColor
                        && Number(
                            handle.colors_ids.find(
                                id => Number(this.pageData.selHandleColor.id) === Number(id)
                            )
                        ))
                        || Number(handle.colors_ids[0])))
                || 0;
            return {
                id: Number(handle.id),
                title: handle.name,
                description: handle.description,
                imageUrl: this.getImageUrl(handle, selectedColor),
                colors:
                    handle.colors_ids
                    && handle.colors_ids
                        .map(c =>
                            this.pageData.handleColors.find(color => Number(c) === Number(color.id))
                        )
                        .filter(isNotNullOrUndefined)
                        .map(color => ({
                            id: Number(color.id),
                            name: color.name,
                            color: color.color,
                            colorImg: color.color_img,
                        })) || [],
                selectedColor,
                showColorSelection: false,
            };
        });

        this.selectedHandleId = Number(this.pageData.selHandle.id);
    }

    private getImageUrl(handle: IccHandle, selectedColor: number): string {
        return handle.colors_imgs && handle.colors_imgs[selectedColor]
            ? '/files/windowhandlescolorswindowaccessory/' + handle.colors_imgs[selectedColor]
            : '/files/windowaccessory/' + handle.img;
    }

    selectHandle(item: iccHandleListItem) {
        const handle = this.pageData.handles.find(f => Number(f.id) === Number(item.id));
        this.modalService.open({
            pageComponent: HandlesOptionsPageComponent,
            resolve: {
                selectedHandle: handle,
                handleColors: this.pageData.handleColors,
                selHandleColor: this.pageData.selHandleColor,
                selHandleOuterColor: this.pageData.selHandleOuterColor,
                availColorOuter: this.pageData.availColorOuter,
                showHeightSelect: this.pageData.showHeightSelect,
                handleHeights: this.pageData.handleHeights,
                loweredHandleHeight: this.pageData.loweredHandleHeight,
                minHandleHeight: this.pageData.minHandleHeight,
                maxHandleHeight: this.pageData.maxHandleHeight,
                handleHeightType: this.pageData.handleHeightType,
                sashHeight: this.pageData.sashHeight,
                sashType: this.pageData.sashType,
                position: this.pageData.position,
                systemHeight: this.pageData.systemHeight,
                handleY: this.pageData.handleY,
            }
        }).result.then((result) => {
            this.sharedFacade.closePage(Object.assign(result, {
                handle,
                category: null,
                subcategory: null
            }));
        })
    }
}
