import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { StepComponent, _ } from '@icc/configurator/shared';
import { EventBusService, APP_CONFIG, AppConfigFactory } from '@icc/common';
import { RollerDimensionsService } from '@icc/legacy/configurator/steps/roller_shutter/dimensions.service';
import { ShutterFacade } from '../+state/shutter.facade';
import { iccListItem } from '@icc/configurator/ui';
import { map } from 'rxjs/operators';
import { SchemasService } from '@icc/legacy/configurator/steps/roller_shutter/schemas.service';
import { Subscription } from 'rxjs';
import { ColorsService } from '@icc/legacy/configurator/steps/roller_shutter/colors.service';

@Component({
  selector: 'icc-systems',
  templateUrl: './systems.component.html',
  styleUrls: ['./systems.component.scss']
})
export class SystemsComponent extends StepComponent implements OnInit, OnDestroy {
  static stepName = _('ROLLERSHUTTER|System');
  static stepIcon = {
      ligature: 'apps',
  };

  public configurator = 'roller_shutter';
  public stepId = 'system';
  public title = _('ROLLERSHUTTER|System');

  systems: iccListItem[] = [];
  selectedSystem$ = this.shutterFacade.selectedSystem$;
  shutters$ = this.shutterFacade.shutters$;
  letters = ['A', 'B', 'C'];

  selectedConfiguratorType$ = this.shutterFacade.selectedConfiguratorType$;
  profile$ = this.shutterFacade.profile$;
  mosquito$ = this.shutterFacade.mosquito$;
  
  private subscriptions: Subscription[] = [];

  constructor(
      @Inject(APP_CONFIG) private config: AppConfigFactory,
      private eventBusService: EventBusService,
      private rollerDimensionsService: RollerDimensionsService,
      private shutterFacade: ShutterFacade,
      private schemasService: SchemasService,
      private colorsService: ColorsService,
  ) {
    super();
    if (this.schemasService.loadedData) {
        this.init();
    }
    this.subscriptions.push(
        this.eventBusService.subscribeWithoutConfiguration('initializedConfigurator', () =>
            this.init()
        )
    );
  }

  ngOnInit() {}

  ngOnDestroy() {
    this.subscriptions.map(el => el.unsubscribe());
  }

  private init() {

  }

  changeSystem() {
    this.schemasService.openModalSystems();
  }

  /**
   * Funkcja ustawia profil
   */
  changeProfile() {
    this.rollerDimensionsService.selectProfile();
    if (!this.config().IccConfig.Configurators.roller_shutter.autoProfileSelection) {
        this.colorsService.init();
    }
  }

  toggleMosquito(value: boolean, index: number) {
    this.rollerDimensionsService.toggleMosquito(value, index);
  }

}
