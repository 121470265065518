import { Injectable, Inject } from '@angular/core';
import { WindowActiveConfiguration } from '@icc/common/configurations/WindowActiveConfiguration';
import { EventBusService } from '@icc/common/event-bus.service';
import { BrowserFramesService } from '@icc/legacy/configurator/layout/frames.service';
import { APP_CONFIG, AppConfig, AppConfigFactory } from '@icc/common/config';
import { StateService } from '@icc/common/state.service';
import { Common } from '@icc/common/Common';
import { AlignmentsService } from '@icc/legacy/configurator/layout/alignments.service';
import { ExtensionsService } from '@icc/legacy/configurator/layout/extensions.service';
import { ProfileType, Profile, SideProfile, Reinforcement } from '@icc/window';
import { ActiveSash } from '@icc/common/layout/active-sash';
import { CoupledWindowActiveConfiguration } from '@icc/common/configurations/CoupledWindowActiveConfiguration';
import { WindowConfiguration } from '@icc/common/configurations/WindowConfiguration';
import { iccSideColorsToSideColors, sideColorsV2ToSideColors } from '@icc/common/configurations/converters/window/colors';
import { IccSideColors } from '@icc/common/data-types';
import { ModalService } from '@icc/helpers';
import { BrowserProfilesService } from './profiles.service';
import { ProfilesListPageComponent } from './profiles-list-page/profiles-list-page.component';
import { core } from '@icc/common';
import { ProfilesPriceService } from '@icc/common/profiles-price.service';
import { MullionsService } from '@icc/common/profiles/mullions.service';

@Injectable()
export class ProfilesModalService {

    constructor(
        private eventBusService: EventBusService,
        private framesService: BrowserFramesService,
        private profilesService: BrowserProfilesService,
        private profilesPriceService: ProfilesPriceService,
        private stateService: StateService,
        private alignmentsService: AlignmentsService,
        private extensionsService: ExtensionsService,
        protected mullionsService: MullionsService,
        @Inject(APP_CONFIG) private config: AppConfigFactory,
        private modalService: ModalService
    ) {
    }

    async openProfilesModal(
        conf: WindowActiveConfiguration | WindowConfiguration,
        type: ProfileType | ProfileType[] | null,
        options: { and?: string[]; not?: string[] } | { and?: string[]; not?: string[] }[],
        selectedProfile: Profile | null,
        color?: Partial<IccSideColors>,
        wood?: SideProfile['wood'],
        reinforcement?: Reinforcement,
        overwritePrice: boolean = true,
        side?: 'left' | 'right' | 'top' | 'bottom'
    ): Promise<Profile> {

        const systemId = conf
            ? WindowConfiguration.is(conf)
                ? conf.system
                : Number(conf.System.id)
            : null;
        const pricesForLength = this.profilesService.profilesPricesForLength;
        const profiles = this.profilesService
            .getFilteredProfiles(conf, type, options, false)
            .map(profile => {
                if (side && profile.type === "extension") {
                    profile.side = side;
                }
                if (profile.type !== 'threshold') {
                    this.profilesService.setProfileDefaultColors(
                        profile,
                        selectedProfile,
                        color || {},
                        wood,
                        WindowConfiguration.is(conf) ? conf.dictionary.systems[systemId] : conf.System
                    );
                }
                let profileOptions: {
                    and?: string[];
                    not?: string[];
                } = {
                    and: [],
                    not: [],
                };
                if (Common.isArray(options) && Common.isArray(type)) {
                    profileOptions = options[type.indexOf(profile.type)];
                } else if (!Common.isArray(options) && !Common.isArray(type)) {
                    profileOptions = options;
                }
                const priceType = this.profilesService.getProfilePriceType(
                    profile.type,
                    profileOptions
                );
                if (profile.type !== 'threshold') {
                    let extensionLength;
                    if (profile.type === 'extension' && this.config().IccConfig.Configurators.extensionPricesForLength) {
                        const extensionSide = (Array.isArray(options) && options[0]?.and?.every(o => o === 'side')) || (!Array.isArray(options) && Array.isArray(options.and) && options.and.every(o => o === 'side')) ? 'vertical' : 'horizontal';
                        extensionLength = this.profilesService.getExtensionLength(extensionSide);
                    }
                    if (overwritePrice){
                        profile.price = systemId
                            ? this.profilesPriceService.getProfilePrice(
                                profile.id,
                                priceType,
                                WindowConfiguration.is(conf) ? conf.dictionary.systems[systemId] : conf.System,
                                profile.selectedColor && profile.selectedColor.frame
                                    ? profile.selectedColor
                                    : WindowConfiguration.is(conf)
                                    ? conf.colors
                                    : conf.Colors,
                                this.profilesService.profilesPrices,
                                null,
                                pricesForLength,
                                extensionLength
                            )
                            : null;
                    }
                } else {
                    const thresholdLength = this.profilesService.getThresholdLength();
                    profile.price = systemId
                        ? this.profilesPriceService.getThresholdPrice(
                            profile.id,
                            'threshold',
                            WindowConfiguration.is(conf) ? conf.dictionary.systems[systemId] : conf.System,
                            this.profilesService.profilesPrices,
                            null,
                            pricesForLength,
                            thresholdLength
                        )
                        : null;
                }
                return profile;
            });

        const modalInstance = this.modalService.open({
            component: 'profilesModal',
            pageComponent: ProfilesListPageComponent,
            resolve: {
                profiles: () => profiles,
                profilesPrices: () => this.profilesService.profilesPrices,
                system: () => (WindowConfiguration.is(conf) ? conf.dictionary.systems[systemId] : conf.System),
                type: () => type,
                selectedProfile: () => selectedProfile,
                currency: () =>
                    this.config().currency || this.stateService.state.offers[0].doc.currency,
                color: () => color,
                wood: () => wood,
                hideCouplings: () => WindowConfiguration.is(conf) ? conf.sashesType !== "Fix" : conf.SashesType !== "Fix",
                reinforcement: () => reinforcement,
                profileCategories: () => this.profilesService.getProfileCategories()
            },
        });

        return modalInstance.result;
    }

    selectAlignmentAndPut(
        field: ActiveSash,
        conf: WindowActiveConfiguration,
        side: 'top' | 'bottom' | 'left' | 'right'
    ) {
        let alignmentType = 'alignment_frame';
        const frame =
            field.frameId != null ? BrowserFramesService.getFrameById(field.frameId, conf) : null;
        if (Common.isNumber(field.parentId)) {
            alignmentType = 'alignment_sash';
        } else if (
            side === 'bottom'
            && field.nearAlignments.bottom === -1
            && field.nearAlignments.left === -1
            && field.nearAlignments.right === -1
            && field.nearMullions.bottom === -1
            && frame
            && frame.lowThreshold
        ) {
            alignmentType = 'alignment_threshold';
        }
        const options = {
            and: [alignmentType],
            not: [],
        };

        this.openProfilesModal(conf, null, options, null, conf.Colors.frame).then(
            selectedProfile => {
                if (selectedProfile) {
                    this.alignmentsService.putAlignmentInField(selectedProfile, field, conf, side);
                    if (this.config().IccConfig.Configurators.dependencies) {
                        this.eventBusService.post({ key: 'processDependencies', value: null });
                    }
                }
            }
        );
    }

    selectExtensionAndPut(
        conf: WindowActiveConfiguration,
        side: 'top' | 'bottom' | 'left' | 'right'
    ) {
        const options = [
            {
                and: [(side === 'left' || side === 'right') ? 'side' : side],
                not: [],
            },
            {
                and: [],
                not: [],
            },
        ];

        this.openProfilesModal(
            conf,
            ['extension', 'sandwich'],
            options,
            null,
            conf.Colors.frame,
            undefined,
            undefined,
            true,
            side
        ).then(profile => {
            if (profile) {
                this.extensionsService.putExtensionOnSide(conf, profile, side);
                if (this.config().IccConfig.Configurators.dependencies) {
                    this.eventBusService.post({ key: 'processDependencies', value: null });
                }

                this.eventBusService.post({
                    key: 'putExtensionOnSide',
                    value: {},
                });
            }
        });
    }

    async selectProfileAndPut(
        conf: WindowActiveConfiguration | WindowConfiguration,
        type: ProfileType
    ) {
        const options = [
            {
                and: [],
                not: [],
            },
            {
                and: [],
                not: [],
            },
        ];

        const selectedProfile = await this.openProfilesModal(
            conf,
            [type],
            options,
            null,
            WindowConfiguration.is(conf)
                ? sideColorsV2ToSideColors(
                    conf.dictionary.colors,
                    conf.colors.frame,
                    this.config().CurLang
                )
                : iccSideColorsToSideColors(conf.Colors.frame)
        );
        return selectedProfile;
    }

    // eslint-disable-next-line max-statements
    changeProfile(
        conf: WindowActiveConfiguration,
        defaultConf: WindowActiveConfiguration,
        type: any,
        {
            position = null,
            frame = null,
            sash = null,
            mullion = null,
            extension = null,
            coupling = null,
        }: {
            position?: any;
            frame?: any;
            sash?: any;
            mullion?: any;
            extension?: any;
            coupling?: any;
        }
    ) {
        frame = frame && conf.Frames.find(el => el.id === frame.id);
        const changeAllProfiles =
            position === 'all' && (type !== 'frame' || (frame && !frame.lowThreshold));
        const changeProfilesWithoutThreshold =
            (position === 'all' || position === 'allFrames')
            && type === 'frame'
            && frame
            && frame.lowThreshold;
        const changeAllFrames = position === 'allFrames' && type === 'frame';
        let color;
        let wood;
        let profileId;
        let options;
        let reinforcement;
        if (type === 'mullion') {
            mullion = this.getMullion(conf, mullion.id);
            profileId = mullion.profileId;
        } else if (type === 'sashFrame') {
            sash = this.getSash(conf, sash.id);
            const sashFrameData = conf.drawData.sashFrame.find((s: any) => s.sashId === sash.id);
            const side =
                (sashFrameData
                    && sashFrameData.sides[position]
                    && sashFrameData.sides[position].outerEdge.side)
                || 'bottom';
            position = changeAllProfiles ? 'bottom' : side;
            profileId = sash.frame[position].profileId;
            color = conf.Colors.sash;
        } else if (type === 'frame') {
            position = changeProfilesWithoutThreshold ? 1 : changeAllProfiles ? 0 : position;
            profileId = frame.frame[position].profileId;
        } else if (type === 'extension') {
            extension = conf.SideProfiles.find(o => o.id === extension.id) || {};
            profileId = extension.profileId;
            color = extension.color;
            wood = extension.wood;
            reinforcement = extension.reinforcement ? core.copy(extension.reinforcement) : null;
        } else if (type === 'coupling') {
            coupling = conf.couplings.find(c => c.id === coupling.id);
            profileId = coupling.profileId;
        }
        const profile = this.profilesService.getProfile(profileId);
        if (!color) {
            color = conf.Colors.frame;
        }
        if (type === 'extension' && profile && profile.type === 'sandwich') {
            profile.width = extension.width;
        }
        [type, options] = this.getOptions(conf, type, {
            position,
            frame,
            sash,
            mullion,
            extension,
            coupling,
        });
        if (type !== 'no_mullion') {
            this.openProfilesModal(conf, type, options, profile, color, wood, reinforcement).then(
                selectedProfile => {
                    if (selectedProfile) {
                        const pauseId = this.eventBusService.pause([
                            'setBondedGlazingInSash',
                            'setMullionProfile',
                            'setSashProfile',
                        ]);
                        try {
                            if (changeAllProfiles && type !== 'mullion') {
                                Object.keys(type === 'frame' ? frame.frame : sash.frame).map(
                                    pos => {
                                        position = type === 'frame' ? Number(pos) : pos;
                                        this.setProfile(conf, defaultConf, type, selectedProfile, {
                                            position,
                                            frame,
                                            sash,
                                            mullion,
                                            extension,
                                        });
                                    }
                                );
                            } else if (changeProfilesWithoutThreshold && type === 'frame') {
                                if (changeAllFrames) {
                                    this.setFramesProfileSet(conf, defaultConf, selectedProfile);
                                } else {
                                    Object.keys(frame.frame).map(pos => {
                                        position = Number(pos);
                                        const frameProfile = this.profilesService.getProfile(
                                            frame.frame[pos].profileId
                                        );
                                        if (frameProfile.type !== 'threshold') {
                                            this.setProfile(
                                                conf,
                                                defaultConf,
                                                type,
                                                selectedProfile,
                                                {
                                                    position,
                                                    frame,
                                                    sash,
                                                    mullion,
                                                    extension,
                                                }
                                            );
                                        }
                                    });
                                }
                            } else if (selectedProfile.type === 'coupling' && mullion) {
                                this.framesService.splitFrame(selectedProfile, mullion, conf);
                            } else if (selectedProfile.type === 'fixed_mullion' && coupling) {
                                this.framesService.joinFrame(selectedProfile, coupling, conf);
                            } else {
                                this.setProfile(
                                    conf,
                                    defaultConf,
                                    selectedProfile.type,
                                    selectedProfile,
                                    {
                                        position,
                                        frame,
                                        sash,
                                        mullion,
                                        extension,
                                        coupling,
                                    }
                                );
                            }
                        } finally {
                            this.eventBusService.resume(
                                ['setBondedGlazingInSash', 'setMullionProfile', 'setSashProfile'],
                                pauseId
                            );
                        }
                        if (this.config().IccConfig.Configurators.dependencies) {
                            this.eventBusService.post({ key: 'processDependencies', value: null });
                        }
                    }
                }
            );
        }
    }

    private setProfile(
        conf: WindowActiveConfiguration,
        defaultConf: WindowActiveConfiguration,
        type: ProfileType,
        profile: Profile,
        {
            position = null,
            frame = null,
            sash = null,
            mullion = null,
            extension = null,
            coupling = null,
        }: {
            position?: 'bottom' | 'top' | 'left' | 'right' | number | null;
            frame?: any | null;
            sash?: any | null;
            mullion?: any | null;
            extension?: any | null;
            coupling?: any | null;
        }
    ) {
        if ((type === 'sash' || type === 'false_mullion_sash') && typeof position === 'string') {
            this.profilesService.setSashProfile(conf, defaultConf, sash, profile, position, {});
        } else if (type.indexOf('mullion') > -1) {
            this.profilesService.setMullionProfile(conf, mullion, profile, {});
        } else if (type === 'frame' && typeof position === 'number') {
            const frameData = conf.drawData && conf.drawData.frame && conf.drawData.frame[0];
            const side =
                (frameData
                    && frameData.sides[position]
                    && frameData.sides[position].outerEdge.side)
                || 'bottom';
            this.profilesService.setFrameProfile(conf, profile, frame, position, { side });
        } else if (type === 'extension' || type === 'sandwich') {
            this.profilesService.setExtensionProfile(extension, profile);
        } else if (type === 'coupling') {
            this.framesService.changeCouplingProfile(conf, coupling, profile, {});
        }
    }

    setFramesProfileSet(
        conf: WindowActiveConfiguration,
        defaultConf: WindowActiveConfiguration,
        profile: Profile
    ) {
        const profileSet = core.copy(conf.ProfileSet);
        profileSet.frameTop = profile.id;
        profileSet.frameSide = profile.id;
        profileSet.frameBottom = profile.id;
        conf.ProfileSet = core.copy(profileSet);
        conf.ProfileSet.isDefault = false;
        defaultConf.ProfileSet = core.copy(profileSet);
        defaultConf.ProfileSet.isDefault = false;
        this.eventBusService.post({
            key: 'setProfileSet',
            value: {
                profileSet,
            },
            conf,
            defaultConf,
        });
    }

    private getMullion(conf: WindowActiveConfiguration, mullionId: number) {
        for (const mullion of conf.Mullions) {
            if (mullion.id === mullionId) {
                return mullion;
            }
        }
        for (const sash of conf.Sashes) {
            for (const mullion of sash.intMullions) {
                if (mullion.id === mullionId) {
                    return mullion;
                }
            }
        }
    }

    private getSash(conf: WindowActiveConfiguration, sashId: number) {
        for (const sash of conf.Sashes) {
            if (sash.id === sashId) {
                return sash;
            }
        }
    }

    getOptions(
        conf: WindowActiveConfiguration,
        type: any,
        {
            position = null,
            frame = null,
            sash = null,
            mullion = null,
            extension = null,
            coupling = null,
        }: {
            position?: 'bottom' | 'top' | 'left' | 'right' | 1 | null;
            frame?: any;
            sash?: any;
            mullion?: any;
            extension?: any;
            coupling?: any;
        }
    ) {
        let options:
            | {
                  and: string[];
                  not: string[];
                  filter?: (
                      profile: Profile,
                      conf: WindowActiveConfiguration | WindowConfiguration | null
                  ) => boolean;
              }
            | {
                  and: string[];
                  not: string[];
                  filter?: (
                      profile: Profile,
                      conf: WindowActiveConfiguration | WindowConfiguration | null
                  ) => boolean;
              }[] = {
            and: [],
            not: [],
        };

        let positionMap: {
            bottom: string;
            right: string;
            top: string;
            left: string;
            0?: string;
            1?: string;
            2?: string;
            3?: string;
        } = {
            0: 'bottom',
            1: 'side',
            2: 'top',
            3: 'side',
            bottom: 'bottom',
            right: 'side',
            top: 'top',
            left: 'side',
        };

        if (position !== null) {
            if (type === 'sashFrame' && sash) {
                const sashFrameData =
                    conf.drawData
                    && conf.drawData.sashFrame
                    && conf.drawData.sashFrame.find(s => s.sashId === sash.id);

                if (sashFrameData) {
                    sashFrameData.sides.forEach((side, index) => {
                        positionMap[index] = side.outerEdge.side || 'bottom';
                    });
                }
                if (sash.nearMullions.bottom > -1) {
                    const bottomMulion = conf.Mullions.find(
                        el => el.id === sash.nearMullions.bottom
                    );
                    const bottomSashes = (bottomMulion && bottomMulion.multiAlignBottom) || [];

                    if (
                        sash.type.type === 'SD'
                        || bottomSashes.some(el => el.type && el.type.type === 'SU')
                    ) {
                        positionMap = {
                            top: 'top_in_top_sash',
                            bottom: 'bottom_in_top_sash',
                            left: 'side_in_top_sash',
                            right: 'side_in_top_sash',
                        };
                    }
                } else if (sash.nearMullions.top > -1) {
                    const topMullion = conf.Mullions.find(el => el.id === sash.nearMullions.top);
                    const topSashes = (topMullion && topMullion.multiAlignTop) || [];

                    if (
                        sash.type.type === 'SU'
                        || topSashes.some(el => el.type && el.type.type === 'SD')
                    ) {
                        positionMap = {
                            top: 'top_in_bottom_sash',
                            bottom: 'bottom_in_bottom_sash',
                            left: 'side_in_bottom_sash',
                            right: 'side_in_bottom_sash',
                        };
                    }
                }
            }
            options.and.push(positionMap[position]);
        }

        if (type === 'sashFrame' && sash) {
            type = 'sash';
            options[sash.type.out_open ? 'and' : 'not'].push('outward_opening');
            if (
                sash.type.passive
                && ((sash.type.handle_position === 'R' && position === 'right')
                    || (sash.type.handle_position === 'L' && position === 'left'))
            ) {
                type = ['sash', 'false_mullion_sash'];
                options = [
                    options,
                    {
                        and: [],
                        not: [],
                    },
                ];
                options[1][sash.type.out_open ? 'and' : 'not'].push('outward_opening');
            }
        } else if (type === 'mullion' && mullion) {
            type = mullion.type;
            if (type === 'fixed_mullion') {
                options.and.push(
                    mullion.direction + (mullion.parentSash != null ? '_sash' : '_frame'),
                    this.mullionsService.getMullionGlazingOrHardwareOptions(mullion)
                );
                const frame =
                    mullion.frameId != null
                        ? BrowserFramesService.getFrameById(mullion.frameId, conf)
                        : null;
                if (
                    !mullion.parentSash
                    && ((mullion.direction === 'vertical'
                        && (mullion.rHeight === conf.Height
                            || (frame && mullion.rHeight === frame.height)))
                        || (mullion.direction === 'horizontal'
                            && (mullion.rWidth === conf.Width
                                || (frame && mullion.rWidth === frame.width))))
                    && this.config().IccConfig.Configurators.coupledFrames
                    && conf.SashesType === 'Fix'
                ) {
                    type = [type, 'coupling'];
                    options = [
                        options,
                        {
                            and: [],
                            not: [],
                        },
                    ];
                }
            }
        } else if (type === 'extension') {
            type = ['extension', 'sandwich'];
            options = [
                options,
                {
                    and: [],
                    not: [],
                },
            ];
        } else if (type === 'coupling' && coupling && (conf.SashesType==="Fix" || !mullion.parentSash)) {
            if (coupling.framesId.length === 1 && coupling.otherFramesId.length === 1) {
                type = ['fixed_mullion', 'coupling'];
                options = [
                    {
                        and: [coupling.direction + '_frame'],
                        not: [],
                    },
                    {
                        and: [],
                        not: [],
                    },
                ];
            }
        } else if (type === 'frame') {
            options = {
                and: [],
                not: [],
                filter: (profile, conf) => {
                    return (
                        (conf && this.profilesService.matchDoorLightSize(profile, conf)) || false
                    );
                },
            };
        }

        return [type, options];
    }
}
