import { core } from '@icc/common/helpers';
import { Common } from '@icc/common/Common';
import { EventBusService } from '@icc/common/event-bus.service';
import { Injectable, Inject } from '@angular/core';
import { DatabaseManagerToken, DatabaseManager } from '@icc/helpers/browser';
import { ConfiguratorsDataService } from '@icc/common/configurators/configurators-data.service';
import { HttpClient } from '@angular/common/http';
import { take } from 'rxjs/operators';
import { IccConfiguratorData, IccPricesData, IccLayoutsData } from '@icc/common/data-types';
import { BehaviorSubject } from 'rxjs';
import { ConfiguratorOptions } from '@icc/configurator/shared';
import { AppConfigFactory, APP_CONFIG } from '@icc/common';

@Injectable()
export class ConfiguratorConfiguratorsDataService extends ConfiguratorsDataService {
    data: IccConfiguratorData & IccPricesData = {};
    layouts: IccLayoutsData['sashesLayouts'] = [];
    layoutsVariants: IccLayoutsData['sashesVariants'] = [];
    loading = false;
    loaded$ = new BehaviorSubject(false);
    get loaded() {
        return (
            this.data && this.data.windowLinesAll && this.data.windowLinesAll.length > 0
            && this.data.windowPrices
            && Object.keys(this.data.windowPrices).length > 0
        );
    }

    loadedConfiguratorsData: IccConfiguratorData;
    loadedPricesData: IccPricesData;
    loadedLayoutsData: IccLayoutsData;

    constructor(
        @Inject(DatabaseManagerToken) private databaseManager: DatabaseManager,
        @Inject(APP_CONFIG) private config: AppConfigFactory,
        private eventBusService: EventBusService,
        private http: HttpClient,
    ) {
        super();
        this.eventBusService.subscribeWithoutConfiguration(
            ['syncedPrices', 'syncedConfigurators'],
            () => {
                this.reload();
            }
        );
    }

    async reload() {
        this.data = {};
        this.layouts = [];
        this.layoutsVariants = [];

        this.load();
    }

    async load() {
        if (!this.loaded) {
            await this.loadParsedData(this.config().embeddedInB2B);
        }

        if (this.loaded) {
            this.loaded$.next(true);
            this.eventBusService.post({
                key: 'loadedConfiguratorsData',
                value: this.data,
            });
        }

        return this.data;
    }

    loadDataFromServer(options: ConfiguratorOptions) {
        return new Promise(resolve => {
            if (!this.loading) {
                this.loading = true;
                this.http
                    .get('/sync/sync/get_configurators_data.json?lang='+ options.languageCode+ '&market_id=' + options.marketId + '&user_type=' + options.preset)
                    .pipe(take(1))
                    .subscribe((data: { configsData: any }) => {
                        this.loadedConfiguratorsData = data.configsData;
                        this.loadLayoutsDataFromServer(options).then(() => {
                            this.loadPricesDataFromServer(options).then(() => {
                                this.loading = false;
                                this.load().then(() => {
                                    resolve();
                                });
                            });
                        });
                    });
            }
        });
    }

    loadPricesDataFromServer(options: ConfiguratorOptions) {
        return new Promise(resolve => {
            this.http
                .get('/sync/sync/get_prices.json?lang='+ options.languageCode+ '&market_id=' + options.marketId)
                .pipe(take(1))
                .subscribe((prices: IccPricesData) => {
                    this.loadedPricesData = prices;
                    resolve();
                });
        });
    }

    loadLayoutsDataFromServer(options: ConfiguratorOptions) {
        return new Promise(resolve => {
            this.http
                .get('/sync/sync/get_sashes_layouts.json?lang='+ options.languageCode+ '&market_id=' + options.marketId)
                .pipe(take(1))
                .subscribe((layouts: IccLayoutsData) => {
                    this.loadedLayoutsData = layouts;
                    resolve();
                });

        });
    }

    async loadParsedData(useDataFromStorage = false) {
        if (useDataFromStorage) {
            const configuratorsDB = this.databaseManager.get('Configurators');
            const pricesDB = this.databaseManager.get('Prices');
            const layoutsDB = this.databaseManager.get('Layouts');
            if (configuratorsDB && pricesDB && layoutsDB) {
                let r = await configuratorsDB.get();
                this.loadedConfiguratorsData = core.parseJson(r && r.data ? r.data : '{}').configsData || {};

                r = await pricesDB.get();
                this.loadedPricesData = core.parseJson(r && r.data ? r.data : '{}');

                const layouts = await layoutsDB.get();
                this.loadedLayoutsData = layouts.data
            }
        }

        if (Common.isObject(this.loadedConfiguratorsData)) {
            const customFillings = core.parseJson(
                localStorage.getItem('customFillings') || '[]'
            );
            this.loadedConfiguratorsData.fillings = customFillings.concat(this.loadedConfiguratorsData.fillings);
            this.data = this.loadedConfiguratorsData;
        }

        Common.extend(this.data, this.loadedPricesData);
        this.data$.next(this.data);

        this.layouts = this.loadedLayoutsData ? this.loadedLayoutsData.sashesLayouts || [] : [];
        this.layouts$.next(this.layouts);
        this.layoutsVariants = this.loadedLayoutsData ? this.loadedLayoutsData.sashesVariants || [] : [];
        this.layoutsVariants$.next(this.layoutsVariants);
    }
}
