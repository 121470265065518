<icc-select-box
    [title]="selectedHandle?.name"
    [description]="selectedHandle?.description"
    [imageUrl]="selectedHandleImg"
></icc-select-box>

<mat-card class="mat-elevation-2">
    <div>
        <p>{{ 'COLOR|Wybierz kolor:' | translate }}</p>
        <icc-list [items]="colors" itemTemplate="itemColor" [scrollable]="false" [selected]="selHandleColor" (select)="selectHandleColor($event)">
        </icc-list>
    </div>

    <div *ngIf="availableColorOuter">
        <mat-slide-toggle [formControl]="selectColorOuter">{{ 'COLOR|Wybierz kolor na zewnątrz:' | translate }}</mat-slide-toggle>
        <small>{{ 'COLOR|Ta opcja pozwala wybrać inny kolor dla klamki zewnętrznej' | translate }}</small>
        <icc-list *ngIf="selectColorOuter.value" [items]="colors" itemTemplate="itemColor" [scrollable]="false" [selected]="selHandleOuterColor" (select)="selectHandleColor($event, true)">
        </icc-list>
    </div>
</mat-card>

<mat-card class="mat-elevation-2" *ngIf="showHeightSelect && handleHeights.length && (!config().IccConfig.Configurators.loweredHandlerHeights || config().preset === 'b2b' || availableLoweredHandleHeight) && (confType != 'hs' && confType != 'sliding_door' && confType != 'door' || systemHeight)">
    <mat-form-field *ngIf="sashType == 'K' && config().IccConfig.Configurators.handlePositionInUType">
        <mat-select [(ngModel)]="position" [placeholder]="'WINDOW|Pozycja klamki' | translate">
            <mat-option value='T'>{{ 'WINDOW|montaż u góry' | translate }}</mat-option>
            <mat-option value='R'>{{ 'WINDOW|montaż po prawej' | translate }}</mat-option>
            <mat-option value='L'>{{ 'WINDOW|montaż po lewej' | translate }}</mat-option>
        </mat-select>
    </mat-form-field>
    <mat-form-field *ngIf="position != 'T' && config().IccConfig.Configurators.handlePosition && (!config().IccConfig.Configurators.loweredHandlerHeights || config().preset === 'b2b')">
        <mat-select [(ngModel)]="handleHeight" [placeholder]="'WINDOW|Wysokość klamki:' | translate">
            <mat-option *ngFor="let height of handleHeights" [value]="height.height">{{height.name}}</mat-option>
        </mat-select>
    </mat-form-field>
    <mat-checkbox *ngIf="availableLoweredHandleHeight" [(ngModel)]="isLoweredHandleHeight">
        {{ 'WINDOW|Obniżona klamka' | translate }}
    </mat-checkbox>
    <mat-form-field *ngIf="position != 'T' && config().IccConfig.Configurators.handlePosition && handleHeight === 'nonstandard'">
        <input matInput iccDimensionInput [(ngModel)]="customHandleHeight" [min]="minHandleHeight" [max]="maxHandleHeight" style="width: 70px" [placeholder]=>"'WINDOW|Niestandardowa wysokość klamki:' | translate"
        <!-- {{$root.user.dimension_unit}} -->
    </mat-form-field>
</mat-card>

<button mat-raised-button color="primary" (click)="save()"
        >{{ 'INTERFACE|OK' | translate }}</button>
