import { Injectable, Inject } from '@angular/core';
import { WindowActiveConfiguration } from '@icc/common/configurations/WindowActiveConfiguration';
import { BrowserFramesService } from './frames.service';
import { ActiveMullion } from '@icc/common/layout/active-mullion';
import {APP_CONFIG, AppConfig, AppConfigFactory} from '@icc/common/config';;
import { SashTypes } from '@icc/window';
import {EventBusService, ProfilesService } from '@icc/common';

@Injectable()
export class SplitFrameService {
    constructor(
        @Inject(APP_CONFIG) private config: AppConfigFactory,
        private framesService: BrowserFramesService,
        private profilesService: ProfilesService,
        private eventBusService: EventBusService,
    ) {
        this.eventBusService.subscribe(['loadedProfiles'], data => {
            this.automaticallySplitFrames(data.activeConfiguration as WindowActiveConfiguration);
        });
    }

    automaticallySplitFrames(conf: WindowActiveConfiguration) {
        this.splitFrameContainingFixAndSash(conf);
        this.splitFrameContainingFixAndOutwardSash(conf);
    }

    splitLowThresholdFrameContainingFixAndSash(conf: WindowActiveConfiguration) {
        if (!this.config().IccConfig.Configurators.coupledFrames
            || !conf.System.split_fix_and_sash_when_threshold
        ) {
            return;
        }

        const couplingProfiles = this.profilesService.getFilteredProfiles(conf, 'coupling', {});
        conf.Sashes.forEach(sash => {
            const frame = conf.Frames.find(f => f.id === sash.frameId);
            const multiAlignField = {
                left: 'multiAlignLeft',
                right: 'multiAlignRight',
                top: 'multiAlignTop',
                bottom: 'multiAlignBottom',
            };
            if (
                frame.lowThreshold
                && sash.nearMullions.bottom === -1
                && sash.nearAlignments.bottom === -1
            ) {
                ['left', 'right'].forEach(side => {
                    let mullion: ActiveMullion;
                    conf.Mullions.forEach(m => {
                        if (
                            m.id === sash.nearMullions[side]
                            && (m.rWidth === frame.width || m.rHeight === frame.height)
                        ) {
                            mullion = m;
                        }
                    });
                    if (mullion && couplingProfiles.length > 0) {
                        if (
                            sash.type.type === 'F'
                            && mullion[multiAlignField[side]].some(
                                s => SashTypes.TURN_HUNG.indexOf(s.type.type) > -1
                            )
                        ) {
                            this.framesService.splitFrame(couplingProfiles[0], mullion, conf);
                        }
                    }
                });
            }
        });
    }

    private splitFrameContainingFixAndSash(conf: WindowActiveConfiguration) {
        if (!this.config().IccConfig.Configurators.coupledFrames || !conf.System.split_fix_and_sash) {
            return;
        }
        
        const couplingProfiles = this.profilesService.getFilteredProfiles(conf, 'coupling', {});
        conf.Sashes.forEach(sash => {
            const frame = conf.Frames.find(f => f.id === sash.frameId);
            const multiAlignField = {
                left: 'multiAlignLeft',
                right: 'multiAlignRight',
                top: 'multiAlignTop',
                bottom: 'multiAlignBottom',
            };
            ['left', 'right', 'top', 'bottom'].forEach(side => {
                let mullion: ActiveMullion;
                conf.Mullions.forEach(m => {
                    if (
                        m.id === sash.nearMullions[side]
                        && (m.rWidth === frame.width || m.rHeight === frame.height)
                    ) {
                        mullion = m;
                    }
                });
                if (mullion && couplingProfiles.length > 0) {
                    if (sash.type.type === 'F') {
                        if (
                            mullion[multiAlignField[side]].some(
                                s => ['F', 'FF'].indexOf(s.type.type) === -1
                            )
                        ) {
                            this.framesService.splitFrame(
                                couplingProfiles[0],
                                mullion,
                                conf
                            );
                        }
                    } else {
                        if (
                            mullion[multiAlignField[side]].some(
                                s => ['F'].indexOf(s.type.type) > -1
                            )
                        ) {
                            this.framesService.splitFrame(
                                couplingProfiles[0],
                                mullion,
                                conf
                            );
                        }
                    }
                }
            });
        });
    }

    private splitFrameContainingFixAndOutwardSash(conf: WindowActiveConfiguration) {
        if (
            !this.config().IccConfig.Configurators.coupledFrames
            || !conf.System.split_fix_and_outward_sash
        ) {
            return;
        }

        const couplingProfiles = this.profilesService.getFilteredProfiles(conf, 'coupling', {});
        conf.Sashes.forEach(sash => {
            const frame = conf.Frames.find(f => f.id === sash.frameId);
            const multiAlignField = {
                left: 'multiAlignLeft',
                right: 'multiAlignRight',
                top: 'multiAlignTop',
                bottom: 'multiAlignBottom',
            };
            ['left', 'right', 'top', 'bottom'].forEach(side => {
                let mullion: ActiveMullion;
                conf.Mullions.forEach(m => {
                    if (
                        m.id === sash.nearMullions[side]
                        && (m.rWidth === frame.width || m.rHeight === frame.height)
                    ) {
                        mullion = m;
                    }
                });
                if (mullion && couplingProfiles.length > 0) {
                    if (sash.type.type === 'F') {
                        if (
                            mullion[multiAlignField[side]].some(
                                s => SashTypes.OUTWARD_OPENING.indexOf(s.type.type) > -1
                            )
                        ) {
                            this.framesService.splitFrame(
                                couplingProfiles[0],
                                mullion,
                                conf
                            );
                        }
                    } else if (SashTypes.OUTWARD_OPENING.indexOf(sash.type.type) > -1) {
                        if (
                            mullion[multiAlignField[side]].some(
                                s => ['F'].indexOf(s.type.type) > -1
                            )
                        ) {
                            this.framesService.splitFrame(
                                couplingProfiles[0],
                                mullion,
                                conf
                            );
                        }
                    }
                }
            });
        });
    }
}
