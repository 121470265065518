/* eslint-disable max-statements */
import { ProfilesService } from '@icc/common/profiles.service';
import { logger, core } from '@icc/common/helpers';
import {
    isArray,
    ModalService,
    isUndefined,
    IssuesService,
    InfoService,
    isObject,
    isDefined,
    isString,
    isNotNullOrUndefined,
    IssueLevel,
} from '@icc/helpers';
import {
    TranslateService,
    AppConfigFactory,
    APP_CONFIG,
    ConfigurationsService,
    ConfiguratorsDataService,
    ColorsDefaultsService,
    EventBusService,
    ValidationService,
    WindowActiveConfiguration,
    Common,
} from '@icc/common';
import { Inject, Injectable } from '@angular/core';
import { CurrentConfiguratorService } from '@icc/common/configurators/current-configurator.service';
import { ConstructionLimitationService } from '../dimensions/construction-limitation.service';
import { HandlesService } from '../handles/handles.service';
import { PriceService } from '@icc/price';
import { MuntinColorsService } from '../muntins/muntin-colors.service';
import { FillingsService } from '../glazings/fillings.service';
import { ColorsPageComponent } from 'libs/configurator/window/src/lib/colors-page/colors-page.component';
import { WoodsPageComponent } from 'libs/configurator/window/src/lib/woods-page/woods-page.component';
import { IccColor, IccColors, IccWood } from '@icc/common/data-types';
import { Side, IccColorGroup } from '@icc/common/data-types/ColorGroup';
import { ColorsService as RollerColorsService } from '../../roller_shutter/colors.service';
import { ColorMappingService } from '@icc/common/colors/colors-mapping.service';
import { Profile } from '@icc/window';
import { SizesService } from 'libs/configurator/door/src/lib/sizes/sizes.service';
import { Color } from '@icc/common/configurations/parts/common';
import { ColorRestrictionService } from '@icc/common/colors/colors-restriction.service';
@Injectable()
export class ColorsService {
    private configurators = ['window', 'hs', 'door', 'folding_door', 'sliding_door'];

    /**
     * Kolory konstrukcji, np. okleiny, lazury
     * @memberof ColorsService
     * @type {Array}
     */
    windowColors: IccColor[] = [];
    /**
     * Rodzaje drewna
     * @memberof ColorsService
     * @type {Array}
     */
    woods: IccWood[] = [];
    /**
     * Czy dane zostały załadowane
     * @memberof ColorsService
     * @type {Boolean}
     */
    loadedData = false;
    mode: string;

    constructor(
        private modalService: ModalService,
        private translateService: TranslateService,
        @Inject(APP_CONFIG) private config: AppConfigFactory,
        private configurationsService: ConfigurationsService<'window'>,
        private configuratorsDataService: ConfiguratorsDataService,
        private currentConfiguratorService: CurrentConfiguratorService,
        private constructionLimitationService: ConstructionLimitationService,
        private handlesService: HandlesService,
        private issuesService: IssuesService,
        private priceService: PriceService,
        private colorMappingService: ColorMappingService,
        private rollerColorsService: RollerColorsService,
        private colorsDefaultsService: ColorsDefaultsService,
        private eventBusService: EventBusService,
        private validationService: ValidationService,
        private infoService: InfoService,
        private muntinColorsService: MuntinColorsService,
        private fillingsService: FillingsService,
        private profilesService: ProfilesService,
        private sizesService: SizesService,
        private colorRestrictionService: ColorRestrictionService
    ) {
        if (this.configuratorsDataService.loaded) {
            this.init();
        }

        this.eventBusService.subscribeWithoutConfiguration('initializedConfigurator', () => {
            this.init();
        });

        this.eventBusService.subscribeWithoutConfiguration('reloadColorsData', () => {
            this.reloadColors();
        });

        this.eventBusService.subscribe('setFillingColor', data => {
            const conf = data.activeConfiguration as WindowActiveConfiguration;
            const confDefault = data.defaultConfiguration as WindowActiveConfiguration;
            if (conf.type === 'door') {
                this.setDefaultColors(conf, confDefault, null, true);
            }
        });
    }

    /**
     * Inicjalizuje działanie ColorsService.
     * @private
     */
    init() {
        if (this.configurators.indexOf(this.currentConfiguratorService.conf) === -1) {
            return;
        }
        if (isUndefined(this.configurationsService.conf)) {
            return;
        }
        this.issuesService.addValidateFunction(this.validate.bind(this));
        this.loadData();
        this.setDefaults();
        this.loadedData = true;
    }

    /**
     * Ładuje dane
     */
    loadData() {
        if (isArray(this.configuratorsDataService.data.woodTypes)) {
            this.woods = this.configuratorsDataService.data.woodTypes;
        }
    }

    /**
     * Ustawia domyślne kolory i rodzaj drewna po uprzednim przefiltrowaniu ich po systemie.
     * @memberof ColorsService
     */
    setDefaults(
        conf = this.configurationsService.conf.Current,
        defaultConf = this.configurationsService.conf.Default,
        reloadDefaults = false
    ) {
        if (!isObject(conf.System) || isUndefined(conf.System.id)) {
            return;
        }
        this.hasSystemAlushell(conf, defaultConf);
        this.setDefaultWood(conf, defaultConf);
        this.loadColorsBySystem(null, conf);
        this.loadColorsByWood(conf);
        this.setDefaultColors(conf, defaultConf, undefined, true, reloadDefaults);

        if (
            this.config().IccConfig.Configurators.alushell
            && conf.System.alushell
            && conf.System.alushell_active
        ) {
            conf.HasAlushell = true;
        }
        this.priceService.count();

        const value = {
            windowColors: this.windowColors,
            woods: this.woods,
        };

        this.eventBusService.post({
            key: 'setDefaultColors',
            value,
            conf,
            defaultConf,
        });
    }

    reloadColors() {
        this.loadColorsBySystem();
        this.loadColorsByWood();
    }

    /**
     * Ustawia domyslne kolory po zmianie rodzaju drewna (po odpowiednim ich przefiltrowaniu).
     * @private
     */
    setDefaultsOnChangeWoodType(
        conf = this.configurationsService.conf.Current,
        confDefault = this.configurationsService.conf.Default,
        filterColors: ((color?: IccColor) => boolean) | null = null
    ) {
        if (this.config().IccConfig.Configurators.woodBicolor) {
            this.loadColorsBySystem(filterColors);
            this.loadColorsByWood(conf);
            this.setDefaultColors(conf, confDefault);
        }
    }

    /**
     * Ładuje kolory przefiltrowane do wybranego systemu.
     * @private
     */
    loadColorsBySystem(
        filterColors: (color?: IccColor) => boolean | null = null,
        conf = this.configurationsService.conf.Current
    ) {
        if ((!isObject(conf.System) || isUndefined(conf.System.id)) && !filterColors) {
            return;
        }

        let allColors = filterColors
            ? this.configuratorsDataService.data.windowColorsAll.filter(filterColors)
            : core.copy(this.configuratorsDataService.data.windowColors[conf.System.id]);

        const colorGroups = this.getColorsGroups(filterColors, allColors, conf);
        // sortuj kolory względem grup i nazw
        allColors = allColors.sort((a, b) => {
            if (isUndefined(a.groups) || a.groups === null) {
                return 1;
            } else if (isUndefined(b.groups) || b.groups === null) {
                return -1;
            }
            let pos = 0,
                x = 0;
            // najpierw sortuj względem grup
            let compareE1 = 9999999;
            for (; x < a.groups.length; ++x) {
                pos = colorGroups.indexOf(String(a.groups[x]));
                if (pos > -1 && compareE1 > pos) {
                    compareE1 = pos;
                }
            }
            let compareE2 = 9999999;
            for (x = 0; x < b.groups.length; ++x) {
                pos = colorGroups.indexOf(String(b.groups[x]));
                if (pos > -1 && compareE2 > pos) {
                    compareE2 = pos;
                }
            }
            if (compareE1 < compareE2) {
                return -1;
            } else if (compareE1 > compareE2) {
                return 1;
            } else {
                return Number(a.order) - Number(b.order);
            }
        });

        core.clear(this.windowColors);
        core.rewriteArray(this.windowColors, allColors);
        allColors = [];
    }

    getColorsGroups(
        filterColors: ((color?: IccColor) => boolean) | null,
        allColors: IccColor[],
        conf = this.configurationsService.conf.Current
    ) {
        // pobierz grupy kolorów z dostępnych kolorów
        const groups: (number | string)[] = [];
        for (let x = 0; x < allColors.length; ++x) {
            const colorGroups = allColors[x].groups;
            if (isNotNullOrUndefined(colorGroups)) {
                for (let y = 0; y < colorGroups.length; ++y) {
                    if (groups.indexOf(colorGroups[y]) < 0) {
                        groups.push(colorGroups[y]);
                    }
                }
            }
        }
        // pobierz grupy kolorów dla danej linii (sprawdź czy grupa kolorów dla linii nie jest pusta)
        const colorGroups = this.configuratorsDataService.data.windowColorGroups.filter(el => {
            const val =
                isArray(el.systems)
                && isArray(groups)
                && groups.indexOf(el.id) > -1
                && (filterColors
                    || (conf.System
                        && el.systems.indexOf(conf.System.id) > -1
                        && el.target.indexOf('show') > -1
                        && (conf.System.type !== 'wood'
                            || (isArray(el.woodTypes)
                                && el.woodTypes.indexOf(conf.Wood.id) > -1))));
            return val;
        });
        const colorGroupsIds: string[] = [];
        for (let x = 0; x < colorGroups.length; ++x) {
            colorGroupsIds[x] = colorGroups[x].id;
        }
        return colorGroupsIds;
    }

    /**
     * Ładuje kolory przefiltrowane wg wybranego rodzaju drewna.
     * @private
     */
    loadColorsByWood(conf = this.configurationsService.conf.Current) {
        if (isUndefined(conf)) {
            return;
        }
        const allColors = core.copy(this.windowColors);
        core.clear(this.windowColors);
        for (let i = 0; i < allColors.length; i++) {
            const color = allColors[i];
            if (
                this.config().IccConfig.Configurators.woodBicolor
            ) {
                if (
                    (this.config().IccConfig.Configurators.woodBicolor && !color.wood_type)
                    || (conf.Wood && color.wood_type === conf.Wood.id)
                ) {
                    this.windowColors.push(color);
                }
            } else {
                if (isNaN(parseInt(String(color.wood_type)))) {
                    this.windowColors.push(color);
                }
            }
        }
    }

    /**
     * Ustawia domyślny rodzaj drewna.
     * @private
     */
    setDefaultWood(
        conf = this.configurationsService.conf.Current,
        confDefault = this.configurationsService.conf.Default
    ) {
        if (conf.System.type === 'wood') {
            if (
                isArray(this.woods)
                && this.woods.length > 0
                && isUndefined(core.fId(this.woods, conf.Wood.id))
            ) {
                conf.Wood = core.copy(this.woods[0]);
                confDefault.Wood = core.copy(this.woods[0]);
            }
        } else {
            conf.Wood = {};
            confDefault.Wood = {};
        }
    }

    /**
     * Ustawia domyslne kolory
     * @private
     */
    setDefaultColors(
        conf = this.configurationsService.conf.Current,
        confDefault = this.configurationsService.conf.Default,
        filterColors: ((color?: IccColor) => boolean) | null = null,
        updateDefaults = false,
        reloadDefaults = false
    ) {
        if (isObject(conf.Colors) || isObject(confDefault.Colors)) {
            if (isObject(confDefault.Colors) && !this.isDefaultColorChanged(conf)) {
                conf.Colors = core.copy(confDefault.Colors);
            }
            let colors = conf.Colors;
            const colorsDefault = confDefault.Colors;
            if (isObject(colors.frame) && isObject(colors.sash)) {
                const allColors = this.windowColors;
                const pauseId = this.eventBusService.pause([
                    'setConstructionColor',
                    'changedSashes',
                ]);

                let colorType;

                // jeśli nie ma ustawionych kolorów zewn., wewn. i rdzenia i jest PVC to ustawiamy domyślny zestaw biały
                if (!colors.frame.inner.id
                    && !colors.frame.outer.id
                    && !colors.frame.core.id
                    && allColors.some(color => color.type === 'white')
                    && conf.System.type === 'pvc') {
                    colorType = 'white';
                }

                try {
                    const colorGroups = this.getColorsGroups(filterColors, allColors);
                    (['frame', 'sash', 'lipping'] as const).forEach(place => {
                        (['core', 'alushell', 'outer', 'inner'] as const).forEach(side => {
                            if (place === 'lipping') {
                                if (
                                    conf.System.lipping_color_based_on_frame_color 
                                    && conf.lippingColor
                                    && conf.innerLippingColor
                                    && conf.lippingColor.isDefault
                                    && conf.innerLippingColor.isDefault
                                    ) {
                                    conf.lippingColor = {...conf.Colors.frame.outer, isDefault: conf.lippingColor.isDefault};
                                    conf.innerLippingColor = {...conf.Colors.frame.inner, isDefault: conf.innerLippingColor.isDefault};
                                } else {
                                    const lippingPriceLevel = this.configuratorsDataService.data.priceLevels.find(
                                        (p) => p.id === Number(conf.System.lipping_price_level_id)
                                    );

                                    const outerAndDoubleColorGroupIds = lippingPriceLevel?.price_levels
                                        .filter((k) => k.side === 'double' || k.side === 'outer')
                                        .map((k) => k.colorGroup);

                                    const innerColorGroupIds = lippingPriceLevel?.price_levels
                                        .filter((k) => k.side === 'inner' || k.side === 'double')
                                        .map((k) => k.colorGroup);

                                    if (outerAndDoubleColorGroupIds && conf.Colors.frame.outer.sides.some(s => ['L|O', 'L|B'].includes(s)) && 
                                        conf.Colors.frame.outer.groups.some(s => outerAndDoubleColorGroupIds.includes(s)) && !conf.System.lipping_price_level_id
                                    ) {
                                        conf.lippingColor = {...conf.Colors.frame.outer};
                                    }
                                    if (innerColorGroupIds && conf.Colors.frame.inner.sides.some(s => ['L|I', 'L|B'].includes(s)) && 
                                        conf.Colors.frame.inner.groups.some(s => innerColorGroupIds.includes(s)) && !conf.System.lipping_price_level_id
                                    ) {
                                        conf.innerLippingColor = {...conf.Colors.frame.inner};
                                    }
                                }
                            } else if (
                                ((side !== 'alushell'
                                    || conf.System.alushell
                                     && conf.System.alushell_active
                                     )
                                && (
                                !colors[place][side]
                                || isUndefined(
                                    core.fId(
                                        allColors,
                                        [colors[place][side].id, colors[place][side].RAL],
                                        ['id', 'RAL']
                                    )
                                )
                                    || (side === 'core' || side === 'alushell' || !colorGroups.some(group => colors[place][side].groups.includes(group)))
                                    || (colors[place][side].isDefault && updateDefaults || !conf.System.separate_frame_and_sash_color && conf.type === 'door')
                                    || colorType === 'white'
                                    )
                                )
                            ) {
                                if (place === 'frame' && side === 'inner' && !conf.Colors.frame.outer.isDefault) {
                                    return;
                                }
                                colors[place][side] = {};
                                colorsDefault[place][side] = {};
                                this.setColorSide(
                                    colors[place][side],
                                    this.getDefaultColorForSide(side, place, colorType, conf),
                                    undefined,
                                    side,
                                    place,
                                    conf,
                                    true
                                );
                                this.setColorSide(
                                    colorsDefault[place][side],
                                    this.getDefaultColorForSide(side, place, colorType, conf),
                                    undefined,
                                    side,
                                    place,
                                    conf,
                                    true
                                );
                            }
                        });
                    });

                    const onEditMode = this.configurationsService.conf.Edit;
                    if (this.onlyDoubleSidedFrameColor(conf) && !onEditMode) {
                        this.setFrameInnerColorByFrameOuterColor(conf);
                    }

                    if (conf.valid != null) {
                        Object.assign(conf, this.validationService.valid(conf, 'colors'));
                    }
                } finally {
                    this.eventBusService.resume(['setConstructionColor', 'changedSashes'], pauseId);
                }
            }
            colors = null;
        }
    }

    onlyDoubleSidedFrameColor(conf: WindowActiveConfiguration): boolean {
        const frameProfileIds = this.profilesService.getUsedFrameProfileIds(conf)
        return frameProfileIds?.length > 0 && frameProfileIds.every(profileId => {
            const profileColorSides = this.profilesService.getProfilePriceLevelColorSides(profileId)
            return profileColorSides && profileColorSides.length > 0 && profileColorSides.every(side => side === 'double');
        })
    }

    isDoubleSidedColorAvailable(conf: WindowActiveConfiguration): boolean {
        const sashProfilesForSelectedSystem = this.getSashProfilesForSelectedSystem(conf);
        if (sashProfilesForSelectedSystem.length > 0) {
            return this.isDoubleSidedColorAvailableForSashProfile(sashProfilesForSelectedSystem);
        } else {
            return this.isDoubleSidedColorAvailableForVirtualSashProfile(conf);
        }
    }


    getSashProfilesForSelectedSystem(conf: WindowActiveConfiguration): Profile[] | [] {
        const sashProfiles = this.profilesService.getProfilesByType('sash');
        if (sashProfiles.length > 0) {
            return sashProfiles.filter(p => p.systems.includes(Number(conf.System.id)));
        } else {
            return [];
        }
    }

    isDoubleSidedColorAvailableForSashProfile(sashProfilesForSelectedSystem): boolean {
        return sashProfilesForSelectedSystem
            .every((sash) => this.profilesService.getPriceLevel(Number(sash.priceLevelId))?.price_levels
            .every((level) => level?.side === 'double')
        );
    }

    isDoubleSidedColorAvailableForVirtualSashProfile(conf) {
        const virtualDoorSash = conf.UsedProfiles && conf.UsedProfiles.find((p) => p.type === 'virtual_door_sash');
        if (virtualDoorSash && virtualDoorSash.priceLevelId && virtualDoorSash.systems.includes(Number(conf.System.id))) {
            return this.profilesService.getPriceLevel(Number(virtualDoorSash.priceLevelId))?.price_levels.every(level => level?.side === 'double');
        }
    }

    setFrameInnerColorByFrameOuterColor(conf: WindowActiveConfiguration) {
        const frameOuterColor = this.getFrameOuterColor(conf);
        const frameInnerColor = this.getFrameInnerColor(conf);
        if (
            frameOuterColor
            && frameInnerColor
            && frameOuterColor.id !== frameInnerColor.id
        ) {
            this.setColorSide(
                frameInnerColor,
                frameOuterColor,
                undefined,
                'inner',
                'frame',
                conf,
                true
            );
        }
    }

    getFrameOuterColor(conf: WindowActiveConfiguration) {
        return conf.Colors.frame && (!core.isEmptyObject(conf.Colors.frame.outer) ? conf.Colors.frame.outer : conf.Colors.frame.core);
    }

    getFrameInnerColor(conf: WindowActiveConfiguration) {
        return conf.Colors.frame && (!core.isEmptyObject(conf.Colors.frame.inner) ? conf.Colors.frame.inner : conf.Colors.frame.core);
    }

    /**
     * Zwraca domyślny kolor dla danego miejsca w konstrukcji
     * @private
     * @param  {string} type                 Strona konstrukcji (outer, inner, core, alushell)
     * @param  {string} place                Miejsce - skrzydło, rama
     * @param  {bool}   setDefaultWhite      Czy ustawić kolor biały jako domyślny
     * @return {object}                      Kolor
     */
    getDefaultColorForSide(
        type,
        place,
        colorType: 'white' | 'outer' | 'inner' | 'bicolor' | null,
        conf = this.configurationsService.conf.Current,
        filterColors: ((color?: IccColor) => boolean) | null = null
    ) {
        if (!isArray(this.windowColors)) {
            logger.error(new Error('Nie załadowane kolory'));
            return {};
        }

        const visColor = this.getColorFromVisualizer();
        const doorPanelMatchingColors = this.getColorFromDoorPanel(conf, type);
        let allColors = this.windowColors;

        const colors = [];
        const whites = [];
        const visColors = [];
        const doorPanelColors = [];
        if (filterColors) {
            allColors = allColors.filter(filterColors);
        }

        const colorSide = place && type && this.getColorSideType(place, type);
        for (let i = 0; i < allColors.length; i++) {
            if (
                this.isColorFitToSide(allColors[i], place, type, colorType)
            ) {
                if (allColors[i].type === 'white') {
                    whites.push(allColors[i]);
                } else if (
                    doorPanelMatchingColors
                    && doorPanelMatchingColors.length > 0
                    && doorPanelMatchingColors.includes(Number(allColors[i].id))
                ) {
                    if (conf.System.door_type && colorSide) {
                        const groups = allColors[i].groups.filter(
                            g => this.getColorGroupById(Number(g))?.sides.find(p => p === colorSide[0])
                        )
                        const availableColor = groups && groups.filter(p => {
                            const colorGroup = this.getColorGroupById(Number(p));
                            const sashWidth = this.sizesService.getSashWidthInRebateBasedOnConstructionWidth(conf);
                            const sashHeight = this.sizesService.getSashHeightInRebateBasedOnConstructionHeight(conf);
                            (this.colorRestrictionService.isColorGroupNotRestricted(colorGroup)) ||
                            (this.colorRestrictionService.isColorGroupBetweenMinAndMaxMetalSheetWidth(colorGroup, sashWidth)) &&
                            (this.colorRestrictionService.isColorGroupBetweenMinAndMaxMetalSheetHeight(colorGroup, sashHeight))
                            }
                        );
                        if (availableColor) {
                            doorPanelColors.push(allColors[i]);
                        }
                    } else {
                        doorPanelColors.push(allColors[i]);
                    }
                } else if (visColor && isString(allColors[i].color)) {
                    if (
                        allColors[i].color.toUpperCase() === visColor.toUpperCase()
                        || allColors[i].color_img === visColor
                    ) {
                        visColors.push(allColors[i]);
                    }
                } else {
                    colors.push(allColors[i]);
                }
            }
        }

        if (isDefined(whites[0])
            && type === 'core'
        ) {
            return whites[0];
        }

        if (conf.System?.default_market_configuration?.window_color_id) {
            const defaultWindowColor = allColors.find(k => k.id === conf.System.default_market_configuration?.window_color_id);
            if (defaultWindowColor) {
                return defaultWindowColor;
            }
        }

        if (doorPanelColors.length > 0) {
            return doorPanelColors[0];
        }
        if (visColor && isDefined(visColors[0])) {
            return visColors[0];
        }
       
        if (isDefined(colors[0])) {
            const whiteColor = whites && Array.isArray(whites) && whites.length > 0 && whites[0];
            const selectedColor = conf.Colors[place][type];
            return Object.keys(selectedColor).length === 0 ? whiteColor ? {} : colors[0] : colors[0];
        }
        return {};
    }

    isColorFitToSide(color: IccColor, place, type, colorType: 'white' | 'outer' | 'inner' | 'bicolor' | null) {
        if (type !== 'core'
            && type !== 'alushell'
            && colorType === 'white'
        ) {
            return false;
        }

        if (type === 'outer'
           && colorType === 'inner'
        ) {
            return false;
        }

        if (type === 'inner'
           && colorType === 'outer'
        ) {
            return false;
        }
        let sides = [place[0].toUpperCase() + '|' + type[0].toUpperCase()];
        if (
            !colorType && ['inner', 'outer'].indexOf(type) > -1
        ) {
            sides = [place[0].toUpperCase() + '|B'];
        }
        if (colorType === 'bicolor' && ['inner', 'outer'].indexOf(type) > -1) {
            sides = [place[0].toUpperCase() + '|D' + type[0].toUpperCase()];
        }
        return (isArray(color.sides)
            && sides.every(s => color.sides.indexOf(s) > -1))
            || color.RAL;
    }

    getColorSideType(place, type) {
       return (place && type) && [place[0]?.toUpperCase() + '|' + type[0]?.toUpperCase()];
    }

    /**
     * Definiuje na podstawie cennika, że dla wybranego systemu (nie) jest dostepna nakładka aluminiowa.
     * @private
     * @return {Boolean} Dostępność nakładki
     */
    hasSystemAlushell(
        conf = this.configurationsService.conf.Current,
        defaultConf = this.configurationsService.conf.Default
    ) {
        const winLineId = conf.System.id;
        if (
            (isObject(this.configuratorsDataService.data.pricesDepends)
                && isObject(this.configuratorsDataService.data.pricesDepends[winLineId + '|n']))
            || (isDefined(conf.System.alushell_factor) && conf.System.alushell_factor !== null)
        ) {
            const prices = this.configuratorsDataService.data.pricesDepends[winLineId + '|n'];
            if (
                isDefined(prices)
                || (isDefined(conf.System.alushell_factor) && conf.System.alushell_factor !== null)
            ) {
                conf.System.alushell = true;
                defaultConf.System.alushell = true;
                return true;
            }
        }

        conf.System.alushell = false;
        defaultConf.System.alushell = false;

        if (conf.HasAlushell) {
            conf.HasAlushell = false;
            if (conf.Colors.frame.alushell?.id) this.setColor('alushell', 'frame', 'none', undefined, false, conf, defaultConf);
            if (conf.Colors.sash.alushell?.id) this.setColor('alushell', 'sash', 'none', undefined, false, conf, defaultConf);
        }
        return false;
    }

    /**
     * Ustawia kolor dla danego miejsca w danym obiekcie
     * @private
     * @param {object}     object Stary kolor
     * @param {object}     color  Nowy kolor
     * @param {string|int} type   Grupa koloru
     * @param {string}     side   Strona konstrukcji (outer, inner, core, alushell)
     * @param {string}     place  Miejsce - skrzydło, rama
     */
    setColorSide(
        object: Partial<IccColor>,
        color: Partial<IccColor> | undefined,
        type: number | 'none' | undefined | string,
        side: 'outer' | 'inner' | 'core' | 'alushell',
        place: 'frame' | 'sash' | 'lipping',
        conf = this.configurationsService.conf.Current,
        isDefault = false,
        mode?: 'simple' | 'advanced' | 'extended' | 'door_extended' | 'extension' | false
    ) {
        if (isNotNullOrUndefined(color)) {
            object.id = color.id;
            object.alfa = color.alfa;
            object.color = color.color;
            object.color_img = color.color_img;
            object.name = color.name;
            object.code = color.code;
            object.RAL = color.RAL;
            object.type = color.type;
            object.types = color.types;
            object.groups = color.groups;
            object.sides = color.sides;
            object.isDefault = isDefault;
            object.visualization = color.visualization;
            if (type === 'none') {
                type = undefined;
            }
            object.group = String(type);
            if (
                conf.type
                && (conf.type as any) !== 'complementary_goods'
                && (conf.type as any) !== 'accessory'
                && conf.Frames
            ) {
                Object.assign(conf, this.validationService.valid(conf, 'colors'));
                this.eventBusService.post({
                    key: 'setConstructionColor',
                    value: {},
                    conf,
                });
            }
        }

        if (place === 'frame' && mode !== 'extension') {
            if (side === 'outer') {
                this.setOuterExtensionColorBasedOnFrameColor(conf);
            } else if (side === 'inner') {
                this.setInnerExtensionColorBasedOnFrameColor(conf);
            }
        }

        if (conf.System.lipping_color_based_on_frame_color && conf.lippingColor && conf.lippingColor.isDefault) {
            conf.lippingColor = {...conf.Colors.frame.outer, isDefault: conf.lippingColor.isDefault};
            conf.innerLippingColor = {...conf.Colors.frame.inner, isDefault: conf.innerLippingColor.isDefault};
        }
    }

    /**
     * Ustawia kolory przy przełączaniu innego koloru skrzydła.
     * @memberof ColorsService
     * @param {bool} refresh Czy odświeżać ceny, rysunek, itp.
     */
    setColorsSashExt(
        colorsSashExt = false,
        refresh = false,
        conf = this.configurationsService.conf.Current,
        confDefault = this.configurationsService.conf.Default
    ) {
        conf.ColorsSashExt = colorsSashExt;
        confDefault.ColorsSashExt = conf.ColorsSashExt;
        if (!conf.ColorsSashExt) {
            const object = conf.Colors;
            const objectd = confDefault.Colors;
            const eventId = this.eventBusService.pause(['setConstructionColor']);
            try {
                this.setColorSide(
                    object.sash.outer,
                    object.frame.outer,
                    object.frame.outer.group,
                    'outer',
                    'sash',
                    conf,
                    object.frame.outer.isDefault
                );
                this.setColorSide(
                    objectd.sash.outer,
                    objectd.frame.outer,
                    objectd.frame.outer.group,
                    'outer',
                    'sash',
                    conf,
                    objectd.frame.outer.isDefault
                );
                this.setColorSide(
                    object.sash.inner,
                    object.frame.inner,
                    object.frame.inner.group,
                    'inner',
                    'sash',
                    conf,
                    object.frame.inner.isDefault
                );
                this.setColorSide(
                    objectd.sash.inner,
                    objectd.frame.inner,
                    objectd.frame.inner.group,
                    'inner',
                    'sash',
                    conf,
                    objectd.frame.inner.isDefault
                );
                this.setColorSide(
                    object.sash.core,
                    object.frame.core,
                    object.frame.core.group,
                    'core',
                    'sash',
                    conf,
                    object.frame.core.isDefault
                );
                this.setColorSide(
                    objectd.sash.core,
                    objectd.frame.core,
                    objectd.frame.core.group,
                    'core',
                    'sash',
                    conf,
                    objectd.frame.core.isDefault
                );
                this.setColorSide(
                    object.sash.alushell,
                    object.frame.alushell,
                    object.frame.alushell.group,
                    'alushell',
                    'sash',
                    conf,
                    object.frame.alushell.isDefault
                );
                this.setColorSide(
                    objectd.sash.alushell,
                    objectd.frame.alushell,
                    objectd.frame.alushell.group,
                    'alushell',
                    'sash',
                    conf,
                    objectd.frame.alushell.isDefault
                );
            } finally {
                this.eventBusService.resume(['setConstructionColor'], eventId);
            }
        }

        if (!refresh) {
            this.handlesService.checkIsOneHandleAndAllHasHandle(conf);
            this.handlesService.setDefaultColorForHinge();
            this.priceService.count();
        }
    }

    setWhiteColor(
        colorType: 'outer' | 'inner' | 'core' | 'alushell',
        place: 'frame' | 'sash',
        type: number | 'none' | undefined,
        conf = this.configurationsService.conf.Current,
        confDefault = this.configurationsService.conf.Default
    ) {
        let color = null; 
        const object = conf.Colors;
        const objectd = confDefault.Colors;

        const colorsWin: IccColor[] = [];
        color = colorsWin.find(p => p.type === 'white') || {};

        if (color) {
            this.setColorSide(object[place][colorType], {}, type, colorType, place);
            this.setColorSide(objectd[place][colorType], {}, type, colorType, place);
        }

        this.setGlazingColorBasedOnColors(conf);
    }

    setGlazingColorBasedOnColors(conf = this.configurationsService.conf.Current) {
        conf.Sashes.forEach(sash => {
            if (sash.glazing.type === 'pvc_panels') {
                sash.glazing.selectedColor = core.copy(conf.Colors);
                sash.intSashes.forEach(intSash => {
                    if (intSash.glazing.type === 'pvc_panels') {
                        intSash.glazing.selectedColor = core.copy(conf.Colors);
                    }
                });
                this.eventBusService.post({
                    key: 'icc-redraw',
                    value: {}
                });
            }
        });
    }

    setDoubleSidedColor(
        colorType: 'outer' | 'inner' | 'core' | 'alushell',
        place: 'frame' | 'sash',
        type: number | 'none' | undefined,
        conf = this.configurationsService.conf.Current,
        confDefault = this.configurationsService.conf.Default
    ) {
        const object = conf.Colors;
        const objectd = confDefault.Colors;

        const color =
            this.configurationsService.conf.Current.Colors
            && this.configurationsService.conf.Current.Colors[place]
            && (this.configurationsService.conf.Current.Colors[place].outer
            && this.configurationsService.conf.Current.Colors[place].outer?.id
                ? this.configurationsService.conf.Current.Colors[place].outer
                : this.configurationsService.conf.Current.Colors[place].core);
        
        const { colors } = this.getSimpleColors();
        const availableColor = colors.find((c) => Number(c.id) === Number(color?.id));
        if (color) {
            this.setColorSide(object[place][colorType], availableColor, type, colorType, place);
            this.setColorSide(objectd[place][colorType], availableColor, type, colorType, place);
        }
        this.setGlazingColorBasedOnColors(conf);
    }

    setColor(
        colorType: 'outer' | 'inner' | 'core' | 'alushell',
        place: 'frame' | 'sash' | 'lipping',
        color: IccColor | 'none',
        type: number | 'none' | undefined,
        refresh: boolean,
        conf = this.configurationsService.conf.Current,
        confDefault = this.configurationsService.conf.Default,
        mode?: 'simple' | 'advanced' | 'extended' | 'door_extended' | 'extension' | false,
    ) {
        const object = conf.Colors;
        const objectd = confDefault.Colors;
        
        if (color === 'none' || color?.type === 'white') {
            color = {} as IccColor;
        }

        const eventId = this.eventBusService.pause(['setConstructionColor']);
        try {
            if (conf.System.type === 'pvc') {
                if (mode === 'advanced') {
                    if ((colorType === 'outer' || colorType === 'inner') && (place === 'frame' || place === 'sash')) {
                        this.setColorSide(objectd.sash[colorType], color, type, colorType, 'sash');
                        this.setColorSide(object.sash[colorType], color, type, colorType, 'sash');
                        this.setColorSide(object.frame[colorType], color, type, colorType, 'frame');
                        this.setColorSide(objectd.frame[colorType], color, type, colorType, 'frame');
                    } else if (colorType === 'alushell') {
                        this.setColorSide(object.frame[colorType], color, type, colorType, 'frame');
                        this.setColorSide(objectd.frame[colorType], color, type, colorType, 'frame');
                        this.setColorSide(object.sash[colorType], color, type, colorType, 'sash');
                        this.setColorSide(objectd.sash[colorType], color, type, colorType, 'sash');
                    }
                } else if (mode === 'extension') {
                    if (colorType === 'outer' && place === 'frame' && mode === 'extension') {
                        this.setColorSide(object.frame[colorType], color, type, colorType, 'frame', conf, false, mode);
                        this.setColorSide(objectd.frame[colorType], color, type, colorType, 'frame', conf, false, mode);
                        this.setColorSide(object.sash[colorType], color, type, colorType, 'sash', conf, false, mode);
                        this.setColorSide(objectd.sash[colorType], color, type, colorType, 'sash', conf, false, mode);
       
                        if (
                            (Object.keys(object.frame.inner).length === 0 && !(objectd.frame.inner.id) 
                                && Object.keys(object.sash.inner).length === 0 && !(objectd.sash.inner.id))
                            || Boolean(object.frame.inner.isDefault && objectd.frame.inner.isDefault
                                && object.sash.inner.isDefault && objectd.sash.inner.isDefault)
                            || (Object.keys(objectd.frame.inner).length === 0 && Object.keys(objectd.sash.inner).length === 0
                                && object.frame.inner.isDefault && object.sash.inner.isDefault)
                        ) {
                            this.setColorSide(object.frame.inner, color, type, 'inner', 'frame', conf, true, mode);
                            this.setColorSide(objectd.frame.inner, color, type, 'inner', 'frame', conf, true, mode);
                            this.setColorSide(object.sash.inner, color, type, 'inner', 'sash', conf, true, mode);
                            this.setColorSide(objectd.sash.inner, color, type, 'inner', 'sash', conf, true, mode);
                        }

                    } else if (colorType === 'inner' && place === 'frame' && mode === 'extension') {
                        this.setColorSide(object.frame[colorType], color, type, colorType, 'frame', conf, false, mode);
                        this.setColorSide(objectd.frame[colorType], color, type, colorType, 'frame', conf, false, mode);
                        this.setColorSide(object.sash[colorType], color, type, colorType, 'sash', conf, false, mode);
                        this.setColorSide(objectd.sash[colorType], color, type, colorType, 'sash', conf, false, mode);
                    }
                } else {
                    if ((colorType === 'outer' && place === 'sash') || (colorType === 'inner' && place === 'sash')) {
                        this.setColorSide(object.frame[colorType], color, type, colorType, 'frame');
                        this.setColorSide(objectd.frame[colorType], color, type, colorType, 'frame');
                        this.setColorSide(object.sash[colorType], color, type, colorType, 'sash');
                        this.setColorSide(objectd.sash[colorType], color, type, colorType, 'sash');
                    } else if (colorType === 'outer' && place === 'frame' || colorType === 'inner' && place === 'frame') {
                        this.setColorSide(object.frame[colorType], color, type, colorType, 'frame', conf, false, mode);
                        this.setColorSide(objectd.frame[colorType], color, type, colorType, 'frame', conf, false, mode);
                        this.setColorSide(object.sash[colorType], color, type, colorType, 'sash', conf, false, mode);
                        this.setColorSide(objectd.sash[colorType], color, type, colorType, 'sash', conf, false, mode);
                    } else if (colorType === 'alushell' && place === 'sash') {
                        this.setColorSide(object.sash[colorType], color, type, colorType, 'sash');
                        this.setColorSide(objectd.sash[colorType], color, type, colorType, 'sash');
                        this.setColorSide(object.frame[colorType], color, type, colorType, 'frame');
                        this.setColorSide(objectd.frame[colorType], color, type, colorType, 'frame');
                    } else if (colorType === 'alushell' && place === 'frame') {
                        this.setColorSide(object.frame[colorType], color, type, colorType, 'frame');
                        this.setColorSide(objectd.frame[colorType], color, type, colorType, 'frame');
                    }
                }
            } else if ((conf.System.type === 'wood' || conf.System.type === 'alu')) {
                if (mode === 'advanced' && place === 'frame') {
                    if (conf.Colors.frame?.outer?.id === conf.Colors.frame?.inner?.id && colorType === 'outer') {
                        this.setColorSide(object.sash.inner, color, type, 'inner', 'sash', conf, conf.Colors.sash.inner.isDefault);
                        this.setColorSide(objectd.sash.inner, color, type, 'inner', 'sash', conf, conf.Colors.sash.inner.isDefault);
                        this.setColorSide(object.frame.inner, color, type, 'inner', 'frame', conf, conf.Colors.frame.inner.isDefault);
                        this.setColorSide(objectd.frame.inner, color, type, 'inner', 'frame', conf, conf.Colors.frame.inner.isDefault);
                    }
                    this.setColorSide(object.sash[colorType], color, type, colorType, 'sash');
                    this.setColorSide(objectd.sash[colorType], color, type, colorType, 'sash');
                    this.setColorSide(object.frame[colorType], color, type, colorType, 'frame');
                    this.setColorSide(objectd.frame[colorType], color, type, colorType, 'frame');
                } else if (colorType === 'inner' && place === 'frame') {
                    this.setColorSide(object.frame[colorType], color, type, colorType, 'frame', conf, conf.Colors.frame.inner.isDefault);
                    this.setColorSide(objectd.frame[colorType], color, type, colorType, 'frame', conf, conf.Colors.frame.inner.isDefault);
                } else if (colorType === 'inner') {
                    this.setColorSide(object.sash[colorType], color, type, colorType, 'sash', conf, conf.Colors.sash.inner.isDefault);
                    this.setColorSide(objectd.sash[colorType], color, type, colorType, 'sash', conf, conf.Colors.sash.inner.isDefault);
                    this.setColorSide(object.frame[colorType], color, type, colorType, 'frame', conf, conf.Colors.frame.inner.isDefault);
                    this.setColorSide(objectd.frame[colorType], color, type, colorType, 'frame', conf, conf.Colors.frame.inner.isDefault);
                } else {
                    if (place === 'sash') {
                        this.setColorSide(object.sash[colorType], color, type, colorType, 'sash');
                        this.setColorSide(objectd.sash[colorType], color, type, colorType, 'sash');
                        this.setColorSide(object.frame[colorType], color, type, colorType, 'frame');
                        this.setColorSide(objectd.frame[colorType], color, type, colorType, 'frame');
                    }
                    if (place === 'frame') {
                        this.setColorSide(object.frame[colorType], color, type, colorType, 'frame', conf, conf.Colors.frame.outer.isDefault);
                        this.setColorSide(objectd.frame[colorType], color, type, colorType, 'frame', conf, conf.Colors.frame.outer.isDefault);
                    }
                }
            } else if (!conf.ColorsSashExt || conf.type === 'window') {
                this.setColorSide(object.frame[colorType], color, type, colorType, 'frame');
                this.setColorSide(objectd.frame[colorType], color, type, colorType, 'frame');
                this.setColorSide(object.sash[colorType], color, type, colorType, 'sash');
                this.setColorSide(objectd.sash[colorType], color, type, colorType, 'sash');
            } else {
                this.setColorSide(object[place][colorType], color, type, colorType, place);
                this.setColorSide(objectd[place][colorType], color, type, colorType, place);
            }
        } finally {
            this.eventBusService.resume(['setConstructionColor'], eventId);
        }

        if (place === "frame" || place === "sash" && mode !== 'extension') {
            if (colorType === 'outer') {
                this.setOuterExtensionColorBasedOnFrameColor(conf);
            } else if (colorType === 'inner') {
                this.setInnerExtensionColorBasedOnFrameColor(conf);
            }
        }

        if (!refresh) {
            this.validateColors(object, colorType, place, color, type);

            if (
                this.currentConfiguratorService.conf === 'window'
                || this.currentConfiguratorService.conf === 'hs'
                || this.currentConfiguratorService.conf === 'sliding_door'
                || this.currentConfiguratorService.conf === 'folding_door'
            ) {
                this.rollerColorsService.setDefaultsColorsOnChangeColor('window');
            }
            if (
                this.currentConfiguratorService.conf === 'window'
                || this.currentConfiguratorService.conf === 'hs'
                || this.currentConfiguratorService.conf === 'sliding_door'
                || this.currentConfiguratorService.conf === 'folding_door'
                || this.currentConfiguratorService.conf === 'door'
            ) {
                this.handlesService.checkIsOneHandleAndAllHasHandle(conf);
                this.handlesService.setDefaultColorForHinge();
                this.constructionLimitationService.findReinforcement(conf);
                this.priceService.count();
            }
        }

        this.eventBusService.post({
            key: 'icc-redraw',
            value: 'frame',
        });
    }

    validateColors(colors: IccColors, colorType, place, color, type, confDefault = this.configurationsService.conf.Default, conf = this.configurationsService.conf.Current) {
        if (
            colorType === 'outer' && color && Object.keys(color).length > 0
        ) {
            if (!color.id && !this.isColorFitToSide(colors[place].inner, place, 'inner', 'inner')) {
                this.setColor('inner', place, this.getDefaultColorForSide('inner', place, 'inner', conf), type, true, conf, confDefault);
            }
            if (conf.Colors[place].inner?.isDefault && (conf.System.type !== 'pvc') && color.sides.includes(`${place[0].toUpperCase()}|B`)
                || (place === 'frame' && this.onlyDoubleSidedFrameColor(conf))) {
                this.setColor('inner', place, color, type, true, conf, confDefault);

                if (conf.System.type === 'alu' || conf.System.type === 'wood') {
                    this.setColor('inner', 'sash', color, type, true, conf, confDefault);
                }
            }
            if (color.sides?.includes(`${place[0].toUpperCase()}|B`)
                && !color.sides?.includes(`${place[0].toUpperCase()}|O`)
                && !color.sides?.includes(`${place[0].toUpperCase()}|DO`)
            ) {
                this.setColor('inner', place, color, type, true, conf, confDefault);
            }
            if (color.sides?.includes(`${place[0].toUpperCase()}|O`)
                && !color.sides?.includes(`${place[0].toUpperCase()}|DO`)
                && !color.sides?.includes(`${place[0].toUpperCase()}|B`)
                && !this.isColorFitToSide(colors[place].inner, place, 'inner', 'outer')
            ) {
                this.setColor('inner', place, this.getDefaultColorForSide('inner', place, 'outer', conf), type, true, conf, confDefault);
            }
            if (color.sides?.includes(`${place[0].toUpperCase()}|DO`)
                && !color.sides?.includes(`${place[0].toUpperCase()}|O`)
                && !color.sides?.includes(`${place[0].toUpperCase()}|B`)
                && !this.isColorFitToSide(colors[place].inner, place, 'inner', 'bicolor')
            ) {
                this.setColor('inner', place, this.getDefaultColorForSide('inner', place, 'bicolor', conf), type, true, conf, confDefault);
            }
        } 
        if (
            colorType === 'inner' && color && Object.keys(color).length > 0
        ) {
            if (!color.id && !this.isColorFitToSide(colors[place].inner, place, 'outer', 'outer')) {
                this.setColor('outer', place, this.getDefaultColorForSide('outer', place, 'outer', conf), type, true, conf, confDefault);
            }
            if (color.sides?.includes(`${place[0].toUpperCase()}|B`)
                && !color.sides?.includes(`${place[0].toUpperCase()}|I`)
                && !color.sides?.includes(`${place[0].toUpperCase()}|DI`)
            ) {
                this.setColor('outer', place, color, type, true, conf, confDefault);
            }
            if (color.sides?.includes(`${place[0].toUpperCase()}|I`)
                && !color.sides?.includes(`${place[0].toUpperCase()}|DI`)
                && !color.sides?.includes(`${place[0].toUpperCase()}|B`)
                && !this.isColorFitToSide(colors[place].inner, place, 'outer', 'inner')
            ) {
                this.setColor('outer', place, this.getDefaultColorForSide('outer', place, 'inner', conf), type, true, conf, confDefault);
            }
            if (color.sides?.includes(`${place[0].toUpperCase()}|DI`)
                && !color.sides?.includes(`${place[0].toUpperCase()}|I`)
                && !color.sides?.includes(`${place[0].toUpperCase()}|B`)
                && !this.isColorFitToSide(colors[place].inner, place, 'outer', 'bicolor')
            ) {
                this.setColor('outer', place, this.getDefaultColorForSide('outer', place, 'bicolor', conf), type, true, conf, confDefault);
            }
        }
    }

    /**
     * Ustawia rodzaj drewna
     * @memberof ColorsService
     * @param {object} wood Rodzaj drewna.
     */
    setWood(
        wood,
        conf = this.configurationsService.conf.Current,
        confDefault = this.configurationsService.conf.Default,
        callback
    ) {
        conf.Wood = core.copy(wood);
        confDefault.Wood = core.copy(wood);

        if (typeof callback === 'function') {
            callback();
        } else {
            this.setDefaults();
        }
    }

    /**
     * Otwiera popup z wyborem koloru.
     * @memberof ColorsService
     * @param  {string} type  Strona konstrukcji (outer, inner, core, alushell)
     * @param  {string} place Miejsce - skrzydło, rama
     */
    openModalColor(
        type: 'outer' | 'inner' | 'core' | 'alushell',
        place: 'frame' | 'sash',
        conf = this.configurationsService.conf.Current,
        filterColors: ((color?: IccColorGroup) => boolean) | null = null,
        refreshConf = true,
        novalidate = false
    ) {
        let side: string[] = [place[0].toUpperCase() + '|' + type[0].toUpperCase()];
        if (
            ['inner', 'outer'].indexOf(type) > -1
        ) {
            side = [
                ...side,
                place[0].toUpperCase() + '|B',
                place[0].toUpperCase() + '|D' + type[0].toUpperCase()
            ];
        }
        const colorsWin: IccColor[] = [];
        let groups: (number | string)[] = [];

        this.windowColors
            .filter(color => {
                let match = false;
                if (isArray(color.sides)) {
                    match = side.every(s => color.sides.indexOf(s) > -1);
                }
                return match;
            })
            .forEach(color => {
                if (isArray(color.groups)) {
                    colorsWin.push(color);
                    groups = groups.concat(
                        color.groups.filter(group => groups.indexOf(group) === -1)
                    );
                }
            });

        const modalInstance = this.modalService.open({
            templateUrl: 'modalColorPVC.html',
            controller: 'ModalColorPVCCtrl as mColorPVC',
            pageComponent: ColorsPageComponent,
            resolve: {
                colors: () => colorsWin,
                selectedColor: () => conf.Colors[place][type],
                colorGroups: () =>
                    (this.configuratorsDataService.data.windowColorGroups || []).filter(el => {
                        let val =
                            groups.indexOf(el.id) > -1
                            && ((isArray(el.systems)
                                && el.systems.indexOf(conf.System.id) > -1
                                && el.target.indexOf('show') > -1
                                && (conf.System.type !== 'wood'
                                    || (isArray(el.woodTypes)
                                        && el.woodTypes.indexOf(conf.Wood.id) > -1)))
                                || (filterColors && filterColors(el)));

                        const groupSides = (el.sides || []).filter(Boolean);
                        if (groupSides.length) {
                            val = val && side.some(s => groupSides.includes(s as Side));
                        }

                        return val;
                    }),
                colorsSashExt: () => conf.ColorsSashExt,
                type: () => type,
            },
        });

        modalInstance.result.then(data => {
            if (isObject(data)) {
                let defaultConf: any = this.configurationsService.conf.Default;

                if (!isObject(this.configurationsService.conf.Default.Colors) && conf) {
                    defaultConf = {
                        Colors: {
                            frame: {
                                outer: {},
                                inner: {},
                                core: {},
                                alushell: {},
                            },
                            sash: {
                                outer: {},
                                inner: {},
                                core: {},
                                alushell: {},
                            },
                        },
                    };
                }
                this.setColor(type, place, data.color, data.group, !refreshConf, conf, defaultConf);
                this.refreshFillingsColors(novalidate, conf);
            }
            this.eventBusService.post({
                key: 'icc-redraw',
                value: 'frame',
            });
        });

        modalInstance.closed.then(() => {
            if (this.config().IccConfig.Configurators.tutorialAvailable) {
                this.eventBusService.post({
                    key: 'tutorialSteps',
                    value: 'getStepImg',
                });
            }
        });
    }

    getPlaceSide(type: 'outer' | 'inner' | 'alushell' | 'core', place) {
        let side: Side[] = [];
        if (type === 'outer') {
            side = [
            (place[0].toUpperCase() + '|O') as Side,
                (place[0].toUpperCase() + '|DO') as Side,
                (place[0].toUpperCase() + '|B') as Side,
                (place[0].toUpperCase() + '|C') as Side,
            ];
        } else if (type === 'inner') {
            side = [
                (place[0].toUpperCase() + '|B') as Side,
                (place[0].toUpperCase() + '|' + type[0].toUpperCase()) as Side,
                (place[0].toUpperCase() + '|D' + type[0].toUpperCase()) as Side,
                (place[0].toUpperCase() + '|C') as Side,
            ];
        } else if (type === 'alushell') {
            side = [(place[0].toUpperCase() + '|' + type[0].toUpperCase()) as Side];
        } else if (type === 'core') {
            side = [(place[0].toUpperCase() + '|' + type[0].toUpperCase()) as Side];
        }

        return side;
    }

    openModalColorSimple(
        type: 'outer' | 'inner' | 'alushell' | 'core',
        place: 'frame' | 'sash' | 'lipping',
        conf = this.configurationsService.conf.Current,
        filterColors?: (color?: IccColorGroup) => boolean,
        refreshConf = true,
        novalidate = false,
        onSelect?: (color?: IccColor) => void,
        selectedColor?: Partial<IccColor> | null,
        mode?: 'simple' | 'advanced' | 'extended' | 'door_extended' | 'extension' | null,
    ) {
        const typec: 'outer' | 'inner' | 'alushell' | 'core' = type;
        const side = this.getPlaceSide(type, place);

        const colorsWin: IccColor[] = [];
        const groups = this.filterWindowColorGroups(side, colorsWin, type);
        
        let colorInPlaceType;
        if (place !== 'lipping' || (place === 'lipping' && conf.System.lipping_color_based_on_frame_color)) {
            colorInPlaceType = conf.Colors[place][type];
        }
        const modalInstance = this.modalService.open({
            templateUrl: 'modalColorPVC.html',
            controller: 'ModalColorPVCCtrl as mColorPVC',
            pageComponent: ColorsPageComponent,
            resolve: {
                colors: () => colorsWin,
                selectedColor: () =>
                    selectedColor && selectedColor.id
                        ? selectedColor
                        : colorInPlaceType && colorInPlaceType.id
                        ? colorInPlaceType
                        : conf.Colors[place]['core'],
                colorGroups: () => this.getColorGroups(groups, filterColors, side),
                colorsSashExt: () => conf.ColorsSashExt,
                type: () => type,
            },
        });

        modalInstance.closed.then(() => {
            if (this.config().IccConfig.Configurators.tutorialAvailable) {
                this.eventBusService.post({
                    key: 'tutorialSteps',
                    value: 'getStepImg',
                });
            }
        });

        return modalInstance.result.then(data => {
            if (isObject(data)) {
                if (onSelect) {
                    onSelect(data.color);
                } else {
                    let defaultConf: any = this.configurationsService.conf.Default;

                    if (!isObject(this.configurationsService.conf.Default.Colors) && conf) {
                        defaultConf = {
                            Colors: {
                                frame: {
                                    outer: {},
                                    inner: {},
                                    core: {},
                                    alushell: {},
                                },
                                sash: {
                                    outer: {},
                                    inner: {},
                                    core: {},
                                    alushell: {},
                                },
                            },
                        };
                    }
                    this.setColor(
                        typec,
                        place,
                        data.color,
                        data.group,
                        !refreshConf,
                        conf,
                        defaultConf,
                        mode,
                    );
                    this.refreshFillingsColors(novalidate, conf);
                }
            }

            if (place === 'sash' && type === 'inner') {
                conf.Colors.sash.inner.isDefault = false;
            }

            if (place === 'frame' && type === 'inner') {
                conf.Colors.frame.inner.isDefault = false;
            }
    
            if (place === 'frame' && type === 'outer') {
                conf.Colors.frame.outer.isDefault = false;
            }

            this.eventBusService.post({
                key: 'icc-redraw',
                value: 'frame',
            });
        });
    }

    filterWindowColorGroups(side, colorsWin, type, conf = this.configurationsService.conf.Current) {
        const { lippingPriceLevelId, lippingPriceLevelGroupIds } = this.getLippingPriceLevelGroupIds(conf, side);

        let groups: (number | string)[] = [];
        this.windowColors
            .filter(color => {
                let match = false;
                if (isArray(color.sides)) {
                    match = side.some(s => color.sides.indexOf(s) > -1);
                }
                if (color.RAL && type === 'alushell') {
                    match = true;
                }
                return match;
            })
            .forEach(color => {
                if (isArray(color.groups)) {

                    if (lippingPriceLevelId && lippingPriceLevelGroupIds) {
                        if (color.groups.some(g => lippingPriceLevelGroupIds.includes(g))) {
                            colorsWin.push(color);
                            groups = groups.concat(
                                color.groups.filter(group => groups.indexOf(group) === -1)
                            );
                        }
                    } else {
                        colorsWin.push(color);
                        groups = groups.concat(
                            color.groups.filter(group => groups.indexOf(group) === -1)
                        );
                    }
                }
            });

        return groups;
    }

    getColorGroups(groups, filterColors, side, conf = this.configurationsService.conf.Current) {
        const windowColorGroups = (this.configuratorsDataService.data.windowColorGroups || []).filter(el => {
            let val =
                groups.indexOf(el.id) > -1
                && (filterColors
                    ? filterColors(el)
                    : isArray(el.systems)
                      && el.systems.indexOf(conf.System.id) > -1
                      && el.target.indexOf('show') > -1
                      && (conf.System.type !== 'wood'
                          || (isArray(el.woodTypes)
                              && el.woodTypes.indexOf(conf.Wood.id) > -1)));

            const groupSides = (el.sides || []);
            if (groupSides.length) {
                val = val && side.some(s => groupSides.includes(s));
            }

            return val;
        });
        if (conf.System?.door_type) {
            const sashHeight = this.sizesService.getSashHeightInRebateBasedOnConstructionHeight(conf);
            const sashWidth = this.sizesService.getSashWidthInRebateBasedOnConstructionWidth(conf)
            return windowColorGroups.filter(p => 
                (this.colorRestrictionService.isColorGroupNotRestricted(p)) ||
                (this.colorRestrictionService.isColorGroupBetweenMinAndMaxMetalSheetWidth(p, sashWidth)) &&
                (this.colorRestrictionService.isColorGroupBetweenMinAndMaxMetalSheetHeight(p, sashHeight))
            );
        } else {
            return windowColorGroups;
        }
    }

    getColorGroupById(id: number) {
        return this.configuratorsDataService.data.windowColorGroups.find(group => Number(group.id) === id);
    }

    getColorsFromColorGroups(colorGroupId: number) {
        if (colorGroupId && Boolean(this.windowColors.length)) {
            return this.windowColors.filter(p => Array.isArray(p?.groups) && p.groups.some(g => Number(g) === Number(colorGroupId)))
        }
    }

    getSimpleColors(
        conf = this.configurationsService.conf.Current,
        side: Side[] = [Side.FC, Side.FB]
    ) {
        const { lippingPriceLevelId, lippingPriceLevelGroupIds } = this.getLippingPriceLevelGroupIds(conf, side);

        const colorsWin: IccColor[] = [];
        let groups: (number | string)[] = [];

        this.windowColors
        .filter(color => {
            let match = false;
            if (isArray(color.sides)) {
                match = side.some(s => color.sides.indexOf(s) > -1);
            }
            if (color.RAL && side.includes(Side.FA)) {
                match = true;
            }
            return match;
        })
        .forEach(color => {
            if (isArray(color.groups)) {

                if (lippingPriceLevelId && lippingPriceLevelGroupIds) {
                    if (color.groups.some(g => lippingPriceLevelGroupIds.includes(g))) {
                        colorsWin.push(color);
                        groups = groups.concat(
                            color.groups.filter(group => groups.indexOf(group) === -1)
                        );
                    }
                } else {
                    colorsWin.push(color);
                    groups = groups.concat(
                        color.groups.filter(group => groups.indexOf(group) === -1)
                    );
                }

            }
        });
        const colorGroups = (this.configuratorsDataService.data.windowColorGroups || []).filter(
            el => {
                let val =
                    groups.indexOf(el.id) > -1
                    && (isArray(el.systems)
                        && el.systems.indexOf(conf.System.id) > -1
                        && el.target.indexOf('show') > -1
                        && (conf.System.type !== 'wood'
                            || (isArray(el.woodTypes) && el.woodTypes.indexOf(conf.Wood.id) > -1)));

                const groupSides = (el.sides || []).filter(Boolean);
                if (groupSides.length) {
                    val = val && side.some(s => groupSides.includes(s));
                }

                return val;
            }
        );
        return {
            colors: colorsWin,
            groups: colorGroups,
        };
    }

    getLippingPriceLevelGroupIds(conf: WindowActiveConfiguration, side: Side[]) {
        let lippingPriceLevelId = null;
        let priceLevels = null;
        let lippingPriceLevelGroupIds = null;

        if (
            !conf.System?.lipping_color_based_on_frame_color && side.some(k => (k[0]).includes('L'))) {
            lippingPriceLevelId = Number(conf.System.lipping_price_level_id);
        }

        if (lippingPriceLevelId) {
            priceLevels = this.configuratorsDataService.data.priceLevels.find(k => k.id === lippingPriceLevelId);
            lippingPriceLevelGroupIds = priceLevels && Array.from(new Set(priceLevels.price_levels.map(p => p.colorGroup)));
        }
        return { lippingPriceLevelId, lippingPriceLevelGroupIds };
    }

    hasDecoPanel(conf = this.configurationsService.conf.Current) {
        return conf.Sashes.some(
            s =>
                (s.type.type === 'DRA'
                    || s.type.type === 'DRP'
                    || s.type.type === 'DOA'
                    || s.type.type === 'DOP')
                && s.glazing
                && s.glazing.type === (conf.System.door_type ? 'door_panels' : 'deco_panels')
        );
    }

    setSimpleColors(color: IccColor, place?: string,
            side?: 'frame' | 'sash' | 'innerFrame' | 'outerFrame' | 'innerSash' | 'innerFillingFirst',
            mode?: 'simple' | 'advanced' | 'extended' | 'door_extended',
            conf = this.configurationsService.conf.Current) {
        let defaultConf: any = this.configurationsService.conf.Default;
        if (!isObject(this.configurationsService.conf.Default.Colors) && conf) {
            defaultConf = {
                Colors: {
                    frame: {
                        outer: {},
                        inner: {},
                        core: {},
                        alushell: {},
                    },
                    sash: {
                        outer: {},
                        inner: {},
                        core: {},
                        alushell: {},
                    },
                },
            };
        }
        const object = conf.Colors;
        const objectd = defaultConf.Colors;
        conf.innerLippingColor = {...conf.lippingColor};

        if ((!place || place === 'frame') && conf.System.type === 'pvc' && this.hasDecoPanel(conf)) {
            if (color.type !== 'white' && color.type !== 'cream') {
                if (mode === 'advanced') {
                    this.setColorSide(object.frame.inner, color, undefined, 'inner', 'frame');
                    this.setColorSide(objectd.frame.inner, color, undefined, 'inner', 'frame');
                    this.setColorSide(object.sash.inner, color, undefined, 'inner', 'sash');
                    this.setColorSide(objectd.sash.inner, color, undefined, 'inner', 'sash');
                } else if (mode === 'extended') {
                    if (side === 'innerSash') {
                        this.setColorSide(object.sash.inner, color, undefined, 'inner', 'sash');
                        this.setColorSide(objectd.sash.inner, color, undefined, 'inner', 'sash');
                    }
                    if (side ==='innerFrame') {
                        this.setColorSide(object.frame.inner, color, undefined, 'inner', 'frame');
                        this.setColorSide(objectd.frame.inner, color, undefined, 'inner', 'frame');
                    }
                }
            } else {
                this.setColor('core', 'frame', color, undefined, false, conf, defaultConf);
                this.setColor('outer', 'frame', 'none', undefined, false, conf, defaultConf);
                this.setColor('inner', 'frame', 'none', undefined, false, conf, defaultConf);
            } 
        } else if (place === 'sash') {
            if (color.type !== 'white' && color.type !== 'cream') {
                this.setColor('outer', 'sash', color, undefined, false, conf, defaultConf);
            } else {
                this.setColor('core', 'sash', color, undefined, false, conf, defaultConf);
                this.setColor('outer', 'sash', 'none', undefined, false, conf, defaultConf);
                this.setColor('inner', 'sash', 'none', undefined, false, conf, defaultConf);
            } 
        }
        if ((!place || place === 'frame') && (!this.hasDecoPanel() || conf.System.door_type)) {
            if (color.type !== 'white' && color.type !== 'cream') {
                this.setColor('outer', 'frame', color, undefined, false, conf, defaultConf);
                this.setColor('inner', 'frame', color, undefined, false, conf, defaultConf);
                
                if (conf.ColorsSashExt) {
                    this.setColor('outer', 'sash', color, undefined, false, conf, defaultConf);
                    this.setColor('inner', 'sash', color, undefined, false, conf, defaultConf);
                }
                
            } else {
                this.setColor('core', 'frame', color, undefined, false, conf, defaultConf);
                this.setColor('outer', 'frame', 'none', undefined, false, conf, defaultConf);
                this.setColor('inner', 'frame', 'none', undefined, false, conf, defaultConf);
            } 
        } else if (place === 'sash') {
            if (color.type !== 'white' && color.type !== 'cream') {
                this.setColor('outer', 'sash', color, undefined, false, conf, defaultConf);
                this.setColor('inner', 'sash', color, undefined, false, conf, defaultConf);
            } else {
                this.setColor('core', 'sash', color, undefined, false, conf, defaultConf);
                this.setColor('outer', 'sash', 'none', undefined, false, conf, defaultConf);
                this.setColor('inner', 'sash', 'none', undefined, false, conf, defaultConf);
            } 
        }
        if (conf.HasAlushell) {
            const alushellColor = this.getAlushellColor(color.id, conf);
            this.setColor('alushell', 'frame', alushellColor, undefined, false, conf, defaultConf);
        }
        if (conf.System.type !== 'pvc') {
            this.refreshFillingsColors(false, conf);
        }
    }

    getAlushellColor(colorId?, conf = this.configurationsService.conf.Current) {
        let color;
        const matchedColors = this.colorMappingService.getColors(
            Number(colorId),
            'window',
            'window'
        );
        const colors = this.getSimpleColors(conf, [Side.FA]);
        const windowColors = matchedColors
            .map(m => colors.colors.filter(c => Number(c.id) === m)[0])
            .filter(m => m);
        if (Common.isArray(windowColors) && Common.isDefined(windowColors[0])) {
            color = core.copy(windowColors[0]);
        } else {
            color = core.copy(colors[0]);
        }
        if (color) {
            color.isDefault = true;
        }
        return color;
    }

    refreshFillingsColors(novalidate: boolean, conf: WindowActiveConfiguration) {
        if (!novalidate && conf.Sashes) {
            conf.Sashes.forEach(sash => {
                if (sash.glazing.type === 'pvc_panels') {
                    sash.glazing.selectedColor = core.copy(conf.Colors);
                    sash.intSashes.forEach(intSash => {
                        if (intSash.glazing.type === 'pvc_panels') {
                            intSash.glazing.selectedColor = core.copy(conf.Colors);
                        }
                    });
                    this.eventBusService.post({
                        key: 'changedSashes',
                        value: {},
                    });
                }
                if (
                    sash.glazing.type === 'deco_panels'
                    || (sash.glazing.type === 'door_panels' && !conf.System.door_type)
                ) {
                    this.fillingsService.setDefaultDecoFillingColors(conf, sash.glazing, this.fillingsService.isDoorPassiveSash(sash) ? 'passive' : 'active');
                    sash.intSashes.forEach(intSash => {
                        if (
                            intSash.glazing.type === 'deco_panels'
                            || intSash.glazing.type === 'door_panels'
                        ) {
                            this.fillingsService.setDefaultDecoFillingColors(conf, intSash.glazing, this.fillingsService.isDoorPassiveSash(sash) ? 'passive' : 'active');
                        }
                    });
                    this.eventBusService.post({
                        key: 'changedSashes',
                        value: {},
                    });
                    this.eventBusService.post({
                        key: 'redraw',
                        value: {},
                    });
                }
            });
        }
    }

    /**
     * Otwiera popup z wyborem rodzaju drewna.
     * @memberof ColorsService
     */
    openModalWood(
        conf = this.configurationsService.conf.Current,
        confDefault = this.configurationsService.conf.Default,
        callback?: () => void
    ) {
        const modalInstance = this.modalService.open({
            templateUrl: 'modalWood.html',
            controller: 'ModalWoodCtrl as wood',
            pageComponent: WoodsPageComponent,
            resolve: {
                woods: () => this.woods,
                selWood: () => conf.Wood,
            },
        });

        modalInstance.result.then(selectedWood => {
            if (isObject(selectedWood)) {
                this.setWood(selectedWood, conf, confDefault, callback);
            }
        });
    }

    /**
     * Sprawdza poprawność kolorów konstrukcji.
     * @return {bool} Poprawność kolorów konstrukcji.
     */
    validate() {
        if (this.configurators.indexOf(this.currentConfiguratorService.conf) === -1) {
            this.issuesService.unregister(
                'no-window-colors',
                this.configurationsService.conf.Current
            );
            this.issuesService.unregister(
                'no-window-alushell-color',
                this.configurationsService.conf.Current
            );
            return true;
        }
        let colors = this.configurationsService.conf.Current.Colors;
        if ((this.configurationsService.conf.Current.System || {}).type === 'alu') {
            if (
                isUndefined(colors.frame.outer.id)
                || isUndefined(colors.sash.outer.id)
                || isUndefined(colors.frame.inner.id)
                || isUndefined(colors.sash.inner.id)
            ) {
                this.issuesService.simpleRegister(
                    'no-window-colors',
                    'Kolory nie zostały określone.',
                    this.translateService.instant('COLOR|Kolory nie zostały określone.'),
                    this.configurationsService.conf.Current,
                    {
                        logLevel: IssueLevel.NONE,
                    }
                );
                return false;
            }
        } else {
            if (
                (this.configurationsService.conf.Current.System || {}).type === 'pvc'
                && (isUndefined(colors.frame.core.id) || isUndefined(colors.sash.core.id))
            ) {
                this.issuesService.simpleRegister(
                    'no-window-colors',
                    'Kolory nie zostały określone.',
                    this.translateService.instant('WINDOW|Kolor okna nie został określony.'),
                    this.configurationsService.conf.Current,
                    {
                        logLevel: IssueLevel.NONE,
                    }
                );
                return false;
            }
        }
        if (
            this.configurationsService.conf.Current.HasAlushell
            && ((this.config().IccConfig.Configurators.alushellExt
                && this.configurationsService.conf.Current.AlushellType !== 'brushed')
                || !this.config().IccConfig.Configurators.alushellExt)
            && (isUndefined(colors.frame.alushell.id) || isUndefined(colors.sash.alushell.id))
        ) {
            this.issuesService.simpleRegister(
                'no-window-alushell-color',
                'Wybierz kolor nakładki.',
                this.translateService.instant('WINDOW|Wybierz kolor nakładki.'),
                this.configurationsService.conf.Current,
                {
                    logLevel: IssueLevel.NONE,
                }
            );
            return false;
        }
        colors = null;
        this.issuesService.unregister('no-window-colors', this.configurationsService.conf.Current);
        this.issuesService.unregister(
            'no-window-alushell-color',
            this.configurationsService.conf.Current
        );
        return true;
    }

    /**
     * Zwraca kolor wybrany w wizualizatorze.
     * @return {boolean|string} Nazwa koloru lub false kiedy nie wybrany.
     */
    getColorFromVisualizer() {
        const visColors = core.parseJson(localStorage.getItem('colors'));
        let windowColor;
        let visColor;
        const timeLimit = new Date(new Date().getTime() - 24 * 60 * 60 * 1000); // doba wstecz

        if (isUndefined(visColors.time) || new Date(visColors.time) >= timeLimit) {
            if (
                this.currentConfiguratorService.conf === 'window'
                || this.currentConfiguratorService.conf === 'hs'
                || this.currentConfiguratorService.conf === 'sliding_door'
                || this.currentConfiguratorService.conf === 'folding_door'
            ) {
                visColor = visColors.okna;
            } else if (this.currentConfiguratorService.conf === 'door') {
                visColor = visColors.drzwi;
            }
        }

        if (isString(visColor)) {
            if (visColor.indexOf('okleiny/') > -1) {
                windowColor = visColor.replace('okleiny/', '');
            } else {
                windowColor = visColor.substring(1);
            }
            return windowColor;
        } else {
            return false;
        }
    }

    clearAlushellColor() {
        this.configurationsService.conf.Current.Colors.frame.alushell = {};
        this.configurationsService.conf.Current.Colors.sash.alushell = {};
        this.configurationsService.conf.Default.Colors.frame.alushell = {};
        this.configurationsService.conf.Default.Colors.sash.alushell = {};
        this.eventBusService.post({
            key: 'icc-redraw',
            value: 'frame',
        });
    }

    setAlushellColor(colorType = '', conf = this.configurationsService.conf.Current, defaultConf = this.configurationsService.conf.Default) {
        if (this.configurationsService.conf.Current.HasAlushell) {
            if (!this.configurationsService.conf.Current.Colors.frame.alushell.id) {
                if (colorType === 'brushed' || this.config().IccConfig.Configurators.alushellExt) {
                    this.configurationsService.conf.Current.AlushellType = 'brushed';
                    const windowColors = this.configuratorsDataService.data.windowColorsAll || [];
                    const colorsGroups = this.configuratorsDataService.data.windowColorGroups || [];

                    const brushedAluGroup = colorsGroups.find(
                        group => group.brushed_alu_group === true
                    );
                    if (!isObject(brushedAluGroup)) {
                        this.clearAlushellColor();
                        this.infoService.showInfo(
                            this.translateService.instant(
                                'WINDOW|Brak zdefiniowanej grupy dla aluminium szczotkowanego'
                            ),
                            null
                        );
                        return;
                    }

                    const brushedAluColor = windowColors.find(
                        color =>
                            color.groups != null && color.groups.indexOf(brushedAluGroup.id) > -1
                    );

                    if (brushedAluColor && isObject(brushedAluColor)) {
                        this.setColor(
                            'alushell',
                            'frame',
                            brushedAluColor,
                            Number(brushedAluGroup.id),
                            true
                        );
                        if (this.configurationsService.conf.Current.ColorsSashExt) {
                            this.setColor(
                                'alushell',
                                'sash',
                                brushedAluColor,
                                Number(brushedAluGroup.id),
                                true
                            );
                        }
                    } else {
                        this.clearAlushellColor();
                        this.infoService.showInfo(
                            this.translateService.instant(
                                'WINDOW|Brak przypisanego koloru do grupy dla nakładki aluminiowej szczotkowanej'
                            ),
                            null
                        );
                        return;
                    }
                } else {
                    this.setDefaultAlushellColor(conf, defaultConf);
                }
            } else if (colorType === 'painted') {
                this.clearAlushellColor();
                this.eventBusService.post({
                    key: 'alushellColorTypeChange',
                    value: {},
                });
                this.configurationsService.conf.Current.AlushellType = colorType;
            }
        } else {
            this.clearAlushellColor();
            this.eventBusService.post({
                key: 'alushellColorTypeChange',
                value: {},
            });
        }

        this.eventBusService.post({
            key: 'icc-redraw',
            value: 'frame',
        });
    }

    setDefaultAlushellColor(conf = this.configurationsService.conf.Current, defaultConf = this.configurationsService.conf.Default) {
        const colorsWin: IccColor[] = [];
        const groups = this.filterWindowColorGroups(["F|A"], colorsWin, "alushell")
        if(colorsWin.length) {
            this.setColor(
                'alushell',
                'frame',
                colorsWin[0],	
                undefined,
                !true,
                conf,
                defaultConf,
                'advanced'
            );
            this.refreshFillingsColors(false, conf);
        }
    }

    getColorFromDoorPanel(conf: WindowActiveConfiguration, type) {
        const sash =
            conf.Sashes
            && conf.Sashes.length > 0
            && conf.Sashes.find(s => s.type.type === 'DRA' || s.type.type === 'DOA');
        if (
            (sash
                && sash.glazing
                && (type === 'outer' && sash.glazing.type === 'door_panels' || sash.glazing.type === 'deco_panels')
                && sash.glazing.selectedColor)
            || (type === 'inner' && sash && sash.panelInner && sash.panelInner.selectedColor)
        ) {
            const panelColor = (sash.glazing.type === 'door_panels' && type === 'inner' ? sash.panelInner : sash.glazing).selectedColor
                .frame[type];
            const matchedColors = panelColor && panelColor.id
                ? this.colorMappingService.getColors(Number(panelColor.id), 'window', 'window')
                : [];
            return matchedColors;
        }
        return null;
    }

    setInnerExtensionColorBasedOnFrameColor(conf: WindowActiveConfiguration) {
        if (conf.System.extension_color_based_on_frame_color && conf.SideProfiles && conf.SideProfiles.length > 0) {
                conf.SideProfiles.forEach(e => {
                const extensionProfile = conf.UsedProfiles.find(p => Number(p?.id) === Number(e?.profileId));
                const priceLevel = this.profilesService.getPriceLevel(Number(extensionProfile?.priceLevelId));
                
                const innerColorGroups = extensionProfile?.priceLevelColorGroups;

                const allInnerColors = [];

                if (priceLevel && Array.isArray(priceLevel?.price_levels)) {
                    priceLevel.price_levels.forEach(p => p?.side === 'double' 
                        && innerColorGroups.indexOf(p?.colorGroup) === -1 && innerColorGroups.push(p?.colorGroup)
                    )
                }

                if (Array.isArray(innerColorGroups) && Boolean(innerColorGroups?.length)) {
                    innerColorGroups.forEach(p => { allInnerColors.push(this.getColorsFromColorGroups(p))});
                    const innerColors = [].concat(...allInnerColors);
                    setInnerExtensionColor(e, innerColors);
                }
            });
        }

        function setInnerExtensionColor(e, innerColors) {
            if (innerColors && Array.isArray(innerColors) && Boolean(innerColors?.length)) {
                if (innerColors.find(p => Number(p.id) === Number(conf.Colors.frame?.core?.id)) && !conf.Colors.frame?.inner?.id) {
                    e.color.inner = {};
                } else if (innerColors.find(p => Number(p.id) === Number(conf.Colors.frame?.inner?.id))) {
                    e.color.inner = conf.Colors.frame.inner as Color;
                } else {
                    e.color.inner = innerColors[0] as Color;
                }
            }
        }
    }

    setOuterExtensionColorBasedOnFrameColor(conf: WindowActiveConfiguration) {
        if (conf.System.extension_color_based_on_frame_color && conf.SideProfiles && conf.SideProfiles.length > 0) {
            conf.SideProfiles.forEach(e => {
                const extensionProfile = conf.UsedProfiles.find(p => Number(p?.id) === Number(e?.profileId));
                const priceLevel = this.profilesService.getPriceLevel(Number(extensionProfile?.priceLevelId));
                
                const outerColorGroups = extensionProfile?.priceLevelColorGroupsOut;

                const allOuterColors = [];

                if (priceLevel && Array.isArray(priceLevel?.price_levels)) {
                    priceLevel.price_levels.forEach(p => p.side === 'double' 
                        && outerColorGroups.indexOf(p?.colorGroup) === -1 && outerColorGroups.push(p?.colorGroup)
                    )
                }

                if (Array.isArray(outerColorGroups) && Boolean(outerColorGroups?.length)) {
                    outerColorGroups.forEach(p => { allOuterColors.push(this.getColorsFromColorGroups(p))});
                    const outerColors = [].concat(...allOuterColors);
                    setOuterExtensionColor(e, outerColors);
                }
            });
        }
        
        function setOuterExtensionColor(e, outerColors) {
            if (outerColors && Array.isArray(outerColors) && Boolean(outerColors?.length)) {
                if (outerColors.find(p => Number(p.id) === Number(conf.Colors.frame?.core?.id)) && !conf.Colors.frame?.outer?.id) {
                    e.color.outer = conf.Colors.frame.core as Color;
                } else if (outerColors.find(p => Number(p.id) === Number(conf.Colors.frame?.outer?.id))){
                    e.color.outer = conf.Colors.frame.outer as Color;
                } else {
                    e.color.inner = outerColors[0] as Color;
                }
            }
        }
    }

    isDefaultColorChanged(conf) {
        if (
            conf.Colors
            && conf.Colors.sash.outer.isDefault
            && conf.Colors.sash.inner.isDefault
            && conf.Colors.frame.outer.isDefault
            && conf.Colors.frame.inner.isDefault
        ) {
            return false;
        }

        return true;
    }
}
