import { Injectable } from '@angular/core';
import { filter, map } from 'rxjs/operators';
import { ConfigurationsService, WindowActiveConfiguration } from '@icc/common';
import { DoorActiveConfiguration } from '@icc/common/configurations/DoorActiveConfiguration';
import { HardwareService } from '../hardware/hardware.service';
import { BrowserProfilesService } from '@icc/configurator/window';
import { LocksService } from '@icc/legacy/configurator/steps/door/locks/locks.service';
import { HandlesService } from '@icc/legacy/configurator/steps/window/handles/handles.service';

@Injectable({
    providedIn: 'root',
})
export class DoorFacade {
    constructor(
        private hardwareService: HardwareService,
        private configurationsService: ConfigurationsService<'door'>,
        private profilesService: BrowserProfilesService,
        private locksService: LocksService,
        private handlesService: HandlesService
    ) {}
    conf = this.configurationsService.conf.Current;
    doorSizes$ = this.configurationsService.configuration$.pipe(map(conf => conf.doorSizes));

    selectedHardwareType$ = this.configurationsService.configuration$.pipe(
        filter(isDoor),
        map(
            conf =>
                conf.doorHardware
                && this.hardwareService.getTypes(conf).find(t => t.id == conf.doorHardware.type)
        )
    );

    hardwareColor$ = this.configurationsService.configuration$.pipe(
        filter(isDoor),
        map(conf => conf.HingeColor)
    );

    hardwareTypeBoxSelect$ = this.configurationsService.configuration$.pipe(
        map(conf => this.hardwareService.hardwareTypeBoxSelect(conf))
    );

    showHardwareTypeBox$ = this.configurationsService.configuration$.pipe(
        map(conf => this.hardwareService.hardwareTypes.length > 0 && conf.doorHardware.set)
    );

    hardwareSet$ = this.configurationsService.configuration$.pipe(
        map(conf => conf.doorHardware.set)
    );

    hardwareSets$ = this.configurationsService.configuration$.pipe(
        map(conf => {
            return this.hardwareService.getAvailableSets(conf);
        })
    );

    pull$ = this.configurationsService.configuration$.pipe(
        map(conf => conf.doorHardware.pull),
        map(id => this.hardwareService.pulls.find(t => Number(t.id) === id))
    );

    electronicLock$ = this.configurationsService.configuration$.pipe(
        map(conf => conf.doorHardware.electronicLock),
        map(id => this.hardwareService.electronicLocks.find(t => Number(t.id) === id))
    );

    plateType$ = this.configurationsService.configuration$.pipe(
        map(conf => conf.doorHardware.plateType),
        map(id => this.hardwareService.platesTypes.find(t => Number(t.id) === id))
    );

    doorViewer$ = this.configurationsService.configuration$.pipe(
        map(conf => conf.doorHardware.doorViewer),
        map(id => this.hardwareService.doorViewers.find(t => t.id == id))
    );

    doorViewerPunching$ = this.configurationsService.configuration$.pipe(
        map(conf => conf.doorHardware.doorViewerPunching)
    );

    plateMainLock$ = this.configurationsService.configuration$.pipe(
        map(conf => conf.doorHardware.plates.mainLock),
        map(id => this.hardwareService.platesToMainLock.find(t => Number(t.id) === id))
    );

    plateAdditionalLock$ = this.configurationsService.configuration$.pipe(
        map(conf => conf.doorHardware.plates.additionalLock),
        map(id => this.hardwareService.platesToAdditionalLock.find(t => Number(t.id) === id))
    );

    plateMainLockBoxSelect$ = this.configurationsService.configuration$.pipe(
        map(conf => this.hardwareService.plateMainLockBoxSelect(conf))
    );

    plateAdditionalLockBoxSelect$ = this.configurationsService.configuration$.pipe(
        map(conf => this.hardwareService.plateAdditionalLockBoxSelect(conf))
    );

    cylinderMainLock$ = this.configurationsService.configuration$.pipe(
        map(conf => conf.doorHardware.cylinders.mainLock),
        map(id => this.hardwareService.cylindersToMainLock.find(t => Number(t.id) === id))
    );

    cylinderMainLockBoxSelect$ = this.configurationsService.configuration$.pipe(
        map(conf => this.hardwareService.cylinderMainLockBoxSelect(conf))
    );

    cylinderAdditionalLock$ = this.configurationsService.configuration$.pipe(
        map(conf => conf.doorHardware.cylinders.additionalLock),
        map(id => this.hardwareService.cylindersToAdditionalLock.find(t => Number(t.id) === id))
    );

    cylinderAdditionalLockBoxSelect$ = this.configurationsService.configuration$.pipe(
        map(conf => this.hardwareService.cylinderAdditionalLockBoxSelect(conf))
    );

    cylinderSystem$ = this.configurationsService.configuration$.pipe(
        map(conf => conf.doorHardware.cylinders.system),
        map(id => this.hardwareService.cylinderSystems.find(t => Number(t.id) === id))
    );

    doorHandleAdjustment$ = this.configurationsService.configuration$.pipe(
        map(conf => conf.doorHardware.doorHandleAdjustment),
        map(id => this.hardwareService.doorHandleAdjustments.find(t => t.id == id))
    );

    showDoorHandleAdjustmentBox$ = this.configurationsService.configuration$.pipe(
        map(conf => this.hardwareService.doorHandleAdjustments.length > 0
            && conf.doorHardware.set === 'leverPull')
    );

    platesBoxSelect$ = this.configurationsService.configuration$.pipe(
        map(conf => this.hardwareService.platesBoxSelect(conf))
    );

    pullsBoxSelect$ = this.configurationsService.configuration$.pipe(
        map(conf => this.hardwareService.pullsBoxSelect(conf))
    )

    cylindersBoxSelect$ = this.configurationsService.configuration$.pipe(
        map(conf => this.hardwareService.cylindersBoxSelect(conf))
    );

    locksBoxSelect$ = this.configurationsService.configuration$.pipe(
        map(conf => this.hardwareService.locksBoxSelect(conf))
    )

    lock$ = this.configurationsService.configuration$.pipe(
        map(conf => conf.Lock.id),
        map(id => this.locksService.locks.find(l => Number(l.id) === Number(id)))
    )

    hinge$ = this.configurationsService.configuration$.pipe(
        map(conf => conf.Hinge?.id), 
        map(id => this.handlesService.findHingesBySystem(this.conf.System)
            .find((h) => Number(h.id) === Number(id))
        )
    );

    hingeBoxSelect$ = this.configurationsService.configuration$.pipe(
        map(conf => this.hardwareService.hingeBoxSelect(conf))
    );
    decorativeHingeCovers$ = this.configurationsService.configuration$.pipe(
        map(conf => conf.doorHardware.decorativeHingeCovers),
        map(id => this.hardwareService.decoHingeCovers.find(t => t.id == id))
    );

    decorativeHingeCoversColor$ = this.configurationsService.configuration$.pipe(
        map(conf => conf.doorHardware.decorativeHingeCoversColor),
        map(id => this.hardwareService.hardwareColors.find(t => Number(t.id) == Number(id)))
    );

    showDecorativeHingeCovers$ = this.configurationsService.configuration$.pipe(
        map(conf => conf.System.decorative_hinge_covers)
    );

    selectedFrame$ = this.configurationsService.configuration$.pipe(
        map(conf => {
            const position = 1;
            const frame = conf.Frames[0];
            const profileId = frame.frame[position].profileId;
            return this.profilesService.getProfile(profileId);
        })
    );

    selectedThreshold$ = this.configurationsService.configuration$.pipe(
        map(conf => {
            return this.profilesService.getProfile(this.profilesService.getUsedThresholdId(conf));
        })
    );

    showDoorViewer$ = this.configurationsService.configuration$.pipe(
        map(conf => this.hardwareService.getAvailableDoorViewers(conf).length > 1)
    );

    showDoorViewerPunchingOption$ = this.configurationsService.configuration$.pipe(
        map(conf => conf.System.available_door_viewer_punching)
    )

    seperateFrameAndSashShortening$ = this.configurationsService.configuration$.pipe(
        map(conf => conf.System.seperate_frame_and_sash_shortening)
    )
}

function isDoor(
    conf: WindowActiveConfiguration | DoorActiveConfiguration
): conf is DoorActiveConfiguration {
    return DoorActiveConfiguration.is(conf);
}
