import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { PageComponent, ICC_PAGE_DATA, ModalService } from '@icc/helpers';
import { _, SharedFacade } from '@icc/configurator/shared';
import { Casing, Profile, ProfileType, Reinforcement } from '@icc/window';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import {
    TranslateService,
    ConfigurationsService,
    APP_CONFIG,
    AppConfigFactory,
    core,
    ProfilesService,
} from '@icc/common';
import { BehaviorSubject, Subscription } from 'rxjs';
import { ProfileColorsService } from '../profile-colors.service';
import { ProfilesPriceService } from '@icc/common/profiles-price.service';
import { CurrentConfiguratorService } from '@icc/common/configurators/current-configurator.service';
import { PriceBaseService } from '@icc/price/b2b';
import { UnitConverterService } from '@icc/configurator/ui';
import { ReinforcementsModalService } from '../../reinforcements/reinforcements-modal.service';
import { distinctUntilChanged } from 'rxjs/operators';
import { gapValidator } from './gap-validator';
import { MatCheckbox } from '@angular/material/checkbox';
import { CasingsService } from '../casings.service';
import { DoorPortalsService, PortalOptions } from '../../door-portals/door-portals.service';
import { DoorPortalsColorsService } from '../../door-portals/door-portals-colors.service';
import { ProfileInfoComponent } from '../../profile-info/profile-info.component';

@Component({
    selector: 'icc-profile-options-page',
    templateUrl: './profile-options-page.component.html',
    styleUrls: ['./profile-options-page.component.scss'],
})
export class ProfileOptionsPageComponent extends PageComponent implements OnInit, OnDestroy {
    public title: string = _('ACCESSORY|Opcje profilu');

    profile: Profile | null = null;
    form: FormGroup;

    private formSubscriptions = new Subscription();
    casingForm!: FormGroup;
    casingWallOverlap: number[] = [];
    casings: any = [];
    casing: Casing | null = null;
    isCasingOptionsSelected = false;
    isCasingAvailableForFrame = false;
    selectedCasing: any;
    CASING_GAP = 10;
    lowestInnerCasingWidth = 0;
    highestInnerCasingWidth = 0;
    innerCasingWidth = 0;
    casingWallWidth!: number;
    casingHitchPoint = 0;

    focused = false;
    showColorsWhenCoupling = this.currentConfiguratorService.conf === 'coupled_window';
    showColorsInComplementary = this.currentConfiguratorService.conf === 'complementary_goods';
    showCoreColorInAdditionalProfilesAndAccessories = this.config().IccConfig.Configurators
        .showCoreColorInAdditionalProfilesAndAccessories;
    currency: any = null;
    optionsInComplementary = this.currentConfiguratorService.conf === 'complementary_goods';

    dimensionUnit = this.unitConverterService.getUnit();

    portalForm!: FormGroup;
    availablePortals: any[] = [];
    isPortalSelected = false;
    selectedPortal: any = null;
    portalPlateTypes: string[] = [];
    leftSideWidths: number[] = [];
    rightSideWidths: number[] = [];
    upperHeights: number[] = [];
    isDoorPortalFormOpen = false;
    portalMinimumWallThickness = 0;
    portalMaximumWallThickness = 0;
    selectedPortalMinimumWallThickness = 0;
    selectedPortalMaximumWallThickness = 0;
    portalGap = 0;
    additionalWallThicknessFromFrame = 0;
    selectedPortalOptions: PortalOptions = {};

    isWysiwygEditorContentEmpty = true;
    hideExtensionColorSelection = false;

    hiddenPrice = false;

    constructor(
        private translateService: TranslateService,
        private configurationsService: ConfigurationsService<'window'>,
        private currentConfiguratorService: CurrentConfiguratorService,
        private priceBaseService: PriceBaseService,
        private sharedFacade: SharedFacade,
        private profileColorsService: ProfileColorsService,
        private profilesPriceService: ProfilesPriceService,
        private fb: FormBuilder,
        @Inject(APP_CONFIG) public config: AppConfigFactory,
        @Inject(ICC_PAGE_DATA)
        private pageData: {
            profile: Profile;
            profilesPrices: any[];
            profile$: BehaviorSubject<Profile>,
            selectedColors$: BehaviorSubject<any>
        },
        private unitConverterService: UnitConverterService,
        private reinforcementsModalService: ReinforcementsModalService,
        private casingsService: CasingsService,
        private profilesSerivce: ProfilesService,
        private doorPortalsService: DoorPortalsService,
        private doorPortalsColorsService: DoorPortalsColorsService,
        private modalService: ModalService
    ) {
        super();
    }

    ngOnInit(conf = this.configurationsService.conf.Current) {
        this.hiddenPrice = this.config().preset === 'b2c' && this.config().hidePricesInB2cConfigurator;
        this.hideExtensionColorSelection = conf.System?.hide_extension_color_selection;
        this.isWysiwygEditorContentEmpty = Boolean(!this.pageData?.profile?.wysiwygEditorContent?.length);
        this.selectedPortalOptions = this.doorPortalsService?.selectedPortalOptions;
        this.isDoorPortalFormOpen = Boolean(this.selectedPortalOptions?.isPortalSelected);
        
        if(this.currentConfiguratorService.conf !== 'complementary_goods') {
            this.availablePortals = this.profilesSerivce.matchProfileToFrame(this.pageData.profile?.id, 'portal');
            if (this.doorPortalsService.getOpeningType() === 'inward') {
                this.availablePortals = this.availablePortals.filter(p => p?.inwardOpening);
            } else {
                this.availablePortals = this.availablePortals.filter(p => p?.outwardOpening);
            }

            this.casings = this.profilesSerivce.matchProfileToFrame(this.pageData.profile?.id, 'casing');
            
            this.casings.length > 0 && this.casingSetup();
            this.availablePortals.length > 0 && this.portalSetup();    
        }

        this.profile = this.pageData.profile;

        this.form = this.fb.group({
            count: 1,
            currentLength: [0, [Validators.min(0), Validators.required]],
            width: [this.profile.width || 0],
            comment: '',
        });

        this.pageData.selectedColors$.subscribe(c => {
            this.profile.selectedColor = c;
        });

        this.sharedFacade.currency$.subscribe(currency => {
            this.currency = currency;
        });

        this.pageData.profile$.subscribe(p => {
            this.form.patchValue(p);
        });
    }

    private casingSetup() {
        if(this.pageData.profile.onlyWithCasingCover) {
            this.isCasingOptionsSelected = true;
            this.casingsService.setIsCasingAvailableForFrame(true);
        } else {
            this.isCasingOptionsSelected = false;
            this.casingsService.setIsCasingAvailableForFrame(false);
        }

        this.setInitialCasingSetup(this.casings);
        this.createCasingFormGroup(this.casings, this.casingHitchPoint);

        this.formSubscriptions.add(this.casingForm.get('casingWallWidth')?.valueChanges.pipe(distinctUntilChanged())
            .subscribe((wallWidth) => {
                const casing = this.casingsService.getCasingBasedOnWallWidth(
                    Number(wallWidth),
                    this.casingHitchPoint,
                    this.casings
                );
                this.innerCasingWidth = wallWidth - this.casingHitchPoint;
                this.casingWallOverlap = this.casingsService.getWallOverlapsBasedOnInnerCasingWidth(casing, this.innerCasingWidth);
                this.casingForm.get('casingWallOverlap')?.patchValue(this.casingWallOverlap[0], { emitEvent: false, });
                this.changeCasingBasedOnInnerWidthAndWallOverlap();
            })
        );
    }

    private portalSetup() { 
        if (this.configurationsService.conf.Current.doorPortal?.id) {
            this.isPortalSelected = true; 
        } else {
            this.isPortalSelected = false;
        }

        this.setInitialPortalSetup();
        this.createPortalFormGroup();
        this.formSubscriptions.add(this.portalForm.get('wallThickness')?.valueChanges.pipe(distinctUntilChanged())
            .subscribe((wallThickness) => {
                const portal = this.doorPortalsService.getPortalBasedOnWallThickness(
                    Number(wallThickness),
                    this.additionalWallThicknessFromFrame,
                    this.availablePortals
                );

                if (!portal) {
                    return;
                }

                const { leftSideWidths, rightSideWidths, upperHeights } = this.doorPortalsService.getPortalSizesForPortalSides(portal);
                this.leftSideWidths = leftSideWidths;
                this.rightSideWidths = rightSideWidths;
                this.upperHeights = upperHeights;

                if (!this.leftSideWidths.includes(Number(this.portalForm.get('leftWidth')?.value))) {
                    this.portalForm.get('leftWidth')?.patchValue(this.leftSideWidths[0], { emitEvent: false, });
                }

                if (!this.rightSideWidths.includes(Number(this.portalForm.get('rightWidth')?.value))) {
                    this.portalForm.get('rightWidth')?.patchValue(this.rightSideWidths[0], { emitEvent: false, });
                }

                if (!this.upperHeights.includes(Number(this.portalForm.get('upperHeight')?.value))) {
                    this.portalForm.get('upperHeight')?.patchValue(this.upperHeights[0], { emitEvent: false, });
                }

                this.selectedPortal = portal;
                this.portalGap = portal.portalGap;
            }
        ));

        this.portalForm.valueChanges.subscribe(val => {
            val.isPortalSelected = true;
            this.doorPortalsService.setSelectedPortalOptions(val)
        });
    }
    togglePortal(event: MatCheckbox) {
        if (event.checked) {
            this.isDoorPortalFormOpen = true;
            this.isPortalSelected = true;
            this.doorPortalsService.setSelectedPortalOptions( { isPortalSelected: true });
        } else {
            this.isDoorPortalFormOpen = false;
            this.isPortalSelected = false;
            this.doorPortalsService.setSelectedPortalOptions( { isPortalSelected: false })
        }
    }

    setInitialPortalSetup(conf = this.configurationsService.conf.Current) {
        this.additionalWallThicknessFromFrame = this.pageData.profile?.additionalWallThickness;
        this.portalMinimumWallThickness = Math.min(...this.availablePortals.map(c => c.minimumPortalDepth)) + this.additionalWallThicknessFromFrame;
        this.portalMaximumWallThickness = Math.max(...this.availablePortals.map(c => c.maximumPortalDepth)) + this.additionalWallThicknessFromFrame;
        const portal = this.doorPortalsService.getAvailableSelectedPortal(conf, this.availablePortals);
        this.portalGap = portal.portalGap ? portal.portalGap : 0;
        
        if (this.availablePortals.find(p => p.id === conf.doorPortal?.id)) {
            this.selectedPortal = conf.doorPortal || portal;
        } else {
            this.selectedPortal = portal;
        }
      
        if(conf.doorPortal?.id && portal) {
            const { leftSideWidths, rightSideWidths, upperHeights } = this.doorPortalsService.getPortalSizesForPortalSides(portal);
            this.setPortalSizesForPortalSides(leftSideWidths, rightSideWidths, upperHeights);
            this.portalPlateTypes = this.doorPortalsService.getPortalPlateType(portal);
        } else {
            const { leftSideWidths, rightSideWidths, upperHeights } = this.doorPortalsService.getPortalSizesForPortalSides(this.availablePortals[0]);
            this.setPortalSizesForPortalSides(leftSideWidths, rightSideWidths, upperHeights);
            this.portalPlateTypes = this.doorPortalsService.getPortalPlateType(this.availablePortals[0]);
        }
    }

    createPortalFormGroup(conf = this.configurationsService.conf.Current) {
        const portal = this.availablePortals.find(p => p.id === conf.doorPortal?.id);

        this.portalForm = this.fb.group({
            plateType: [
                portal ? conf.doorPortal?.plateType :
                    this.selectedPortalOptions?.plateType ? this.selectedPortalOptions.plateType : this.portalPlateTypes[0]
            ],
            wallThickness: [
                portal ? conf.doorPortal?.wallThickness :
                    this.selectedPortalOptions?.wallThickness ? this.selectedPortalOptions.wallThickness : this.portalMinimumWallThickness,
                {
                    validators: [
                        Validators.min(Number(this.portalMinimumWallThickness)),
                        Validators.max(Number(this.portalMaximumWallThickness)),
                        gapValidator(
                            this.portalMinimumWallThickness,
                            this.portalMaximumWallThickness,
                            this.portalGap
                        ),
                    ],
                    updateOn: 'change',
                },
            ],
            leftWidth: [
                portal ? conf.doorPortal?.leftWidth :
                    this.selectedPortalOptions?.leftWidth ? this.selectedPortalOptions.leftWidth : this.leftSideWidths[0]
            ],
            rightWidth: [
                portal ? conf.doorPortal?.rightWidth :
                    this.selectedPortalOptions?.rightWidth ? this.selectedPortalOptions.rightWidth : this.rightSideWidths[0]
            ],
            upperHeight: [
                portal ? conf.doorPortal?.upperHeight :
                    this.selectedPortalOptions?.upperHeight ? this.selectedPortalOptions.upperHeight : this.upperHeights[0]
            ],
        });
    }

    setPortalSizesForPortalSides(leftSideWidths: number[], rightSideWidths: number[], upperHeights: number[]) {
        this.leftSideWidths = leftSideWidths.sort((a, b) => a - b);
        this.rightSideWidths = rightSideWidths.sort((a, b) => a - b);
        this.upperHeights = upperHeights.sort((a, b) => a - b);
    }

    changeCasingBasedOnInnerWidthAndWallOverlap() {
        this.selectedCasing = this.casingsService.getCasingBasedOnInnerWidthAndWallOverlap(
            this.casingForm.get('casingWallWidth')?.value - this.casingHitchPoint,
            this.casingForm.get('casingWallOverlap')?.value,
            this.casings
        );
    }

    ngOnDestroy() {
        this.formSubscriptions.unsubscribe();
    }

    setInitialCasingSetup(casings: any, conf = this.configurationsService.conf.Current) {
        this.isCasingAvailableForFrame = this.casingsService.isCasingAvailableForFrame;
        this.casingHitchPoint = this.pageData.profile.casingHitchPoint;
        this.isCasingOptionsSelected = conf.Casing?.isCasingSelected;
        this.lowestInnerCasingWidth = Math.min(...casings.map(c => c.innerCasingWidth)) + this.casingHitchPoint;
        this.highestInnerCasingWidth = Math.max(...casings.map(c => c.innerCasingWidth)) + this.casingHitchPoint;
    }

    createCasingFormGroup(casings: any, casingHitchPoint: number, conf = this.configurationsService.conf.Current) {
        let casingWallWidth;
        let casingWallOverlap;
        const isSelectedCasingAvailable = casings.find((c) => c.id === conf.Casing.id);

        if (isSelectedCasingAvailable) {
            this.casingWallOverlap = this.casingsService.getWallOverlapsBasedOnInnerCasingWidth(this.casings, isSelectedCasingAvailable.innerCasingWidth);
            casingWallWidth = Number(conf.Casing?.innerWidth) + casingHitchPoint;
            casingWallOverlap = conf.Casing?.wallOverlap;
        }

        this.casingForm = this.fb.group({
            casingWallWidth: [casingWallWidth ? casingWallWidth : this.casingWallWidth, {
                validators: [
                    Validators.min(Number(this.lowestInnerCasingWidth)),
                    Validators.max(Number(this.highestInnerCasingWidth)),
                    gapValidator(this.lowestInnerCasingWidth, this.highestInnerCasingWidth, this.CASING_GAP)],
                updateOn: 'change'
            }],
            casingWallOverlap: [casingWallOverlap ? casingWallOverlap : casings[0].casingWallOverlap],
        });
    }

    toggleCasing(event: MatCheckbox) {
        if (event.checked) {
            this.isCasingOptionsSelected = true;
            this.casingsService.setIsCasingAvailableForFrame(true);
        } else {
            this.isCasingOptionsSelected = false;
            this.casingsService.setIsCasingAvailableForFrame(false);
        }
    }

    add(conf = this.configurationsService.conf.Current) {
        if (
            this.currentConfiguratorService.conf !== 'complementary_goods' &&
            this.currentConfiguratorService.conf !== 'coupled_window'
        ) {
            this.casings.length > 0 && this.addCasing(conf);
            this.availablePortals.length > 0 && this.addPortal(conf);
        }

        if (this.profile && this.form?.value) {
            Object.assign(this.profile, this.form.value);
            this.sharedFacade.closePage(this.profile);
        }
    }

    private addCasing(conf = this.configurationsService.conf.Current) {
        const selectedCasing = this.selectedCasing || conf.Casing;
        this.isCasingAvailableForFrame = this.casingsService.isCasingAvailableForFrame;

        if (this.isCasingAvailableForFrame !== true) {
            this.casingsService.unsetCasing();
        } else {
            this.casingsService.setCasing(
                selectedCasing,
                this.casingForm.get('casingWallWidth')?.value - this.casingHitchPoint,
                this.casingForm.get('casingWallOverlap')?.value,
                this.casingHitchPoint
            );
        }
    }

    isPortalAvailable(conf = this.configurationsService.conf.Current) {
        const portalId = (this.availablePortals[0]?.id && conf.doorPortal?.id) ?? this.availablePortals[0]?.id;
        return portalId && this.pageData.profile?.type === 'frame' && this.doorPortalsService.isPortalValid(portalId, conf);
    }

    private addPortal(conf = this.configurationsService.conf.Current) {
        const isSelectedPortalAvailable = this.availablePortals.find((p) => p.id === conf.doorPortal?.id);

        if (
            isSelectedPortalAvailable &&
            conf.doorPortal?.id &&
            this.selectedPortal?.id === conf.doorPortal?.id
        ) {
            this.selectedPortal = conf.doorPortal;
        }

        const portal = {
            selectedPortal: this.selectedPortal,
            plateType: this.portalForm.get('plateType')?.value,
            wallThickness: this.portalForm.get('wallThickness')?.value,
            depth:
                Number(this.portalForm.get('wallThickness')?.value) -
                this.additionalWallThicknessFromFrame,
            leftWidth: this.portalForm.get('leftWidth')?.value,
            rightWidth: this.portalForm.get('rightWidth')?.value,
            upperHeight: this.portalForm.get('upperHeight')?.value,
        };

        if (portal && this.selectedPortal && this.isPortalSelected || this.isDoorPortalFormOpen) {
            this.doorPortalsService.setPortal(portal);
            this.doorPortalsColorsService.setDefaultColor(conf);
        } else {
            this.doorPortalsService.unsetPortal();
        }
    }

    getPrice() {
        let price = null;
        if (this.profile && this.profile.selectedColor) {
            price = this.profilesPriceService.getProfilePrice(
                this.profile.id,
                this.profile.type,
                null,
                this.profile.selectedColor,
                this.pageData.profilesPrices
            );
        }
        return !isNaN(price) && price !== null
            ? price
            : this.translateService.instant('CONFIGURATOR|Do wyceny');
    }

    openModalColors() {
        if (this.profile) {
            this.profileColorsService
                .openProfileColorsModal(
                    core.copy(this.profile),
                    core.copy(this.profile),
                    this.pageData.profilesPrices,
                    this.pageData.selectedColors$,
                    true
                )
                .then(selection => {
                    if (this.profile) {
                        this.profile.price = this.profilesPriceService.getProfilePrice(
                            this.profile.id,
                            this.profile.type,
                            null,
                            selection ? selection.colors : this.profile.selectedColor,
                            this.pageData.profilesPrices
                        );

                        this.profile.selectedColor = core.copy( selection ? selection.colors : this.profile.selectedColor);
                        this.profile.selectedWood = core.copy(
                            selection ? selection.wood : this.profile.selectedWood
                        );
                        this.pageData.profile$.next(this.form.value);
                    }
                });
        }
    }

    openModalReinforcements(profile: Profile) {
        this.reinforcementsModalService.openReinforcementsModal(
            profile.id,
            profile.reinforcement ? profile.reinforcement : null
        ).then((reinforcement: Reinforcement | false) => {
            if (reinforcement) {
                if (this.profile) {
                    this.profile.reinforcement = reinforcement.id === null ? null : core.copy(reinforcement);
                }
            }
        });

    }

    hasZeroPrice(
        price: {
            price_field: any;
            price_length: any;
            price_construction: any;
            price_piece: any;
            price_sash: any;
            price_area: any;
            price_percent: any;
        },
        type: ProfileType | null = null
    ) {
        if (!price) {
            return false;
        }
        let requiredFields = [price.price_field, price.price_length, price.price_piece, price.price_percent];
        if (type === 'threshold') {
            requiredFields = [price.price_construction, price.price_sash, price.price_length];
        } else if (type && ['coupling', 'extension', 'alignment', 'other'].includes(type)) {
            requiredFields = [price.price_piece, price.price_length];
        } else if (type === 'sandwich') {
            requiredFields = [price.price_area];
        } else if (type === 'frame') {
            requiredFields.push(price.price_construction);
        }
        return requiredFields.every(p => p === 0);
    }

    hasNoPrice(
        price: {
            price_field: any;
            price_length: any;
            price_piece: any;
            price_construction: any;
            price_sash: any;
            price_area: any;
            price_percent: any;
        },
        type: ProfileType | null = null
    ) {
        if (!price) {
            return true;
        }
        let requiredFields = [price.price_field, price.price_length, price.price_piece, price.price_percent];
        if (type === 'threshold') {
            requiredFields = [price.price_construction, price.price_sash, price.price_length];
        } else if (type && ['coupling', 'extension', 'alignment', 'other'].includes(type)) {
            requiredFields = [price.price_piece, price.price_length];
        } else if (type === 'sandwich') {
            requiredFields = [price.price_area];
        } else if (type === 'frame') {
            requiredFields.push(price.price_construction);
        }
        return requiredFields.some(p => isNaN(Number(p)) || p === null);
    }

    priceMarket(price: number) {
        return this.priceBaseService.addMarginMarketFactor(price);
    }

    clickShowInfoButton() {
        this.modalService
            .open({
                pageComponent: ProfileInfoComponent,
                resolve: {
                    profile: () => this.profile,
                    button: () => false
                },
            });
    }
}
