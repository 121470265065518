import { core } from '@icc/common/helpers';
import {
    Frame,
    Sash,
    SashV2,
    MullionV2,
    FrameV2,
    Reinforcement,
    ProfileV2,
    ProfileShapeV2,
    SideProfileV2,
} from '@icc/window';
import { WindowConfiguration12 } from '../../WindowConfiguration.12';
import { WindowConfiguration, IWindowConfiguration } from '../../WindowConfiguration';
import { Color, ColorId } from '../../parts/common';
import { RollerShutter } from '../../parts/roller_shutter';
import { DoorConfiguration } from '../../DoorConfiguration';

export function changesInVersion13(
    oldConfiguration: WindowConfiguration12,
    dataRequiredToUpdate: {
        langCode: string;
        windowColorRalsMap: Record<number | string, number>;
    }
): IWindowConfiguration {
    const dictionary: IWindowConfiguration['dictionary'] = {
        colors: {},
        glazingBeadColors: {},
        sealColors: {},
        siliconeColors: {},
        accessoryColors: {},
        woods: {},
        glazingSpacers: {},
        hinges: {},
        locks: {},
        fittings: {},
        systems: {},
        profiles: {},
        profileShapes: {},
        sashTypes: {},
        reinforcements: {},
        handles: {},
        fillings: {},
        doorHardware: {},
        hardwareSystems: {},
        doorSizes: {},
        doorLightSizes: {},
    };
    const configuration: IWindowConfiguration = {
        ...core.copy(oldConfiguration),
        valid: {
            ...oldConfiguration.valid,
            extensionsColors: true,
            extensionsSystems: true,
            muntins: true,
            muntinsColors: true,
            loadedThresholdColor: true,
            thresholdColor: true,
            loadedLippingColor: true,
            lippingColor: true,
            innerLippingColor: true,
            casing: true,
            loadedFillingsColors: true,
            fillingsCategories: true,
            System: true,
            panelGlazings: true,
        },
        priceGross: oldConfiguration.price,
        priceGrossAfterDiscounts: oldConfiguration.priceAfterDiscounts,
        dictionary,
        wood: oldConfiguration.wood && oldConfiguration.wood.id,
        glazingSpacer: oldConfiguration.glazingSpacer && oldConfiguration.glazingSpacer.id,
        lock: oldConfiguration.lock && oldConfiguration.lock.id,
        sashes: oldConfiguration.sashes.map(sash =>
            sashToSashV2(sash, dictionary, dataRequiredToUpdate.langCode)
        ),
        sideProfiles: oldConfiguration.sideProfiles.map<SideProfileV2>(el => {
            return {
                ...el,
                reinforcement: el.reinforcement ? el.reinforcement.id : null,
                dimensions: {
                    width: 0,
                    height: 0
                }
            };
        }),
        fitting: oldConfiguration.fitting && oldConfiguration.fitting.id,
        system: oldConfiguration.system && oldConfiguration.system.id,
        lowThreshold: oldConfiguration.lowThreshold ? oldConfiguration.lowThreshold.id : false,
        frames: oldConfiguration.frames.map(frame =>
            frameToFrameV2(frame, dictionary, dataRequiredToUpdate.langCode)
        ),
        couplings: oldConfiguration.couplings.map(coupling => ({
            ...convertReinforcementV2(coupling, dictionary, dataRequiredToUpdate.langCode),
            dimensions: {
                width: 0,
                height: 0
            }
        })),
        mullions: oldConfiguration.mullions.map(mullion =>
            convertReinforcementV2(mullion, dictionary, dataRequiredToUpdate.langCode)
        ),
        colors: oldConfiguration.colors,
        hinge: oldConfiguration.hinge && oldConfiguration.hinge.id && {
            ...oldConfiguration.hinge,
            visualizations: []
        },
        ownedSashesTypes: {
            ...oldConfiguration.ownedSashesTypes,
            doorTopLight: false,
            doorLeftLight: false,
            doorRightLight: false
        },
        layout: {
            ...oldConfiguration.layout,
            fromCode: false,
            layoutCode: null,
            countSashes: 0,
            shape: 0,
            transomsOnSeperateFrames: false,
            conf: 'window',
            sashTypes: [],
            isMirrorLayoutAvailable: false,
        },
        oneFilling: {
            ...oldConfiguration.oneFilling,
            doorActiveInner: false,
            doorLight: false,
            doorTopLight: false,
            doorLeftLight: false,
            doorRightLight: false
        },
        deletedAttachments: [],
        doorSizes: {
            sashSize: 0,
            sashSizeId: null,
            passiveSashSize: 0,
            passiveSashSizeId: null,
            shortening: 0,
            frameShortening: 0,
            topLightSize: 0,
            topLightSizeId: null,
            leftLightSize: 0,
            leftLightSizeId: null,
            rightLightSize: 0,
            rightLightSizeId: null,
            customWidth: 0,
            customHeight: 0,
            customSize: false,
            sizeIntervalCustomWidth: 0,
            sizeIntervalHeight: 0,
            standardHeight: null,
            isStandardHeightSelected: null,
            isStandardWidthSelected: null
        },
        glazingBeadType: false,
        systemPackets: [],
        foundationProfile: false,
        thresholdColor: null,
        lippingColor: null,
        innerLippingColor: null,
        attributes: [],
        doorPortal: null,
        casing: null,
        fillingsCategories: null,
        System: null,
    };

    if (oldConfiguration.wood) {
        configuration.dictionary.woods[oldConfiguration.wood.id] = convertToDictionaryEntry(
            oldConfiguration.wood,
            dataRequiredToUpdate.langCode
        );
    }
    if (oldConfiguration.glazingSpacer) {
        configuration.dictionary.glazingSpacers[
            oldConfiguration.glazingSpacer.id
        ] = convertToDictionaryEntry(oldConfiguration.glazingSpacer, dataRequiredToUpdate.langCode);
    }
    if (oldConfiguration.lock) {
        configuration.dictionary.locks[oldConfiguration.lock.id] = convertToDictionaryEntry(
            oldConfiguration.lock,
            dataRequiredToUpdate.langCode
        );
    }
    if (oldConfiguration.fitting) {
        configuration.dictionary.fittings[oldConfiguration.fitting.id] = convertToDictionaryEntry(
            oldConfiguration.fitting,
            dataRequiredToUpdate.langCode
        );
    }
    if (oldConfiguration.system) {
        configuration.dictionary.systems[oldConfiguration.system.id] = convertToDictionaryEntry(
            {
                ...oldConfiguration.system,
                confType: oldConfiguration.type,
                doorType: false
            },
            dataRequiredToUpdate.langCode
        );
    }
    if (oldConfiguration.colors && configuration.colors) {
        if (oldConfiguration.colors.frame.outer && configuration.colors.frame.outer) {
            updateColorId(configuration.colors.frame.outer);
            configuration.dictionary.colors[
                convertColorId(configuration.colors.frame.outer)
            ] = convertToDictionaryEntry(
                oldConfiguration.colors.frame.outer,
                dataRequiredToUpdate.langCode
            );
        }
        if (oldConfiguration.colors.frame.inner && configuration.colors.frame.inner) {
            updateColorId(configuration.colors.frame.inner);
            configuration.dictionary.colors[
                convertColorId(configuration.colors.frame.inner)
            ] = convertToDictionaryEntry(
                oldConfiguration.colors.frame.inner,
                dataRequiredToUpdate.langCode
            );
        }
        if (oldConfiguration.colors.frame.core && configuration.colors.frame.core) {
            updateColorId(configuration.colors.frame.core);
            configuration.dictionary.colors[
                convertColorId(configuration.colors.frame.core)
            ] = convertToDictionaryEntry(
                oldConfiguration.colors.frame.core,
                dataRequiredToUpdate.langCode
            );
        }
        if (oldConfiguration.colors.frame.alushell && configuration.colors.frame.alushell) {
            updateColorId(configuration.colors.frame.alushell);
            configuration.dictionary.colors[
                convertColorId(configuration.colors.frame.alushell)
            ] = convertToDictionaryEntry(
                oldConfiguration.colors.frame.alushell,
                dataRequiredToUpdate.langCode
            );
        }
        if (oldConfiguration.colors.sash.outer && configuration.colors.sash.outer) {
            updateColorId(configuration.colors.sash.outer);
            configuration.dictionary.colors[
                convertColorId(configuration.colors.sash.outer)
            ] = convertToDictionaryEntry(
                oldConfiguration.colors.sash.outer,
                dataRequiredToUpdate.langCode
            );
        }
        if (oldConfiguration.colors.sash.inner && configuration.colors.sash.inner) {
            updateColorId(configuration.colors.sash.inner);
            configuration.dictionary.colors[
                convertColorId(configuration.colors.sash.inner)
            ] = convertToDictionaryEntry(
                oldConfiguration.colors.sash.inner,
                dataRequiredToUpdate.langCode
            );
        }
        if (oldConfiguration.colors.sash.core && configuration.colors.sash.core) {
            updateColorId(configuration.colors.sash.core);
            configuration.dictionary.colors[
                convertColorId(configuration.colors.sash.core)
            ] = convertToDictionaryEntry(
                oldConfiguration.colors.sash.core,
                dataRequiredToUpdate.langCode
            );
        }
        if (oldConfiguration.colors.sash.alushell && configuration.colors.sash.alushell) {
            updateColorId(configuration.colors.sash.alushell);
            configuration.dictionary.colors[
                convertColorId(configuration.colors.sash.alushell)
            ] = convertToDictionaryEntry(
                oldConfiguration.colors.sash.alushell,
                dataRequiredToUpdate.langCode
            );
        }
    }

    if (oldConfiguration.muntins && configuration.muntins) {
        if (oldConfiguration.muntins.colorOuter && configuration.muntins.colorOuter) {
            updateColorId(configuration.muntins.colorOuter);
            configuration.dictionary.colors[
                convertColorId(configuration.muntins.colorOuter)
            ] = convertToDictionaryEntry(
                oldConfiguration.muntins.colorOuter,
                dataRequiredToUpdate.langCode
            );
        }
        if (oldConfiguration.muntins.colorInner && configuration.muntins.colorInner) {
            updateColorId(configuration.muntins.colorInner);
            configuration.dictionary.colors[
                convertColorId(configuration.muntins.colorInner)
            ] = convertToDictionaryEntry(
                oldConfiguration.muntins.colorInner,
                dataRequiredToUpdate.langCode
            );
        }
    }

    if (oldConfiguration.glazingBeadColor && configuration.glazingBeadColor) {
        if (configuration.glazingBeadColor.RAL) {
            updateColorId(configuration.glazingBeadColor);
        }
        configuration.dictionary.glazingBeadColors[
            configuration.glazingBeadColor.id
        ] = convertToDictionaryEntry(
            oldConfiguration.glazingBeadColor,
            dataRequiredToUpdate.langCode
        );
    }

    if (oldConfiguration.sealColor) {
        configuration.dictionary.sealColors[
            oldConfiguration.sealColor.id
        ] = convertToDictionaryEntry(oldConfiguration.sealColor, dataRequiredToUpdate.langCode);
    }

    if (oldConfiguration.siliconeColor) {
        configuration.dictionary.siliconeColors[
            oldConfiguration.siliconeColor.id
        ] = convertToDictionaryEntry(oldConfiguration.siliconeColor, dataRequiredToUpdate.langCode);
    }

    if (oldConfiguration.hinge && oldConfiguration.hinge.id) {
        configuration.dictionary.hinges[oldConfiguration.hinge.id] = convertToDictionaryEntry(
            oldConfiguration.hinge,
            dataRequiredToUpdate.langCode
        );
        configuration.dictionary.accessoryColors[
            oldConfiguration.hinge.color.id
        ] = convertToDictionaryEntry(oldConfiguration.hinge.color, dataRequiredToUpdate.langCode);
    }
    configuration.dictionary.profiles = oldConfiguration.usedProfiles
        .map(profile => convertToDictionaryEntry(profile, dataRequiredToUpdate.langCode))
        .reduce<Record<string, ProfileV2>>((prev, cur) => {
            prev[cur.id] = cur;
            return prev;
        }, {});
    configuration.dictionary.profileShapes = oldConfiguration.usedProfileShapes
        .map(profileShape => convertToDictionaryEntry(profileShape, dataRequiredToUpdate.langCode))
        .reduce<Record<string, ProfileShapeV2>>((prev, cur) => {
            prev[cur.id] = cur;
            return prev;
        }, {});
    oldConfiguration.sashes.forEach(sash => {
        if (sash.type) {
            configuration.dictionary.sashTypes[sash.type.id] = convertToDictionaryEntry(
                sash.type,
                dataRequiredToUpdate.langCode
            );
        }
        if (sash.handleInner) {
            configuration.dictionary.handles[sash.handleInner.id] = convertToDictionaryEntry(
                sash.handleInner,
                dataRequiredToUpdate.langCode
            );
            configuration.dictionary.accessoryColors[
                sash.handleInner.color.id
            ] = convertToDictionaryEntry(sash.handleInner.color, dataRequiredToUpdate.langCode);
        }
        if (sash.handleOuter) {
            configuration.dictionary.handles[sash.handleOuter.id] = convertToDictionaryEntry(
                sash.handleOuter,
                dataRequiredToUpdate.langCode
            );
            configuration.dictionary.accessoryColors[
                sash.handleOuter.color.id
            ] = convertToDictionaryEntry(sash.handleOuter.color, dataRequiredToUpdate.langCode);
        }
        if (sash.frame) {
            if (sash.frame.top && sash.frame.top.reinforcement) {
                configuration.dictionary.reinforcements[
                    Number(sash.frame.top.reinforcement.id)
                ] = convertToDictionaryEntry(
                    sash.frame.top.reinforcement,
                    dataRequiredToUpdate.langCode
                );
            }
            if (sash.frame.bottom && sash.frame.bottom.reinforcement) {
                configuration.dictionary.reinforcements[
                    Number(sash.frame.bottom.reinforcement.id)
                ] = convertToDictionaryEntry(
                    sash.frame.bottom.reinforcement,
                    dataRequiredToUpdate.langCode
                );
            }
            if (sash.frame.left && sash.frame.left.reinforcement) {
                configuration.dictionary.reinforcements[
                    Number(sash.frame.left.reinforcement.id)
                ] = convertToDictionaryEntry(
                    sash.frame.left.reinforcement,
                    dataRequiredToUpdate.langCode
                );
            }
            if (sash.frame.right && sash.frame.right.reinforcement) {
                configuration.dictionary.reinforcements[
                    Number(sash.frame.right.reinforcement.id)
                ] = convertToDictionaryEntry(
                    sash.frame.right.reinforcement,
                    dataRequiredToUpdate.langCode
                );
            }
        }

        if (sash.type) {
            let suffix = '';
            if (sash.panelType === 'Double') {
                suffix = 'd';
            } else if (sash.panelType === 'Outer') {
                suffix = 'o';
            } if (sash.panelType === 'Inner') {
                suffix = 'i';
            }

            if (sash.filling) {
                sash.filling.id = sash.filling.id + suffix;
                configuration.dictionary.fillings[sash.filling.id] = convertToDictionaryEntry(
                    sash.filling,
                    dataRequiredToUpdate.langCode
                );
            }
            if (
                sash.panelGlazing
                && sash.panelGlazing.id
            ) {
                configuration.dictionary.fillings[
                    Number(sash.panelGlazing.id)
                ] = convertToDictionaryEntry(
                    sash.panelGlazing,
                    dataRequiredToUpdate.langCode
                );
            }
            if (sash.intSashes) {
                sash.intSashes.forEach(field => {
                    if (field.filling) {
                        field.filling.id = field.filling.id + suffix;
                        configuration.dictionary.fillings[
                            field.filling.id
                        ] = convertToDictionaryEntry(field.filling, dataRequiredToUpdate.langCode);
                    }
                    if (
                        field.panelGlazing
                        && field.panelGlazing.id
                    ) {
                        configuration.dictionary.fillings[
                            Number(field.panelGlazing.id)
                        ] = convertToDictionaryEntry(
                            field.panelGlazing,
                            dataRequiredToUpdate.langCode
                        );
                    }
                });
            }
        }
    });

    configuration.sashes.forEach(sash => {
        if (sash.type) {
            if (!sash.intSashes || sash.intSashes.length === 0) {
                if (sash.filling) {
                    if (sash.filling.color) {
                        if (sash.filling.color.outer) {
                            updateColorId(sash.filling.color.outer);
                        }
                        if (sash.filling.color.inner) {
                            updateColorId(sash.filling.color.inner);
                        }
                        if (sash.filling.color.core) {
                            updateColorId(sash.filling.color.core);
                        }
                        if (sash.filling.color.alushell) {
                            updateColorId(sash.filling.color.alushell);
                        }
                    }
                }
            } else {
                sash.intSashes.forEach(field => {
                    if (field.filling) {
                        if (field.filling.color) {
                            if (field.filling.color.outer) {
                                updateColorId(field.filling.color.outer);
                            }
                            if (field.filling.color.inner) {
                                updateColorId(field.filling.color.inner);
                            }
                            if (field.filling.color.core) {
                                updateColorId(field.filling.color.core);
                            }
                            if (field.filling.color.alushell) {
                                updateColorId(field.filling.color.alushell);
                            }
                        }
                    }
                });
            }
        }
    });

    oldConfiguration.mullions.forEach(mullion => {
        if (mullion.reinforcement) {
            configuration.dictionary.reinforcements[
                Number(mullion.reinforcement.id)
            ] = convertToDictionaryEntry(mullion.reinforcement, dataRequiredToUpdate.langCode);
        }
    });

    oldConfiguration.frames.forEach(frame => {
        frame.frame.forEach(f => {
            if (f.reinforcement) {
                configuration.dictionary.reinforcements[
                    Number(f.reinforcement.id)
                ] = convertToDictionaryEntry(f.reinforcement, dataRequiredToUpdate.langCode);
            }
        });
    });

    oldConfiguration.couplings.forEach(c => {
        if (c.reinforcement) {
            configuration.dictionary.reinforcements[
                Number(c.reinforcement.id)
            ] = convertToDictionaryEntry(c.reinforcement, dataRequiredToUpdate.langCode);
        }
    });

    oldConfiguration.sideProfiles.forEach(c => {
        if (c.reinforcement) {
            configuration.dictionary.reinforcements[
                Number(c.reinforcement.id)
            ] = convertToDictionaryEntry(c.reinforcement, dataRequiredToUpdate.langCode);
        }
    });

    configuration.couplings.forEach(c => {
        if (c.color.outer) {
            updateColorId(c.color.outer);
        }
        if (c.color.inner) {
            updateColorId(c.color.inner);
        }
        if (c.color.core) {
            updateColorId(c.color.core);
        }
        if (c.color.alushell) {
            updateColorId(c.color.alushell);
        }
    });

    configuration.sideProfiles.forEach(c => {
        if (c.color.outer) {
            updateColorId(c.color.outer);
        }
        if (c.color.inner) {
            updateColorId(c.color.inner);
        }
        if (c.color.core) {
            updateColorId(c.color.core);
        }
        if (c.color.alushell) {
            updateColorId(c.color.alushell);
        }
    });

    if (configuration.rollerShutter && configuration.rollerShutter.colors) {
        Object.keys(configuration.rollerShutter.colors).forEach(
            (c: keyof RollerShutter['colors']) => {
                if (
                    configuration.rollerShutter.colors[c]
                    && configuration.rollerShutter.colors[c] != null
                    && configuration.rollerShutter.colors[c].id
                        in dataRequiredToUpdate.windowColorRalsMap
                ) {
                    updateColorId(configuration.rollerShutter.colors[c]);
                }
            }
        );
    }
    if (configuration.type === 'door') {
        (configuration as DoorConfiguration).doorHardware = {
            doorHandleAdjustment: null,
            type: null,
            set: null,
            pull: null,
            pullColor: null,
            pullColorRelatedToPlate: false,
            electronicLock: null,
            beaconProximity: false,
            color: null,
            plateType: null,
            doorViewer: null,
            doorViewerPunching: false,
            plates: {
                mainLock: null,
                additionalLock: null,
                color: null
            },
            cylinders: {
                mainLock: null,
                additionalLock: null,
                system: null,
                color: null,
                isKeyAvailableForMainLock: null,
                maxKeyQuantityForMainLock: null,
                keyQuantityForMainLock: null,
                keyPriceForMainLock: null,
                isKeyAvailableForAdditionalLock: null,
                maxKeyQuantityForAdditionalLock: null,
                keyQuantityForAdditionalLock: null,
                keyPriceForAdditionalLock: null,
            },
            decorativeHingeCovers: null,
            decorativeHingeCoversColor: null,
            decorativeHingeCoversColorRelatedToPlate: false,
            visualizations: {
                pull: null,
                doorViewer: null,
                mainLockPlate: null,
                additionalLockPlate: null,
                mainLockCylinder: null,
                additionalLockCylinder: null,
                decorativeHingeCovers: null
            }
        };
    }

    return configuration;

    function updateColorId(color: Color | ColorId) {
        if (color.RAL) {
            color.id = dataRequiredToUpdate.windowColorRalsMap[color.id] || color.id;
        }
    }
}

function convertToDictionaryEntry<T extends { name: string }>(
    obj: T,
    langCode: string
): T & {
    name: Record<string, string>;
} {
    return {
        ...obj,
        name: {
            [langCode]: obj.name,
        },
    };
}

function sashToSashV2(
    sash: Sash,
    dict: WindowConfiguration['dictionary'],
    langCode: string
): SashV2 {
    const mullions: MullionV2[] = sash.intMullions && sash.intMullions.map(m => {
        if (m.reinforcement) {
            dict.reinforcements[m.reinforcement.id] = convertToDictionaryEntry(
                m.reinforcement,
                langCode
            );
        }
        return {
            ...m,
            reinforcement: m.reinforcement ? m.reinforcement.id : null,
        };
    });

    if (sash.filling) {
        dict.fillings[sash.filling.id] = convertToDictionaryEntry(sash.filling, langCode);
    }

    if (sash.frame) {
        if (sash.frame.top.reinforcement) {
            dict.reinforcements[sash.frame.top.reinforcement.id] = convertToDictionaryEntry(
                sash.frame.top.reinforcement,
                langCode
            );
        }

        if (sash.frame.bottom.reinforcement) {
            dict.reinforcements[sash.frame.bottom.reinforcement.id] = convertToDictionaryEntry(
                sash.frame.bottom.reinforcement,
                langCode
            );
        }

        if (sash.frame.left.reinforcement) {
            dict.reinforcements[sash.frame.left.reinforcement.id] = convertToDictionaryEntry(
                sash.frame.left.reinforcement,
                langCode
            );
        }

        if (sash.frame.right.reinforcement) {
            dict.reinforcements[sash.frame.right.reinforcement.id] = convertToDictionaryEntry(
                sash.frame.right.reinforcement,
                langCode
            );
        }
    }

    const sashV2: SashV2 = {
        ...sash,
        intMullions: mullions,
        intSashes: sash.intSashes && sash.intSashes.map(s => sashToSashV2(s, dict, langCode)),
        filling: {
            id: sash.filling.id,
            color: sash.filling.color,
            custom: sash.filling.custom,
            overlayColor: sash.filling.overlay_color,
            visualization: null,
        },
        panelInner: null,
        frame: sash.frame && {
            top: {
                ...sash.frame.top,
                reinforcement: sash.frame.top.reinforcement?.id || null,
            },
            bottom: {
                ...sash.frame.bottom,
                reinforcement: sash.frame.bottom.reinforcement?.id || null,
            },
            left: {
                ...sash.frame.left,
                reinforcement: sash.frame.left.reinforcement?.id || null,
            },
            right: {
                ...sash.frame.right,
                reinforcement: sash.frame.right.reinforcement?.id || null,
            },
        },
        panelGlazing: sash.panelGlazing?.id && {
            ...sash.panelGlazing,
            visualization: null
        },
        handleInner: sash.handleInner?.id && {
            ...sash.handleInner,
            visualization: null,
        },
        handleOuter: sash.handleOuter?.id && {
            ...sash.handleOuter,
            visualization: null,
        }
    };

    return sashV2;
}

function frameToFrameV2(
    frame: Frame,
    dict: WindowConfiguration['dictionary'],
    langCode: string
): FrameV2 {
    const frameV2: FrameV2 = {
        ...frame,
        frame: frame.frame.map(f => {
            if (f.reinforcement) {
                dict.reinforcements[f.reinforcement.id] = convertToDictionaryEntry(
                    f.reinforcement,
                    langCode
                );
            }
            return {
                ...f,
                reinforcement: f.reinforcement?.id || null,
            };
        }),
    };
    return frameV2;
}

function convertReinforcementV2<T extends { reinforcement: Reinforcement | null }>(
    profile: T,
    dict: WindowConfiguration['dictionary'],
    langCode: string
): T & {
    reinforcement: number | null;
} {
    if (profile.reinforcement) {
        dict.reinforcements[profile.reinforcement.id] = convertToDictionaryEntry(
            profile.reinforcement,
            langCode
        );
    }
    const profileV2: T & {
        reinforcement: number | null;
    } = {
        ...profile,
        reinforcement: profile.reinforcement && profile.reinforcement.id,
    };
    return profileV2;
}

function convertColorId(color) {
    return Number(color.id)
                + '+'
                + Number(color.RAL);
}