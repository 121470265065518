import {Injectable, Inject} from '@angular/core';
import { Common } from '@icc/common/Common';
import {APP_CONFIG, AppConfig, AppConfigFactory} from '@icc/common/config';
import { ActiveSash } from '../layout/active-sash';

@Injectable()
export class MullionsService {

    mullionTypes = {
        fixed_mullion: {
            type: 'fixed_mullion',
            vertical: ['vertical_frame'],
            horizontal: ['horizontal_frame'],
            set: 'fixedMullion',
            priceType: 'fixed_mullion',
        },
        structured_muntin_frame: {
            type: 'fixed_mullion',
            vertical: ['vertical_frame'],
            horizontal: ['horizontal_frame'],
            set: 'fixedMullion',
            priceType: 'structured_muntin_frame',
        },
        structured_muntin_sash: {
            type: 'fixed_mullion',
            vertical: ['vertical_sash'],
            horizontal: ['horizontal_sash'],
            set: 'fixedMullion',
            priceType: 'structured_muntin_sash',
        },
        false_mullion: {
            type: 'false_mullion',
            vertical: [],
            horizontal: false,
            set: 'falseMullion',
            priceType: 'false_mullion',
        },
        false_mullion_central_handle: {
            type: 'false_mullion',
            vertical: ['central_handle'],
            horizontal: false,
            set: 'centralHandleFalseMullion',
            priceType: 'false_mullion',
        },
        false_mullion_double_handle: {
            type: 'false_mullion',
            vertical: ['double_handle'],
            horizontal: false,
            set: 'doubleHandleFalseMullion',
            priceType: 'false_mullion',
        },
        no_mullion: {
            type: false,
            vertical: false,
            horizontal: false,
            set: '',
            priceType: false,
        }
    };

    constructor(
        @Inject(APP_CONFIG) private config: AppConfigFactory
    ) { }

    getMullionTypeBetweenFields(fields1, fields2, direction: 'horizontal' | 'vertical', falseMullionSashesIds: number[], type, mullions?) {
        const falseMullionsMap = {
            DS: ['D', 'DK'],
            DSH: ['D', 'DK'],
            DSC: ['D', 'DK'],
            ODS: ['OD'],
            ODSH: ['OD'],
            DRP: ['DRA'],
            DOP: ['DOA'],
            PSK: ['PSK'],
            SD: ['SU', 'F', 'FF'],
            SU: ['SD', 'F', 'FF'],
        };
        const field1 = fields1[0];
        const field2 = fields2[0];

        let joinFixAddAstragals = this.config().IccConfig.Configurators.window.joinFixAddAstragals;
        if (Common.isObject(joinFixAddAstragals)) {
            const normalizedType = type === 'door' ? 'door' : 'window';
            joinFixAddAstragals = this.config().IccConfig.Configurators.window.joinFixAddAstragals[normalizedType];
        }

        let mullionType = 'fixed_mullion';
        if (field1.parentId != null && field2.parentId != null) {
            mullionType = 'structured_muntin_sash';
        } else if (
            (
                // field1.type.type === 'HS'
                // || field1.type.type === 'S'
                // || field2.type.type === 'HS'
                // || field2.type.type === 'S'
                /*|| */(falseMullionSashesIds.indexOf(field1.frame.right.profileId) > -1
                    || falseMullionSashesIds.indexOf(field2.frame.left.profileId) > -1)
                    && fields1.length === 1 && fields2.length === 1
            )
            && direction === 'vertical'
        ) {
            mullionType = 'no_mullion';
        } else if ((field1.type.type === 'DSH' && !field2.type.passive && falseMullionsMap['DSH'].indexOf(field2.type.type) > -1
                || field2.type.type === 'DSH' && !field1.type.passive && falseMullionsMap['DSH'].indexOf(field1.type.type) > -1
                || field1.type.type === 'ODSH' && !field2.type.passive && falseMullionsMap['ODSH'].indexOf(field2.type.type) > -1
                || field2.type.type === 'ODSH' && !field1.type.passive && falseMullionsMap['ODSH'].indexOf(field1.type.type) > -1)
            && field1.type.handle_position === 'R'
            && field2.type.handle_position === 'L'
            && fields1.length === 1
            && fields2.length === 1
            && direction === 'vertical'
        ) {
            mullionType = 'false_mullion_double_handle';
        }  else if ((field1.type.type === 'DSC' && !field2.type.passive && falseMullionsMap['DSC'].indexOf(field2.type.type) > -1
                || field2.type.type === 'DSC' && !field1.type.passive && falseMullionsMap['DSC'].indexOf(field1.type.type) > -1)
            && field1.type.handle_position === 'R'
            && field2.type.handle_position === 'L'
            && fields1.length === 1
            && fields2.length === 1
            && direction === 'vertical'
        ) {
            mullionType = 'false_mullion_central_handle';
        } else if ((((
                field1.type.passive
                    && falseMullionsMap[field1.type.type]
                    && !field2.type.passive
                    && falseMullionsMap[field1.type.type].indexOf(field2.type.type) > -1
                || field2.type.passive
                    && falseMullionsMap[field2.type.type]
                    && !field1.type.passive
                    && falseMullionsMap[field2.type.type].indexOf(field1.type.type) > -1
                )
                && field1.type.handle_position === 'R'
                && field2.type.handle_position === 'L')
            // Warunki dla HS
            || (
                field1.type.type === 'HS'
                && field1.type.handle_position === 'R'
                && field2.type.type === 'HS'
                && field2.type.handle_position === 'L'
            )
            || (
                field1.type.type === 'HS'
                && field2.type.type !== 'HS'
                && field1.type.handle_position === 'R'
                && field1.nearMullions.left !== -1
                && mullions
                && mullions.find(el => el.id === field1.nearMullions.left).multiAlignLeft[0].type.type !== 'HS')
            || (
                field1.type.type !== 'HS'
                && field2.type.type === 'HS'
                && field2.type.handle_position === 'L'
                && field2.nearMullions.right !== -1
                && mullions
                && mullions.find(el => el.id === field2.nearMullions.right).multiAlignRight[0].type.type !== 'HS'))
            && fields1.length === 1
            && fields2.length === 1
            && direction === 'vertical'
        ) {
            mullionType = 'false_mullion';
        } else if (field1.type.type === 'F' && field2.type.type === 'F'
            && joinFixAddAstragals
        ) {
            mullionType = 'structured_muntin_frame';
        } else if (fields1.length === 1
            && fields2.length === 1
            && (field1.type.type === 'SD' && falseMullionsMap['SD'].indexOf(field2.type.type) > -1
                || field2.type.type === 'SU' && falseMullionsMap['SU'].indexOf(field1.type.type) > -1)
            && direction === 'horizontal'
        ) {
            mullionType = 'false_mullion';
        }
        return mullionType;
    }

    neededWiderMullion(fields1, fields2, direction: 'horizontal' | 'vertical') {
        if (!fields1.every(e => e.type) || !fields2.every(e => e.type)) {
            return false;
        }

        let hasHingesOnLeft = fields1.some(field => field.type.handle_position === 'L' && !['HS', 'FD', 'FDO'].includes(field.type.type));
        let hasHingesOnRight = fields2.some(field => field.type.handle_position === 'R' && !['HS', 'FD', 'FDO'].includes(field.type.type));

        if (direction === 'horizontal') {
            hasHingesOnLeft = fields1.some(field => ['K', 'DK'].includes(field.type.type));
            hasHingesOnRight = fields2.some(field => ['OKL'].includes(field.type.type));
        }

        return hasHingesOnLeft && hasHingesOnRight;
    }

    getMullionGlazingOrHardwareOptions(mullion) {
        const alignSides = mullion.direction === 'vertical'
            ? ['multiAlignLeft', 'multiAlignRight']
            : ['multiAlignTop', 'multiAlignBottom'];

        const sashes = [
            ...mullion[alignSides[0]],
            ...mullion[alignSides[1]]
        ];
        return this.getGlazingOrHardwareOptions(mullion.parentSash != null, sashes);
    }

    getGlazingOrHardwareOptions(internal: boolean, sashes: ActiveSash[]) {
        if (internal) {
            return 'for_glazing';
        }

        const forGlazingTypes = ['F', 'FF', 'OFF'];
        let forGlazing = false;
        let forHardware = false;
        sashes.forEach(sash => {
            if (forGlazingTypes.includes(sash.type.type)) {
                forGlazing = true;
            } else {
                forHardware = true;
            }
        });

        if (forGlazing && forHardware) {
            return 'for_glazing_or_hardware';
        } else if (forGlazing) {
            return 'for_glazing';
        } else {
            return 'for_hardware';
        }
    }
}
