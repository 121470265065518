<icc-tabs *ngIf="mainTabs.length > 1" [tabs]="mainTabs" [activeTabIndex]="initTab" (activated)="changedTab($event)"></icc-tabs>
<div id="fillings-list" class='fillings-list' *ngIf="mode === 'list'">
    <icc-list [searchable]="true"
              [items]="fillings"
              [selected]="selectedFilling?.id"
              [itemTemplate]="'itemImgTitleDescription'"
              itemImgHeight="high"
              vertical="true"
              (select)="selectFilling($event)"
              (editGlazing)="openGlazingUnitModal($event)"
              ></icc-list>
</div>
<div id="fillings-filters" *ngIf="mode === 'filters'">
    <form [formGroup]="fillingFilters">
        <h3 *ngIf="filtersLevels.thermalTransmittance.length > 1">{{ 'WINDOW|Przenikalność cieplna (Uw)' | translate}}</h3>
        <icc-slider *ngIf="filtersLevels.thermalTransmittance.length > 1" [steps]="filtersLevels.thermalTransmittance" formControlName="thermalTransmittance"></icc-slider>
        <p *ngIf="filtersLevels.thermalTransmittance.length > 1">{{ thermalTransmittanceDescription$ | async}}</p>
        <h3 *ngIf="filtersLevels.securityLevelOutside.length > 1">{{ 'WINDOW|Bezpieczeństwo zewnętrzne' | translate}}</h3>
        <icc-slider *ngIf="filtersLevels.securityLevelOutside.length > 1" [steps]="filtersLevels.securityLevelOutside" formControlName="securityLevelOutside"></icc-slider>
        <p *ngIf="filtersLevels.securityLevelOutside.length > 1">{{ securityLevelOutsideDescription$ | async}}</p>
        <h3 *ngIf="filtersLevels.securityLevelInside.length > 1">{{ 'WINDOW|Bezpieczeństwo wewnętrzne' | translate}}</h3>
        <icc-slider *ngIf="filtersLevels.securityLevelInside.length > 1" [steps]="filtersLevels.securityLevelInside" formControlName="securityLevelInside"></icc-slider>
        <p *ngIf="filtersLevels.securityLevelInside.length > 1">{{ securityLevelInsideDescription$ | async}}</p>
        <h3 *ngIf="filtersLevels.noiseProtection.length > 1">{{ 'WINDOW|Ochrona przed hałasem' | translate}}</h3>
        <icc-slider *ngIf="filtersLevels.noiseProtection.length > 1" [steps]="filtersLevels.noiseProtection" formControlName="noiseProtection"></icc-slider>
        <p *ngIf="filtersLevels.noiseProtection.length > 1">{{ noiseProtectionDescription$ | async}}</p>
        <h3 *ngIf="filtersLevels.glassType.length > 1 || filtersLevels.glassType?.length === 1 && filtersLevels.glassTypeVariant.length > 1">{{ 'WINDOW|Szyby specjalne' | translate}}</h3>
        <div class="glass-type-select" *ngIf="filtersLevels.glassType?.length > 1">
            <div *ngFor="let type of filtersLevels.glassType" (click)="changeGlassType(type)" [class.selected]="fillingFilters.get('glassType')?.value === type?.id">
                <div class="item-imgTitle">
                    <img class="item-imgTitle-img" [src]="('/files/glasstype/' + type.image)" [alt]="type.name" defaultImg>
                    <p class="item-imgTitle-title">{{ type.name }}</p>
                </div>
            </div>
        </div>
        <div class="glass-type-select" *ngIf="filtersLevels.glassType?.length === 1 && filtersLevels.glassTypeVariant.length > 1">
            <div *ngFor="let type of filtersLevels.glassTypeVariant" (click)="changeGlassTypeVariant(type)" [class.selected]="fillingFilters.get('glassTypeVariant')?.value === type?.id">
                <div class="item-imgTitle">
                    <img class="item-imgTitle-img" [src]="('/files/glasstypevariant/' + type.image)" [alt]="type.name" defaultImg>
                    <p class="item-imgTitle-title">{{ type.name }}</p>
                </div>
            </div>
        </div>
        <icc-select-box [title]="'WINDOW| Wariant szyby' | translate"
                [description]="selectedGlassTypeVariant?.name"
                [imageUrl]="'/files/glasstypevariant/' + (selectedGlassTypeVariant?.image || '')"
                [buttonText]="'INTERFACE|Zmień' | translate"
                *ngIf="getMatchingVariants().length > 0"
                (select)="selectGlassTypeVariant()"
        ></icc-select-box>
    </form>
</div>
<div *ngIf="selectedFilling && mode !== 'list'" class="selected-summary mat-elevation-z4">
    <img [src]="'/files/filling/' + (selectedFilling?.image || '')" defaultImg>
    <p>{{ selectedFilling?.name }}</p>
    <button mat-raised-button (click)="select($event)" color="primary">{{ 'INTERFACE|Wybierz' | translate}}</button>
</div>
