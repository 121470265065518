import { Component, OnInit, Inject } from '@angular/core';
import { PageComponent, _ } from '@icc/configurator/shared';
import {
    APP_CONFIG,
    AppConfigFactory,
    EventBusService,
    ConfigurationsService,
    DrawService,
    TranslateService,
    core,
    Common,
    WindowActiveConfiguration,
} from '@icc/common';
import { ProfilesModalService } from '../profiles/profiles-modal.service';
import { ActiveSash } from '@icc/common/layout/active-sash';
import { HandlesService } from '@icc/legacy/configurator/steps/window/handles/handles.service';

@Component({
    selector: 'icc-edit-handles-page',
    templateUrl: './edit-handles-page.component.html',
    styleUrls: ['./edit-handles-page.component.scss'],
})
export class EditHandlesPageComponent extends PageComponent implements OnInit {
    title = _('WINDOW|Klamka');
    fullscreen = true;

    drawOptions: any = null;
    drawButtons = {
        fullscreen: false,
        moveShift: true,
        alignments: true,
        dimensions: false,
        extensions: true,
        mullions: true,
        profiles: false,
        sashes: true,

        toggle: (
            type:
                | 'alignments'
                | 'dimensions'
                | 'extensions'
                | 'fullscreen'
                | 'mullions'
                | 'profiles'
                | 'sashes'
        ) => {
            if (type && typeof this.drawButtons[type] !== 'undefined') {
                this.drawButtons[type] = !this.drawButtons[type];
            }

            this.drawButtons.update();
        },

        update: () => {
            const conf = this.configurationsService.conf.Current;
            if (conf) {
                this.drawOptions = Object.assign({}, this.drawService.options, {
                    sashFrame: {
                        buttons: [
                            {
                                condition: (response: any) =>
                                    [
                                        'F',
                                        'FF',
                                        'OFF',
                                        'DS',
                                        'DSC',
                                        'ODS',
                                        'DRP',
                                        'DOP',
                                        'FD',
                                        'FDO',
                                    ].indexOf(response.sash.type.type) === -1,
                                innerHtml: `<img src="/application/dist/web/img/icons/handler.png">`,
                                title: this.translateService.instant('INTERFACE|Wybierz klamkę'),
                                onClick: (response: any) => {
                                    const sash = core.fIdO(
                                        this.configurationsService.conf.Current.Sashes,
                                        response.sash.id
                                    );
                                    this.openModalHandle(sash, 'innerLever');
                                },
                            },
                        ],
                    },
                    onInterfaceClick: () => {},
                });
                this.drawOptions.base.side = 'inner';
            }

            this.eventBusService.post({
                key: 'icc-redraw',
                value: null,
            });
        },
    };

    constructor(
        @Inject(APP_CONFIG) private config: AppConfigFactory,
        private eventBusService: EventBusService,
        private configurationsService: ConfigurationsService<'window'>,
        public drawService: DrawService,
        private profilesModalService: ProfilesModalService,
        private translateService: TranslateService,
        private handlesService: HandlesService
    ) {
        super();
    }

    ngOnInit() {
        this.drawButtons.update();
    }

    openModalHandle(sash: 'window' | ActiveSash | undefined, place: string) {
        if (
            place === 'innerLever'
            && (sash === 'window'
                || (sash && sash.type && sash.type.type !== 'DRA' && sash.type.type !== 'DOA'))
        ) {
            this.handlesService.openModalHandle(sash, place, 'InnerLever');
            if (this.config().IccConfig.Configurators.tutorialAvailable) {
                this.eventBusService.post({
                    key: 'tutorialSteps',
                    value: 'modalHandleSash',
                });
            }
        } else {
            this.handlesService.openModalHandle(
                sash,
                place,
                this.configurationsService.conf.Current.HandleType
            );
            if (this.config().IccConfig.Configurators.tutorialAvailable) {
                this.eventBusService.post({
                    key: 'tutorialSteps',
                    value: 'modalHandle',
                });
            }
        }
    }
}
