
<h3 *ngIf="!hiddenPrice">{{conf.Price | currency : currency}}</h3>

<mat-accordion class="price-details-align">
    <mat-expansion-panel *ngFor="let seg of segments">
        <mat-expansion-panel-header [collapsedHeight]="'92px'" [expandedHeight]="'92px'">
            <mat-panel-title>
                <span>{{seg.id}}. {{seg.type}}</span>
                <b>
                    <span *ngIf="seg.name">{{seg.name}}</span>
                    <span *ngIf="!seg.name">{{seg.type}}</span>
                </b>
            </mat-panel-title>
            <mat-panel-description>
                <span></span>
                <span>
                    <h4 *ngIf="seg.valueType == 'value' && !hiddenPrice" style="padding-right: 0px">{{seg.value | currency:currency:'—' }}</h4>
                    <h4 *ngIf="seg.valueType == 'percent' && !hiddenPrice" style="padding-right: 0px">{{percentConvert(seg.value)}}%</h4>
                    <h4 *ngIf="seg.valueType == 'multiplier' && !hiddenPrice" style="padding-right: 0px">{{seg.value}}</h4>
                </span>
                <span>
                    <h4 *ngIf="seg.valueType == 'value' && !hiddenPrice" style="font-weight: 400; padding-right: 0px">{{seg.baseValue | currency:currency:'—' }}</h4>
                    <h4 *ngIf="seg.valueType == 'percent' && !hiddenPrice" style="font-weight: 400; padding-right: 0px">{{percentConvert(seg.baseValue)}}%</h4>
                    <h4 *ngIf="seg.valueType == 'multiplier' && !hiddenPrice" style="font-weight: 400; padding-right: 0px">{{seg.baseValue}}</h4>
                </span>
            </mat-panel-description>
        </mat-expansion-panel-header>
        <div class="summaryStep" *ngIf="seg.to && seg.valueType == 'percent'">
            {{ 'INTERFACE|Dopłata do:' | translate }}
            <strong *ngIf="!isArray(seg.to)">{{seg.to}}</strong>
            <strong *ngIf="isArray(seg.to)"><span *ngFor="let item of seg.to">{{item}}<span *ngIf="!$last">, </span></span></strong>
            <br />
        </div>
        <ng-container *ngTemplateOutlet="segmentDetails;context:{'data': seg.data}"></ng-container>
    </mat-expansion-panel>
</mat-accordion>
<h2>{{'CONFIGURATOR|Wyliczone dopłaty' | translate}}</h2>
<mat-accordion class="price-details-align">
    <mat-expansion-panel *ngFor="let seg of parts">
        <mat-expansion-panel-header  [collapsedHeight]="'92px'" [expandedHeight]="'92px'">
            <mat-panel-title>
                <span>{{seg.type}}</span>
                <b>
                    <span *ngIf="seg.name">{{seg.name}}</span>
                    <span *ngIf="!seg.name">{{seg.type}}</span>
                </b>
            </mat-panel-title>
            <mat-panel-description>
                <span></span>
                <span>
                    <h4 style="padding-right: 0px" *ngIf="!hiddenPrice">{{seg.value | currency:currency:'—' }}</h4>
                </span>
                <span>
                    <h4 style="font-weight: 400; padding-right: 0px" *ngIf="seg.basePercent && !hiddenPrice">{{seg.basePercent}}</h4>
                    <h4 style="font-weight: 400; padding-right: 0px" *ngIf="!seg.basePercent && !hiddenPrice"></h4>
                </span>
                <span>
                    <h4 style="font-weight: 400; padding-right: 0px" *ngIf="!hiddenPrice">{{seg.baseValue | currency:currency:'—' }}</h4>
                </span>
            </mat-panel-description>
        </mat-expansion-panel-header>
        <ng-container *ngTemplateOutlet="segmentDetails;context:{'data': seg.valueData}"></ng-container>
        <ng-container *ngTemplateOutlet="segmentDetails;context:{'data': seg.percentData}"></ng-container>
    </mat-expansion-panel>
</mat-accordion>

<ng-template #segmentDetails let-data="data">
    <div class="summaryStep" *ngIf="!isArray(data) && isObject(data)">
        <div *ngFor="let detail of data | keyvalue" >
            {{detail.key}}:
            <strong *ngIf="!isArray(detail.value) && !isObject(detail.value) && detail.key != 'Cena' && detail.key != 'Grupy rabatów'">{{detail.value}}</strong>
            <strong *ngIf="!hiddenPrice && !isArray(detail.value) && !isObject(detail.value) && detail.key == 'Cena' || detail.key == 'Grupy rabatów'">{{detail.value| currency:currency}}</strong>
            <br />
    
            <span *ngIf="isObject(detail.value) || isArray(detail.value)">
                <span *ngFor="let A of detail.value | keyvalue">
                    <span *ngIf="(!isArray(A.value) && !isObject(A.value)) && A.key != 'Id'">
                        &nbsp;&nbsp;&nbsp;&nbsp;{{A.key}}: <strong>{{A.value}}</strong><br />
                    </span>
                    <span *ngIf="isObject(A.value) || isArray(A.value)">
                        &nbsp;&nbsp;&nbsp;&nbsp;{{A.key}}:<br />
                        <span *ngFor="let B of A.value | keyvalue">
                            <span *ngIf="(!isArray(B.value) && !isObject(B.value)) && B.key != 'Id cennika' && B.key != 'Id profilu'">
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{B.key}}: <strong>{{B.value}}</strong><br />
                            </span>
                            <span *ngIf="isObject(B.value) || isArray(B.value)">
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{B.key}}:<br />
                                <span *ngFor="let C of B.value | keyvalue">
                                    <span *ngIf="(!isArray(C.value) && !isObject(C.value)) && C.key != 'Id'">
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{C.key}}: <strong>{{C.value}}</strong><br />
                                    </span>
                                    <span *ngIf="(isObject(C.value) || isArray(C.value)) && C.key != 'systems'"><!-- ukryj "systems" -->
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{C.key}}:<br />
                                        <span *ngFor="let D of C.value | keyvalue">
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{D.key}}: <strong>{{D.value}}</strong><br />
                                        </span>
                                    </span>
                                </span>
                            </span>
                        </span>
                    </span>
                </span>
            </span>
        </div>
    </div>
    <div class="summaryStep" *ngIf="isArray(data)">
        <div *ngFor="let item of data" >
            <span *ngFor="let detail of item | keyvalue">
                {{detail.key}}:
                <strong *ngIf="!isArray(detail.value) && !isObject(detail.value) && detail.key != 'Cena'">{{detail.value}}</strong>
                <strong *ngIf="!hiddenPrice && !isArray(detail.value) && !isObject(detail.value) && detail.key == 'Cena'">{{detail.value| currency:currency}}</strong>
                <span *ngIf="isObject(detail.value) || isArray(detail.value)">
                    <span *ngFor="let A of detail.value | keyvalue">
                        <span *ngIf="isObject(A.value) || isArray(A.value)">
                            <br />{{A.key}}:
                            <span *ngFor="let B of A.value | keyvalue">
                                <span *ngIf="isObject(B.value) || isArray(B.value)">
                                    &nbsp;&nbsp;&nbsp;&nbsp;<br />{{B.key}}:
                                    <span *ngFor="let C of B.value | keyvalue">
                                        <span *ngIf="isObject(C.value) || isArray(C.value)">
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<br />{{C.key}}:
                                            <span *ngFor="let D of C.value | keyvalue">
                                                <br />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{D.key}}: <strong>{{D.value}}</strong>
                                            </span>
                                        </span>
                                        <span *ngIf="!isObject(C.value) && !isArray(C.value)">
                                            <br />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{C.key}}: <strong>{{C.value}}</strong>
                                        </span>
                                    </span>
                                </span>
                                <span *ngIf="!isObject(B.value) && !isArray(B.value)">
                                    <br />&nbsp;&nbsp;&nbsp;&nbsp;{{B.key}}: <strong>{{B.value}}</strong>
                                </span>
                            </span>
                        </span>
                        <span *ngIf="!isObject(A.value) && !isArray(A.value)">
                            {{A.key}}: <strong>{{A.value}}</strong><br />
                        </span>
                    </span>
                </span>
                <br />
            </span>

        </div>
    </div>
    <div class="summaryStep" *ngIf="!isArray(data) && !isObject(data)" >
    </div>
</ng-template>