import { Injectable, Inject } from '@angular/core';
import {
    EventBusService,
    core,
    ConfigurationsService,
    AppConfigFactory,
    APP_CONFIG,
    WindowActiveConfiguration,
    ValidationService,
} from '@icc/common';
import { ModalService, isNotNullOrUndefined } from '@icc/helpers';
import { FillingColorsPageComponent } from 'libs/configurator/window/src/lib/filling-colors-page/filling-colors-page.component';
import { IccFilling, IccColors, IccColor, IccWood } from '@icc/common/data-types';
import { ActiveSash } from '@icc/common/layout/active-sash';
import { ColorsService } from '../colors/colors.service';
import { FillingsService } from './fillings.service';
import { DoorActiveConfiguration } from '@icc/common/configurations/DoorActiveConfiguration';
import { LoadedConfiguratorsDataValue } from '@icc/common/configurators/configurators-data.service';
import { Common } from '@icc/common/Common';
import { Side } from '@icc/common/data-types/ColorGroup';
const colorsModalTemplate = require('!raw-loader!../../complementary_goods/colors/modal.html');

@Injectable()
export class FillingsColorsService {
    colors = [];
    colorGroups = [];
    woodType = 0;
    data = null;
    constructor(
        private eventBusService: EventBusService,
        private modalService: ModalService,
        private colorsService: ColorsService,
        private configurationsService: ConfigurationsService<'window' | 'door'>,
        private validationService: ValidationService,
        private fillingsService: FillingsService,
        @Inject(APP_CONFIG) private config: AppConfigFactory
    ) {
        this.eventBusService.subscribe<LoadedConfiguratorsDataValue>(
            'loadedConfiguratorsData',
            data => {
                this.loadColors(data.value, data.activeConfiguration as WindowActiveConfiguration);
            }
        );
    }
    private loadColors(data, conf: WindowActiveConfiguration) {
        this.data = data;
        if (!conf) {
            return;
        }
        this.validationService.indeterminate(conf, 'loadedFillingsColors');

        if ((data.windowColorsAll) && data.windowColorGroups) {
            this.colors = data.windowColorsAll || [];
            this.colorGroups = data.windowColorGroups || [];
            Object.assign(conf, this.validationService.valid(conf, 'loadedFillingsColors'));
            this.eventBusService.post({
                key: 'loadedFillingsColors',
                value: {
                    colors: this.colors,
                    colorGroups: this.colorGroups,
                },
                conf
            });
        } else {
            this.validationService.invalid(conf, 'loadedFillingsColors');
        }
    }

    getFillingColors(fillingType) {
        if (fillingType) {
            return this.colors
                .filter(
                    color =>
                        color.groups
                        && color.groups
                            .some(
                                groupId =>
                                    fillingType.color_groups_ids
                                    && fillingType.color_groups_ids.includes(groupId)
                            )
                );
        } else {
            return [];
        }
    }
    getSashColors(sash: ActiveSash, side: 'outer' | 'inner'){
        const outerColor = sash.glazing.selectedColor.frame.outer;
        const coreColor = sash.glazing.selectedColor.frame.core;
        const innerColor = sash.glazing.selectedColor.frame.inner;
        return side === 'outer'
                    ? (outerColor?.id ? outerColor : coreColor)?.id
                    : (innerColor?.id ? innerColor : coreColor)?.id;
    }
    /**
     * Otwieranie modala wyboru  koloru panela wypełnieniowegi
     * @param  {Object} accessory Panel wypełnieniowy
     */
    openModalColors(sash, novalidate, side, conf=this.configurationsService.conf.Current) {
        const modalInstance = this.modalService.open({
            template: colorsModalTemplate,
            controller: 'ModalPvcColorsCtrl as $ctrl',
            pageComponent: FillingColorsPageComponent,
            resolve: {
                sash: () => sash,
                novalidate: () => novalidate,
                colors: () => this.getFillingColors(sash.glazing),
                colorGroups: () => this.colorGroups.filter(el => sash.glazing.color_groups_ids.includes(el.id)),
                selectedColorId: () => this.getSashColors(sash,side)
            }
        });

        modalInstance.result.then(selection => {
            if (side === 'outer'){
                sash.glazing.selectedColor.frame.outer = core.copy(selection);
                sash.glazing.selectedColor.sash.outer = core.copy(selection);
                if (sash.glazing.selectedColor.frame.core && !sash.glazing.selectedColor.frame.inner){
                    sash.glazing.selectedColor.frame.inner = core.copy(sash.glazing.selectedColor.frame.core)
                    sash.glazing.selectedColor.sash.inner = core.copy(sash.glazing.selectedColor.frame.core)
                }
            } else if (side === 'inner'){
                sash.glazing.selectedColor.frame.inner = core.copy(selection);
                sash.glazing.selectedColor.sash.inner = core.copy(selection);
                if (sash.glazing.selectedColor.frame.core && !sash.glazing.selectedColor.frame.outer){
                    sash.glazing.selectedColor.frame.outer = core.copy(sash.glazing.selectedColor.frame.core)
                    sash.glazing.selectedColor.sash.outer = core.copy(sash.glazing.selectedColor.frame.core)
            }
            }
            this.eventBusService.post({
                key: 'changedSashes',
                value: {},
            });
        });

        modalInstance.closed.then(() => {
            if (this.config().IccConfig.Configurators.tutorialAvailable) {
                this.eventBusService.post({
                    key: 'tutorialSteps',
                    value: 'getStepImg',
                });
            }
        });

        if (this.config().IccConfig.Configurators.tutorialAvailable) {
            this.eventBusService.post({
                key: 'tutorialSteps',
                value: 'pvcModal',
            });
        }
    }

    /**
     * Otwieranie modala wyboru  koloru panelu deco
     * @param  {Object} conf Przekazana konfiguracja
     * @param  {Object} sashId Id sasha
     * @param  {Object} dualColor Rodzaj wybieranego koloru - true kolor2, false kolor1 panelu
     * @param  {Object} novalidate Czy walidować kolor
     */
    openModalDecoPanelColors(conf, sashId, dualColor, novalidate) {
        const field = dualColor ? 'selectedColorSecond' : 'selectedColor';
        const sash = conf.Sashes.reduce((sashes, s) => sashes.concat([s], s.intSashes), []).find(
            s => s.id === sashId
        );
        const modalInstance = this.modalService.open({
            template: colorsModalTemplate,
            controller: 'ModalDecoPanelColorsCtrl as $ctrl',
            pageComponent: FillingColorsPageComponent,
            resolve: {
                sash: () => sash.glazing,
                dualColor: () => dualColor,
                novalidate: () => novalidate,
            },
        });

        modalInstance.result.then(selection => {
            this.eventBusService.post({
                key: 'changedSashes',
                value: {},
            });
            sash.glazing[field] = core.copy(selection.colors);
            if (typeof sash.parentId !== 'undefined') {
                conf.Sashes.find(s => s.id === sash.parentId).glazing[field] = core.copy(
                    selection.colors
                );
            }
            sash.selectedWood = core.copy(selection.wood);
            this.eventBusService.post({
                key: 'icc-redraw',
                value: 'frame',
            });
        });

        modalInstance.closed.then(() => {
            this.eventBusService.post({
                key: 'reloadColorsData',
                value: null,
            });
            if (this.config().IccConfig.Configurators.tutorialAvailable) {
                this.eventBusService.post({
                    key: 'tutorialSteps',
                    value: 'getStepImg',
                });
            }
        });
    }

    /**
     * Otwieranie modala wyboru  kolorów wszystkich paneli wypełnieniowych
     * @param  {Object} accessory Panel wypełnieniowy
     */
    selectColor(glazing, novalidate) {
        this.modalService
            .open({
                template: colorsModalTemplate,
                controller: 'ModalPvcColorsCtrl as $ctrl',
                pageComponent: FillingColorsPageComponent,
                resolve: { sash: () => glazing, novalidate: () => novalidate },
            })
            .result.then(selection => {
                this.configurationsService.conf.Current.Sashes.forEach(sash => {
                    sash.glazing.selectedColor = core.copy(selection.colors);
                    sash.glazing.selectedWood = core.copy(selection.wood);
                    sash.intSashes.forEach(intSash => {
                        intSash.glazing.selectedColor = core.copy(selection.colors);
                        intSash.glazing.selectedWood = core.copy(selection.wood);
                    });
                });
                this.eventBusService.post({
                    key: 'changedSashes',
                    value: {},
                });
            });
    }

    setPanelColor(filling: ActiveSash['glazing'], colors: IccColors) {
        filling.selectedColor = core.copy(colors);
    }

    /**
     * Ustawia kolor w danym w miejscu konstrukcji.
     * @memberof ColorsService
     * @param {string}     colorType Strona konstrukcji (outer, inner, core, alushell)
     * @param {string}     place     Miejsce - skrzydło, rama
     * @param {object}     color     Kolor
     * @param {string|int} type      Grupa koloru
     * @param {bool}       refresh   Czy odświeżać ceny, rysunek, itp.
     */
    setColor(
        colorType: 'outer' | 'inner',
        place: 'first' | 'other' = 'first',
        color: IccColor | 'none',
        type: number | 'none' | undefined,
        refresh: boolean,
        filling: any,
        setColorsBasedOnColorMapping: boolean,
        sashType?: string
    ) {
        const fillingColors = this.getFillingColors(filling);
        let object = filling?.selectedColor;
        if (place === 'other') {
            object = filling.selectedColorSecond;
        }
        if (color === 'none') {
            color = fillingColors.find(p => p.type === 'white') as IccColor || {} as IccColor;
        }
        this.setColorSide(object?.frame[colorType], color, type);
        if (sashType !== 'DOP') {
            this.setColorSide(object?.sash[colorType], color, type);
        }
        if (sashType === 'DOP') {
            if (colorType === 'outer') {
                const isDefaultColor = filling.selectedColor.sash.outer.isDefault;
                this.setColorSide(object?.sash[colorType], color, type, isDefaultColor);
            }
            if (colorType === 'inner') {
                const isDefaultColor = filling.selectedColor.sash.inner.isDefault;
                this.setColorSide(object?.sash[colorType], color, type, isDefaultColor);
            }
        }

        
        if (setColorsBasedOnColorMapping) {
            this.eventBusService.post({
                key: 'setFillingColor',
                value: { colorType },
            });
        }
        this.eventBusService.post({
            key: 'icc-redraw',
            value: 'frame',
        });
    }

    setColorSide(
        object: Partial<IccColor>,
        color: Partial<IccColor> | undefined,
        type: number | 'none' | undefined | string,
        isDefault = false
    ) {
        if (color && object) {
            object.id = color.id;
            object.alfa = color.alfa;
            object.color = color.color;
            object.color_img = color.color_img;
            object.name = color.name;
            object.code = color.code;
            object.RAL = color.RAL;
            object.type = color.type;
            object.types = color.types;
            object.groups = color.groups;
            object.sides = color.sides;
            object.isDefault = isDefault;
            object.group = String(type);
        }
    }

    getSelectedSashes(sash: 'door' | 'doorActive' | 'doorActiveInner' | 'doorPassive' | 'window' , conf = this.configurationsService.conf.Current) {
        return conf.Sashes.filter(
            this.fillingsService.getFilterForField(sash, conf)
        );
    }

    openModalColor(
        type: 'outer' | 'inner',
        place: 'first' | 'other' = 'first',
        sash: 'door' | 'doorActive' | 'doorActiveInner' | 'doorPassive',
        mode: "simple" | "advanced" | "extended" | "door_extended", 
        conf = this.configurationsService.conf.Current
    ) {
        const sashes = this.getSelectedSashes(sash);
        if (sashes.length > 0 && sashes[0] && sashes[0].glazing) {
            let selectedColor = sashes[0].glazing.selectedColor;
            if (place === 'other') {
                selectedColor = sashes[0].glazing.selectedColorSecond;
            }

            const hasInnerPanel = conf.type === 'door'
                                    && conf.OwnedSashesTypes.doorActive
                                    && sashes[0].glazing.type === 'door_panels'
                                    && sashes[0].panelInner;

            if (hasInnerPanel && type === 'inner') {
                selectedColor = sashes[0].panelInner.selectedColor;
                if (place === 'other') {
                    selectedColor = sashes[0].panelInner.selectedColorSecond;
                }
            }
            this.colorsService.openModalColorSimple(
                type,
                'sash',
                conf,
                group => {
                    const sideField = (hasInnerPanel && type === 'inner') ? 'panelInner' : 'glazing';
                    let colorGroups = sashes[0][sideField].color_groups_ids;
                    if (place === 'other') {
                        colorGroups = sashes[0][sideField].part_color_groups_ids;
                    }
                    return group != null
                        && typeof colorGroups !== 'undefined'
                        && colorGroups.map(Number).indexOf(Number(group.id))
                            > -1;
                },
                true,
                false,
                (color: IccColor) => {
                    if (conf.System.type === 'pvc') {
                        sashes.forEach(sashq => {
                            this.setColor(type, place, color, 'none', false, sashq[type === 'inner' && hasInnerPanel ? 'panelInner' : 'glazing'], true);
                
                            sashq.intSashes.forEach(field => {
                                this.setColor(type, place, color, 'none', false, field[type === 'inner' && hasInnerPanel ? 'panelInner' : 'glazing'], true);
                            })
                        });
                    } else {
                        const pauseId = this.eventBusService.pause(['setFillingColor']);
                        this.setFillingColorInAllSashes(sashes, type, place, color, hasInnerPanel, conf);
                        if (
                            type === 'outer' 
                            && place !== 'other' 
                            && conf.System.door_type && (mode === 'advanced' || mode === 'door_extended') 
                            && conf.Colors.sash.inner.isDefault 
                            && conf.Colors.frame.inner.isDefault
                        ) {
                            this.setFillingColorInAllSashes(sashes, 'inner', place, color, hasInnerPanel, conf);
                        }
                        const isPassiveSashColorDefault = conf.Sashes.find(s => s.type.type === 'DOP')?.glazing.selectedColor.sash.outer.isDefault;
                        if (sash === 'doorActive' && isPassiveSashColorDefault) {
                            const passiveSashes = conf.Sashes.filter(
                                this.fillingsService.getFilterForField('doorPassive', conf)
                            );
                            if (conf.Colors.sash.inner.isDefault) {
                                this.setFillingColorInAllSashes(passiveSashes, type, place, color, hasInnerPanel, conf);
                                if (type === 'outer' && (mode === 'advanced' || mode === 'door_extended')) {
                                    this.setFillingColorInAllSashes(passiveSashes, 'inner', place, color, hasInnerPanel, conf);
                                }
                            }
                        }
                        if (sash === 'doorPassive') {
                            if (type === 'inner') {
                                const chosenPassiveSash = sashes.find(s => s.glazing.id === hasInnerPanel.id)
                                chosenPassiveSash.panelInner.selectedColor.sash.inner.isDefault = false;
                            }

                            if (type === 'outer') {
                                const chosenPassiveSash = sashes.find(s => s.glazing.id === hasInnerPanel.id)
                                chosenPassiveSash.glazing.selectedColor.sash.outer.isDefault = false;
                            }
                        }
                        this.eventBusService.resume(['setFillingColor'], pauseId);
                    }
                },
                selectedColor && selectedColor.frame[type]
            );
        }
    }

    private setFillingColorInAllSashes(
        sashes: ActiveSash[],
        type: 'outer' | 'inner',
        place: 'first' | 'other',
        color: IccColor,
        hasInnerPanel: Partial<IccFilling> & {
            selectedColor?: IccColors;
            selectedColorSecond?: IccColors;
            selectedWood?: IccWood;
        },
        conf: DoorActiveConfiguration | WindowActiveConfiguration
    ) {
        const fillingType = type === 'inner' && hasInnerPanel ? 'panelInner' : 'glazing';
        const otherType = type === 'outer' ? 'inner' : 'outer';
        sashes.forEach(sash => {
            if (
                color.type === 'white' &&
                (sash[fillingType].selectedColor.frame[otherType].type === 'white' ||
                    Common.isUndefined(sash[fillingType].selectedColor.frame[otherType].id))
            ) {
                this.setColor(type, place, 'none', 'none', false, sash[fillingType], true);
                this.setColor(otherType, place, 'none', 'none', false, sash[fillingType], true);
            } else {
                const sashType = sash.type.type;
                this.setColor(type, place, color, 'none', false, sash[fillingType], true, sashType);
                if (type === 'inner' && conf.System.door_type) {
                    this.setColor('outer', place, color, 'none', false, sash[fillingType], true, sashType);
                }
            }

            sash.intSashes.forEach(field => {
                if (
                    color.type === 'white' &&
                    (field[fillingType].selectedColor.frame[otherType].type === 'white' ||
                        Common.isUndefined(field[fillingType].selectedColor.frame[otherType].id))
                ) {
                    this.setColor(type, place, 'none', 'none', false, field[fillingType], true);
                    this.setColor(otherType, place, 'none', 'none', false, field[fillingType], true);
                } else {
                    const sashType = sash.type.type;
                    this.setColor(type, place, color, 'none', false, field[fillingType], true, sashType);
                }
            });
        });

        if (type === 'outer') {
            sashes.forEach(sash => {
                if (sash[hasInnerPanel ? 'panelInner' : 'glazing'][place === 'other' ? 'selectedColorSecond' : 'selectedColor']?.sash?.inner?.isDefault
                    && color.sides.includes(Side.FB)
                ) {
                    const sashType = sash.type.type;
                    this.setColor('inner', place, color, 'none', false, sash[hasInnerPanel ? 'panelInner' : 'glazing'], true, sashType);
                    this.setColor('outer', place, color, 'none', false, sash[hasInnerPanel ? 'panelInner' : 'glazing'], true, sashType);

                    sash.intSashes.forEach(field => {
                        this.setColor('inner', place, color, 'none', false, field[hasInnerPanel ? 'panelInner' : 'glazing'], true);
                    });
                }
            });
        }
    }

    hasInnerPanel(sashes: ActiveSash[], conf = this.configurationsService.conf.Current) {
        return conf.type === 'door' && conf.OwnedSashesTypes.doorActive && sashes[0].glazing.type === 'door_panels' && sashes[0].panelInner;
    }
}
