import { Injectable, Inject } from '@angular/core';
import { SideColors } from '@icc/common/configurations/parts/common';
import { Common } from '@icc/common/Common';
import { core } from '@icc/common/helpers';
import { ColorsDefaultsService } from '@icc/common/colors/colors-defaults.service';
import { ConfiguratorsDataService } from '@icc/common/configurators/configurators-data.service';
import { CuttingStockService } from './cutting-stock.service';
import * as camelCase from 'camelcase';

@Injectable()
export class ColorsPositionService {
    constructor(
        private colorsDefaultsService: ColorsDefaultsService,
        private configuratorsDataService: ConfiguratorsDataService,
        private cuttingStockService: CuttingStockService
    ) {}

    groupPositionsByColors(positions) {
        const groups = {};
        positions
            .filter(position => ['window', 'hs', 'door', 'folding_door', 'sliding_door'].indexOf(position.doc.confType) > -1)
            .forEach(position => {
                if (!groups[position.doc.groupCode]) {
                    if (Common.isString(position.doc.details) && position.doc.details !== '') {
                        position.doc.details = core.parseJson(position.doc.details);
                    }

                    groups[position.doc.groupCode] = {
                        system: position.doc.details.system,
                        colors: position.doc.details.colors,
                        colorsSashExt: position.doc.details.colorsSashExt,
                        wood: position.doc.details.wood,
                        alushell: {
                            has: position.doc.details.hasAlushell,
                            type: position.doc.details.alushellType,
                        },
                        positionsPrice: position.doc.client_price * position.doc.quantity,
                        positionsPriceBeforeDiscount: position.doc.client_price_before_discount * position.doc.quantity,
                        positionsDealerPriceBeforeDiscount: position.doc.dealer_price_before_discount * position.doc.quantity,
                        factor: 0,
                        price: 0,
                        dealerPrice: 0
                    };
                } else {
                    groups[position.doc.groupCode].positionsPrice +=
                        position.doc.client_price * position.doc.quantity;
                    groups[position.doc.groupCode].positionsPriceBeforeDiscount +=
                        position.doc.client_price_before_discount * position.doc.quantity;
                    groups[position.doc.groupCode].positionsDealerPriceBeforeDiscount +=
                        position.doc.dealer_price_before_discount * position.doc.quantity;
                }
            });
        const colorPriceGroups = Object.keys(groups)
            .map(groupCode => groups[groupCode])
            .map(group => {
                const factor = this.getColorFactor({
                    colorGroups: this.configuratorsDataService.data.windowColorGroups,
                    colors: group.colors,
                    system: group.system,
                    wood: group.wood,
                    price: group.positionsPrice,
                });
                group.factor = factor;
                group.price = group.positionsPriceBeforeDiscount * factor / 100;
                group.dealerPrice = group.positionsDealerPriceBeforeDiscount * factor / 100;
                return group;
            })
            .filter(group => group.price > 0);
        return colorPriceGroups;
    }

    groupPositionsByProfilesInColors(positions) {
        const groups = {};
        positions
            .filter(position => ['window', 'hs', 'door', 'folding_door', 'sliding_door'].indexOf(position.doc.confType) > -1)
            .forEach(position => {
                if (Common.isString(position.doc.details) && position.doc.details !== '') {
                    position.doc.details = core.parseJson(position.doc.details);
                }
                if (position.doc.details.usedProfilesSegments) {
                    for (const profileId in position.doc.details.usedProfilesSegments) {
                        if (position.doc.details.usedProfilesSegments.hasOwnProperty(profileId)) {
                            const segments = position.doc.details.usedProfilesSegments[profileId];
                            segments.forEach(segment => {
                                const groupCode = this.getProfileInColorCode(
                                    profileId,
                                    segment.color
                                );
                                if (!groups[groupCode]) {
                                    const profilePriceData = this.getProfilePrice(
                                        profileId,
                                        this.configuratorsDataService.data.profilesPrices,
                                        segment.color
                                    );
                                    if (profilePriceData && profilePriceData.length) {
                                        const profilePrice = Math.min(
                                            ...profilePriceData.map(p => p.price_length)
                                        );
                                        const profileSettlementLength = Math.min(
                                            ...profilePriceData.map(p => p.settlement_length || 0)
                                        );
                                        if (profileSettlementLength && profilePrice) {
                                            groups[groupCode] = {
                                                profile: position.doc.details.dictionary?.profiles[profileId],
                                                colors: segment.color,
                                                usedLength: segment.length,
                                                price: 0,
                                                waste: 0,
                                                sticksCount: 0,
                                                profilePrice,
                                                segments: Array(position.doc.quantity).fill(
                                                    segment.length
                                                ),
                                                settlementLength: profileSettlementLength,
                                            };
                                        }
                                    }
                                } else {
                                    groups[groupCode].usedLength +=
                                        segment.length * position.doc.quantity;
                                    groups[groupCode].segments.push(
                                        ...Array(position.doc.quantity).fill(segment.length)
                                    );
                                }
                            });
                        }
                    }
                }
            });
        const colorPriceGroups = Object.keys(groups)
            .map(groupCode => groups[groupCode])
            .map(group => {
                const { waste, stickCount } = this.cuttingStockService.fit(
                    group.segments,
                    group.settlementLength
                );
                group.waste = waste;
                group.sticksCount = stickCount;
                group.price = (group.profilePrice * waste) / 1000;
                return group;
            });

        return colorPriceGroups;
    }

    getProfileInColorCode(profileId, colors) {
        let code = profileId.toString();
        ['outer', 'core', 'inner'].forEach(side => {
            code += `_${colors[side] ? colors[side].id : 0}_${
                colors[side] && colors[side].RAL ? 1 : 0
            }`;
        });
        return code;
    }

    /**
     * Zwraca najwyższą dopłatę procentową dla koloru.
     * @param  {object} colorGroups Grupy kolorów
     * @param  {object} color       Kolor
     * @param  {string} prop        Nazwa pola zawierającego dopłatę.
     * @return {number}             Dopłata procentowa dla koloru
     */
    getMaxColorGroupFactor({
        colorGroups,
        color,
        prop,
        system,
        wood,
        price,
    }: {
        colorGroups;
        color;
        prop;
        system;
        wood;
        price;
    }) {
        let colorFactorExtra = null;
        if (!Common.isNumber(price)) {
            return NaN;
        }
        if (!colorGroups) {
            colorGroups = [];
        }
        colorGroups = colorGroups.filter(
            el =>
                Common.isArray(el.systems)
                && el.systems.map(Number).indexOf(Number(system.id)) > -1
                && el.target.indexOf('price') > -1
                && (system.type !== 'wood'
                    || (Common.isArray(el.woodTypes)
                        && el.woodTypes.map(Number).indexOf(Number(wood.id)) > -1))
        );
        if (color && Common.isArray(color.groups)) {
            for (let i = 0; i < color.groups.length; i++) {
                let group = core.fIdO<any>(colorGroups, color.groups[i]);
                if (color.groups[i] === 'RAL') {
                    group = this.getRALGroup(colorGroups);
                }
                if (Common.isDefined(group)) {
                    const customColorFactor = null;
                    if (Common.isDefined(group.offer_price_ranges)) {
                        const offerPriceRanges =
                            (group.offer_price_ranges && core.parseJson(group.offer_price_ranges))
                            || [];
                        const colorSashFactor = offerPriceRanges
                            .filter(
                                p =>
                                    Number(p.from) <= parseFloat(price)
                                    && Number(p.to) >= parseFloat(price)
                            )
                            .reduce((prev, cur, index) => {
                                return prev < cur[camelCase(prop)] ? cur[camelCase(prop)] : prev;
                            }, 0);
                        if (colorSashFactor > colorFactorExtra) {
                            colorFactorExtra = colorSashFactor;
                        }
                    }
                }
            }
        }
        if (colorFactorExtra == null) {
            return NaN;
        }
        return colorFactorExtra;
    }

    /**
     * Zwraca dopłatę procentową za RAL.
     * @param  {array}  colorGroups   Grupy kolorów
     * @return {number}               Cena po dopłatach
     */
    getRALGroup(colorGroups) {
        const colorGroupsArray: any[] = core.objToArray(colorGroups);
        const RALGroup = core.fIdO(colorGroupsArray, 'ral', 'code');
        return RALGroup;
    }

    getColorFactor({
        colorGroups,
        colors,
        system,
        wood,
        price,
    }: {
        colorGroups;
        colors;
        system;
        wood;
        price;
    }) {
        let colorFactor = 0;
        let prop = 'price_factor_duo';

        if (colors.outer?.id === colors.inner?.id) {
            prop = 'price_factor_both';
        } else if (!colors.outer?.id || colors.outer?.sides.includes('F|C')) {
            prop = 'price_factor_in';
        }  else if (!colors.inner?.id || colors.inner?.sides.includes('F|C')) {
            prop = 'price_factor_out';
        }

        let side = 'inner';
        if (!colors.outer?.id && !colors.inner?.id) {
            side = 'core';
        } else if (!colors.inner?.id) {
            side = 'outer';
        }
       
        colorFactor = this.getMaxColorGroupFactor({
            colorGroups,
            color: colors.frame[side],
            prop,
            system,
            wood,
            price,
        });
        if (colors.outer?.id !== colors.inner?.id) {
            const colorFactorOut = this.getMaxColorGroupFactor({
                colorGroups,
                color: colors.frame.outer,
                prop: 'price_factor_duo',
                system,
                wood,
                price,
            });
            colorFactor =
                colorFactorOut > colorFactor || isNaN(colorFactorOut)
                    ? colorFactorOut
                    : colorFactor;
        }
        return colorFactor;
    }

    getProfilePrice(profileId, prices, colors) {
        if (
            !prices
            || !prices[profileId]
            || !prices[profileId].default
            || !prices[profileId].default
            || !colors
        ) {
            return null;
        }
        const profilePrices = prices[profileId].default;
        let colorGroups = [];
        let colorGroupsOut = [];
        let side = 'double';

        if (!colors.inner?.groups && !colors.outer?.groups) {
            colorGroups = colors.core.groups.map(Number);
        } else if (colors.inner?.groups && !colors.outer?.groups) {
            colorGroups = colors.inner.groups.map(Number);
            side = 'single';
        } else if (colors.outer?.groups && !colors.inner?.groups) {
            colorGroups = colors.outer.groups.map(Number);
            side = 'single';
        } else if (colors.inner?.groups && colors.outer?.groups && colors.outer.id === colors.outer.id) {
            colorGroups = colors.inner.groups.map(Number);
        } else if (colors.inner?.groups && colors.outer?.groups && colors.outer.id !== colors.outer.id) {
            colorGroups = colors.inner.groups.map(Number);
            colorGroupsOut = colors.outer.groups.map(Number);
            side = 'bicolor';
        } else {
            return null;
        }

        const profilePrice = Object.keys(profilePrices)
            .map(type => profilePrices[type])
            .map(pricesType =>
                pricesType
                    .map((p, index) => {
                        p.id = index;
                        return p;
                    })
                    .find(
                        p =>
                            colorGroups.indexOf(Number(p.colorGroup)) > -1
                            && (side !== 'bicolor'
                                || (side === 'bicolor'
                                    && colorGroupsOut.indexOf(Number(p.colorGroupOut)) > -1))
                            && p.side === side
                    )
            )
            .filter(el => el);
        if (profilePrice) {
            return profilePrice;
        }
        return null;
    }
}
