import { Injectable } from '@angular/core';
import { IccDrawMathService } from '@icc/draw';
import { core } from '.';
import { ConfiguratorsDataService } from '@icc/common/configurators/configurators-data.service';
@Injectable({
    providedIn: 'root',
})
export class SizeRangeService {
    doorSizes: any;

    constructor(
        private configuratorsDataService: ConfiguratorsDataService,
    ){

    }

    getSingleRange(sizeRangeId: number, size: number, isWidth = true) {
        const poly = this.getSizeRangeById(sizeRangeId)?.sizes;
        const range = IccDrawMathService.getPolyRangeInPerpendicularLine(
            poly,
            size,
            isWidth ? 'x' : 'y'
        );
        if (!Array.isArray(range)) {
            return;
        }

        if (typeof range[0][0] === 'undefined' && range[0][1]) {
            range[0][0] = range[0][1];
        } else if (typeof range[0][1] === 'undefined' && range[0][0]) {
            range[0][1] = range[0][0];
        }

        return range;
    }


    getNearestRangeToSize(ranges, size: number){
        if (!ranges?.length) {
            return [];
        }

        return ranges?.find(
            (item) =>
                item.indexOf(
                    ranges
                        .flat()
                        .reduce((prev, curr) =>
                            Math.abs(curr - size) < Math.abs(prev - size) ? curr : prev
                        )
                ) > -1
        );
    }

    // eslint-disable-next-line max-statements
    getSizeRange(sizeRangeId, width, height, verbose = false, steps?: { height, width }) {
        const widthRanges = this.getSingleRange(sizeRangeId, height, true) || [];
        const heightRanges = this.getSingleRange(sizeRangeId, width, false) || [];
        const poly = this.getSizeRangeById(sizeRangeId)?.sizes?.map((el) => ({
            x: el?.width,
            y: el?.height
        }));

        const isWidthInRange = widthRanges.some((item) =>
            IccDrawMathService.isValueInRange(item, width)
        );

        const isHeightInRange = heightRanges.some((item) =>
            IccDrawMathService.isValueInRange(item, height)
        );

        let nearestRangeWidth = this?.getNearestRangeToSize(widthRanges, width);
        let nearestRangeHeight = this?.getNearestRangeToSize(heightRanges, height);
        
        if (widthRanges?.length > 0 && !heightRanges?.length) {
            if (verbose) {
                const nearestWidthPoint = IccDrawMathService.getNearestPointInPoly(poly, {x: width, y: height});
                const heightRange = this.getSingleRange(sizeRangeId, nearestWidthPoint?.x,true) || [];
                nearestRangeHeight = this?.getNearestRangeToSize(heightRange,height);
            }
             return {
                ...verbose && {
                    height: {
                        minY: nearestRangeHeight[0],
                        maxY: nearestRangeHeight[1],
                    },
                },
                width: {
                    minX: nearestRangeWidth[0],
                    maxX: nearestRangeWidth[1],
                },
                isValid: undefined
            }
        } else if (heightRanges?.length > 0 && !widthRanges?.length) {
            if (verbose) {
                const nearestHeightPoint = IccDrawMathService.getNearestPointInPoly(poly, {x: width, y: height});
                const widthRange = this.getSingleRange(sizeRangeId, nearestHeightPoint?.y,true) || [];
                nearestRangeWidth = this?.getNearestRangeToSize(widthRange,width);
            }
            return {
                ...verbose && {
                    width: {
                        minX: nearestRangeWidth[0],
                        maxX: nearestRangeWidth[1],
                    },
                },
                height: {
                    minY: nearestRangeHeight[0],
                    maxY: nearestRangeHeight[1],
                },
                isValid: undefined
            }

        } else if (!heightRanges?.length && !widthRanges?.length || (!isWidthInRange && !isHeightInRange)) {
            return this.getRangesForTwoDimensions(sizeRangeId,width,height,poly);
        } else if (isWidthInRange && isHeightInRange) {
            
            if (steps?.height || steps?.width) {
                const heightInRange = core.isValueInRangeWithIncludedStepValue(widthRanges[0][0], widthRanges[0][1], steps?.width, width);
                const widthInRange = core.isValueInRangeWithIncludedStepValue(heightRanges[0][0], heightRanges[0][1], steps?.height, height);
                
                if (!heightInRange || !widthInRange) {
                    return this.getMinAndMaxExtremumValue(false, nearestRangeWidth, nearestRangeHeight);
                } else {
                    return this.getMinAndMaxExtremumValue(true, nearestRangeWidth, nearestRangeHeight);
                }

            } else {
                return this.getMinAndMaxExtremumValue(true, nearestRangeWidth, nearestRangeHeight);
            }
        } else if (Array.isArray(nearestRangeWidth) && Array.isArray(nearestRangeHeight)) {
            return this.getMinAndMaxExtremumValue(true, nearestRangeWidth, nearestRangeHeight);
        }

        return null;
    }

    getMinAndMaxExtremumValue(isValid = false, nearestRangeWidth, nearestRangeHeight) {
        return {
            isValid,
            width: {
                minX: nearestRangeWidth[0],
                maxX: nearestRangeWidth[1],
            },
            height: {
                minY: nearestRangeHeight[0],
                maxY: nearestRangeHeight[1],
            }
        }
    }

    getRangesForTwoDimensions(sizeRangeId, width, height, poly){
        const nearestPoint = IccDrawMathService.getNearestPointInPoly(poly, {x: width, y: height});
        const widthRanges = this.getSingleRange(sizeRangeId,nearestPoint?.y,true) || [];
        const heightRanges = this.getSingleRange(sizeRangeId,nearestPoint?.x,false) || [];
        const nearestRangeWidth = this?.getNearestRangeToSize(widthRanges,width);
        const nearestRangeHeight = this?.getNearestRangeToSize(heightRanges,height);
        return {
            isValid: undefined,
            width: {
                minX: nearestRangeWidth[0],
                maxX: nearestRangeWidth[1],
            },
            height: {
                minY: nearestRangeHeight[0],
                maxY: nearestRangeHeight[1],
            }
        }
    }


    getNearestRanges(width, height, sizeId, sizePoly){
        if (width && height && sizePoly && sizeId){
            const tempHeight = Math.abs(height - sizePoly?.maxY) < Math.abs(height - sizePoly?.minY) ? sizePoly.maxY : sizePoly.minY;
            const widthRange = this.getNearestRangeToSize(this.getSingleRange(sizeId,height),width);
            const tempWidth = Math.abs(width - widthRange[1]) < Math.abs(width - widthRange[0]) ? widthRange[1] : widthRange[0];
            const heightRange = this.getNearestRangeToSize(this.getSingleRange(sizeId,tempWidth, false),height);
            return {
                widthRange,
                heightRange
            }
        }
    }

    getSizeStep(sashSizes, isWidth = true){
        return (isWidth ? sashSizes?.size_interval_custom_width : sashSizes?.size_interval_height) || 1
    }

    createPolyFromSizeRange(sizeRange){
        const sizesXY = (sizeRange?.sizes || []).map(size => ({
            x: Number(size.width),
            y: Number(size.height),
        }));
        return core.getRangeFromPoly(sizesXY);
    }

    isPointInSizeRangeId(sizeId, x ,y) {
        if (sizeId){
            const size = this.getSizeRangeById(sizeId);
            return core?.pointInPolygon(size?.sizes,x,y);
        } else {
            return true;
        }
    }

    getSizeRangeById(sizeRangeId) {
        return this.configuratorsDataService.data?.sizeRanges.find(
            (el) => el?.id === Number(sizeRangeId)
        );
    }
}
