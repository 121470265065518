import { svgImageFilter } from './svg-draw-parser';
const $ = require('imports-loader?this=>window!jquery');

export default function SvgImageFilter($sce) {
    'ngInject';

    return function(data, IccConfig, conf, isPdf=false) {
        data = svgImageFilter(window.atob(data), IccConfig, $, window.location.href, false, false, isPdf, conf);

        return $sce.trustAsHtml(data);
    };
}
