import { Component, OnInit, Inject } from '@angular/core';
import { PageComponent, ICC_PAGE_DATA } from '@icc/helpers';
import { _, SharedFacade } from '@icc/configurator/shared';
import { iccListItem } from '@icc/configurator/ui';
import { IccWood } from '@icc/common/data-types';
import { PriceService } from '@icc/price';

@Component({
  selector: 'icc-woods-page',
  templateUrl: './woods-page.component.html',
  styleUrls: ['./woods-page.component.scss']
})
export class WoodsPageComponent extends PageComponent implements OnInit {
  public title = _('CONFIGURATOR|Wybierz rodzaj drewna');
  public selected: any = null;

  woods: iccListItem[] = [];

  constructor(
    private sharedFacade: SharedFacade,
    private priceService: PriceService,
    @Inject(ICC_PAGE_DATA) private pageData: {
        woods: any[];
        selWood: IccWood;
    }
  ) {
    super();
   }

  ngOnInit() {
    this.woods = this.pageData.woods.map((wood: any) => ({
        id: Number(wood.id),
        title: wood.name,
        imageUrl: '/files/woodtype/' + wood.img,
    }));
    this.selected = Number(this.pageData.selWood.id);

}
    selectWood(item: iccListItem){
        const wood = this.pageData.woods.find(w => Number(w.id) === item.id);
        this.selected = wood.id;
        this.sharedFacade.closePage(wood)
    }

}
