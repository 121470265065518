import { Component, OnInit, Inject } from '@angular/core';
import {
    PageComponent,
    _,
    SharedFacade,
    ICC_PAGE_DATA,
    ModalService,
} from '@icc/configurator/shared';
import { iccListItem } from '@icc/configurator/ui';
import { IccAccessoryAccessory, IccColor } from '@icc/common/data-types';
import { AccessoriesOptionsPageComponent } from '../accessories-options-page/accessories-options-page.component';
import { ActiveSash } from '@icc/common/layout/active-sash';
import {
    AppConfigFactory,
    APP_CONFIG,
    ConfigurationsService,
    ConfiguratorsDataService,
    TranslateService,
} from '@icc/common';
import { Profile } from '@icc/window';
import { AccessoriesListPageStoreService } from './accessories-list-page-store.service';
import { Categories, Category } from './categories.interface';
@Component({
    selector: 'icc-accessories-list-page',
    templateUrl: './accessories-list-page.component.html',
    styleUrls: ['./accessories-list-page.component.scss'],
})
export class AccessoriesListPageComponent extends PageComponent implements OnInit {
    public title = this.translateService.instant('ACCESSORY|Wybierz akcesoria dla ');
    public options = [];

    accessories: iccListItem[] = [];
    constructor(
        private sharedFacade: SharedFacade,
        private modalService: ModalService,
        private translateService: TranslateService,
        private configurationsService: ConfigurationsService<'door'>,
        private configuratorsDataService: ConfiguratorsDataService,
        @Inject(APP_CONFIG) public config: AppConfigFactory,
        private accessoriesListPageStoreService: AccessoriesListPageStoreService,
        @Inject(ICC_PAGE_DATA)
        private pageData: {
            accessories: IccAccessoryAccessory[];
            categories: any[];
            subcategories: any[];
            forIt: 'configuration' | 'sash' | 'side' | 'drive';
            sashNum?: number;
            sash?: ActiveSash | string;
            colors?: IccColor[];
            noPrice: boolean;
        }
    ) {
        super();
        let subtitle = '';
        switch (pageData.forIt) {
            case 'configuration':
                subtitle = this.translateService.instant('ACCESSORY|całej konstrukcji');
                break;
            case 'sash':
                subtitle =
                    this.translateService.instant('ACCESSORY|kwatery ID:') + ' ' + pageData.sashNum;
                break;
            case 'side':
                switch (pageData.sash) {
                    case 'left':
                        subtitle = this.translateService.instant('ACCESSORY|lewej krawędzi');
                        break;
                    case 'right':
                        subtitle = this.translateService.instant('ACCESSORY|prawej krawędzi');
                        break;
                    case 'top':
                        subtitle = this.translateService.instant('ACCESSORY|górnej krawędzi');
                        break;
                    case 'bottom':
                        subtitle = this.translateService.instant('ACCESSORY|dolnej krawędzi');
                        break;
                }
                break;
            case 'drive':
                subtitle = this.translateService.instant('ACCESSORY|sterowania rolety');
                break;
        }

        this.title =
            this.translateService.instant('ACCESSORY|Wybierz akcesoria dla') + ' ' + subtitle;
    }

    ngOnInit() {
        if (
            this.config().filterAccessoriesByCategories &&
            this.pageData.forIt === 'configuration'
        ) {
            this.getAccessoriesBasedOnCategories();
        } else {
            this.getAccesories();
        }

        const selectedAccessories = this.configurationsService.conf.Current.Accessories;
        const selectedSideAccessories = this.configurationsService.conf.Current.SideAccessories;
        const selectedMidAccessories = this.configurationsService.conf.Current.Sashes;

        if (selectedAccessories.length) {
            this.disableAccessories(selectedAccessories);
        }

        Object.values(selectedSideAccessories).forEach((side) => {
            if (Array.isArray(side)) {
                this.disableAccessories(side);
            }
        })

        if (selectedMidAccessories.length) {
            selectedMidAccessories.forEach((acc) => {
                this.disableAccessories(acc.hardware);
            }) 
        }

        const dependencies = this.configuratorsDataService.data.dependencies || [];
        const blockedAccessoriesToConfiguration = this.configurationsService.conf.Current.Dependencies?.filter(
            (p) => p.type === 'blockade_to_configuration'
        );
        if (blockedAccessoriesToConfiguration && dependencies) {
            this.markAccessoryAsDisabledBasedOnDependencyBlockingToConfiguration(
                blockedAccessoriesToConfiguration,
                dependencies
            );
        }
    }

    private getAccessoriesBasedOnCategories() {
        this.accessoriesListPageStoreService.selectedCategoryObs$.subscribe((categories) => {
            if (!(categories?.category?.id || categories?.subcategory?.id)) {
                return;
            }
            this.setAccessoriesBasedOnCategories(categories);
        });
    }

    private getAccesories() {
        this.accessories = this.pageData.accessories.map<iccListItem>((accessory) => ({
            id: Number(accessory.id),
            categoryId: Number(accessory.window_accessories_category_id),
            title: accessory.name,
            imageUrl: `/files/windowaccessory/${accessory.img}`,
            description: `${accessory.description}`,
        }));
    }

    private setAccessoriesBasedOnCategories(categories) {
        const subCategories = this.getSubCategories(categories);
        const mainCategories = this.getMainCategories(categories);

        if (subCategories?.length && categories.subcategory.id !== Category.allSubCategories) {
            this.accessories = [...subCategories];
        } else if(categories.subcategory.id === Category.allSubCategories && categories.category.id !== Category.allCategories) {
            this.accessories = [...mainCategories];
        } else {
            this.accessories = [...mainCategories, ...subCategories];
        }
    }

    private getSubCategories(categories: Categories) {
        if (!categories) {
            return;
        }

        if (categories.subcategory.id === Category.allSubCategories) {
            const subcategoriesFromParentCategory = categories.category.id === Category.allCategories 
                ? this.configuratorsDataService.data.windowAccessoriesCategories.filter(category => category.parent_id !== null).map(k => k.id)
                : this.getSubcategoriesFromParentCategoryBasedOnId(Number(categories.category.id));
            const accessories = this.pageData.accessories
                .filter(p => subcategoriesFromParentCategory.find(catId => Number(catId) === Number(p.window_accessories_category_id)));
            return this.getProjectedIccListItem(accessories);
        } else {
            const accessories = this.pageData.accessories.filter(
                (p) =>
                    Number(p.window_accessories_category_id) === Number(categories.subcategory.id)
            );
            return this.getProjectedIccListItem(accessories);
        }
    }

    private getMainCategories(categories: Categories) {
        if (!categories) {
            return;
        }

        if (categories.category.id === Category.allCategories) {
            const accessories = this.pageData.accessories.filter((p) =>
                this.pageData.categories.some(
                    (sub) => Number(sub.id) === Number(p.window_accessories_category_id)
                )
            );
            return this.getProjectedIccListItem(accessories);
        } else {
            const parentCategories = this.configuratorsDataService.data.windowAccessoriesCategories
                .filter((category) => Number(category.parent_id) === Number(categories.category.id))
                .map((k) => k.id);

            const categoryIds = parentCategories.length
                ? this.getSubcategoriesFromParentCategoryBasedOnId(Number(categories.category.id))
                : [];

            const accessories = this.pageData.accessories.filter(
                (p) =>
                    Number(p.window_accessories_category_id) === Number(categories.category.id) ||
                    (categoryIds.length &&
                        categoryIds.find(
                            (cId) => Number(cId) === Number(p.window_accessories_category_id)
                        ))
            );
            return this.getProjectedIccListItem(accessories);
        }
    }

    private getProjectedIccListItem(listItem: any[]): any[] {
        if (!listItem.length) return [];

        return listItem.map<iccListItem>((accessory) => ({
            id: Number(accessory.id),
            categoryId: Number(accessory.window_accessories_category_id),
            title: accessory.name,
            imageUrl: `/files/windowaccessory/${accessory.img}`,
            description: `${accessory.description}`,
        }));
    }

    getSubcategoriesFromParentCategoryBasedOnId(id: number) {
        const parentCategories = this.configuratorsDataService.data.windowAccessoriesCategories.filter(
            (c: any) => c.parent_id
        );

        if (!parentCategories.length) {
            return [];
        }

        return this.accessoriesListPageStoreService.getCategories(id, parentCategories);
    }

    selectAccessory(item: iccListItem) {
        const accessory = this.pageData.accessories.find((a) => Number(a.id) === Number(item.id));
        if (accessory) {
            this.modalService
                .open({
                    pageComponent: AccessoriesOptionsPageComponent,
                    resolve: {
                        accessory,
                        forIt: this.pageData.forIt,
                        sash: this.pageData.sash,
                        colors: this.pageData.colors,
                    },
                })
                .result.then((result) => {
                    if (result) {
                        this.sharedFacade.closePage(result);
                    }
                });
        }
    }

    markAccessoryAsDisabledBasedOnDependencyBlockingToConfiguration(
        blockedAccessoriesToConfiguration,
        dependencies
    ) {
        dependencies.forEach((dependency) => {
            blockedAccessoriesToConfiguration.forEach((blocked) => {
                if (dependency && blocked && dependency.id === blocked.id) {
                    dependency.conditions.forEach((condition) => {
                        if (condition.type === 'accessory') {
                            const accessory = this.accessories.find(
                                (p) => p.id === Number(condition.value)
                            );
                            if (accessory && condition.value) {
                                this.disableAccessoryAndAddDescription(condition);
                            }
                        }
                    });
                }
            });
        });
    }

    disableAccessoryAndAddDescription(condition) {
        this.accessories.find((p) => p.id === Number(condition.value)).disabled = true;
        this.accessories.find(
            (p) => p.id === Number(condition.value)
        ).description += this.translateService.instant(
            'ACCESSORY|Nie można dodać dodatku do konstrukcji'
        );
    }

    disableAccessories(selectedAccessories) {
        const accCategories =
        this.configuratorsDataService.data.windowAccessoriesCategories || []; 
        const selectedCategoriesOnlyOne = []; 
        const selectedCategoriesOnlySingle = [];

        selectedAccessories.forEach((acc) => {
            const category = accCategories.find((cat) => cat.id === acc.accessory.window_accessories_category_id);
            if (category.only_one_accessory) {
                selectedCategoriesOnlyOne.push(category); 
            }
            if (category.only_single_accessory) {
                selectedCategoriesOnlySingle.push(category);
            }
        });

        this.accessories.forEach(acc => {
            if (selectedCategoriesOnlyOne.some(cat => Number(cat.id) === acc.categoryId)) {
                acc.disabled = true;
            } 
            if (
                selectedCategoriesOnlySingle.some(cat => Number(cat.id) === acc.categoryId)
                && selectedAccessories.some(selAcc => Number(selAcc.accessory.id) === acc.id)
                ) {
                    acc.disabled = true;
                }
        })
    }
}