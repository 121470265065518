<mat-checkbox
    *ngIf="configuration.window.canDisableMuntins"
    class="disableMuntinsCheckbox"
    [(ngModel)]="muntinsDisabled"
    (change)="disableAndRemoveMuntins($event.checked)"
    >{{ 'WINDOW|Bez szprosów' | translate }}</mat-checkbox
>
<div class="muntinsStepComponent">
    <button
        [disabled]="muntinsDisabled"
        mat-raised-button
        color="primary"
        (click)="openModalMuntinsLayout()"
        matRipple
    >
        {{ 'WINDOW|Wybierz układ szprosów' | translate | uppercase }}
    </button>

    <button
        [disabled]="muntinsDisabled"
        mat-raised-button
        color="primary"
        (click)="openModalMuntinsManualLayout()"
        matRipple
    >
        {{ 'CONFIGURATOR|Szprosy niestandardowe' | translate | uppercase }}
    </button>

    <mat-form-field class="muntinsSelect" *ngIf="!configuration.window.muntinTypesModal">
        <mat-label>{{ 'WINDOW|Typ szprosów' | translate }}</mat-label>
        <mat-select [disabled]="muntinsDisabled" (selectionChange)="changeType($event)" [value]="(muntinsType$ | async)?.id">
            <mat-option *ngFor="let type of types" trackBy="type.id" [value]="type.id">
                {{ type.name }}
            </mat-option>
        </mat-select>
    </mat-form-field>

    <icc-select-box
        *ngIf="configuration.window.muntinTypesModal"
        [disabled]="muntinsDisabled"
        [title]="'WINDOW|Typ szprosów:' | translate"
        [description]="(muntinsType$ | async)?.name"
        [imageUrl]="'/files/windowbartype/' + ((muntinsType$ | async)?.img || '')"
        [buttonText]="'INTERFACE|Zmień' | translate"
        (select)="openModalTypes()"
    ></icc-select-box>

    <mat-form-field class="muntinsSelect">
        <mat-label>{{ 'WINDOW|Szerokość szprosa' | translate }}</mat-label>
        <mat-select [disabled]="muntinsDisabled" (selectionChange)="changeWidth($event)" [value]="muntinsSizeId$ | async">
            <mat-option *ngFor="let size of (muntinsType$ | async)?.sizes || []" trackBy="size.id" [value]="size.id">
                {{ (size.size | unitmm:1:0:'':null:' ') }}
            </mat-option>
        </mat-select>
    </mat-form-field>

    <icc-select-box
        [disabled]="muntinsDisabled"
        [title]="
            (showSecondColor$ | async)
                ? ('COLOR|Kolor wewnętrzny' | translate)
                : ('COLOR|Kolor szprosa' | translate)
        "
        [description]="(muntinsColorInner$ | async)!.name"
        [imageUrl]="'/files/color/' + (muntinsColorInner$ | async)!.color_img"
        [color]="'#' + (muntinsColorInner$ | async)!.color"
        [colorSelect]="true"
        [buttonText]="'INTERFACE|Zmień' | translate"
        (select)="openModalColor('in')"
    ></icc-select-box>

    <icc-select-box
        [disabled]="muntinsDisabled"
        [title]="'COLOR|Kolor zewnętrzny' | translate"
        [description]="(muntinsColorOuter$ | async)!.name"
        [imageUrl]="'/files/color/' + (muntinsColorOuter$ | async)!.color_img"
        [color]="'#' + (muntinsColorOuter$ | async)!.color"
        [colorSelect]="true"
        [buttonText]="'INTERFACE|Zmień' | translate"
        (select)="openModalColor('out')"
        *ngIf="(showSecondColor$ | async)"
    ></icc-select-box>

    <mat-slide-toggle
        [disabled]="muntinsDisabled"
        class="duplex-slider"
        (change)="changeDuplex($event)"
        *ngIf="isDuplexAvailable()"
        [checked]="duplex$ | async"
    >
        {{ 'WINDOW|Dupleks' | translate }}
    </mat-slide-toggle>
</div>
