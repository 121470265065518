<div class="modal-body">
    
    <div *ngFor="let frame of frames">
        <h3>{{ 'WINDOW|Konfiguracja' | translate }} {{frame.index}}</h3>
        <div>
            <span class="frame-parameter">U<sub>w</sub> : {{ (frame.uw || 0) | number:'1.3-3' }}</span>
            <span class="frame-parameter">S<sub>w</sub> : {{ (frame.sw || 0) | number:'1.3-3' }}</span>
        </div>
        <div >

                <table class="table table-condensed table-hover">
                    <tr class="active">
                        <td></td>
                        <td>{{ 'WINDOW|Linia' | translate }}</td>
                        <td colspan="2">h<sub>e</sub></td>

                    </tr>

                    <tr>
                        <td>system</td>
                        <td>#{{conf.System.id}} - {{conf.System.name}}</td>
                        <td>{{ (conf.System.he || 0) | number:'1.3-3' }}</td>
                    </tr>

                    <tr class="active">
                        <td></td>
                        <td>{{ 'WINDOW|Szprosy' | translate }}</td>
                        <td>{{ 'WINDOW|Duplex' | translate }}</td>
                        <td>{{ 'OFFER|Ilość' | translate }}</td>
                    </tr>

                    <tr>
                        <td>muntins</td>
                        <td>#{{conf.MuntinsData.type.id}} - {{conf.MuntinsData.type.name}}</td>
                        <td>{{conf.MuntinsData.duplex}}</td>
                        <td>{{muntins}}</td>
                    </tr>

                    <tr class="active">
                        <td></td>
                        <td>{{ 'COLOR|Kolor' | translate }}</td>
                        <td colspan="2">α</td>

                    </tr>

                    <tr>
                        <td>sash.outer</td>
                        <td *ngIf="conf.Colors.sash.outer.id">#{{conf.Colors.sash.outer.id}} - {{conf.Colors.sash.outer.name}}</td>
                        <td *ngIf="conf.Colors.sash.outer.id">{{ (conf.Colors.sash.outer.alfa || 0) | number:'1.3-3' }}</td>
                        <td *ngIf="!conf.Colors.sash.outer.id">-</td>
                        <td *ngIf="!conf.Colors.sash.outer.id">-</td>
                    </tr>

                    <tr>
                        <td>sash.core</td>
                        <td *ngIf="conf.Colors.sash.core.id">#{{conf.Colors.sash.core.id}} - {{conf.Colors.sash.core.name}}</td>
                        <td *ngIf="conf.Colors.sash.core.id">{{ (conf.Colors.sash.core.alfa || 0) | number:'1.3-3' }}</td>
                        <td *ngIf="!conf.Colors.sash.core.id">-</td>
                        <td *ngIf="!conf.Colors.sash.core.id">-</td>
                    </tr>

                    <tr>
                        <td>frame.outer</td>
                        <td *ngIf="conf.Colors.frame.outer.id">#{{conf.Colors.frame.outer.id}} - {{conf.Colors.frame.outer.name}}</td>
                        <td *ngIf="conf.Colors.frame.outer.id">{{ (conf.Colors.frame.outer.alfa || 0) | number:'1.3-3' }}</td>
                        <td *ngIf="!conf.Colors.frame.outer.id">-</td>
                        <td *ngIf="!conf.Colors.frame.outer.id">-</td>
                    </tr>

                    <tr>
                        <td>frame.core</td>
                        <td *ngIf="conf.Colors.frame.core.id">#{{conf.Colors.frame.core.id}} - {{conf.Colors.frame.core.name}}</td>
                        <td *ngIf="conf.Colors.frame.core.id">{{ (conf.Colors.frame.core.alfa || 0) | number:'1.3-3' }}</td>
                        <td *ngIf="!conf.Colors.frame.core.id">-</td>
                        <td *ngIf="!conf.Colors.frame.core.id">-</td>
                    </tr>
                </table>


            <div class="clearfix"></div>
        </div>

        <div *ngFor="let sash of frame.sashes">
            <h4>
                {{ 'WINDOW|Kwatera' | translate }} {{sash.index}}
                <small *ngIf="sash.intIndex">{{ 'WINDOW|pole' | translate }} {{sash.intIndex}}</small>
            </h4>

            <table class="table table-condensed table-hover">
                <tr class="active">
                    <td></td>

                    <td>{{ 'WINDOW|Szyba' | translate }}</td>
                    <td>{{ 'WINDOW|Ciepła ramka' | translate }}</td>

                    <td>Ψ</td>
                    <td>L</td>

                    <td>A<sub>g</sub></td>
                    <td>S<sub>g</sub></td>
                    <td>U<sub>g</sub></td>
                </tr>

                <tr>
                    <td>inner</td>

                    <td>#{{sash.sash.glazing.id}} - {{sash.sash.glazing.name}}</td>
                    <td>#{{conf.WarmEdge.id}} - {{conf.WarmEdge.name}}</td>

                    <td>{{ sash.data.values.Psi | number:'1.3-3' }}</td>
                    <td>{{ sash.data.values.L | number:'1.3-3' }}</td>

                    <td>{{ sash.data.values.Ag | number:'1.3-3' }}</td>
                    <td>{{ sash.data.values.Sg | number:'1.3-3' }}</td>
                    <td>{{ sash.data.values.Ug | number:'1.3-3' }}</td>
                </tr>

                <tr>
                    <td colspan="8"></td>
                </tr>

                <tr class="active">
                    <td></td>

                    <td>{{ 'WINDOW|Rama' | translate }}</td>
                    <td>{{ 'WINDOW|Skrzydło' | translate }}</td>
                    <td colspan="2">{{ 'WINDOW|Słupek' | translate }}</td>

                    <td>A<sub>f</sub></td>
                    <td>S<sub>f</sub></td>
                    <td>U<sub>f</sub></td>
                </tr>

                <tr *ngFor="let join of sash.data.joins; let i = index">
                    <td>{{join.side}}</td>

                    <td *ngIf="join.confFrame">#{{join.confFrame.id}} - {{join.confFrame.name}}</td>
                    <td *ngIf="!join.confFrame">-</td>

                    <td *ngIf="join.sashFrame">#{{join.sashFrame.id}} - {{join.sashFrame.name}}</td>
                    <td *ngIf="!join.sashFrame">-</td>

                    <td *ngIf="join.divider" colspan="2">#{{join.divider.id}} - {{join.divider.name}}</td>
                    <td *ngIf="!join.divider" colspan="2">-</td>

                    <td>{{ sash.data.values.Af[i] | number:'1.3-3' }}</td>
                    <td>{{ sash.data.values.Sf[i] | number:'1.3-3' }}</td>
                    <td>{{ sash.data.values.Uf[i] | number:'1.3-3' }}</td>
                </tr>
            </table>
        </div>
    </div>
</div>
