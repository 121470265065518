import { HandleHeight } from '@icc/window';
import { Component, OnInit, Inject } from '@angular/core';
import { APP_CONFIG, AppConfigFactory, ConfigurationsService } from '@icc/common';
import { ModalService, ICC_PAGE_DATA, PageComponent } from '@icc/helpers';
import { WindowFacade } from '../+state/window.facade';
import { iccListItem } from '@icc/configurator/ui';
import { IccHandle } from '@icc/common/data-types/Handle';
import { IccAccessoryColor, IccSashType } from '@icc/common/data-types';
import { _, SharedFacade } from '@icc/configurator/shared';
import { ActiveSash } from '@icc/common/layout/active-sash';
import { ColorMappingService } from '@icc/common/colors/colors-mapping.service';
import { FormControl } from '@angular/forms';

@Component({
    selector: 'icc-handles-options-page',
    templateUrl: './handles-options-page.component.html',
    styleUrls: ['./handles-options-page.component.scss'],
})
export class HandlesOptionsPageComponent extends PageComponent implements OnInit {
    public title: string = _('WINDOW|Opcje klamki');
    selectedHandle: IccHandle | null = null;
    availableColorOuter = false;
    showHeightSelect = false;
    isLoweredHandleHeight = false;
    availableLoweredHandleHeight: HandleHeight | undefined;
    minHandleHeight = 0;
    maxHandleHeight = Infinity;
    handleHeightType: ActiveSash['handleHeightType'] = 'standard';
    max = Infinity;
    sashType: IccSashType['type'] | null = null;
    position: IccSashType['handle_position'] | null = null;
    systemHeight = false;

    colors: iccListItem[] = [];
    handleHeights: HandleHeight[] = [];
    confType = 'window';
    handleHeight: number | 'middle' | 'nonstandard' | null = null;
    customHandleHeight: number | 'middle' | 'nonstandard' = 0;

    selectColorOuter = new FormControl(false);

    selHandleColor = 0;
    defaultHandleColor = false;
    selHandleOuterColor = 0;
    defaultHandleOuterColor = false;

    selectedHandleImg = '';

    constructor(
        @Inject(APP_CONFIG) public config: AppConfigFactory,
        private windowFacade: WindowFacade,
        private sharedFacade: SharedFacade,
        private modalService: ModalService,
        private configurationsService: ConfigurationsService<'window'>,
        private colorsMappingService: ColorMappingService,
        @Inject(ICC_PAGE_DATA)
        private pageData: {
            selectedHandle: IccHandle;
            handleColors: IccAccessoryColor[];
            selHandleColor: IccAccessoryColor;
            selHandleOuterColor: IccAccessoryColor;
            availColorOuter: boolean;
            showHeightSelect: boolean;
            handleHeights: {
                name: string;
                height: number | 'middle' | 'nonstandard';
            }[];
            loweredHandleHeight: {
                name: string;
                height: number;
            };
            minHandleHeight: number;
            maxHandleHeight: number;
            handleHeightType: ActiveSash['handleHeightType'];
            sashHeight: number;
            sashType: IccSashType['type'];
            position: IccSashType['handle_position'];
            systemHeight: boolean;
            handleY: number | 'middle' | 'nonstandard';
            place: string;
        }
    ) {
        super();
    }

    ngOnInit() {
        this.selectedHandle = this.pageData.selectedHandle;
        this.availableColorOuter = this.pageData.availColorOuter;
        this.showHeightSelect = this.pageData.showHeightSelect;
        this.handleHeights = this.pageData.handleHeights;
        this.availableLoweredHandleHeight = this.pageData.loweredHandleHeight;
        this.isLoweredHandleHeight = this.selectedHandle.isLoweredHandleHeight ? true : false;
        this.confType = this.configurationsService.conf.Current.type;
        this.minHandleHeight = this.pageData.minHandleHeight;
        this.maxHandleHeight = this.pageData.maxHandleHeight;
        this.handleHeightType = this.pageData.handleHeightType;
        this.max = this.pageData.sashHeight - 50;
        this.sashType = this.pageData.sashType;
        this.position = this.pageData.position;
        this.systemHeight = this.pageData.systemHeight;

        this.selHandleColor = Number(this.pageData.selHandleColor.id);
        this.defaultHandleColor = Boolean(this.pageData.selHandleColor.isDefault);
        this.selHandleOuterColor = Number(this.pageData.selHandleOuterColor.id);
        this.defaultHandleOuterColor = Boolean(this.pageData.selHandleOuterColor.isDefault);
        this.colors = this.pageData.handleColors
            .filter(
                color =>
                    this.selectedHandle
                    && this.selectedHandle.colors_ids
                    && this.selectedHandle.colors_ids.some(id => Number(id) === Number(color.id))
            )
            .map(color => ({
                id: Number(color.id),
                title: color.name,
                color: '#' + color.color,
                imageUrl: '/files/windowhandlescolor/' + color.color_img,
            }));
        this.setDefaultHandleColors();
        this.selectedHandleImg = this.getImageUrl(this.selectedHandle, this.selHandleColor);

        if (
            !this.pageData.handleY
            && this.pageData.handleHeights
            && this.pageData.handleHeights[0]
        ) {
            this.pageData.handleY = this.pageData.handleHeights[0].height;
            if (this.pageData.handleHeights.some(h => h.height === 'middle')) {
                this.handleHeightType = 'middle';
            }
        }
        if (this.handleHeightType === 'standard') {
            this.handleHeight = this.pageData.handleY;
            this.customHandleHeight = this.pageData.handleY;
        } else if (this.handleHeightType === 'middle') {
            this.handleHeight = 'middle';
            this.customHandleHeight = this.pageData.handleY;
        } else if (this.handleHeightType === 'nonstandard') {
            this.handleHeight = 'nonstandard';
            this.customHandleHeight = this.pageData.handleY;
        }
    }

    private setDefaultHandleColors() {
        if ((this.pageData.selectedHandle.colors_ids || []).map(Number).indexOf(this.selHandleColor) === -1) {
            const handleColorsIds = (this.pageData.selectedHandle.colors_ids || []).map(Number);
            const windowColorId = this.colorsMappingService.getWindowColorId(
                this.configurationsService.conf.Current,
                this.pageData.place === 'outerPull' ? 'outer' : 'inner'
            );
            const matchedColors = this.colorsMappingService.getColors(
                Number(windowColorId),
                'window',
                'accessory'
            );
            const colors = matchedColors
                .map(m => handleColorsIds.filter(c => c === m)[0])
                .filter(m => m);
            if (colors.length) {
                this.selHandleColor = colors[0];
            } else {
                this.selHandleColor = handleColorsIds[0];
            }
            this.defaultHandleColor = true;
        }
        if ((this.pageData.selectedHandle.colors_ids || []).map(Number).indexOf(this.selHandleOuterColor) === -1) {
            this.selHandleOuterColor = this.selHandleColor;
            this.defaultHandleOuterColor = true;
        }
    }

    changeHandle() {}

    private getImageUrl(handle: IccHandle, selectedColor: number): string {
      return handle.colors_imgs && handle.colors_imgs[selectedColor]
          ? '/files/windowhandlescolorswindowaccessory/' + handle.colors_imgs[selectedColor]
          : '/files/windowaccessory/' + handle.img;
    }

    selectHandleColor(colorItem: iccListItem, outer = false) {
        const handle = this.pageData.selectedHandle;
        const color = this.pageData.handleColors.find(c => Number(c.id) === colorItem.id);
        if (handle && color) {
            this.defaultHandleOuterColor = false;
            this.defaultHandleColor = false;
            if (!outer) {
                this.selHandleColor = Number(color.id);
            }
            if ((this.confType === 'door' && !outer && (!this.selectColorOuter.value ||  !this.selHandleOuterColor || !this.pageData.selHandleOuterColor.id)) || outer){
                this.selHandleOuterColor = Number(color.id);
            }
            this.selectedHandleImg = this.getImageUrl(handle, this.selHandleColor);
        }
    }

    save() {
        if (this.availableLoweredHandleHeight && this.availableLoweredHandleHeight.height) {
            if (this.isLoweredHandleHeight) {
                this.handleHeight = this.availableLoweredHandleHeight.height;
            } else {
                this.handleHeight = 'middle';
            }
        }
        this.pageData.selectedHandle.isLoweredHandleHeight = this.isLoweredHandleHeight;

        this.sharedFacade.closePage({
            handle: this.pageData.selectedHandle,
            height: this.handleHeight,
            customHeight: this.customHandleHeight,
            position: this.position,
            color: this.selHandleColor,
            colorOuter: this.selHandleOuterColor,
            defaultColor: this.defaultHandleColor,
            defaultColorOuter: this.defaultHandleOuterColor,
        });
    }
}
