import { core } from '@icc/common/helpers';
import { WindowActiveConfiguration } from '@icc/common/configurations/WindowActiveConfiguration';
import { ProfilesService } from '@icc/common/profiles.service';
import { SashTypes, ProfileSet } from '@icc/window';
import { Common } from '@icc/common/Common';
import { Injectable } from '@angular/core';

@Injectable()
export abstract class ProfileSetsService {
    loadedData = false;
    protected profileSets: ProfileSet[] = [];

    constructor(protected profilesService: ProfilesService) {}

    getDefaultSet(conf: WindowActiveConfiguration, defaultConf: WindowActiveConfiguration) {
        let defaultProfileSet;
        if (
            defaultConf.ProfileSet
            && (defaultConf.ProfileSet.id || defaultConf.ProfileSet.frameSide)
            && defaultConf.ProfileSet.systems.indexOf(Number(conf.System.id)) > -1
        ) {
            defaultProfileSet = core.copy(defaultConf.ProfileSet);
        } else {
            if (conf.System?.market_default_configurations?.profile_set_id) {
                defaultProfileSet = this.getProfileSet(conf.System.market_default_configurations.profile_set_id);
            } else {
                defaultProfileSet = this.getProfileSet(conf.System.default_profile_set_id);
            }
        }
        if (!defaultProfileSet) {
            defaultProfileSet = this.getProfileSetsForSystem(conf.System.id)[0];
        }
        if (!defaultProfileSet || (!defaultProfileSet.id && !defaultProfileSet.frameSide)) {
            defaultProfileSet = this.getProfileSetFromProfiles(conf);
        }
        return defaultProfileSet;
    }

    getProfileSet(id: number, profileSets: ProfileSet[] = this.profileSets) {
        const matchedSets = profileSets.filter(set => Number(set.id) === Number(id));
        let matchedSet = null;
        if (matchedSets.length > 0) {
            matchedSet = matchedSets[0];
        }
        return matchedSet;
    }

    getProfileSetsForSystem(systemId: number) {
        const matchedSets = this.profileSets.filter(
            set => set.systems.indexOf(Number(systemId)) > -1
        );
        return matchedSets;
    }

    getProfileSetFromProfiles(conf) {
        const framesTop = this.profilesService.getFilteredProfiles(conf, 'frame', {
            and: ['side'],
            not: [],
        });
        const framesBottom = this.profilesService.getFilteredProfiles(conf, 'frame', {
            and: ['bottom'],
            not: [],
        });
        const framesSide = this.profilesService.getFilteredProfiles(conf, 'frame', {
            and: ['side'],
            not: [],
        });
        const sashes = this.profilesService.getFilteredProfiles(conf,  conf.System.door_type ? 'virtual_door_sash' : 'sash', {
            and: [],
            not: ['outward_opening'],
        });
        const sashesOutward = this.profilesService.getFilteredProfiles(conf,  conf.System.door_type ? 'virtual_door_sash' : 'sash', {
            and: ['outward_opening'],
            not: [],
        });
        const fixedMullions = this.profilesService.getFilteredProfiles(conf, 'fixed_mullion');
        const falseMullions = this.profilesService.getFilteredProfiles(conf, 'false_mullion');
        const thresholds = this.profilesService.getFilteredProfiles(conf, 'threshold');
        const profileShapes = this.profilesService.getProfileShapes();
        return {
            id: null,
            name: '',
            frameTop: framesTop[0] ? framesTop[0].id : null,
            frameBottom: framesBottom[0] ? framesBottom[0].id : null,
            frameSide: framesSide[0] ? framesSide[0].id : null,
            sash: sashes[0] ? sashes[0].id : null,
            sashOutward: sashesOutward[0] ? sashesOutward[0].id : null,
            fixedMullion: fixedMullions[0] ? fixedMullions[0].id : null,
            falseMullion: falseMullions[0] ? falseMullions[0].id : null,
            threshold: thresholds[0] ? thresholds[0].id : null,
            centralHandleFalseMullion: null,
            doubleHandleFalseMullion: null,
            zMullion: null,
            glazingBeadShape: profileShapes[0] ? profileShapes[0].id : null,
            systems: [Number(conf.System.id)],
        };
    }

    getProfileSetsForConstructionPart(part, conf, profileSets: ProfileSet[]) {
        const profileIds = conf.Sashes.filter(
            sash => part.sashIds.map(Number).indexOf(Number(sash.id)) > -1
        ).reduce(
            (ids, sash) => {
                const frame = conf.Frames.find(f => f.id === sash.frameId);
                const frameSides = this.profilesService.getFrameSides(frame, conf);
                ['left', 'right', 'top', 'bottom'].forEach(side => {
                    if (
                        sash.frame[side]
                        && ids.sash.indexOf(sash.frame[side].profileId) === -1
                        && SashTypes.FIX.indexOf(sash.type.type) === -1
                    ) {
                        ids.sash.push(sash.frame[side].profileId);
                    }
                    if (
                        sash.frame[side]
                        && ids.sashOutward.indexOf(sash.frame[side].profileId) === -1
                        && SashTypes.OUTWARD_OPENING.indexOf(sash.type.type) > -1
                    ) {
                        ids.sashOutward.push(sash.frame[side].profileId);
                    }
                    const frameSideIndex = frameSides.findIndex(
                        frameSide => frameSide.side === side
                    );
                    if (conf.drawData && conf.drawData.sashFrame) {
                        const sashData = conf.drawData.sash.find(o => o.sashId === sash.id);
                        if (
                            frameSideIndex > -1
                            && Common.isNumber(sashData.parallel.poly[frameSideIndex])
                        ) {
                            if (
                                frame.frame[frameSideIndex]?.profileId
                                && ids.frame[frameSides[frameSideIndex].sideSimple].indexOf(
                                    frame.frame[frameSideIndex]?.profileId
                                ) === -1
                                && (side !== 'bottom' || !frame.lowThreshold)
                            ) {
                                ids.frame[frameSides[frameSideIndex].sideSimple].push(
                                    frame.frame[frameSideIndex].profileId
                                );
                            }
                            if (
                                frame.frame[frameSideIndex]?.profileId
                                &&ids.threshold.indexOf(frame.frame[frameSideIndex]?.profileId) === -1
                                && (side === 'bottom' && frame.lowThreshold)
                            ) {
                                ids.threshold.push(frame.frame[frameSideIndex].profileId);
                            }
                        }
                    }
                });
                return ids;
            },
            {
                frame: {
                    top: [],
                    bottom: [],
                    side: [],
                },
                sash: [],
                sashOutward: [],
                threshold: [],
            }
        );
        const mullionsIds = conf.Mullions.filter(
            mullion => part.divIds.map(Number).indexOf(Number(mullion.id)) > -1
        ).reduce(
            (mullionProfilesIds, mullion) => {
                if (
                    this.profilesService.getMullionSetType(mullion, conf.Mullions, conf.type)
                    && mullionProfilesIds[
                        this.profilesService.getMullionSetType(mullion, conf.Mullions, conf.type)
                    ].indexOf(mullion.profileId) === -1
                ) {
                    mullionProfilesIds[
                        this.profilesService.getMullionSetType(mullion, conf.Mullions, conf.type)
                    ].push(mullion.profileId);
                }
                return mullionProfilesIds;
            },
            {
                fixedMullion: [],
                falseMullion: [],
                centralHandleFalseMullion: [],
                doubleHandleFalseMullion: [],
                zMullion: [],
            }
        );

        const matchingProfileSetsIds = profileSets
            .filter(set => {
                return (
                    (profileIds.frame.top.length === 0
                        || profileIds.frame.top.indexOf(set.frameTop) > -1)
                    && (profileIds.frame.side.length === 0
                        || profileIds.frame.side.indexOf(set.frameSide) > -1)
                    && (profileIds.frame.bottom.length === 0
                        || profileIds.frame.bottom.indexOf(set.frameBottom) > -1)
                    && (profileIds.threshold.length === 0
                        || profileIds.threshold.indexOf(set.threshold) > -1)
                    && (profileIds.sash.length === 0 || profileIds.sash.indexOf(set.sash) > -1)
                    && (profileIds.sashOutward.length === 0
                        || profileIds.sashOutward.indexOf(set.sashOutward) > -1)
                    && (mullionsIds.fixedMullion.length === 0
                        || mullionsIds.fixedMullion.indexOf(set.fixedMullion) > -1)
                    && (mullionsIds.falseMullion.length === 0
                        || mullionsIds.falseMullion.indexOf(set.falseMullion) > -1)
                    && (mullionsIds.centralHandleFalseMullion.length === 0
                        || mullionsIds.centralHandleFalseMullion.indexOf(
                            set.centralHandleFalseMullion
                        ) > -1)
                    && (mullionsIds.doubleHandleFalseMullion.length === 0
                        || mullionsIds.doubleHandleFalseMullion.indexOf(
                            set.doubleHandleFalseMullion
                        ) > -1)
                    && (mullionsIds.zMullion.length === 0
                        || mullionsIds.zMullion.indexOf(set.zMullion) > -1)
                );
            })
            .map(set => set.id);

        return matchingProfileSetsIds;
    }

    protected loadProfileSets(data) {
        this.profileSets = data.profileSets || [];
        this.loadedData = true;
    }
}
