import {
    Component,
    OnInit,
    Input,
    Output,
    EventEmitter,
    TemplateRef,
    ViewChild,
    ChangeDetectionStrategy,
    OnChanges,
    AfterViewChecked,
    Inject,
} from '@angular/core';
import {
    itemTemplateIsType,
} from './item';
import type {
    iccListItem,
    iccListTab,
    iccListItemTemplate,
    iccListItemWidth,
    TemplateMap,
    iccListItemImgHeight,
} from './item';
import * as Fuse from 'fuse.js';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { BehaviorSubject, fromEvent, Subject, Subscription } from 'rxjs';
import { VirtualScrollerComponent, IPageInfo } from 'ngx-virtual-scroller';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { AppConfigFactory, APP_CONFIG } from '@icc/common/config';
import { SharedFacade } from '@icc/configurator/shared';

@Component({
    selector: 'icc-list',
    templateUrl: './list.component.html',
    styleUrls: ['./list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ListComponent implements OnInit, OnChanges, AfterViewChecked {
    @Input() items: iccListItem[] = [];
    @Input() tabs: iccListTab[] = [];
    @Input() selected: number | string = 0;
    @Input() selectedMultiple: (number | string)[] = [];
    @Input() searchable: boolean = false;
    @Input() scrollable: boolean = true;
    @Input() multiple: boolean = false;
    @Input() vertical: boolean = false;
    @Input() selectBeforeCallback: boolean = this.config().IccConfig.Components.ListComponent.listComponentSelectBeforeCallback;
    @Input() itemTemplate?: TemplateRef<any> | iccListItemTemplate;
    @Output() showInfo = new EventEmitter<iccListItem>();
    @Output() editGlazing = new EventEmitter<iccListItem>();
    @Output() select = new EventEmitter<iccListItem>();
    @Output() selectMultiple = new EventEmitter<(number | string)[]>();
    @Output() contentScrolled = new EventEmitter<'UP' | 'DOWN'>();
    @Output() tabChange = new EventEmitter<{
        tab: iccListTab;
        filteredItems: iccListItem[];
    }>();

    @ViewChild('itemOnlyImg', { static: true })
    private itemOnlyImgTemplate?: TemplateRef<any>;

    @ViewChild('itemOnlyTitle', { static: true })
    private itemOnlyTitleTemplate?: TemplateRef<any>;

    @ViewChild('itemColor', { static: true })
    private itemColorTemplate?: TemplateRef<any>;

    @ViewChild('itemImgTitleDescription', { static: true })
    private itemImgTitleDescriptionTemplate?: TemplateRef<any>;

    @ViewChild('mountingMethod', { static: true })
    private mountingMethod?: TemplateRef<any>;

    @ViewChild('itemImgTitle', { static: true })
    private itemImgTitleTemplate?: TemplateRef<any>;
    @ViewChild('scroll', { static: false })
    private scroller?: VirtualScrollerComponent;

    public itemTemplateDef?: TemplateRef<any>;

    @Input()
    public itemImgHeight?: iccListItemImgHeight;

    @Input()
    public itemWidth: iccListItemWidth = 'narrow';
    @Input()
    public ignoreTemplateWidth = false;

    activeTab: number | string | null = null;
    filteredItems$: BehaviorSubject<iccListItem[]> = new BehaviorSubject([]);

    filteredItems: iccListItem[] = [];
    resizeSubscribtion?: Subscription;

    lastScrollPos = 0;
    animating = false;

    isMobile = false;
    hasBeenScrolled = false;
    public searching = false;
    public availableTabs: iccListTab[] = [];

    private elementsToHideOnScroll: (HTMLElement | null)[] = [];

    private unsubscribe$ = new Subject<void>();

    hideSingleTab: boolean = this.config().IccConfig.Components.ListComponent.hideSingleTab;

    constructor(
        private breakpointObserver: BreakpointObserver,
        @Inject(APP_CONFIG) private config: AppConfigFactory,
        private sharedFacade: SharedFacade,
    ) {
        this.breakpointObserver
            .observe([Breakpoints.Handset, Breakpoints.Tablet])
            .subscribe(state => {
                this.isMobile = state.matches;
            });
    }

    isSelectedId(id: string | number) {
        if (this.multiple) {
            return this.selectedMultiple.includes(id);
        } else {
            return this.selected == id;
        }
    }

    ngOnInit() {
        const map: TemplateMap = {
            itemOnlyImg: {
                template: this.itemOnlyImgTemplate!,
                width: 'verynarrow',
                imgHeight: 'medium'
            },
            itemColor: {
                template: this.itemColorTemplate!,
                width: 'medium',
                imgHeight: 'low'
            },
            itemImgTitleDescription: {
                template: this.itemImgTitleDescriptionTemplate!,
                width: 'wide',
                imgHeight: 'medium'
            },
            mountingMethod: {
                template: this.mountingMethod!,
                width: 'wide',
                imgHeight: 'medium'
            },
            itemImgTitle: {
                template: this.itemImgTitleTemplate!,
                width: 'narrow',
                imgHeight: 'medium'
            },
            itemOnlyTitle: {
                template: this.itemOnlyTitleTemplate!,
                width: 'narrow',
                imgHeight: 'medium'
            },
        };
        this.itemTemplateDef = this.mapTemplateStringToDef(map);
        this.itemWidth = this.mapTemplateStringToWidth(map);
        this.itemImgHeight = this.mapTemplateStringToHeight(map);

        if (this.searchable) {
            this.sharedFacade.enableSearch();
        }

        this.sharedFacade.searchValue$.pipe(takeUntil(this.unsubscribe$)).subscribe(value => {
            if (value && value.length > 0) {
                this.searching = true;
                this.search(value);
            } else if (this.searching) {
                this.stopSearching();
            }
        });

        this.elementsToHideOnScroll = [
            document.querySelector('#banner-container'),
            document.querySelector('#step-toolbar'),
            document.querySelector('.summary'),
        ];
        // on window rezise
        this.resizeSubscribtion = fromEvent(window, 'resize')
            // debounced
            .pipe(debounceTime(500))
            // trigger arrows change
            .subscribe(() => {
                this.showElemsOnscroll(this.elementsToHideOnScroll);
            });
    }

    ngAfterViewChecked() {
        if (!this.hasBeenScrolled) {
            this.scrollToSelectedItem();
        }
    }

    scrollToSelectedItem() {
        if (this.scroller && (this.selected || this.selectedMultiple)) {
            const scrollToItem = this.multiple ? this.selectedMultiple[0] : this.selected
            const selectedItemIndex = this.filteredItems.findIndex(i => String(i.id) === String(scrollToItem));
            if(selectedItemIndex) {
                this.scroller.scrollToIndex(
                  selectedItemIndex
                );
            }
        }
    }

    onSelect(item: iccListItem) {
        if (item.disabled) {
            return;
        }
        if (this.multiple) {
            if (this.selectBeforeCallback) {
                if (this.selectedMultiple.includes(item.id)) {
                    this.selectedMultiple = this.selectedMultiple.filter(el => el !== item.id);
                } else {
                    this.selectedMultiple = this.selectedMultiple.concat(item.id);
                }
            }
            this.selectMultiple.emit(this.selectedMultiple);
        } else if (this.selectBeforeCallback) {
            this.selected = item.id;
        }
        this.select.emit(item);
    }

    ngOnChanges() {
        this.filterItems();
    }

    selectTab(tab: iccListTab) {
        this.activeTab = tab.id;
        const filteredItems =  this.filterItems();
        this.tabChange.next({
            tab,
            filteredItems,
        });
    }
    ngOnDestroy(){
        this.showElemsOnscroll(this.elementsToHideOnScroll);
        this.unsubscribe$.next();
        this.sharedFacade.disableSearch();
    }
    /**
     * Sets classes .slide-off to header on scroll
     *
     * @param {IPageInfo} delta
     * @memberof ListComponent
     */
    onScroll() {

        if (this.isMobile) {
            if (
                Math.abs(
                    this.lastScrollPos
                        - ((this.scroller as VirtualScrollerComponent)
                            .parentScroll as HTMLDivElement).scrollTop
                ) < 10
            ) {
                return;
            }

            const parentScrollTop = ((this.scroller as VirtualScrollerComponent)
                .parentScroll as HTMLDivElement).scrollTop;

            const dir = this.lastScrollPos > parentScrollTop ? 'UP' : 'DOWN';

            this.contentScrolled.emit(dir);

            if (parentScrollTop < 10) {
                this.showElemsOnscroll(this.elementsToHideOnScroll);
                this.animating = true;
                setTimeout(() => {
                    this.animating = false;
                }, 500);
            }

            if (!this.animating) {
                if (dir === 'DOWN') {
                    this.hideElemsOnScroll(this.elementsToHideOnScroll);
                } else {
                    this.showElemsOnscroll(this.elementsToHideOnScroll);
                }
                this.animating = true;
                setTimeout(() => {
                    this.animating = false;
                }, 500);
            }
            this.lastScrollPos = ((this.scroller as VirtualScrollerComponent)
                .parentScroll as HTMLDivElement).scrollTop;
        }
        this.hasBeenScrolled = true;
    }

    private showElemsOnscroll(elems: (HTMLElement | null)[]) {
        elems.filter(e => e).forEach(elem => (elem as HTMLElement).classList.remove('slide-off'));
    }

    private hideElemsOnScroll(elems: (HTMLElement | null)[]) {
        elems.filter(e => e).forEach(elem => (elem as HTMLElement).classList.add('slide-off'));
    }
    filterItems() {
        let filteredItems = this.items;
        if (this.items && !this.searching) {
            if (this.tabs && this.tabs.length > 0) {
                this.availableTabs = this.tabs.filter(tab =>
                    this.items.some(
                        item => item.tabs && item.tabs.map(String).includes(String(tab.id))
                    )
                );
                filteredItems = this.items.filter(
                    item => item.tabs && item.tabs.map(String).includes(String(this.activeTab))
                );
                this.filteredItems$.next(filteredItems);
            } else {
                this.filteredItems$.next(filteredItems);
            }
        }
        this.filteredItems = filteredItems;
        this.scrollToSelectedItem();
        return filteredItems;
    }

    getItemHeightAndWidthClass() {
        return {
            ['icc-list-item-' + this.itemWidth + ' icc-list-item-img-' + this.itemImgHeight]: true,
        };
    }

    stopSearching() {
        this.searching = false;
        this.filterItems();
    }

    search(value: string) {
        const options: Fuse.FuseOptions<any> = {
            shouldSort: true,
            tokenize: true,
            threshold: 0.2,
            keys: ['title', 'description'],
        };
        const fuse = new Fuse(this.items, options);
        const result = fuse.search(value);
        this.filteredItems$.next(result);
    }

    getSelectedTab() {
        let selectedIndex = 0;
        const selectedItem = this.items.find(
            i =>
                String(i.id)
                === (this.multiple ? String(this.selectedMultiple) : String(this.selected))
        );
        if (selectedItem) {
            selectedIndex =
                (selectedItem.tabs != null
                    && this.availableTabs.findIndex(
                        t =>
                            selectedItem
                            && selectedItem.tabs
                            && selectedItem.tabs.some(g => String(t.id) === String(g))
                    ))
                || 0;
        }
        if (selectedIndex < 0) {
            selectedIndex = 0;
        }
        return selectedIndex;
    }

    clickShowInfoButton(item: iccListItem) {
        this.showInfo.emit(item);
    }

    clickEditGlazingButton(item: iccListItem) {
        this.editGlazing.emit(item);
    }

    trackByFunction(index: number | string, complexItem: iccListItem): number | string {
        return complexItem.id;
    }

    private mapTemplateStringToDef(map: TemplateMap) {
        if (itemTemplateIsType(this.itemTemplate, map)) {
            return map[this.itemTemplate].template;
        } else {
            return this.itemTemplate;
        }
    }

    private mapTemplateStringToWidth(map: TemplateMap) {
        if (!this.ignoreTemplateWidth && itemTemplateIsType(this.itemTemplate, map)) {
            return map[this.itemTemplate].width;
        } else {
            return this.itemWidth;
        }
    }

    private mapTemplateStringToHeight(map: TemplateMap) {
        if (this.itemImgHeight) {
            return this.itemImgHeight;
        } else if (itemTemplateIsType(this.itemTemplate, map)) {
            return map[this.itemTemplate].imgHeight;
        } else {
            return 'low';
        }
    }
}
