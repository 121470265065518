import { Pipe, PipeTransform } from '@angular/core';
import { Common } from '@icc/common/Common';
import { core } from '@icc/common/helpers';

@Pipe({name: 'currency'})

export class CurrencyPipe implements PipeTransform {

    transform(data: any, currency?: any, noneSymbol?: string | null, exchange = null, onlyValue = false): any {
        let value: any;

        if (data === null ) {
            return noneSymbol
        } else if (Common.isUndefined(data)) {
            return noneSymbol || null;
        } else if (isNaN(data)) {
            return noneSymbol || null;
        } else if (Common.isUndefined(currency)
            || Common.isUndefined(currency.symbol)
            || Common.isUndefined(currency.value)
            || Common.isUndefined(currency.thousands)
            || Common.isUndefined(currency.decimals)) {
            if (typeof currency === 'string') {
                try {
                    const o = JSON.parse(currency);
                    if (o && typeof o === 'object' && o !== null) {
                        currency = o;
                        const toExchange = exchange ?? !currency.pricesInCurrency;
                        const decimalPlaces =  currency.decimal_places != null ? Number(currency.decimal_places) : 2;
                        const decimalPlacesMultiplier = onlyValue ? 100 : (decimalPlaces == 0 ? 1 : Math.pow(10, decimalPlaces)); 

                        if (toExchange) {
                            value = Math.round(decimalPlacesMultiplier * (parseFloat(data) || 0) / parseFloat(currency.value)) / decimalPlacesMultiplier;
                        } else {
                            value = (parseFloat(data) || 0).toFixed(decimalPlaces);
                        }

                        if (onlyValue) {
                            return value;
                        }
                        if (currency.position === 'before') {
                            return currency?.symbol + value.toString();
                        } else {
                            return value.toString() + currency?.symbol;
                        }
                    }
                } catch (e) {
                    if (onlyValue) {
                        return parseFloat(data) || 0;
                    }
                    return (parseFloat(data) || 0).toFixed(2).toString() + 'zł';
                }
            }
            if(onlyValue) {
                return (parseFloat(data) || 0);
            }
            return (parseFloat(data) || 0).toFixed(2).toString() + 'zł';
        } else {
            const toExchange = exchange ?? !currency.pricesInCurrency;
            const decimalPlaces =  currency.decimal_places != null ? Number(currency.decimal_places) : 2;
            const decimalPlacesMultiplier = onlyValue ? 100 : (decimalPlaces == 0 ? 1 : Math.pow(10, decimalPlaces)); 

            if (toExchange) {
                value = Math.round(decimalPlacesMultiplier * (parseFloat(data) || 0) / parseFloat(currency.value)) / decimalPlacesMultiplier;
            } else {
                value = (parseFloat(data) || 0);
            }
            if (onlyValue) {
                return value.toFixed(decimalPlaces);
            }

            if (currency.position === 'before') {
                return currency?.symbol + core.formatNumber(value, decimalPlaces ?? 2, 3, currency.thousands, currency.decimals);
            } else {
                return core.formatNumber(value, decimalPlaces ?? 2, 3, currency.thousands, currency.decimals) + ' ' + currency?.symbol;
            }
        }
    }
  }
