import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { _, StepComponent, ModalService, SharedFacade } from '@icc/configurator/shared';
import { AccessoriesLocationPageComponent } from '../../../../window/src/lib/accessories-location-page/accessories-location-page.component';
import { AccessoriesService } from '@icc/legacy/configurator/steps/window/accessories/accessories.service';
import { IccAccessory, IccAccessoryColor } from '@icc/common/data-types';
import { APP_CONFIG, AppConfigFactory, core } from '@icc/common';
import { Subscription } from 'rxjs';
import { PriceService } from '@icc/price';
import { AccessoryFacade } from '../+state/accessory.facade';
import { ComplementaryGoodsService } from '@icc/legacy/configurator/steps/complementary_goods/complementary-goods.service';

@Component({
    selector: 'icc-accessories',
    templateUrl: './accessories.component.html',
    styleUrls: ['./accessories.component.scss'],
})
export class AccessoriesComponent extends StepComponent implements OnInit, OnDestroy {
    static stepName = _('STEPS|Dodatki i akcesoria');
    static stepIcon = {
        ligature: 'reorder',
    };

    public configurator = 'window';
    public stepId = 'accessories';
    public title = _('ACCESSORY|Dodatki');
    public options = [];

    colors: IccAccessoryColor[] = [];
    currency: any = {};

    accessories$ = this.accessoriesFacade.accessories$;
    complementaryAccessories$ = this.accessoriesFacade.complementaryAccessories$;
    complementarySills$ = this.accessoriesFacade.complementarySills$;
    complementaryCassonettos$ = this.accessoriesFacade.complementaryCassonettos$;
    complementaryFillings$ = this.accessoriesFacade.complementaryFillings$;
    complementaryProfiles$ = this.accessoriesFacade.complementaryProfiles$;
    sideAccessories$ = this.accessoriesFacade.sideAccessories$;
    sideProfiles$ = this.accessoriesFacade.sideProfiles$;

    sashes$ = this.accessoriesFacade.sashes$;
    isAccessoryConfigurator$ = this.accessoriesFacade.isAccessoryConfigurator$;
    windowAccessoriesManufacturerCode = this.config().IccConfig.Configurators
        .windowAccessoriesManufacturerCode;
    windowAccessoriesCounterControl = this.config().IccConfig.Configurators
        .windowAccessoriesCounterControl;
    showCoreColorInAdditionalProfilesAndAccessories = this.config().IccConfig.Configurators
        .showCoreColorInAdditionalProfilesAndAccessories;
    commentInAccessory = this.config().IccConfig.Configurators.commentInAccessory;

    private subscriptions: Subscription[] = [];
    private isAccessoryConfigurator = false;
    hiddenPrice = false;

    constructor(
        private accessoriesFacade: AccessoryFacade,
        private sharedFacade: SharedFacade,
        private modalService: ModalService,
        private accessoriesService: AccessoriesService,
        private complementaryGoodsService: ComplementaryGoodsService,
        private priceService: PriceService,
        @Inject(APP_CONFIG) private config: AppConfigFactory
    ) {
        super();
    }

    ngOnInit() {
        this.hiddenPrice = this.config().preset === 'b2c' && this.config().hidePricesInB2cConfigurator;
        
        if (this.complementaryGoodsService.loaded.accessory) {
            this.complementaryGoodsService.loadAccessories();
            this.colors = this.complementaryGoodsService.colorsAll;
        }
        this.subscriptions.push(
            this.sharedFacade.currency$.subscribe(currency => {
                this.currency = currency;
            })
        );

        this.subscriptions.push(
            this.accessoriesFacade.isAccessoryConfigurator$.subscribe(isAccessory => {
                this.isAccessoryConfigurator = isAccessory;
            })
        );
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(s => s.unsubscribe());
    }

    addAccessoriesToPart() {
        this.modalService.open({
            pageComponent: AccessoriesLocationPageComponent,
        });
    }

    addAccessoriesToConstruction() {
        this.accessoriesService.openModalAccessories().then(result => {
            if (result) {
                this.sharedFacade.closePage();
            }
        });
    }

    addComplementaryAccessories() {
        this.complementaryGoodsService.openModalAccessories().then(() => {
            this.sharedFacade.closePage();
        });
    }

    addComplementarySills() {
        this.complementaryGoodsService.openModalSills().then(() => {
            this.sharedFacade.closePage();
        });
    }

    addComplementaryCassonetto() {
        this.complementaryGoodsService.openModalCassonettos().then(() => {
            this.sharedFacade.closePage();
        });
    }

    addComplementaryFilling() {
        this.complementaryGoodsService.openModalFilling().then(() => {
            this.sharedFacade.closePage();
        });
    }

    addComplementaryProfile() {
        this.complementaryGoodsService.openModalProfile().then(() => {
            this.sharedFacade.closePage();
        });
    }

    remove(sash: any, accessory: IccAccessory, type?: string) {
        if (this.isAccessoryConfigurator) {
            this.complementaryGoodsService.remove(accessory, type || 'accessory');
        } else {
            this.accessoriesService.remove(sash, accessory);
        }
    }

    getColor(colorId, colors = this.complementaryGoodsService.colorsAll) {
        const color = core.fIdO(colors, colorId);
        if (color) {
            return color.name;
        }
    }

    getAccessoryPrice(accessory: IccAccessory) {
        return this.accessoriesService.getAccessoryPrice(accessory);
    }

    getAccessoryColors(accessory: IccAccessory) {
        return (this.complementaryGoodsService.colorsAll || []).filter(
            c =>
                accessory.accessory.colors_ids
                && accessory.accessory.colors_ids.map(Number).includes(Number(c.id))
        );
    }

    changeAccessoryColor(accessory: IccAccessory) {
        this.accessoriesService.setAccessoryColor(accessory, this.complementaryGoodsService.colorsAll)
        this.priceService.count();
    }

    isEnabledType(type: 'accessory' | 'glass' | 'profile' | 'cassonetto' | 'windowsill') {
        return this.complementaryGoodsService.isEnabledType(type)
    }

}
