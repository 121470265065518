import { ActiveConfiguration } from './ActiveConfiguration';
import { RollerShutterConfiguration } from './RollerShutterConfiguration';
import { PriceSegment, DiscountGroups, PricePart } from '@icc/price/b2b';
import { TimeLimitSegment } from '@icc/common/time-limit/time-limit-functions';
import { ActiveAccessory } from './parts/common';
import { ActiveRollerShutter } from './parts/roller_shutter';
import { ConfiguratorsDataService } from '../configurators/configurators-data.service';

export class RollerShutterActiveConfiguration implements ActiveConfiguration {
    type: 'roller_shutter' | 'external_blind' = 'roller_shutter';
    Name: string = '';
    Price: number = 0;
    PriceNoMargin: number = 0;
    PriceAfterDiscounts: number = 0;
    PriceGross: number = 0;
    PriceGrossAfterDiscounts: number = 0;
    Quantity: number = 1;
    Issues: any[] = [];
    PriceSegments: PriceSegment[] = [];
    PriceSegmentsNoMargin: PriceSegment[] = [];
    PriceParts: PricePart[] = [];
    PricePartsNoMargin: PricePart[] = [];
    DiscountGroups: DiscountGroups = {};
    DiscountGroupsNoMargin: DiscountGroups = {};
    Discount: number = 0;
    timeLimit: number = 0;
    timeLimitsStack: TimeLimitSegment[] = [];
    Title = '';
    Description = '';
    Attachments = [];
    valid: {} = {};
    PriceValid = false;

    Accessories: any[] = [];
    RollerShutter: ActiveRollerShutter = new ActiveRollerShutter();
    Height: number = 1500;
    Width: number = 1500;
    Weight: number = 0;

    drawData: any;

    attributes: {
        id: string;
        name: string;
        value: string;
        valueId?: number;
    }[] = [];
    hasRoller = false;

    constructor(configuration?, private configuratorsDataService?: ConfiguratorsDataService) {
        if (configuration) {
            if (RollerShutterActiveConfiguration.is(configuration)) {
                for (const propName in configuration) {
                    if (this.hasOwnProperty(propName)) {
                        this[propName] = configuration[propName];
                    }
                }
            } else {
                if (RollerShutterConfiguration.is(configuration) && configuratorsDataService) {
                    this.Name = configuration.name;
                    this.Price = configuration.price;
                    this.PriceNoMargin = configuration.priceNoMargin;
                    this.PriceAfterDiscounts = configuration.priceAfterDiscounts;
                    this.PriceGross = configuration.priceGross;
                    this.PriceGrossAfterDiscounts = configuration.priceGrossAfterDiscounts;
                    this.Quantity = configuration.quantity;
                    this.PriceSegments = configuration.priceSegments;
                    this.PriceSegmentsNoMargin = configuration.priceSegmentsNoMargin;
                    this.PriceParts = configuration.priceParts;
                    this.PricePartsNoMargin = configuration.pricePartsNoMargin;
                    this.DiscountGroups = configuration.discountGroups;
                    this.DiscountGroupsNoMargin = configuration.discountGroupsNoMargin;
                    this.Title = configuration.title;
                    this.Description = configuration.description;
                    this.valid = configuration.valid;
                    this.timeLimit = configuration.timeLimit;
                    this.timeLimitsStack = configuration.timeLimitsStack;
                    this.attributes = configuration.attributes;
                    this.Accessories = configuration.accessories
                        .filter(accessory =>
                            configuratorsDataService.get('windowAccessories', accessory.id)
                        )
                        .map(
                            accessory =>
                                new ActiveAccessory(accessory, null, this.configuratorsDataService)
                        );

                    this.RollerShutter = new ActiveRollerShutter(
                        configuration.rollerShutter,
                        configuratorsDataService
                    );
                    this.Width = configuration.rollerShutter.boxWidth;
                    this.Height = configuration.rollerShutter.rollerHeight;
                    this.Weight = configuration.parameters.weight;
                }
            }
        }
        this.configuratorsDataService = null;
    }

    public static is(configuration): configuration is RollerShutterActiveConfiguration {
        return configuration instanceof RollerShutterActiveConfiguration
            || !configuration.$version && (configuration.type === 'roller_shutter' || configuration.type === 'external_blind');
    }
}

export type IRollerShutterActiveConfiguration = Pick<
    RollerShutterActiveConfiguration,
    keyof RollerShutterActiveConfiguration
>;
