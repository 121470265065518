import { Common } from '../Common';
import { Injectable } from '@angular/core';
import { IccColors } from '@icc/common/data-types';

@Injectable()
export class ColorsDefaultsService {
    /**
     * Zwraca rodzaj koloru ustawionego po danej stronie konstrukcji.
     * @memberof ColorsService
     * @param  {object} colors Obiekt z kolorami
     * @param  {string} side   Miejsce konfiguracji
     * @return {string}        Rodzaj koloru
     */
    getColorValue(colors: IccColors, side: keyof IccColors['frame'], onlyFrame = false) {
        let value = 'NONE';
        if (colors.frame && colors.frame[side] && (onlyFrame || colors.sash && colors.sash[side])) {
            if (colors.frame[side].type === 'white' && (onlyFrame || colors.sash[side].type === 'white')) {
                value = 'WHITE';
            } else if (colors.frame[side].type === 'cream' && (onlyFrame || colors.sash[side].type === 'cream')) {
                value = 'CREAM';
            } else if (
                Common.isDefined(colors.frame[side].id)
                && colors.frame[side].type !== 'white'
                && colors.frame[side].type !== 'cream'
                && (onlyFrame || 
                    Common.isDefined(colors.sash[side].id)
                    && colors.sash[side].type !== 'white'
                    && colors.sash[side].type !== 'cream'
                    && colors.frame[side].id === colors.sash[side].id
                    && colors.frame[side].RAL === colors.sash[side].RAL
                )
            ) {
                value = 'COLOR';
            } else if (
                Common.isDefined(colors.frame[side].id)
                || (!onlyFrame && Common.isDefined(colors.sash[side].id))
            ) {
                value = 'BICOLOR';
            }
        }
        return value;
    }

    /**
     * Ustawia układ kolorów na podstawie ustawionych kolorów, jeśli wybrany nie pasuje.
     * @private
     * @param  {Object} colors      Kolory
     * @param  {Cbject} sashesCount Count skrzydeł
     * @return {Array}              Pary
     */
    getColorPairs(colors) {
        let pairs = [];

        if (colors.frame.core.type === 'white'
            && !colors.frame.outer?.id
            && !colors.frame.inner?.id) {
            pairs = ['b_b'];
            this.buildCoreColor(colors, pairs);
        } else if (colors.frame.core.type === 'cream'
        && !colors.frame.outer?.id
        && !colors.frame.inner?.id) {
            pairs = ['c_c'];
            this.buildCoreColor(colors, pairs);
        } else if (colors.frame.outer?.id && !colors.frame.inner?.id) {
            if (Common.isArray(colors.frame.outer.groups)) {
                for (let i = 0; i < colors.frame.outer.groups.length; i++) {
                    if (pairs.indexOf(colors.frame.outer.groups[i] + '_b')) {
                        pairs.push(colors.frame.outer.groups[i] + '_b');
                    }
                }
            }
        } else if (!colors.frame.outer?.id && colors.frame.inner?.id) {
            if (Common.isArray(colors.frame.inner.groups)) {
                for (let i = 0; i < colors.frame.inner.groups.length; i++) {
                    if (pairs.indexOf('b_' + colors.frame.inner.groups[i])) {
                        pairs.push('b_' + colors.frame.inner.groups[i]);
                    }
                }
            }
        } else if (colors.frame.outer?.id && colors.frame.inner?.id && colors.frame.outer?.id === colors.frame.inner?.id) {
            this.buildBilateralAndBicolor(colors, pairs, { Bilateral: '_' });
        } else if (colors.frame.outer?.id && colors.frame.inner?.id && colors.frame.outer?.id !== colors.frame.inner?.id) { 
            this.buildBilateralAndBicolor(colors, pairs, { Bicolor: '__' });
        }
        return pairs;
    }

    buildCoreColor(colors, pairs) {
        if (Common.isArray(colors.frame.core.groups)) {
            for (let i = 0; i < colors.frame.core.groups.length; i++) {
                if (
                    pairs.indexOf(
                        colors.frame.core.groups[i] + '_' + colors.frame.core.groups[i]
                    )
                ) {
                    pairs.push(
                        colors.frame.core.groups[i] + '_' + colors.frame.core.groups[i]
                    );
                }
            }
        }
    }

    buildBilateralAndBicolor(colors, pairs, colorSymbol) {
        let symbol = null;
        if (colorSymbol.Bilateral) {
            symbol = '_';
        } else if (colorSymbol.Bicolor) {
            symbol = '__';
        }
        if (
            Common.isArray(colors.frame.outer.groups)
            && Common.isArray(colors.frame.inner.groups)
        ) {
            for (let i = 0; i < colors.frame.outer.groups.length; i++) {
                for (let j = 0; j < colors.frame.inner.groups.length; j++) {
                    if (
                        pairs.indexOf(
                            colors.frame.outer.groups[i]
                                + symbol
                                + colors.frame.inner.groups[j]
                        ) === -1
                    ) {
                        pairs.push(
                            colors.frame.outer.groups[i]
                                + symbol
                                + colors.frame.inner.groups[j]
                        );
                    }
                }
            }
        }
    }
}
