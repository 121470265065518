<mat-card *ngFor="let product of (products | async)">
  <h2>{{product.name}}</h2>
  <p><b>Indeks: </b>{{product.code}}</p>
  <p><b>Indeks rozszerzony: </b>{{product.codeExt}}</p>
  <p *ngIf="this.curLang">
    <b>Nazwa: </b>{{product.localizedNamesExt[this.curLang]}}
  </p>
  <p *ngIf="!hiddenPrice">
    <b>Cena: </b>{{product.unitPrice}}
  </p>
  <p><b>Ilość: </b>{{product.quantity}}</p>
  <p *ngIf="product.configuration && product.configuration.length > 0"><b>Cechy: </b></p>
  <ul *ngIf="product.configuration && product.configuration.length > 0">
    <li *ngFor="let feature of product.configuration">
      {{feature.field}}: {{feature.value}}
    </li>
  </ul>
  <br>
</mat-card>