<form class="mat-elevation-z3">
    <p>{{ 'ROLLERSHUTTER|Szerokość rolety' | translate }}</p>
    <mat-form-field *ngFor="let shutter of (shutters$ | async); index as index;">
        <mat-label>{{letters[index]}}</mat-label>
        <input matInput
            [iccDimensionsValidator]="shutter.realWidth"
            isWidthDimension=true
            iccDimensionInput
            type="number"
            [name]="'shutter-width' + index"
            [ngModel]="shutter.realWidth"
            (ngModelChange)="shutter.realWidth = $event;
            updateDimensions(false,index)"
            [ngModelOptions]="{updateOn: 'blur'}"
            #shutterWidth="ngModel">
        <mat-error *ngIf="shutterWidth.invalid">{{ 'CONFIGURATOR|Podane wymiary są nieprawidłowe.' | translate }}</mat-error>
        <span matSuffix>{{ dimensionUnit.unitSymbol }}</span>
    </mat-form-field>
    <p>{{ 'ROLLERSHUTTER|Długość rolety' | translate }}</p>
    <mat-form-field *ngFor="let shutter of (shutters$ | async); index as index;">
        <mat-label>{{letters[index]}}</mat-label>
        <input matInput
            [iccDimensionsValidator]="shutter.realHeight"
            isWidthDimension=false
            iccDimensionInput
            type="number"
            step="0.1"
            [name]="'shutter-length' + index"
            [ngModel]="this.addBoxToHeight ? shutter.realHeight+this.configurationsService.conf.Current.RollerShutter.realBoxHeight : shutter.realHeight"
            (ngModelChange)="shutter.realHeight = $event;
            updateDimensions(true,index)"
            [ngModelOptions]="{updateOn: 'blur'}"
            [disabled]="shutter.commonRail"
            #shutterLength="ngModel">
        <mat-error *ngIf="shutterLength.invalid">{{ 'CONFIGURATOR|Podane wymiary są nieprawidłowe.' | translate }}</mat-error>
        <span matSuffix>{{ dimensionUnit.unitSymbol }}</span>
    </mat-form-field>
    <p class='dimensions-info' (click)='openDimensionsInfoPage()' *ngIf='isInfoPageAvailable'>
        {{ 'CONFIGURATOR|Jak mierzyć rolete?' | translate }} <mat-icon>info</mat-icon>
    </p>
    <p *ngIf="dimensionsInfo && (systemInfo$ | async)">
        <mat-icon>info</mat-icon>
        {{systemInfo$ | async}}
    </p>
       <div *ngIf="(mosquito$ | async) && this.areStepsJoined" id="mosquito-form">
            <form class='mosquito-form'>
                <h4>{{ 'ROLLERSHUTTER|Siatka antyinsektowa:' | translate }}</h4>
                <mat-checkbox *ngFor="let shutter of (shutters$ | async); index as index;" [name]="'shutter-mosquito-' + index" [ngModel]="shutter.mosquito"  (ngModelChange)="toggleMosquito($event, index)">
                    {{ 'ROLLERSHUTTER|Kwatera' | translate }} {{letters[index]}}
                </mat-checkbox>
            </form>
    </div>
</form>

