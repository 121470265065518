import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import {
    _,
    StepComponent,
    isObject,
    isDefined,
    isArray,
    ModalService,
} from '@icc/configurator/shared';
import { WindowFacade } from '../+state/window.facade';
import { tap, map, withLatestFrom } from 'rxjs/operators';
import {
    ConfigurationsService,
    EventBusService,
    DrawService,
    AppConfigFactory,
    APP_CONFIG,
    WindowActiveConfiguration,
    SizeRangeService,
    core,
    TranslateService,
    ConfiguratorsDataService,
} from '@icc/common';
import { SashesService } from '@icc/legacy/configurator/steps/window/sashes/sashes.service';
import { LayoutService } from '@icc/legacy/configurator/layout/layout.service';
import {
    FormBuilder,
    FormGroup,
    FormControl,
    ReactiveFormsModule,
    FormsModule,
} from '@angular/forms';
import { iccListItem } from '@icc/configurator/ui';
import { Observable } from 'rxjs';
import { SashLayoutPageComponent } from '../sash-layout-page/sash-layout-page.component';
import { BrowserProfilesService } from '../profiles/profiles.service';
import { ThresholdsService } from '@icc/legacy/configurator/layout/thresholds.service';
import { Profile } from '@icc/window';
import { DimensionsService } from '@icc/legacy/configurator/steps/window/dimensions/dimensions.service';
import { SashTypesService } from '@icc/legacy/configurator/layout/sash-types.service';
import { FillingsService } from '@icc/legacy/configurator/steps/window/glazings/fillings.service';
import { StepsService } from '@icc/helpers';
import { IccDrawMathService } from '@icc/draw';
import { IccLayoutsData } from '@icc/common/data-types';

@Component({
    selector: 'icc-layout',
    templateUrl: './layout.component.html',
    styleUrls: ['./layout.component.scss'],
})
export class LayoutComponent extends StepComponent implements OnInit, OnDestroy {
    static stepName = _('STEPS|Układ');
    static stepIcon = {
        ligature: 'view_compact',
    };

    public configurator = 'window';
    public stepId = 'layout';
    public title = _('WINDOW|Konstrukcja');
    public options = [
        {
            title: _('WINDOW|Edycja konstrukcji'),
            component: SashLayoutPageComponent,
            show: () => this.availableLayoutEdit(),
        },
    ];
    public allowMirrorLayout: boolean = this.config().IccConfig.Configurators.allowMirrorLayout;
    public selectedLayoutId: number | null = null;
    mode: 'list' | 'details' = 'list';

    balcony$ = this.windowFacade.balcony$;
    layout$ = this.windowFacade.layout$;

    balcony = this.configurationsService.conf.Current.Balcony;
    lowThresholds0$ = this.balcony$.pipe(map(balcony => this.lowThresholds.length > 0 && balcony));
    lowThresholds1$ = this.windowFacade.lowThreshold$.pipe(
        map(lowThreshold => this.lowThresholds.length > 1 && lowThreshold)
    );
    selectedLowThresholdId$ = this.windowFacade.configuration$.pipe(
        map(conf => this.profilesService.getUsedThresholdId(conf))
    );

    lowThresholds: Profile[] = [];

    drawOptions = Object.assign({}, this.drawService.options, {
        dimensions: false,
        indexes: false,
    });

    public defaultLayouts: any[] = [];
    private subscriptions: any[] = [];

    fins = new FormControl('');
    isMonoblockFrames = false;
    isRenoFrames = this.configurationsService.conf.Current.System.type_frame === 'reno';
    isOneFrameProfile = false;
    oneFinWidth = false;
    oneMaxFinWidth = null;
    shape = 'rect';
    frameSides: {
        type: string;
        profile: Profile | null;
        side: {
            side: string;
            sideSimple: string;
            frameEdges: { frameId: number; frameEdgeIndex: number }[];
        };
        id: number;
        finWidth: any;
        finWidths: number[];
        monoblockFrames: Profile[];
        maxFinWidth: any;
    }[] = [];
    isCircle =
        !this.configurationsService.conf.Current.hasRoller
        && this.shape !== 'circle'
        && this.isOneFrameProfile;
    monoblock$ = this.windowFacade.monoblock$;
    reno = this.configurationsService.conf.Current.System.type_frame === 'reno';
    mono =
        this.configurationsService.conf.Current.System.type_frame === 'monoblock'
        && this.isMonoblockFrames;
    canChangeDoorSide =
        this.configurationsService.conf.Current.type === 'door';
    doorSide: 'W_L' | 'W_R' | 'Z_L' | 'Z_R' = 'W_L';
    constructor(
        private windowFacade: WindowFacade,
        private configurationsService: ConfigurationsService<'window' | 'door' | 'sliding_door' | 'hs' | 'folding_door'>,
        private configuratorsDataService: ConfiguratorsDataService,
        private sashesService: SashesService,
        private layoutService: LayoutService,
        private eventBusService: EventBusService,
        private modalService: ModalService,
        private thresholdsService: ThresholdsService,
        private profilesService: BrowserProfilesService,
        private dimensionsService: DimensionsService,
        public drawService: DrawService,
        private sashTypesService: SashTypesService,
        private fillingsService: FillingsService,
        private stepsService: StepsService,
        private sizeRangeService: SizeRangeService,
        private translateService: TranslateService,
        @Inject(APP_CONFIG) private config: AppConfigFactory
    ) {
        super();
    }

    ngOnInit() {
        if (
            (!this.config().IccConfig.Configurators.window.defaultListLayoutMode
                || this.configurationsService.conf.Current.layoutHasBeenChosen)
            && this.configurationsService.conf.Current.Layout
            && this.configurationsService.conf.Current.Layout.id
            && this.configurationsService.conf.Current.System
            && !this.configurationsService.conf.Current.System.door_type
        ) {
            this.mode = 'details';
        }
        if (this.sashesService.loadedData) {
            this.init();
        }
        this.subscriptions.push(
            this.eventBusService.subscribeWithoutConfiguration('initializedConfigurator', () =>
                this.init()
            ),
            this.layout$.subscribe(l => {
                this.allowMirrorLayout = l && l.isMirrorLayoutAvailable && this.config().IccConfig.Configurators.allowMirrorLayout;
                this.selectedLayoutId = l && Number(l.id);
            })
        );
    }

    ngOnDestroy() {
        this.subscriptions.map(el => el.unsubscribe());
    }

    init() {
        this.loadLayouts();
        this.lowThresholds = this.profilesService.getFilteredProfiles(
            this.configurationsService.conf.Current,
            'threshold'
        );
        if (this.configurationsService.conf.Current.Sashes.length) {
            const { doorSideWZ, doorSideLR } = this.layoutService.getDoorSides();
            this.doorSide = (doorSideWZ + '_' + doorSideLR) as 'W_L' | 'W_R' | 'Z_L' | 'Z_R';
        }
        this.setFrameSides();
    }


    mirrorLayout() {
        this.configurationsService.conf.Current.Sashes.forEach(sash => {
            this.sashTypesService.mirrorSashType(sash, this.configurationsService.conf.Current);
        })
    }

    /**
     * Ładuje gotowe układy.
     */
    loadLayouts() {
        const blockedSashLayoutVariant: { types: any; options: any; }[] = this.layoutService.getBlockedSashLayoutVariants();

        const handlePosition = this.configurationsService.conf.Current.Sashes?.find((k) => k.type?.handle_position)?.type?.handle_position;
        const conf = this.configurationsService.conf.Current;
        this.defaultLayouts = this.layoutService
            .getLayouts(conf)
            .filter(this.filterLayoutsForDoorSide.bind(this))
            .map((el: any) => {
                let isSashLayoutBlocked = this.layoutService.isSashLayoutBlocked(el, blockedSashLayoutVariant, handlePosition);
                const errorMessages: string[] = [];
                if (el.SashesLayoutsVariant?.size_range_id && this.config()?.IccConfig.Configurators.window?.filterLayoutsOnSizeRanges) {
                    const sizeRanges = this.sizeRangeService.getSizeRange(el.SashesLayoutsVariant?.size_range_id, conf?.Width, conf?.Height);
                    if (sizeRanges && !sizeRanges?.isValid) {
                        if (sizeRanges?.width) {
                            if (sizeRanges?.width?.minX && sizeRanges?.width?.maxX && sizeRanges?.width?.minX !== sizeRanges?.width?.maxX) {
                                errorMessages.push(Math.abs(conf?.Width - sizeRanges?.width?.minX) < Math.abs(conf?.Width - sizeRanges?.width?.maxX) && sizeRanges?.width?.minX
                                    ? this.translateService.instant("WINDOW|Minimalna szerokość: {size} mm", { size: sizeRanges?.width?.minX })
                                    : this.translateService.instant("WINDOW|Maksymalna szerokość: {size} mm", { size: sizeRanges?.width?.maxX }));
                            } else if (sizeRanges?.width?.minX || sizeRanges?.width?.maxX || sizeRanges?.width?.minX === sizeRanges?.width?.maxX) {
                                this.translateService.instant("WINDOW|Szerokość: {size} mm", { size: sizeRanges?.width?.minX || sizeRanges?.width?.maxX })
                            }
                        } if (sizeRanges?.height) {
                            if (sizeRanges?.height?.maxY && sizeRanges?.height?.minY && sizeRanges?.height?.maxY !== sizeRanges?.height?.minY) {
                                errorMessages.push(Math.abs(conf?.Height - sizeRanges?.height?.minY) < Math.abs(conf?.Height - sizeRanges?.height?.maxY) && sizeRanges?.height?.minY
                                    ? this.translateService.instant("WINDOW|Minimalna wysokość: {size} mm", { size: sizeRanges?.height?.minY })
                                    : this.translateService.instant("WINDOW|Maksymalna wysokość: {size} mm", { size: sizeRanges?.height?.maxY }))
                            } else if (sizeRanges?.height?.maxY || sizeRanges?.height?.minY || sizeRanges?.height?.maxY === sizeRanges?.height?.minY) {
                                this.translateService.instant("WINDOW|Wysokość: {size} mm", { size: sizeRanges?.height?.maxY || sizeRanges?.height?.minY })
                            }
                        }
                    }


                }
                return {
                    id: Number(el.SashesLayoutsVariant.id),
                    isMirrorLayoutAvailable: el.SashesLayoutsVariant.allow_mirror_layout,
                    title: el.SashesLayoutsVariant.name,
                    imageUrl: '/files/sasheslayoutsvariant/' +
                        ((this.config().IccConfig.Configurators.window.drawingDefaultOuterView
                            && this.configurationsService.conf.conf !== 'door')
                            || (this.config().IccConfig.Configurators.door.drawingDefaultOuterView
                                && this.configurationsService.conf.conf === 'door')
                            ? el.SashesLayoutsVariant.img_out
                            : el.SashesLayoutsVariant.img),
                    disabled: errorMessages.length || isSashLayoutBlocked,
                    errorMessages
                }
            });
    }

    selectWindowSashesLayout(item: iccListItem) {
        const sashesLayout = this.sashesService.layouts.find(
            l => Number(l.SashesLayoutsVariant.id) === item.id
        );
        if (sashesLayout) {
            this.layoutService.selectLayout(sashesLayout).then(() => {
                this.configurationsService.conf.Current.layoutHasBeenChosen = true;
                if (this.configurationsService.conf.Current.System
                    && !this.configurationsService.conf.Current.System.door_type) {
                    this.mode = 'details';
                }
                this.allowMirrorLayout = sashesLayout.SashesLayoutsVariant?.allow_mirror_layout && this.config().IccConfig.Configurators.allowMirrorLayout;
                if (this.fillingsService.getMatchingFillingsForDoorSashes(this.configurationsService.conf.Current, 'doorLight').length <= 1) {
                    this.stepsService.disable('lightsglazing')
                }
                this.loadLayouts();
            });
        }
    }

    switchLowThreshold(value: boolean) {
        if (value) {
            this.setLowThreshold();
        } else {
            this.unsetLowThreshold();
        }
    }

    canBeBalcony() {
        return this.thresholdsService.canBeBalcony(this.configurationsService.conf.Current);
    }

    changeBalcony(value: boolean) {
        this.configurationsService.conf.Current.Balcony = value;
        this.thresholdsService.changeBalcony();
    }

    hasThreshold() {
        return this.thresholdsService.hasThreshold(this.configurationsService.conf.Current);
    }

    setLowThreshold() {
        this.thresholdsService.setThreshold(this.configurationsService.conf.Current);
    }

    unsetLowThreshold() {
        this.thresholdsService.unsetThreshold(this.configurationsService.conf.Current);
    }

    changeLowThreshold(id) {
        this.thresholdsService.changeLowThreshold(id, this.configurationsService.conf.Current);
    }

    availableLayoutEdit() {
        return (
            this.configurationsService.conf.Current != null
            && this.configurationsService.conf.Current.Sashes?.length > 0
            && this.allowMirrorLayout
        );
    }

    editLayout() {
        this.modalService.open({
            pageComponent: SashLayoutPageComponent,
        });
    }

    selectedLowThresholdId() {
        this.profilesService.getUsedThresholdId(this.configurationsService.conf.Current);
    }

    setFrameSides() {
        if (
            !this.configurationsService.conf.Current
            || !this.configurationsService.conf.Current.Frames.length
        ) {
            return;
        }

        const conf = this.configurationsService.conf.Current;
        const sides = this.profilesService.getFrameSidesOnEdge(conf);

        if (this.profilesService.loadedData) {
            this.frameSides = sides.map((side, index) => {
                let profile: Profile | null = null;
                let type = 'std';
                let id = 0;
                let finWidth = null;
                let finWidths: number[] = [];
                let monoblockFrames: Profile[] = [];
                const frame = conf.Frames.find(f => f.id === side.frameEdges[0].frameId);
                const frameEdgeIndex = side.frameEdges[0].frameEdgeIndex;
                if (frame && frame.frame && frame.frame[frameEdgeIndex]) {
                    profile = this.profilesService.getProfile(
                        frame.frame[frameEdgeIndex].profileId
                    );
                    id = frame.frame[frameEdgeIndex].profileId;
                    finWidth =
                        frame.frame[frameEdgeIndex].finWidth != null
                            ? frame.frame[frameEdgeIndex].finWidth
                            : profile.finWidth;
                    finWidths = this.profilesService.getFinWidths(profile);
                    monoblockFrames = this.profilesService.getMonoblockFrames(
                        side.sideSimple,
                        conf
                    );

                    if (profile.options && profile.options.indexOf('renovation_frame') > -1) {
                        type = 'reno';
                    } else if (conf.System && conf.System.type_frame === 'monoblock') {
                        type = 'monoblock';
                    }
                }

                this.isRenoFrames = this.isRenoFrames || type === 'reno';
                this.isMonoblockFrames = this.isMonoblockFrames || type === 'monoblock';

                return {
                    type,
                    profile,
                    side,
                    id,
                    finWidth,
                    finWidths,
                    monoblockFrames,
                    maxFinWidth: Math.max(...finWidths),
                };
            });
            let oneFinWidth = true;
            let oneMaxFinWidth = this.frameSides[0].maxFinWidth;
            const finWidth = this.frameSides[0].finWidth;
            for (const side of this.frameSides) {
                if (side.maxFinWidth < oneMaxFinWidth) {
                    oneMaxFinWidth = side.maxFinWidth;
                }
                if (finWidth !== side.finWidth) {
                    oneFinWidth = false;
                }
            }
            this.oneFinWidth = oneFinWidth;
            this.oneMaxFinWidth = oneMaxFinWidth;
            this.isOneFrameProfile =
                this.frameSides.map(side => side.id).filter((v, i, a) => a.indexOf(v) === i).length
                === 1;
            // vm.oneFinWidth = vm.oneFinWidth && vm.isOneFrameProfile;
        }
    }

    onChangeRenoDimensions(index: null | number = null) {
        if (
            this.config().IccConfig.Configurators.window.maxFinWidth
            && index != null
            && this.frameSides[index].finWidth > this.frameSides[index].maxFinWidth
        ) {
            this.frameSides[index].finWidth = this.frameSides[index].maxFinWidth;
        }
        this.profilesService.setFrameFinWidths(
            this.configurationsService.conf.Current,
            this.frameSides.map(side => (side.type === 'reno' ? side.finWidth : null))
        );
        this.dimensionsService.changedDimensions();
    }

    onChangeOneFinWidth() {
        if (
            this.config().IccConfig.Configurators.window.maxFinWidth
            && this.frameSides[0].finWidth > this.oneMaxFinWidth
        ) {
            this.frameSides[0].finWidth = this.oneMaxFinWidth;
        }
        this.frameSides.forEach(side => {
            side.finWidth = this.frameSides[0].finWidth;
        });
        this.profilesService.setFrameFinWidths(
            this.configurationsService.conf.Current,
            this.frameSides.map(side => (side.type === 'reno' ? side.finWidth : null))
        );
        this.onChangeRenoDimensions();
    }

    changedDoorSide(event: 'W_L' | 'W_R' | 'Z_L' | 'Z_R') {
        this.doorSide = event;
        this.loadLayouts();
        const doorSidesArray = this.doorSide.split('_');
        this.layoutService.selectDefaultLayout(doorSidesArray[0], doorSidesArray[1]);
        this.loadLayouts();
    }

    filterLayoutsForDoorSide(layout: any) {
        if (this.configurationsService.conf.Current.type === 'door' && this.canChangeDoorSide) {
            const doorSideWZ =
                (layout.Neighbours && layout.Neighbours.middle.some(s => s.type === 'DOA') || layout.Sashes && layout.Sashes.some(s => s.type === 'DOA'))
                    ? 'Z'
                    : 'W';
            const doorSideLR =
                (layout.Neighbours
                    && layout.Neighbours.middle.some(
                        s => s.type === 'DRA' && s.handle_position === 'R' || s.type === 'DOA' && s.handle_position === 'L'
                    ) || layout.Sashes
                    && layout.Sashes.some(
                        s => s.type === 'DRA' && s.handle_position === 'R' || s.type === 'DOA' && s.handle_position === 'L'
                    ))
                    ? 'L'
                    : 'R';
            return (
                this.config().IccConfig.Configurators.showMismatchedVariants
                || (!this.doorSide || this.doorSide === doorSideWZ + '_' + doorSideLR)
            );
        } else {
            return true;
        }
    }
}
