import { Component, Inject, OnInit } from '@angular/core';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AppConfigFactory, APP_CONFIG, ConfigurationsService, ConfiguratorsDataService } from '@icc/common';
import { TranslateService } from '@ngx-translate/core';
import { ReturnButton, SharedFacade } from '@icc/configurator/shared';
import { currencyExchange, Issue, OfferInfo } from '@icc/helpers';
import { Currency, TaxRate } from '@icc/common/data-types/PricesData';
import { InitService } from "./../../../../../../apps/configurator/src/app/init.service";
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'icc-client-info-dialog',
  templateUrl: './client-info-dialog.component.html',
  styleUrls: ['./client-info-dialog.component.scss']
})
export class ClientInfoDialogComponent implements OnInit {
    constructor(
        public dialogRef: MatDialogRef<ClientInfoDialogComponent>,
        private translateService: TranslateService,
        private configurationsService: ConfigurationsService,
        private configuratorsDataService: ConfiguratorsDataService,
        private initService: InitService,
        private sharedFacade: SharedFacade,
        @Inject(APP_CONFIG) public config: AppConfigFactory,
        @Inject(MAT_DIALOG_DATA) public data: {
            issues: Issue[],
            actions: [],
            message: string;
            shopMessage: boolean;
            configuration;
            returnButton?: ReturnButton;
            configurationShortcode?: string;
        }
    ) { }
    private unsubscribe$ = new Subject<void>();

    clientInfoFormEnabled = false;

    taxRates: TaxRate[] = [];

    configurationShortcode: string;
    returnButton: ReturnButton;
    defaultNettoPrice: number;
    configuration;
    positionName: string;
    offerDurationInDays: number;
    name: string;
    fullName: string;
    email: string;
    phoneNumber: string;
    address: string;
    city: string;
    postCode: string;
    selectedVat: number;
    nettoPrice: number;
    quantity: number;
    discountPercent: number;
    discountCash: number;
    additionalServicesPrice: number;
    comment: string;
    customConstructionName: string;
    fullPrice: number;
    fullNettoPrice: number;
    vatPrice: number;
    currency: Currency = {};

    hiddenPrice = false;
    validatorForAutomatedAttacks = "";
    isDownloadPdfOnDiskCheckboxChecked = true;
    isDownloadPdfOnEmailCheckboxChecked = false;

    ngOnInit(): void {
        this.sharedFacade.currency$.pipe(takeUntil(this.unsubscribe$)).subscribe(currency => {
            this.currency = currency;
        });
        this.hiddenPrice = this.config().preset === 'b2c' && this.config().hidePricesInB2cConfigurator;
        this.taxRates = this.getTaxRates();
        this.configurationShortcode = this.data.configurationShortcode;
        this.configuration = this.data.configuration
        this.returnButton = this.data.returnButton;
        this.positionName = this.configuration.System && this.configuration.System.name;
        this.discountPercent = 0;
        this.selectedVat = Number(this.taxRates.find(tax => tax.default)?.value) || Number(this.taxRates[0]?.value) || 0;
        this.quantity = this.configurationsService.getQuantity();
        this.nettoPrice = currencyExchange(this.configuration.Price || 0, this.currency, true);
        this.defaultNettoPrice = this.nettoPrice;
        this.offerDurationInDays = 14;
        this.comment = this.translateService.instant('OFFER|Oferta ważna tylko i wyłącznie w przypadku pełnego zamówienia kompletu drzwiowego wraz z montażem');
        this.customConstructionName = this.configurationsService.getConstructionName();
        this.discountCash = 0;
        this.fullPrice = this.getFullPrice();
        this.fullNettoPrice = this.getFullNettoPrice();
        this.vatPrice = this.getVatPrice();
    }

    getTaxRates(): TaxRate[] {
        const taxRates = this.configuratorsDataService.data?.taxRates?.sort((a,b) => Number(a.value) - Number(b.value));

        if (!taxRates) {
            return;
        }

        taxRates.forEach((tax, index) => {
            taxRates[index].value = Number(tax.value);
        });

        return taxRates;
    }

    getNettoPrice(): number {
        return this.quantity * this.nettoPrice;
    }

    resetToDefaults() {
        this.nettoPrice = this.defaultNettoPrice;
        this.changedInputData("default");
    }

    getFullNettoPrice(): number {
        const nettoPrice = this.getNettoPrice();
        return this.parseToFixed((nettoPrice - this.discountCash + (this.additionalServicesPrice || 0)));
    }

    changedInputData(flag) {
        if (flag === 'discountCash') {
            this.discountStrategy(true);
        } else if (flag === 'discountPercent') {
            this.discountStrategy(false);
        } else if (flag === 'vat') {
            this.fullPrice = this.getFullPrice();
            this.vatPrice = this.getVatPrice();
        } else if (flag === 'fullPrice') {
            this.fullPriceStrategy(false);
        } else if (flag === 'fullNettoPrice') {
            this.fullPriceStrategy(true);
        } else {
            this.defaultInputsDataPath();
        }
    }
    parseToFixed(value){
        return Number(Number(value).toFixed(2));
    }
    fullPriceStrategy(isNetto: boolean) {
        if (isNetto) {
            this.fullPrice = this.parseToFixed(this.fullNettoPrice * (1 + (this.selectedVat / 100)));
        } else {
            this.fullNettoPrice = this.parseToFixed(this.fullPrice / (1 + (this.selectedVat / 100)));
        }
        this.vatPrice = this.parseToFixed(this.fullPrice - this.fullNettoPrice);
        const nettoToDiscount = this.parseToFixed(
            this.additionalServicesPrice
                ? this.fullNettoPrice - this.additionalServicesPrice
                : this.fullNettoPrice
        );

        const discountCash = (this.nettoPrice * this.quantity) - nettoToDiscount;

        this.discountCash =  this.parseToFixed(discountCash);

        this.discountPercent = this.parseToFixed(this.discountCash/(this.nettoPrice * this.quantity)*100);
    }
    discountStrategy(isCash?: boolean){
        if (isCash){
            this.discountPercent = this.parseToFixed(this.discountCash/this.getNettoPrice()*100);
        } else {
            this.discountCash = this.parseToFixed(this.getNettoPrice()/100*this.discountPercent);
        }
        this.fullPrice = this.getFullPrice();
        this.fullNettoPrice = this.getFullNettoPrice();
        this.vatPrice = this.getVatPrice();
    }
    defaultInputsDataPath(){
        this.discountPercent = this.parseToFixed(this.discountCash/this.nettoPrice*100);
        this.fullPrice = this.getFullPrice();

        if (this.discountPercent) {
            this.discountStrategy(true);
        }

        this.fullNettoPrice = this.getFullNettoPrice();
        this.vatPrice = this.getVatPrice();
    }
    getVatPrice(): number {
        return this.parseToFixed((this.selectedVat / 100) * this.getFullNettoPrice());
    }

    getFullPrice(): number {
        return this.parseToFixed(this.getVatPrice() + this.getFullNettoPrice());
    }

    getVatByValue(value: string) {
        const tax = this.taxRates.find(t => Number(t.value) === Number(value));
        return tax && tax.name || null;
    }

    getFormData(): OfferInfo {
        this.configurationsService.setConstructionName(this.customConstructionName);
        this.configurationsService.setQuantity(this.quantity);
        if (this.initService.options) {
            this.initService.options.quantity = Number(this.quantity);
            this.initService.options.userDescription = this.customConstructionName;
        }

        return {
            offerDurationInDays: this.offerDurationInDays,
            name: this.name,
            fullName: this.fullName,
            email: this.email,
            phoneNumber: this.phoneNumber,
            address: this.address,
            city: this.city,
            postCode: this.postCode,
            selectedVat: this.getVatByValue(this.selectedVat.toString()),
            vatPrice: this.getVatPrice(),
            nettoPriceOne: this.nettoPrice,
            nettoPrice: this.getNettoPrice(),
            fullNettoPrice: this.getFullNettoPrice(),
            quantity: this.quantity,
            discountPercent: this.discountPercent,
            additionalServicesPrice: this.additionalServicesPrice,
            comment: this.comment,
            customConstructionName: this.customConstructionName,
            fullPrice: this.getFullPrice(),
            positionName: this.positionName,
            isOfferWithCustomerDetailsAndDiscountSelected: this.clientInfoFormEnabled,
            isDownloadPdfOnEmailOptionSelected: this.isDownloadPdfOnEmailCheckboxChecked,
            isDownloadPdfOnDiskOptionSelected: this.isDownloadPdfOnDiskCheckboxChecked,
            validatorForAutomatedAttacks: this.validatorForAutomatedAttacks,
            currency: this.currency
        }
    }

    closeWithData() {
        if (this.validatorForAutomatedAttacks === "") {
            this.dialogRef.close({action: 'print', data: this.getFormData(), returnButton: this.returnButton});
        }
    }

    isCheckboxChecked(name: 'download-pdf') {
        if (name === 'download-pdf') return this.isDownloadPdfOnDiskCheckboxChecked;
    }
    isFieldDisabled(name: 'email' | 'print-button') {
        if (name === 'email') return !this.isDownloadPdfOnEmailCheckboxChecked;
        if (name === 'print-button') return this.isDownloadPdfOnDiskCheckboxChecked === false && this.isDownloadPdfOnEmailCheckboxChecked === false;
    }

    toggleCheckbox(checkboxName: 'download-pdf' | 'pdf-on-email', event: MatCheckbox) {
        if (checkboxName === 'download-pdf' && event.checked) this.isDownloadPdfOnDiskCheckboxChecked = true;
        if (checkboxName === 'download-pdf' && !event.checked) this.isDownloadPdfOnDiskCheckboxChecked = false;
        if (checkboxName === 'pdf-on-email' && event.checked) this.isDownloadPdfOnEmailCheckboxChecked = true;
        if (checkboxName === 'pdf-on-email' && !event.checked) this.isDownloadPdfOnEmailCheckboxChecked = false;
    }

    getButtonName() {
        if (this.clientInfoFormEnabled) {
            return this.translateService.instant('INTERFACE|Generuj ofertę');
        } else {
            return this.translateService.instant('INTERFACE|Generuj PDF');
        }
    }
}
