import { Component, OnInit, Type, Inject } from '@angular/core';
import { _, PageComponent, SharedFacade, ICC_PAGE_DATA } from '@icc/configurator/shared';
import { WindowFacade } from '../+state/window.facade';
import { iccListItem } from '@icc/configurator/ui';
import { Sash } from '@icc/window';

interface iccMuntinLayoutItem extends iccListItem {
    v?: number;
    h?: number;
    s?: number;
}

@Component({
    selector: 'icc-muntins-layouts',
    templateUrl: './muntins-layouts.component.html',
    styleUrls: ['./muntins-layouts.component.scss'],
})
export class MuntinsLayoutsComponent extends PageComponent implements OnInit {
    public title = _('WINDOW|Wybierz układ szprosów');
    public options = [];

    layouts: iccMuntinLayoutItem[] = [
        { id: 1, title: '0_0', imageUrl: '/application/dist/web/img/bar-layouts/0_0.gif', v: 0, h: 0 },
        { id: 2, title: '0_1', imageUrl: '/application/dist/web/img/bar-layouts/0_1.gif', v: 0, h: 1 },
        { id: 3, title: '0_1_b', imageUrl: '/application/dist/web/img/bar-layouts/0_1_b.gif', s: 0 },
        { id: 4, title: '0_2', imageUrl: '/application/dist/web/img/bar-layouts/0_2.gif', v: 0, h: 2 },
        { id: 5, title: '1_0', imageUrl: '/application/dist/web/img/bar-layouts/1_0.gif', v: 1, h: 0 },
        { id: 6, title: '1_1', imageUrl: '/application/dist/web/img/bar-layouts/1_1.gif', v: 1, h: 1 },
        { id: 7, title: '1_1_b', imageUrl: '/application/dist/web/img/bar-layouts/1_1_b.gif', s: 1 },
        { id: 8, title: '1_1_c', imageUrl: '/application/dist/web/img/bar-layouts/1_1_c.gif', s: 2 },
        { id: 9, title: '1_2', imageUrl: '/application/dist/web/img/bar-layouts/1_2.gif', v: 1, h: 2 },
        { id: 10, title: '1_3', imageUrl: '/application/dist/web/img/bar-layouts/1_3.gif', v: 1, h: 3 },
        { id: 11, title: '2_1', imageUrl: '/application/dist/web/img/bar-layouts/2_1.png', v: 2, h: 1 },

    ];

    constructor(@Inject(ICC_PAGE_DATA) private pageData: any,private windowFacade: WindowFacade, private sharedFacade: SharedFacade) {
        super();
    }
    selectedMuntinLayout(item: iccMuntinLayoutItem) {
        const layout = this.layouts.find(f => Number(f.id) === Number(item.id));
        this.sharedFacade.closePage(layout);
    }

    ngOnInit() {}
}
