import { InfoService } from '@icc/helpers';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { ConfiguratorPartialState, OpenMessage, DismissMessage } from '@icc/configurator/shared';
import { configuratorQuery } from './+state/root.selectors';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '@icc/configurator/ui';

@Injectable()
export class ConfiguratorInfoService extends InfoService {

    openedMessage$ = this.store.select(configuratorQuery.getLastMessage);
    messages$ = this.store.select(configuratorQuery.getAllMessages);

    constructor (private store: Store<ConfiguratorPartialState> , public dialog: MatDialog) {
        super();
    }

    /**
     * Otwiera modal informacyjny.
     *
     * TODO
     *
     * @return {Promise} Promise
     */
    openInfoModal({
        template,
        message,
        title,
        footer = false,
        data = {},
        ownTemplateUrl,
        issue
    }: {
        template?: string;
        message: string;
        title: string;
        footer?: boolean;
        data?: any;
        ownTemplateUrl?: string;
        issue?: boolean;
    }): PromiseLike<void> {
        this.store.dispatch(new OpenMessage({
            message,
            footer,
            issue
        }));
        return new Promise(resolve => {
            resolve();
        })
    }

    dismissInfoModal() {
        this.store.dispatch(new DismissMessage());
    }

    /**
     * Otwiera ostrzeżenie.
     *
     * TODO
     *
     * @param {string}   message Komunikat
     * @param {function} callback Callback
     * @return {Promise} Promise
     */
    openWarning(message: string, issue: boolean = false) {
        return this.openInfoModal({
            message,
            title: ('INTERFACE|Ostrzeżenie'),
            issue
        });
    }

    dismissWarning(message: string) {
        this.store.dispatch(new DismissMessage(message));
    }

    /**
     * Otwiera okno z pytaniem o potwierdzenie wykonania operacji
     * @param  {string}   title     Tytul okna
     * @param  {string}   question  Tresc komunikatu
     * @param  {Function} callback  Funkcja w przypadku potwierdzenia
     * @param  {Function} callback2 Funkcja w przypadku zaniechania
     *
     * TODO
     */
    openConfirmModal(title: string, question: string, options: {
        name: string,
        callback: () => void,
        accent?: boolean
    }[]) {
        const dialogRef = this.dialog.open(ConfirmDialogComponent, {
            minWidth: '250px',
            data: {
                title,
                question,
                options
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                result.callback();
            }
        });
    }

    /**
     * Ukrywa info.
     * TODO
     */
    hideInfo() {
    }

    /**
     * Wyświetla info.
     *
     * @param {string} text    Tekst
     * @param {string} style   Styl
     * @param {string} url     Url
     * @param {number} timeout Timeout
     *
     * TODO
     */
    showInfo(text: string, style: string | null, url?: string | (() => void) | null, timeout: number = 9000) {
    }
}
