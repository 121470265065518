import { Component, OnInit, Inject, HostListener, ElementRef, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { PageComponent } from '@icc/helpers';
import { _ } from '@icc/configurator/shared';
import {
    APP_CONFIG,
    AppConfigFactory,
    EventBusService,
    ConfigurationsService,
    DrawService,
    TranslateService,
    ProfilesService,
} from '@icc/common';
import { ExtensionsService } from '@icc/legacy/configurator/layout/extensions.service';
import { BrowserFramesService } from '@icc/legacy/configurator/layout/frames.service';
import { ProfilesModalService, WindowFacade } from '@icc/configurator/window';
import { SideProfile } from '@icc/window';

@Component({
    selector: 'icc-extensions-page',
    templateUrl: './extensions-page.component.html',
    styleUrls: ['./extensions-page.component.scss'],
})
export class ExtensionsPageComponent extends PageComponent implements OnInit {
    title = _('WINDOW|Edycja poszerzeń');
    fullscreen = true;

    drawOptions: any = null;
    drawButtons = {
        alignments: true,
        dimensions: false,
        extensions: true,
        fullscreen: false,
        mullions: true,
        profiles: false,
        sashes: true,

        toggle: (type: 'extensions' | 'fullscreen') => {
            if (type && typeof this.drawButtons[type] !== 'undefined') {
                this.drawButtons[type] = !this.drawButtons[type];
            }

            this.drawButtons.update();
        },

        update: () => {
            const conf = this.configurationsService.conf.Current;
            const defaultConf = this.configurationsService.conf.Default;
            if (conf) {
                this.drawOptions = Object.assign({}, this.drawService.options, {
                    dimensions: {
                        multiplier: 1,
                        type: this.drawButtons.fullscreen
                            ? 'extended'
                            : this.drawService.options.dimensions.type,
                    },
                    extensions: {
                        actions: {
                            onClick: (response: any) =>
                                ((response.extensions[response.index].side === 'bottom'
                                    && !conf.foundationProfile)
                                || response.extensions[response.index].side !== 'bottom')
                                && this.profilesModalService.changeProfile(conf, defaultConf, 'extension', {
                                    extension: response.extensions[response.index].extension,
                                    position: response.extensions[response.index].side,
                                }),
                            onHover: 'single-hover',
                        },
                        buttons: [
                            {
                                class: 'ico-sides',
                                positions: ['bottom'],
                                title: this.translateService.instant(
                                    'INTERFACE|Edytuj poszerzenia'
                                ),
                                condition: (response: any) =>
                                        (response.index === 'bottom'
                                            && !conf.foundationProfile
                                            && !(conf.System?.seperate_frame_and_sash_shortening
                                                && conf.doorSizes.frameShortening !== conf.doorSizes.shortening))
                                        || response.index !== 'bottom',
                                buttons: [
                                    {
                                        class: 'ico-plus',
                                        condition: (response: any) =>
                                            ((response.index === 'bottom'
                                                && conf.Shape.shape !== 'circle')
                                            || (response.index !== 'bottom'
                                                && conf.Shape.shape === 'rect'
                                                && !conf.hasRoller)) && this.isExtensionAvailable('bottom'),
                                        onClick: (response: any) =>
                                            this.profilesModalService.selectExtensionAndPut(
                                                conf,
                                                response.index
                                            ),
                                        positions: ['bottom'],
                                        title: this.translateService.instant(
                                            'INTERFACE|Dodaj poszerzenie'
                                        ),
                                    },
                                    {
                                        class: 'ico-remove',
                                        condition: (response: any) => {
                                            const frameEdges = this.framesService.getFrameProfilesIndexesOnTheEdge(
                                                response.index,
                                                conf
                                            );
                                            return (
                                                ((response.index === 'bottom'
                                                    && conf.Shape.shape !== 'circle')
                                                    || (response.index !== 'bottom'
                                                        && conf.Shape.shape === 'rect'
                                                        && !conf.hasRoller))
                                                && frameEdges
                                                && conf.SideProfiles.filter(sp =>
                                                    sp.framesId.some(f =>
                                                        frameEdges.some(
                                                            fE =>
                                                                f.id === fE.frameId
                                                                && f.edges.includes(
                                                                    fE.frameEdgeIndex
                                                                )
                                                        )
                                                    )
                                                ).length
                                            );
                                        },
                                        onClick: (response: any) =>
                                            this.extensionsService.removeExtensionFromSide(
                                                conf,
                                                response.index
                                            ),
                                        positions: ['bottom'],
                                        title: this.translateService.instant(
                                            'INTERFACE|Usuń poszerzenie'
                                        ),
                                    },
                                ],
                            },

                            {
                                class: 'ico-sides',
                                positions: ['top'],
                                title: this.translateService.instant(
                                    'INTERFACE|Edytuj poszerzenia'
                                ),
                                condition: (response: any) =>
                                        (response.index === 'bottom'
                                            && !conf.foundationProfile
                                            && !(conf.System?.seperate_frame_and_sash_shortening
                                                && conf.doorSizes.frameShortening !== conf.doorSizes.shortening))
                                        || response.index !== 'bottom',
                                buttons: [
                                    {
                                        class: 'ico-plus',
                                        condition: (response: any) =>
                                            ((response.index === 'bottom'
                                                && conf.Shape.shape !== 'circle')
                                            || (response.index !== 'bottom'
                                                && conf.Shape.shape === 'rect'
                                                && !conf.hasRoller))
                                        && this.isExtensionAvailable('top'),
                                        onClick: (response: any) =>
                                            this.profilesModalService.selectExtensionAndPut(
                                                conf,
                                                response.index
                                            ),
                                        positions: ['top'],
                                        title: this.translateService.instant(
                                            'INTERFACE|Dodaj poszerzenie'
                                        ),
                                    },
                                    {
                                        class: 'ico-remove',
                                        condition: (response: any) => {
                                            const frameEdges = this.framesService.getFrameProfilesIndexesOnTheEdge(
                                                response.index,
                                                conf
                                            );
                                            return (
                                                ((response.index === 'bottom'
                                                    && conf.Shape.shape !== 'circle')
                                                    || (response.index !== 'bottom'
                                                        && conf.Shape.shape === 'rect'
                                                        && !conf.hasRoller))
                                                && frameEdges
                                                && conf.SideProfiles.filter(sp =>
                                                    sp.framesId.some(f =>
                                                        frameEdges.some(
                                                            fE =>
                                                                f.id === fE.frameId
                                                                && f.edges.includes(
                                                                    fE.frameEdgeIndex
                                                                )
                                                        )
                                                    )
                                                ).length
                                            );
                                        },
                                        onClick: (response: any) =>
                                            this.extensionsService.removeExtensionFromSide(
                                                conf,
                                                response.index
                                            ),
                                        positions: ['top'],
                                        title: this.translateService.instant(
                                            'INTERFACE|Usuń poszerzenie'
                                        ),
                                    },
                                ],
                            },

                            {
                                class: 'ico-sides',
                                positions: ['left', 'right'],
                                title: this.translateService.instant(
                                    'INTERFACE|Edytuj poszerzenia'
                                ),
                                condition: (response: any) =>
                                        (response.index === 'bottom'
                                            && !conf.foundationProfile
                                            && !(conf.System?.seperate_frame_and_sash_shortening
                                                && conf.doorSizes.frameShortening !== conf.doorSizes.shortening))
                                        || response.index !== 'bottom',
                                buttons: [
                                    {
                                        class: 'ico-plus',
                                        condition: (response: any) =>
                                            ((response.index === 'bottom'
                                                && conf.Shape.shape !== 'circle')
                                            || (response.index !== 'bottom'
                                                && conf.Shape.shape === 'rect'
                                                && !conf.hasRoller))
                                                && this.isExtensionAvailable('side'),
                                        onClick: (response: any) =>
                                            this.profilesModalService.selectExtensionAndPut(
                                                conf,
                                                response.index
                                            ),
                                        positions: ['left', 'right'],
                                        title: this.translateService.instant(
                                            'INTERFACE|Dodaj poszerzenie'
                                        ),
                                    },
                                    {
                                        class: 'ico-remove',
                                        condition: (response: any) => {
                                            const frameEdges = this.framesService.getFrameProfilesIndexesOnTheEdge(
                                                response.index,
                                                conf
                                            );
                                            return (
                                                ((response.index === 'bottom'
                                                    && conf.Shape.shape !== 'circle')
                                                    || (response.index !== 'bottom'
                                                        && conf.Shape.shape === 'rect'
                                                        && !conf.hasRoller))
                                                && frameEdges
                                                && conf.SideProfiles.filter(sp =>
                                                    sp.framesId.some(f =>
                                                        frameEdges.some(
                                                            fE =>
                                                                f.id === fE.frameId
                                                                && f.edges.includes(
                                                                    fE.frameEdgeIndex
                                                                )
                                                        )
                                                    )
                                                ).length
                                            );
                                        },
                                        onClick: (response: any) =>
                                            this.extensionsService.removeExtensionFromSide(
                                                conf,
                                                response.index
                                            ),
                                        positions: ['left', 'right'],
                                        title: this.translateService.instant(
                                            'INTERFACE|Usuń poszerzenie'
                                        ),
                                    },
                                ],
                            },
                        ],
                    },
                    onInterfaceClick: () => {},
                });
                this.drawOptions.base.side = 'inner';
            }

            this.eventBusService.post({
                key: 'icc-redraw',
                value: null,
            });
        },
    };

    width$ = this.windowFacade.width$;
    height$ = this.windowFacade.height$;

    constructor(
        @Inject(APP_CONFIG) private config: AppConfigFactory,
        private eventBusService: EventBusService,
        private configurationsService: ConfigurationsService<'window'>,
        public drawService: DrawService,
        private profilesModalService: ProfilesModalService,
        private profileService: ProfilesService,
        private translateService: TranslateService,
        private framesService: BrowserFramesService,
        private extensionsService: ExtensionsService,
        private windowFacade: WindowFacade
    ) {
        super();
    }

    ngOnInit() {
        this.drawButtons.update();
    }

    isExtensionAvailable(side: 'bottom' | 'top' | 'side', conf = this.configurationsService.conf.Current) {
        return this.extensionsService.isExtensionAvailable(side, conf);
    }
}
