import { PriceFunc, PriceElemsData, PriceSegment, getConstrElems } from './Prices';
import { CustomPricesRecords } from '@icc/common/custom-price/CustomPrice';
import * as camelCase from 'camelcase';
import { Injectable, Inject } from '@angular/core';
import { PriceException } from './price-exception';
import {APP_CONFIG, AppConfig, AppConfigFactory} from '@icc/common/config';;
import { ColorsDefaultsService } from '@icc/common/colors/colors-defaults.service';
import { Common } from '@icc/common/Common';
import { core } from '@icc/common/Core';
import { IccSideColors } from '@icc/common/data-types';

@Injectable()
export class PriceColorsService {
    constructor(
        private colorsDefaultsService: ColorsDefaultsService,
        @Inject(APP_CONFIG) private config: AppConfigFactory
    ) {}

    /**
     * Zwraca najwyższą dopłatę procentową dla koloru.
     * @param  {object} colorGroups Grupy kolorów
     * @param  {object} color       Kolor
     * @param  {string} prop        Nazwa pola zawierającego dopłatę.
     * @return {number}             Dopłata procentowa dla koloru
     */
    getMaxColorGroupFactor({
        colorGroups,
        color,
        prop,
        system,
        wood,
        sash,
        sashId,
        divId,
        drawData,
        sashes,
        mullions,
        customPrice,
        side,
    }: {
        colorGroups;
        color;
        prop;
        system;
        wood;
        sash;
        sashId;
        divId;
        drawData;
        sashes;
        mullions;
        customPrice: CustomPricesRecords;
        side?;
    }) {
        let colorFactor = null;
        let colorFactorExtra = null;
        let colorFactorExtraProfile = 0;
        if (!Common.isArray(sash)) {
            return NaN;
        }
        if (!colorGroups) {
            colorGroups = [];
        }
        colorGroups = colorGroups.filter(
            el =>
                Common.isArray(el.systems)
                && el.systems.indexOf(system.id) > -1
                && el.target.indexOf('price') > -1
                && (side == null || el.sides.includes(side))
                && (system.type !== 'wood'
                    || (Common.isArray(el.woodTypes) && el.woodTypes.indexOf(wood.id) > -1))
        );
        if (Common.isArray(color.groups)) {
            for (let i = 0; i < color.groups.length; i++) {
                let group = core.fIdO(colorGroups, color.groups[i]) as any;
                if (color.groups[i] === 'RAL') {
                    group = this.getRALGroup(colorGroups);
                }
                if (Common.isDefined(group)) {
                    let customColorFactor = null;
                    if (Common.isObject(customPrice) && customPrice[group.id]) {
                        customColorFactor = customPrice[group.id].getPrice(prop);
                    }
                    if (customColorFactor != null && customColorFactor >= colorFactor) {
                        colorFactor = customColorFactor;
                    }
                    if (customColorFactor == null && Common.isDefined(group[prop])) {
                        if (group[prop] != null && parseFloat(group[prop]) >= colorFactor) {
                            colorFactor = parseFloat(group[prop]);
                        }
                    }
                    if (Common.isDefined(group.prices_extra)) {
                        const pricesExtra = group.prices_extra
                            ? core.parseJson(group.prices_extra)
                            : [];
                        sash.forEach(s => {
                            const colorSashFactor = pricesExtra
                                .filter(
                                    p => p.sashTypes && p.sashTypes.map(Number).indexOf(~~s) > -1
                                )
                                .reduce((prev, cur, index) => {
                                    if (Common.isObject(customPrice) && customPrice[group.id]) {
                                        const customColorSashFactor = customPrice[
                                            group.id
                                        ].getPrice('prices_extra', index, camelCase(prop));
                                        return prev < customColorSashFactor
                                            ? customColorSashFactor
                                            : prev;
                                    } else {
                                        return prev < cur[camelCase(prop)]
                                            ? cur[camelCase(prop)]
                                            : prev;
                                    }
                                }, 0);
                            if (colorSashFactor > colorFactorExtra) {
                                colorFactorExtra = colorSashFactor;
                            }
                        });
                    }
                    if (Common.isDefined(group.prices_extra_profiles)) {
                        const pricesExtraProfiles = group.prices_extra_profiles
                            ? core.parseJson(group.prices_extra_profiles)
                            : [];
                        const profilesIds = [];
                        sashId.forEach(s => {
                            const profilesForSash = this.getProfilesForSash(s, drawData, sashes);
                            profilesIds.push(
                                ...profilesForSash.filter(p => !profilesIds.includes(p))
                            );
                        });
                        divId.forEach(s => {
                            const profileForMullion = this.getProfileForMullion(s, mullions);
                            if (!profilesIds.includes(profileForMullion)) {
                                profilesIds.push(profileForMullion);
                            }
                        });
                        colorFactorExtraProfile += profilesIds.reduce((prev, profileId) => {
                            const profileSashFactor = pricesExtraProfiles
                                .filter(p => p.profiles.map(Number).indexOf(~~profileId) > -1)
                                .reduce((p, cur, index) => {
                                    let factor = cur[camelCase(prop)];
                                    if (Common.isObject(customPrice) && customPrice[group.id]) {
                                        const customProfileSashFactor = customPrice[
                                            group.id
                                        ].getPrice('prices_extra', index, camelCase(prop));
                                        if (customProfileSashFactor !== null) {
                                            factor = customProfileSashFactor;
                                        }
                                    }
                                    return p + factor;
                                }, 0);
                            return prev + profileSashFactor;
                        }, 0);
                    }
                }
            }
        }
        if (colorFactor == null && colorFactorExtra == null) {
            return NaN;
        }
        return (colorFactorExtra || colorFactor) + colorFactorExtraProfile;
    }

    /**
     * Zwraca dopłatę procentową za RAL.
     * @param  {array}  colorGroups   Grupy kolorów
     * @return {number}               Cena po dopłatach
     */
    getRALGroup(colorGroups) {
        colorGroups = core.objToArray(colorGroups);
        const RALGroup = core.fIdO<any>(colorGroups, 'ral', 'code');
        return RALGroup;
    }


    getColorFactor({
        NoPriceCauses,
        isAlushell,
        colorGroups,
        colorGroupsPrices,
        colors,
        system,
        wood,
        sash,
        sashId,
        divId,
        drawData,
        sashes,
        mullions,
        customPrice,
    }: {
        NoPriceCauses;
        isAlushell;
        colorGroups;
        colorGroupsPrices;
        colors;
        system;
        wood;
        sash;
        sashId;
        divId;
        drawData;
        sashes;
        mullions;
        customPrice?: CustomPricesRecords;
    }) {
        let colorFactor = 0
        if (!isAlushell) {
            colorFactor = this.getColorGroupsPrice(colorGroupsPrices, colors);
        }
        if (colorFactor || isAlushell) {
            colorFactor = this.getMaxColorGroupFactor({
                colorGroups,
                color: colors.frame[this.getColorSide(colors.frame, false, isAlushell)],
                prop: isAlushell? 'price_factor_out' : this.getColorFactorProperty(colors.frame, isAlushell),
                system,
                wood,
                sash,
                sashId,
                divId,
                drawData,
                sashes,
                mullions,
                customPrice,
                side: isAlushell ? 'F|A' : null,
            });

            if (colors.frame.outer?.id && colors.frame.inner?.id && colors.frame.outer?.id !== colors.frame.inner?.id) {
                const colorFactorOut = this.getMaxColorGroupFactor({
                    colorGroups,
                    color: colors.frame[this.getColorSide(colors.frame, true)],
                    prop: this.getColorFactorProperty(colors.frame),
                    system,
                    wood,
                    sash,
                    sashId,
                    divId,
                    drawData,
                    sashes,
                    mullions,
                    customPrice,
                });
                colorFactor =
                        colorFactorOut > colorFactor || isNaN(colorFactorOut)
                            ? colorFactorOut
                            : colorFactor;
            }
        }

        if (isNaN(colorFactor)) {
            NoPriceCauses.push('color factor');
        }
    
        return colorFactor;
    }
    getColorSide(colors: Partial<IccSideColors>, outer = false, isAlushell = false) {
        if (isAlushell) {
            return 'alushell';
        } else if (!colors.outer?.id && !colors.inner?.id) {
            return 'core';
        } else if (colors.outer?.id === colors.inner?.id) {
            return 'inner';
        } else if (!colors.outer?.id || colors.outer?.sides.includes('F|C')) {
            return 'inner';
        } else if (!colors.inner?.id || colors.inner?.sides.includes('F|C')) {
            return 'outer';
        } else if (outer) {
            return 'outer';
        }
        return 'inner';
    }
    
    getColorFactorProperty(colors: Partial<IccSideColors>, isAlushell = false) {
        
        if (colors.outer?.id === colors.inner?.id) {
            return 'price_factor_both';
        } else if (!colors.outer?.id || colors.outer?.sides.includes('F|C')) {
            return 'price_factor_in';
        }  else if (!colors.inner?.id || colors.inner?.sides.includes('F|C') || isAlushell) {
            return 'price_factor_out';
        }
        return 'price_factor_duo';
    }

    getColorGroupsPrice(colorGroupsPrices, colors) {
        return colorGroupsPrices ? colorGroupsPrices : []
            .filter(
                el =>
                    colors.frame.inner.groups
                    && colors.frame.inner.groups.indexOf(el.window_color_group_inner_id) > -1
                    && colors.frame.outer.groups
                    && colors.frame.outer.groups.indexOf(el.window_color_group_outer_id) > -1
            )
            .reduce((prev, curr) => {
                if (isNaN(prev) || Number(curr.price) > prev) {
                    prev = Number(curr.price);
                }
                return prev;
            }, NaN);
    }

    /**
     * Dopłata procentowa za kolor.
     *
     * @param {number} price         Cena wejściowa
     * @param {object} PriceElems    Składowe ceny
     * @param {array}  NoPriceCauses Powody braku wyceny
     * @param {array}  constrElems   Lista elementów konstrukcyjnych, do przeliczenia przez dopłatę.
     * @param {array}  colors        Kolory
     * @param {array}  colorGroups   Grupy kolorów
     * @param {number} sashesCount   Liczba skrzydeł w konstrukcji
     * @return {number} Cena z dopłatą
     */
    @PriceFunc({
        shortName: 'color',
        data: {
            colors: 'conf.Colors',
            colorGroups: 'data.windowColorGroups',
            colorGroupsPrices: 'data.windowColorGroupsPrices',
            sashesCount: 'conf.Sashes.length',
            system: 'conf.System',
            wood: 'conf.Wood',
            drawData: 'conf.drawData',
            sashes: 'conf.Sashes',
            customPrice: 'price.WindowColorGroup',
            mullions: 'conf.Mullions',
        },
    })
    suppColor(
        { PriceStack, NoPriceCauses }: PriceElemsData,
        {
            colors,
            colorGroups,
            colorGroupsPrices,
            sashesCount,
            system,
            wood,
            drawData,
            sashes,
            mullions,
            customPrice,
        }: {
            colors;
            colorGroups;
            colorGroupsPrices;
            sashesCount;
            system;
            wood;
            drawData;
            sashes;
            mullions;
            customPrice: CustomPricesRecords;
        }
    ): PriceSegment[] {
        const constrElems = getConstrElems(this.config().IccConfig, system, 'color');
        const priceSashes = PriceStack.filter(
            s => constrElems.indexOf('sashes') > -1 && s.type === 'sashes'
        );
        const divs = PriceStack.filter(
            s => constrElems.indexOf('dividers') > -1 && s.type === 'dividers'
        );
        const priceSegments: PriceSegment[] = [];
        const divFactors: any = [];
        if (!colorGroups || colorGroups.length === 0) {
            return [];
        }
        if (colorGroupsPrices == null) {
            colorGroupsPrices = [];
        }
        const colorFactorDefault = this.getColorFactor({
            NoPriceCauses,
            isAlushell: false,
            colorGroups,
            colorGroupsPrices,
            colors,
            system,
            wood,
            sash: [],
            sashId: [],
            divId: [],
            drawData,
            sashes,
            mullions,
            customPrice,
        });
        priceSashes.forEach(s => {
            const colorFactor = this.getColorFactor({
                NoPriceCauses,
                isAlushell: false,
                colorGroups,
                colorGroupsPrices,
                colors,
                system,
                wood,
                sash: s.data.types,
                sashId: s.data.sashIds,
                divId: s.data.divIds || [],
                drawData,
                sashes,
                mullions,
                customPrice,
            });
            if (s.data && s.data.divIds) {
                divFactors.push({
                    divs: s.data.divIds || [],
                    factor: colorFactor,
                });
            }
            priceSegments.push({
                type: 'color',
                baseValue: !isNaN(colorFactor) ? 1 + colorFactor / 100 : null,
                value: !isNaN(colorFactor) ? 1 + colorFactor / 100 : null,
                valueType: 'percent',
                data: {},
                to: s.id,
            });
        });
        divs.forEach(d => {
            const factor = divFactors.filter(df => df.divs.indexOf(d.data.divId) > -1);
            if (factor.length > 0) {
                priceSegments.push({
                    type: 'color',
                    baseValue: !isNaN(factor[0].factor) ? 1 + factor[0].factor / 100 : null,
                    value: !isNaN(factor[0].factor) ? 1 + factor[0].factor / 100 : null,
                    valueType: 'percent',
                    data: {},
                    to: d.id,
                });
            }
        });
        priceSegments.push({
            type: 'color',
            baseValue: !isNaN(colorFactorDefault) ? 1 + colorFactorDefault / 100 : null,
            value: !isNaN(colorFactorDefault) ? 1 + colorFactorDefault / 100 : null,
            valueType: 'percent',
            data: {},
            to: constrElems.filter(s => s !== 'sashes' && s !== 'dividers'),
        });
        return priceSegments;
    }

    /**
     * Dopłata za typ drzewa
     * @param  {Number} price           Cena
     * @param  {Object} PriceElems      Wycena
     * @param  {Array} constrElems      Elementy konstrukcyjne
     * @param  {Object} wood            Drzewo
     * @return {Number}                 Cena
     */
    @PriceFunc({
        shortName: 'wood',
        data: {
            wood: 'conf.Wood',
            system: 'conf.System',
            customPrice: 'price.WoodType',
        },
    })
    suppWoodType(
        { PriceElems }: PriceElemsData,
        { wood, system, customPrice }: { wood; system; customPrice: CustomPricesRecords }
    ): PriceSegment[] {
        let factors = 1;
        let woodCharge = 0;
        if (system.type !== 'wood') {
            return [];
        }
        if (Common.isObject(wood)) {
            if (Common.isObject(customPrice) && customPrice[wood.id]) {
                woodCharge = customPrice[wood.id].getPrice('charge');
            } else {
                woodCharge = parseFloat(wood.charge);
            }
            PriceElems.wood = {
                id: wood.id,
                factor: woodCharge,
                name: wood.name,
            };
            factors *= 1 + woodCharge / 100;
        }

        return [
            {
                type: 'wood',
                baseValue: factors,
                value: factors,
                valueType: 'percent',
                data: {
                    id: wood.id,
                    name: wood.name,
                },
            },
        ] as PriceSegment[];
    }

    private getProfilesForSash(sashId, drawData, sashes) {
        const profilesForSash = [];
        const sashData = drawData.sash.find(o => o.sashId === sashId);
        if (sashData) {
            sashData.parallel.poly.forEach((frameIndex, sashIndex) => {
                if (Common.isNumber(frameIndex)) {
                    const frameProfileId = sashData.parallel.profiles[frameIndex];
                    if (!profilesForSash.includes(frameProfileId)) {
                        profilesForSash.push(frameProfileId);
                    }
                }
            });
            const sash = sashes.find(s => s.id === sashId);
            if (sash.type.type !== 'F') {
                ['left', 'right', 'top', 'bottom']
                    .filter(side => sash.frame[side])
                    .forEach(side => {
                        const sashProfileId = sash.frame[side].profileId;
                        if (!profilesForSash.includes(sashProfileId)) {
                            profilesForSash.push(sashProfileId);
                        }
                    });
            }
        }

        return profilesForSash;
    }

    private getProfileForMullion(mullionId, mullions) {
        const mullion = mullions.find(m => m.id === mullionId);
        return mullion.profileId;
    }
}
