import { Component, OnInit, Inject } from '@angular/core';
import { PageComponent } from '@icc/helpers';
import { _, SharedFacade, ICC_PAGE_DATA } from '@icc/configurator/shared';
import { iccListItem, iccListTab } from '@icc/configurator/ui';
import { IccAccessoryColor, IccAccessoryAccessory } from '@icc/common/data-types';
import {
    isNotNullOrUndefined,
    ModalService,
} from '@icc/configurator/shared';
import { HardwareTypeOptionsPageComponent } from '../hardware-type-options-page/hardware-type-options-page.component';
import { ConfigurationsService, ConfiguratorsDataService, EventBusService, TranslateService } from '@icc/common';
import { KeysService } from '../cylinders-page/keys.service';

@Component({
  selector: 'icc-hardware-types-page',
  templateUrl: './hardware-types-page.component.html',
  styleUrls: ['./hardware-types-page.component.scss']
})
export class HardwareTypesPageComponent extends PageComponent implements OnInit {
    public title = _('DOOR|Wybierz typ osprzętu');
    public options = [];
    public selected: any = null;

    types: iccListItem[] = [];
    tabs: iccListTab[] = [];
    selectedTypeId = 0;
    constructor(
        private sharedFacade: SharedFacade,
        private eventBusService: EventBusService,
        @Inject(ICC_PAGE_DATA)
        private pageData: {
            title: string;
            types: IccAccessoryAccessory[];
            hardwareColors?: IccAccessoryColor[];
            selType : number;
            selColor: IccAccessoryColor;
            tabs?: iccListTab[];
            canOpenOptions?: (item: IccAccessoryAccessory) => boolean;
        },
        private modalService: ModalService,
        private translateService: TranslateService,
        private keysService: KeysService,
        private configurationsService: ConfigurationsService<'door'>,
        private configuratorsDataService: ConfiguratorsDataService,
    ) {
        super();
        this.title = pageData.title;
        this.eventBusService.post({ key: 'processDependencies', value: null });
    }

    ngOnInit() {
        this.types = this.pageData.types.map(type => {
            const selectedColor = this.getSelectedColor(type);
            return {
                id: Number(type.id),
                title: type.name,
                description: type.description,
                imageUrl: this.getImageUrl(type, selectedColor),
                colors: this.pageData.hardwareColors == null ? [] :  
                    type.colors_ids
                    && type.colors_ids
                        .map(c =>
                            this.pageData.hardwareColors.find(color => Number(c) === Number(color.id))
                        )
                        .filter(isNotNullOrUndefined)
                        .map(color => ({
                            id: Number(color.id),
                            name: color.name,
                            color: color.color,
                            colorImg: color.color_img,
                        })) || [],
                selectedColor,
                showColorSelection: false,
                tabs: this.pageData.tabs && this.getTabs(type),
            };
        });
        this.selectedTypeId = Number(this.pageData.selType);

        const dependencies = this.configuratorsDataService.data.dependencies || [];
        const blockedItems = this.configurationsService.conf.Current.Dependencies.filter(
            (p) => p.type === 'blockade_to_configuration'
        );
        if (
            Array.isArray(dependencies) &&
            blockedItems.length > 0 &&
            Array.isArray(blockedItems) &&
            dependencies.length > 0
        ) {
            this.markAsDisabled(blockedItems, dependencies, 'pull');
            this.markAsDisabled(blockedItems, dependencies, 'accessory');
        }
    }

    markAsDisabled(blockedItems, dependencies, accessoryType) {
        dependencies.forEach(dependency => {
            blockedItems.forEach(blocked => {
                if (dependency && blocked && dependency.id === blocked.id) {
                    dependency.conditions.forEach(condition => {
                        if (condition?.type === accessoryType) {
                            const type = this.types.find((p) => p.id === Number(condition?.value));
                            if (type && condition?.value) {
                                this.disablePullAndAddDescription(condition, accessoryType);
                            }
                        }
                    })
                }
            });
        });
    }

    disablePullAndAddDescription(condition, accessoryType: 'pull' | 'accessory') {
        this.types.find((p) => p.id === Number(condition?.value)).disabled = true;

        switch (accessoryType) {
            case 'pull': {
                this.types.find((p) => p.id === Number(condition?.value)).description = this.translateService.instant('ACCESSORY|Nie można dodać pochwytu do konstrukcji');
                break;
            }
            case 'accessory': {
                this.types.find((p) => p.id === Number(condition?.value)).description = this.translateService.instant('ACCESSORY|Osprzęt nie może zostać dodany do konstrukcji');
                break;
            }
        }
    }

    private getImageUrl(hardware: IccAccessoryAccessory, selectedColor: number): string {
        return hardware.colors_imgs && hardware.colors_imgs[selectedColor]
            ? '/files/windowhandlescolorswindowaccessory/' + hardware.colors_imgs[selectedColor]
            : '/files/windowaccessory/' + hardware.img;
    }

    selectType(item: iccListItem) {
        const type = this.pageData.types.find(f => Number(f.id) === Number(item.id));

        if(type.type === 'cylinder') {
            this.keysService.resetQuantityIfKeysAreDifferent(type);
        }

        if (type && type.id && (typeof this.pageData.canOpenOptions !== 'function' || this.pageData.canOpenOptions(type))) {
            this.modalService.open({
                pageComponent: HardwareTypeOptionsPageComponent,
                resolve:{
                    selectedType: type,
                    hardwareColors: this.pageData.hardwareColors ? this.pageData.hardwareColors : [],
                    selColor: this.pageData.selColor,
                }
            }).result.then((result) => {
                this.sharedFacade.closePage(Object.assign(result, {
                    type
                }));
            });
        } else {
            this.sharedFacade.closePage({
                type
            });
        }
    }

    private getTabs(type) {
        if (type.type === 'plate') {
            this.tabs = this.pageData.tabs.map(tab => ({
                    id: tab.toString(),
                    name: this.getPlateNames(tab)
            }));
            return [type.plate_type];
        } else if (type.type === 'handle' && type.handle_type === 'pull') {
            this.tabs = this.pageData.tabs.map((tab) => ({
                id: tab.id,
                name: tab.name,
            }));
            return [type.window_accessories_category_id];
        } else {
            return [];
        }
    }

    private getPlateNames(tab) {
        const tabNames = {
            long: this.translateService.instant('DOOR|Długi'),
            divided: this.translateService.instant('DOOR|Dzielony'),
            bottom: this.translateService.instant('DOOR|Dolny'),
        };
        for (const [key, name] of Object.entries(tabNames)) {
            if(key === tab) {
                return name;
            }
        }
    }

    private getSelectedColor(type) {
        return (type.colors_ids
            && ((this.pageData.selColor
                && Number(
                    type.colors_ids.find(
                        id => Number(this.pageData.selColor.id) === Number(id)
                    )
                ))
                || Number(type.colors_ids[0])))
        || 0;
    }
}
