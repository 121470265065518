<mat-card>
    <mat-card-content>
        <icc-select-box [title]="('COLOR|Kolor zewnętrzny' | translate)"
            [description]="getOuterColor()?.name"
            [color]="'#' + getOuterColor()?.color"
            [imageUrl]="'/files/color/' + getOuterColor()?.color_img"
            [buttonText]="'INTERFACE|Zmień' | translate" [colorSelect]="true"
            (select)="openModalColor('outer')"></icc-select-box>
        <icc-select-box [title]="('COLOR|Kolor wewnętrzny' | translate)"
            [description]="getInnerColor()?.name"
            [color]="'#' + getInnerColor()?.color"
            [imageUrl]="'/files/color/' + getInnerColor()?.color_img"
            [buttonText]="'INTERFACE|Zmień' | translate" [colorSelect]="true"
            (select)="openModalColor('inner')"></icc-select-box>
        <icc-select-box *ngIf="showCore" [title]="('WINDOW|Rdzeń' | translate)"
            [description]="config.Colors.frame.core?.name"
            [color]="'#' + config.Colors.frame.core?.color"
            [imageUrl]="'/files/color/' + config.Colors.frame.core?.color_img"
            [buttonText]="'INTERFACE|Zmień' | translate" [colorSelect]="true"
            (select)="openModalColor('core')"></icc-select-box>
    </mat-card-content>
    <mat-card-actions>
        <button *ngIf="agreeButton" class="agree-button" mat-raised-button color="primary" (click)="close()">{{ 'INTERFACE|Zatwierdź' | translate }}</button>
    </mat-card-actions>
</mat-card>

