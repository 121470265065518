import { Injectable } from '@angular/core';
import {
    ConfiguratorsDataService,
    ConfigurationsService,
    EventBusService,
    ProfilesService,
    WindowActiveConfiguration,
} from '@icc/common';
import { IccColor, IccColorGroup } from '@icc/common/data-types';
import { _ } from '@icc/configurator/shared';
import { ModalService } from '@icc/helpers';
import { ColorsPageComponent } from '../colors-page/colors-page.component';
import { LoadedConfiguratorsDataValue } from '@icc/common/configurators/configurators-data.service';
import { DoorPortalsService } from './door-portals.service';

@Injectable({
    providedIn: 'root',
})
export class DoorPortalsColorsService {
    colors: IccColor[] = [];
    colorGroups: any[] = [];
    data: LoadedConfiguratorsDataValue | null = null;

    constructor(
        private configuratorsDataService: ConfiguratorsDataService,
        private configurationsService: ConfigurationsService<'window'>,
        private eventBusService: EventBusService,
        private profilesService: ProfilesService,
        private modalService: ModalService,
        private doorPortalsService: DoorPortalsService
    ) {
        eventBusService.subscribe<LoadedConfiguratorsDataValue>(
            'loadedConfiguratorsData',
            (data) => {
                if (
                    data.activeConfiguration &&
                    WindowActiveConfiguration.is(data.activeConfiguration)
                ) {
                    this.loadColors(
                        data.value,
                        data.activeConfiguration as WindowActiveConfiguration
                    );
                }
            }
        );
        
        eventBusService.subscribe('setConstructionColor', data => {
            if (WindowActiveConfiguration.is(data.activeConfiguration)) {
                this.setMatchingColorsIfDefault(
                    data.activeConfiguration as WindowActiveConfiguration
                );
            }
        });
    }

    getPortalColors(conf: WindowActiveConfiguration) {
        const matchingGroups = this.getPortalColorGroups(conf);
        const result = this.colors.filter((p) =>
            matchingGroups?.some((x) => p.groups?.includes(x.id))
        );
        return result;
    }

    getPortalColorGroups(conf: WindowActiveConfiguration) {
        if (conf.doorPortal && conf.doorPortal?.id) {
            const portal = this.doorPortalsService.getPortalById(conf.doorPortal?.id);
            const colorGroup = portal?.priceLevelColorGroups;
            const colorGroupOut = portal?.priceLevelColorGroupsOut;
            const portalId = portal?.id;
            if (
                this.profilesService.profilesPrices &&
                portalId &&
                this.profilesService.profilesPrices[portalId]
            ) {
                return this.colorGroups.filter(
                    (c) =>
                        colorGroup?.includes(Number(c.id)) ||
                        colorGroupOut?.includes(Number(c.id))
                );
            }
        }
    }

    getFilteredPortalColorGroups(conf = this.configurationsService.conf.Current): IccColorGroup[] {
        const colorGroupIds = this.getPortalColorGroups(conf);
        return colorGroupIds || [];
    }

    getSelectedPortalColor(conf = this.configurationsService.conf.Current) {
        return (conf.doorPortal && conf.doorPortal.selectedColor) || null;
    }

    openModal(conf = this.configurationsService.conf.Current) {
        return this.modalService
            .open({
                pageComponent: ColorsPageComponent,
                resolve: {
                    colors: () => this.getPortalColors(conf),
                    selectedColor: () => conf.doorPortal?.selectedColor,
                    colorGroups: () => this.getPortalColorGroups(conf),
                },
            })
            .result.then((data) => {
                if (data) {
                    this.setColor(data.color, conf);
                }
            });
    }

    setMatchingColorsIfDefault(conf = this.configurationsService.conf.Current) {
        const pauseId = this.eventBusService.pause(['setDoorPortalColor']);
        if (conf.doorPortal && conf.doorPortal?.id) {
            this.setDefaultColor(conf);
        }
        this.eventBusService.resume(['setDoorPortalColor'], pauseId);
    }

    setDefaultColor(conf = this.configurationsService.conf.Current) {
            const defaultColor = this.getDefaultPortalColor(conf);
            this.setColor(defaultColor);
    }

    getDefaultPortalColor(conf = this.configurationsService.conf.Current) {
        const filteredColors = this.getPortalColors(conf);
        const selectedSashOuterColor = conf.Colors.sash.outer;
        const defaultPortalColor =
            (selectedSashOuterColor &&
                filteredColors.find((c) => Number(c.id) === Number(selectedSashOuterColor.id))) ||
            filteredColors[0];
        return defaultPortalColor;
    }

    setColor(color: IccColor, conf = this.configurationsService.conf.Current) {
        if (conf.doorPortal) {
            conf.doorPortal.selectedColor = color;

            this.eventBusService.post({
                key: 'setDoorPortalColor',
                value: { color: color && color?.id }
            });
        }
    }

    private loadColors(data: LoadedConfiguratorsDataValue, conf: WindowActiveConfiguration) {
        this.data = data;
        if (!conf) {
            return;
        }
        if (data.windowColorsAll && data.windowColorGroups) {
            this.colors = data.windowColorsAll || [];
            this.colorGroups = data.windowColorGroups || [];
        }
    }
}
