import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { ModalService, SharedFacade, StepComponent, _ } from '@icc/configurator/shared';
import { DimensionsOptionsPageComponent } from '../dimensions-options-page/dimensions-options-page.component';
import { ShutterFacade } from '../+state/shutter.facade';
import { DrawService } from '@icc/common/configurators/draw.service';
import {
    core,
    ConfigurationsService,
    TranslateService,
    APP_CONFIG,
    AppConfigFactory,
    EventBusService,
} from '@icc/common';
import { RollerDimensionsService } from '@icc/legacy/configurator/steps/roller_shutter/dimensions.service';
import { map, takeUntil } from 'rxjs/operators';
import { DrawResizeDirective } from 'libs/configurator/shared/src/lib/draw-resize/draw-resize.directive';
import { UnitConverterService } from '@icc/configurator/ui';
import { PriceService } from '@icc/price';
import { DrivesService } from '@icc/legacy/configurator/steps/roller_shutter/drives.service';
import { DimensionsInfoComponent } from '../dimensions-info/dimensions-info.component';
import { Subject } from 'rxjs';


@Component({
    selector: 'icc-dimensions',
    templateUrl: './dimensions.component.html',
    styleUrls: ['./dimensions.component.scss'],
})
export class DimensionsComponent extends StepComponent implements OnInit {
    static stepName = _('DIMENSION|Rozmiar');
    static stepIcon = {
        ligature: 'settings_overscan',
    };

    public configurator = 'roller_shutter';
    public stepId = 'rollerdimensions';
    public title = _('DIMENSION|Rozmiar');

    public options = [
        {
            title: _('WINDOW|Zaawansowane'),
            component: DimensionsOptionsPageComponent,
        },
        {
            title: _('DIMENSION|Resetuj wymiary'),
            icon: {
                ligature: 'refresh',
            },
            show:() => this.showResetDimensionButon(),
            callback:() => {
               this.resetDimensionToWindow();
            },

        }
    ];

    hasPrices$ = this.shutterFacade.hasPrices$;
    guidesEnable = this.config().IccConfig.Configurators.roller_shutter.guides || false;
    slatsEnable = this.config().IccConfig.Configurators.roller_shutter.slats || false;
    dimensionsInfo = this.config().IccConfig.Configurators.roller_shutter.dimensionsInfo;
    areStepsJoined = this.config().IccConfig.Configurators.roller_shutter.joinTypesAndSystemsStep;
    isInfoPageAvailable = this.config().IccConfig.Configurators.roller_shutter.showDimensionsInfo;
    width$ = this.shutterFacade.width$;
    height$ = this.shutterFacade.fullHeight$;
    shutters$ = this.shutterFacade.shutters$;
    systemInfo$ = this.shutterFacade.selectedSystem$.pipe(map(system => system && system.dimensionsInfo));
    addBoxToHeight = false;
    letters = ['A', 'B', 'C'];
    mosquito$ = this.shutterFacade.mosquito$;
    drawOptions: any = null;
    drawButtons = {
        alignments: true,
        dimensions: false,
        extensions: true,
        fullscreen: false,
        mullions: true,
        profiles: false,
        sashes: true,

        toggle: (
            type:
                | 'alignments'
                | 'dimensions'
                | 'extensions'
                | 'fullscreen'
                | 'mullions'
                | 'profiles'
                | 'sashes'
        ) => {
            if (type && typeof this.drawButtons[type] !== 'undefined') {
                this.drawButtons[type] = !this.drawButtons[type];
            }

            this.drawButtons.update();
        },

        update: (side: 'inner' | 'outer' = 'inner') => {
            const conf = this.configurationsService.conf.Current;
            const shutters = this.configurationsService.conf.Current.RollerShutter.shutters;
            if (conf) {
                this.drawOptions = Object.assign({}, this.drawService.options, {
                    base: {
                        side,
                    },
                    shutter: {
                        buttons: [
                            {
                                class: 'ico-minus common-icon',
                                condition: response =>
                                    response.shutter.commonRail && response.shutter.id,
                                onClick: response => this.toggleCommonRail(response.shutter.id),
                                positions: ['left'],
                                title: this.translateService.instant(
                                    'INTERFACE|Ustaw osobne prowadnice'
                                ),
                            },
                            {
                                class: 'ico-plus common-icon',
                                condition: response =>
                                    !response.shutter.commonRail && response.shutter.id,
                                onClick: response => this.toggleCommonRail(response.shutter.id),
                                positions: ['left'],
                                title: this.translateService.instant(
                                    'INTERFACE|Ustaw wspólną prowadnicę'
                                ),
                            },
                            {
                                class: 'ico-left guide-icon',
                                condition: response =>
                                    this.guidesEnable && response.shutter.id === shutters[0].id,
                                onClick: response =>
                                    this.guideSelection(response.shutter.id, 'left'),
                                positions: ['left'],
                                title: this.translateService.instant(
                                    'INTERFACE|Wybierz prowadnicę'
                                ),
                            },
                            {
                                class: 'ico-right guide-icon',
                                condition: response =>
                                    this.guidesEnable
                                    && response.shutter.id === shutters[shutters.length - 1].id,
                                onClick: response =>
                                    this.guideSelection(response.shutter.id, 'right'),
                                positions: ['right'],
                                title: this.translateService.instant(
                                    'INTERFACE|Wybierz prowadnicę'
                                ),
                            },
                            {
                                class: 'ico-both',
                                condition: response =>
                                    this.guidesEnable
                                    && response.shutter.commonRail
                                    && response.shutter.id,
                                onClick: response =>
                                    this.guideSelection(
                                        response.shutter.id,
                                        `common${response.shutter.id}`
                                    ),
                                positions: ['left'],
                                title: this.translateService.instant(
                                    'INTERFACE|Wybierz prowadnicę'
                                ),
                            },
                            {
                                class: 'ico-baseline',
                                condition: response =>
                                    this.guidesEnable
                                    && !response.shutter.commonRail
                                    && response.shutter.id,
                                positions: ['left'],
                                title: this.translateService.instant(
                                    'INTERFACE|Wybierz prowadnicę'
                                ),
                                buttons: [
                                    {
                                        class: 'ico-right baseline-icon',
                                        condition: response => this.guidesEnable,
                                        onClick: response =>
                                            this.guideSelection(
                                                response.shutter.id,
                                                `middleRight${response.shutter.id}`
                                            ),
                                        positions: ['left'],
                                        title: this.translateService.instant(
                                            'INTERFACE|Wybierz prowadnicę'
                                        ),
                                    },
                                    {
                                        class: 'ico-left baseline-icon',
                                        condition: response => this.guidesEnable,
                                        onClick: response =>
                                            this.guideSelection(
                                                response.shutter.id - 1,
                                                `middleLeft${response.shutter.id}`
                                            ),
                                        positions: ['left'],
                                        title: this.translateService.instant(
                                            'INTERFACE|Wybierz prowadnicę'
                                        ),
                                    },
                                ],
                            },
                            {
                                class: 'ico-border-bottom',
                                condition: response => this.slatsEnable,
                                onClick: response => this.slatSelection(response.shutter.id),
                                positions: ['bottom'],
                                title: this.translateService.instant(
                                    'INTERFACE|Wybierz listwę końcową'
                                ),
                            },
                        ],
                    },
                    indexes: {
                        multiplier: 3,
                    },
                    extendedRollerInWindow: this.config().IccConfig.Configurators
                        .extendedRollerInWindow,
                    onInterfaceClick: () => {},
                });
                this.drawOptions.base.side = 'inner';
            }
            this.rollerDimensionsService.updateDimensions();
        },
    };

    dimensionUnit = this.unitConverterService.getUnit();
    modalConfigurator = false;

    private unsubscribe$ = new Subject<void>();

    constructor(
        @Inject(APP_CONFIG) private config: AppConfigFactory,
        private shutterFacade: ShutterFacade,
        public drawService: DrawService,
        private rollerDimensionsService: RollerDimensionsService,
        public configurationsService: ConfigurationsService<'roller_shutter'>,
        private translateService: TranslateService,
        private eventBusService: EventBusService,
        private unitConverterService: UnitConverterService,
        private priceService: PriceService,
        private drivesService: DrivesService,
        private modalService: ModalService,
        private sharedFacade: SharedFacade
    ) {
        super();
        this.sharedFacade.getIsModalConfigurator$.pipe(takeUntil(this.unsubscribe$)).subscribe(active => {
            this.modalConfigurator = active;
        });
    }

    ngOnInit(conf = this.configurationsService.conf.Current) {
        this.addBoxToHeight = conf.RollerShutter?.type?.measureType === 'with_box';
        this.drawButtons.update();
    }

    ngOnDestroy() {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    /**
     * Przełączanie wspólnej prowadnicy
     * @param {object} shutter Roleta
     * @param {object} idx     Index
     */
    toggleCommonRail(shutterId) {
        const index = this.configurationsService.conf.Current.RollerShutter.shutters.findIndex(
            o => o.id === shutterId
        );
        const shutter = this.configurationsService.conf.Current.RollerShutter.shutters[index];

        if (shutter.commonRail) {
            shutter.commonRail = false;
        } else {
            shutter.commonRail = true;

            this.configurationsService.conf.Current.RollerShutter.shutters[
                index - 1
            ].realHeight = core.copy(shutter.realHeight);
        }

        this.rollerDimensionsService.setGuideProfiles();
        this.rollerDimensionsService.setSlatProfiles();
        this.rollerDimensionsService.updateDimensions();
    }

    /**
     * Funkcja otwierajaca dialog wyboru prowadnic
     * @param {object} shutterId
     */
    guideSelection(shutterId, side = null) {
        this.rollerDimensionsService.openModalGuideSelection(
            shutterId,
            side,
            this.configurationsService.conf.Current
        );
    }

    /**
     * Funkcja otwierajaca dialog wyboru prowadnic
     * @param {object} shutterId
     */
    slatSelection(shutterId) {
        this.rollerDimensionsService.openModalSlatSelection(
            shutterId,
            this.configurationsService.conf.Current
        );
    }

    updateDimensions(isHeightChanged, changedInputIndex) {
        this.rollerDimensionsService.updateDimensions(isHeightChanged,changedInputIndex);
        this.drivesService.setDrive(this.configurationsService.conf.Current.RollerShutter.drive);
        this.rollerDimensionsService.updateDimensions();
        this.priceService.count();
    }

    toggleMosquito(value: boolean, index: number) {
        this.rollerDimensionsService.toggleMosquito(value, index);
    }

    switchDrawingSide(side: 'inner' | 'outer') {
        this.drawButtons.update(side);
        this.drawService.init();
    }

    openDimensionsInfoPage() {
        this.modalService
        .open({
            pageComponent: DimensionsInfoComponent,
            resolve: {},
        })
    }

    resetDimensionToWindow() {
        this.rollerDimensionsService.resetDimensionToWindowSize()
    }

    showResetDimensionButon() {
        return this.modalConfigurator && this.rollerDimensionsService.showResetDimensionButon();
    }
}
