import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { ProfilesService } from '@icc/common/profiles.service';
import { ProfileSetsService } from '@icc/common/profile-sets.service';
import { ProfilesModalService } from './profiles-modal.service';
import { BrowserProfilesService } from './profiles.service';
import { BrowserProfileSetsService } from './profile-sets.service';
import { ProfileColorsService } from './profile-colors.service';
import { ThresholdColorsService } from './threshold-colors.service';
import { LippingColorsService } from './lipping-colors.service';
import { CasingsService } from './casings.service';

@NgModule({
    imports: [CommonModule, FormsModule],
    declarations: [],
    exports: [],
    providers: [
        BrowserProfilesService,
        { provide: ProfilesService, useExisting: BrowserProfilesService },
        BrowserProfileSetsService,
        { provide: ProfileSetsService, useExisting: BrowserProfileSetsService },
        ProfilesModalService,
        ProfileColorsService,
        ThresholdColorsService,
        LippingColorsService,
        CasingsService
    ],
})
export class ProfilesModule {}
