import { ComplementaryGoodsActiveConfiguration } from '@icc/common/configurations/ComplementaryGoodsActiveConfiguration';
import { Component, OnInit, Inject } from '@angular/core';
import { PageComponent, ModalService, ICC_PAGE_DATA, isObject } from '@icc/helpers';
import { SharedFacade, _ } from '@icc/configurator/shared';
import { ColorsService } from '@icc/legacy/configurator/steps/window/colors/colors.service';
import {
    IccAccessoryAccessory,
    IccMaterial,
    IccColorGroup,
    IccColor,
} from '@icc/common/data-types';
import { BehaviorSubject } from 'rxjs';
import { Profile } from '@icc/window';
import {
    WindowActiveConfiguration,
    core,
    ConfigurationsService,
    EventBusService,
} from '@icc/common';

@Component({
    selector: 'icc-profiles-colors-page',
    templateUrl: './profiles-colors-page.component.html',
    styleUrls: ['./profiles-colors-page.component.scss'],
})
export class ProfilesColorsPageComponent extends PageComponent implements OnInit {
    public title = _('WINDOW|Wybierz kolor profilu');
    public options = [];

    config = {
        type: 'window',
        Colors:
            this.pageData.selectedProfile
            && this.pageData.selectedProfile.selectedColor
            && this.pageData.selectedProfile.selectedColor.frame
                ? this.pageData.selectedProfile.selectedColor
                : {
                      frame: {
                          core: {},
                          outer: {},
                          inner: {},
                          alushell: {},
                      },
                      sash: {
                          core: {},
                          outer: {},
                          inner: {},
                          alushell: {},
                      },
                  },
        System: {
            type: 'pvc',
        },
        Wood:
            this.pageData.selectedProfile && this.pageData.selectedProfile.selectedWood
                ? this.pageData.selectedProfile.selectedWood
                : {},
        valid: {},
    };

    configDefault = {
        type: 'window',
        Colors:
            this.pageData.selectedProfile
            && this.pageData.selectedProfile.selectedColor
            && this.pageData.selectedProfile.selectedColor.frame
                ? this.pageData.selectedProfile.selectedColor
                : {
                      frame: {
                          core: {},
                          outer: {},
                          inner: {},
                          alushell: {},
                      },
                      sash: {
                          core: {},
                          outer: {},
                          inner: {},
                          alushell: {},
                      },
                  },
        System: {
            type: 'pvc',
        },
        Wood:
            this.pageData.selectedProfile && this.pageData.selectedProfile.selectedWood
                ? this.pageData.selectedProfile.selectedWood
                : {},
        valid: {},
    };

    hasCream = false;

    showCore = false;

    agreeButton = false;
    constructor(
        private modalService: ModalService,
        private sharedFacade: SharedFacade,
        private colorsService: ColorsService,
        @Inject(ICC_PAGE_DATA)
        private pageData: {
            profile: Profile;
            profilesPrices: any;
            selectedColors$: BehaviorSubject<any>;
            selectedProfile?: Profile;
            agreeButton?: boolean;
        },
        private configurationsService: ConfigurationsService<'window'>,
        private eventBusService: EventBusService
    ) {
        super();

        const conf = this.configurationsService.conf.Current;

        if (WindowActiveConfiguration.is(conf)) {
            this.config.System.type = this.configDefault.System.type = conf.System.type || null;
        }

        this.hasCream = this.colorsService.windowColors.some(
            el => el.sides && el.sides.indexOf('F|C') && el.type === 'cream'
        );

        colorsService.loadData();
        this.setDefaults();
        if (
            this.pageData.profile
        ) {
            this.filter = group =>
            Boolean(
                group
                && group.target.includes('show_profile')
                && (this.pageData.profile.priceLevelColorGroups.some((g: any) => g == group.id)
                    || this.pageData.profile.priceLevelColorGroupsOut.some((g: any) => g == group.id))
            );
        }
    }

    ngOnInit() {
        this.agreeButton = this.pageData.agreeButton || false;
        this.config.Colors =
            this.pageData.profile
            && this.pageData.profile.selectedColor
            && this.pageData.profile.selectedColor.frame
                ? this.pageData.profile.selectedColor
                : {
                        frame: {
                            core: {},
                            outer: {},
                            inner: {},
                            alushell: {},
                        },
                        sash: {
                            core: {},
                            outer: {},
                            inner: {},
                            alushell: {},
                        },
                    };
        this.configDefault.Colors =
            this.pageData.profile
            && this.pageData.profile.selectedColor
            && this.pageData.profile.selectedColor.frame
                ? this.pageData.profile.selectedColor
                : {
                        frame: {
                            core: {},
                            outer: {},
                            inner: {},
                            alushell: {},
                        },
                        sash: {
                            core: {},
                            outer: {},
                            inner: {},
                            alushell: {},
                        },
                    };
    }


    openModalWood() {
        this.colorsService.openModalWood(
            this.config as WindowActiveConfiguration,
            this.configDefault as WindowActiveConfiguration,
            this.setDefaults.bind(this)
        );
    }

    openModalColor(type: 'outer' | 'inner' | 'alushell') {
        this.colorsService
            .openModalColorSimple(
                type,
                'frame',
                this.config as WindowActiveConfiguration,
                this.filter.bind(this),
                false,
                false,
                undefined,
                null,
                'extension'
            )
            .then(() => {
                this.pageData.profile.selectedColor = core.copy(this.config.Colors);
                this.pageData.selectedColors$.next(this.pageData.profile.selectedColor);
            });
    }

    filter: (color?: IccColorGroup) => boolean = group => true;
    filterColors: (color?: IccColor) => boolean = color => true;

    setDefaults() {
        this.colorsService.loadData();
        this.colorsService.loadColorsBySystem(this.filterColors.bind(this));

        this.colorsService.setDefaultWood(
            this.config as WindowActiveConfiguration,
            this.configDefault as WindowActiveConfiguration
        );
        this.colorsService.loadColorsByWood(this.config as WindowActiveConfiguration);
        this.colorsService.setDefaultColors(
            this.config as WindowActiveConfiguration,
            this.configDefault as WindowActiveConfiguration,
            this.filterColors.bind(this)
        );
    }

    /**
     * Zamyka modal.
     *
     *
     * @memberOf ModalPriceDetailsCtrl
     */
    close() {
        this.sharedFacade.closePage({ colors: this.config.Colors, wood: this.config.Wood });
    }

    getOuterColor() {
        if (this.config.Colors && this.config.Colors.frame) {
            if (this.config.Colors.frame.outer && this.config.Colors.frame.outer.id) {
                return this.config.Colors.frame.outer;
            } else {
                return this.config.Colors.frame.core;
            }
        }
    }

    getInnerColor() {
        if (this.config.Colors && this.config.Colors.frame) {
            if (this.config.Colors.frame.inner && this.config.Colors.frame.inner.id) {
                return this.config.Colors.frame.inner;
            } else {
                return this.config.Colors.frame.core;
            }
        }
    }
}
