import { logger } from '@icc/common/helpers';
import { Injectable, Inject, Optional } from '@angular/core';
import { EventBusService } from '@icc/common/event-bus.service';
import {
    ConfigurationsService,
    ConfigurationType,
} from '@icc/common/configurations/configurations.service';
import { PriceBaseService } from './price-base.service';
import { PriceDetailsService } from './price-details.service';

/**
 * Usługa liczenia ceny
 *
 * @export
 * @class PriceService
 */
@Injectable()
export class PriceService {
    syncRequests: number = 0;
    syncingPrice = false;

    /**
     * Creates an instance of PriceService.
     *
     * @param {ng.ui.bootstrap.IModalService} $uibModal $uibModal
     * @param {any} ConfigurationsService ConfigurationsService
     * @param {PriceBaseService} priceBaseService PriceBaseService
     *
     * @memberOf PriceService
     */
    constructor(
        private configurationsService: ConfigurationsService,
        private priceBaseService: PriceBaseService,
        private priceDetailsService: PriceDetailsService,
        private eventBusService: EventBusService
    ) {
        this.eventBusService.subscribe(
            [
                'changedBalcony',
                'changedFillings',
                'changedSashes',
                'changedStep',
                'putAlignmentInField',
                'putExtensionOnSide',
                'removedAlignmentInField',
                'removedAlignment',
                'removedExtensionFromSide',
                'setConstructionColor',
                'setExtensionProfile',
                'setFrameProfile',
                'setGlazingInSash',
                'setGlazingBeadColor',
                'setGlazingBeadInSash',
                'setMullionProfile',
                'setProfileSet',
                'setSashProfile',
                'setSealColor',
                'setSiliconeColor',
                'setShape',
                'setSystem',
                'setWarmEdge',
                'setLowThreshold',
                'unsetLowThreshold',
                'insertMuntins',
                'removeMuntins',
                'updateMuntins',
                'setMuntinColor',
                'changedAlushell',
                'changedOptions',
                'montageOptionsChanged',
                'changedDrive',
                'changedDoorHardware',
                'configurationChanged',
                'setFillingColor',
                'setDoorPortal',
                'changedConfigurator'
            ],
            data => {
                try {
                    if (data.activeConfiguration) {
                        this.count();
                    }
                } catch (err) {
                    logger.error(err);
                }
            }
        );
    }

    async syncPrice(fromTimeout = false) {
        if (this.syncRequests > 0 || !this.configurationsService.conf.Current.PriceValid) {
            this.syncRequests = 0;

            this.priceBaseService.setPrice(this.configurationsService.conf.Current);
            if (fromTimeout) {
                this.eventBusService.post({
                    key: 'updatedPrice',
                    value: null
                });
            }
        }

        if (this.syncingPrice) {
            setTimeout(() => {
                this.syncPrice(true);
            }, 100);
        }
    }

    /**
     * Przelicza cenę
     *
     * @param {*} config Konfiguracja
     * @param {*} [offer]  Oferta
     * @returns {number} Wyliczona cena
     *
     * @memberOf PriceService
     */
    count(config?, offer?) {
        if (config) {
            return this.priceBaseService.setPrice(config, offer);
        } else {
            if (this.configurationsService.conf.Current.PriceValid) {
                this.configurationsService.conf.Current.PriceValid = false;
            }
            this.syncRequests++;
            return NaN;
        }
    }

    /**
     * Otwiera modal szczegółow wyceny
     *
     * @param {any} config
     *
     * @memberOf PriceService
     */
    showPriceDetailsModal(config: ConfigurationType) {
        this.priceDetailsService.showPriceDetailsModal(config);
    }
}
