import { Injectable } from '@angular/core';
import { IccColor } from '@icc/common/data-types';
import { EventBusService } from '@icc/common/event-bus.service';
import { IssuesService } from '@icc/configurator/shared';
import { ConfiguratorsDataService, LoadedConfiguratorsDataValue } from '@icc/common/configurators/configurators-data.service';
import { WindowActiveConfiguration } from '@icc/common/configurations/WindowActiveConfiguration';
import { Common, IssueLevel, ValidationService, core, TranslateService, ConfigurationsService } from '@icc/common';
import { ColorMappingService } from '@icc/common/colors/colors-mapping.service';
import { ColorsService } from '@icc/legacy/configurator/steps/window/colors/colors.service';
import { Side } from '@icc/common/data-types/ColorGroup';
import { DoorActiveConfiguration } from '@icc/common/configurations/DoorActiveConfiguration';

@Injectable({
    providedIn: 'root',
})
export class LippingColorsService {
    constructor(
        private eventBusService: EventBusService,
        private issuesService: IssuesService,
        private colorsService: ColorsService,
        private validationService: ValidationService,
        private colorMappingService: ColorMappingService,
        private translateService: TranslateService,
        private configurationsService: ConfigurationsService<'window'>,
        private configuratorsDataService: ConfiguratorsDataService,
    ) {
        eventBusService.subscribe<LoadedConfiguratorsDataValue>('loadedConfiguratorsData', data => {});

        eventBusService.subscribe(['setLowLipping', 'unsetLowLipping'], data => {
            if (
                WindowActiveConfiguration.is(data.activeConfiguration)
                && WindowActiveConfiguration.is(data.defaultConfiguration)
            ) {
                this.validateColorsAndFixIssues(
                    data.activeConfiguration as WindowActiveConfiguration,
                    data.defaultConfiguration as WindowActiveConfiguration,
                    'lippingColor'
                );
            }
        });


        this.eventBusService.subscribe<LoadedConfiguratorsDataValue>('setDefaultColors', (data) => {
            this.validateColorsAndFixIssues(
                data.activeConfiguration as WindowActiveConfiguration,
                data.defaultConfiguration as WindowActiveConfiguration,
                'lippingColor'
            );
        });

        this.eventBusService.subscribe<LoadedConfiguratorsDataValue>('setDefaultColors', (data) => {
            this.validateColorsAndFixIssues(
                data.activeConfiguration as WindowActiveConfiguration,
                data.defaultConfiguration as WindowActiveConfiguration,
                'innerLippingColor'
            );
        });
    }

    getLippingColors(conf: WindowActiveConfiguration) {
        if (conf.System.lipping_color_based_on_frame_color) {
            return this.colorsService.getSimpleColors(conf, [Side.FO]).colors;
        } else {
            return this.colorsService.getSimpleColors(conf, [Side.LB]).colors;
        }
    }

    getInnerLippingColors(conf: WindowActiveConfiguration) {
        if (conf.System.lipping_color_based_on_frame_color) {
            return this.colorsService.getSimpleColors(conf, [Side.FI]).colors;
        } else {
            return this.colorsService.getSimpleColors(conf, [Side.LI]).colors;
        }
    }

    getLippingColorGroups(conf: WindowActiveConfiguration) {
        return this.colorsService.getColorsGroups(null, this.colorsService.windowColors, conf);
    }

    getDefaultColor(conf: WindowActiveConfiguration) {
        if (!conf.System.available_lipping_color) {
            return null;
        }
        const availColors = this.getLippingColors(conf);
        const windowColorId = this.colorMappingService.getWindowColorId(conf, 'outer');
        const matchedColors = this.colorMappingService.getColors(windowColorId, 'window', 'window');
        const windowColors = matchedColors
            .map(m => availColors.filter(c => Number(c.id) === Number(m))[0])
            .filter(m => m);
        let color;
        if (Common.isArray(windowColors) && Common.isDefined(windowColors[0])) {
            color = core.copy(windowColors[0]);
        } else if (Common.isArray(availColors) && Common.isDefined(availColors[0])) {
            color = core.copy(availColors[0]);
        } else {
            return null;
        }
        color.isDefault = true;
        return color;
    }

    setDefaultColor(conf: WindowActiveConfiguration, defaultConf: any,) {
        if (
            conf.lippingColor 
            && conf.lippingColor.isDefault !== false 
            ) conf.lippingColor.isDefault = true;
            
        if (
            conf.innerLippingColor 
            && conf.innerLippingColor.isDefault !== false 
            ) conf.innerLippingColor.isDefault = true;

        if (
            conf.System.available_lipping_color
            && (!conf.lippingColor?.id
            || !conf.innerLippingColor?.id)
            ) {
            const color = this.getDefaultColor(conf);
            if (color) {
                this.setColor(color, conf, defaultConf);
            } else {
                this.setColor(null, conf, defaultConf);
            }
        } 
        
        if (!conf.System.available_lipping_color) {
            conf.lippingColor = {};
            conf.innerLippingColor = {};
        }
    }

    setColor(
        color: IccColor | null,
        conf: WindowActiveConfiguration,
        defaultConf: any,
        type?: 'outer' | 'inner'
    ) {
        let object;
        if (type === 'outer' || !type) {
            if (conf.lippingColor && color) {
                object = conf.lippingColor;
                this.colorsService.setColorSide(object, color, undefined, 'outer', 'lipping', conf, conf.lippingColor.isDefault)
            }
            if (type && color && !conf.System.lipping_color_based_on_frame_color) {
                if (color.sides.some(s => ['L|I', 'L|B'].includes(s)) && conf.innerLippingColor && conf.innerLippingColor.isDefault) {
                    object = conf.innerLippingColor;
                    this.colorsService.setColorSide(object, color, undefined, 'inner', 'lipping', conf, conf.innerLippingColor.isDefault)
                }
            } else {
                if (conf.innerLippingColor && conf.innerLippingColor.isDefault && color) {
                    object = conf.innerLippingColor;
                    this.colorsService.setColorSide(object, color, undefined, 'inner', 'lipping', conf, conf.innerLippingColor.isDefault)
                }

                if (
                    conf.innerLippingColor
                    && conf.lippingColor
                    && !conf.innerLippingColor.isDefault
                    && !conf.lippingColor.isDefault
                    && this.colorsService.mode === 'advanced') {
                    this.setOuterColorBasedOnInnerColor(conf);
                }
            }
        }
        if (type === 'inner') {
            object = conf.innerLippingColor;
           
            if (object && color && conf.innerLippingColor) this.colorsService.setColorSide(object, color, undefined, 'inner', 'lipping', conf, conf.innerLippingColor.isDefault)
            
            if (
                conf.System.lippingColor !== conf.System.innerLippingColor
                || (conf.System.available_lipping_color 
                    && conf.System.lipping_color_based_on_frame_color 
                    && conf.System.available_lipping_in_bicolor)
                ) {
                if (conf.innerLippingColor) conf.innerLippingColor.isDefault = false;
            }
        }

        this.eventBusService.post({
            key: 'setLippingColor',
            value: {
                colorId: color && color.id,
            },
        });
    }

    validColor(
        conf: WindowActiveConfiguration,
        defaultConf: any,
        sideColor: 'lippingColor' | 'innerLippingColor'
    ) {
        if (
            sideColor === 'lippingColor' &&
            !conf.System.available_lipping_color &&
            conf.lippingColor &&
            conf.lippingColor.id
        ) {
            return false;
        } else if (
            sideColor === 'innerLippingColor' &&
            !conf.System.available_lipping_in_bicolor &&
            conf.innerLippingColor &&
            conf.innerLippingColor.id
        ) {
            return false;
        }

        if (sideColor === 'lippingColor') {
            const availColors = this.getLippingColors(conf);
            const colorAvailable = availColors.some(
                (el) =>
                    conf.lippingColor &&
                    el.id === conf.lippingColor.id &&
                    el.RAL === conf.lippingColor.RAL
            );
            return colorAvailable;
        } else if (sideColor === 'innerLippingColor') {
            const availColors = this.getInnerLippingColors(conf);
            const colorAvailable = availColors.some(
                (el) =>
                    conf.innerLippingColor &&
                    el.id === conf.innerLippingColor.id &&
                    el.RAL === conf.innerLippingColor.RAL
            );
            return colorAvailable;
        }
    }

    validateColors(
        conf: WindowActiveConfiguration,
        defaultConf: any,
        sideColor: 'lippingColor' | 'innerLippingColor'
    ) {
        this.validationService.indeterminate(conf, sideColor);
        if (this.validationService.isValidElements(conf, ['system'])) {
            const valid = this.validColor(conf, defaultConf, sideColor);
            if (!valid) {
                this.validationService.invalid(conf, sideColor);
                this.issuesService.simpleRegister(
                    'invalid-lippings-colors',
                    'Niepoprawne kolory progów',
                    this.translateService.instant('WINDOW|Niepoprawne kolory progów'),
                    conf,
                    {
                        level: IssueLevel.ERROR,
                        logLevel: IssueLevel.NONE,
                        blockStepsAfter: null,
                    }
                );
            } else {
                Object.assign(conf, this.validationService.valid(conf, sideColor));
                this.issuesService.unregister('invalid-lippings-colors', conf);
            }
        }
    }

    validateColorsAndFixIssues(
        conf: WindowActiveConfiguration,
        defaultConf: any,
        sideColor: 'lippingColor' | 'innerLippingColor'
    ) {
        this.validateColorAndFixIssue(conf, defaultConf, sideColor);
    }

    validateColorAndFixIssue(
        conf: WindowActiveConfiguration,
        defaultConf: any,
        sideColor: 'lippingColor' | 'innerLippingColor',
    ) {
        this.validationService.indeterminate(conf, sideColor);
        if (this.validationService.isValidElements(conf, ['system'])) {
            const pauseId = this.eventBusService.pause(['setLippingColor']);
            try {
                if (!this.validColor(conf, defaultConf, sideColor)) {
                    this.validationService.invalid(conf, sideColor);
                    this.setDefaultColor(conf, defaultConf);
                } else {
                    Object.assign(conf, this.validationService.valid(conf, sideColor));
                }
            } finally {
                this.eventBusService.resume(['setLippingColor'], pauseId);
            }
        }
    }

    openModal(type: 'outer' | 'inner', conf: WindowActiveConfiguration, defaultConf: any) {

        if (conf.System.available_lipping_color && conf.System.lipping_color_based_on_frame_color) {
            if (conf.lippingColor) conf.lippingColor.isDefault = false;
        }

        if (type === 'inner' && conf.System.lipping_price_level_id) {
            if (conf.innerLippingColor) conf.innerLippingColor.isDefault = false;
        }

        return this.colorsService.openModalColorSimple(
            type,
            conf.System.lipping_color_based_on_frame_color ? 'frame' : 'lipping',
            conf,
            undefined,
            true,
            false,
            (color: IccColor) => {
                this.setColor(color, conf, defaultConf, type);
            },
            type === 'outer' ? conf.lippingColor : conf.innerLippingColor
        );
    }
    isOnlyDoubleSidePriceLevelAvailable(conf = this.configurationsService.conf.Current as DoorActiveConfiguration) {
        if (
            !(
                Array.isArray(this.configuratorsDataService.data?.priceLevels) &&
                this.configuratorsDataService.data?.priceLevels.length
            )
        ) {
            return false;
        }
        const lippingPriceLevel = this.configuratorsDataService.data.priceLevels.find(p => p.id === Number(conf.System.lipping_price_level_id));

        if (!lippingPriceLevel) {
            return false;
        }

        const isOnlyDoubleSidePriceLevel = lippingPriceLevel.price_levels.every(
            (priceLevel: { side: string }) => priceLevel.side === 'double'
        );

        return isOnlyDoubleSidePriceLevel;
    }

    isInnerColorBasedOnOuterColorAvailable(conf = this.configurationsService.conf.Current) {
        if ((conf.innerLippingColor?.id && conf.lippingColor?.id === conf.innerLippingColor?.id)) return true;

        if (conf.lippingColor?.id !== conf.innerLippingColor?.id) {
            if (
                (conf.System.available_lipping_color 
                && conf.System.lipping_color_based_on_frame_color 
                && conf.System.available_lipping_in_bicolor)
                || (conf.System.available_lipping_color && conf.System.lipping_price_level_id)
                ) {
                return false;
            } else {
                const side = this.colorsService.getPlaceSide('inner', 'lipping');
                const colorsWin: IccColor[] = [];
                const groups = this.colorsService.filterWindowColorGroups(side, colorsWin, 'inner');
                return conf.lippingColor?.groups?.some((lippingGroupId) =>
                    this.colorsService.getColorGroups(groups, undefined, side).some((group) => group.id === lippingGroupId)
                );
            }
        }
    }

    setOuterColorBasedOnInnerColor(conf = this.configurationsService.conf.Current) {
        conf.innerLippingColor = {...conf.lippingColor, isDefault: conf.innerLippingColor?.isDefault};
    }
}
