<icc-select-box [title]="('COLOR|Kolor zewnętrzny' | translate)"
                [description]="config.Colors?.frame?.outer?.name"
                [color]="'#' + config.Colors?.frame?.outer?.color"
                [imageUrl]="'/files/color/' + config.Colors?.frame?.outer?.color_img"
                [buttonText]="'INTERFACE|Zmień' | translate" [colorSelect]="true"
                (select)="openModalColor('outer')"></icc-select-box>
<icc-select-box [title]="('COLOR|Kolor wewnętrzny' | translate)"
                [description]="config.Colors?.frame?.inner?.name"
                [color]="'#' + config.Colors?.frame?.inner?.color"
                [imageUrl]="'/files/color/' + config.Colors?.frame?.inner?.color_img"
                [buttonText]="'INTERFACE|Zmień' | translate" [colorSelect]="true"
                (select)="openModalColor('inner')"></icc-select-box>
<icc-select-box *ngIf="showCore" [title]="('WINDOW|Rdzeń' | translate)"
                [description]="config.Colors?.frame?.core?.name"
                [color]="'#' + config.Colors?.frame?.core?.color"
                [imageUrl]="'/files/color/' + config.Colors?.frame?.core?.color_img"
                [buttonText]="'INTERFACE|Zmień' | translate" [colorSelect]="true"
                (select)="openModalColor('core')"></icc-select-box>
