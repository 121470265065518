import './vendor';
import './app/styles/scss/default.scss';
import './app/app/console';

import {enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';
import {UpgradeModule} from '@angular/upgrade/static';
import {moduleFactory} from './app/app.module';
import { environment } from './environments/environment';

import * as angular from 'angular';
import app from './app/app.module.ajs';
import machine from './app/app/machine';

import getUser from './app/sync/Infrastructure/GetUser';
import { B2BDatabaseManager } from './app/sync/Databases/DatabaseManager';
import {CONFIG} from './app/app/config';
import {logger} from '@icc/common/helpers';

import * as Sentry from '@sentry/angular';
import { BrowserTracing } from "@sentry/tracing";
import { ThemeService } from '@icc/helpers';
import { AngularIntegration } from './app/app/sentry';

if (environment.production) {
  enableProdMode();
}

if (window.supportedBrowser) {
    getUser((data: any) => {
        if (data.domain && document) {
            try {
                const nodeBeginHead = document.createRange()
                    .createContextualFragment(data.domain.insert_head_begin);
                document.head.prepend(nodeBeginHead);

                const nodeEndHead = document.createRange()
                    .createContextualFragment(data.domain.insert_head_end);
                document.head.append(nodeEndHead);

                const nodeEndBody = document.createRange()
                    .createContextualFragment(data.domain.insert_body_end);
                document.body.append(nodeEndBody);
            } catch (error) {
                logger.error(error);
            }
        }

        if (data.theme || data.iccConfig.Theme) {
            const themeService = new ThemeService();
            themeService.setTheme(data.theme || data.iccConfig.Theme);
        }

        USER_ID = data.user_type.id;
        LANG_CODE = data.user_type.user_language || data.langCode;
        IccConfig = data.iccConfig;
        EnvConfig = data.envConfig;
        if (window.remoteHost) {
            EnvConfig.remoteHost = window.remoteHost;
        }
        Langs = data.langs;
        ProjectName = data.projectName;
        RavenDSN = data.ravenDSN;
        LocaleVersion = data.localeVersion;
        IccConfig.Version = VERSION;

        if (RavenDSN) {
            Sentry
                .init({
                    dsn: RavenDSN,
                    release: IccConfig.Version,
                    integrations: [
                        new BrowserTracing({
                            tracingOrigins: ["localhost", location.host],
                            routingInstrumentation: Sentry.routingInstrumentation,
                        }),
                        new AngularIntegration({
                            angular
                        })

                    ],
                    tracesSampleRate: 1.0,
                });
        }

        CONFIG.IccConfig = IccConfig;
        CONFIG.EnvConfig = EnvConfig;
        CONFIG.LocaleVersion = LocaleVersion;
        CONFIG.Langs = Langs;
        CONFIG.CurLang = LANG_CODE;
        CONFIG.marketId = data.user_type.marketId;
        CONFIG.USER_ID = USER_ID;
        CONFIG.ProjectName = ProjectName;
        CONFIG.RavenDSN = RavenDSN;
        CONFIG.dimensionUnit = data.user_type?.dimension_unit;
        CONFIG.numberOfPlacesAfterDecimalSeparator = data.user_type?.number_of_places_after_decimal_separator;
        CONFIG.filterAccessoriesByCategories = data.user_type?.filter_accessories_by_categories;
        CONFIG.weightUnit = data.user_type?.weight_unit;
        CONFIG.offerOptions.user = USER_ID;
        CONFIG.offerOptions.project = ProjectName;
        CONFIG.offerOptions.lang = LANG_CODE;
        CONFIG.Elements = {};
        CONFIG.domain = data.domain?.domain;
        CONFIG.theme = data.theme;
        CONFIG.metaTagData = data.metaTagData;
        if (window.elements && window.elements[LANG_CODE]) {
            Object.keys(window.elements[LANG_CODE]).forEach(key => {
                if (window.elements[LANG_CODE][key].markets && window.elements[LANG_CODE][key].content && window.elements[LANG_CODE][key].markets.includes(data.user_type.marketId.toString())) {
                    CONFIG.Elements[key.split('_')[1]] = window.elements[LANG_CODE][key];
                }
            });
        }
        const databaseManager = new B2BDatabaseManager();
        databaseManager.initAll(data.user_type, machine, CONFIG).then(() => {
            app.constant('DatabaseManager', databaseManager);
            app.constant('IccConfig', CONFIG.IccConfig);
            app.constant('EnvConfig', CONFIG.EnvConfig);
            app.constant('LocaleVersion', CONFIG.LocaleVersion);
            app.constant('Langs', CONFIG.Langs);
            app.constant('CurLang', CONFIG.CurLang);
            app.constant('USER_ID', CONFIG.USER_ID);
            app.constant('ProjectName', CONFIG.ProjectName);
            app.constant('RavenDSN', CONFIG.RavenDSN);
            app.constant('machine', CONFIG.machine);
            app.constant('debug', CONFIG.debug);
            app.constant('isSafari', CONFIG.isSafari);
            app.constant('isElectron', CONFIG.isElectron);
            app.constant('isPhonegap', CONFIG.isPhonegap);
            app.constant('isNative', CONFIG.isNative);
            app.constant('appScheme', CONFIG.appScheme);
            app.constant('Elements', CONFIG.Elements);
            app.constant('offerOptions', CONFIG.offerOptions);
            app.constant('domain', CONFIG.domain);
            app.constant('theme', CONFIG.theme);
            app.constant('metaTagData', CONFIG.metaTagData);
            platformBrowserDynamic().bootstrapModule(moduleFactory(databaseManager)).then(platformRef => {
                const upgrade = platformRef.injector.get(UpgradeModule) as UpgradeModule;
                upgrade.bootstrap(document.documentElement, [app.name]);
                angular.module('icc').requires.push('ngSentry');
            }).catch(err => console.error(err));
        });
    }, err => logger.err(err));
}
