import { PanelsData } from './parts/garage_door/PanelsData';
import { ActiveConfiguration } from './ActiveConfiguration';
import { GarageDoorConfiguration } from './GarageDoorConfiguration';
import { PriceSegment, DiscountGroups, PricePart } from '@icc/price/b2b';
import { TimeLimitSegment } from '@icc/common/time-limit/time-limit-functions';
import { ConfiguratorsDataService } from '../configurators/configurators-data.service';
import { ActiveAccessory } from './parts/common';
import { GarageDoorDimensions } from './parts/garage_door';
import { GlazingImpost } from './parts/garage_door/GlazingImpost';

export class GarageDoorActiveConfiguration implements ActiveConfiguration {
    type: 'garage_door' = 'garage_door';
    Name: string = '';
    Price: number = 0;
    PriceNoMargin: number = 0;
    PriceAfterDiscounts: number = 0;
    PriceGross: number = 0;
    PriceGrossAfterDiscounts: number = 0;
    Quantity: number = 1;
    Issues: any[] = [];
    PriceSegments: PriceSegment[] = [];
    PriceSegmentsNoMargin: PriceSegment[] = [];
    PriceParts: PricePart[] = [];
    PricePartsNoMargin: PricePart[] = [];
    DiscountGroups: DiscountGroups = {};
    DiscountGroupsNoMargin: DiscountGroups = {};
    Discount: number = 0;
    timeLimit: number = 0;
    timeLimitsStack: TimeLimitSegment[] = [];
    Title = '';
    Description = '';
    Attachments = [];
    valid: {} = {};
    PriceValid = false;
    drawData: any;

    system: any;
    dimensions: GarageDoorDimensions = {
        width: null,
        height: null,
        lintelHeight: null,
        garageDepth: null,
        guidanceLift: null,
        leftJambWidth: null,
        rightJambWidth: null
    };
    panelsData: PanelsData;
    glazingsGlobalImposts: GlazingImpost[];
    windowsGlobalType;
    windowsGlobalGlazing;
    glazingPanelsGlobalGlazing;
    windowFramesGlobalOuterColor;
    glazingPanelsGlobalColor;
    ventilationGratesGlobalOuterColor;
    mechanism;
    guide: any;
    panel;
    panelInternal;
    panelStructure;
    panelInternalStructure;
    panelColor;
    panelInternalColor;
    plinthPanel;
    plinthPanelStructure;
    drive;
    driveType: 'manual' | 'electrical';
    driveSide: 'left' | 'right';
    wicketDoor;
    wicketDoorHandleColor;
    wicketDoorFrameProfilesColor;
    wicketDoorDirection;

    Width;
    Height;

    Accessories: any[] = [];

    attributes: {
        id: string;
        name: string;
        value: string;
        valueId?: number;
    }[] = [];

    public static is(configuration): configuration is GarageDoorActiveConfiguration {
        return configuration instanceof GarageDoorActiveConfiguration || !configuration.$version;
    }

    constructor(configuration?, private configuratorsDataService?: ConfiguratorsDataService) {
        if (configuration) {
            if (GarageDoorActiveConfiguration.is(configuration)) {
                for (const propName in configuration) {
                    if (this.hasOwnProperty(propName)) {
                        this[propName] = configuration[propName];
                    }
                }
            } else {
                if (GarageDoorConfiguration.is(configuration) && configuratorsDataService) {
                    this.Name = configuration.name;
                    this.Price = configuration.price;
                    this.PriceNoMargin = configuration.priceNoMargin;
                    this.PriceAfterDiscounts = configuration.priceAfterDiscounts;
                    this.PriceGross = configuration.priceGross;
                    this.PriceGrossAfterDiscounts = configuration.priceGrossAfterDiscounts;
                    this.Quantity = configuration.quantity;
                    this.PriceSegments = configuration.priceSegments;
                    this.PriceSegmentsNoMargin = configuration.priceSegmentsNoMargin;
                    this.PriceParts = configuration.priceParts;
                    this.PricePartsNoMargin = configuration.pricePartsNoMargin;
                    this.DiscountGroups = configuration.discountGroups;
                    this.DiscountGroupsNoMargin = configuration.discountGroupsNoMargin;
                    this.Title = configuration.title;
                    this.Description = configuration.description;
                    this.valid = configuration.valid;
                    this.timeLimit = configuration.timeLimit;
                    this.timeLimitsStack = configuration.timeLimitsStack;
                    this.attributes = configuration.attributes;

                    this.system = configuratorsDataService.get(
                        'garageLines',
                        configuration.system
                    );
                    this.dimensions = configuration.dimensions;
                    this.panelsData = configuration.panelsData;
                    this.glazingsGlobalImposts = configuration.glazingsGlobalImposts;
                    this.panel = configuration.panel;
                    this.panelInternal = configuration.panelInternal;
                    this.panelStructure = configuration.panelStructure;
                    this.panelInternalStructure = configuration.panelInternalStructure;
                    this.panelColor = configuration.panelColor;
                    this.panelInternalColor = configuration.panelInternalColor;
                    this.guide = configuration.guide;
                    this.wicketDoor = configuration.wicketDoor;
                    this.wicketDoorDirection = configuration.wicketDoorDirection;
                    this.wicketDoorFrameProfilesColor = configuration.wicketDoorFrameProfilesColor;
                    this.wicketDoorHandleColor = configuration.wicketDoorHandleColor;
                    this.Accessories = configuration.accessories.map(
                        accessory =>
                            new ActiveAccessory(accessory, null, this.configuratorsDataService)
                    );
                }
            }
        }
        this.configuratorsDataService = null;
    }
}

export type IGarageDoorActiveConfiguration = Pick<
    GarageDoorActiveConfiguration,
    keyof GarageDoorActiveConfiguration
>;
