import { Injectable } from '@angular/core';
import { core } from '../helpers';

@Injectable()
export class ColorRestrictionService {
    isColorGroupNotRestricted(colorGroup) {
        return !(
            colorGroup?.max_sheet_metal_height ||
            colorGroup?.min_sheet_metal_height ||
            colorGroup?.max_sheet_metal_width ||
            colorGroup?.min_sheet_metal_width
        );
    }

    isColorGroupBetweenMinAndMaxMetalSheetHeight(colorGroup, sashHeight: number) {
        const min = colorGroup?.min_sheet_metal_height ?? 0;
        const max = colorGroup?.max_sheet_metal_height ?? Number.MAX_VALUE;

        return core.isNumberInRange(sashHeight, Number(min), Number(max));
    }

    isColorGroupBetweenMinAndMaxMetalSheetWidth(colorGroup, sashWidth: number) {
        const min = colorGroup?.min_sheet_metal_width ?? 0;
        const max = colorGroup?.max_sheet_metal_width ?? Number.MAX_VALUE;

        return core.isNumberInRange(sashWidth, Number(min), Number(max));
    }
}
