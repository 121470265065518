import { isUndefined, StepsService, isDefined } from '@icc/helpers';
import { Injectable } from '@angular/core';
import { ConfigurationsService, ConfiguratorsDataService, EventBusService } from '@icc/common';
import { CurrentConfiguratorService } from '@icc/common/configurators/current-configurator.service';
import { MontagesService } from './montages.service';
import { PriceService } from '@icc/price';
import { ColorsService } from './colors.service';
import { MosquitoNetType } from '@icc/common/data-types/Mosquito';

@Injectable()
export class TypesService {
    types = [];
    netTypes = [];
    loadedData = false;

    constructor(
        private configurationsService: ConfigurationsService<'mosquito' | 'window'>,
        private currentConfiguratorService: CurrentConfiguratorService,
        private configuratorsDataService: ConfiguratorsDataService,
        private montagesService: MontagesService,
        private priceService: PriceService,
        private eventBusService: EventBusService,
        private stepsService: StepsService,
        private colorsService: ColorsService
    ) {
        if (this.configuratorsDataService.loaded) {
            this.init();
        }

        this.eventBusService.subscribeWithoutConfiguration('initializedConfigurator', () => {
            this.init();
        });
    }

    /**
     * Funkcja wywołana po zaladowaniu konfiguracji
     */
    private init() {
        if (this.configurationsService.conf && this.configurationsService.conf.Current.type === 'mosquito') {
            this.types = this.getTypes(this.configurationsService.conf.Current);
            if (isUndefined(this.configurationsService.conf.Current.Type.id)) {
                this.setDefaultValues();
            }
            this.loadedData = true;
        } else {
            return;
        }
    }

    /**
     * Pobranie typeow garazowych
     */
    getTypes(conf = this.configurationsService.conf.Current) {
        if (
            conf
            && conf.System
            && conf.System.id
            && this.configuratorsDataService.data.mosquitoTypes[conf.System.id]
        ) {
            return this.configuratorsDataService.data.mosquitoTypes[conf.System.id];
        } else {
            return [];
        }
    }

    /**
     * Wybor typeu
     * @param  {object}  type     Wybrany type
     * @param  {boolean} price    Czy przeliczyc ceny
     */
    selectType(type, price?) {
        if (
            this.configurationsService.conf.Current.type !== 'mosquito'
            || this.configurationsService.conf.Default.type !== 'mosquito'
        ) {
            return;
        }
        this.configurationsService.conf.Current.Type = type;
        this.configurationsService.conf.Default.Type = type;

        if (type.has_direction && this.configurationsService.conf.Current.Direction === null) {
            this.configurationsService.conf.Current.Direction = 'left';
        } else if (!type.has_direction) {
            this.configurationsService.conf.Current.Direction = null;
        }
        if (price) {
            this.priceService.count();
        }
        this.montagesService.setDefaultValues();
        this.colorsService.loadColorsBySystem(
            this.configurationsService.conf.Current.System.id,
            type.id
        );

        this.setDefaultNetType();

        if (
            this.configuratorsDataService.data.mosquitoWindowLines[
                this.configurationsService.conf.Current.System.id
            ]
            || (this.configurationsService.conf.Current.Type
                && this.configurationsService.conf.Current.Type.id
                && (this.configuratorsDataService.data.mosquitoMontages.some(
                    el =>
                        this.configurationsService.conf.Current.type === 'mosquito'
                        && el.types.indexOf(this.configurationsService.conf.Current.Type.id) > -1
                )
                    || this.configurationsService.conf.Current.Type.profile_position
                    || this.configurationsService.conf.Current.Type.muntin_position))
        ) {
            this.stepsService.enable('montages');
        } else {
            this.stepsService.disable('montages');
        }
    }

    setNetType(netType: MosquitoNetType) {
        if (
            this.configurationsService.conf.Current.type !== 'mosquito'
            || this.configurationsService.conf.Default.type !== 'mosquito'
        ) {
            return;
        }
        this.configurationsService.conf.Current.NetType = netType;
    }

    /**
     * Ustawienie domyslnych wartosci po uruchomieniu
     */
    setDefaultValues() {
        this.types = this.getTypes();
        if (isDefined(this.types[0])) {
            this.selectType(this.types[0]);
        } else {
            this.selectType({});
        }
    }

    changeDirection(direction: 'left' | 'right') {
        if (
            this.configurationsService.conf.Current.type !== 'mosquito'
            || this.configurationsService.conf.Default.type !== 'mosquito'
        ) {
            return;
        }
        this.configurationsService.conf.Current.Direction = direction;
    }

    getNetTypes() {
        const conf = this.configurationsService.conf.Current;
        if (
            conf.type !== 'mosquito'
        ) {
            return [];
        }
        return (this.configuratorsDataService.data.mosquitoNetTypes || []).filter(
            type => type.types.indexOf(conf.Type.id) > -1
        );
    }

    private setDefaultNetType() {
        if (
            this.configurationsService.conf.Current.type !== 'mosquito'
            || this.configurationsService.conf.Default.type !== 'mosquito'
        ) {
            return;
        }
        const netTypes = this.getNetTypes();
        const netType = this.configurationsService.conf.Current.NetType;
        if (!netType || !netTypes.some(type => type.id === netType.id)) {
            this.setNetType(netTypes[0]);
        }
    }
}
