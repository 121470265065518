import { ActiveConfiguration } from './ActiveConfiguration';

import { WindowActiveConfiguration } from './WindowActiveConfiguration';
import { DoorConfiguration } from './DoorConfiguration';
import { ConfiguratorsDataService } from '../configurators/configurators-data.service';
import { Shape, DoorHardwareV2 } from '@icc/window';
import { IccFilling } from '../data-types/Filling';
import { ColorV2 } from './parts/common/Colors';
import { HardwareVisualization, IccSideColors, IccColors, IccWood } from '../data-types';
import { core } from '../helpers';
import { AppConfig } from '../app-config';

export class DoorActiveConfiguration extends WindowActiveConfiguration
    implements ActiveConfiguration {
    type: 'door' = 'door';

    Height = 2000;
    Width = 1000;
    Shape: Shape = {
        shape: 'rect',
        width: 1000,
        height: 2000,
        circuit: 6000,
        s1: 1000,
        s2: 0,
        s3: 0,
        h1: 2000,
        h2: 0,
        h3: 2000,
    };
    Factors = {
        thermalTranssmitance: {
            door: null,
            doorLights: {
                left: null,
                right: null,
                top: null,
                total: null
            },
            construction: null
        }
    };


    Model: any | null = null;
    ModelOptions: {
        panelType: 'Inset' | 'Outer' | 'Double' | 'Inner' | 'Mixed';
        panelGlazing: Partial<IccFilling> | null;
        panelInner:
            | Partial<IccFilling> & {
                  selectedColor?: IccColors;
                  selectedColorSecond?: IccColors;
                  selectedWood?: IccWood;
              }
            | null;
        selectedColor?: Partial<IccSideColors>;
        selectedColorSecond?: Partial<IccSideColors>;
        innerSelectedColor?: Partial<IccSideColors>;
        innerSelectedColorSecond?: Partial<IccSideColors>;
        passiveSelectedColor?: Partial<IccSideColors>;
        passiveSelectedColorSecond?: Partial<IccSideColors>;
        passiveSashModel?:
            | Partial<IccFilling> & {
                  selectedColor?: IccColors;
                  selectedColorSecond?: IccColors;
                  selectedWood?: IccWood;
              }
            | null;
        hasBeenChosen: boolean;
    } = {
        panelType: 'Inset',
        panelGlazing: null,
        panelInner: null,
        hasBeenChosen: false,
    };
    SashesType: 'Fix' | 'FixSash' | 'Func' | 'Mix' = 'Func';
    OwnedSashesTypes = {
        window: false,
        doorActive: true,
        doorPassive: false,
        doorTopLight: false,
        doorLeftLight: false,
        doorRightLight: false,
    };
    destination: string = '';
    HandleType = 'DoubleLever';

    doorHardwareDictionary: {
        /**
         * Osprzęt drzwi
         */
        doorHardware: Record<DoorHardwareV2['id'], DoorHardwareV2>;
        /**
         * Systemy akcesoriów
         */
        hardwareSystems: Record<DoorHardwareV2['id'], DoorHardwareV2>;
        /**
         * Kolory dodatków (klamek, zawiasów)
         */
        accessoryColors: Record<ColorV2['id'], ColorV2>;
    } = {
        doorHardware: {},
        hardwareSystems: {},
        accessoryColors: {},
    };
    doorHardware: {
        doorHandleAdjustment: null | number;
        type: null | number;
        set: null | 'leverPull' | 'doubleLever' | 'leverKnob';
        pull: null | number;
        pullColor: null | number;
        pullColorRelatedToPlate: boolean;
        electronicLock: null | number;
        beaconProximity: boolean;
        color: null | number;
        plateType: null | number;
        doorViewer: null | number;
        doorViewerPunching: boolean;
        plates: {
            mainLock: null | number;
            additionalLock: null | number;
            color: null | number;
        };
        cylinders: {
            mainLock: null | number;
            additionalLock: null | number;
            system: null | number;
            color: null | number;
            isKeyAvailableForMainLock: null | boolean;
            maxKeyQuantityForMainLock: null | number;
            keyQuantityForMainLock: null | number;
            keyPriceForMainLock: null | number;
            isKeyAvailableForAdditionalLock: null | boolean;
            maxKeyQuantityForAdditionalLock: null | number;
            keyQuantityForAdditionalLock: null | number;
            keyPriceForAdditionalLock: null | number;
        };
        decorativeHingeCovers: null | number;
        decorativeHingeCoversColor: null | number;
        decorativeHingeCoversColorRelatedToPlate: boolean;
        visualizations: {
            pull: HardwareVisualization | null;
            doorViewer: HardwareVisualization | null;
            mainLockPlate: HardwareVisualization | null;
            additionalLockPlate: HardwareVisualization | null;
            mainLockCylinder: HardwareVisualization | null;
            additionalLockCylinder: HardwareVisualization | null;
            decorativeHingeCovers: HardwareVisualization | null;
        };
    } = {
        doorHandleAdjustment: null,
        type: null,
        set: null,
        pull: null,
        pullColor: null,
        pullColorRelatedToPlate: null,
        electronicLock: null,
        beaconProximity: false,
        color: null,
        plateType: null,
        doorViewer: null,
        doorViewerPunching: false,
        plates: {
            mainLock: null,
            additionalLock: null,
            color: null,
        },
        cylinders: {
            mainLock: null,
            additionalLock: null,
            system: null,
            color: null,
            isKeyAvailableForMainLock: null,
            maxKeyQuantityForMainLock: null,
            keyQuantityForMainLock: null,
            keyPriceForMainLock: null,
            isKeyAvailableForAdditionalLock: null,
            maxKeyQuantityForAdditionalLock: null,
            keyQuantityForAdditionalLock: null,
            keyPriceForAdditionalLock: null,
        },
        decorativeHingeCovers: null,
        decorativeHingeCoversColor: null,
        decorativeHingeCoversColorRelatedToPlate: false,
        visualizations: {
            pull: null,
            doorViewer: null,
            mainLockPlate: null,
            additionalLockPlate: null,
            mainLockCylinder: null,
            additionalLockCylinder: null,
            decorativeHingeCovers: null
        },
    };

    // eslint-disable-next-line max-statements
    constructor(
        configuration?,
        configuratorsDataService?: ConfiguratorsDataService,
        langCode = configuration?.langCode || 'pl',
        config: AppConfig = null,
    ) {
        super(configuration, configuratorsDataService, langCode, config);
        if (configuration) {
            if (DoorActiveConfiguration.is(configuration)) {
                for (const propName in configuration) {
                    if (this.hasOwnProperty(propName)) {
                        this[propName] = configuration[propName];
                    }
                }
            } else {
                if (DoorConfiguration.is(configuration) && configuratorsDataService) {
                    if (configuration.sashes) {
                        const sashWithDecoPanel = configuration.sashes.find(
                            sash =>
                                sash.filling
                                && ['DRA', 'DOA'].includes(
                                    configuration.dictionary.sashTypes[sash.type.id].type
                                )
                                && (configuration.dictionary.fillings[sash.filling.id].type
                                    === 'deco_panels'
                                    || configuration.dictionary.fillings[sash.filling.id].type
                                        === 'door_panels')
                        );
                        const passiveSashWithDecoPanel = configuration.sashes.find(
                            sash =>
                                sash.type
                                && configuration.dictionary.sashTypes[sash.type.id]
                                && ['DRP', 'DOP'].includes(
                                    configuration.dictionary.sashTypes[sash.type.id].type
                                )
                                && sash.filling
                                && (configuration.dictionary.fillings[sash.filling.id].type
                                    === 'deco_panels'
                                    || configuration.dictionary.fillings[sash.filling.id].type
                                        === 'door_panels')
                        );
                        if (sashWithDecoPanel && sashWithDecoPanel.filling) {
                            this.Model = core.copy(configuratorsDataService.data.fillings.find(e => e.id === sashWithDecoPanel.filling.id))
                                || core.copy(
                                    configuratorsDataService.data.fillings.find(
                                        e => e.id.split('.')[0] === sashWithDecoPanel.filling.id
                                    )
                                )
                                || {}
                            this.Model.selectedColor = !sashWithDecoPanel.filling.color
                                ? null
                                : {
                                      frame: {
                                          inner: this.convertName(
                                              configuration.dictionary.colors[
                                                  Number(sashWithDecoPanel.filling.color.inner.id)
                                                  + '+'
                                                  + Number(sashWithDecoPanel.filling.color.inner.RAL)
                                              ]
                                          ),
                                          outer: this.convertName(
                                              configuration.dictionary.colors[
                                                  Number(sashWithDecoPanel.filling.color.outer.id)
                                                  + '+'
                                                  + Number(sashWithDecoPanel.filling.color.outer.RAL)
                                              ]
                                          ),
                                          core: this.convertName(
                                              configuration.dictionary.colors[
                                                  Number(sashWithDecoPanel.filling.color.core.id)
                                                  + '+'
                                                  + Number(sashWithDecoPanel.filling.color.core.RAL)
                                              ]
                                          ),
                                      },
                                      sash: {
                                          inner: this.convertName(
                                              configuration.dictionary.colors[
                                                  Number(sashWithDecoPanel.filling.color.inner.id)
                                                  + '+'
                                                  + Number(sashWithDecoPanel.filling.color.inner.RAL)
                                              ]
                                          ),
                                          outer: this.convertName(
                                              configuration.dictionary.colors[
                                                  Number(sashWithDecoPanel.filling.color.outer.id)
                                                  + '+'
                                                  + Number(sashWithDecoPanel.filling.color.outer.RAL)
                                              ]
                                          ),
                                          core: this.convertName(
                                              configuration.dictionary.colors[
                                                  Number(sashWithDecoPanel.filling.color.core.id)
                                                  + '+'
                                                  + Number(sashWithDecoPanel.filling.color.core.RAL)
                                              ]
                                          ),
                                      },
                                  };
                            this.Model.selectedColorSecond = !sashWithDecoPanel.filling.overlayColor
                                ? null
                                : {
                                      frame: {
                                          inner: this.convertName(
                                              configuration.dictionary.colors[
                                                  Number(sashWithDecoPanel.filling.overlayColor.inner.id)
                                                  + '+'
                                                  + Number(sashWithDecoPanel.filling.overlayColor.inner.RAL)
                                              ]
                                          ),
                                          outer: this.convertName(
                                              configuration.dictionary.colors[
                                                  Number(sashWithDecoPanel.filling.overlayColor.outer.id)
                                                  + '+'
                                                  + Number(sashWithDecoPanel.filling.overlayColor.outer.RAL)
                                              ]
                                          ),
                                          core: this.convertName(
                                              configuration.dictionary.colors[
                                                  Number(sashWithDecoPanel.filling.overlayColor.core.id)
                                                  + '+'
                                                  + Number(sashWithDecoPanel.filling.overlayColor.core.RAL)
                                              ]
                                          ),
                                      },
                                      sash: {
                                          inner: this.convertName(
                                              configuration.dictionary.colors[
                                                  Number(sashWithDecoPanel.filling.overlayColor.inner.id)
                                                  + '+'
                                                  + Number(sashWithDecoPanel.filling.overlayColor.inner.RAL)
                                              ]
                                          ),
                                          outer: this.convertName(
                                              configuration.dictionary.colors[
                                                  Number(sashWithDecoPanel.filling.overlayColor.outer.id)
                                                  + '+'
                                                  + Number(sashWithDecoPanel.filling.overlayColor.outer.RAL)
                                              ]
                                          ),
                                          core: this.convertName(
                                              configuration.dictionary.colors[
                                                Number(sashWithDecoPanel.filling.overlayColor.core.id)
                                                + '+'
                                                + Number(sashWithDecoPanel.filling.overlayColor.core.RAL)
                                              ]
                                          ),
                                      },
                                  };
                            this.ModelOptions.panelType = sashWithDecoPanel.panelType || 'Inset';
                            this.ModelOptions.panelGlazing = sashWithDecoPanel.panelGlazing
                                ? configuratorsDataService.get<IccFilling>(
                                      'fillings',
                                      sashWithDecoPanel.panelGlazing.id
                                  ) || null
                                : null;
                            this.ModelOptions.panelInner = sashWithDecoPanel.panelInner
                                ? core.copy(configuratorsDataService.data.fillings.find(e => Number(e.id) === Number(sashWithDecoPanel.panelInner.id)))
                                    || core.copy(
                                        configuratorsDataService.data.fillings.find(
                                            e => Number(e.id.split('.')[0]) === Number(sashWithDecoPanel.panelInner.id)
                                        )
                                    ) || null
                                : null;
                            if (this.ModelOptions.panelInner) {
                                this.ModelOptions.panelInner.selectedColor =
                                    !sashWithDecoPanel.panelInner || !sashWithDecoPanel.panelInner.color
                                        ? null
                                        : {
                                            frame: {
                                                inner: this.convertName(
                                                    configuration.dictionary.colors[
                                                        Number(sashWithDecoPanel.panelInner.color.inner.id)
                                                        + '+'
                                                        + Number(sashWithDecoPanel.panelInner.color.inner.RAL)
                                                    ]
                                                ),
                                                outer: this.convertName(
                                                    configuration.dictionary.colors[
                                                        Number(sashWithDecoPanel.panelInner.color.outer.id)
                                                        + '+'
                                                        + Number(sashWithDecoPanel.panelInner.color.outer.RAL)
                                                    ]
                                                ),
                                                core: this.convertName(
                                                    configuration.dictionary.colors[
                                                        Number(sashWithDecoPanel.panelInner.color.core.id)
                                                        + '+'
                                                        + Number(sashWithDecoPanel.panelInner.color.core.RAL)
                                                    ]
                                                ),
                                            },
                                            sash: {
                                                inner: this.convertName(
                                                    configuration.dictionary.colors[
                                                        Number(sashWithDecoPanel.panelInner.color.inner.id)
                                                        + '+'
                                                        + Number(sashWithDecoPanel.panelInner.color.inner.RAL)
                                                    ]
                                                ),
                                                outer: this.convertName(
                                                    configuration.dictionary.colors[
                                                        Number(sashWithDecoPanel.panelInner.color.outer.id)
                                                        + '+'
                                                        + Number(sashWithDecoPanel.panelInner.color.outer.RAL)
                                                    ]
                                                ),
                                                core: this.convertName(
                                                    configuration.dictionary.colors[
                                                        Number(sashWithDecoPanel.panelInner.color.core.id)
                                                        + '+'
                                                        + Number(sashWithDecoPanel.panelInner.color.core.RAL)
                                                    ]
                                                ),
                                            },
                                        };
                                this.ModelOptions.panelInner.selectedColorSecond =
                                    !sashWithDecoPanel.panelInner
                                    || !sashWithDecoPanel.panelInner.overlayColor
                                        ? null
                                        : {
                                            frame: {
                                                inner: this.convertName(
                                                    configuration.dictionary.colors[
                                                        Number(sashWithDecoPanel.panelInner.overlayColor.inner.id)
                                                        + '+'
                                                        + Number(sashWithDecoPanel.panelInner.overlayColor.inner.RAL)
                                                    ]
                                                ),
                                                outer: this.convertName(
                                                    configuration.dictionary.colors[
                                                        Number(sashWithDecoPanel.panelInner.overlayColor.outer.id)
                                                        + '+'
                                                        + Number(sashWithDecoPanel.panelInner.overlayColor.outer.RAL)
                                                    ]
                                                ),
                                                core: this.convertName(
                                                    configuration.dictionary.colors[
                                                        Number(sashWithDecoPanel.panelInner.overlayColor.core.id)
                                                        + '+'
                                                        + Number(sashWithDecoPanel.panelInner.overlayColor.core.RAL)
                                                    ]
                                                ),
                                            },
                                            sash: {
                                                inner: this.convertName(
                                                    configuration.dictionary.colors[
                                                        Number(sashWithDecoPanel.panelInner.overlayColor.inner.id)
                                                        + '+'
                                                        + Number(sashWithDecoPanel.panelInner.overlayColor.inner.RAL)
                                                    ]
                                                ),
                                                outer: this.convertName(
                                                    configuration.dictionary.colors[
                                                        Number(sashWithDecoPanel.panelInner.overlayColor.outer.id)
                                                        + '+'
                                                        + Number(sashWithDecoPanel.panelInner.overlayColor.outer.RAL)
                                                    ]
                                                ),
                                                core: this.convertName(
                                                    configuration.dictionary.colors[
                                                        Number(sashWithDecoPanel.panelInner.overlayColor.core.id)
                                                        + '+'
                                                        + Number(sashWithDecoPanel.panelInner.overlayColor.core.RAL)
                                                    ]
                                                ),
                                            },
                                        };
                            }
                            this.ModelOptions.selectedColor =
                                this.Model.selectedColor && this.Model.selectedColor.frame;
                            this.ModelOptions.selectedColorSecond =
                                this.Model.selectedColorSecond
                                && this.Model.selectedColorSecond.frame;

                            this.ModelOptions.innerSelectedColor =
                                this.ModelOptions.panelInner
                                && this.ModelOptions.panelInner.selectedColor
                                && this.ModelOptions.panelInner.selectedColor.frame;
                            this.ModelOptions.innerSelectedColorSecond =
                                this.ModelOptions.panelInner
                                && this.ModelOptions.panelInner.selectedColorSecond
                                && this.ModelOptions.panelInner.selectedColorSecond.frame;
                        }
                        if (passiveSashWithDecoPanel && passiveSashWithDecoPanel.filling) {
                            this.ModelOptions.passiveSashModel = configuratorsDataService.get(
                                'fillings',
                                sashWithDecoPanel.filling.id
                            );
                            this.ModelOptions.passiveSashModel.selectedColor = !passiveSashWithDecoPanel
                                .filling.color
                                ? null
                                : {
                                      frame: {
                                          inner: this.convertName(
                                              configuration.dictionary.colors[
                                                  Number(passiveSashWithDecoPanel.filling.color.inner.id)
                                                  + '+'
                                                  + Number(passiveSashWithDecoPanel.filling.color.inner.RAL)
                                              ]
                                          ),
                                          outer: this.convertName(
                                              configuration.dictionary.colors[
                                                  Number(passiveSashWithDecoPanel.filling.color.outer.id)
                                                  + '+'
                                                  + Number(passiveSashWithDecoPanel.filling.color.outer.RAL)
                                              ]
                                          ),
                                          core: this.convertName(
                                              configuration.dictionary.colors[
                                                  Number(passiveSashWithDecoPanel.filling.color.core.id)
                                                  + '+'
                                                  + Number(passiveSashWithDecoPanel.filling.color.core.RAL)
                                              ]
                                          ),
                                      },
                                      sash: {
                                          inner: this.convertName(
                                              configuration.dictionary.colors[
                                                  Number(passiveSashWithDecoPanel.filling.color.inner.id)
                                                  + '+'
                                                  + Number(passiveSashWithDecoPanel.filling.color.inner.RAL)
                                              ]
                                          ),
                                          outer: this.convertName(
                                              configuration.dictionary.colors[
                                                  Number(passiveSashWithDecoPanel.filling.color.outer.id)
                                                  + '+'
                                                  + Number(passiveSashWithDecoPanel.filling.color.outer.RAL)
                                              ]
                                          ),
                                          core: this.convertName(
                                              configuration.dictionary.colors[
                                                  Number(passiveSashWithDecoPanel.filling.color.core.id)
                                                  + '+'
                                                  + Number(passiveSashWithDecoPanel.filling.color.core.RAL)
                                              ]
                                          ),
                                      },
                                  };
                            this.ModelOptions.passiveSashModel.selectedColorSecond = !passiveSashWithDecoPanel
                                .filling.overlayColor
                                ? null
                                : {
                                      frame: {
                                          inner: this.convertName(
                                              configuration.dictionary.colors[
                                                Number(passiveSashWithDecoPanel.filling.overlayColor.inner.id)
                                                + '+'
                                                + Number(passiveSashWithDecoPanel.filling.overlayColor.inner.RAL)
                                              ]
                                          ),
                                          outer: this.convertName(
                                              configuration.dictionary.colors[
                                                Number(passiveSashWithDecoPanel.filling.overlayColor.outer.id)
                                                + '+'
                                                + Number(passiveSashWithDecoPanel.filling.overlayColor.outer.RAL)
                                              ]
                                          ),
                                          core: this.convertName(
                                              configuration.dictionary.colors[
                                                Number(passiveSashWithDecoPanel.filling.overlayColor.core.id)
                                                + '+'
                                                + Number(passiveSashWithDecoPanel.filling.overlayColor.core.RAL)
                                              ]
                                          ),
                                      },
                                      sash: {
                                          inner: this.convertName(
                                              configuration.dictionary.colors[
                                                Number(passiveSashWithDecoPanel.filling.overlayColor.inner.id)
                                                + '+'
                                                + Number(passiveSashWithDecoPanel.filling.overlayColor.inner.RAL)
                                              ]
                                          ),
                                          outer: this.convertName(
                                              configuration.dictionary.colors[
                                                Number(passiveSashWithDecoPanel.filling.overlayColor.outer.id)
                                                + '+'
                                                + Number(passiveSashWithDecoPanel.filling.overlayColor.outer.RAL)
                                              ]
                                          ),
                                          core: this.convertName(
                                              configuration.dictionary.colors[
                                                Number(passiveSashWithDecoPanel.filling.overlayColor.core.id)
                                                + '+'
                                                + Number(passiveSashWithDecoPanel.filling.overlayColor.core.RAL)
                                              ]
                                          ),
                                      },
                                  };
                            this.ModelOptions.passiveSelectedColor =
                                this.ModelOptions.passiveSashModel.selectedColor
                                && this.ModelOptions.passiveSashModel.selectedColor.frame;
                            this.ModelOptions.passiveSelectedColorSecond =
                                this.ModelOptions.passiveSashModel.selectedColorSecond
                                && this.ModelOptions.passiveSashModel.selectedColorSecond.frame;
                        }
                    }
                    this.Height = configuration.height;
                    this.SashesType = configuration.sashesType;
                    this.Width = configuration.width;
                    this.HandleType = configuration.handleType;
                    this.OwnedSashesTypes = configuration.ownedSashesTypes;
                    this.Shape = configuration.shape;
                    this.doorHardware = configuration.doorHardware;
                    this.doorHardwareDictionary.doorHardware =
                        configuration.dictionary.doorHardware;
                    this.doorHardwareDictionary.hardwareSystems =
                        configuration.dictionary.hardwareSystems;
                    this.doorHardwareDictionary.accessoryColors =
                        configuration.dictionary.accessoryColors;
                }
            }
        }
    }

    public static is(configuration: any): configuration is DoorActiveConfiguration {
        return (
            configuration instanceof DoorActiveConfiguration
            || (!configuration.$version && configuration.type === 'door')
        );
    }
}
