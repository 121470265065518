import { BrowserModule, DomSanitizer } from '@angular/platform-browser';
import { NgModule, ModuleWithProviders, Provider, Type, APP_INITIALIZER, ErrorHandler } from '@angular/core';

import { AppComponent } from './app.component';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ConfiguratorUiModule } from '@icc/configurator/ui';
import { AppRoutingModule } from './app-routing.module';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import {
    initialState as rootInitialState,
    configuratorReducer,
    configuratorRootReducer,
} from './+state/root.reducer';
import { ConfiguratorEffects } from './+state/root.effects';
import { NxModule } from '@nrwl/nx';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '../environments/environment';

import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { ConfiguratorWindowModule } from '@icc/configurator/window';
import { PreviewComponent } from './preview/preview.component';
import { IccDrawModule } from '@icc/draw';
import { InitService } from './init.service';
import { ServiceWorkerModule } from '@angular/service-worker';
import { TranslateModule, TranslateLoader, TranslateParser, TranslateCompiler, MissingTranslationHandler, TranslateService as NgxTranslateService } from '@ngx-translate/core';
import {
    SharedModule,
    SharedFacade,
    StepsService,
    InfoService,
    CurrencyPipe,
    IccTranslateParser,
    TranslateMessageFormatCompiler,
} from '@icc/configurator/shared';
import { NotificationsPanelComponent } from './notifications-panel/notifications-panel.component';
import { ConfiguratorConfigurationsService } from './configurations.service';
import { APP_CONFIG, AppConfig, AppConfigFactory } from '@icc/common/config';
import { EventBusService } from '@icc/common/event-bus.service';
import { ConfiguratorsDataService } from '@icc/common/configurators/configurators-data.service';
import { ConfiguratorConfiguratorsDataService } from './configurators-data.service';
import { DatabaseManagerToken } from '@icc/helpers/browser';
import { ConfiguratorDatabaseManager } from './database-manager';
import {
    TranslateService,
    ConfigurationsService,
    ValidationService,
    StateService,
    DrawService,
} from '@icc/common';
import { ConfiguratorTranslateService, MyMissingTranslationHandler } from './translate.service';
import { CommonModule } from '@angular/common';
import { TranslateHttpLoader } from './translate-http-loader';
import { CurrentConfiguratorService } from '@icc/common/configurators/current-configurator.service';
import { ConfiguratorCurrentConfiguratorService } from './current-configurator.service';
import { ConfiguratorStepsService } from './step/steps.service';
import { ConfiguratorPriceModule } from '@icc/legacy/price/price.module';
import { ProfilesPriceService } from '@icc/common/profiles-price.service';
import { PriceModule, PriceDetailsModule, DiscountsAndMultipliersService } from '@icc/price/b2b';
import { ConfiguratorUserService } from './user.service';
import { UserService } from '@icc/common/user.service';
import { CustomPricesService } from '@icc/common/custom-price/custom-prices.service';
import { ConfiguratorCustomPricesService } from './custom-prices.service';
import { BrowserDiscountsAndMultipliersService } from '@icc/legacy/price/discounts-and-multipliers.service';
import { WarrantyService } from '@icc/legacy/price/warranty.service';
import { ConfiguratorInfoService } from './info.service';
import { ConfiguratorDoorModule } from '@icc/configurator/door';
import { ConfiguratorShutterModule } from '@icc/configurator/shutter';
import { MatDialogModule } from '@angular/material/dialog';
import { ConfiguratorMosquitoScreenModule } from '@icc/configurator/mosquito-screen';
import { ConfiguratorAccessoryModule } from '@icc/configurator/accessory';
import { ConfiguratorPleatedBlindModule } from '@icc/configurator/pleated-blind';
import { ConfiguratorsAvailabilityService } from '@icc/legacy/configurator/configurators-availability.service';
import { HotkeyModule } from 'angular2-hotkeys';
import { ConfiguratorAwningModule } from '@icc/configurator/awning';
import { SaveInformationComponent } from './save-information/save-information.component';
import { ConfiguratorGarageDoorModule } from '@icc/configurator/garage-door';
import { TimeLimitModule } from '@icc/common/time-limit/time-limit.module';
import { ConfiguratorCoupledWindowModule } from '@icc/configurator/coupled-window';
import { ConstructionIssuesModalComponent } from './construction-issues-modal/construction-issues-modal.component';
import * as Sentry from "@sentry/angular";
import { AnomalyService } from './anomaly.service';

export function HttpLoaderFactory(http: HttpClient, sharedFacade: SharedFacade, domSanitizer: DomSanitizer, config: AppConfigFactory) {
    return new TranslateHttpLoader(http, sharedFacade, domSanitizer, config);
}

export function loadOptionsFactory(initService: InitService) {
    return () => {
        return initService.loadOptionsFromServer();
    };
}

export function appConfigFactory(initService: InitService): () => Partial<AppConfig> {
    return () => {
        return initService.getAppConfig();
    };
}

const commonProviders: Provider[] = [
    InitService,
    ConfiguratorConfigurationsService,
    { provide: APP_CONFIG, useFactory: appConfigFactory, deps: [InitService] },
    EventBusService,
    ConfiguratorConfiguratorsDataService,
    ConfiguratorTranslateService,
    { provide: TranslateService, useExisting: ConfiguratorTranslateService },
    ConfiguratorCurrentConfiguratorService,
    { provide: CurrentConfiguratorService, useExisting: ConfiguratorCurrentConfiguratorService },
    ConfiguratorStepsService,
    { provide: StepsService, useExisting: ConfiguratorStepsService },
    ValidationService,
    ConfiguratorUserService,
    ProfilesPriceService,
    ConfiguratorCustomPricesService,
    { provide: CustomPricesService, useExisting: ConfiguratorCustomPricesService },
    WarrantyService,
    DrawService,
    ConfiguratorInfoService,
    { provide: InfoService, useExisting: ConfiguratorInfoService },
    ConfiguratorsAvailabilityService,
    AnomalyService,
];

const rootProviders: Provider[] = [
    { provide: APP_INITIALIZER, useFactory: loadOptionsFactory, deps: [InitService], multi: true },
    StateService,
    BrowserDiscountsAndMultipliersService,
    {
        provide: DiscountsAndMultipliersService,
        useExisting: BrowserDiscountsAndMultipliersService,
    },
    { provide: ConfigurationsService, useExisting: ConfiguratorConfigurationsService },
    { provide: ConfiguratorsDataService, useExisting: ConfiguratorConfiguratorsDataService },
    { provide: DatabaseManagerToken, useClass: ConfiguratorDatabaseManager },
    { provide: UserService, useExisting: ConfiguratorUserService },
    { 
        provide: ErrorHandler,
        useValue: Sentry.createErrorHandler(), 
    },
];

@NgModule({
    declarations: [
        AppComponent,
        PreviewComponent,
        NotificationsPanelComponent,
        SaveInformationComponent,
        ConstructionIssuesModalComponent,
    ],
    imports: [
        CommonModule,
        SharedModule,
        ConfiguratorUiModule,
        ConfiguratorWindowModule,
        ConfiguratorDoorModule,
        ConfiguratorShutterModule,
        ConfiguratorPleatedBlindModule,
        ConfiguratorMosquitoScreenModule,
        ConfiguratorCoupledWindowModule,
        IccDrawModule,
        HotkeyModule.forRoot(),
    ],
    exports: [
        AppComponent,
        PreviewComponent,
        NotificationsPanelComponent,
        SharedModule,
        ConfiguratorWindowModule,
        ConfiguratorUiModule,
        ConfiguratorDoorModule,
        ConfiguratorShutterModule,
        ConfiguratorPleatedBlindModule,
        ConfiguratorMosquitoScreenModule,
        ConfiguratorAccessoryModule,
        ConfiguratorAwningModule,
        ConfiguratorGarageDoorModule,
        ConfiguratorCoupledWindowModule,
        IccDrawModule,
        SaveInformationComponent,
    ],
    entryComponents: [NotificationsPanelComponent, AppComponent, SaveInformationComponent, ConstructionIssuesModalComponent],
})
export class SharedAppModule {}

const imports: (any[] | Type<any> | ModuleWithProviders<{}>)[] = [
    SharedAppModule,
    HttpClientModule,
    ConfiguratorUiModule,
    AppRoutingModule,
    // MatDialogModule,
    NxModule.forRoot(),
    EffectsModule.forRoot([ConfiguratorEffects]),
    !environment.production ? StoreDevtoolsModule.instrument() : [],
    StoreRouterConnectingModule.forRoot(),
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    TranslateModule.forRoot({
        loader: {
            provide: TranslateLoader,
            useFactory: HttpLoaderFactory,
            deps: [HttpClient, SharedFacade, DomSanitizer, APP_CONFIG],
        },
        parser: {
            provide: TranslateParser,
            useClass: IccTranslateParser,
            deps: [APP_CONFIG],
        },
        missingTranslationHandler: {
            provide: MissingTranslationHandler,
            useClass: MyMissingTranslationHandler
        },
        compiler: {
            provide: TranslateCompiler,
            useClass: TranslateMessageFormatCompiler
        }
    }),
    PriceModule,
    PriceDetailsModule,
    TimeLimitModule,
];

@NgModule({
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        StoreModule.forRoot(
            {
                configurator: configuratorRootReducer,
            },
            {
                initialState: { configurator: { configurator: rootInitialState } },
                metaReducers: [],
                runtimeChecks: {
                    strictStateImmutability: false,
                    strictActionImmutability: false
                },
            }
        ),
        ...imports,
    ],
    providers: [
        ...commonProviders,
        ...rootProviders
    ],
    bootstrap: [AppComponent],
    exports: [],
})
export class AppModule {}

@NgModule({
    imports: [
        CommonModule,
        StoreModule.forFeature(
            'configurator',
            { configurator: configuratorReducer },
            {
                initialState: { configurator: rootInitialState },
                metaReducers: !environment.production ? [] : [],
            }
        ),
        ...imports,
    ],
    providers: commonProviders,
    bootstrap: [AppComponent],
    exports: [AppComponent],
})
export class NewConfiguratorModule {}

@NgModule({})
export class ConfiguratorModule {
    static forFeature(): ModuleWithProviders<NewConfiguratorModule> {
        return {
            ngModule: NewConfiguratorModule,
            providers: commonProviders,
        };
    }
}
