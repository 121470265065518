import { Component, OnInit, Inject } from '@angular/core';
import { PageComponent, ICC_PAGE_DATA, ModalService } from '@icc/helpers';
import { _, SharedFacade } from '@icc/configurator/shared';
import { IccAccessoryAccessory, IccAccessoryColor } from '@icc/common/data-types';
import {
    AppConfigFactory,
    APP_CONFIG,
    ConfigurationsService,
    ConfiguratorsDataService,
} from '@icc/common';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActiveSash } from '@icc/common/layout/active-sash';
import { Profile } from '@icc/window';
import { AccessoriesColorsPageComponent } from '../accessories-colors-page/accessories-colors-page.component';
import { BehaviorSubject } from 'rxjs';
import { PriceAccessoryService } from 'libs/price/src/lib/price-accessory.service';
import { UnitConverterService } from '@icc/configurator/ui';
import { MatOptionSelectionChange } from '@angular/material/core';
import { DoorActiveConfiguration } from '@icc/common/configurations/DoorActiveConfiguration';
import { PriceBaseService } from '@icc/price/b2b';

@Component({
    selector: 'icc-accessories-options-page',
    templateUrl: './accessories-options-page.component.html',
    styleUrls: ['./accessories-options-page.component.scss'],
})
export class AccessoriesOptionsPageComponent extends PageComponent implements OnInit {
    public title: string = _('ACCESSORY|Opcje dodatku');

    accessory: IccAccessoryAccessory;
    form: FormGroup = this.fb.group({
        count: 1,
        selectedColor: null,
        colorOptions: null,
        amount_width: 0,
        position: 0,
        amount_height: 0,
        amount: 0,
        comment: '',
    });
    focused = false;
    forIt: 'configuration' | 'sash' | 'side' = 'configuration';
    topFrameProfile: Profile | null = null;
    sash: ActiveSash | null = null;
    showCoreColorInAdditionalProfilesAndAccessories = this.config().IccConfig.Configurators
        .showCoreColorInAdditionalProfilesAndAccessories;
    currency: any = null;
    hiddenPrice = false;
    private price: number = null;

    dimensionUnit = this.unitConverterService.getUnit();

    constructor(
        private priceAccessoryService: PriceAccessoryService,
        private configurationsService: ConfigurationsService<'window'>,
        private priceBaseService: PriceBaseService,
        private sharedFacade: SharedFacade,
        private modalService: ModalService,
        private fb: FormBuilder,
        private configuratorsDataService: ConfiguratorsDataService,
        @Inject(APP_CONFIG) public config: AppConfigFactory,
        @Inject(ICC_PAGE_DATA)
        private pageData: {
            accessory: IccAccessoryAccessory;
            forIt: 'configuration' | 'sash' | 'side';
            sash?: ActiveSash;
            colors: IccAccessoryColor[];
        },
        private unitConverterService: UnitConverterService
    ) {
        super();
    }

    ngOnInit() {
        this.hiddenPrice = this.config().preset === 'b2c' && this.config().hidePricesInB2cConfigurator;

        this.accessory = this.pageData.accessory;
        this.forIt = this.pageData.forIt;
        this.sash = this.pageData.sash || null;
        if (
            this.config().IccConfig.Configurators.ventilatorPositioning
            && this.pageData.sash
            && this.pageData.sash.frame
            && this.pageData.sash.frame.top
        ) {
            this.topFrameProfile =
                this.configurationsService.conf.Current.UsedProfiles.find(
                    o => this.pageData.sash && o.id === this.pageData.sash.frame.top.profileId
                ) || null;
        }
        if (this.accessory.blockCountChange) {
            this.form.get('count').disable();
        }
        if (this.accessory.blockAmountChange) {
            this.form.get('amount').disable();
        }
        this.form.patchValue({
            count: this.accessory.count || 1,
            amount: this.accessory.amount || null,
            selectedColor: this.getSelectedColor(this.accessory),
            colorOptions: this.setDefaultColorOption(this.accessory)
        });

        this.sharedFacade.currency$.subscribe(currency => {
            this.currency = currency;
        });
        this.price = this.setAccessoryPrice();
        this.setColorOptionsPrice(this.pageData.accessory);

        
        const windowAccessoriesCategories = this.configuratorsDataService.data.windowAccessoriesCategories;
        const disableCount = windowAccessoriesCategories.find((category) => this.accessory.window_accessories_category_id === category.id)

        if (disableCount && disableCount.only_single_accessory) this.form.get('count').disable();
    }

    add() {
        const colorControl = this.form.get('selectedColor');
        Object.assign(this.accessory, this.form.value);
        this.sharedFacade.closePage({
            accessory: this.accessory,
            color:
                colorControl
                && this.pageData.colors.find(c => Number(c.id) === Number(colorControl.value)),
        });
    }

    setAccessoryColorPrice(color, event: MatOptionSelectionChange) {
        if (event.isUserInput && color) {
            this.price = this.priceAccessoryService.getPriceAccessoryColor(this.accessory, color.id);
        }
    }

    setColorOptionsPrice(accessory) {
        const selectedColorOption = this.form.get('colorOptions')?.value;

        switch (selectedColorOption) {
            case "white":
                this.price = (accessory?.price_white || 0);
                break;
            case "color-white":
                this.price = (accessory?.price_white_colour || 0);
                break;
            case "color-color":
                this.price = (accessory?.price_colour_colour || 0);
                break
            case "ral":
                this.price = (accessory?.price_ral || 0);
                break;
        }
    }

    isAccessoryColorFieldDisabled() {
        if (this.accessory && this.accessory?.color_related_to_plate && this.accessory?.selectedColor) {
            return this.accessory?.color_related_to_plate;
        } else {
            return false;
        }
    }

    getSelectedColor(accessory: IccAccessoryAccessory, conf = this.configurationsService.conf.Current as DoorActiveConfiguration) {
        if (accessory.color_related_to_plate && conf.doorHardware.plates && conf.doorHardware.plates.color) {
            const selectedPlate = conf.doorHardware.plates;
            return (selectedPlate.color).toString();
        } else {
            return accessory.selectedColor;
        }
    }

    getSelectedAccessories(conf = this.configurationsService.conf.Current) {
        return conf.Accessories;
    }

    setDefaultColorOption(accessory) {
        if (accessory?.price_white && Number(accessory.price_white) > 0) {
            return "white";
        } else if (accessory?.price_white_colour && Number(accessory.price_white_colour) > 0) {
            return "color-white";
        } else if (accessory?.price_colour_colour && Number(accessory.price_white) > 0) {
            return "color-color";
        } else if (accessory?.price_ral && Number(accessory.price_white) > 0) {
            return "ral";
        } else {
            return null;
        }
    }

    setAccessoryPrice() {
        if (!this.accessory.no_price /*&& !noPrice*/) {
            return this.priceAccessoryService.getPriceAccessoryOne(
                this.accessory,
                this.accessory.show_colors && this.accessory.price_source == 'table'
                    ? this.accessory.colorOptions
                    : this.accessory.selectedColor,
                this.configurationsService.conf.Current
            );
        }
    }

    getPrice() {
        return this.priceBaseService.addMarginMarketFactor(this.price, this.accessory.no_discount);
    }

    getAccessoryColors(accessory: IccAccessoryAccessory) {
        return (this.pageData.colors || []).filter(
            c => accessory.colors_ids && accessory.colors_ids.map(Number).includes(Number(c.id))
        );
    }

    openModalColors(accessory: IccAccessoryAccessory) {
        this.modalService.open({
            pageComponent: AccessoriesColorsPageComponent,
            resolve: {
                accessory,
                type: 'accessory',
                colors$: new BehaviorSubject(accessory.selectedColor),
            },
        });
    }
}
