import { Injectable, Inject } from '@angular/core';
import { WindowActiveConfiguration } from '@icc/common/configurations/WindowActiveConfiguration';
import { Frame, Profile, Coupling, SideProfile, Alignment, SashTypes } from '@icc/window';
import { EventBusService } from '@icc/common/event-bus.service';
import { logger } from '@icc/common/helpers';
import { ProfilesService } from '@icc/common/profiles.service';
import { ActiveMullion } from '@icc/common/layout/active-mullion';
import { CouplingsService } from './couplings.service';
import { FramesService } from '@icc/common/layout/frames.service';
import { ResizeSashService } from './resize-sash.service';
import {APP_CONFIG, AppConfig, AppConfigFactory} from '@icc/common/config';;
import { ResizeFrameService } from './resize-frame.service';
import { ParametersService } from '@icc/common/configurators/parameters.service';
import { PriceService } from '@icc/price';
import { BrowserShapeService } from './shape.service';
import { MullionsLayoutService } from './mullions-layout.service';
import { SashesLayoutService } from './sashes-layout.service';
import { WarrantyService } from '@icc/legacy/price/warranty.service';
import { ConstructionLimitationService } from '../steps/window/dimensions/construction-limitation.service';

@Injectable()
export class BrowserFramesService extends FramesService {
    minSize: number = this.config().IccConfig.Configurators.minWidth;
    minSizeGlass: number = this.config().IccConfig.Configurators.minWidthGlass;

    constructor(
        @Inject(APP_CONFIG) private config: AppConfigFactory,
        private eventBusService: EventBusService,
        private resizeSashService: ResizeSashService,
        private resizeFrameService: ResizeFrameService,
        private profilesService: ProfilesService,
        private warrantyService: WarrantyService,
        private parametersService: ParametersService,
        private priceService: PriceService,
        private shapeService: BrowserShapeService,
        private constructionLimitationService: ConstructionLimitationService,
        private mullionsLayoutService: MullionsLayoutService,
        private sashesLayoutService: SashesLayoutService
    ) {
        super();
        this.eventBusService.subscribe('setSystem', data => {
            try {
                this.validFrames(data.activeConfiguration as WindowActiveConfiguration);
            } catch (err) {
                logger.error(err);
            }
        });

        this.eventBusService.subscribe('setProfileSet', data => {
            try {
                this.validFrames(data.activeConfiguration as WindowActiveConfiguration);
            } catch (err) {
                logger.error(err);
            }
        });
    }

    putFrame(frame: Frame, conf: WindowActiveConfiguration) {
        conf.Frames.push(frame);
        this.eventBusService.post({
            key: 'changedFrames',
            value: null,
            conf
        });

        this.sashesLayoutService.setIndex(conf);
    }

    putCoupling(coupling: Coupling, profile: Profile, conf: WindowActiveConfiguration) {
        conf.couplings.push(coupling);
        this.eventBusService.post({
            key: 'setCouplingProfile',
            value: {
                profile,
                coupling,
            },
            conf
        });
    }

    changeCouplingProfile(
        conf: WindowActiveConfiguration,
        coupling: Coupling,
        profile: Profile,
        { isDefault = false }: { isDefault?: boolean }
    ) {
        const oldWidth = coupling.width;
        if (profile) {
            coupling.profileId = profile.id;
            coupling.width = profile.width;
            coupling.widthOut = profile.widthOut;
        } else {
            coupling.profileId = null;
        }
        coupling.isDefault = isDefault;

        const sizeDiff = coupling.width - oldWidth;

        const oneSideFrames = conf.Frames.filter(f =>
            coupling.framesId.some(fId => fId.id === f.id)
        );
        const otherSideFrames = conf.Frames.filter(f =>
            coupling.otherFramesId.some(fId => fId.id === f.id)
        );

        if (coupling.direction === 'horizontal') {
            oneSideFrames.forEach(frame => {
                this.resizeFrameService.resizeOneSideFrame(Math.floor(sizeDiff / 2), frame, conf, [
                    'bottom',
                    'right',
                    'rHeight',
                    'height',
                    'ry',
                ]);
            });

            otherSideFrames.forEach(frame => {
                this.resizeFrameService.resizeOtherSideFrame(Math.ceil(sizeDiff / 2), frame, conf, [
                    'top',
                    'right',
                    'rHeight',
                    'height',
                    'ry',
                    'y',
                    'vertical',
                    'multiAlignTop',
                ]);
            });

            conf.couplings.filter(f =>
                coupling.perpendicularCouplings.top.some(fId => fId === f.id)
            ).forEach(c => {
                c.length -= Math.floor(sizeDiff / 2);
            });
            conf.couplings.filter(f =>
                coupling.perpendicularCouplings.bottom.some(fId => fId === f.id)
            ).forEach(c => {
                c.length -= Math.ceil(sizeDiff / 2);
            });
        } else {
            oneSideFrames.forEach(frame => {
                this.resizeFrameService.resizeOneSideFrame(Math.floor(sizeDiff / 2), frame, conf, [
                    'right',
                    'bottom',
                    'rWidth',
                    'width',
                    'rx',
                ]);
            });

            otherSideFrames.forEach(frame => {
                this.resizeFrameService.resizeOtherSideFrame(Math.ceil(sizeDiff / 2), frame, conf, [
                    'left',
                    'bottom',
                    'rWidth',
                    'width',
                    'rx',
                    'x',
                    'horizontal',
                    'multiAlignLeft',
                ]);
            });

            conf.couplings.filter(f =>
                coupling.perpendicularCouplings.left.some(fId => fId === f.id)
            ).forEach(c => {
                c.length -= Math.floor(sizeDiff / 2);
            });
            conf.couplings.filter(f =>
                coupling.perpendicularCouplings.right.some(fId => fId === f.id)
            ).forEach(c => {
                c.length -= Math.ceil(sizeDiff / 2);
            });
        }

        this.eventBusService.post({
            key: 'setCouplingProfile',
            value: {
                profile,
                coupling,
            },
        });
        this.eventBusService.post({
            key: 'icc-redraw',
            value: 'frame',
        });
    }

    setDefaultFrame(conf: WindowActiveConfiguration) {
        conf.Frames = [];
        conf.couplings = [];
        const newFrame: Frame = new Frame({
            id: this.getIdForNew(conf),
            index: 1,
            height: conf.Height,
            width: conf.Width,
            x: 0,
            y: 0,
        });
        this.putFrame(newFrame, conf);
    }

    resetFrameDimensions(conf: WindowActiveConfiguration) {
        if (conf.Frames && conf.Frames.length === 1) {
            conf.Frames[0].width = conf.Width;
            conf.Frames[0].height = conf.Height;

            this.eventBusService.post({
                key: 'changedFrames',
                value: null,
            });
        } else {
            this.setDefaultFrame(conf);
        }
    }

    validFrames(conf: WindowActiveConfiguration) {
        if (conf.Frames.length === 0) {
            this.setDefaultFrame(conf);
        }
    }

    onTheEdgeOf(
        edge: 'left' | 'right' | 'top' | 'bottom',
        frame: Frame,
        conf: WindowActiveConfiguration
    ) {
        switch (edge) {
            case 'bottom':
                return frame.y + frame.height === conf.Height;
            case 'top':
                return frame.y === 0;
            case 'left':
                return frame.x === 0;
            case 'right':
                return frame.x + frame.width === conf.Width;
        }
    }

    /**
     * Zwraca indeksy krawędzi ram.
     * @param edge Krawędź
     * @param conf Konfiguracja
     *
     */
    getFrameProfilesIndexesOnTheEdge(
        edge: 'left' | 'right' | 'top' | 'bottom',
        conf: WindowActiveConfiguration
    ) {
        const sides = this.profilesService.getFrameSidesOnEdge(conf);
        const frameEdges = sides.find(s => s.side === edge);
        return frameEdges && frameEdges.frameEdges;
    }

    splitFrame(
        couplingProfile: Profile,
        mullion: ActiveMullion,
        conf: WindowActiveConfiguration
    ) {
        const oldFrame = conf.Frames.find(frame => frame.id === mullion.frameId);
        const sideProfilesOnEdge = this.getSideProfilesEdgeMap(conf);

        let newFrame: Frame;
        if (mullion.direction === 'horizontal') {
            const oldHeight = oldFrame.height;
            oldFrame.height = mullion.ry - Math.floor(couplingProfile.width / 2);

            newFrame = new Frame({
                id: this.getIdForNew(conf),
                index: 1,
                height: oldHeight - oldFrame.height - couplingProfile.width,
                width: oldFrame.width,
                x: oldFrame.x,
                y: oldFrame.y + oldFrame.height + couplingProfile.width,
            });
            mullion.multiAlignTop.forEach(sash => {
                sash.nearMullions.bottom = -1;
            });
            conf.Sashes.filter(
                sash => sash.ry >= mullion.ry && sash.frameId === mullion.frameId
            ).forEach(sash => {
                sash.frameId = newFrame.id;
                sash.ry -= mullion.ry + Math.ceil(couplingProfile.width / 2);
                if (sash.nearMullions.top === mullion.id) {
                    sash.nearMullions.top = -1;
                }
            });
            conf.Mullions.filter(
                m => m.ry >= mullion.ry && m.id !== mullion.id && m.frameId === mullion.frameId
            ).forEach(
                m => {
                    m.frameId = newFrame.id;
                    m.ry -= mullion.ry + Math.ceil(couplingProfile.width / 2);
                }
            );

            conf.Alignments.forEach(m => {
                if (
                    this.getAlignmentPosition(mullion.direction, m) > mullion.ry
                    || (this.getAlignmentPosition(mullion.direction, m) === mullion.ry
                        && m.side !== 'bottom')
                ) {
                    m.frameId = newFrame.id;
                    if (m.direction === mullion.direction) {
                        m.position -= mullion.ry + Math.ceil(couplingProfile.width / 2);
                    } else {
                        m.shift -= mullion.ry + Math.ceil(couplingProfile.width / 2);
                    }

                    m.perpendicularMullions.top = m.perpendicularMullions.top.filter(
                        p => p !== mullion.id
                    );
                    m.parallelMullions.top = m.parallelMullions.top.filter(p => p !== mullion.id);
                } else {
                    m.perpendicularMullions.bottom = m.perpendicularMullions.bottom.filter(
                        p => p !== mullion.id
                    );
                    m.parallelMullions.bottom = m.parallelMullions.bottom.filter(
                        p => p !== mullion.id
                    );
                }
            });

            this.resizeSashService.resizeSashesWidth(
                couplingProfile.width,
                conf,
                mullion.multiAlignTop,
                mullion.multiAlignBottom,
                mullion,
                mullion.multiAlignTopDiv,
                mullion.multiAlignBottomDiv,
                [
                    'vertical',
                    'top',
                    'bottom',
                    'left',
                    'right',
                    'Height',
                    'rHeight',
                    'ry',
                    'multiAlignLeft',
                ],
                true
            );
            newFrame.lowThreshold = oldFrame.lowThreshold;
            oldFrame.lowThreshold = false;
        } else {
            const oldWidth = oldFrame.width;
            oldFrame.width = mullion.rx - Math.floor(couplingProfile.width / 2);
            newFrame = new Frame({
                id: this.getIdForNew(conf),
                index: 1,
                height: oldFrame.height,
                width: oldWidth - oldFrame.width - couplingProfile.width,
                x: oldFrame.x + oldFrame.width + couplingProfile.width,
                y: oldFrame.y,
            });
            mullion.multiAlignLeft.forEach(sash => {
                sash.nearMullions.right = -1;
            });
            conf.Sashes.filter(
                sash => sash.rx >= mullion.rx && sash.frameId === mullion.frameId
            ).forEach(sash => {
                sash.frameId = newFrame.id;
                sash.rx -= mullion.rx + Math.ceil(couplingProfile.width / 2);
                if (sash.nearMullions.left === mullion.id) {
                    sash.nearMullions.left = -1;
                }
            });
            conf.Mullions.filter(
                m => m.rx >= mullion.rx && m.id !== mullion.id && m.frameId === mullion.frameId
            ).forEach(m => {
                m.frameId = newFrame.id;
                m.rx -= mullion.rx + Math.ceil(couplingProfile.width / 2);
            });

            conf.Alignments.forEach(m => {
                if (
                    this.getAlignmentPosition(mullion.direction, m) > mullion.rx
                    || (this.getAlignmentPosition(mullion.direction, m) === mullion.rx
                        && m.side !== 'right')
                ) {
                    m.frameId = newFrame.id;
                    if (m.direction === mullion.direction) {
                        m.position -= mullion.rx + Math.ceil(couplingProfile.width / 2);
                    } else {
                        m.shift -= mullion.rx + Math.ceil(couplingProfile.width / 2);
                    }
                    m.perpendicularMullions.left = m.perpendicularMullions.left.filter(
                        p => p !== mullion.id
                    );
                    m.parallelMullions.left = m.parallelMullions.left.filter(p => p !== mullion.id);
                } else {
                    m.perpendicularMullions.right = m.perpendicularMullions.right.filter(
                        p => p !== mullion.id
                    );
                    m.parallelMullions.right = m.parallelMullions.right.filter(
                        p => p !== mullion.id
                    );
                }
            });

            this.resizeSashService.resizeSashesWidth(
                couplingProfile.width,
                conf,
                mullion.multiAlignLeft,
                mullion.multiAlignRight,
                mullion,
                mullion.multiAlignLeftDiv,
                mullion.multiAlignRightDiv,
                [
                    'horizontal',
                    'left',
                    'right',
                    'top',
                    'bottom',
                    'Width',
                    'rWidth',
                    'rx',
                    'multiAlignTop',
                ],
                true
            );
            newFrame.lowThreshold = oldFrame.lowThreshold;
        }

        conf.Mullions.splice(conf.Mullions.findIndex(m => m.id === mullion.id), 1);
        const newCoupling = new Coupling({
            id: CouplingsService.getIdForNew(conf),
            direction: mullion.direction,
            color: null,
            length: mullion.direction === 'horizontal' ? mullion.rWidth : mullion.rHeight,
            profileId: couplingProfile.id,
            width: couplingProfile.width,
            widthOut: couplingProfile.widthOut,
            wood: null,
            reinforcement: null,
            adjacentSideProfileId: [],
            adjacentOtherSideProfileId: [],
            framesId: [
                {
                    id: oldFrame.id,
                    edges: [],
                },
            ],
            otherFramesId: [
                {
                    id: newFrame.id,
                    edges: [],
                },
            ],
            side: mullion.direction === 'horizontal' ? 'top' : 'left',
            otherSide: mullion.direction === 'horizontal' ? 'bottom' : 'right',
        });
        const couplingsToReattach = conf.couplings.filter(c =>
            c.direction === newCoupling.direction && c.framesId.some(fId => fId.id === oldFrame.id)
        );
        couplingsToReattach.forEach(c => {
            c.framesId = c.framesId.filter(fId => fId.id !== oldFrame.id);
            c.framesId.push({
                id: newFrame.id,
                edges: [],
            });
        });

        const perpendicularCouplingsToReattach = conf.couplings.filter(c =>
            c.direction !== newCoupling.direction && c.framesId.some(fId => fId.id === oldFrame.id)
        );
        perpendicularCouplingsToReattach.forEach(c => {
            c.framesId.push({
                id: newFrame.id,
                edges: [],
            });
            c.perpendicularCouplings[newCoupling.direction === 'horizontal' ? 'left' : 'top'].push(newCoupling.id);
        });

        const perpendicularCouplingsToReattachOther = conf.couplings.filter(c =>
            c.direction !== newCoupling.direction && c.otherFramesId.some(fId => fId.id === oldFrame.id)
        );
        perpendicularCouplingsToReattachOther.forEach(c => {
            c.otherFramesId.push({
                id: newFrame.id,
                edges: [],
            });
            c.perpendicularCouplings[newCoupling.direction === 'horizontal' ? 'right' : 'bottom'].push(newCoupling.id);
        });

        this.putCoupling(newCoupling, couplingProfile, conf);
        this.putFrame(newFrame, conf);

        this.reassignSideProfilesToFrames(conf, sideProfilesOnEdge);

        this.eventBusService.post({
            key: 'icc-redraw',
            value: 'frame',
            conf
        });
        this.shapeService.setShapes(conf);
        this.eventBusService.post({
            key: 'changedFrames',
            value: {},
            conf
        });
        this.eventBusService.post({
            key: 'changedSashes',
            value: {},
            conf
        });

        this.constructionLimitationService.findReinforcement(conf);
        this.priceService.count();
        this.parametersService.count(conf);
        this.warrantyService.check(conf);
        conf.Layout.changed = true;

        this.eventBusService.post({
            key: 'icc-redraw',
            value: 'frame',
            conf
        });
    }

    moveCoupling(
        coupling: Coupling,
        newPosition: number,
        conf: WindowActiveConfiguration,
        minWidthPrev?: number,
        minWidthNext?: number
    ) {
        const oneSideFrames = conf.Frames.filter(f =>
            coupling.framesId.some(fId => fId.id === f.id)
        );
        const otherSideFrames = conf.Frames.filter(f =>
            coupling.otherFramesId.some(fId => fId.id === f.id)
        );

        if (coupling.direction === 'horizontal') {
            let diff =
                oneSideFrames[0].y
                + oneSideFrames[0].height
                + Math.ceil(coupling.width / 2)
                - newPosition;

            const oneSideMaxSize = Math.min(
                ...conf.Sashes.filter(
                    s => oneSideFrames.some(f => f.id === s.frameId) && s.nearMullions.bottom === -1
                ).map(s => s.rWidth)
            );
            const otherSideMaxSize = Math.min(
                ...conf.Sashes.filter(
                    s => otherSideFrames.some(f => f.id === s.frameId) && s.nearMullions.top === -1
                ).map(s => s.rWidth)
            );

            if (oneSideMaxSize - diff <= this.minSizeGlass + minWidthPrev) {
                diff = oneSideMaxSize - this.minSizeGlass - minWidthPrev;
            }
            if (otherSideMaxSize + diff <= this.minSizeGlass + minWidthNext) {
                diff = this.minSizeGlass + minWidthNext - otherSideMaxSize;
            }

            oneSideFrames.forEach(frame => {
                this.resizeFrameService.resizeOneSideFrame(diff, frame, conf, [
                    'bottom',
                    'right',
                    'rHeight',
                    'height',
                    'ry',
                ]);
            });

            otherSideFrames.forEach(frame => {
                this.resizeFrameService.resizeOtherSideFrame(-diff, frame, conf, [
                    'top',
                    'right',
                    'rHeight',
                    'height',
                    'ry',
                    'y',
                    'vertical',
                    'multiAlignTop',
                ]);
            });

            conf.couplings.filter(f =>
                coupling.perpendicularCouplings.top.some(fId => fId === f.id)
            ).forEach(c => {
                c.length -= diff;
            });
            conf.couplings.filter(f =>
                coupling.perpendicularCouplings.bottom.some(fId => fId === f.id)
            ).forEach(c => {
                c.length += diff;
            });
        } else {
            let diff =
                oneSideFrames[0].x
                + oneSideFrames[0].width
                + Math.ceil(coupling.width / 2)
                - newPosition;

            const oneSideMaxSize = Math.min(
                ...conf.Sashes.filter(
                    s => oneSideFrames.some(f => f.id === s.frameId) && s.nearMullions.right === -1
                ).map(s => s.rWidth)
            );
            const otherSideMaxSize = Math.min(
                ...conf.Sashes.filter(
                    s => otherSideFrames.some(f => f.id === s.frameId) && s.nearMullions.left === -1
                ).map(s => s.rWidth)
            );

            if (oneSideMaxSize - diff <= this.minSizeGlass + minWidthPrev) {
                diff = oneSideMaxSize - this.minSizeGlass -  minWidthPrev;
            }
            if (otherSideMaxSize + diff <= this.minSizeGlass + minWidthNext) {
                diff = this.minSizeGlass + minWidthNext - otherSideMaxSize;
            }

            oneSideFrames.forEach(frame => {
                this.resizeFrameService.resizeOneSideFrame(diff, frame, conf, [
                    'right',
                    'bottom',
                    'rWidth',
                    'width',
                    'rx',
                ]);
            });

            otherSideFrames.forEach(frame => {
                this.resizeFrameService.resizeOtherSideFrame(-diff, frame, conf, [
                    'left',
                    'bottom',
                    'rWidth',
                    'width',
                    'rx',
                    'x',
                    'horizontal',
                    'multiAlignLeft',
                ]);
            });

            conf.couplings.filter(f =>
                coupling.perpendicularCouplings.left.some(fId => fId === f.id)
            ).forEach(c => {
                c.length -= diff;
            });
            conf.couplings.filter(f =>
                coupling.perpendicularCouplings.right.some(fId => fId === f.id)
            ).forEach(c => {
                c.length += diff;
            });
        }

        this.eventBusService.post({
            key: 'icc-redraw',
            value: 'frame',
        });
        this.shapeService.setShapes(conf);
        this.eventBusService.post({
            key: 'changedFrames',
            value: {},
        });
        this.eventBusService.post({
            key: 'changedSashes',
            value: {},
        });

        this.constructionLimitationService.findReinforcement(conf);
        this.priceService.count();
        this.parametersService.count(conf);
        this.warrantyService.check(conf);
        conf.Layout.changed = true;

        this.eventBusService.post({
            key: 'icc-redraw',
            value: 'frame',
        });
    }

    joinFrame(
        mullionProfile: Partial<Profile>,
        coupling: Coupling,
        conf: WindowActiveConfiguration
    ) {
        const oneSideFrame = conf.Frames.find(frame => frame.id === coupling.framesId[0].id);
        const otherSideFrame = conf.Frames.find(frame => frame.id === coupling.otherFramesId[0].id);
        const sideProfilesOnEdge = this.getSideProfilesEdgeMap(conf);
        let mullion: ActiveMullion;
        if (coupling.direction === 'horizontal') {
            const couplingPosition = oneSideFrame.height + Math.ceil(coupling.width / 2);

            const oneSideSashes = conf.Sashes.filter(
                sash => sash.frameId === oneSideFrame.id && sash.nearMullions.bottom === -1
            );
            const oneSideMullions = conf.Mullions.filter(
                m => m.frameId === oneSideFrame.id && m.ry + m.rHeight === oneSideFrame.height
            );
            const oneSideAlignments = conf.Alignments.filter(
                a => a.frameId === oneSideFrame.id && a.side !== 'top'
            );

            oneSideFrame.height = otherSideFrame.height + coupling.width + oneSideFrame.height;
            oneSideFrame.lowThreshold = oneSideFrame.lowThreshold || otherSideFrame.lowThreshold;

            const otherSideSashes = conf.Sashes.filter(sash => sash.frameId === otherSideFrame.id);
            const otherSideMullions = conf.Mullions.filter(m => m.frameId === otherSideFrame.id);
            const otherSideAlignments = conf.Alignments.filter(
                a => a.frameId === otherSideFrame.id
            );

            mullion = new ActiveMullion(this.mullionsLayoutService.getIdForNew(conf), {
                frameId: oneSideFrame.id,
                rx: 0,
                ry: couplingPosition - Math.ceil(coupling.width / 2),
                rWidth: oneSideFrame.width,
                rHeight: 1,
                multiAlignTop: oneSideSashes,
                multiAlignBottom: otherSideSashes.filter(sash => sash.nearMullions.top === -1),
                multiAlignTopDiv: oneSideMullions,
                multiAlignBottomDiv: otherSideMullions.filter(
                    m => m.multiAlignTop.length === 0 && m.direction !== coupling.direction
                ),
                direction: coupling.direction,
            });
            mullion.perpendicularAlignments.top = oneSideAlignments
                .filter(
                    a => a.direction === 'vertical' && a.perpendicularAlignments.bottom.length === 0
                )
                .map(a => a.id);
            mullion.parallelAlignments.top = oneSideAlignments
                .filter(
                    a => a.direction === 'horizontal' && a.parallelAlignments.bottom.length === 0
                )
                .map(a => a.id);
            mullion.perpendicularAlignments.bottom = otherSideAlignments
                .filter(
                    a => a.direction === 'vertical' && a.perpendicularAlignments.top.length === 0
                )
                .map(a => a.id);
            mullion.parallelAlignments.bottom = otherSideAlignments
                .filter(
                    a =>
                        a.direction === 'horizontal'
                        && a.parallelAlignments.top.length === 0
                        && a.side !== 'bottom'
                )
                .map(a => a.id);

            oneSideSashes.forEach(sash => {
                if (sash.nearAlignments.bottom === -1) {
                    sash.nearMullions.bottom = mullion.id;
                }
            });
            oneSideAlignments.forEach(a => {
                if (a.direction === 'vertical' && a.perpendicularAlignments.bottom.length === 0) {
                    a.perpendicularMullions.bottom.push(mullion.id);
                }
                if (a.direction === 'horizontal' && a.parallelAlignments.bottom.length === 0) {
                    a.parallelMullions.bottom.push(mullion.id);
                }
            });
            otherSideSashes.forEach(sash => {
                sash.frameId = oneSideFrame.id;
                sash.ry += couplingPosition + Math.ceil(coupling.width / 2);
                if (sash.nearMullions.top === -1 && sash.nearAlignments.top === -1) {
                    sash.nearMullions.top = mullion.id;
                }
            });
            otherSideMullions.forEach(m => {
                m.frameId = oneSideFrame.id;
                m.ry += couplingPosition + Math.ceil(coupling.width / 2);
            });
            otherSideAlignments.forEach(a => {
                a.frameId = oneSideFrame.id;
                if (a.direction === 'vertical' && a.perpendicularAlignments.top.length === 0) {
                    a.perpendicularMullions.top.push(mullion.id);
                }
                if (
                    a.direction === 'horizontal'
                    && a.parallelAlignments.top.length === 0
                    && a.side !== 'bottom'
                ) {
                    a.parallelMullions.top.push(mullion.id);
                }
                if (a.direction === mullion.direction) {
                    a.position += couplingPosition + Math.ceil(coupling.width / 2);
                } else {
                    a.shift += couplingPosition + Math.ceil(coupling.width / 2);
                }
            });

            this.resizeSashService.resizeSashesWidth(
                -coupling.width,
                conf,
                mullion.multiAlignTop,
                mullion.multiAlignBottom,
                mullion,
                mullion.multiAlignTopDiv,
                mullion.multiAlignBottomDiv,
                [
                    'vertical',
                    'top',
                    'bottom',
                    'left',
                    'right',
                    'Height',
                    'rHeight',
                    'ry',
                    'multiAlignLeft',
                ],
                true
            );
        } else {
            const couplingPosition = oneSideFrame.width + Math.ceil(coupling.width / 2);

            const oneSideSashes = conf.Sashes.filter(
                sash => sash.frameId === oneSideFrame.id && sash.nearMullions.right === -1
            );
            const oneSideMullions = conf.Mullions.filter(
                m => m.frameId === oneSideFrame.id && m.rx + m.rWidth === oneSideFrame.width
            );
            const oneSideAlignments = conf.Alignments.filter(
                a => a.frameId === oneSideFrame.id && a.side !== 'left'
            );
            oneSideFrame.width = otherSideFrame.width + coupling.width + oneSideFrame.width;
            oneSideFrame.lowThreshold = oneSideFrame.lowThreshold || otherSideFrame.lowThreshold;

            const otherSideSashes = conf.Sashes.filter(sash => sash.frameId === otherSideFrame.id);
            const otherSideMullions = conf.Mullions.filter(m => m.frameId === otherSideFrame.id);
            const otherSideAlignments = conf.Alignments.filter(
                a => a.frameId === otherSideFrame.id
            );

            mullion = new ActiveMullion(this.mullionsLayoutService.getIdForNew(conf), {
                frameId: oneSideFrame.id,
                rx: couplingPosition - Math.ceil(coupling.width / 2),
                ry: 0,
                rWidth: 1,
                rHeight: oneSideFrame.height,
                multiAlignLeft: oneSideSashes,
                multiAlignRight: otherSideSashes.filter(sash => sash.nearMullions.left === -1),
                multiAlignLeftDiv: oneSideMullions,
                multiAlignRightDiv: otherSideMullions.filter(
                    m => m.multiAlignLeft.length === 0 && m.direction !== coupling.direction
                ),
                direction: coupling.direction,
            });
            mullion.perpendicularAlignments.left = oneSideAlignments
                .filter(
                    a =>
                        a.direction === 'horizontal' && a.perpendicularAlignments.right.length === 0
                )
                .map(a => a.id);
            mullion.parallelAlignments.left = oneSideAlignments
                .filter(a => a.direction === 'vertical' && a.parallelAlignments.right.length === 0)
                .map(a => a.id);
            mullion.perpendicularAlignments.right = otherSideAlignments
                .filter(
                    a => a.direction === 'horizontal' && a.perpendicularAlignments.left.length === 0
                )
                .map(a => a.id);
            mullion.parallelAlignments.right = otherSideAlignments
                .filter(
                    a =>
                        a.direction === 'vertical'
                        && a.parallelAlignments.left.length === 0
                        && a.side !== 'right'
                )
                .map(a => a.id);

            oneSideSashes.forEach(sash => {
                if (sash.nearAlignments.right === -1) {
                    sash.nearMullions.right = mullion.id;
                }
            });
            oneSideAlignments.forEach(a => {
                if (a.direction === 'horizontal' && a.perpendicularAlignments.right.length === 0) {
                    a.perpendicularMullions.right.push(mullion.id);
                }
                if (a.direction === 'vertical' && a.parallelAlignments.right.length === 0) {
                    a.parallelMullions.right.push(mullion.id);
                }
            });
            otherSideSashes.forEach(sash => {
                sash.frameId = oneSideFrame.id;
                sash.rx += couplingPosition + Math.floor(coupling.width / 2);
                if (sash.nearMullions.left === -1 && sash.nearAlignments.left === -1) {
                    sash.nearMullions.left = mullion.id;
                }
            });
            otherSideMullions.forEach(m => {
                m.frameId = oneSideFrame.id;
                m.rx += couplingPosition + Math.floor(coupling.width / 2);
            });
            otherSideAlignments.forEach(a => {
                a.frameId = oneSideFrame.id;
                if (a.direction === 'horizontal' && a.perpendicularAlignments.left.length === 0) {
                    a.perpendicularMullions.left.push(mullion.id);
                }
                if (
                    a.direction === 'vertical'
                    && a.parallelAlignments.left.length === 0
                    && a.side !== 'right'
                ) {
                    a.parallelMullions.left.push(mullion.id);
                }
                if (a.direction === mullion.direction) {
                    a.position += couplingPosition + Math.floor(coupling.width / 2);
                } else {
                    a.shift += couplingPosition + Math.floor(coupling.width / 2);
                }
            });
            this.resizeSashService.resizeSashesWidth(
                -coupling.width,
                conf,
                mullion.multiAlignLeft,
                mullion.multiAlignRight,
                mullion,
                mullion.multiAlignLeftDiv,
                mullion.multiAlignRightDiv,
                [
                    'horizontal',
                    'left',
                    'right',
                    'top',
                    'bottom',
                    'Width',
                    'rWidth',
                    'rx',
                    'multiAlignTop',
                ],
                true
            );
        }
        mullion.profileId = mullionProfile.id;
        mullion.type = 'fixed_mullion';

        conf.Mullions.push(mullion);
        conf.couplings.splice(conf.couplings.findIndex(m => m.id === coupling.id), 1);
        conf.Frames.splice(conf.Frames.findIndex(m => m.id === otherSideFrame.id), 1);

        const couplingsToReattach = conf.couplings.filter(c =>
            c.framesId.some(fId => fId.id === otherSideFrame.id)
        );
        couplingsToReattach.forEach(c => {
            c.framesId = c.framesId.filter(fId => fId.id !== otherSideFrame.id);
            if (!c.framesId.some(fId => fId.id === oneSideFrame.id)) {
                c.framesId.push({
                    id: oneSideFrame.id,
                    edges: [],
                });
            }
        });

        const couplingsToReattachOther = conf.couplings.filter(c =>
            c.otherFramesId.some(fId => fId.id === otherSideFrame.id)
        );
        couplingsToReattachOther.forEach(c => {
            c.otherFramesId = c.otherFramesId.filter(fId => fId.id !== otherSideFrame.id);
            if (!c.otherFramesId.some(fId => fId.id === oneSideFrame.id)) {
                c.otherFramesId.push({
                    id: oneSideFrame.id,
                    edges: [],
                });
            }
        });

        this.eventBusService.post({
            key: 'icc-redraw',
            value: 'frame',
        });

        this.reassignSideProfilesToFrames(conf, sideProfilesOnEdge);

        this.sashesLayoutService.setIndex(conf);

        this.shapeService.setShapes(conf);
        this.eventBusService.post({
            key: 'changedFrames',
            value: {},
        });
        this.eventBusService.post({
            key: 'changedSashes',
            value: {
                action: 'joinedFrame',
                frame: oneSideFrame,
            },
        });
        this.eventBusService.post({
            key: 'setMullionProfile',
            value: {
                profile: mullionProfile
            },
        });

        this.constructionLimitationService.findReinforcement(conf);
        this.priceService.count();
        this.parametersService.count(conf);
        this.warrantyService.check(conf);
        conf.Layout.changed = true;

        this.eventBusService.post({
            key: 'icc-redraw',
            value: 'frame',
        });
    }

    frameSidesToEdge(
        edge: {
            frameId: number;
            frameEdgeIndex: number;
        },
        conf: WindowActiveConfiguration
    ) {
        const sides = this.profilesService.getFrameSidesOnEdge(conf);
        const side = sides.find(s =>
            s.frameEdges.some(
                fE => fE.frameEdgeIndex === edge.frameEdgeIndex && fE.frameId === edge.frameId
            )
        );

        if (conf.System?.door_type && conf.OwnedSashesTypes.doorTopLight || conf.OwnedSashesTypes.doorLeftLight || conf.OwnedSashesTypes.doorRightLight) {
            return (side && side.side);
        } else {
            return (side && side.side) || 'top';
        }
    }

    private reassignSideProfilesToFrames(
        conf: WindowActiveConfiguration,
        sideProfilesOnEdge: Record<number, 'left' | 'right' | 'top' | 'bottom'>
    ) {
        conf.SideProfiles.forEach(sP => {
            if (sideProfilesOnEdge[sP.id]) {
                const frameEdges = this.getFrameProfilesIndexesOnTheEdge(
                    sideProfilesOnEdge[sP.id],
                    conf
                ).reduce<SideProfile['framesId']>((frames, e) => {
                    const matchEdge = frames.find(f => f.id === e.frameId);
                    if (!matchEdge) {
                        frames.push({
                            id: e.frameId,
                            edges: [e.frameEdgeIndex],
                        });
                    } else {
                        matchEdge.edges.push(e.frameEdgeIndex);
                    }
                    return frames;
                }, []);
                sP.framesId = frameEdges;
            }
        });
    }

    private getIdForNew(conf: WindowActiveConfiguration) {
        const framesId = conf.Frames.reduce((prev, el) => (el.id > prev ? el.id : prev), -1);
        return framesId + 1;
    }

    private getSideProfilesEdgeMap(conf: WindowActiveConfiguration) {
        return conf.SideProfiles.filter(s => s.framesId.length > 0).reduce<
            Record<number, 'left' | 'right' | 'top' | 'bottom'>
        >((p, s) => {
            p[s.id] = this.frameSidesToEdge(
                {
                    frameId: s.framesId[0].id,
                    frameEdgeIndex: s.framesId[0].edges[0],
                },
                conf
            ) as 'left' | 'right' | 'top' | 'bottom';
            return p;
        }, {});
    }

    private getAlignmentPosition(orientation: 'vertical' | 'horizontal', alignment: Alignment) {
        if (orientation === alignment.direction) {
            return alignment.position;
        } else {
            return alignment.shift;
        }
    }
}
