/* eslint-disable max-statements */
import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { _, StepComponent, SharedFacade } from '@icc/configurator/shared';
import { iccListTab, iccListItem } from '@icc/configurator/ui';
import { WindowFacade } from '../+state/window.facade';
import { SubscriptionLike } from 'rxjs';
import { map, withLatestFrom } from 'rxjs/operators';
import { ColorsService } from '@icc/legacy/configurator/steps/window/colors/colors.service';
import { ConfigurationsService } from '@icc/common/configurations/configurations.service';
import { FillingsColorsService } from '@icc/legacy/configurator/steps/window/glazings/fillings-colors.service';
import { FillingsFacade } from '../glazings/fillings.facade';
import { APP_CONFIG, AppConfigFactory, TranslateService } from '@icc/common';
import { ThresholdColorsService } from '../profiles/threshold-colors.service';
import { LippingColorsService } from '../profiles/lipping-colors.service';
import { DoorPortalsService } from '../door-portals/door-portals.service';
import { DoorPortalsColorsService } from '../door-portals/door-portals-colors.service';
import { FillingsService } from '@icc/legacy/configurator/steps/window/glazings/fillings.service';
import { IccColor } from '@icc/common/data-types';
import { ActiveSash } from '@icc/common/layout/active-sash';

@Component({
    selector: 'icc-colors',
    templateUrl: './colors.component.html',
    styleUrls: ['./colors.component.scss'],
})
export class ColorsComponent extends StepComponent implements OnInit, OnDestroy {
    static stepName = _('STEPS|Kolor');
    static stepIcon = {
        ligature: 'palette',
    };

    public configurator = 'window';
    public stepId = 'colors';
    public title = _('COLOR|Wybierz kolor:');
    public options = [
        {
            title: _('WINDOW|Kolory zaawansowane'),
            callback: () => {
                const oldMode = this.mode;
                this.mode = 'advanced';
                this.sharedFacade.changeColorsStepMode('advanced');
                this.colorsService.setColorsSashExt(false);
                if (oldMode === 'door_extended' && (this.lippingColorsService.isInnerColorBasedOnOuterColorAvailable(this.configurationsService.conf.Current))) {
                    this.lippingColorsService.setOuterColorBasedOnInnerColor(this.configurationsService.conf.Current);
                }
                if (oldMode === 'door_extended' && !this.differentDoorColors) {
                    this.setBilateral('frame');
                }
            },
            icon: {
                ligature: 'invert_colors',
            },
            show: () => (this.isInnerColorAvailable()
                && (this.mode === 'door_extended' || this.mode === 'extended' || this.mode === 'simple'))
                || (this.mode === 'simple' && !this.config().IccConfig.Configurators.window.defaultAdvancedColorsMode),
            hover: (!this.config().IccConfig.Configurators.window.defaultAdvancedColorsMode) ? '' : 'active'
        },
        {
            title: _('COLOR|Kolory zaawansowane'),
            callback: () => {
                this.mode = 'extended';
                this.sharedFacade.changeColorsStepMode('advanced');
                this.colorsService.setColorsSashExt(true);
            },
            icon: {
                ligature: 'invert_colors',
            },
            show: () => this.isInnerColorAvailable()
                && this.mode === 'advanced'
                && this.availableColorsSashExt()
                && !this.configurationsService.conf.Current.System.door_type,
        },
        {
            title: _('WINDOW|Kolory zaawansowane'),
            callback: () => {
                this.mode = 'door_extended';
                this.sharedFacade.changeColorsStepMode('door_extended');
            },
            icon: {
                ligature: 'invert_colors',
            },
            show: () => this.isInnerColorAvailable()
                && this.mode === 'advanced'
                && this.config().IccConfig.Configurators.window.defaultAdvancedColorsMode
                && this.configurationsService.conf.Current.type === 'door'
                && this.configurationsService.conf.Current.System.door_type,
        },
    ];

    colors: iccListItem[] = [];
    colorGroups: iccListTab[] = [];
    selectedColorId$ = this.windowFacade.selectedColor$.pipe(
        map(color => color && Number(color.id))
    );
    selectedSystemType = null; 

    onlyDoubleSidedFrameColor = false;
    onlyDoubleSidedFrameColor$ = this.windowFacade.onlyDoubleSidedFrameColor$;
    onlyDoubleSidedSashColor = false;
    innerFrame$ = this.windowFacade.innerFrameColor$;
    outerFrame$ = this.windowFacade.outerFrameColor$;
    coreFrame$ = this.windowFacade.coreFrameColor$;
    alushellFrame$ = this.windowFacade.alushellFrameColor$;
    innerSash$ = this.windowFacade.innerSashColor$;
    outerSash$ = this.windowFacade.outerSashColor$;
    coreSash$ = this.windowFacade.coreSashColor$;
    alushellSash$ = this.windowFacade.alushellSashColor$;
    hasAlushellColorSelect$ = this.windowFacade.hasAlushellColorSelect$;
    hasAlushell$ = this.windowFacade.hasAlushell$;
    doorType$ = this.windowFacade.system$.pipe(map(system => system.door_type));
    hasDecoPanel$ = this.fillingsFacade.hasDecoPanel$;
    availableDecoPanelSecondColor$ = this.fillingsFacade.availableDecoPanelSecondColor$;
    innerFillingFirst$ = this.fillingsFacade.innerFirstColor$;
    outerFillingFirst$ = this.fillingsFacade.outerFirstColor$;
    innerFillingOther$ = this.fillingsFacade.innerOtherColor$;
    outerFillingOther$ = this.fillingsFacade.outerOtherColor$;
    innerFillingFirstPassive$ = this.fillingsFacade.innerFirstColorPassive$;
    outerFillingFirstPassive$ = this.fillingsFacade.outerFirstColorPassive$;
    innerFillingOtherPassive$ = this.fillingsFacade.innerOtherColorPassive$;
    outerFillingOtherPassive$ = this.fillingsFacade.outerOtherColorPassive$;
    hasDoorPassiveSash$ =  this.fillingsFacade.hasDoorPassiveSash$;
    hasDecoPanelPassive$ = this.fillingsFacade.hasDecoPanelPassive$;
    availableDecoPanelSecondColorPassive$ = this.fillingsFacade.availableDecoPanelSecondColorPassive$;
    availableInnerDecoPanelSecondColor$ = this.fillingsFacade.availableInnerDecoPanelSecondColor$;
    hasDoorActiveInner$ = this.fillingsFacade.hasDoorActiveInner$;

    hasThresholdColorSelect$ = this.windowFacade.hasThresholdColorSelect$;
    availableThresholds$ = this.windowFacade.availableThresholdColors$;
    thresholdColor$ = this.windowFacade.thresholdColor$;
    hasLippingColorSelect$ = this.windowFacade.hasLippingColorSelect$;
    lippingColor$ = this.windowFacade.lippingColor$;
    innerLippingColor$ = this.windowFacade.innerLippingColor$;
    hasLippingBicolorSelect$ = this.windowFacade.hasLippingBicolorSelect$;
    selectedDoorPortalColor$ = this.windowFacade.selectedDoorPortalColor$;
    selectedDoorPortal$ = this.windowFacade.selectedDoorPortal$;
    differentDoorColors = false;
    
    isFrameInnerColorAvailable = false;
    isInnerFillingFirstColorAvailable = false;
    isInnerFillingOtherAvailable = false;
    isInnerFillingOtherPassiveAvailable = false;
    isInnerLippingColorAvailable = false;

    mode: 'simple' | 'advanced' | 'extended' | 'door_extended' = this.config().IccConfig.Configurators.window.defaultAdvancedColorsMode ? 'advanced' : 'simple';
    private subscriptions: SubscriptionLike[] = [];

    constructor(
        @Inject(APP_CONFIG) private config: AppConfigFactory,
        private windowFacade: WindowFacade,
        private sharedFacade: SharedFacade,
        private fillingsFacade: FillingsFacade,
        private colorsService: ColorsService,
        private fillingsColorsService: FillingsColorsService,
        private configurationsService: ConfigurationsService<'window'>,
        private translateService: TranslateService,
        private thresholdColorsService: ThresholdColorsService,
        private lippingColorsService: LippingColorsService,
        private doorPortalsService: DoorPortalsService,
        private doorPortalsColorsService: DoorPortalsColorsService,
        private fillingsService: FillingsService
    ) {
        super();
    }

    ngOnInit() {
        this.selectedSystemType = this.configurationsService.conf.Current.System.type;
        this.onlyDoubleSidedFrameColor$.subscribe(o => {
            this.onlyDoubleSidedFrameColor = o;
        });
        const { colors, groups } = this.colorsService.getSimpleColors();
        this.colors = colors.map<iccListItem>(color => ({
            id: Number(color.id),
            title: color.name,
            description: color.code,
            color: '#' + color.color,
            imageUrl: '/files/color/' + color.color_img,
            tabs: (color.groups && color.groups.map(Number)) || [],
        }));

        this.colorGroups = groups.map<iccListTab>(group => ({
            id: Number(group.id),
            name: group.name,
        }));
        this.subscriptions.push(
            this.sharedFacade.colorsStepMode$
                .pipe(withLatestFrom(this.windowFacade.colorSashExt$))
                .subscribe(([stepMode, colorSashExt]) => {
                    if (stepMode === 'advanced') {
                        this.mode = colorSashExt ? 'extended' : 'advanced';
                    }
                    if (stepMode === 'door_extended') {
                        this.mode = 'door_extended';
                    }
                })
        );

        this.subscriptions.push(
            this.windowFacade.outerFrameColor$
            .pipe(withLatestFrom(this.windowFacade.innerFrameColor$))
            .subscribe(([outerColor, innerColor]) => {
                if (
                    outerColor?.id !== innerColor?.id
                    && this.mode !== 'extended'
                    && this.mode !== 'door_extended'
                    && this.mode !== 'advanced'
                ) {
                    this.mode = 'advanced';
                    this.sharedFacade.changeColorsStepMode('advanced');
                }
            })
        );
        this.subscriptions.push(
            this.windowFacade.colorSashExt$.subscribe(colorSashExt => {
                if (colorSashExt) {
                    if (this.mode !== 'extended') {
                        this.mode = 'extended';
                        this.sharedFacade.changeColorsStepMode('advanced');
                    }
                } else if (this.mode === 'extended') {
                    this.mode = 'advanced';
                }
            })
        );

        this.subscriptions.push(
            this.fillingsFacade.hasDifferentColors$.subscribe(differentColors => {
                this.differentDoorColors = Boolean(differentColors)
                    && this.configurationsService.conf.Current.type === 'door'
                    && this.configurationsService.conf.Current.System.door_type;
                if (this.differentDoorColors) {
                    this.mode = 'door_extended';
                }
            })
        );
    }

    isDoorWithDecoPanels(conf = this.configurationsService.conf.Current) {
        return conf.Sashes.some(
            s =>
                (s.type?.type === 'DRA'
                    || s.type?.type === 'DRP'
                    || s.type?.type === 'DOA'
                    || s.type?.type === 'DOP')
                && s.glazing
                && s.glazing.type === (conf.System.door_type ? 'door_panels' : 'deco_panels')
        );
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(s => s.unsubscribe());
    }

    selectColor(item: iccListItem) {
        const { colors } = this.colorsService.getSimpleColors();
        const color = colors.find(c => Number(c.id) === item.id);
        if (color) {
            this.colorsService.setSimpleColors(color);
        }
    }

    showBilateralButton(place: 'innerFrame' | 'innerSash' | 'innerFillingFirst') { 
        return !this.showWhiteColorButton(place) && this.selectedSystemType === 'pvc';
    }

    showWhiteColorButton(place: 'innerFrame' | 'innerSash' | 'innerFillingFirst', conf = this.configurationsService.conf.Current) {
        if (place === 'innerFillingFirst' && this.selectedSystemType === 'pvc') {
            const sash = this.fillingsFacade.getDoorSash(conf, 'doorActive');
            if (
                sash
                && sash.glazing
                && sash.glazing.selectedColor
                && sash.glazing.selectedColor.frame
            ) {
                if (sash.glazing.selectedColor.frame.outer?.id && sash.glazing.selectedColor.frame.outer?.id === sash.glazing.selectedColor.frame.inner?.id) {
                    return true;
                } else if (sash.glazing.selectedColor.frame.core?.type === 'white' && sash.glazing.selectedColor.frame.outer?.id) {
                    return false;
                } else if (sash.glazing.selectedColor.frame.core?.type === 'white') {
                    return true;
                }
            }
        } else if (place === 'innerSash' && this.selectedSystemType === 'pvc') {
            if (conf.Colors.sash.outer?.id && conf.Colors.sash.outer?.id === conf.Colors.sash.inner?.id) {
                return true;
            } else if (conf.Colors.sash.core?.type === 'white' && conf.Colors.sash.outer?.id) {
                return false;
            } else if (conf.Colors.sash.core?.type === 'white') {
                return true;
            }
            return false;
        } else if (place === 'innerFrame' && this.selectedSystemType === 'pvc') {
            if (conf.Colors.frame.outer?.id && conf.Colors.frame.outer?.id === conf.Colors.frame.inner?.id) {
                return true;
            } else if (conf.Colors.frame.core?.type === 'white' && conf.Colors.frame.outer?.id) {
                return false;
            } else if (conf.Colors.frame.core?.type === 'white') {
                return true;
            }
        }
    }

    setBilateral(place: 'frame') {
        const color =
            this.configurationsService.conf.Current.Colors
            && this.configurationsService.conf.Current.Colors[place]
            && ((this.configurationsService.conf.Current.Colors[place].outer
                && this.configurationsService.conf.Current.Colors[place].outer?.id)
                ? this.configurationsService.conf.Current.Colors[place].outer
                : this.configurationsService.conf.Current.Colors[place].core);

        const { colors } = this.colorsService.getSimpleColors();
        const availableColor = colors.find(c => Number(c.id) === Number(color?.id));
        
        if (color && availableColor) {
            this.colorsService.setSimpleColors(availableColor, place);
        }
    }

    openModalColor(type: 'outer' | 'inner' | 'alushell' | 'core', place: 'frame' | 'sash' = 'frame') {
        this.colorsService.openModalColorSimple(
            type,
            place,
            this.configurationsService.conf.Current,
            undefined,
            true,
            false,
            undefined,
            null,
            this.mode,
        );
    }

    setWhiteInnerFillingColor(conf = this.configurationsService.conf.Current as any) {
        const sashes = conf.Sashes.filter(
            this.fillingsService.getFilterForField('doorActive', conf)
        );
        const fillingType = 'glazing';
        sashes.forEach((sash: any) => {
            this.fillingsColorsService.setColor('inner', 'first', 'none', 'none', false, sash[fillingType], false);
        });
    }
    
    
    setDoubleSidedColor(
        place: 'frame' | 'sash' | 'lipping',
        side: 'innerFrame' | 'innerSash' | 'innerFillingFirst' | 'innerLipping',
        conf = this.configurationsService.conf.Current,
        defaultConf = this.configurationsService.conf.Default
    ) {
        const sashes = this.fillingsColorsService.getSelectedSashes('doorActive', conf);
        if (this.mode === 'advanced') {
            if (side === 'innerFrame' && !sashes) {
                this.colorsService.setDoubleSidedColor('inner', 'frame', 'none', conf, defaultConf);
                this.colorsService.setDoubleSidedColor('inner', 'sash', 'none', conf, defaultConf);
            } else if (place === 'frame' || place === 'sash') {
                if (side === 'innerFillingFirst') {
                    this.setDoubleSidedColorForFillings(sashes); 
                } else if (sashes && sashes[0]?.panelInner?.selectedColor || sashes[0]?.glazing?.selectedColor) {
                    if( side === 'innerFrame') {
                        this.colorsService.setDoubleSidedColor('inner', 'sash', 'none', conf, defaultConf);
                        this.colorsService.setDoubleSidedColor('inner', 'frame', 'none', conf, defaultConf);
                    } else {
                        this.setDoubleSidedColorForFillings(sashes); 
                        this.colorsService.setDoubleSidedColor('inner', 'sash', 'none', conf, defaultConf);
                        this.colorsService.setDoubleSidedColor('inner', 'frame', 'none', conf, defaultConf);
                    }
                } else { 
                    this.colorsService.setDoubleSidedColor('inner', 'sash', 'none', conf, defaultConf);
                    this.colorsService.setDoubleSidedColor('inner', 'frame', 'none', conf, defaultConf);
                    const windowSashes = this.fillingsColorsService.getSelectedSashes('window', conf)[0]?.glazing
                    if (windowSashes) {
                        this.colorsService.refreshFillingsColors(false, conf);
                    }
                }
            }
        } else {
            if (side === 'innerFillingFirst' && place === 'sash') {
                this.setDoubleSidedColorForFillings(sashes); 
            } else if ((place === 'frame' || place ==='sash') && side === 'innerSash') {
                this.colorsService.setDoubleSidedColor('inner', place, 'none', conf, defaultConf);
            } else if (place === 'frame' && side === 'innerFrame') {
                this.colorsService.setDoubleSidedColor('inner', place, 'none', conf, defaultConf);
            }
        }
    }

    setWhiteColor(
            place: 'frame' | 'sash' | 'lipping',
            side: 'innerFrame' | 'innerSash' | 'innerFillingFirst' | 'innerLipping',
            conf = this.configurationsService.conf.Current,
            defaultConf = this.configurationsService.conf.Default
    ) {
        if (this.mode === 'advanced') {
            if (place === 'frame' || place === 'sash') {
                const sashes = this.fillingsColorsService.getSelectedSashes('doorActive', conf);
                if (side === 'innerFrame') {
                    this.colorsService.setWhiteColor('inner', 'frame', 'none', conf, defaultConf);
                    this.colorsService.setWhiteColor('inner', 'sash', 'none', conf, defaultConf);
                }
                if (side !== 'innerFrame' && side !== 'innerFillingFirst' && (sashes && sashes[0]?.panelInner?.selectedColor || sashes[0]?.glazing?.selectedColor)) {
                    this.setWhiteColorForFillings(sashes);
                    this.colorsService.setWhiteColor('inner', 'sash', 'none', conf, defaultConf);
                    this.colorsService.setWhiteColor('inner', 'frame', 'none', conf, defaultConf);
                } else {
                    if (side !== 'innerFillingFirst' && conf.Colors.frame.inner?.id !== conf.Colors.frame.outer?.id) {
                        this.colorsService.setWhiteColor('inner', 'frame', 'none', conf, defaultConf);
                        this.colorsService.setWhiteColor('inner', 'sash', 'none', conf, defaultConf);
                    }
                    const windowSashes = this.fillingsColorsService.getSelectedSashes('window', conf)[0]?.glazing;
                    if (windowSashes) {
                        this.colorsService.refreshFillingsColors(false, conf);
                    }
                }
            } 
        } else{
            if ((place === 'frame' || place ==='sash') && side === 'innerSash') {
                this.colorsService.setWhiteColor('inner', place, 'none', conf, defaultConf);
            } else if (place === 'frame' && side === 'innerFrame') {
                this.colorsService.setWhiteColor('inner', place, 'none', conf, defaultConf);
            } else if (place === 'lipping' && side === 'innerLipping') {
                let innerWhiteColor = null;
                const innerLippingColors = this.lippingColorsService.getInnerLippingColors(conf);
                innerWhiteColor = innerLippingColors.find(c => c.type === 'white') as IccColor;
                this.lippingColorsService.setColor(innerWhiteColor, conf, defaultConf, 'inner')
            }
        }

        if (this.colorsService.hasDecoPanel(conf) && side === 'innerFillingFirst') {
            const sashes = this.fillingsColorsService.getSelectedSashes('doorActive', conf);
            const hasInnerPanel = this.fillingsColorsService.hasInnerPanel(sashes);
            sashes.forEach(sashq => {
                this.fillingsColorsService.setColor('inner', 'first', 'none', 'none', false, sashq['inner' === 'inner' && hasInnerPanel ? 'panelInner' : 'glazing'], false);
    
                sashq.intSashes.forEach(field => {
                    this.fillingsColorsService.setColor('inner', 'first', 'none', 'none', false, field['inner' === 'inner' && hasInnerPanel ? 'panelInner' : 'glazing'], false);
                })
            });

            if (!this.fillingsFacade.canHaveSashColor(this.configurationsService.conf.Current)) {
                const sashColor = conf.Colors.frame.inner as IccColor;
                this.colorsService.setSimpleColors(sashColor, 'sash');
            }
        }
    }

    isWhiteColorButtonDisabled(
        place: 'frame' | 'sash',
        side: 'innerSash' | 'innerFrame' | 'innerFillingFirst',
        conf = this.configurationsService.conf.Current
    ) {
        if (place === 'sash' && side === 'innerFillingFirst') {
            const sash = this.fillingsFacade.getDoorSash(conf, 'doorActive');
            if (
                sash &&
                sash.glazing &&
                sash.glazing.selectedColor &&
                sash.glazing.selectedColor.frame
            ) {
                return (
                    (sash.glazing.selectedColor.frame.core?.id &&
                        !sash.glazing.selectedColor.frame.inner?.id) ||
                    (sash.glazing.selectedColor.frame.inner?.type === 'white' &&
                        sash.glazing.selectedColor.frame.outer?.type === 'white')
                );
            }
        } else if (place === 'sash' && side === 'innerSash') {
            return (
                (conf.Colors.sash?.core?.id && !conf.Colors.sash?.inner?.id) ||
                (conf.Colors.sash?.inner?.type === 'white' &&
                    conf.Colors.sash?.outer?.type === 'white')
            );
        } else if (place === 'frame' && side === 'innerFrame') {
            return (
                (conf.Colors.frame?.core?.id && !conf.Colors.frame?.inner?.id) ||
                (conf.Colors.frame?.inner?.type === 'white' &&
                    conf.Colors.frame?.outer?.type === 'white')
            );
        }
    }

    openModalFillingColor(type: 'outer' | 'inner', place: 'first' | 'other' = 'first', sash: 'door' | 'doorActive' | 'doorActiveInner' | 'doorPassive' = 'door') {
        this.fillingsColorsService.openModalColor(
            type,
            place,
            sash,
            this.mode,
            this.configurationsService.conf.Current
        );
    }

    openModalThresholdColor() {
        this.thresholdColorsService.openModal(
            this.configurationsService.conf.Current,
            this.configurationsService.conf.Default
        );
    }

    openModalLippingColor(type: 'outer' | 'inner') {
        this.lippingColorsService.openModal(
            type,
            this.configurationsService.conf.Current,
            this.configurationsService.conf.Default,
        )
    }

    availableColorsSashExt() {
        return (this.config().IccConfig.Configurators.extendedColorSelect
            && this.configurationsService.conf.Current.System.different_sash_color
            && this.config().IccConfig.Configurators.selectSashFrameColor
        );
    }

    showInnerDoorPanel(doorType = false) {
        return (this.mode === 'door_extended' && doorType || (this.mode !== 'door_extended' && !doorType))
    }

    showOuterFillingFirstColor(hasDecoPanel = false) {
        return hasDecoPanel;
    }

    showInnerFillingFirstColor(hasDecoPanel = false, doorType = false, hasDoorActiveInner = false) {
        this.isInnerFillingFirstColorAvailable = !this.isDoubleSidedColorAvailable();

        return ((!this.isDoubleSidedColorAvailable() && hasDecoPanel && this.showInnerDoorPanel(doorType))
            || (hasDoorActiveInner && doorType && this.mode !== 'advanced'))
    }

    getFrameAlushellTitle() {
        return this.mode === 'extended' ? this.translateService.instant('COLOR|Kolor nakładki aluminiowej ościeżnicy') : this.translateService.instant('COLOR|Kolor nakładki aluminiowej');
    }

    showFrameOuter(hasAlushell = false, doorType = false, showOuterFillingFirstColor = false, conf = this.configurationsService.conf.Current) {
        return !hasAlushell && showOuterFillingFirstColor ? conf.System.separate_frame_and_sash_color :
            (this.mode !== 'door_extended' && !hasAlushell && !doorType) || hasAlushell
    }

    getFrameOuterTitle(doorType: boolean, hasDecoPanel = false) {
        return (!doorType && hasDecoPanel && this.mode === 'advanced')
            || (this.mode === 'door_extended' && doorType && hasDecoPanel && !this.onlyDoubleSidedFrameColor)
            || (this.mode === 'extended' && !doorType && !this.onlyDoubleSidedFrameColor)
            ? this.translateService.instant('COLOR|Kolor zewnętrzny ościeżnicy')
            : ((this.mode === "advanced" || this.mode === "door_extended") && doorType && hasDecoPanel)
            ? this.translateService.instant('COLOR|Kolor ościeżnicy')
            : this.translateService.instant('COLOR|Kolor zewnętrzny');
    }
    
    getFrameInnerTitle(doorType: boolean, hasDecoPanel = false) {
        return (this.mode === 'extended' || doorType || this.mode === 'advanced' && hasDecoPanel) ? this.translateService.instant('COLOR|Kolor wewnętrzny ościeżnicy') : this.translateService.instant('COLOR|Kolor wewnętrzny')
    }

    showFrameInner(doorType = false, hasAlushell = false, showInnerFillingFirstColor = false, conf = this.configurationsService.conf.Current) {
        this.isFrameInnerColorAvailable = showInnerFillingFirstColor ? conf.System.separate_frame_and_sash_color && !this.onlyDoubleSidedFrameColor :
        ((doorType && !this.onlyDoubleSidedFrameColor)
                || (!doorType)) || hasAlushell;
        
        return showInnerFillingFirstColor ? conf.System.separate_frame_and_sash_color && !this.onlyDoubleSidedFrameColor :
            ((this.mode === 'door_extended' && doorType && !this.onlyDoubleSidedFrameColor)
                    || (this.mode !== 'door_extended' && !doorType)) || hasAlushell;
    }

    showInnerFillingOther(availableInnerDecoPanelSecondColor = false, showInnerDoorPanel = false, hasDoorActiveInner = false) {
        this.isInnerFillingOtherAvailable = availableInnerDecoPanelSecondColor;

        return availableInnerDecoPanelSecondColor && (showInnerDoorPanel || hasDoorActiveInner);
    }

    showinnerFillingOtherPassive(availableDecoPanelSecondColorPassive = false, showInnerDoorPanel = false) {
        this.isInnerFillingOtherPassiveAvailable = availableDecoPanelSecondColorPassive;

        return availableDecoPanelSecondColorPassive && showInnerDoorPanel;
    }

    showCore(conf = this.configurationsService.conf.Current) {
        const colorsWin: IccColor[] = [];
        const groups = this.colorsService.filterWindowColorGroups(["F|C"], colorsWin, "core")
        const coreColors = this.colorsService.getColorGroups(groups, undefined, ["F|C"], conf);
        return (
            coreColors?.length > 1 &&
            ((this.configurationsService.conf.Current.System.type === 'pvc' &&
                this.mode === 'extended') ||
                (this.configurationsService.conf.Current.System.type === 'pvc' &&
                    this.mode === 'advanced' &&
                    this.configurationsService.conf.conf === 'window'))
        );
    }

    getFrameCoreTitle() {
        return this.mode === 'extended' ? this.translateService.instant('WINDOW|Rdzeń ościeżnicy') : this.translateService.instant('WINDOW|Rdzeń')
    }

    isCoreButtonDisabled() {
        const colorsWin: IccColor[] = [];
        const groups = this.colorsService.filterWindowColorGroups(["F|C"], colorsWin, "core")
        return this.colorsService.getColorGroups(groups, undefined, ["F|C"], this.configurationsService.conf.Current).length <= 1;
    }

    showInnerBicolorLippingColor(hasLippingBicolorSelect = false, showInnerLippingColor = false, conf = this.configurationsService.conf.Current) {
        if (conf.System.lipping_color_based_on_frame_color) {
            this.isInnerLippingColorAvailable = hasLippingBicolorSelect;
            return hasLippingBicolorSelect && showInnerLippingColor;
        } 

        if (conf.System.lipping_price_level_id && this.mode === 'door_extended') {
            showInnerLippingColor = true;
            return showInnerLippingColor;
        }
    }
    showInnerLippingColor(doorType: boolean, conf = this.configurationsService.conf.Current) {
        if (conf.System.lipping_color_based_on_frame_color) {
            return (this.mode === 'door_extended' && doorType || (this.mode !== 'door_extended' && !doorType))
        } else {
            return this.mode === 'door_extended' && !this.lippingColorsService.isOnlyDoubleSidePriceLevelAvailable();
        }
    }
    
    openModalPortalColor() {
        this.doorPortalsColorsService.openModal(this.configurationsService.conf.Current);
    }

    showOuterSash(hasAlushell: boolean) {
        return !hasAlushell && this.fillingsFacade.canHaveSashColor(this.configurationsService.conf.Current);
    }

    showInnerSash() {
        return this.fillingsFacade.canHaveSashColor(this.configurationsService.conf.Current);
    }

    private setDoubleSidedColorForFillings(sashes: ActiveSash[], conf = this.configurationsService.conf.Current) {
        let color: IccColor;
        const hasInnerPanel = this.fillingsColorsService.hasInnerPanel(sashes);

        sashes.forEach(s => {
            if (hasInnerPanel) {
                color = s.panelInner?.selectedColor?.frame.outer as IccColor;
            } else {
                color = s.glazing?.selectedColor?.frame.outer as IccColor;
            }
            
            this.fillingsColorsService.setColor('inner', 'first', color, 'none', false, s['inner' === 'inner' && hasInnerPanel ? 'panelInner' : 'glazing'], true);

            s.intSashes.forEach(field => {
                this.fillingsColorsService.setColor('inner', 'first', color, 'none', false, field['inner' === 'inner' && hasInnerPanel ? 'panelInner' : 'glazing'], true);
            });
        });
        
        if (!conf.System.separate_frame_and_sash_color) {
            if (!this.fillingsFacade.canHaveSashColor(this.configurationsService.conf.Current)) {
                const sashColor = conf.Colors.frame.inner as IccColor;
                this.colorsService.setSimpleColors(sashColor, 'sash');
            }
        }
    }

    private setWhiteColorForFillings(sashes: ActiveSash[], conf = this.configurationsService.conf.Current, defaultConf = this.configurationsService.conf.Default) {
        const hasInnerPanel = this.fillingsColorsService.hasInnerPanel(sashes);

        sashes.forEach(s => {
            this.fillingsColorsService.setColor('inner', 'first', 'none', 'none', false, s['inner' === 'inner' && hasInnerPanel ? 'panelInner' : 'glazing'], false);

            s.intSashes.forEach(field => {
                this.fillingsColorsService.setColor('inner', 'first', 'none', 'none', false, field['inner' === 'inner' && hasInnerPanel ? 'panelInner' : 'glazing'], false);
            });
        });
        
        if (!conf.System.separate_frame_and_sash_color) {
            if (!this.fillingsFacade.canHaveSashColor(this.configurationsService.conf.Current)) {
                const sashColor = conf.Colors.frame.inner as IccColor;
                this.colorsService.setSimpleColors(sashColor, 'sash');
            }
        }
    }

    isDoubleSidedColorAvailable(conf = this.configurationsService.conf.Current) {
        this.colorsService.mode = this.mode;
        
        return this.colorsService.isDoubleSidedColorAvailable(conf);
    }

    isInnerColorAvailable() {
        return this.isFrameInnerColorAvailable || this.isInnerFillingFirstColorAvailable || this.isInnerFillingOtherAvailable || this.isInnerLippingColorAvailable;
    }
}
