import { Component, OnInit, Inject, OnDestroy, ChangeDetectorRef } from '@angular/core';
import {
    StepComponent,
    _,
    ModalService,
    ConfiguratorOptions,
    SharedFacade,
} from '@icc/configurator/shared';
import {
    APP_CONFIG,
    AppConfigFactory,
    ConfigurationsService,
    WindowActiveConfiguration,
    TranslateService,
} from '@icc/common';
import { WindowFacade } from '@icc/configurator/window';
import { FillingsService } from '@icc/legacy/configurator/steps/window/glazings/fillings.service';
import { Subscription, Observable, merge } from 'rxjs';
import { iccListItem, IccSliderStep } from '@icc/configurator/ui';
import { FillingsFacade } from 'libs/configurator/window/src/lib/glazings/fillings.facade';
import { IccFilling, IccGlassTypeVariant, IccGlassType } from '@icc/common/data-types';
import { FormBuilder } from '@angular/forms';
import { startWith, map, distinctUntilChanged } from 'rxjs/operators';
import { GlassTypeVariantsPageComponent } from 'libs/configurator/window/src/lib/glass-type-variants-page/glass-type-variants-page.component';

interface SecurityLevel extends IccSliderStep {
    id: number;
    label: string;
    description: string;
    levels: (string | null)[];
}

@Component({
    selector: 'icc-lights-glazing',
    templateUrl: './lights-glazing.component.html',
    styleUrls: ['./lights-glazing.component.scss'],
})
export class LightsGlazingComponent extends StepComponent implements OnInit, OnDestroy {
    static stepName = _('DOOR|Oszklenie doświetli');
    static stepIcon = {
        ligature: 'table_chart',
    };
    public configurator = 'door';
    public stepId = 'lightsglazing';
    public title = _('DOOR|Oszklenie doświetli');
    private isSliderDisabled = false;

    public options = [
        {
            title: _('DOOR|Różne oszklenia w doświetlach'),
            callback: () => {
                this.mode = 'extra';
                this.sharedFacade.changeLightsGlazingStepMode('extra');
            },
            icon: {
                ligature: 'table_chart',
            },
            show: () => this.hasManyLights() && this.mode !== 'extra',
        },
        {
            title: _('DOOR|Jedno oszklenie doświetli'),
            callback: () => {
                this.mode = 'list';
                this.sharedFacade.changeLightsGlazingStepMode('list');
            },
            icon: {
                ligature: 'settings_backup_restore',
            },
            show: () => this.hasManyLights() && this.mode !== 'list',
        },
    ];

    public mode: 'extra' | 'list' = 'list';

    leftLightFillingBox: ReturnType<FillingsFacade['fillingBox']>;
    rightLightFillingBox: ReturnType<FillingsFacade['fillingBox']>;
    topLightFillingBox: ReturnType<FillingsFacade['fillingBox']>;

    fillings: iccListItem[] = [];

    securityLevels: SecurityLevel[] = [
        {
            id: 0,
            label: 'STD',
            description: this.translateService.instant(
                'WINDOW|Szyba standardowa, rozwiązanie ekonomiczne w zakupie, nie posiada żadnych dodatkowych zabezpieczeń. W przypadku uszkodzenia może grozić poważnym zranieniem.'
            ),
            levels: [null],
        },
        {
            id: 1,
            label: this.translateService.instant('WINDOW|bezpieczna'),
            description: this.translateService.instant(
                'WINDOW|Szyba bezpieczna hartowana, jest kilkukrotnie odporniejsza na rozbicie od zwykłej szyby, ponadto w przypadku uszkodzenia rozpada się na drobne kawałki, co zmniejsza ryzyko poważnych zranień.'
            ),
            levels: ['esg', 'o1', 'o2', 'p1a'],
        },
        {
            id: 2,
            label: this.translateService.instant('WINDOW|antywłamaniowa P2'),
            description: this.translateService.instant(
                'WINDOW|Szyba antywłamaniowa P2, stanowi podstawowe zabezpieczenie przed włamaniem podjętym bez przygotowania'
            ),
            levels: ['p2a', 'p3a'],
        },
        {
            id: 3,
            label: this.translateService.instant('WINDOW|antywłamaniowa P4'),
            description: this.translateService.instant(
                'WINDOW|Szyba antywłamaniowa klasy P4, szyby utrudniające włamanie. Klasa P4 ma porównywalną skuteczność do kraty o oczku 15 cm z drutu o średnicy 1cm.'
            ),
            levels: ['p4a'],
        },
        {
            id: 4,
            label: this.translateService.instant('WINDOW|antywłamaniowa P5'),
            description: this.translateService.instant(
                'WINDOW|Zwiększona odporność na włamanie – szyby zastępują kraty z prętów stalowych o średnicy 1,2 cm.'
            ),
            levels: ['p5a', 'p6b', 'p7b', 'p8b']
        }
    ];

    securityLevelDescription = '';

    fillingFilters = this.fb.group({
        securityLevel: [this.securityLevels[0]],
        glassType: ['0'],
        glassTypeVariant: ['0'],
    });

    selectedLightGlazingId$ = this.fillingsFacade.selectedLightGlazingId$;
    selectedFilling?: IccFilling;
    selectedGlassTypeVariant: IccGlassTypeVariant | null = null;

    glassTypes: IccGlassType[] = [];
    glassTypeVariants: IccGlassTypeVariant[] = [];
    filtersMap: Record<string, string[]> = {};
    filtersLevels: {
        securityLevel: SecurityLevel[];
        glassType: IccGlassType[];
        glassTypeVariant: IccGlassTypeVariant[];
    } = {
        securityLevel: [],
        glassType: [],
        glassTypeVariant: [],
    };

    private filters = {
        securityLevel: (securityLevel: SecurityLevel) => (filling: IccFilling) =>
            securityLevel.levels.includes(filling.security_level_outer || null),
        glassType: (glassType: string) => (filling: IccFilling) =>
            ((!glassType || Number(glassType) === 0)
                && (!filling.glass_types || filling.glass_types.length === 0))
            || (glassType
                && filling.glass_types
                && filling.glass_types.some(gt => gt.glass_type_id === glassType)),
        glassTypeVariant: (glassType: string, glassTypeVariant: number) => (filling: IccFilling) =>
            ((!glassTypeVariant || Number(glassTypeVariant) === 0)
                && (!filling.glass_types || filling.glass_types.length === 0))
            || (glassTypeVariant
                && filling.glass_types
                && filling.glass_types.some(
                    gt =>
                        Number(gt.glass_type_id) === Number(glassType)
                        && Number(gt.glass_type_variant_id) === Number(glassTypeVariant)
                )),
    };

    setAll = 0;

    private subscriptions: Subscription[] = [];
    static stepEnable = (conf: WindowActiveConfiguration, options: ConfiguratorOptions) =>
        Boolean(conf.System.door_type)
        && Boolean(
            conf.OneFilling.doorLeftLight
                || conf.OneFilling.doorRightLight
                || conf.OneFilling.doorTopLight
        );

    constructor(
        @Inject(APP_CONFIG) private config: AppConfigFactory,
        public fillingsFacade: FillingsFacade,
        private fillingsService: FillingsService,
        private configurationsService: ConfigurationsService<'door'>,
        private sharedFacade: SharedFacade,
        private translateService: TranslateService,
        private fb: FormBuilder,
        private cdr: ChangeDetectorRef,
        private modalService: ModalService
    ) {
        super();
    }

    openModalFilling(field: string) {
        this.fillingsService.openModal(
            field,
            this.configurationsService.conf.Current,
            this.configurationsService.conf.Default
        );
    }

    ngOnInit() {
        this.subscriptions.push(
            this.fillingsFacade.leftLightFillingBox$.subscribe(box => {
                this.leftLightFillingBox = box;
            })
        );
        this.subscriptions.push(
            this.fillingsFacade.rightLightFillingBox$.subscribe(box => {
                this.rightLightFillingBox = box;
            })
        );
        this.subscriptions.push(
            this.fillingsFacade.topLightFillingBox$.subscribe(box => {
                this.topLightFillingBox = box;
            })
        );

        if (
            !this.configurationsService.conf.Current.OneFilling.doorLight
            && this.mode !== 'extra'
        ) {
            this.mode = 'extra';
            this.sharedFacade.changeLightsGlazingStepMode('extra');
        }
        this.subscriptions.push(
            this.sharedFacade.lightsGlazingStepMode$.subscribe(stepMode => {
                this.mode = stepMode;
            })
        );

        const securityLevelControl = this.fillingFilters.get('securityLevel');
        const glassTypeControl = this.fillingFilters.get('glassType');
        const glassTypeVariantControl = this.fillingFilters.get('glassTypeVariant');
        this.glassTypes = [...(this.fillingsService.glassTypes || [])];
        this.glassTypeVariants = this.fillingsService.glassTypeVariants || [];
        this.loadFillings();

        let oldKey = '';
        if (securityLevelControl && glassTypeControl && glassTypeVariantControl) {
            this.subscriptions.push(
                merge(
                    securityLevelControl.valueChanges.pipe(
                        distinctUntilChanged(),
                        map(value => ({ field: 'securityLevel', value }))
                    ),
                    glassTypeControl.valueChanges.pipe(
                        distinctUntilChanged(),
                        map(value => ({ field: 'glassType', value }))
                    ),
                    glassTypeVariantControl.valueChanges.pipe(
                        distinctUntilChanged(),
                        map(value => ({ field: 'glassTypeVariant', value }))
                    )
                ).subscribe(({ field, value }) => {
                    const filtersValue = this.fillingFilters.value;
                    filtersValue[field] = value;
                    if (field === 'securityLevel') {
                        this.securityLevelDescription = value && value.description || '';
                    }
                    if (--this.setAll > 0) {
                        return;
                    }
                    this.setAll = 0;
                    if (field === 'glassTypeVariant') {
                        this.selectedGlassTypeVariant =
                            this.glassTypeVariants.find(
                                variant => Number(variant.id) === Number(value)
                            ) || null;
                    }

                    const filtersValues = {
                        securityLevel: filtersValue.securityLevel,
                        glassType: filtersValue.glassType,
                        glassTypeVariant: filtersValue.glassTypeVariant,
                    };

                    const fieldIndexes = {
                        securityLevel: 0,
                        glassType: 1,
                        glassTypeVariant: 2,
                    };
                    const key = `${filtersValues.securityLevel.id}_${filtersValues.glassType}_${
                        filtersValues.glassTypeVariant
                    }`;
                    if (this.filtersMap[key] || oldKey === key) {
                        oldKey = key;
                        this.matchFilling(key);
                    } else {
                        oldKey = key;
                        const availableCombination = Object.keys(this.filtersMap)
                            .filter(k => k[1] === '_')
                            .reduce(
                                (max, k) => {
                                    const values = key.split('_');
                                    const values2 = k.split('_');
                                    if (
                                        values2[fieldIndexes[field]]
                                            === values[fieldIndexes[field]]
                                        && ((this.filtersLevels.glassType
                                            && this.filtersLevels.glassType.length === 1
                                            && this.filtersLevels.glassTypeVariant
                                            && this.filtersLevels.glassTypeVariant.length > 1)
                                            || (values2[fieldIndexes['glassType']]
                                                === values[fieldIndexes['glassType']]
                                                && (values2[fieldIndexes['glassTypeVariant']]
                                                    === values[fieldIndexes['glassTypeVariant']]
                                                    || field === 'glassType'
                                                    || values[fieldIndexes['glassTypeVariant']]
                                                        === '0'
                                                    || values[fieldIndexes['glassTypeVariant']]
                                                        === 'undefined')))
                                    ) {
                                        const matchCount = values.reduce(
                                            (prev, cur, index) =>
                                                values2[index] === cur ? ++prev : prev,
                                            0
                                        );
                                        const matchDiff = values.reduce(
                                            (prev, cur, index) =>
                                                Number(values2[index]) - Number(cur) + prev,
                                            0
                                        );
                                        if (
                                            matchCount > max.match
                                            || (matchCount === max.match
                                                && Math.abs(matchDiff) < max.matchDiff)
                                        ) {
                                            return {
                                                key: k,
                                                match: matchCount,
                                                matchDiff: Math.abs(matchDiff),
                                            };
                                        } else {
                                            return max;
                                        }
                                    } else {
                                        return max;
                                    }
                                },
                                { key: '', match: 0, matchDiff: 0 }
                            );
                        if (availableCombination.key) {
                            const newFiltersValues = availableCombination.key.split('_');
                            this.setAll = 3 - availableCombination.match;
                            this.fillingFilters.patchValue({
                                securityLevel: this.securityLevels.find(
                                    l => l.id === Number(newFiltersValues[0])
                                ),
                                glassType: String(newFiltersValues[1] || '0'),
                                glassTypeVariant: newFiltersValues[2] || 0,
                            });
                        } else {
                            this.matchFilling('');
                        }
                    }
                })
            );
        }
        const conf = this.configurationsService.conf.Current;
        const fillings = this.fillingsService.getMatchingFillingsForDoorSashes(conf, 'doorLight');
        this.selectedFilling =
            fillings.find(f => f.id === conf.OneFilling.doorLight) || fillings[0];
        this.setStartFiltersValues();
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    loadFillings() {
        const conf = this.configurationsService.conf.Current;

        this.fillings = this.fillingsService
            .getMatchingFillingsForDoorSashes(conf, 'doorLight')
            .filter(filling => filling.type === 'glazing')
            .map<iccListItem>(filling => {
                const { securityLevel, glassType, glassTypeVariant } = this.matchFiltersValues(
                    filling
                );

                if (securityLevel) {
                    if (!this.filtersMap['securityLevel_' + securityLevel.id]) {
                        this.filtersMap['securityLevel_' + securityLevel.id] = [];
                    }
                    this.filtersMap['securityLevel_' + securityLevel.id].push(filling.id);
                }
                if (glassType) {
                    if (!this.filtersMap['glassType_' + glassType.id]) {
                        this.filtersMap['glassType_' + glassType.id] = [];
                    }
                    this.filtersMap['glassType_' + glassType.id].push(filling.id);
                }
                if (glassTypeVariant) {
                    if (!this.filtersMap['glassTypeVariant_' + glassTypeVariant.id]) {
                        this.filtersMap['glassTypeVariant_' + glassTypeVariant.id] = [];
                    }
                    this.filtersMap['glassTypeVariant_' + glassTypeVariant.id].push(filling.id);
                }

                const key = `${securityLevel.id}_${(glassType && glassType.id)
                    || '0'}_${(glassTypeVariant && glassTypeVariant.id) || '0'}`;
                if (!this.filtersMap[key]) {
                    this.filtersMap[key] = [];
                }
                this.filtersMap[key].push(filling.id);

                return {
                    id: filling.id,
                    title: filling.code,
                    imageUrl: `/files/filling/${filling.image}`,
                    description: `${filling.name}`,
                    filters: {
                        securityLevel,
                        glassType: (glassType && glassType.id) || '0',
                        glassTypeVariant: (glassTypeVariant && glassTypeVariant.id) || 0,
                    },
                };
            });
        Object.keys(this.filtersMap).forEach(key => {
            const [filter, id] = key.split('_');
            if (
                filter === 'securityLevel'
                && !this.filtersLevels.securityLevel.map(l => l.id).includes(Number(id))
            ) {
                this.filtersLevels.securityLevel.push(
                    this.securityLevels.find(l => l.id === Number(id)) || this.securityLevels[0]
                );
            }
            if (
                filter === 'glassType'
                && !this.filtersLevels.glassType.map(l => Number(l.id)).includes(Number(id))
            ) {
                this.filtersLevels.glassType.push(
                    this.glassTypes.find(l => Number(l.id) === Number(id)) || this.glassTypes[0]
                );
            }
            if (
                filter === 'glassTypeVariant'
                && !this.filtersLevels.glassTypeVariant.map(l => Number(l.id)).includes(Number(id))
            ) {
                this.filtersLevels.glassTypeVariant.push(
                    this.glassTypeVariants.find(l => Number(l.id) === Number(id))
                        || this.glassTypeVariants[0]
                );
            }
        });
        this.filtersLevels.securityLevel.sort((a, b) => a.id - b.id);
        this.filtersLevels.glassType.sort((a, b) => Number(a.id) - Number(b.id));
        this.filtersLevels.glassTypeVariant.sort((a, b) => Number(a.id) - Number(b.id));
        this.cdr.markForCheck();
    }

    haveFillingAnotherSecurityLevels(key){
        const splittedKey = key.split('_');
        if (splittedKey.length > 2){
            const securityVariants = [...Array.from(new Set(Object.keys(this.filtersMap).filter(key=>{
                const keyTypeAndVariant = key.split('_');
                if (`${keyTypeAndVariant[1]}${keyTypeAndVariant[2]}`===`${splittedKey[1]}${splittedKey[2]}`){
                    return true;
                }
            })))]
            this.isSliderDisabled = securityVariants.length <= 1;
        }
    }

    selectFilling() {
        const conf = this.configurationsService.conf.Current;
        const defaultConf = this.configurationsService.conf.Current;
        this.fillingsService.setDecorativePanelInDoorSashes(
            conf,
            defaultConf,
            this.selectedFilling,
            'doorLight'
        );
    }

    hasManyLights() {
        const conf = this.configurationsService.conf.Current;
        return conf.Sashes.filter(sash => sash.type && sash.type.type === 'F').length > 1;
    }

    setDefaultFiltersValues() {}

    changeGlassType(glassType: IccGlassType) {
        this.fillingFilters.patchValue({
            glassType: glassType.id,
        });
    }

    changeGlassTypeVariant(glassTypeVariant: IccGlassTypeVariant) {
        this.fillingFilters.patchValue({
            glassTypeVariant: glassTypeVariant.id,
        });
    }

    matchFilling(key: string) {
        if (
            this.filtersMap[key]
            && (!this.selectedFilling || !this.filtersMap[key].includes(this.selectedFilling.id))
        ) {
            const conf = this.configurationsService.conf.Current;
            const fillings = this.fillingsService.getMatchingFillingsForDoorSashes(
                conf,
                'doorLight'
            );
            this.selectedFilling = fillings.find(filling => {
                return (
                    filling.type === 'glazing'
                    && this.filtersMap[key]
                    && filling.id === this.filtersMap[key][0]
                );
            });
            this.selectFilling();
            this.haveFillingAnotherSecurityLevels(key)
        }
    }

    selectGlassTypeVariant() {
        this.modalService
            .open({
                pageComponent: GlassTypeVariantsPageComponent,
                resolve: {
                    glassTypeVariants: this.getMatchingVariants(),
                    selectedVariant: this.fillingFilters.value.glassTypeVariant,
                },
            })
            .result.then(result => {
                if (result) {
                    const glassTypeVariantControl = this.fillingFilters.get('glassTypeVariant');
                    if (glassTypeVariantControl) {
                        glassTypeVariantControl.setValue(result);
                        this.selectedGlassTypeVariant =
                            this.glassTypeVariants.find(
                                variant => String(variant.id) === String(result)
                            ) || null;
                    }
                }
            });
    }

    getMatchingVariants() {
        return (this.fillingsService.glassTypeVariants || []).filter(
            variant =>
                Number(variant.glass_type_id) === Number(this.fillingFilters.value.glassType)
                && this.filtersLevels.glassTypeVariant.map(Number).includes(Number(variant.id))
        );
    }

    private matchFiltersValues(filling: IccFilling) {
        const securityLevel =
            this.securityLevels.find(level => this.filters.securityLevel(level)(filling))
            || this.securityLevels[0];
        const glassType = this.glassTypes.find(type => this.filters.glassType(type.id)(filling));
        let glassTypeVariant = null;
        if (glassType) {
            glassTypeVariant = this.glassTypeVariants.find(variant =>
                this.filters.glassTypeVariant(glassType.id, variant.id)(filling)
            );
        }
        return {
            securityLevel,
            glassType,
            glassTypeVariant,
        };
    }

    private setStartFiltersValues() {
        if (this.selectedFilling) {
            const { securityLevel, glassType, glassTypeVariant } = this.matchFiltersValues(
                this.selectedFilling
            );
            const startValue = {
                securityLevel,
                glassType: (glassType && String(glassType.id)) || '0',
                glassTypeVariant: (glassTypeVariant && glassTypeVariant.id) || 0,
            };
            this.haveFillingAnotherSecurityLevels(`${securityLevel.id}_${glassType.id}_${glassTypeVariant.id}`)
            const match = Object.keys(this.fillingFilters.value).reduce(
                (prev, cur) =>
                    prev
                    + (this.fillingFilters.value[cur] !== startValue[cur]
                    || this.fillingFilters.value[cur].id !== startValue[cur].id
                        ? 1
                        : 0),
                0
            );
            this.setAll = match;
            this.fillingFilters.patchValue(startValue);
        }
    }
}
