import { core } from '../helpers';
import { Common } from '../Common';
import Position from './Position';
import { OfferGroupCodeService } from './OfferGroupCodeService';
import { OfferSequenceService } from './OfferSequenceService';
import { OfferSummaryServiceStatic } from './offer-summary.service';
import { MontagesService } from './montages.service';
import { ParserService } from '../ParserService';
import { currencyExchange } from '@icc/helpers';

export class PositionService {
    static getCopied(positions, source, destination, skip, { IccConfig, dealer, allDiscounts }) {
        const relations = {};
        skip = Common.isArray(skip) ? skip : [];
        const newPositions = positions
            .filter((pos) => pos && skip.indexOf(pos.confType) === -1)
            .map((pos) => {
                const originalId = pos.tmp_id || pos._id;
                const params = Common.extend(pos, {
                    dealer_offer_id: destination,
                    id: null,
                    tmp_id: null,
                    created: null,
                });

                const groupCode = OfferGroupCodeService.generateGroupCode(params, IccConfig);
                params.configuration = core.parseJson(params.configuration);
                const newPosition = new Position(
                    Common.extend(params, {
                        groupCode,
                        offer: source,
                    }),
                    IccConfig,
                    dealer,
                    allDiscounts
                );
                newPosition.configuration = core.stringJson(newPosition.configuration);
                relations[originalId] = newPosition.id;
                return newPosition;
            });
        newPositions
            .filter((position) => position.confType === 'coupled_window')
            .forEach((position) => {
                position.details = core.parseJson(position.details);
                position.details.windows = position.details.windows.map((window) => {
                    window.positionId = relations[window.positionId];
                    return window;
                });
                position.details.rollerShutters = position.details.rollerShutters.map(
                    (rollerShutter) => {
                        rollerShutter.positionId = relations[rollerShutter.positionId];
                        return rollerShutter;
                    }
                );
                position.details.couplings = position.details.couplings.map((coupling) => {
                    coupling.framesId.forEach((rel) => {
                        rel.positionId = relations[rel.positionId];
                    });
                    coupling.otherFramesId.forEach((rel) => {
                        rel.positionId = relations[rel.positionId];
                    });
                    return coupling;
                });
                position.details.sideProfiles = position.details.sideProfiles.map((sideprofile) => {
                    sideprofile.framesId.forEach((rel) => {
                        rel.positionId = relations[rel.positionId];
                    });
                    return sideprofile;
                });
                position.details.windowSills = position.details.windowSills.map((sill) => {
                    sill.framesId.forEach((rel) => {
                        rel.positionId = relations[rel.positionId];
                    });
                    return sill;
                });
                position.details = core.stringJson(position.details);

                position.configuration = core.parseJson(position.configuration);
                position.configuration.windows = position.configuration.windows.map((window) => {
                    window.positionId = relations[window.positionId];
                    return window;
                });
                position.configuration.rollerShutters = position.configuration.rollerShutters.map(
                    (rollerShutter) => {
                        rollerShutter.positionId = relations[rollerShutter.positionId];
                        return rollerShutter;
                    }
                );
                position.configuration.couplings = position.configuration.couplings.map(
                    (coupling) => {
                        coupling.framesId.forEach((rel) => {
                            rel.positionId = relations[rel.positionId];
                        });
                        coupling.otherFramesId.forEach((rel) => {
                            rel.positionId = relations[rel.positionId];
                        });
                        return coupling;
                    }
                );
                position.configuration.sideProfiles = position.configuration.sideProfiles.map(
                    (sideprofile) => {
                        sideprofile.framesId.forEach((rel) => {
                            rel.positionId = relations[rel.positionId];
                        });
                        return sideprofile;
                    }
                );
                position.configuration.windowSills = position.configuration.windowSills.map(
                    (sill) => {
                        sill.framesId.forEach((rel) => {
                            rel.positionId = relations[rel.positionId];
                        });
                        return sill;
                    }
                );
                position.configuration = core.stringJson(position.configuration);
            });

        newPositions
            .filter((position) => position.coupled_position_id)
            .forEach((position) => {
                position.coupled_position_id = relations[position.coupled_position_id];
            });

        const newSequence = newPositions.reduce(
            (prevSequence, pos) =>
                OfferSequenceService.updateOfferSequence(
                    prevSequence,
                    [
                        {
                            id: pos.tmp_id,
                            groupCode: pos.groupCode,
                        },
                    ],
                    'add',
                    IccConfig
                ),
            []
        );

        return {
            newPositions,
            newSequence,
            relations,
        };
    }

    static getDimensions(position, IccConfig, dimensionUnits: { dimensionUnit: 'mm' | 'inch', numberOfSpacesAfterDecimalSeparator: number }) {
        const drawData = position.details?.drawData ?? position.configuration?.drawData;
        let shutterCircuit = 0;
        let circuit = 0;
        let doorCircuit = 0;
        let windowCircuit = 0;
        const constructionData =
            drawData
                ? (position.configuration.type === 'coupled_window' ||
                      position.configuration.type === 'roller_shutter' ||
                      (position.configuration.type === 'external_blind' &&
                          IccConfig.Configurators.roller_shutter.sumRollerAndBoxHeight)) &&
                    drawData.base.find((b) => !b.positionId)
                        ? drawData.base.find((b) => !b.positionId)
                        : drawData.reno && drawData.reno[0]
                        ? drawData.reno[0]
                        : drawData.shape && drawData.shape[0]
                        ? drawData.shape[0]
                    : null
                : null;

        const area =
            constructionData && constructionData.polyArea
                ? constructionData.polyArea
                : position.details?.width && position.details?.height
                ? (position.details.width * position.details.height) / 1e6
                : 0;
        if (IccConfig.Offer.separateCircuits){
            windowCircuit =
                position.configuration.type === 'window' || position.configuration.type === 'coupled_window' || position.configuration.type === 'hs'
                ? position.details?.shape && position.details?.shape.circuit
                    ? position.details.shape.circuit
                    : constructionData && constructionData.polyEdge
                    ? constructionData.polyEdge * 1e3
                    : 0
                : 0

            if (((position.configuration.type === 'window' || position.configuration.type === 'sliding_door' || position.configuration.type === 'hs') && position.configuration?.RollerShutter?.realBoxHeight > 0) && position.configuration?.hasRoller || position.configuration.type === 'roller_shutter'){
                shutterCircuit = constructionData && constructionData.polyEdge
                ? constructionData.polyEdge * 1e3
                : position.configuration?.RollerShutter?.shutters?.map(item=>(item.realHeight+position.configuration?.RollerShutter?.realBoxHeight+item.realWidth)*2).reduce((a,b)=>a+b,0)
            }


            doorCircuit =
                position.configuration.type === 'door'
                ? position.details?.shape && position.details?.shape.circuit
                    ? position.details.shape.circuit
                    : constructionData && constructionData.polyEdge
                    ? constructionData.polyEdge * 1e3
                    : 0
                : 0;
        }
        if (position.configuration.type !== 'roller_shutter'){
            circuit =
                constructionData && constructionData.polyEdge
                    ? constructionData.polyEdge * 1e3
                    : position.details?.shape && position.details?.shape.circuit
                    ? position.details.shape.circuit
                    : 0;
        }


        const size =
            constructionData && constructionData.rect
                ?
                ParserService.dimensionUnit(constructionData.rect.width, dimensionUnits.dimensionUnit, IccConfig, dimensionUnits.numberOfSpacesAfterDecimalSeparator)
                + ' x ' +
                ParserService.dimensionUnit(constructionData.rect.height, dimensionUnits.dimensionUnit, IccConfig, dimensionUnits.numberOfSpacesAfterDecimalSeparator)
                : position.details?.width && position.details?.height
                ?
                ParserService.dimensionUnit(position.details.width, dimensionUnits.dimensionUnit, IccConfig, dimensionUnits.numberOfSpacesAfterDecimalSeparator)
                + ' x ' +
                ParserService.dimensionUnit(position.details.height, dimensionUnits.dimensionUnit, IccConfig, dimensionUnits.numberOfSpacesAfterDecimalSeparator)
                : ``;

        let glazingArea = 0;
        if (position.configuration?.Sashes) {
            position.configuration.Sashes.forEach((sash) => {
                glazingArea += parseFloat(sash.glazingSizes.area || 0);

                sash.intSashes.forEach((intSash) => {
                    glazingArea += parseFloat(intSash.glazingSizes.area || 0);
                });
            });
        }
        return { area, glazingArea, circuit, doorCircuit, windowCircuit, shutterCircuit, size };
    }

    /**
     * Parsuje wartości pozycji przed zwroceniem
     * @param  {object} offer Obiekt pozycji
     * @return {object}       Obiekt sparsowany
     */
    static parsePositionValues(position) {
        return Common.extend(position, {
            configuration: core.parseJson(position.configuration),
            dealer_price: parseFloat(position.dealer_price),
            dealer_price_before_discount: parseFloat(position.dealer_price_before_discount),
            client_price: parseFloat(position.client_price),
            client_price_before_discount: parseFloat(position.client_price_before_discount),
            group_discounts: core.parseJson(position.group_discounts),
            circuit: parseFloat(position.circuit),
            shutter_circuit: parseFloat(position.shutter_circuit),
            door_circuit: parseFloat(position.door_circuit),
            window_circuit: parseFloat(position.window_circuit),
            area: parseFloat(position.area),
            weight: parseFloat(position.weight),
            quantity: parseFloat(position.quantity),
        });
    }

    static addPosition(
        params: any,
        offer,
        IccConfig,
        dealer,
        user,
        allPositions,
        allDiscounts,
        market,
        montages?
    ) {
        const groupCode = OfferGroupCodeService.generateGroupCode(params, IccConfig);
        const newPosition = new Position(
            Object.assign(params, { groupCode }),
            IccConfig,
            dealer,
            allDiscounts
        );
        offer.currency = core.parseJson(offer.currency);
        const posObj = {
            id: newPosition.tmp_id,
            groupCode,
        };

        if (!allPositions) {
            allPositions = [
                {
                    doc: newPosition,
                },
            ];
        } else {
            allPositions.push({
                doc: newPosition,
            });
        }

        offer = OfferSummaryServiceStatic.autoUpdateOffer(
            params.dealer_offer_id || offer._id || offer.tmp_id,
            posObj,
            'add',
            undefined,
            user,
            offer,
            IccConfig,
            allPositions,
            market,
            Boolean(Number(offer.order))
        );

        if (montages) {
            const groups = PositionService.getOfferGroups(offer, allPositions);
            MontagesService.setMontagesDataStatic(
                offer,
                groups,
                montages,
                dealer && dealer.id,
                market.id,
                (group: any) => ''
            );
            MontagesService.updateMontages(offer, groups, allPositions, user, market, IccConfig);
        }

        return {
            offer,
            positions: allPositions,
        };
    }

    static updatePosition(
        params: any,
        offer,
        IccConfig,
        dealer,
        user,
        allPositions,
        allDiscounts,
        market,
        montages?
    ) {
        if (allPositions) {
            const position = allPositions.find((p) => p.doc.tmp_id === params.tmp_id);
            if (params.details) {
                delete position.doc.dealer_price_before_discount;
                delete position.doc.dealer_price;
                delete position.doc.dealer_discount;
                delete position.doc.client_price_before_discount;
                delete position.doc.client_price;
                delete position.doc.client_discount;
                delete position.doc.weight;
                delete position.doc.weight_positions_quantity;
                delete position.doc.configuration;
                delete position.doc.details;
                delete position.doc.confType;
                delete position.doc.size;
                delete position.doc.name;
                delete position.doc.quantity;
                delete position.doc.circuit;
                delete position.doc.shutter_circuit;
                delete position.doc.door_circuit;
                delete position.doc.window_circuit;
                delete position.doc.area;
            }
            Object.assign(position.doc, params);
            params = position.doc;
        }
        const groupCode = OfferGroupCodeService.generateGroupCode(params, IccConfig);
        const newPosition = new Position(
            Object.assign(params, { groupCode }),
            IccConfig,
            dealer,
            allDiscounts
        );
        offer.currency = core.parseJson(offer.currency);
        const posObj = {
            id: newPosition.tmp_id,
            groupCode,
        };

        if (!allPositions) {
            allPositions = [
                {
                    doc: newPosition,
                },
            ];
        } else {
            allPositions = allPositions.map((pos) => {
                if (pos.doc.tmp_id === newPosition.tmp_id) {
                    return {
                        doc: newPosition,
                    };
                } else {
                    return pos;
                }
            });
        }
        offer = OfferSummaryServiceStatic.autoUpdateOffer(
            params.dealer_offer_id || offer._id || offer.tmp_id,
            posObj,
            'update',
            undefined,
            user,
            offer,
            IccConfig,
            allPositions,
            market,
            Boolean(Number(offer.order))
        );

        if (montages) {
            const groups = PositionService.getOfferGroups(offer, allPositions);
            MontagesService.setMontagesDataStatic(
                offer,
                groups,
                montages,
                dealer && dealer.id,
                market.id,
                (group: any) => ''
            );
            MontagesService.updateMontages(offer, groups, allPositions, user, market, IccConfig);
        }

        return {
            offer: offer,
            positions: allPositions,
        };
    }

    static removePosition(
        params: any,
        offer,
        IccConfig,
        dealer,
        user,
        allPositions,
        allDiscounts,
        market,
        montages?
    ) {
        const posObj = {
            id: params.tmp_id,
        };

        allPositions = allPositions.filter((p) => p.doc.tmp_id !== params.tmp_id);

        params.offer = OfferSummaryServiceStatic.autoUpdateOffer(
            params.dealer_offer_id || offer._id || offer.tmp_id,
            posObj,
            'remove',
            undefined,
            user,
            offer,
            IccConfig,
            allPositions,
            market,
            Boolean(Number(offer.order))
        );

        if (montages) {
            const groups = PositionService.getOfferGroups(offer, allPositions);
            MontagesService.setMontagesDataStatic(
                offer,
                groups,
                montages,
                dealer && dealer.id,
                market.id,
                (group: any) => ''
            );
            MontagesService.updateMontages(offer, groups, allPositions, user, market, IccConfig);
        }

        return {
            offer,
            positions: allPositions,
        };
    }

    static refreshMontages(
        params: any,
        offer,
        IccConfig,
        dealer,
        user,
        allPositions,
        allDiscounts,
        market,
        montages?,
        transportCostsTypes?,
        transportCosts?
    ) {
        allPositions = allPositions.filter((p) => p.doc.tmp_id !== params.tmp_id);

        params.offer = OfferSummaryServiceStatic.autoUpdateOffer(
            params.dealer_offer_id || offer._id || offer.tmp_id,
            undefined,
            undefined,
            undefined,
            user,
            offer,
            IccConfig,
            allPositions,
            market,
            Boolean(Number(offer.order))
        );

        if (montages) {
            const groups = PositionService.getOfferGroups(offer, allPositions);
            MontagesService.setMontagesDataStatic(
                offer,
                groups,
                montages,
                dealer && dealer.id,
                market.id,
                (group: any) => ''
            );
            MontagesService.updateMontages(offer, groups, allPositions, user, market, IccConfig);
        }

        OfferSummaryServiceStatic.autoUpdateOffer(
            params.dealer_offer_id || offer._id || offer.tmp_id,
            undefined,
            undefined,
            undefined,
            user,
            offer,
            IccConfig,
            allPositions,
            market,
            Boolean(Number(offer.order)),
            null,
            transportCostsTypes,
            transportCosts
        );

        return {
            offer,
            positions: allPositions,
        };
    }

    static calcMontagesOptions({
        offer,
        IccConfig,
        dealer,
        user,
        allPositions,
        market,
        cartOptions,
        allMontages,
        transportCostsTypes,
        transportCosts,
        measurement,
        taxRate = { value: 0 },
    }: {
        offer;
        IccConfig;
        dealer;
        user;
        allPositions;
        market;
        cartOptions: {
            montage: boolean;
            montageType: null | string;
            measurePrice: number;
            measurement: boolean;
            personalPickup?: boolean;
        }[];
        allMontages;
        transportCostsTypes?;
        transportCosts?;
        measurement?;
        taxRate?: { value: number };
    }) {
        offer = core.copy(offer);
        offer = OfferSummaryServiceStatic.autoUpdateOffer(
            offer._id || offer.tmp_id,
            undefined,
            undefined,
            undefined,
            user,
            offer,
            IccConfig,
            allPositions,
            market,
            Boolean(Number(offer.order))
        );

        const prices: {
            default: number;
            defaultDiscount: number;
            withMeasurement: number;
            withMeasurementDiscount: number;
            withMeasurementMeasure: number;
            withMeasurementAndMontage: Record<string, { vat8: number; standard: number }>;
            withMeasurementAndMontageDiscount: number;
            withMeasurementAndMontageMeasure: Record<string, { vat8: number; standard: number }>;
            personalPickup: number;
            personalPickupDiscount: number;
            transportCostType: null | false | string;
        } = {
            default: 0,
            defaultDiscount: 0,
            withMeasurement: 0,
            withMeasurementDiscount: 0,
            withMeasurementMeasure: 0,
            withMeasurementAndMontage: {},
            withMeasurementAndMontageDiscount: 0,
            withMeasurementAndMontageMeasure: {},
            personalPickup: 0,
            personalPickupDiscount: 0,
            transportCostType: null,
        };

        cartOptions.forEach((option) => {
            let offerCopy = core.copy(offer);
            const groups = PositionService.getOfferGroups(offerCopy, allPositions);
            offerCopy.montages = {
                montage: option.montage,
                montageType: option.montageType,
            };
            offerCopy.measurement = option.measurement;
            offerCopy.measure_price = option.measurePrice;
            offerCopy.transport_from_producent_to_client = !option.personalPickup;

            if (allMontages) {
                MontagesService.setMontagesDataStatic(
                    offerCopy,
                    groups,
                    allMontages,
                    dealer && dealer.id,
                    market.id,
                    (group: any) => ''
                );
                MontagesService.updateMontages(
                    offerCopy,
                    groups,
                    allPositions,
                    user,
                    market,
                    IccConfig
                );
            }

            offerCopy = OfferSummaryServiceStatic.autoUpdateOffer(
                offerCopy._id || offerCopy.tmp_id,
                undefined,
                undefined,
                undefined,
                user,
                offerCopy,
                IccConfig,
                allPositions,
                market,
                Boolean(Number(offerCopy.order)),
                null,
                transportCostsTypes,
                transportCosts
            );
            PositionService.setOptionPrices({
                offerCopy,
                measurement,
                option,
                groups,
                taxRate,
                prices
            });
        });
        return prices;
    }

    private static setOptionPrices({
        offerCopy,
        measurement,
        option,
        groups,
        taxRate,
        prices
    }: {
        offerCopy,
        measurement,
        option: {
            montage: boolean;
            montageType: null | string;
            measurePrice: number;
            measurement: boolean;
            personalPickup?: boolean;
        },
        groups,
        taxRate: { value: number },
        prices: {
            default: number;
            defaultDiscount: number;
            withMeasurement: number;
            withMeasurementDiscount: number;
            withMeasurementMeasure: number;
            withMeasurementAndMontage: Record<string, { vat8: number; standard: number }>;
            withMeasurementAndMontageDiscount: number;
            withMeasurementAndMontageMeasure: Record<string, { vat8: number; standard: number }>;
            personalPickup: number;
            personalPickupDiscount: number;
            transportCostType: null | false | string;
        }
    }) {
        const currency =  core.parseJson(offerCopy.currency);
        const groupDiscounts = core.parseJson(offerCopy.group_discounts);
        const multiplier = groupDiscounts.reduce((a, b) => a + b.discount, 0);

        const measurePrice =
            currencyExchange(measurement?.price || 0, currency) +
            ((measurement?.supplement || 0) / 100) * offerCopy.client_price;

        offerCopy.measure_price = offerCopy.measurement ? measurePrice : 0;
        option.measurePrice = offerCopy.measure_price;

        const grossPrice = PositionService.getGrossPrice(groups, {}, offerCopy, taxRate.value)
            .grossPrice;
        const grossPriceVat8 = PositionService.getGrossPrice(
            groups,
            offerCopy.positions_tax_rates,
            offerCopy,
            8
        ).grossPrice;
        if (option.montage && option.montageType) {
            prices.withMeasurementAndMontage[option.montageType] = {
                vat8: 0,
                standard: 0,
            };
            prices.withMeasurementAndMontageMeasure[option.montageType] = {
                vat8: 0,
                standard: 0,
            };
            prices.withMeasurementAndMontage[option.montageType].standard = !offerCopy.valuation
                ? grossPrice
                : 0;
            prices.withMeasurementAndMontage[option.montageType].vat8 = !offerCopy.valuation
                ? grossPriceVat8
                : 0;
            prices.withMeasurementAndMontageDiscount = multiplier;
            prices.withMeasurementAndMontageMeasure[option.montageType].standard =
                (option.measurePrice * (100 + taxRate.value)) / 100;
            prices.withMeasurementAndMontageMeasure[option.montageType].vat8 =
                (option.measurePrice * 108) / 100;
        } else if (option.measurement) {
            prices.withMeasurement = !offerCopy.valuation ? grossPrice : 0;
            prices.withMeasurementDiscount = multiplier;
            prices.withMeasurementMeasure = (option.measurePrice * (100 + taxRate.value)) / 100;
        } else if (option.personalPickup) {
            prices.personalPickup = !offerCopy.valuation ? grossPrice : 0;
            prices.personalPickupDiscount = multiplier;
        } else {
            prices.default = !offerCopy.valuation ? grossPrice : 0;
            prices.defaultDiscount = multiplier;
        }

        if (offerCopy.transport_cost_type || !prices.transportCostType) {
            prices.transportCostType = offerCopy.transport_cost_type;
        }
    }

    static getGrossPrice(groups, positionsTaxRates, offer, mainTaxRateValue) {
        const positions = groups
            .map((positionGroup) => positionGroup.rows)
            .reduce((a, b) => a.concat(b), [])
            .map((pos) => ({
                id: pos.id,
                price: pos.doc.client_price,
                tax_rate:
                    positionsTaxRates?.[pos.id] != null
                        ? positionsTaxRates[pos.id]
                        : mainTaxRateValue,
                quantity: pos.doc.quantity,
            }));

        const taxesValues = {};
        let grossPrice = 0;
        for (const pos of positions) {
            if (pos.tax_rate) {
                if (taxesValues[+pos.tax_rate]) {
                    taxesValues[+pos.tax_rate] +=
                        ((pos.price * (100 + pos.tax_rate)) / 100) * pos.quantity;
                } else {
                    taxesValues[+pos.tax_rate] =
                        ((pos.price * (100 + pos.tax_rate)) / 100) * pos.quantity;
                }
                grossPrice += ((pos.price * (100 + pos.tax_rate)) / 100) * pos.quantity;
            }
        }
        if (offer.measure_price > 0) {
            grossPrice += (offer.measure_price * (100 + mainTaxRateValue)) / 100;
        }
        if (offer.transport_cost > 0) {
            grossPrice += (offer.transport_cost * (100 + mainTaxRateValue)) / 100;
        }
        if (offer.client_montages_price_before_discount > 0) {
            grossPrice +=
                (offer.client_montages_price_before_discount * (100 + mainTaxRateValue)) / 100;
        }
        return {
            grossPrice,
            taxesValues,
        };
    }

    static getOfferGroups(offer, positions) {
        const groups = [];
        for (let i = 0; i < offer.sequence.length; i++) {
            for (const key in offer.sequence[i]) {
                if (Object.prototype.hasOwnProperty.call(offer.sequence[i], key)) {
                    const pos = positions.filter(
                        (e) =>
                            offer.sequence[i][key].indexOf(e.doc.tmp_id) > -1 &&
                            !e.doc.coupled_position_id
                    );
                    if (pos.length) {
                        groups.push({
                            groupCode: pos[0].doc.groupCode,
                            confType: pos[0].doc.confType,
                            rows: pos,
                            groupData: offer.positions_groups_data[pos[0].doc.groupCode],
                            groupEdition: 0,
                        });
                    }
                }
            }
        }
        return groups;
    }
}
