import {DatabaseConfiguration, DatabaseManager} from '@icc/helpers/browser';
import { Injectable } from "@angular/core";

@Injectable()
export class ConfiguratorDatabaseManager extends DatabaseManager {
    protected databasesConfigurations: DatabaseConfiguration[] = [
        {
            name: 'Configurators',
            simple: true
        },
        {
            name: 'CustomPrices',
            simple: true
        },
        {
            name: 'DiscountPerProduct',
            simple: true
        },
        {
            name: 'DiscountsAndMultipliers',
            simple: true
        },
        {
            name: 'Layouts',
            simple: true
        },
        {
            name: 'Prices',
            simple: true
        },
        {
            name: 'Settings',
            simple: true
        },
        {
            name: 'Translations',
            simple: true
        }
    ];

    constructor() {
        super();
    }
}
