import { Injectable, Inject } from '@angular/core';

import { ConfigurationsService } from '@icc/common/configurations/configurations.service';
import { map } from 'rxjs/operators';
import { APP_CONFIG, AppConfigFactory } from '@icc/common/config';
import { WindowActiveConfiguration } from '@icc/common/configurations/WindowActiveConfiguration';

@Injectable()
export class ConfiguratorFacade {
    constructor(
        private configurationsService: ConfigurationsService,
        @Inject(APP_CONFIG) private config: AppConfigFactory
    ) {}

    price$ = this.configurationsService.configuration$.pipe(
        map(conf => ({
            showPrice: (
                    WindowActiveConfiguration.is(conf)
                    && conf.Sashes.length > 0
                    && !conf.ChangedDimensions
                    && (this.config().preset === 'b2c' ? !this.config().hidePricesInB2cConfigurator : true)
                )
                || (
                    (this.config().preset === 'b2c' ? !this.config().hidePricesInB2cConfigurator : true)
                    &&
                    (conf.type === 'roller_shutter'
                    || conf.type === 'external_blind')
                    && conf.RollerShutter.shutters.length > 0
                )
                || (
                    (this.config().preset === 'b2c' ? !this.config().hidePricesInB2cConfigurator : true)
                    && (conf.type === 'accessory'
                    || conf.type === 'garage_door'
                    || conf.type === 'awning'
                    || conf.type === 'complementary_goods'
                    || conf.type === 'mosquito'
                    || conf.type === 'coupled_window')
                ),
            price:
                this.config().preset === 'b2c'
                && this.config().IccConfig.Configurators.grossPriceInSummaryB2C
                    ? conf.PriceGross
                    : conf.Price,
            priceAfterDiscounts:
                this.config().preset === 'b2c'
                && this.config().IccConfig.Configurators.grossPriceInSummaryB2C
                    ? conf.PriceGrossAfterDiscounts
                    : conf.PriceAfterDiscounts,
            discount: conf.Discount,
            hasGrossPrice:
                this.config().preset === 'b2c'
                && this.config().IccConfig.Configurators.grossPriceInSummaryB2C,
        }))
    );
    issues$ = this.configurationsService.configuration$.pipe(map(conf => conf.Issues));
}
