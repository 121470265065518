import { Component } from '@angular/core';
import {
    ConfigurationsService,
    DrawService,
    EventBusService,
    TranslateService,
    WindowActiveConfiguration,
} from '@icc/common';
import { ActiveSash } from '@icc/common/layout/active-sash';
import { _, StepComponent, isDefined, ModalService, ConfiguratorOptions } from '@icc/configurator/shared';
import { ConfiguratorsAvailabilityService } from '@icc/legacy/configurator/configurators-availability.service';
import { PriceService } from '@icc/price';
import { WindowFacade } from '../+state/window.facade';
import { SashMosquitoComponent } from '../sash-mosquito/sash-mosquito.component';

@Component({
    selector: 'icc-mosquito',
    templateUrl: './mosquito.component.html',
    styleUrls: ['./mosquito.component.scss'],
})
export class MosquitoComponent extends StepComponent {
    static stepName = _('STEPS|Siatki');
    static stepIcon = {
        ligature: 'grid_on',
    };
    public configurator = 'window';
    public stepId = 'mosquito';
    public title = _('WINDOW|Dodaj siatki do poszczególnych kwater.');
    public options = [];

    drawOptions: DrawService['options'] | null = null;

    width$ = this.windowFacade.width$;
    height$ = this.windowFacade.height$;
    sashes$ = this.windowFacade.sashes$;
    mosquito$ = this.windowFacade.mosquito$;

    static stepEnable = (conf: WindowActiveConfiguration, options: ConfiguratorOptions) =>
        conf.SashesType !=='FixSash' && conf.SashesType !== 'Fix';

    // eslint-disable-next-line max-params
    constructor(
        public drawService: DrawService,
        private translateService: TranslateService,
        private windowFacade: WindowFacade,
        private configurationsService: ConfigurationsService<'window'>,
        private configuratorsAvailabilityService: ConfiguratorsAvailabilityService,
        private eventBusService: EventBusService,
        private modalService: ModalService,
        private priceService: PriceService
    ) {
        super();

        const conf = this.configurationsService.conf.Current;

        this.drawOptions = Object.assign({}, drawService.options, {
            sash: {
                buttons: [
                    {
                        class: 'ico-plus',
                        positions: ['center'],
                        title: this.translateService.instant('WINDOW|Dodaj siatkę'),
                        condition: (response: { sash: ActiveSash }) =>
                            this.allowModalMosquito(conf, response.sash),
                        onClick: (response: { sash: ActiveSash }) =>
                            this.openModalMosquito(conf, response.sash),
                    },
                ],
            },
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            onInterfaceClick: () => {},
        });
    }

    allowModalMosquito(conf: WindowActiveConfiguration, sash: ActiveSash | undefined) {
        if (!sash) {
            return conf.Sashes.some((el) => el.type?.type !== 'F');
        }

        sash = conf.Sashes.find((s) => s.id === sash?.id);

        if (!sash) {
            return false;
        }

        const leftSideMullion = conf.Mullions.filter(function filterDividers(e) {
            return e.id === sash?.nearMullions.left;
        });
        const leftSide =
            isDefined(leftSideMullion[0]) &&
            isDefined(leftSideMullion[0].multiAlignLeft) &&
            isDefined(leftSideMullion[0].multiAlignLeft[0])
                ? leftSideMullion[0].multiAlignLeft[0]
                : {
                      type: {
                          type: '',
                      },
                  };

        const rightSideMullion = conf.Mullions.filter(function filterDividers(e) {
            return e.id === sash?.nearMullions.right;
        });
        const rightSide =
            isDefined(rightSideMullion[0]) &&
            isDefined(rightSideMullion[0].multiAlignRight) &&
            isDefined(rightSideMullion[0].multiAlignRight[0])
                ? rightSideMullion[0].multiAlignRight[0]
                : {
                      type: {
                          type: '',
                      },
                  };

        return (
            sash?.type?.type &&
            this.configuratorsAvailabilityService.availableConfigs.mosquito &&
            ['K', 'D', 'DK', 'PSK', 'HS'].indexOf(sash.type.type) >= 0 &&
            ((sash.type.handle_position === 'L' &&
                leftSide?.type &&
                ['DSC', 'HS'].indexOf(leftSide?.type.type) === -1 &&
                (sash.type.type !== 'PSK' ||
                    (sash.type.type === 'PSK' && leftSide?.type?.type !== 'PSK'))) ||
                sash.type.handle_position !== 'L') &&
            ((sash.type.handle_position === 'R' &&
                rightSide?.type &&
                ['DSC'].indexOf(rightSide.type.type) === -1) ||
                sash.type.handle_position !== 'R') &&
            ['window', 'hs'].indexOf(conf.System.confType) > -1
        );
    }

    openModalMosquito(conf: WindowActiveConfiguration, sash: ActiveSash | undefined) {
        const modalInstance = this.modalService.open({
            pageComponent: SashMosquitoComponent,
            resolve: {
                conf: () => conf,
                sash: () => sash && conf.Sashes.find((s) => s.id === sash.id),
            },
        });

        modalInstance.result.then(() => {
            this.priceService.count();
        });

        modalInstance.closed.then(() =>
            this.eventBusService.post({ key: 'processDependencies', value: null })
        );
    }
}
