/* eslint-disable max-statements */
import { ListPageComponent } from '@icc/configurator/pages';
import { Injectable, Inject } from '@angular/core';
import { IccAccessoryAccessory, IccAccessoryColor } from '@icc/common/data-types';
import {
    TranslateService,
    ConfiguratorsDataService,
    ConfigurationsService,
    APP_CONFIG,
    AppConfigFactory,
    EventBusService,
    core,
} from '@icc/common';
import {
    ModalService,
    isUndefined,
    isObject,
    isDefined,
    isArray,
    castToNullableNumber,
} from '@icc/helpers';
import { CurrentConfiguratorService } from '@icc/common/configurators/current-configurator.service';
import { PriceService } from '@icc/price/b2b';
import { ColorMappingService } from '@icc/common/colors/colors-mapping.service';
import { HardwareTypesPageComponent } from '../hardware-types-page/hardware-types-page.component';
import { HardwareTypeOptionsPageComponent } from '../hardware-type-options-page/hardware-type-options-page.component';
import { DoorActiveConfiguration } from '@icc/common/configurations/DoorActiveConfiguration';
import { _ } from '@icc/configurator/shared';
import { TimeLimitService } from '@icc/common/time-limit/time-limit.service';
import { LocksService } from '@icc/legacy/configurator/steps/door/locks/locks.service';
import { iccListTab } from '@icc/configurator/ui';
import { AccessoriesService } from '@icc/legacy/configurator/steps/window/accessories/accessories.service';
@Injectable({
    providedIn: 'root',
})
export class HardwareService {
    private configurators = ['door'];

    doorHandleAdjustments: any[] = [];
    hardwareTypes: any[] = [];
    hardwareColors: IccAccessoryColor[] = [];
    electronicLocks: any[] = [];
    pulls: any[] = [];
    hinges: any[] = [];
    platesTypes: any = [];
    doorViewers: any = [];
    platesToMainLock: any = [];
    platesToAdditionalLock: any = [];
    plateKinds: any = [];
    cylindersToMainLock: any = [];
    cylindersToAdditionalLock: any = [];
    cylinderSystems: any = [];
    decoHingeCovers: any = [];
    loadedData = false;
    dependencies = [];

    constructor(
        private translateService: TranslateService,
        private modalService: ModalService,
        private configuratorsDataService: ConfiguratorsDataService,
        private currentConfiguratorService: CurrentConfiguratorService,
        private configurationsService: ConfigurationsService<'door'>,
        private priceService: PriceService,
        @Inject(APP_CONFIG) private config: AppConfigFactory,
        private colorMappingService: ColorMappingService,
        private eventBusService: EventBusService,
        private timeLimitService: TimeLimitService,
        private locksService: LocksService,
        private accessoriesService: AccessoriesService
    ) {
        if (this.configuratorsDataService.loaded) {
            this.init();
        }

        this.eventBusService.subscribeWithoutConfiguration(
            ['initializedConfigurator', 'loadedConfiguratorsData'],
            () => {
                this.init();
            }
        );

        this.eventBusService.subscribe(['accessoryColorBasedOnMapping'], (data) => {
            const value = data.value as {
                colorId: number;
                place: 'pull' | 'decoHingeCovers' | 'handle' | 'cylinder' | 'accessory' | 'plates';
            };
            this.setColorsBasedOnColorMapping(value.colorId, value.place);
        });

        this.eventBusService.subscribe(
            ['changedFillings'],
            data => {
                if (DoorActiveConfiguration.is(data.activeConfiguration) && data.activeConfiguration.System.door_type) {
                    this.setDefaultSet(data.activeConfiguration);
                    this.setDefaultDoorViewer(data.activeConfiguration);
                    this.validateAndFixIssues();
                }
            }
        );

        this.eventBusService.subscribe<unknown[]>(['setFrameProfile'], (data) => {
            if (
                DoorActiveConfiguration.is(data.activeConfiguration) &&
                data.activeConfiguration.System.door_type
            ) {
                this.validateAndFixIssues();
            }
        });
    }

    validateAndFixIssues(conf = this.configurationsService.conf.Current) {
        if (this.pulls.length && conf.doorHardware.set === 'leverPull' && !this.isPullBasedOnDependenciesAvailable()) {
            this.setDefaultPull();
        }

        if (this.platesToMainLock.length && conf.doorHardware.plates.mainLock && !this.isPlateBasedOnDepenencyAvailable()) {
            this.setDefaultPlateToMainLock();
        }
    }

    /**
     * Funkcja inicjalizacyjna
     */
    init() {
        if (this.configurators.indexOf(this.currentConfiguratorService.conf) === -1) {
            return;
        }

        this.hardwareColors = this.configuratorsDataService.data.windowHandlesColors;
        this.cylinderSystems = this.configuratorsDataService.data.hardwareSystems || [];
        this.dependencies = this.configuratorsDataService.data.dependencies || [];
        
        this.findHardwareBySystem();

        this.loadedData = true;
    }

    // eslint-disable-next-line max-statements
    findHardwareBySystem(conf = this.configurationsService.conf.Current) {
        if (!isObject(conf.System) || isUndefined(conf.System.id)) {
            return;
        }
        this.doorHandleAdjustments = [];
        this.hardwareTypes = [];
        this.pulls = [];
        this.hinges = [];
        this.electronicLocks = [];
        this.platesTypes = [];
        this.doorViewers = [];
        this.platesToMainLock = [];
        this.platesToAdditionalLock = [];
        this.plateKinds = [];
        this.cylindersToMainLock = [];
        this.cylindersToAdditionalLock = [];
        this.decoHingeCovers = [];
        (this.configuratorsDataService.data.doorHandleAdjustments || []).forEach(lock => {
            const systemIds = lock.window_lines_ids;
            if (systemIds.indexOf(conf.System.id) > -1) {
                this.doorHandleAdjustments.push(lock);
            }
        });
        this.doorHandleAdjustments.unshift({
            id: null,
            name: this.translateService.instant('INTERFACE|Brak'),
        });
        (this.configuratorsDataService.data.hardwareTypes || []).forEach(lock => {
            const systemIds = lock.window_lines_ids;
            if (systemIds.indexOf(conf.System.id) > -1) {
                this.hardwareTypes.push(lock);
            }
        });
        (this.configuratorsDataService.data.hinges || []).forEach(hinge => {
            const systemIds = hinge.window_lines_ids;
            if (systemIds.indexOf(conf.System.id) > -1) {
                this.hinges.push(hinge);
            }
        });
        (this.configuratorsDataService.data.pullType || []).forEach(item => {
            const systemIds = item.window_lines_ids;
            if (systemIds.indexOf(conf.System.id) > -1) {
                this.pulls.push(item);
            }
        });
        (this.configuratorsDataService.data.electronicLocks || []).forEach(item => {
            const systemIds = item.window_lines_ids;
            if (systemIds.indexOf(conf.System.id) > -1) {
                this.electronicLocks.push(item);
            }
        });
        (this.configuratorsDataService.data.plateTypes || []).forEach(item => {
            const systemIds = item.window_lines_ids;
            if (systemIds.indexOf(conf.System.id) > -1) {
                this.platesTypes.push(item);
            }
        });
        if (!conf.System.always_with_viewer_if_available) {
            this.doorViewers.push({
                id: 0,
                name: this.translateService.instant('INTERFACE|Brak'),
            });
        }
        (this.configuratorsDataService.data.doorViewers || []).forEach(item => {
            const systemIds = item.window_lines_ids;
            if (systemIds.indexOf(conf.System.id) > -1) {
                this.doorViewers.push(item);
            }
        });
        (this.configuratorsDataService.data.plates || []).forEach(item => {
            const systemIds = item.window_lines_ids;
            if (systemIds.indexOf(conf.System.id) > -1) {
                if (item.fits_the_lock) {
                    this.platesToAdditionalLock.push(item);
                } else {
                    this.platesToMainLock.push(item);
                    if (!this.plateKinds.includes(item.plate_type)) {
                        this.plateKinds.push(item.plate_type);
                    }
                }
            }
        });
        (this.configuratorsDataService.data.cylinders || []).forEach(item => {
            const systemIds = item.window_lines_ids;
            if (systemIds.indexOf(conf.System.id) > -1) {
                if (item.fits_the_lock) {
                    this.cylindersToAdditionalLock.push(item);
                } else {
                    this.cylindersToMainLock.push(item);
                }
            }
        });
        (this.configuratorsDataService.data.decoHingeCovers || []).forEach(item => {
            const systemIds = item.window_lines_ids;
            if (systemIds.indexOf(conf.System.id) > -1) {
                this.decoHingeCovers.push(item);
            }
        });
        if (!this.configurationsService.conf.Edit) {
            this.setDefaultDoorHandleAdjustments(conf);
            this.setDefaultType(conf);
            this.setDefaultElectronicLock(conf);
            this.setDefaultDoorViewer(conf);
            this.setDefaultSet(conf);
            this.setDefaultPlateType(conf);
            this.setDefaultPlateToMainLock(conf);
            this.setDefaultPlateToAdditionalLock(conf);
            this.setDefaultCylinderToMainLock(conf);
            this.setDefaultDecorativeHingeCover(conf);
            this.setDefaultPull(conf);
            if (!conf.System.available_door_viewer_punching) {
                this.changeDoorViewerPunching(false, conf)
            }
        }
    }

    openDoorHandleAdjustmentsModal(conf = this.configurationsService.conf.Current) {
        const modalInstance = this.modalService.open({
            pageComponent: ListPageComponent,
            resolve: {
                title: () => _('DOOR|Przystosowanie pod pochwyt'),
                selectedId: () => conf.doorHardware.doorHandleAdjustment,
                items: () => this.doorHandleAdjustments,
                imageUrl: () => '/files/windowaccessory',
            },
        });

        modalInstance.result.then(selected => {
            if (isObject(selected) && isDefined(selected)) {
                this.setDoorHandleAdjustments(selected.id);
            }
        });
    }

    setDefaultDoorHandleAdjustments(conf = this.configurationsService.conf.Current) {
        const adjustments = this.doorHandleAdjustments;
        if (adjustments.length > 0 && conf.doorHardware.set === 'leverPull' && conf.doorHardware.type) {
            if (!adjustments.map(p => castToNullableNumber(p.id)).includes(conf.doorHardware.doorHandleAdjustment)) {
                this.setDoorHandleAdjustments(adjustments[0].id);
            }
        } else {
            this.setDoorHandleAdjustments(null);
        }
    }

    setDoorHandleAdjustments(id: number | null, conf = this.configurationsService.conf.Current) {
        conf.doorHardware.doorHandleAdjustment = castToNullableNumber(id);
        this.setDefaultType(conf);
        this.emitEvent();
    }

    getTypes(conf = this.configurationsService.conf.Current) {
        const types = this.hardwareTypes.filter(type =>
            type.accessories
                .map(castToNullableNumber)
                .includes(conf.doorHardware.doorHandleAdjustment)
            && (type.hardware_sets || []).includes(conf.doorHardware.set)
        );
        types.push({
            id: null,
            name: this.translateService.instant('INTERFACE|Brak'),
        });
        return types;
    }

    openModalType(conf = this.configurationsService.conf.Current) {
        const matchedColor = this.hardwareColors.find(
            (el) => Number(el.id) === conf.doorHardware.color
        );
        const modalInstance = this.modalService.open({
            pageComponent: HardwareTypesPageComponent,
            resolve: {
                title: () => _('DOOR|Wybierz typ osprzętu'),
                types: () => this.getTypes(conf),
                selType: () => conf.doorHardware.type,
                hardwareColors: () => this.hardwareColors,
                selColor: () => matchedColor,
            },
        });
        if (this.config().IccConfig.Configurators.tutorialAvailable) {
            this.eventBusService.post({
                key: 'tutorialSteps',
                value: 'modalHinge',
            });
        }

        modalInstance.result.then(selectedData => {
            if (isObject(selectedData) && isDefined(selectedData.type)) {
                this.setType(selectedData.type.id);
                this.setHardwareColorById(selectedData.color, selectedData.defaultColor);
                this.eventBusService.post({ key: 'processDependencies', value: null });
                this.priceService.count();
            }
        });

        modalInstance.closed.then(() => {
            if (this.config().IccConfig.Configurators.tutorialAvailable) {
                this.eventBusService.post({
                    key: 'tutorialSteps',
                    value: 'getStepImg',
                });
            }
        });
    }

    setDefaultType(conf = this.configurationsService.conf.Current) {
        const hardwareTypes = this.getTypes(conf);
        if (hardwareTypes.length > 0) {
            this.setType(castToNullableNumber(hardwareTypes[0].id), conf);
            this.setDefaultColorForHardware(conf);
        } else {
            this.setType(null, conf);
        }
    }

    setType(type: number | null, conf = this.configurationsService.conf.Current) {
        conf.doorHardware.type = castToNullableNumber(type);
        this.setDefaultElectronicLock(conf);
        this.setDefaultPull(conf);
        this.setDefaultPlateType(conf);
        this.setDefaultPlateToMainLock(conf);
        this.setDefaultCylinderToMainLock(conf);
        this.setDefaultDecorativeHingeCover(conf);
        this.emitEvent();
    }

    openModalTypeOptions() {
        const matchedColor = this.hardwareColors.find(
            (el) => Number(el.id) === this.configurationsService.conf.Current.doorHardware.color
        );
        const hardwareTypeId =
            this.configurationsService.conf.Current.doorHardware
            && this.configurationsService.conf.Current.doorHardware.type;
        const type = this.hardwareTypes.find(f => Number(f.id) === Number(hardwareTypeId));
        const modalInstance = this.modalService.open({
            pageComponent: HardwareTypeOptionsPageComponent,
            resolve: {
                title: () => _('WINDOW|Opcje osprzętu'),
                selectedType: () => type,
                hardwareColors: () => this.hardwareColors,
                selColor: () => matchedColor,
            },
        });

        modalInstance.result.then(selectedData => {
            if (isObject(selectedData) && isDefined(selectedData.type)) {
                this.setHardwareColorById(selectedData.color);
            }
            this.priceService.count();
            this.timeLimitService.count();
        });
        modalInstance.closed.then(() => {
            if (this.config().IccConfig.Configurators.tutorialAvailable) {
                this.eventBusService.post({
                    key: 'tutorialSteps',
                    value: 'getStepImg',
                });
            }
        });
    }

    /**
     * Funkcja ustawiajca kolor osprzętu
     * @param {object} color Kolor
     */
    setHardwareColor(
        color: IccAccessoryColor | null,
        conf = this.configurationsService.conf.Current
    ) {
        conf.doorHardware.color = color ? Number(color.id) : null;
        this.setDefaultColorForPlate(conf);
        this.setDefaultColorForPull(conf);
        this.setDefaultColorForMainCylinder(conf);
        if (color) {
            this.setColorsBasedOnColorMapping(Number(color.id));
        }
        this.emitEvent();
    }

    /**
     * Funkcja ustawiająca domyslny kolor dla osprzętu
     */
    setDefaultColorForHardware(conf = this.configurationsService.conf.Current) {
        const type = this.hardwareTypes.find(t => Number(t.id) === conf.doorHardware.type);

        if (isObject(type) && isArray(type.colors_ids) && isArray(this.hardwareColors)) {
            const windowColorId = this.colorMappingService.getWindowColorId(conf, 'inner');
            const matchedColors = this.colorMappingService.getColors(
                Number(windowColorId),
                'window',
                'accessory'
            );
            const colors = this.hardwareColors.filter(el => type.colors_ids.indexOf(el.id) > -1);
            if (isArray(colors) && isDefined(colors[0])) {
                const windowColors = matchedColors
                    .map(m => colors.filter(c => Number(c.id) === m)[0])
                    .filter(m => m);
                let color;
                if (isArray(windowColors) && isDefined(windowColors[0])) {
                    color = core.copy(windowColors[0]);
                } else {
                    color = core.copy(colors[0]);
                }
                color.isDefault = true;
                this.setHardwareColor(color, conf);
            }
        } else {
            this.setHardwareColor(null, conf);
        }
    }

    /**
     * Funkcja ustawiająca kolor dla osprzętu
     * @param  {number} colorId  Id koloru
     */
    setHardwareColorById(colorId, isDefault = false) {
        const matchedColors = this.hardwareColors.filter(el => el.id === colorId);
        if (matchedColors.length > 0) {
            const color = core.copy(matchedColors[0]);
            color.isDefault = isDefault;
            this.setHardwareColor(color);
        } else {
            this.setHardwareColor(null);
        }
    }

    hardwareTypeBoxSelect = (conf: DoorActiveConfiguration) => {
        const type = this.getTypes(conf).find(
            t => castToNullableNumber(t.id) === conf.doorHardware.type
        );
        const matchedColor = this.hardwareColors.find(
            (el) => Number(el.id) === conf.doorHardware.color
        );
        const img = type ? '/files/windowaccessory/' + type.img : '';

        const name = type ? type.name : '';
        const color = matchedColor ? matchedColor.name : null;
        const colorId = matchedColor ? matchedColor.id : null;
        const description = type ? type.description : '';
        return {
            img,
            name,
            color,
            colorId,
            description,
        };
    };

    setSet(
        set: null | 'leverKnob' | 'doubleLever' | 'leverPull',
        conf = this.configurationsService.conf.Current
    ) {
        conf.doorHardware.set = set;
        this.setDefaultType();
        this.setDefaultDoorHandleAdjustments();
        this.setDefaultPull();
        this.setDefaultPlateToMainLock();
        this.setDefaultDecorativeHingeCover();
        this.setDefaultColorForHardware();
        this.emitEvent();
    }

    setDefaultSet(conf: DoorActiveConfiguration) {
        const availableSets = this.getAvailableSets(conf).map(s => (s === 'none' ? null : s));
        if (!availableSets.includes(conf.doorHardware.set) || conf.doorHardware.set === null) {
            this.setSet(availableSets[0], conf);
        }
    }

    getAvailableSets(conf: DoorActiveConfiguration) {
        const sets = ['doubleLever', 'leverPull', 'leverKnob', 'none'];
        const doorActiveSash = conf.Sashes.find(s => ['DRA', 'DOA'].includes(s.type.type));
        const selectedFilling = doorActiveSash && doorActiveSash.glazing ? doorActiveSash.glazing : conf.Model;
        return (conf.System.hardware_sets || [])
            .filter(s => s !== 'leverPull' || !selectedFilling || selectedFilling.has_pull)
            .sort((a, b) => sets.indexOf(a) - sets.indexOf(b));
    }

    openPullsModal() {
        const matchedColor = this.hardwareColors.find(el => (Number(el.id) === this.configurationsService.conf.Current.doorHardware.pullColor));

        const modalInstance = this.modalService.open({
            pageComponent: HardwareTypesPageComponent,
            resolve: {
                title: () => _('DOOR|Pochwyty'),
                types: () => this.pulls,
                tabs: () => this.getTabsBasedOnWindowAccessoriesCategoryId(this.pulls),
                selType: () => this.configurationsService.conf.Current.doorHardware.pull,
                hardwareColors: () => this.hardwareColors,
                selColor: () => matchedColor,
                canOpenOptions: () => (type: IccAccessoryAccessory) => !type.pull_color_related_to_plate
            },
        });

        modalInstance.result.then(selected => {
            if (isObject(selected) && isDefined(selected.type)) {
                this.setPull(Number(selected.type.id), selected.type.pull_color_related_to_plate);
                if (!selected.type.pull_color_related_to_plate && selected.type?.colors_ids) {
                    this.setPullColorById(selected.color, selected.defaultColor);
                } else {
                    this.setDefaultColorForPull();
                }
                this.eventBusService.post({ key: 'processDependencies', value: null });
                this.priceService.count();
            }
        });
    }

    setDefaultPull(conf = this.configurationsService.conf.Current) {
        if (this.pulls.length > 0 && conf.doorHardware.set === 'leverPull') {
            if (!this.pulls.map(p => castToNullableNumber(p.id)).includes(conf.doorHardware.pull) || !this.isPullBasedOnDependenciesAvailable()) {
                
                const pull = this.getPull();
                this.setPull(Number(pull.id), pull.pull_color_related_to_plate);

                if (!pull.pull_color_related_to_plate && pull?.colors_ids?.length > 0 && pull?.colors_ids[0]) {
                    this.setPullColorById(pull.colors_ids[0]);
                } else {
                    this.setDefaultColorForPull();
                }
            }
        } else {
            this.setPull(null, null);
        }
    }


    getPull(conf = this.configurationsService.conf.Current) {
        const pullCategoryId = this.pulls.find(
            (p) => Number(p.id) === Number(conf.doorHardware?.pull)
        )?.window_accessories_category_id;

        if (pullCategoryId) {
            return this.pulls.find(
                (p) =>
                    this.isPullBasedOnDependenciesAvailable(Number(p.id)) &&
                    Number(p?.window_accessories_category_id) === Number(pullCategoryId)
            );
        }

        return this.pulls.find((p) => this.isPullBasedOnDependenciesAvailable(Number(p.id)));
    }

    isPlateBasedOnDepenencyAvailable(plateId = this.configurationsService.conf.Current.doorHardware?.plates?.mainLock) {
        let isPlateAvailable = true;

        const blockedItems = this.configurationsService.conf.Current.Dependencies.filter(
            (p) => p.type === 'blockade_to_configuration'
        );

        if (Array.isArray(blockedItems) && !blockedItems.length || !plateId) {
            return isPlateAvailable;
        }

        this.dependencies.forEach(dependency => {
            blockedItems.forEach(blocked => {
                if (dependency && blocked && dependency.id === blocked.id) {
                    dependency.conditions.forEach(condition => {
                        if (condition.type) {
                            if (plateId && condition.value && Number(plateId) === Number(condition.value)) {
                                isPlateAvailable = false;
                            }
                        }
                    })
                }
            });
        });

        return isPlateAvailable;
    }

    isPullBasedOnDependenciesAvailable(pullId = this.configurationsService.conf.Current.doorHardware?.pull) {
        let isPullAvailable = true;
       
        const blockedItems = this.configurationsService.conf.Current.Dependencies.filter(
            (p) => p.type === 'blockade_to_configuration'
        );

        if (Array.isArray(blockedItems) && !blockedItems.length || !pullId) {
            return isPullAvailable;
        }

        this.dependencies.forEach(dependency => {
            blockedItems.forEach(blocked => {
                if (dependency && blocked && dependency.id === blocked.id) {
                    dependency.conditions.forEach(condition => {
                        if (condition.type === 'pull') {
                            if (pullId && condition.value && pullId === Number(condition.value)) {
                                isPullAvailable = false;
                            }
                        }
                    })
                }
            });
        });

        return isPullAvailable;
    }

    setPull(id: number | null, pullColorRelatedToPlate: boolean | null, conf = this.configurationsService.conf.Current) {
        conf.doorHardware.pull = id;
        conf.doorHardware.pullColorRelatedToPlate = pullColorRelatedToPlate;
        this.emitEvent();
        this.eventBusService.post({ key: 'processDependencies', value: null });
    }

    openLockModalInSash(lockBox) {
        const modalInstance = this.modalService.open({
            pageComponent: ListPageComponent,
            resolve: {
                title: () => _('DOOR|Wybór zamka'),
                selectedId: () => lockBox.sash.lock.id,
                items: () => this.locksService.locks,
                imageUrl: () => '/files/windowaccessory',
            },
        });

        modalInstance.result.then(selected => {
            if (isObject(selected) && isDefined(selected)) {
                lockBox.sash.lock = selected;
                if (this.config().IccConfig.Configurators.dependencies) {
                    this.eventBusService.post({ key: 'processDependencies', value: null });
                }
                this.priceService.count();
            }
            this.timeLimitService.count();
        });

        modalInstance.closed.then(() => {
            if (this.config().IccConfig.Configurators.tutorialAvailable) {
                this.eventBusService.post({
                    key: 'tutorialSteps',
                    value: 'getStepImg',
                });
            }
        });
    }

    openLockModal(conf = this.configurationsService.conf.Current) {

        const modalInstance = this.modalService.open({
            pageComponent: ListPageComponent,
            resolve: {
                title: () => _('DOOR|Wybór zamka'),
                selectedId: () => conf.Lock.id,
                items: () => this.locksService.locks,
                imageUrl: () => '/files/windowaccessory',
            },
        });

        modalInstance.result.then(selected => {
            if (isObject(selected) && isDefined(selected)) {
                this.locksService.selectLock(selected);
            }
            this.timeLimitService.count();
        });

        modalInstance.closed.then(() => {
            if (this.config().IccConfig.Configurators.tutorialAvailable) {
                this.eventBusService.post({
                    key: 'tutorialSteps',
                    value: 'getStepImg',
                });
            }
        });
    }

    openElectronicLockModal(conf = this.configurationsService.conf.Current) {
        const modalInstance = this.modalService.open({
            pageComponent: ListPageComponent,
            resolve: {
                title: () => _('DOOR|Elektroniczne wkładki'),
                selectedId: () => conf.doorHardware.electronicLock,
                items: () => this.electronicLocks,
                imageUrl: () => '/files/windowaccessory',
            },
        });

        modalInstance.result.then(selected => {
            if (isObject(selected) && isDefined(selected)) {
                this.setElectronicLock(Number(selected.id));
            }
        });
    }

    setDefaultElectronicLock(conf = this.configurationsService.conf.Current) {
        const type = this.hardwareTypes.find(f => Number(f.id) === Number(conf.doorHardware.type));
        const cylinder = this.cylindersToMainLock.find(
            f => Number(f.id) === Number(conf.doorHardware.cylinders.mainLock)
        );
        if (
            this.electronicLocks.length > 0
            && ((type && type.electronic_door_lock) || (cylinder && cylinder.electronic_door_lock))
        ) {
            if (!this.electronicLocks.map(p => castToNullableNumber(p.id)).includes(conf.doorHardware.electronicLock)) {
                this.setElectronicLock(Number(this.electronicLocks[0].id));
            }
        } else {
            this.setElectronicLock(null);
        }
    }

    setElectronicLock(id: number | null, conf = this.configurationsService.conf.Current) {
        conf.doorHardware.electronicLock = id;
        this.emitEvent();
    }

    openPlateTypeModal(conf = this.configurationsService.conf.Current) {
        const modalInstance = this.modalService.open({
            pageComponent: ListPageComponent,
            resolve: {
                title: () => _('DOOR|Typy oszyldowania'),
                selectedId: () => conf.doorHardware.plateType,
                items: () => this.getPlateTypes(conf),
                imageUrl: () => '/files/windowaccessory',
            },
        });

        modalInstance.result.then(selected => {
            if (isObject(selected) && isDefined(selected)) {
                this.setPlateType(Number(selected.id));
            }
        });
    }

    setDefaultPlateType(conf = this.configurationsService.conf.Current) {
        const platesTypes = this.getPlateTypes(conf);
        if (
            platesTypes.length > 0
            && !platesTypes.map(p => castToNullableNumber(p.id)).includes(conf.doorHardware.plateType)
        ) {
            this.setPlateType(Number(platesTypes[0].id));
        } else if (platesTypes.length === 0) {
            this.setPlateType(null);
        }
    }

    setPlateType(id: number | null, conf = this.configurationsService.conf.Current) {
        conf.doorHardware.plateType = id;
        this.emitEvent();
    }

    getPlateTypes(conf = this.configurationsService.conf.Current) {
        return this.platesTypes.filter(type =>
            type.accessories.map(Number).includes(conf.doorHardware.type)
        );
    }

    openDoorViewerModal(conf = this.configurationsService.conf.Current) {
        const modalInstance = this.modalService.open({
            pageComponent: ListPageComponent,
            resolve: {
                title: () => _('DOOR|Wizjery'),
                selectedId: () =>
                    conf.doorHardware.doorViewer === null
                        ? null
                        : String(conf.doorHardware.doorViewer),
                items: () => this.getAvailableDoorViewers(conf),
                imageUrl: () => '/files/windowaccessory',
                defaultImageUrl: () => '/img/door/wizjer_brak.jpg'
            },
        });

        modalInstance.result.then(selected => {
            if (isObject(selected) && isDefined(selected)) {
                this.setDoorViewer(selected.id);
            }
        });
    }

    setDefaultDoorViewer(conf = this.configurationsService.conf.Current) {
        const doorViewers = this.getAvailableDoorViewers(conf);
        if (doorViewers.length > 1) {
            if (!conf.doorHardware.doorViewer || !doorViewers.map(p => castToNullableNumber(p.id)).includes(conf.doorHardware.doorViewer)) {
                this.setDoorViewer(doorViewers[0].id);
            }
        } else {
            this.setDoorViewer(null);
        }
    }

    setDoorViewer(id: number | null, conf = this.configurationsService.conf.Current) {
        conf.doorHardware.doorViewer = id === 0 ? id : Number(id);
        if (!conf.doorHardware.doorViewer) this.changeDoorViewerPunching(false, conf);
        this.emitEvent();
    }

    getAvailableDoorViewers(conf = this.configurationsService.conf.Current) {
        const doorActiveSash = conf.Sashes.find(s => ['DRA', 'DOA'].includes(s.type.type));
        const selectedFilling = doorActiveSash && doorActiveSash.glazing ? doorActiveSash.glazing : conf.Model;
        return (selectedFilling && selectedFilling.has_door_viewer) ? this.doorViewers : this.doorViewers.filter(v => v.id === 0);
    }

    getPlatesToMainLock(conf = this.configurationsService.conf.Current) {
        return this.platesToMainLock.filter(plate =>
            (plate.hardware_sets || []).includes(conf.doorHardware.set)
        );
    }

    getPlatesToAdditionalLock(conf = this.configurationsService.conf.Current) {
        const platetoMainLock = this.platesToMainLock.find(
            p => castToNullableNumber(p.id) === conf.doorHardware.plates.mainLock
        );
        return this.platesToAdditionalLock.filter(
            plate =>
                platetoMainLock
                && (plate.hardware_sets || []).includes(conf.doorHardware.set)
                && plate.accessories.map(Number).includes(Number(platetoMainLock.id))
        );
    }

    getPlateKinds(conf = this.configurationsService.conf.Current) {
        return this.platesToMainLock.reduce((plateKinds, plate) => {
            if (!plateKinds.includes(plate.plate_type)) {
                plateKinds.push(plate.plate_type);
            }
            return plateKinds;
        }, []);
    }

    openPlateToMainLockModal() {
        const matchedColor = this.hardwareColors.find(
            (el) =>
                Number(el.id) === this.configurationsService.conf.Current.doorHardware.plates.color
        );
        const modalInstance = this.modalService.open({
            pageComponent: HardwareTypesPageComponent,
            resolve: {
                title: () => _('DOOR|Szyldy zamka głównego'),
                types: () => this.getPlatesToMainLock(),
                tabs: () => this.getPlateKinds(),
                selType: () => this.configurationsService.conf.Current.doorHardware.plates.mainLock,
                hardwareColors: () => this.hardwareColors,
                selColor: () => matchedColor,
            },
        });

        modalInstance.result.then(selectedData => {
            if (isObject(selectedData) && isDefined(selectedData.type)) {
                this.setPlateToMainLock(Number(selectedData.type.id));
                this.setPlateColorById(selectedData.color, selectedData.defaultColor);
                this.eventBusService.post({ key: 'processDependencies', value: null });
                this.priceService.count();
            }
        });
    }

    /**
     * @returns {boolean} [true] - if default plate has been set
     * @returns {boolean} [false] - if default plate has been set to null
     */
    setDefaultPlateToMainLock(conf = this.configurationsService.conf.Current) {
        const plates = this.getPlatesToMainLock(conf).filter(p => this.isPlateBasedOnDepenencyAvailable(p.id));

        const isDoorHardwareType = (this.hardwareTypes.length > 0 && conf.doorHardware.type);
        let isPlateSet = false;
        if (plates.length > 0 && conf.doorHardware.set) {
            if (isDoorHardwareType) {
                const type = this.getTypes(conf).find(
                    t => Number(t.id) === Number(conf.doorHardware.type)
                );
                if (type) {
                    let defaultPlateId;
                    defaultPlateId = type.plate_main_lock_lever_id;
                    if (conf.doorHardware.set === 'leverKnob' && type.plate_main_lock_knob_id) {
                        defaultPlateId = type.plate_main_lock_knob_id;
                    } else if (
                        conf.doorHardware.set === 'leverPull'
                        && type.plate_main_lock_pull_id
                    ) {
                        defaultPlateId = type.plate_main_lock_pull_id;
                    }
                    if (defaultPlateId && plates.map(p => castToNullableNumber(p.id)).includes(Number(defaultPlateId))) {
                        this.setPlateToMainLock(Number(defaultPlateId));
                        isPlateSet = true;
                    }
                }
            } else if (this.hardwareTypes.length === 0) {
                if (!plates.map(p => castToNullableNumber(p.id)).includes(conf.doorHardware.plates.mainLock)) {
                    this.setPlateToMainLock(Number(plates[0].id));
                    this.setDefaultColorForPlate(conf);
                    isPlateSet = true;
                } else if (
                    conf.doorHardware.plates.mainLock
                    && plates.map(p => castToNullableNumber(p.id)).includes(conf.doorHardware.plates.mainLock)
                ) {
                    isPlateSet = true;
                }
            }
        }
        if (!isPlateSet) {
            this.setPlateToMainLock(null);
            this.setDefaultColorForPlate(conf);
        }
    }

    setPlateToMainLock(id: number | null, conf = this.configurationsService.conf.Current) {
        conf.doorHardware.plates.mainLock = id;
        this.setDefaultCylinderToMainLock(conf);
        this.setDefaultPlateToAdditionalLock(conf);
        this.eventBusService.post({ key: 'processDependencies', value: null });
        this.emitEvent();
    }

    /**
     * Okno modalne z wyborem klamki
     * @param  {object} sash      Skrzydło
     * @param  {string} place     Położenie
     * @param  {string} type      Typ
     */
    openModalPlateOptions() {
        const matchedColor = this.hardwareColors.find(
            (el) =>
                Number(el.id) === this.configurationsService.conf.Current.doorHardware.plates.color
        );
        const plateId =
            this.configurationsService.conf.Current.doorHardware
            && this.configurationsService.conf.Current.doorHardware.plates.mainLock;
        const plate = this.platesToMainLock.find(f => Number(f.id) === Number(plateId));
        const modalInstance = this.modalService.open({
            pageComponent: HardwareTypeOptionsPageComponent,
            resolve: {
                title: _('WINDOW|Opcje szyldu zamka głównego'),
                selectedType: () => plate,
                hardwareColors: () => this.hardwareColors,
                selColor: () => matchedColor,
            },
        });

        modalInstance.result.then(selectedData => {
            if (isObject(selectedData) && isDefined(selectedData.type)) {
                this.setPlateColorById(selectedData.color);
            }
            this.priceService.count();
            this.timeLimitService.count();
        });
        modalInstance.closed.then(() => {
            if (this.config().IccConfig.Configurators.tutorialAvailable) {
                this.eventBusService.post({
                    key: 'tutorialSteps',
                    value: 'getStepImg',
                });
            }
        });
    }

    openModalPullOptions() {
        const matchedColor = this.hardwareColors.find(
            (el) => Number(el.id) === this.configurationsService.conf.Current.doorHardware.pullColor
        );
        const pullId = this.configurationsService.conf.Current.doorHardware
            && this.configurationsService.conf.Current.doorHardware.pull;
        const pull = this.pulls.find(f => Number(f.id) === Number(pullId));

        const modalInstance = this.modalService.open({
            pageComponent: HardwareTypeOptionsPageComponent,
            resolve: {
                title: _('WINDOW|Opcje pochwytu'),
                selectedType: () => pull,
                hardwareColors: () => this.hardwareColors,
                selColor: () => matchedColor,
            },
        });

        modalInstance.result.then(selectedData => {
            if (isObject(selectedData) && isDefined(selectedData.type)) {
                this.setPullColorById(selectedData.color);
            }
            this.priceService.count();
            this.timeLimitService.count();
        });
        modalInstance.closed.then(() => {
            if (this.config().IccConfig.Configurators.tutorialAvailable) {
                this.eventBusService.post({
                    key: 'tutorialSteps',
                    value: 'getStepImg',
                });
            }
        });
    }

    setPullColor(color: IccAccessoryColor, conf = this.configurationsService.conf.Current) {
        conf.doorHardware.pullColor = (color && Number(color.id)) || null;
        if (color?.id) {
            this.setColorsBasedOnColorMapping(Number(color.id), 'pull');
        }
        this.emitEvent();
    }

    setPullColorById(colorId, isDefault = false) {
        const matchedColors = this.hardwareColors.filter(el => Number(el.id) === Number(colorId));
        if (matchedColors.length > 0) {
            const color = core.copy(matchedColors[0]);
            color.isDefault = isDefault;
            this.setPullColor(color);
        }
    }

    setDefaultColorForPull(conf = this.configurationsService.conf.Current) {
        const pull = this.pulls.find(t => Number(t.id) === conf.doorHardware.pull)

        if (isObject(pull) && isArray(pull.colors_ids) && isArray(this.hardwareColors)) {
            const windowColorId = this.colorMappingService.getWindowColorId(conf, 'inner');
            const matchedColors = this.colorMappingService.getColors(
                Number(windowColorId),
                'window',
                'accessory'
            );
            const colors = this.hardwareColors.filter(el => pull.colors_ids.indexOf(el.id) > -1);
            if (isArray(colors) && isDefined(colors[0])) {
                const plateColors = pull.pull_color_related_to_plate
                    && conf.doorHardware.plates?.color
                    && colors.filter(c => Number(c.id) === conf.doorHardware.plates.color);
                const windowColors = matchedColors
                    .map(m => colors.filter(c => Number(c.id) === m)[0])
                    .filter(m => m);
                let color;
                if (isArray(plateColors) && plateColors[0]) {
                    color = core.copy(plateColors[0]);
                } else if (isArray(windowColors) && isDefined(windowColors[0])) {
                    color = core.copy(windowColors[0]);
                } else {
                    color = core.copy(colors[0]);
                }
                color.isDefault = true;
                this.setPullColor(color, conf);
            }
        } else {
            this.setPullColor(null, conf);
        }
    }

    setDefaultColorForMainCylinder(conf) {
        const cylinder = this.cylindersToMainLock.find(t => Number(t.id) === conf.doorHardware.cylinders.mainLock)
        const cylinderMainLockColors = this.cylindersToMainLock.find(
            (p) => Number(p.id) === Number(conf.doorHardware.cylinders.mainLock)
        )?.colors_ids;
        const isColorAvailable = cylinderMainLockColors?.some((el) => Number(el) === Number(conf.doorHardware.cylinders.color));
        
        if (isColorAvailable) {
            return;
        }

        if (isObject(cylinder) && isArray(cylinder.colors_ids) && isArray(this.hardwareColors)) {
            const windowColorId = this.colorMappingService.getWindowColorId(conf, 'inner');
            const matchedColors = this.colorMappingService.getColors(
                Number(windowColorId),
                'window',
                'accessory'
            );
            const colorsBasedOnColorMapping = this.colorMappingService.getColors(
                Number(windowColorId),
                'accessory',
                'accessory'
            );
            const colors = this.hardwareColors.filter(el => cylinder.colors_ids.indexOf(el.id) > -1);
            if (isArray(colors) && isDefined(colors[0])) {
                const mappingColors = 
                    colorsBasedOnColorMapping
                        .map(m => colors.filter(c => Number(c.id) === m)[0])
                        .filter(m => m) ??
                    matchedColors
                        .map(m => colors.filter(c => Number(c.id) === m)[0])
                        .filter(m => m);
                let color;
                
                if (isArray(mappingColors) && isDefined(mappingColors[0])) {
                    color = core.copy(mappingColors[0]);
                } else {
                    color = core.copy(colors[0]);
                }
                color.isDefault = true;
                this.setCylinderMainLockColor(color, conf);
            }
        } else {
            this.setCylinderMainLockColor(null, conf);
        }
    }

    setCylinderMainLockColor(color: IccAccessoryColor, conf = this.configurationsService.conf.Current) {
        conf.doorHardware.cylinders.color = (color && Number(color.id)) || null;
        this.emitEvent();
    }

    /**
     * Funkcja ustawiajca kolor szyldu
     * @param {object} color Kolor
     */
    setPlateColor(color: IccAccessoryColor, conf = this.configurationsService.conf.Current) {
        conf.doorHardware.plates.color = (color && Number(color.id)) || null;
        if (this.configurationsService.conf.Current.doorHardware.pullColorRelatedToPlate) {
            this.setDefaultColorForPull(conf);
        }
        if (conf.doorHardware.decorativeHingeCoversColorRelatedToPlate && conf.doorHardware.plates?.color) {
            this.setDecorativeHingeCoverColorById(conf.doorHardware.plates.color);
        }
        
        this.accessoriesService.setAccessoriesColorBasedOnHardware('plates');
        this.setColorsBasedOnColorMapping(conf.doorHardware.plates.color, 'plates')
        this.emitEvent();
    }

    /**
     * Funkcja ustawiająca domyslny kolor dla szyldów
     */
    setDefaultColorForPlate(conf = this.configurationsService.conf.Current) {
        const plate = this.platesToMainLock.find(
            t => Number(t.id) === conf.doorHardware.plates.mainLock
        );

        if (isObject(plate) && isArray(plate.colors_ids) && isArray(this.hardwareColors)) {
            const windowColorId = this.colorMappingService.getWindowColorId(conf, 'inner');
            const matchedColors = this.colorMappingService.getColors(
                Number(windowColorId),
                'window',
                'accessory'
            );
            const colors = this.hardwareColors.filter(el => plate.colors_ids.indexOf(el.id) > -1);
            if (isArray(colors) && isDefined(colors[0])) {
                const typeColors = conf.doorHardware.type && conf.doorHardware.color && colors.filter(c => Number(c.id) === conf.doorHardware.color);
                const windowColors = matchedColors
                    .map(m => colors.filter(c => Number(c.id) === m)[0])
                    .filter(m => m);
                let color;
                if (isArray(typeColors) && typeColors[0]) {
                    color = core.copy(typeColors[0]);
                } else if (isArray(windowColors) && isDefined(windowColors[0])) {
                    color = core.copy(windowColors[0]);
                } else {
                    color = core.copy(colors[0]);
                }
                color.isDefault = true;
                this.setPlateColor(color, conf);
            }
        } else {
            this.setPlateColor(null, conf);
        }
    }

    /**
     * Funkcja ustawiająca kolor dla szyldu
     * @param  {number} colorId  Id koloru
     */
    setPlateColorById(colorId, isDefault = false) {
        const matchedColors = this.hardwareColors.filter(el => el.id === colorId);
        if (matchedColors.length > 0) {
            const color = core.copy(matchedColors[0]);
            color.isDefault = isDefault;
            this.setPlateColor(color);
        }
    }

        
    private getImageUrl(hardware: IccAccessoryAccessory, selectedColor: string): string {
        return hardware && (hardware.colors_imgs && hardware.colors_imgs[selectedColor]
            ? '/files/windowhandlescolorswindowaccessory/' + hardware.colors_imgs[selectedColor]
            : '/files/windowaccessory/' + hardware.img);
    }
    plateAdditionalLockBoxSelect = (conf: DoorActiveConfiguration) => {
        const plate = this.platesToAdditionalLock.find(
            t => Number(t.id) === conf.doorHardware.plates.additionalLock
        );
        const matchedColor = this.hardwareColors.find(el =>  Number(el.id) === conf.doorHardware.plates.color);
        const name = plate ? plate.name : '';
        const color = matchedColor ? matchedColor.name : null;
        const colorId = matchedColor ? matchedColor.id : null;
        const description = plate ? plate.description : '';
        const imagePath = this.getImageUrl(plate, colorId);
        return {
            imagePath,
            name,
            color,
            colorId,
            description,
        };
    }
    plateMainLockBoxSelect = (conf: DoorActiveConfiguration) => {
        const plate = this.platesToMainLock.find(
            t => Number(t.id) === conf.doorHardware.plates.mainLock
        );
        const matchedColor = this.hardwareColors.find(el =>  Number(el.id) === conf.doorHardware.plates.color);
        const name = plate ? plate.name : '';
        const color = matchedColor ? matchedColor.name : null;
        const colorId = matchedColor ? matchedColor.id : null;
        const description = plate ? plate.description : '';
        const imagePath = this.getImageUrl(plate, colorId);
        return {
            imagePath,
            name,
            color,
            colorId,
            description,
        };
    };

    cylinderMainLockBoxSelect = (conf: DoorActiveConfiguration) => {
        const cylinder = this.cylindersToMainLock.find(
            t => Number(t.id) === conf.doorHardware.cylinders.mainLock
        );
        const matchedColor = this.hardwareColors.find(el =>  Number(el.id) === conf.doorHardware.cylinders.color);
        const name = cylinder ? cylinder.name : '';
        const color = matchedColor ? matchedColor.name : null;
        const colorId = matchedColor ? matchedColor.id : null;
        const description = cylinder ? cylinder.description : '';
        const imagePath = this.getImageUrl(cylinder, colorId);
        return {
            imagePath,
            name,
            color,
            colorId,
            description,
        };
    };

    cylinderAdditionalLockBoxSelect = (conf: DoorActiveConfiguration) => {
        const cylinder = this.cylindersToAdditionalLock.find(
            t => Number(t.id) === conf.doorHardware.cylinders.additionalLock
        );
        const matchedColor = this.hardwareColors.find(el =>  Number(el.id) === conf.doorHardware.cylinders.color);
        const name = cylinder ? cylinder.name : '';
        const color = matchedColor ? matchedColor.name : null;
        const colorId = matchedColor ? matchedColor.id : null;
        const description = cylinder ? cylinder.description : '';
        const imagePath = this.getImageUrl(cylinder, colorId);
        return {
            imagePath,
            name,
            color,
            colorId,
            description,
        };
    };

    hingeBoxSelect = (conf: DoorActiveConfiguration) => {
        if (!conf.Hinge?.id) {
            return;
        }
        const cylinder = this.hinges.find(
            t => Number(t.id) === Number(conf.Hinge.id)
        );
        const matchedColor = this.hardwareColors.find(el =>  Number(el.id) === Number(conf.HingeColor?.id));
        const colorId = matchedColor ? matchedColor.id : null;
        const imagePath = this.getImageUrl(cylinder, colorId);
        return {
            imagePath,
        };
    }
    
    openPlateToAdditionalLockModal(conf = this.configurationsService.conf.Current) {
        const modalInstance = this.modalService.open({
            pageComponent: ListPageComponent,
            resolve: {
                title: () => _('DOOR|Szyldy zamka dodatkowego'),
                selectedId: () => conf.doorHardware.plates.additionalLock,
                items: () => this.getPlatesToAdditionalLock(conf),
                imageUrl: () => '/files/windowaccessory',
            },
        });

        modalInstance.result.then(selected => {
            if (isObject(selected) && isDefined(selected)) {
                this.setPlateToAdditionalLock(Number(selected.id));
            }
        });
    }

    setDefaultPlateToAdditionalLock(conf = this.configurationsService.conf.Current) {
        const plates = this.getPlatesToAdditionalLock(conf);
        if (plates.length > 0) {
            if (this.hardwareTypes.length > 0 && conf.doorHardware.type) {
                const type = this.getTypes(conf).find(
                    t => Number(t.id) === Number(conf.doorHardware.type)
                );
                if (
                    type
                    && type.plate_additional_lock_id
                    && plates.map(p => castToNullableNumber(p.id)).includes(Number(type.plate_additional_lock_id))
                ) {
                    this.setPlateToAdditionalLock(Number(type.plate_additional_lock_id));
                }
            }
            if (!plates.map(p => castToNullableNumber(p.id)).includes(conf.doorHardware.plates.additionalLock)) {
                this.setPlateToAdditionalLock(Number(plates[0].id));
            }
        } else {
            this.setPlateToAdditionalLock(null);
        }
    }

    setPlateToAdditionalLock(id: number | null, conf = this.configurationsService.conf.Current) {
        conf.doorHardware.plates.additionalLock = id;
        this.setDefaultCylinderToAdditionalLock(conf);
        this.emitEvent();
    }

    openCylinderToMainLockModal(conf = this.configurationsService.conf.Current) {
        const matchedColor = this.hardwareColors.find(
            (el) =>
                Number(el.id) === this.configurationsService.conf.Current.doorHardware.cylinders.color
        );

        const modalInstance = this.modalService.open({
            pageComponent: HardwareTypesPageComponent,
            resolve: {
                title: () => _('DOOR|Rodzaje wkładek zamka głównego'),
                types: () => this.getCylindersToMainLock(conf),
                selType: () => conf.doorHardware.cylinders.mainLock,
                selColor: () => matchedColor,
                hardwareColors: () => this.hardwareColors,
                canOpenOptions: () => conf.doorHardware.cylinders.isKeyAvailableForMainLock
            },
        });

        modalInstance.result.then(selected => {
            if (isObject(selected) && isDefined(selected.type)) {
                if (selected?.color) {
                    this.setMainLockColorById(selected.color);
                }
                this.setCylinderToMainLock(Number(selected.type.id));
                this.eventBusService.post({ key: 'processDependencies', value: null });
                this.priceService.count();
            }
        });
    }

    /**
     * @returns {boolean} [true] - if default cylinder has been set or has not changed
     * @returns {boolean} [false] - if default cylinder has been set to null
     */
    setDefaultCylinderToMainLock(conf = this.configurationsService.conf.Current): boolean {
        const cylinders = this.getCylindersToMainLock(conf);
        if (cylinders.length > 0 && conf.doorHardware.set) {
            if (this.hardwareTypes.length === 0) {
                if (!cylinders.map(p => castToNullableNumber(p.id)).includes(conf.doorHardware.cylinders.mainLock)) {
                    this.setCylinderToMainLock(Number(cylinders[0].id));
                }
                return true;
            } else if (
                this.hardwareTypes.length > 0
                && conf.doorHardware.type
            ) {
                const type = this.getTypes(conf).find(
                    t => Number(t.id) === Number(conf.doorHardware.type)
                );
                if (
                    type
                    && type.cylinder_main_lock_id
                    && cylinders.map(p => castToNullableNumber(p.id)).includes(Number(type.cylinder_main_lock_id))
                ) {
                    this.setCylinderToMainLock(Number(type.cylinder_main_lock_id));
                    return true;
                }
            }
        }
        this.setCylinderToMainLock(null);
        return false;
    }

    setCylinderToMainLock(id: number | null, conf = this.configurationsService.conf.Current) {
        conf.doorHardware.cylinders.mainLock = id;
        this.setDefaultCylinderToAdditionalLock(conf);
        this.setDefaultCylinderSystem(conf);
        this.setDefaultElectronicLock(conf);
        this.setKeyOptionsForMainLock(conf);
        this.emitEvent();
    }

    setKeyOptionsForMainLock(conf = this.configurationsService.conf.Current) {
        const cylinder = this.cylindersToMainLock.find(
            c => Number(c.id) === Number(conf.doorHardware.cylinders.mainLock)
        );
        if (this.cylindersToMainLock.length > 0 && cylinder && cylinder.max_key_quantity > 0) {
            this.setDefaultMaxKeyQuantityForMainLock(Number(cylinder.max_key_quantity));
            this.setDefaultKeyPriceForMainLock(Number(cylinder.key_price));

            if (cylinder.additional_key_for_the_cylinder && !cylinder.fits_the_lock) {
                conf.doorHardware.cylinders.isKeyAvailableForMainLock = cylinder.additional_key_for_the_cylinder;
            }
        } else {
            conf.doorHardware.cylinders.isKeyAvailableForMainLock = false;
            conf.doorHardware.cylinders.keyQuantityForMainLock = 0;
        }
    }

    setDefaultMaxKeyQuantityForMainLock(quantity: number | null, conf = this.configurationsService.conf.Current) {
        conf.doorHardware.cylinders.maxKeyQuantityForMainLock = quantity;
    }

    setDefaultKeyPriceForMainLock(price: number | null, conf = this.configurationsService.conf.Current) {
        conf.doorHardware.cylinders.keyPriceForMainLock = price;
    }

    setKeyOptionsForAdditionalLock(conf) {
        const cylinder = this.cylindersToAdditionalLock.find(
            c => Number(c.id) === Number(conf.doorHardware.cylinders.additionalLock)
        );
        if(this.cylindersToAdditionalLock.length > 0 && cylinder && cylinder.additional_key_for_the_cylinder) {
            this.setDefaultMaxKeyQuantityForAdditionalLock(Number(cylinder.max_key_quantity));
            this.setDefaultKeyPriceForAdditionalLock(Number(cylinder.key_price));
            conf.doorHardware.cylinders.isKeyAvailableForAdditionalLock = true;
        } else {
            conf.doorHardware.cylinders.isKeyAvailableForAdditionalLock = false;
            conf.doorHardware.cylinders.keyQuantityForAdditionalLock = 0;
        }
    }

    setDefaultMaxKeyQuantityForAdditionalLock(quantity: number | null, conf = this.configurationsService.conf.Current) {
        conf.doorHardware.cylinders.maxKeyQuantityForMainLock = quantity;
    }

    setDefaultKeyPriceForAdditionalLock(price: number | null, conf = this.configurationsService.conf.Current) {
        conf.doorHardware.cylinders.keyPriceForAdditionalLock = price;
    }

    getCylindersToMainLock(conf = this.configurationsService.conf.Current) {
        const plateToMainLock = this.platesToMainLock.find(
            p => castToNullableNumber(p.id) === conf.doorHardware.plates.mainLock
        );

        return this.cylindersToMainLock.filter(
            cylinder =>
                plateToMainLock
                && plateToMainLock.accessories.map(Number).includes(Number(cylinder.id))
        );
    }

    getCylindersToAdditionalLock(conf = this.configurationsService.conf.Current) {
        const cylinderToMainLock = this.cylindersToMainLock.find(
            p => castToNullableNumber(p.id) === conf.doorHardware.cylinders.mainLock
        );
        const plateToAdditionalLock = this.platesToAdditionalLock.find(
            p => castToNullableNumber(p.id) === conf.doorHardware.plates.additionalLock
        );

        return this.cylindersToAdditionalLock.filter(
            cylinder =>
                cylinderToMainLock
                && plateToAdditionalLock
                && cylinder.accessories.map(Number).includes(Number(cylinderToMainLock.id))
                && plateToAdditionalLock.accessories.map(Number).includes(Number(cylinder.id))
        );
    }

    openCylinderToAdditionalLockModal(conf = this.configurationsService.conf.Current) {
        const modalInstance = this.modalService.open({
            pageComponent: HardwareTypesPageComponent,
            resolve: {
                title: () => _('DOOR|Rodzaje wkładek zamka dodatkowego'),
                types: () => this.getCylindersToAdditionalLock(),
                selType: () => conf.doorHardware.cylinders.additionalLock,
            },
        });

        modalInstance.result.then(selected => {
            if (isObject(selected) && isDefined(selected.type)) {
                this.setCylinderToAdditionalLock(Number(selected.type.id));
                this.eventBusService.post({ key: 'processDependencies', value: null });
                this.priceService.count();
            }
        });
    }

    setDefaultCylinderToAdditionalLock(conf = this.configurationsService.conf.Current) {
        const cylinders = this.getCylindersToAdditionalLock(conf);
        if (cylinders.length > 0) {
            if (!cylinders.map(p => castToNullableNumber(p.id)).includes(conf.doorHardware.cylinders.additionalLock)) {
                this.setCylinderToAdditionalLock(Number(cylinders[0].id));
            }
        } else if (
            cylinders.length > 0
            && this.hardwareTypes.length > 0
            && conf.doorHardware.type
        ) {
            const type = this.getTypes(conf).find(
                t => Number(t.id) === Number(conf.doorHardware.type)
            );
            if (
                type
                && type.cylinder_additional_lock_id
                && cylinders
                    .map(p => castToNullableNumber(p.id))
                    .includes(Number(type.cylinder_additional_lock_id))
            ) {
                this.setCylinderToAdditionalLock(Number(type.cylinder_additional_lock_id));
            }
        } else {
            this.setCylinderToAdditionalLock(null);
        }
    }
    openModalCylinderToMainLockOptions( conf = this.configurationsService.conf.Current) {
        const cylinderId = conf.doorHardware && conf.doorHardware.cylinders.mainLock;
        const selectedType = this.cylindersToMainLock.find(f => Number(f.id) === Number(cylinderId));
        const matchedColor = this.hardwareColors.find(el => Number(el.id) === this.configurationsService.conf.Current.doorHardware.cylinders.color);
        const modalInstance = this.modalService.open({
            pageComponent: HardwareTypeOptionsPageComponent,
            resolve: {
                title: () => _('DOOR|Opcje wkładki zamka głównego'),
                selectedType: () => selectedType,
                imageUrl: () => '/files/windowaccessory',
                hardwareColors: () => this.hardwareColors,
                selColor: () => matchedColor,
            },
        });

        modalInstance.result.then((selectedData) => {
            if (isObject(selectedData) && isDefined(selectedData.type)) {
                this.setMainLockColorById(selectedData.color);
            }
            this.priceService.count();
            this.timeLimitService.count();
        });

        modalInstance.closed.then(() => {
            if (this.config().IccConfig.Configurators.tutorialAvailable) {
                this.eventBusService.post({
                    key: 'tutorialSteps',
                    value: 'getStepImg',
                });
            }
        });
    }

    setMainLockColorById(colorId, isDefault = false) {
        const matchedColors = this.hardwareColors.filter(el => el.id === colorId);
        
        if (!(matchedColors.length > 0) || !matchedColors[0]) {
            this.setMainLockColor(null);
            return;
        }
        
        const color = core.copy(matchedColors[0]);
        color.isDefault = isDefault;
        this.setMainLockColor(color);
    }

    setMainLockColor(color: IccAccessoryColor, conf = this.configurationsService.conf.Current) {
        conf.doorHardware.cylinders.color = (color && Number(color.id)) || null;
        this.setColorsBasedOnColorMapping(Number(color?.id), 'cylinder');
        this.emitEvent();
    }

    openModalCylinderToAdditionalLockOptions(conf = this.configurationsService.conf.Current) {
        const cylinderId = conf.doorHardware && conf.doorHardware.cylinders.additionalLock;
        const selectedType = this.cylindersToAdditionalLock.find(f => Number(f.id) === Number(cylinderId));
        const modalInstance = this.modalService.open({
            pageComponent: HardwareTypeOptionsPageComponent,
            resolve: {
                title: () => _('DOOR|Opcji wkładki zamka dodatkowego'),
                selectedType: () => selectedType,
                imageUrl: () => '/files/windowaccessory',
            },
        });

          modalInstance.result.then(() => {
            this.priceService.count();
            this.timeLimitService.count();
        });

        modalInstance.closed.then(() => {
            if (this.config().IccConfig.Configurators.tutorialAvailable) {
                this.eventBusService.post({
                    key: 'tutorialSteps',
                    value: 'getStepImg',
                });
            }
        });
    }

    setCylinderToAdditionalLock(id: number | null, conf = this.configurationsService.conf.Current) {
        conf.doorHardware.cylinders.additionalLock = id;
        this.setKeyOptionsForAdditionalLock(conf);
        this.emitEvent();
    }

    getCylinderSystems(conf = this.configurationsService.conf.Current) {
        const cylinderToMainLock = this.cylindersToMainLock.find(
            p => castToNullableNumber(p.id) === conf.doorHardware.cylinders.mainLock
        );
        return this.cylinderSystems.filter(
            system =>
                cylinderToMainLock
                && Number(cylinderToMainLock.window_accessory_system_id) === Number(system.id)
        );
    }

    openCylinderSystemModal(conf = this.configurationsService.conf.Current) {
        const modalInstance = this.modalService.open({
            pageComponent: ListPageComponent,
            resolve: {
                title: () => _('DOOR|Systemy wkładek'),
                selectedId: () => conf.doorHardware.cylinders.system,
                items: () => this.getCylinderSystems(conf),
                imageUrl: () => '/files/windowaccessorysystem',
            },
        });

        modalInstance.result.then(selected => {
            if (isObject(selected) && isDefined(selected)) {
                this.setCylinderSystem(Number(selected.id));
            }
        });
    }

    setDefaultCylinderSystem(conf = this.configurationsService.conf.Current) {
        const system = this.getCylinderSystems(conf);
        if (system.length > 0) {
            if (!system.map(p => castToNullableNumber(p.id)).includes(conf.doorHardware.cylinders.system)) {
                this.setCylinderSystem(Number(system[0].id));
            }
        } else {
            this.setCylinderSystem(null);
        }
    }

    setCylinderSystem(id: number | null, conf = this.configurationsService.conf.Current) {
        conf.doorHardware.cylinders.system = id;
        this.emitEvent();
    }

    changeDoorViewerPunching(value: boolean, conf = this.configurationsService.conf.Current) {
        conf.doorHardware.doorViewerPunching = value;
        this.emitEvent();
    }

    platesBoxSelect = (conf: DoorActiveConfiguration) => {
        const mainLockPlate = this.platesToMainLock.find(
            t => Number(t.id) === conf.doorHardware.plates.mainLock
        );
        const additionalLockPlate = this.platesToAdditionalLock.find(
            t => Number(t.id) === conf.doorHardware.plates.additionalLock
        );
        const matchedColor = this.hardwareColors.find(
            (el) => Number(el.id) === conf.doorHardware.plates.color
        );
        const img = mainLockPlate ? '/files/windowaccessory/' + mainLockPlate.img : '';

        const mainLockName = mainLockPlate ? mainLockPlate.name : '';
        const additionalLockName = additionalLockPlate ? additionalLockPlate.name : '';
        const color = matchedColor ? matchedColor.name : null;
        const colorId = matchedColor ? matchedColor.id : null;
        return {
            img,
            mainLockName,
            additionalLockName,
            color,
            colorId,
        };
    };

    pullsBoxSelect = (conf: DoorActiveConfiguration) => {
        const pullType = this.pulls.find(t => Number(t.id) === conf.doorHardware.pull);
        const matchedColor = this.hardwareColors.find(el => Number(el.id) === conf.doorHardware.pullColor);
        const name = pullType?.name;
        const color = matchedColor ? matchedColor.name : null;
        const colorId = matchedColor ? matchedColor.id : null;
        const imagePath = this.getImageUrl(pullType, colorId);

        return {
           pullType: {
               name,
               color,
               colorId,
               imagePath
           }
        }
    }

    locksBoxSelect = (conf: DoorActiveConfiguration) => {
        const lock = this.locksService.locks.find(l => Number(l.id) === Number(conf.Lock.id));
        const name = lock?.name;
        return {
            lock: {
                name,
            }
        }
    }

    cylindersBoxSelect = (conf: DoorActiveConfiguration) => {
        const mainLockCylinder = this.cylindersToMainLock.find(
            t => Number(t.id) === conf.doorHardware.cylinders.mainLock
        );

        const color =
            this.hardwareColors.find(
                (el) => Number(el.id) === Number(conf.doorHardware.cylinders.color)
            );

        const additionalLockCylinder = this.cylindersToAdditionalLock.find(
            t => Number(t.id) === conf.doorHardware.cylinders.additionalLock
        );
        const cylinderSystem = this.cylinderSystems.find(
            t => Number(t.id) === conf.doorHardware.cylinders.system
        );
        const electronicLockObject = this.electronicLocks.find(
            t => Number(t.id) === conf.doorHardware.electronicLock
        );

        const imagePath = this.getFirstImagePathFromCylinders(
            color?.id ?? '',
            mainLockCylinder,
            additionalLockCylinder,
            cylinderSystem,
            electronicLockObject,
        );

        const mainLockName = mainLockCylinder?.name ?? '';
        const additionalLockName = additionalLockCylinder?.name ?? '';
        const system = cylinderSystem?.name ?? '';
        const electronicLock =electronicLockObject?.name ?? '';
        return {
            cylinders: {
                imagePath,
                mainLockName,
                additionalLockName,
                color: color?.name ?? '',
                system,
                electronicLock,
            },
        };
    };

    getFirstImagePathFromCylinders(colorId, ...cylindersBox) {
        const item = cylindersBox.find(i => i !== undefined);
        if(item && colorId) {
            return this.getImageUrl(item, colorId)
        } else if (item.img) {
            return '/files/windowaccessory/' + item.img;
        } else {
            return null;
        }
    }

    setDefaultDecorativeHingeCover(conf = this.configurationsService.conf.Current) {
        const covers = this.decoHingeCovers;
        if (covers.length > 0 && conf.System && conf.System.decorative_hinge_covers) {
            if (!covers.map(p => castToNullableNumber(p.id)).includes(conf.doorHardware.decorativeHingeCovers)) {
                this.setDecorativeHingeCover(Number(covers[0].id));
            }
        } else {
            this.setDecorativeHingeCover(null);
        }
    }

    setDecorativeHingeCover(id: number | null, conf = this.configurationsService.conf.Current) {
        conf.doorHardware.decorativeHingeCovers = id;
        this.setDefaultColorForDecorativeHingeCover(conf);
        this.emitEvent();
    }

    openModalDecorativeHingeCoverOptions() {
        const matchedColor = this.hardwareColors.find(
            (el) =>
                Number(el.id) ===
                this.configurationsService.conf.Current.doorHardware.decorativeHingeCoversColor
        );
        const hingeCoverId =
            this.configurationsService.conf.Current.doorHardware
            && this.configurationsService.conf.Current.doorHardware.decorativeHingeCovers;
        const cover = this.decoHingeCovers.find(f => Number(f.id) === Number(hingeCoverId));
        const modalInstance = this.modalService.open({
            pageComponent: HardwareTypeOptionsPageComponent,
            resolve: {
                title: () => _('WINDOW|Ozdobne nakładki zawiasów'),
                selectedType: () => cover,
                hardwareColors: () => this.hardwareColors,
                selColor: () => matchedColor,
            },
        });

        modalInstance.result.then(selectedData => {
            if (isObject(selectedData) && isDefined(selectedData.type)) {
                this.setDecorativeHingeCoverColorById(selectedData.color);
            }
        });
    }

    /**
     * Funkcja ustawiajca kolor ozdobnych nakładek zawiasów
     * @param {object} color Kolor
     */
    setDecorativeHingeCoverColor(
        color: IccAccessoryColor | null,
        conf = this.configurationsService.conf.Current
    ) {
        conf.doorHardware.decorativeHingeCoversColor = color ? Number(color.id) : null;
        if (conf.doorHardware.decorativeHingeCoversColor) {
            this.setColorsBasedOnColorMapping(
                Number(conf.doorHardware.decorativeHingeCoversColor),
                'decoHingeCovers'
            );
        }
        this.emitEvent();
    }

    /**
     * Funkcja ustawiająca kolor dla ozdobnych nakładek zawiasów
     * @param  {number} colorId  Id koloru
     */
    setDecorativeHingeCoverColorById(colorId, isDefault = false) {
        const matchedColors = this.hardwareColors.filter(el => el.id == colorId);
        if (matchedColors.length > 0) {
            const color = core.copy(matchedColors[0]);
            color.isDefault = isDefault;
            this.setDecorativeHingeCoverColor(color);
        } else {
            this.setDecorativeHingeCoverColor(null);
        }
    }

    /**
     * Funkcja ustawiająca domyslny kolor dla ozdobnych nakładek zawiasów
     */
    setDefaultColorForDecorativeHingeCover(conf = this.configurationsService.conf.Current) {
        const cover = this.decoHingeCovers.find(
            t => Number(t.id) === conf.doorHardware.decorativeHingeCovers
        );

        if(conf.doorHardware.decorativeHingeCoversColorRelatedToPlate && conf.doorHardware.plates?.color) {
            this.setDecorativeHingeCoverColorById(conf.doorHardware.plates.color);
        } else if (isObject(cover) && isArray(cover.colors_ids) && isArray(this.hardwareColors)) {
            const windowColorId = this.colorMappingService.getWindowColorId(conf, 'inner');
            const matchedColors = this.colorMappingService.getColors(
                Number(windowColorId),
                'window',
                'accessory'
            );

            const matchedColors2 = this.colorMappingService.getColors(
                Number(windowColorId),
                'accessory',
                'accessory'
            );
            const colors = this.hardwareColors.filter(el => cover.colors_ids.indexOf(el.id) > -1);
            if (isArray(colors) && isDefined(colors[0])) {
                const windowColors = matchedColors
                    .map(m => colors.filter(c => Number(c.id) === m)[0])
                    .filter(m => m);
                let color;
                if (isArray(windowColors) && isDefined(windowColors[0])) {
                    color = core.copy(windowColors[0]);
                } else {
                    color = core.copy(colors[0]);
                }
                color.isDefault = true;
                this.setDecorativeHingeCoverColor(color, conf);
            }
        } else {
            this.setDecorativeHingeCoverColor(null, conf);
        }
    }

    /**
     * Each accessory has an assigned category.
     * Returns @iccListTab array based on the provided @accessories array.
     * @returns {iccListTab}
     */
    private getTabsBasedOnWindowAccessoriesCategoryId(accessories) {
        const accessoriesProjection: iccListTab[] = [];

        const uniqueCategoriesIds = Array.from(
            new Set(accessories.map((p) => p.window_accessories_category_id))
        );

        const categoriesName = this.configuratorsDataService.data.windowAccessoriesCategories.filter(
            (c) => uniqueCategoriesIds.some((x) => Number(x) === Number(c.id))
        );

        categoriesName.forEach(a => {
            accessoriesProjection.push({id: Number(a.id), name: a.name} );
        });

        return accessoriesProjection;
    }

    private emitEvent() {
        this.updateDictionary();
        this.eventBusService.post({
            key: 'changedDoorHardware',
            value: null,
        });
    }

    setColorsBasedOnColorMapping(
        colorId: number,
        passedType?: 'pull' | 'decoHingeCovers' | 'handle' | 'cylinder' | 'accessory' | 'plates',
        conf = this.configurationsService.conf.Current
    ) {
        if (!colorId) return;

        const matchedColors = this.colorMappingService.getMappedColorsFromAccessory(colorId);
        let matchedColor = [];

        if (passedType !== 'cylinder') {
            const cylinderMainLock = this.cylindersToMainLock.find(
                (p) => Number(p.id) === Number(conf.doorHardware.cylinders.mainLock)
            )?.colors_ids;
            matchedColor = matchedColors?.filter((el) =>
                cylinderMainLock?.some((p) => Number(p) === Number(el))
            );
            if (matchedColor && matchedColor[0]) {
                conf.doorHardware.cylinders.color = matchedColor[0];
            }
        }

        if (passedType !== 'decoHingeCovers') {
            const decoHingeCovers = this.decoHingeCovers.find(
                (p) => Number(p.id) === Number(conf.doorHardware.decorativeHingeCovers)
            )?.colors_ids;
            matchedColor = matchedColors?.filter((el) =>
                decoHingeCovers?.some((p) => Number(p) === Number(el))
            );
            if (matchedColor && matchedColor[0]) {
                conf.doorHardware.decorativeHingeCoversColor = matchedColor[0];
            }
        }

        const handleColorsAll = this.configuratorsDataService.data.windowHandlesColors;
        const hingeColors = conf.Hinge?.colors_ids;
        matchedColor = matchedColors?.filter((el) =>
            hingeColors?.some((p) => Number(p) === Number(el))
        );
        if (matchedColor && matchedColor[0]) {
            const color = handleColorsAll.find((p) => Number(p.id) === Number(matchedColor[0]));
            conf.HingeColor = color;
        }

        if (passedType !== 'plates') {
            const platesToMainLock = this.platesToMainLock.find(
                (p) => Number(p.id) === Number(conf.doorHardware.plates.mainLock)
            )?.colors_ids;
            matchedColor = matchedColors?.filter((el) =>
                platesToMainLock?.some((p) => Number(p) === Number(el))
            );
            if (matchedColor && matchedColor[0]) {
                conf.doorHardware.plates.color = matchedColor[0];
            }
        }
        if (passedType !== 'pull') {
            const pulls = this.pulls.find((p) => Number(p.id) === Number(conf.doorHardware.pull))
                ?.colors_ids;
            matchedColor = matchedColors?.filter((el) =>
                pulls?.some((p) => Number(p) === Number(el))
            );
            if (matchedColor && matchedColor[0]) {
                conf.doorHardware.pullColor = matchedColor[0];
            }
        }

        if (passedType !== 'accessory') {
            const accessories = conf.Accessories;
            accessories.forEach(a => {
                matchedColor = matchedColors?.filter((el) => a.accessory.colors_ids?.some(p => Number(p) === Number(el)));
              
                if (matchedColor && matchedColor[0]) {
                    a.color = matchedColor[0];
                    a.accessory.color = this.accessoriesService.colorsAll.find(p => Number(p.id) === Number(matchedColor[0]));
                    a.accessory.selectedColor = matchedColor[0];
                }

            })
        }
    }


    private updateDictionary(conf = this.configurationsService.conf.Current) {
        if (this.configuratorsDataService) {
            const doorHandleAdjustment = this.configuratorsDataService.get(
                'doorHandleAdjustments',
                conf.doorHardware.doorHandleAdjustment
            );
            if (doorHandleAdjustment) {
                conf.doorHardwareDictionary.doorHardware[doorHandleAdjustment.id] = {
                    id: doorHandleAdjustment.id,
                    name: doorHandleAdjustment.name,
                };
            }

            const pull = this.configuratorsDataService.get('pullType', conf.doorHardware.pull);
            if (pull) {
                conf.doorHardwareDictionary.doorHardware[pull.id] = {
                    id: pull.id,
                    name: pull.name,
                };
                const color = conf.doorHardware.pullColor || conf.doorHardware.color;
                conf.doorHardware.visualizations.pull =
                    pull.visualizations
                    && ((color && pull.visualizations[color]) || pull.visualizations[-1]);
            }

            const pullColor = this.configuratorsDataService.get(
                'windowHandlesColors',
                conf.doorHardware.pullColor
            );
            if (pullColor) {
                conf.doorHardwareDictionary.accessoryColors[Number(pullColor.id)] = {
                    id: Number(pullColor.id),
                    name: pullColor.name,
                    color: pullColor.color,
                    code: pullColor.code,
                    color_img: pullColor.color_img,
                };
            }

            const electronicLock = this.configuratorsDataService.get(
                'electronicLocks',
                conf.doorHardware.electronicLock
            );
            if (electronicLock) {
                conf.doorHardwareDictionary.doorHardware[electronicLock.id] = {
                    id: electronicLock.id,
                    name: electronicLock.name,
                };
            }

            const plateType = this.configuratorsDataService.get(
                'plateTypes',
                conf.doorHardware.plateType
            );
            if (plateType) {
                conf.doorHardwareDictionary.doorHardware[plateType.id] = {
                    id: plateType.id,
                    name: plateType.name,
                    backset: Number(plateType.backset),
                    distanceToAdditionalLock: Number(plateType.distance_to_additional_lock),
                    lockSpacing: Number(plateType.lock_spacing),
                };
            }

            const doorViewer = this.configuratorsDataService.get(
                'doorViewers',
                conf.doorHardware.doorViewer
            );
            if (doorViewer) {
                conf.doorHardwareDictionary.doorHardware[doorViewer.id] = {
                    id: doorViewer.id,
                    name: doorViewer.name,
                };
                conf.doorHardware.visualizations.doorViewer =
                    doorViewer.visualizations && doorViewer.visualizations[-1];
            }

            const additionalLockPlate = this.configuratorsDataService.get(
                'plates',
                conf.doorHardware.plates.additionalLock
            );
            if (additionalLockPlate) {
                conf.doorHardwareDictionary.doorHardware[additionalLockPlate.id] = {
                    id: additionalLockPlate.id,
                    name: additionalLockPlate.name,
                    backset: Number(additionalLockPlate.backset),
                    distanceToAdditionalLock: Number(
                        additionalLockPlate.distance_to_additional_lock
                    ),
                    lockSpacing: Number(additionalLockPlate.lock_spacing),
                };
                const color = conf.doorHardware.plates.color || conf.doorHardware.color;
                conf.doorHardware.visualizations.additionalLockPlate =
                    additionalLockPlate.visualizations
                    && ((color && additionalLockPlate.visualizations[color])
                        || additionalLockPlate.visualizations[-1]);
            }

            const mainLockCylinder = this.configuratorsDataService.get(
                'cylinders',
                conf.doorHardware.cylinders.mainLock
            );
            if (mainLockCylinder) {
                conf.doorHardwareDictionary.doorHardware[mainLockCylinder.id] = {
                    id: mainLockCylinder.id,
                    name: mainLockCylinder.name,
                    backset: Number(mainLockCylinder.backset),
                    distanceToAdditionalLock: Number(mainLockCylinder.distance_to_additional_lock),
                    lockSpacing: Number(mainLockCylinder.lock_spacing),
                };
                const color = conf.doorHardware.cylinders.color || conf.doorHardware.plates.color || conf.doorHardware.color;
                conf.doorHardware.visualizations.mainLockCylinder =
                    mainLockCylinder.visualizations
                    && ((color && mainLockCylinder.visualizations[color])
                        || mainLockCylinder.visualizations[-1]);
            }

            const additionalLockCylinder = this.configuratorsDataService.get(
                'cylinders',
                conf.doorHardware.cylinders.additionalLock
            );
            if (additionalLockCylinder) {
                conf.doorHardwareDictionary.doorHardware[additionalLockCylinder.id] = {
                    id: additionalLockCylinder.id,
                    name: additionalLockCylinder.name,
                    backset: Number(additionalLockCylinder.backset),
                    distanceToAdditionalLock: Number(
                        additionalLockCylinder.distance_to_additional_lock
                    ),
                    lockSpacing: Number(additionalLockCylinder.lock_spacing),
                };
                const color = conf.doorHardware.plates.color || conf.doorHardware.color;
                conf.doorHardware.visualizations.additionalLockCylinder =
                    additionalLockCylinder.visualizations
                    && ((color && additionalLockCylinder.visualizations[color])
                        || additionalLockCylinder.visualizations[-1]);
            }

            const type = this.configuratorsDataService.get('hardwareTypes', conf.doorHardware.type);
            if (type) {
                conf.doorHardwareDictionary.doorHardware[type.id] = {
                    id: type.id,
                    name: type.name,
                    backset: Number(type.backset),
                    distanceToAdditionalLock: Number(type.distance_to_additional_lock),
                    lockSpacing: Number(type.lock_spacing),
                };
            }

            const system = this.configuratorsDataService.get(
                'hardwareSystems',
                conf.doorHardware.cylinders.system
            );
            if (system) {
                conf.doorHardwareDictionary.hardwareSystems[system.id] = {
                    id: system.id,
                    name: system.name,
                };
            }

            const mainLockPlate = this.configuratorsDataService.get(
                'plates',
                conf.doorHardware.plates.mainLock
            );
            if (mainLockPlate) {
                conf.doorHardwareDictionary.doorHardware[mainLockPlate.id] = {
                    id: mainLockPlate.id,
                    name: mainLockPlate.name,
                    backset: Number(mainLockPlate.backset),
                    distanceToAdditionalLock: Number(mainLockPlate.distance_to_additional_lock),
                    lockSpacing: Number(mainLockPlate.lock_spacing),
                };
                const color = conf.doorHardware.plates.color || conf.doorHardware.color;
                conf.doorHardware.visualizations.mainLockPlate =
                    mainLockPlate.visualizations
                    && ((color && mainLockPlate.visualizations[color])
                        || mainLockPlate.visualizations[-1]);
            }

            const cylinderColor = this.configuratorsDataService.get(
                'windowHandlesColors',
                conf.doorHardware.cylinders.color
            );
            if (cylinderColor) {
                conf.doorHardwareDictionary.accessoryColors[Number(cylinderColor.id)] = {
                    id: Number(cylinderColor.id),
                    name: cylinderColor.name,
                    color: cylinderColor.color,
                    code: cylinderColor.code,
                    color_img: cylinderColor.color_img,
                };
            }

            const decorativeHingeCovers = this.configuratorsDataService.get(
                'decorativeHingeCovers',
                conf.doorHardware.decorativeHingeCovers
            );

            if(decorativeHingeCovers) {
                conf.doorHardwareDictionary.doorHardware[decorativeHingeCovers.id] = {
                    id: decorativeHingeCovers.id,
                    name: decorativeHingeCovers.name,
                };
                const color = conf.doorHardware.decorativeHingeCoversColor || conf.doorHardware.color;
                const isAvailableInSystem = decorativeHingeCovers.window_lines_ids.includes(conf.System.id);
                conf.doorHardware.visualizations.decorativeHingeCovers = isAvailableInSystem && decorativeHingeCovers.visualizations
                    && ((color && decorativeHingeCovers.visualizations[color]) || decorativeHingeCovers.visualizations[-1]);
                conf.doorHardware.decorativeHingeCoversColorRelatedToPlate = decorativeHingeCovers.color_related_to_plate;
            }

            const typeColor = this.configuratorsDataService.get(
                'windowHandlesColors',
                conf.doorHardware.color
            );
            if (typeColor) {
                conf.doorHardwareDictionary.accessoryColors[Number(typeColor.id)] = {
                    id: Number(typeColor.id),
                    name: typeColor.name,
                    color: typeColor.color,
                    code: typeColor.code,
                    color_img: typeColor.color_img,
                };
            }

            const plateColor = this.configuratorsDataService.get(
                'windowHandlesColors',
                conf.doorHardware.plates.color
            );
            if (plateColor) {
                conf.doorHardwareDictionary.accessoryColors[Number(plateColor.id)] = {
                    id: Number(plateColor.id),
                    name: plateColor.name,
                    color: plateColor.color,
                    code: plateColor.code,
                    color_img: plateColor.color_img,
                };
            }

            const decorativeHingeCoversColor = this.configuratorsDataService.get(
                'windowHandlesColors',
                conf.doorHardware.decorativeHingeCoversColor
            );
            if (decorativeHingeCoversColor) {
                conf.doorHardwareDictionary.accessoryColors[
                    Number(decorativeHingeCoversColor.id)
                ] = {
                    id: Number(decorativeHingeCoversColor.id),
                    name: decorativeHingeCoversColor.name,
                    color: decorativeHingeCoversColor.color,
                    code: decorativeHingeCoversColor.code,
                    color_img: decorativeHingeCoversColor.color_img,
                };
            }
        }
    }
}
