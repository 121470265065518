<icc-select-box [title]="'ROLLERSHUTTER|System' | translate"
                [description]="(selectedSystem$ | async)?.name"
                [imageUrl]="'/files/rollershuttersystem/' + ((selectedSystem$ | async)?.image || '')"
                [buttonText]="'INTERFACE|Zmień' | translate"
                (select)="changeSystem()"
></icc-select-box>

<icc-select-box
                *ngIf="(selectedConfiguratorType$ | async) === 'external_blind'"
                [title]="'ROLLERSHUTTER|Typ pancerza:' | translate"
                [description]="(profile$ | async)?.name"
                [imageUrl]="'/files/profile/' + ((profile$ | async)?.img || '')"
                [buttonText]="'INTERFACE|Zmień' | translate"
                (select)="changeProfile()"
></icc-select-box>

<div *ngIf="mosquito$ | async" id="mosquito-form" class="mat-elevation-z3">
    <form>
        <h4>{{ 'ROLLERSHUTTER|Siatka antyinsektowa:' | translate }}</h4>
        <mat-checkbox *ngFor="let shutter of (shutters$ | async); index as index;" [name]="'shutter-mosquito-' + index" [ngModel]="shutter.mosquito"  (ngModelChange)="toggleMosquito($event, index)">
            {{ 'WINDOW|Kwatera' | translate }} {{letters[index]}}
        </mat-checkbox>
    </form>
</div>
